import { Component, OnInit, ViewChild } from '@angular/core';
import { _getOptionScrollPosition } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-pools',
  templateUrl: './pools.component.html',
  styleUrls: ['./pools.component.scss']
})
export class PoolsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['isActive', 'defaultName'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  filterString = '';
  showOnlyActive = true;
  loadingData = false;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private uiService: UiService,
    public userService: UserService

  ) { }

  ngOnInit(): void {
    this.getPools();
  }

  getPools() {
    this.loadingData = true;
    if (!this.userService.hasPermission('CanReadPool')) {
      return;
    }
    this.externaldatasourceService.executeExternalDataSource(584)
    .then(executeExternalDataSourceResult => {
      this.dataSource = new MatTableDataSource(executeExternalDataSourceResult);
      this.loadingData = false;
      this.applicationInfoService.projectInfo.pools = executeExternalDataSourceResult;
    })
    .catch(error => {
      this.loadingData = false;
      console.error(error);
    });
  }

  savePool(pool) {
    if (!this.userService.hasPermission('CanUpdatePool')) {
      return;
    }

    this.externaldatasourceService.executeExternalDataSource(583, [
      this.commonService.getModifyArrayBody(pool, [])
    ])
    .then(executeExternalDataSourceResult => {
    })
    .catch(error => {
      const poolToUpdate = this.applicationInfoService.projectInfo.pools.find(projectPool => projectPool.id == pool.id);
      if (poolToUpdate) {
        poolToUpdate.defaultName = pool.defaultName
      }
      console.error(error);
    });

  }

  getTableHeight() {
    let tableSize = Number(this.uiService.getDesignSpanPosition('projectSettingsDesignSpan', 40));
    return tableSize + 'px';
  }

  itemIsFiltered(row) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(row.defaultName, this.filterString)) {
     returnValue = false
    }
    return returnValue;
  }

  createNewPool() {
    this.externaldatasourceService.executeExternalDataSource(582, [
      this.commonService.getModifyArrayBody({
        projectId: this.applicationInfoService.projectID,
        defaultName: "new pool",
        isActive: true
      }, [])
    ])
    .then(executeExternalDataSourceResult => {
      this.getPools();
    })
    .catch(error => {
      console.error(error);
    });
  }
}
