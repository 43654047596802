<div class="everleadSupportWrapper">
    <div class="everleadSupportSection">
        <div>
            <!-- <div class="everleadSupportSectionHeader">{{ 'EVERLEADSupport.Label.BugHeader' | texttransform }}</div> -->
            <app-layout header="EVERLEADSupport.Label.BugHeader">
                <div class="everleadSupportSectionDescription">{{ 'EVERLEADSupport.Label.BugDesc' | texttransform }}</div>
                <div class="everleadSupportSectionLink">
                    <app-jj-icons type="envelope" [width]="20" [height]="20"></app-jj-icons>
                    <a href="mailto:support@everlead.ai">                        
                        {{ 'EVERLEADSupport.Label.CustomerService' | texttransform }}
                    </a><br>
                </div>    
            </app-layout>            
        </div>
        <div>
            <app-layout header="EVERLEADSupport.Label.SuggestionHeader">
                <!-- <div class="everleadSupportSectionHeader">{{ 'EVERLEADSupport.Label.SuggestionHeader' | texttransform }}</div> -->
                <div class="everleadSupportSectionDescription">{{ 'EVERLEADSupport.Label.SuggestionDesc' | texttransform }}</div>
                <div class="everleadSupportSectionLink">                    
                    <app-jj-icons type="envelope" [width]="20" [height]="20"></app-jj-icons>
                    <a href="mailto:suggestions@everlead.ai">                        
                        {{ 'EVERLEADSupport.Label.SendSuggestion' | texttransform }}
                    </a><br>
                </div>    
            </app-layout>
        </div>    
    </div>
    <div class="everleadSupportButton">
        <app-main-button svg="x" (onClick)="closePopup()" label="General.Label.Close"></app-main-button>
    </div>
</div>