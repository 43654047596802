

<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>
    <div class="wizardPageContent maxHeight">

            <div *ngIf="wizardService.wizardPage == 1" class="maxHeight">
            
                <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.showDuplicateOption == true">
                    <label class="wizardSubheader__descriptionLabel">{{ 'CampaignDesign.Label.CloneTemplateMessage' | texttransform }}</label>
                    <label class="noPadding wizardLabel">
                        {{ 'CampaignDesign.Label.ExistingTemplateWorkflow' | texttransform }}
                    </label>
                    <app-wizard-infobutton displayText="CampaignDesign.Label.ExistingTemplate"></app-wizard-infobutton>
                    <select [(ngModel)]="wizardService.wizardArray.selectedTemplateWorkflow" class="wizardInput"
                        (change)="getTemplate()">
                        <option [ngValue]="null">{{ 'ProcessDesign.Label.PleaseSelect' | texttransform }}</option>
                        <option *ngFor="let template of applicationInfoService.workflows.toArray();" [ngValue]="template.id">
                            {{template?.defaultName}}</option>
                    </select>
                </div>

                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">
                        {{ 'ProcessDesign.Label.DefaultName' | texttransform }}
                        <app-wizard-infobutton displayText="CampaignDesign.Info.DefaultName"></app-wizard-infobutton>
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.defaultName" class="wizardInput">
                </div>
                <div class="wizardDetailItem" *ngIf="!wizardService.wizardArray.duplicateTemplate && !wizardService.wizardArray.selectedTemplateWorkflow">
                    <label class="noPadding wizardLabel">
                        {{ 'CampaignDesign.Label.IsActive' | texttransform }}
                        <app-wizard-infobutton displayText="CampaignDesign.Label.IsActive"></app-wizard-infobutton>
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.isActive" type="checkbox">
                </div>

                <div class="workflowFlowchart__wrapper" [ngClass]="{ hideInDom: !wizardService.wizardArray.duplicateTemplateId}">
                    <app-designer-flowchart
                        [controlid]="flowChartId"
                        [initialTreeDepth]="2"
                        [treeWidth]="'65%'"
                        [treeHeight]="'90%'"
                        [height]="'100%'"
                        [width]="'100%'"
                        [toolboxInfo]="true"
                        [toolboxExpand]="false"
                        [top]="'5%'"
                    ></app-designer-flowchart>
                </div>
            </div>
            <div *ngIf="wizardService.wizardPage == 2" class="maxHeight">
                    <div class="wizardDetailItem">
                        <!-- <label class="noPadding wizardLabel">
                            {{ 'ProcessDesign.Label.Type' | texttransform }}
                        </label> -->
                        <select [(ngModel)]="wizardService.wizardArray.typeLookupId" class="maxWidth">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let type of types" [ngValue]="type.id">{{ type?.nameTranslationToken?.tokenFullName | texttransform }}</option>
                        </select>
                    </div>
                    <div class="wizardDetailItem">
                        {{ getTypeTranslationToken() | texttransform }}
                    </div>
            </div>
    
                <div *ngIf="wizardService.wizardPage == 3" class="maxHeight">
                        <div>
                            <div class="wizardDetailItem d-flex">
                                <input [placeholder]="'ProcessDesign.Label.CreateStepToolTip' | texttransform" [(ngModel)]="newStepName" class="wizardDetailItem__input">
                                <button class="wizardDetailItem__button" (click)="createWorkflowStep(newStepName)" [disabled]="newStepName.length < 3">{{ 'ProcessDesign.Label.CreateStep' | texttransform }}</button>
                            </div>
                            <div class="wizardDetailItem d-flex" *ngIf="applicationInfoService.expertMode">
                                <input [placeholder]="'ProcessDesign.Label.CreateStepsToolTip' | texttransform" [(ngModel)]="newStepBatch" class="wizardDetailItem__input">
                                <button class="wizardDetailItem__button" (click)="createWorkflowSteps(newStepBatch)" [disabled]="newStepBatch.length < 3">{{ 'ProcessDesign.Label.CreateSteps' | texttransform }}</button>
                            </div>
                        </div>            
                        <div [ngClass]="{
                            workflowDefault: !applicationInfoService.expertMode,
                            workflowExpert: applicationInfoService.expertMode
                        }">   
                            <label class="wizardSubheader__descriptionLabel">{{ 'ProcessDesign.Steps.ExistingSteps' | texttransform }}</label>             
                            <div class="wizardDetailItem" *ngFor="let step of wizardService.wizardArray.workflowSteps">
                                <label class="noPadding wizardLabel">
                                    {{ step.defaultName | texttransform }}                
                                </label>            
                            </div>
                        </div>        
                </div>

                <div *ngIf="wizardService.wizardPage == 4" class="maxHeight">
                        <div class="wizardDetailItem">
                            <label class="noPadding wizardLabel">
                                {{ 'ProcessDesign.Label.StarterStepId' | texttransform }}
                                <app-wizard-infobutton displayText="ProcessDesign.Info.StarterStepId"></app-wizard-infobutton>
                            </label>
                            <select [(ngModel)]="wizardService.wizardArray.starterStepId" class="wizardInput">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let step of wizardService.wizardArray.workflowSteps" [ngValue]="step.id">{{ step.defaultName }}</option>
                            </select>
                        </div>
                        <div class="wizardDetailItem">
                            <label class="noPadding wizardLabel">
                                {{ 'ProcessDesign.Label.NotRelevantStepId' | texttransform }} 
                                <app-wizard-infobutton displayText="ProcessDesign.Info.NotRelevantStepId"></app-wizard-infobutton>
                            </label>
                            <select [(ngModel)]="wizardService.wizardArray.notRelevantStepResultId" class="wizardInput">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let step of wizardService.wizardArray.workflowSteps" [ngValue]="step.id">{{ step.defaultName }}</option>
                            </select>
                        </div>
                </div>

                <div *ngIf="wizardService.wizardPage == 5" class="maxHeight">
                        <div class="wizardDetailItem">
                            <label class="noPadding wizardLabel">
                                {{ 'ProcessDesign.Label.IsCallingPerAccount' | texttransform }}
                                <app-wizard-infobutton displayText="ProcessDesign.Info.IsCallingPerAccount"></app-wizard-infobutton>
                            </label>
                            <input [(ngModel)]="wizardService.wizardArray.isCallingPerAccount" type="checkbox">
                        </div>
                </div>
    </div>
</div>
