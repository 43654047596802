
    <div [style.z-index]=getZIndex(1) id="everleadPopup" class="popupContainer__wrapper" 
        [style.width]="getPopupDimension('x')" 
        [style.height]="getPopupDimension('y')" 
        [ngClass]="{ hideInDom: applicationInfoService.showJJPopup == false }"
    >
        <div id="everleadPopup__header" class="popupContainer__header" >
            <div class="ellipsisText headerText">
                <span *ngIf="axivasTranslateService.translationTextArrayGlobal != null && !wizardService?.wizardArray?.duplicateTemplateId">
                    {{ headerText | texttransform }}</span>
                <span *ngIf="wizardService?.wizardArray?.duplicateTemplateId">{{ 'ProcessVisual.Label.TemplateWorkflow'| texttransform }}</span>
            </div>        
            <div class="popupContainer__header__icons">
                <app-jj-icons [type]="'frame-corners'" *ngIf="!isExpanded && !applicationInfoService.isMobile() && isInitialyMaximized == false" 
                    class="fingerCursor" (click)="toggleExpand()" [pointer]="true"></app-jj-icons>
                <app-jj-icons [type]="'arrows-in-simple'" *ngIf="isExpanded && !applicationInfoService.isMobile() && isInitialyMaximized == false" 
                    class="fingerCursor" (click)="toggleExpand()" [pointer]="true"></app-jj-icons>
                <app-jj-icons [type]="'x'" *ngIf="popupIsClosable" (click)="closePopup()" class="fingerCursor" [pointer]="true"></app-jj-icons>
                <!-- <svg-icon src="assets/images/svg/icon-Max.svg" *ngIf="!isExpanded && !applicationInfoService.isMobile() && isInitialyMaximized == false" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" class="fingerCursor popupContainer__header__icon" (click)="toggleExpand()"></svg-icon> -->
                <!-- <svg-icon src="assets/images/svg/icon-Min.svg" *ngIf="isExpanded && !applicationInfoService.isMobile() && isInitialyMaximized == false" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" class="fingerCursor popupContainer__header__icon" (click)="toggleExpand()"></svg-icon> -->
                <!-- <svg-icon src="assets/images/svg/close.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" *ngIf="popupIsClosable" class="fingerCursor popupContainer__header__icon" (click)="closePopup()"></svg-icon>     -->
            </div>
        </div>    
        <div *ngIf="subHeader != null" class="subHeaderText">
            {{ subHeader | texttransform }}
        </div>
        <div #popupContent class="popupContainer__content" [ngClass]="{ popupContainer__content__fullSize: isFullSize }">
            <div [innerHTML]="innerHTML" name="jjPopupDialog" class="popupContainer__inner" *ngIf="showContainer == false"></div>
            <app-custom-container *ngIf="showContainer == true" [inititalid]=controlId [controluiid]=controlId style="overflow: hidden;"></app-custom-container>        
            <div id="jjpopupbottom" class="popupContainer__bottom"></div>
        </div>        
    </div>

<div *ngIf="applicationInfoService.showJJPopup && applicationInfoService.applicationInitialized" class="pupopBackGroundLayer" [style.z-index]=getZIndex()></div>