<button [id]="id" *ngIf="type === 'sm'" class="everleadMainButtonAction" [disabled]="disabled" (click)="buttonClick()" [class.disabled]="disabled" [style.width.px]="24" [style.height.px]="24" [ngStyle]="{ 'background-color' : (click || isActive) ? 'secondary' : undefined }">
    <app-jj-icons [type]="svg" [width]="16" [height]="16" [color]="color ? color : actioncolorChange" [tippy]="label | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"></app-jj-icons>
</button>

<button [id]="id" *ngIf="type === 'md'" class="everleadMainButtonAction" [disabled]="disabled" (click)="buttonClick()" [class.disabled]="disabled" [style.width.px]="32" [style.height.px]="32" [ngStyle]="{ 'background-color' : (click || isActive) ? 'secondary' : undefined }">
    <app-jj-icons [type]="svg" [width]="24" [height]="24" [color]="color ? color : actioncolorChange" [tippy]="label | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"></app-jj-icons>
</button>

<button [id]="id" *ngIf="type === 'lg'" class="everleadMainButtonAction" [disabled]="disabled" (click)="buttonClick()" [class.disabled]="disabled" [style.width.px]="48" [style.height.px]="48" [ngStyle]="{ 'background-color' : (click || isActive) ? 'secondary' : undefined }">
    <app-jj-icons [type]="svg" [width]="24" [height]="24" [color]=" color ? color : actioncolorChange" [tippy]="label | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"></app-jj-icons>
</button>


