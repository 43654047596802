<div class="licenseAddUserWrapper">
        
    <div class="licenseAddUserPrep" *ngIf="mode==1">
        <!-- licenseCheck -->
        <div class="licenseAddUserPrep__rocket">
            <app-jj-icons [type]="'rocket'" [width]="160" [height]="160"></app-jj-icons>
        </div>
        <div class="licenseAddUserPrep__text">
            <div class="licenseAddUserPrep__text__header">
                {{ 'Licensing.Label.WantToAddNewLicenses' | texttransform }}
            </div>
            <div class="licenseAddUserPrep__text__description">
                {{ 'Licensing.Label.WantToAddNewLicensesDesc' | texttransform }}
            </div>
        </div>
        <div class="licenseAddUserPrep__buttons">
            <app-main-button [maxWidth]="true" svg="" (onClick)="changeMode(2)" label="Licensing.Label.AddLicense"></app-main-button>
            <app-main-button [maxWidth]="true" svg="" type="border" (onClick)="closePopup()" label="General._.Cancel"></app-main-button>
        </div>
    </div>
        
    <div class="licenseAddUserLicense" *ngIf="mode==2">
        <!-- newLicenses -->
        <div class="licenseAddUserLicense__headerWrapper">
            <div class="licenseAddUserLicense__header">
                <div class="licenseAddUserLabel">{{ 'Licensing.Label.CurrentPlan' | texttransform }}</div>
                <div class="licenseAddUserValue">{{ getPackageName() }}</div>    
            </div>
            <div class="licenseAddUserLicense__headerUpgradePlan">
                <div (click)="upgradePlan()" class="upgradePlan">{{ 'Licensing.Label.UpgradePlan' | texttransform }}</div>    
            </div>
        </div>
        <div class="licenseAddUserLicense__currentLicenses">
            <div class="licenseAddUserLabel">{{ 'Licensing.Label.CurrentLicenseAmount' | texttransform }}</div>
            <div class="licenseAddUserValue">{{ licensingService.licenseInfo.licenseCount }}</div>    
        </div>        

        <div class="licenseAddUserLicense__currentFee">
            <div class="licenseAddUserLabel">{{ 'Licensing.Label.CurrentFee' | texttransform }}</div>
            <div class="licenseAddUserValue">{{ getFee() }}</div>    
        </div>        
        <div class="licenseAddUserLicense__licenses">
            <div class="licenseAddUserLabel licenseAddUserLabelSpacer">{{ 'Licensing.Label.AdditionalLicensesText' | texttransform }}</div>
            <div>
                <app-counter-input  [(ngModel)]="additionalLicenses"></app-counter-input> 
            </div>
        </div>
        <div class="licenseAddUserLicense__newLicenseFee">
            <div class="licenseAddUserLabel">{{ 'Licensing.Label.NewFee' | texttransform }}</div>
            <div class="licenseAddUserLabel">{{ getNewFeeText() }}</div>
            <div class="licenseAddUserValue">{{ getFee(true) }}</div>    
        </div>
        <div class="licenseAddUserLicense__acceptNewFee">
            <div class="licenseAddUserLicense__acceptNewFee__box">
                <input type="checkbox" [(ngModel)]="acceptNewFee">
            </div>
            <div class="licenseAddUserLicense__acceptNewFee__text" [innerHTML]="'Licensing.Label.NewLicenseDisclaimer' | texttransform">
                <!-- {{ 'Licensing.Label.NewLicenseDisclaimer' | texttransform }} -->
            </div>
        </div>
        <div class="licenseAddUserLicense__buttons">
            <app-main-button [maxWidth]="true" svg="" (onClick)="addLicenses()" label="Licensing.Label.AddNow"
                [disabled]="additionalLicenses == 0 || !acceptNewFee"></app-main-button>
            <app-main-button [maxWidth]="true" svg="" type="border" (onClick)="closePopup()" label="General._.Cancel"></app-main-button>
        </div>
    </div>

    <div class="licenseAddUserPrep" *ngIf="mode==3">
        <!-- licenses added Successfully -->
        <div class="licenseAddUserPrep__rocket">
            <app-jj-icons [type]="'user-check'" [width]="160" [height]="160"></app-jj-icons>
        </div>
        <div class="licenseAddUserPrep__text">
            <div class="licenseAddUserPrep__text__header">
                {{ 'Licensing.Label.LicensesAddedSuccessfully' | texttransform }}
            </div>
            <div class="licenseAddUserPrep__text__description">
                {{ 'Licensing.Label.LicensesAddedSuccessfullyDesc' | texttransform }}
            </div>
        </div>
        <div class="licenseAddUserPrep__buttons">
            <!-- <app-main-button [maxWidth]="true" svg="" (onClick)="changeMode(4)" label="Licensing.Label.CreateUser"></app-main-button> -->
            <!-- <app-main-button [maxWidth]="true" svg="" type="border" (onClick)="closePopup()" label="Licensing.Label.NotYet"></app-main-button> -->
            <app-main-button [maxWidth]="true" svg="" type="border" (onClick)="closePopup()" label="General.Label.Close"></app-main-button>
        </div>
    </div>

    <div class="licenseAddUserLicense" *ngIf="mode==4">
        <!-- new User -->
        <div class="licenseAddUserLicense__headerWrapper">
            <div class="licenseAddUserLicense__header">
                <div class="licenseAddUserLabel">{{ 'Licensing.Label.CurrentPlan' | texttransform }}</div>
                <div class="licenseAddUserValue">{{ getPackageName() }}</div>    
            </div>
            <div class="licenseAddUserLicense__headerUpgradePlan">
                <div (click)="upgradePlan()" class="upgradePlan">{{ 'Licensing.Label.UpgradePlan' | texttransform }}</div>    
            </div>
        </div>
        
        <div class="licenseAddUserLicense__newUserValues">
            <div *ngFor="let newUser of newUserList" class="newUserItem">
                <app-text-input label="Contact.Label.FirstName" [(ngModel)]="newUser.firstName"></app-text-input>
                <app-text-input label="Contact.Label.LastName" [(ngModel)]="newUser.lastName"></app-text-input>
                <app-text-input label="Contact.Label.EMail" [(ngModel)]="newUser.email"></app-text-input>
                <app-select-input label="General.Label.Role" [(ngModel)]="newUser.roleId" [options]="userRoles" display="defaultName" bind="id"></app-select-input>
                <div *ngIf="newUser.roleId != null" class="newUserItem__roleDesc">{{ getRoleDescription(newUser.roleId) | texttransform }}</div>
                <div class="newUserItem__remove" (click)="removeUser(newUser)">
                    {{ 'General.Label.Remove' | texttransform }}
                </div>
            </div>
            <div class="addNewUserItem">
                <app-main-button [maxWidth]="true" svg="plus" type="svg" *ngIf="!maxUserReached()"
                    (onClick)="addUserToList()" label="Licensing.Label.AddAdditionalUser"></app-main-button>
                <div *ngIf="licensingService.licenseInfo.freeLicenses == 0" class="maxUserReached">
                    {{ 'Licensing.Label.MaxUserReached' | texttransform }}
                </div>    
            </div>
        </div>

        <div class="licenseAddUserLicense__buttons">
            <app-main-button [maxWidth]="true" svg="" (onClick)="createNewUser()" label="Licensing.Label.AddNow"
                [disabled]="!checkIfUserCanBeCreated()"></app-main-button>
            <app-main-button [maxWidth]="true" svg="" type="border" (onClick)="closePopup()" label="General._.Cancel"></app-main-button>
        </div>
    </div>

    <div class="licenseAddUserLicense" *ngIf="mode==5">
        <app-licensing-package-upgrade></app-licensing-package-upgrade>
    </div>

    <div class="licenseAddUserPrep" *ngIf="mode==6">
        <!-- Accept Package change -->
        <div class="licenseAddUserPrep__rocket">
            <app-jj-icons [type]="'rocket'" [width]="160" [height]="160"></app-jj-icons>
        </div>
        <div class="licenseAddUserPrep__text">
            <div class="licenseAddUserPrep__text__header">
                {{ licensingChangePackage?.packageSectionName | texttransform }} {{ licensingChangePackage?.packageDetails?.defaultName }}
            </div>
            <div class="subscriptionSelection" *ngIf="packageChanged == false">
                <div (click)="monthlySubscription = !monthlySubscription">
                    <span *ngIf="!monthlySubscription">
                        {{ 'PackageUpgrade.Label.YearlyInterval' | texttransform }}
                    </span>
                    <!-- <app-jj-icons [type]="'toggle-left-fill'" *ngIf="!monthlySubscription" [width]="60"
                        [height]="28" (click)="changePaymentIntervall()"></app-jj-icons>
                    <app-jj-icons [type]="'toggle-right-fill'" *ngIf="monthlySubscription" [width]="60"
                        [height]="28" (click)="changePaymentIntervall()"></app-jj-icons> -->
                    <span *ngIf="monthlySubscription">
                        {{ 'PackageUpgrade.Label.MonthlyInterval' | texttransform }}
                    </span>
                </div>               
            </div>
            <div class="licenseAddUserPrep__text__description" *ngIf="packageChanged == false">
                {{ 'Licensing.Label.DoYouWantToChangeThePackage' | texttransform }}
            </div>
            <div class="licenseAddUserPrep__text__description" *ngIf="packageChanged ==true">
                {{ 'Licensing.Label.ChangeInProgress' | texttransform }}
            </div>
        </div>
        <div class="licenseAddUserPrep__buttons" *ngIf="packageChanged == true">
            <app-main-button [maxWidth]="true" svg="" type="border" (onClick)="closePopup()" label="General.Label.Close"></app-main-button>
        </div>
        <div class="licenseAddUserPrep__buttons" *ngIf="packageChanged == false">
            <app-main-button [maxWidth]="true" svg="" (onClick)="changePackage()" label="PackageUpgrade.Label.Change"></app-main-button>
            <app-main-button [maxWidth]="true" svg="" type="border" (onClick)="closePopup()" label="General._.Cancel"></app-main-button>
        </div>
    </div>
</div>