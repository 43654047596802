<div class="containerLogout">
  
        <div class="containerLogout__content">
            <div class="containerLogout__message">
                <app-jj-icons type="warning" width="30" height="30"></app-jj-icons>
                {{ 'AWS.Logout.Message' | texttransform }}
            </div>
            <div class="containerLogout__footer">
                <app-main-button svg="x" width="20" height="20" label="General.Label.No" (onClick)="closeWindow()"></app-main-button>
                <app-main-button svg="check" width="20" height="20" label="General.Label.Yes" (onClick)="logout()"></app-main-button>
            </div>
        </div>

    <!--   ! or with app-layout      
        <app-layout header="AWS.Logout.Header">

         <div class="containerLogout__content">
            <div class="containerLogout__message">
                <app-jj-icons type="warning" width="30" height="30"></app-jj-icons>
                {{ 'AWS.Logout.Message' | texttransform }}
            </div>
            <div class="containerLogout__footer">
                <app-main-button svg="x" width="20" height="20" label="General.Label.No" (onClick)="closeWindow()"></app-main-button>
                <app-main-button svg="check" width="20" height="20" label="General.Label.Yes" (onClick)="logout()"></app-main-button>
            </div>
        </div>
        </app-layout> 
    -->

</div>
