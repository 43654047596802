<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>
<!-- 
<app-select-tippy [(ngModel)]="variable" label="newDropbox">
    <option #option [value]="1">numberone</option>
    <option #option [value]="2">numbertwo</option>
</app-select-tippy>
 -->
<div class="leadmanagementDashboard__wrapper" (scroll)="applicationInfoService.onWindowScroll($event)">
    <div class="leadmanagementDashboard__content">
        <div class="leadmanagementDashboard__content__left">
            <div class="leadmanagementDashboard__content__left__leads">
               <!-- <app-dashboardcomponent-leadstates></app-dashboardcomponent-leadstates>  -->
                <app-dashbdashboardcomponent-kpis [showKPIs]="false" class="maxHeight"></app-dashbdashboardcomponent-kpis>
            </div>
            <div class="leadmanagementDashboard__content__left__contacts">
                <!-- <app-dashbdashboardcomponent-kpis [showKPIs]="false" class="maxHeight"></app-dashbdashboardcomponent-kpis> -->
                <app-dashboardcomponent-leadstates></app-dashboardcomponent-leadstates>
            </div>
        </div>
        <div class="leadmanagementDashboard__content__right">
            <app-dashbdashboardcomponent-mytopcontacts class="maxHeight maxWidth" dashboard="leadmanagment"></app-dashbdashboardcomponent-mytopcontacts>
            <!-- <app-dashbdashboardcomponent-myday></app-dashbdashboardcomponent-myday> -->
        </div>
    </div>

</div>