
<div class="everleadDateField" [class.disabled]="disabled">
    <label *ngIf="label" 
    class="everleadDateField__label" 
    [class.disabled]="disabled" [class.error]="error" 
    [class.active]="isFocused && !disabled">
        {{ label | texttransform }}
        <app-wizard-infobutton *ngIf="info" [displayText]="info" [width]="17" [height]="17"></app-wizard-infobutton>
    </label>
    <div class="everleadDateField__value"[class.disabled]="disabled" [class.error]="error" [class.active]="isFocused && !disabled" *ngIf="!calendarOnly">
        <input
        [name]="label + 'reusable-calendar'"
        [owlDateTimeTrigger]="!disabled ? dt101 : null "
        [owlDateTime]="dt101" 
        [placeholder]="placeholder" 
        [(ngModel)]="selectedDate"
        (focus)="handleFocus()"
        (blur)="handleBlur()"
        [min]="minDate"
        [max]="maxDate"
        appendTo="body"
        aria-modal="true"
        (dateTimeChange)="onDateChange($event)"
        [disabled]="disabled"
        readonly>
        <owl-date-time #dt101></owl-date-time>
        <app-jj-icons  (click)="!disabled ? dt101.open() : null" *ngIf="!selectedDate" type="calendar-dots" [width]="20" [height]="20" [color]="disabled ? 'gray0' : 'secondary'"></app-jj-icons>
        <app-jj-icons  *ngIf="selectedDate" (click)="!disabled ? resetValue() : null" type="x" [width]="20" [height]="20" [color]="disabled ? 'gray0':'red'"></app-jj-icons>
    </div>
    <div class="everleadDateField__value"[class.disabled]="disabled" [class.active]="isFocused && !disabled" [class.error]="error" *ngIf="calendarOnly">
        <input
        [name]="label + 'reusable-calendar'"
        [owlDateTimeTrigger]="!disabled ? dt101 : null "
        [owlDateTime]="dt101" 
        [placeholder]="placeholder" 
        [(ngModel)]="selectedDate"
        (focus)="handleFocus()"
        (blur)="handleBlur()"
        [min]="minDate"
        [max]="maxDate"
        appendTo="body"
        aria-modal="true"
        (dateTimeChange)="onDateChange($event)"
        [disabled]="disabled"
        readonly>
        <owl-date-time #dt101 pickerType="calendar"></owl-date-time>
        <app-jj-icons  (click)="!disabled ? dt101.open() : null" *ngIf="!selectedDate" type="calendar-dots" [width]="20" [height]="20" [color]="disabled ? 'gray0' : 'secondary'"></app-jj-icons>
        <app-jj-icons  *ngIf="selectedDate" (click)="!disabled ? resetValue() : null" type="x" [width]="20" [height]="20" [color]="disabled ? 'gray0': 'red'"></app-jj-icons>
    </div>
</div>