<div class="userHierarchyItemWrapper">
    <div class="userHierarchyItem" cdkDropList [cdkDropListData]="userChildren"
    (cdkDropListDropped)="addMe($event)">
        <app-jj-icons type="x" [width]="16" [height]="16" color="red" *ngIf="userChildren.length == 0 && !isGroupOwner && canEditGroups" 
            class="deleteChildIcon" (click)="deleteHierarchyEntry()"></app-jj-icons>
        <div class="userHierarchyItem__icon">
            <app-jj-icons type="crown" [width]="48" [height]="48" *ngIf="isGroupOwner"></app-jj-icons>            
            <app-jj-icons type="user" [width]="48" [height]="48" *ngIf="!isGroupOwner"></app-jj-icons>            
        </div>
        <div class="userHierarchyItem__name">
            {{ getUserName() }}
        </div>                          
        <div class="userHierarchyItem__icon" *ngIf="isGroupOwner && userChildren.length == 0">
            <app-jj-icons type="arrows-counter-clockwise" [width]="24" [height]="24" *ngIf="!switchManager"
                (click)="startSwitching()"></app-jj-icons>
            <app-select-input label="UserHierarchy.Label.NewMainUser" [(ngModel)]="group.mainUserId" [options]="users" 
                display="userName" bind="id" *ngIf="switchManager"></app-select-input>
            <div class="userHierarchyItem__icon__save">
                <app-jj-icons type="floppy-disk" [width]="24" [height]="24" *ngIf="switchManager" (click)="saveNewManager()"></app-jj-icons>
                <app-jj-icons type="x" [width]="24" [height]="24" *ngIf="switchManager" (click)="camcelNewManager()"></app-jj-icons>    
            </div>
        </div>
    </div>
    <div class="userHierarchyChildren">
        <div *ngFor="let child of userChildren" class="childItem">
            <div class="childItem__border">
                <div class="childItem__border__borderdesign"></div>
            </div>
            <app-userhierarchy-designer-item 
                [users]="users"
                [child]="child"
                [groupId]="groupId"
                [groupConnections]="groupConnections"
                [isGroupOwner]="false"
                [parentGuid]="guid"
                [group]="group"
                [canEditGroups]="canEditGroups"
            ></app-userhierarchy-designer-item>
        </div>
    </div>
</div>