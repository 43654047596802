import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-userdashboard',
  templateUrl: './userdashboard.component.html',
  styleUrls: ['./userdashboard.component.scss']
})
export class UserdashboardComponent implements OnInit {
  @Input() selectionmode = 2;  
  campaignOverview = []
  selectedTipp = null;
  dashboardInfo = {
    microCampaignItemsPerPage: 5,
    microCampaignPage: 1,
    microCampaignMaxPage: 1
  }
  newDashboard= false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    public axivasTranslateService: AxivasTranslateService,
    private methodService: MethodService
  ) { }

  ngOnInit(): void {
    this.getCampaignOverview();
    this.getRandomTippOfTheDay()
  }
  
  getRandomTippOfTheDay() {
    const tipps = this.applicationInfoService.tipps.filter(tipp => tipp.typeLookupId == 6467);
    if (tipps.length > 0) {
      const randomNumber = this.commonService.randomNumber(0, tipps.length - 1);
      this.selectedTipp = tipps[randomNumber];
    }    
  }

  getCampaignOverview() {
    this.externaldatasourceService.executeExternalDataSource(759, [this.dashboardInfo.microCampaignPage, this.dashboardInfo.microCampaignItemsPerPage])
    .then(getCampaignOverviewResult => {
      this.campaignOverview = getCampaignOverviewResult;
    });
  }

  showCampaignVisual(campaignOverviewItem) {
    this.applicationInfoService.miscSettings['processvisual'] = campaignOverviewItem;
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [campaignOverviewItem.resultName, 'processvisual', '90']);
  }
}
