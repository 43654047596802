<div class="barleadscoreWrapper" (click)="showPopup()" [ngClass]="{ fingerCursor: popupAllowed }">
    <div class="barleadscoreWrapper__labelWrapper">
        <span *ngIf="label" class="barleadscoreWrapper__label">{{label | texttransform}}</span>
        <span *ngIf="labelStrong" class="barleadscoreWrapper__labelStrong">{{labelStrong | texttransform }}</span>
        <app-jj-icons *ngIf="info" type="info" [width]="16" [height]="16" [tippy]="info | texttransform" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
    </div>

    <div class="barLeadscore">
        <div class="barLeadscore__placeholder" [ngClass]="{
            accountHeaderBar: accountHeader 
        }">
            <div *ngFor="let score of scoreArray" [ngClass]=" getValue(score.range) ?  score.class : 'inactive'"></div>
        </div>
        <span [ngClass]="getNumberColor()">{{ roundUp(value) }}</span>       
    </div>
</div>