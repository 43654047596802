export class Permission {
  permissionId: number;
  permission: string;

  constructor(values: Object = {}) {
    this.permissionId =  values['id'];
    // this.permission =  'Can' + values['name']['tokenName'];
    this.permission =  'Can' + values['defaultName'];
  }
}
