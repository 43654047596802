import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { ApplicationInfoService, VariableType } from 'app/core/application/application-info.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-custom-register',
  templateUrl: './custom-register.component.html',
  styleUrls: ['./custom-register.component.scss']
})
export class CustomRegisterComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  updateSubControlAttributesEventSubscription: Subscription = new Subscription();
  updateSubControlContentEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  eventServiceSubscription: Subscription = new Subscription();
  visibleTabs: any[] = [];
  selectedTabIndex = 0;
  innerHTMLs = [];
  
  constructor(
    private injector: Injector,
    private domSanitizer: DomSanitizer,
    public applicationInfoService: ApplicationInfoService,
  ) {
    super(injector);
    this.valueType = ValueType.List;
  }

  ngOnInit() {
    if (
      this.controlDefinition.logicalControlId == 29020 ||
      this.controlDefinition.logicalControlId == 29811 ||
      this.controlDefinition.logicalControlId == 29019
    ) {
      const accountOverviewControl = this.applicationInfoService.controls.Item('253831');
      if (!this.commonService.checkIfItemIsInArray(this.controlDefinition.childs, accountOverviewControl)) {
        this.controlDefinition.childs.unshift(accountOverviewControl);
      }      
    }

    this.getVisibleChilds();
    this.checkAttributes();
    this.updateSubControlAttributesEventSubscription = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.getVisibleChilds();
    });
    this.eventServiceSubscription = this.eventService.activateTabEvent.subscribe(event => {
      if (this.controlDefinition.logicalControlId === Number(event.target)) {
        this.selectedTabIndex = Number(event.arguments[0]);
      }
    });
    this.updateSubControlContentEventSubscription = this.eventService.updateControlContentEvent.subscribe(event => {
      if (this.controlDefinition.logicalControlId === Number(event.target)) {
        this.getVisibleChilds();
      }
    });
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'updateRegisterControl' && event.value.id == this.controlDefinition.id) {
        this.getVisibleChilds();
      }
    });

    if (this.applicationInfoService.mainTabId != null) {
      this.selectedTabIndex = this.applicationInfoService.mainTabId;
      this.applicationInfoService.mainTabId = null;
    }
  }


  changeHeaderCardsvisisbility(){
    this.applicationInfoService.isCardsVisible = true;
    this.applicationInfoService.isHeaderVisible= true;
  }


  getSubContainerWidth(child: any) {
    return { width: child.width };
  }

  getVisibleChilds() {
    this.visibleTabs = [];
    this.innerHTMLs = [];
    this.selectedTabIndex = 0;

    // console.log('this control definition', this.controlDefinition);
    this.controlDefinition.childs.forEach(element => {
      let addChild = false;
      if (element.value !== null && element.value !== '') {
        this.innerHTMLs[element.id] = this.getInnerHTML(element.value);
      }
      // console.warn(element, this.uiService.checkAttributeChanges('visible', element, true, VariableType.Boolean));
      if (this.uiService.checkAttributeChanges('visible', element, true, VariableType.Boolean) === true && element.isActive) {
        if (this.selectedTabIndex === 0) { this.selectedTabIndex = element.logicalControlId; }
        addChild = true;
       
      } else if (element.additionalSetting1 == 'developer' && this.applicationInfoService.isDeveloper) {
        addChild = true;
      }
      if (!this.uiService.checkViewModeVisibility(element)) {
        addChild = false;
      }
  
      if (this.controlService.checkIfControlIsVisible(element) == false) {
        addChild = false;
      }
      if (element.value == '<jj-leadscoringdetail></jj-leadscoringdetail>') {
        addChild = false;
      }
      if (addChild) {
        this.visibleTabs.push(element);
      }
    });
  }


  setCurrentChild(id) {
    this.selectedTabIndex = Number(id);
    this.showTab();
    this.applicationInfoService.resetHeaderCardsPosition();
  }

  getRegisterId() {
    return 'bottomRegister'.concat(this.controlDefinition.id);
  }

  getMyId() {
    return 'topRegister'.concat(this.controlDefinition.id);
  }

  getInnerHTML(value) {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

  onChangeMobileTab(logicalControlId){
    this.setCurrentChild(logicalControlId);
  }

  showTab() {
    if (!this.commonService.isNullOrUndefined(this.controlDefinition.additionalSetting3) && this.controlDefinition.additionalSetting3 != '') {
      return;
    }
    if (this.applicationInfoService.mainViewMaximixed) { return; }
    
    // const element = document.getElementById(this.getMyId());
    // if (!this.commonService.isNullOrUndefined(element) && !this.applicationInfoService.isMobile()) {
    //   element.scrollIntoView(true);
    // }
  }


  getMaximumHeightOfSubContainer(elementId, substractValue = 20) {
    let calculatedHeight = Number(this.uiService.getDesignSpanPositionMobile(elementId, substractValue));
    return calculatedHeight;
  }


  getWrapperHeight(wrapperId, substractValue = 24) {
    return this.uiService.getDesignSpanPosition(wrapperId, substractValue) + 'px';
  }




  ngOnDestroy() {
    if (this.updateSubControlAttributesEventSubscription) { this.updateSubControlAttributesEventSubscription.unsubscribe(); }
    if (this.eventServiceSubscription) { this.eventServiceSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }    
    if (this.updateSubControlContentEventSubscription) { this.updateSubControlContentEventSubscription.unsubscribe(); }        
  }
}
