const designerIconsArray= [
    {name: 'binoculars', component:'WEBTRACKING'},
    {name: 'target', component:'WEBTRACKING'},
    {name : 'laptop-user', component: 'WEBTRACKING'},
    {name: 'bell-ringing', component: 'WEBTRACKING'},
    {name:'puzzle-piece', componenet:'LEADMANAGMENT'},
    {name: 'hand-key', component:'LEADMANAGMENT' },
    {name: 'hand-heart', compoment:'LEADMANAGMENT'},
    {name:'address-book', component:'LEADMANAGMENT'},
    {name:'calendar-heart', component:'LEADMANAGMENT'},
    {name:'calendar', componenet: 'LEADMANAGMENT'},
    {name: 'clock-user', component:'LEADMANAGMENT'},
    {name:'clock-clockwise', component:'LEADMANAGMENT'},
    {name:'mailbox', component:'LEADMANAGMENT'},
    {name:'megaphone', component:'KAMPAGNEN'},
    {name:'megaphone-window', component:'KAMPAGNEN'},
    {name:'layout', component:'KAMPAGNEN'},
    {name:'paper-plane-tilt', component:'KAMPAGNEN'},
    {name:'check-square', component:'KAMPAGNEN'},
    {name:'book-open-text', component:'KAMPAGNEN'},
    {name: 'database', component: 'DATEN' },
    {name:'list-checks', component:'DATEN'},
    {name:'mailbox', component:'DATEN'},
    {name:'database-arrow', component:'DATEN'},
    {name:'eye', component:'DATEN'},
    {name:'clipboard-text', component:'DATEN'},
    {name:'tree-structure', component:'DATEN'},
    {name:'gear-six', component:'PROJECTINSTELLUNGEN'},
    {name:'database', componenet:'DATENMANAGMENT'},
    {name: 'envelope', component:'EMAIL'},
    {name:'article', component:'EMAIL'},
    {name:'article-ny-times', component:'EMAIL'},
    {name:'mailbox-gear', component:'EMAIL'},
    {name:'user-gear', component:'BENUTZERMANAGMENT'},
    {name:'identification-card', component:'BENUTZERMANAGMENT'},
    {name:'user-list', component:'BENUTZERMANAGMENT'},
    {name:'users-four', component:'BENUTZERMANAGMENT'},
    {name:'stack' , component:'BENUTZERMANAGMENT'},
    {name:'user-gear', component:'BENUTZERMANAGMENT' },
    {name:'user-check',component:'BENUTZERMANAGMENT' },
    {name:'users-three', component:'BENUTZERMANAGMENT'},
    {name:'file', component:'DOKUMENTATION'},
    {name:'plugs', component:'DOKUMENTATION'},
    {name:'browsers', component:'DOKUMENTATION'},
    {name:'shapes', component:'VERSCHIEDENES'},
    {name:'cloud-check', component:'VERSCHIEDENES'},
    {name:'file-magnifying-glass', component:'VERSCHIEDENES'},
    {name:'puzzle-gear', component:'VERSCHIEDENES'},
    {name:'clock-countdown', component:'VERSCHIEDENES'},
    {name:'package', component:'VERSCHIEDENES'},
    {name:'grid-nine', component:'VERSCHIEDENES'},
    {name:'palette', component:'VERSCHIEDENES'},
    {name:'identification-badge', component:'VERSCHIEDENES'},
    {name: 'user-sound', component:'VERSCHIEDENES'},].sort((a,b)  => a.name > b.name ? 1 : - 1);


    const  allIconsLibrary= [
        {
          "name": "envelope"
        },
        {
          "name": "envelope-open"
        },
        {
          "name": "user"
        },
        {
          "name": "arrows-counter-clockwise"
        },
        {
          "name": "plus"
        },
        {
          "name": "plus-bold"
        },
        {
          "name": "navigation-arrow"
        },
        {
          "name": "caret-down"
        },
        {
          "name": "caret-up"
        },
        {
          "name": "caret-left"
        },
        {
          "name": "caret-right"
        },
        {
          "name": "caret-circle-right"
        },
        {
          "name": "dots-three-outline-fill"
        },
        {
          "name": "caret-circle-right-fill"
        },
        {
          "name": "sliders"
        },
        {
          "name": "list"
        },
        {
          "name": "arrow-left"
        },
        {
          "name": "arrow-down"
        },
        {
          "name": "arrow-up"
        },
        {
          "name": "arrow-right"
        },
        {
          "name": "binoculars"
        },
        {
          "name": "chart-bar"
        },
        {
          "name": "chart-bar-horizontal"
        },
        {
          "name": "chart-bar-horizontal-fill"
        },
        {
          "name": "gear"
        },
        {
          "name": "gear-six"
        },
        {
          "name": "megaphone"
        },
        {
          "name": "puzzle-piece"
        },
        {
          "name": "x"
        },
        {
          "name": "building"
        },
        {
          "name": "magnifying-glass"
        },
        {
          "name": "export"
        },
        {
          "name": "arrow-square-out"
        },
        {
          "name": "editor"
        },
        {
          "name": "browser"
        },
        {
          "name": "globe"
        },
        {
          "name": "lock-simple"
        },
        {
          "name": "lock-simple-fill"
        },
        {
          "name": "lock-simple-open"
        },
        {
          "name": "info"
        },
        {
          "name": "info-fill"
        },
        {
          "name": "info-bold"
        },
        {
          "name": "phone"
        },
        {
          "name": "pencil-simple"
        },
        {
          "name": "pencil-simple-fill"
        },
        {
          "name": "pencil-simple-line"
        },
        {
          "name": "warning"
        },
        {
          "name": "warning-bold"
        },
        {
          "name": "note-pencil"
        },
        {
          "name": "check"
        },
        {
          "name": "square"
        },
        {
          "name": "check-square-offset"
        },
        {
          "name": "check-square"
        },
        {
          "name": "circle"
        },
        {
          "name": "radio-button-fill"
        },
        {
          "name": "magic-wand"
        },
        {
          "name": "trash"
        },
        {
          "name": "tray-arrow-down"
        },
        {
          "name": "dot"
        },
        {
          "name": "arrows-in-simple"
        },
        {
          "name": "rocket"
        },
        {
          "name": "calendar-dots"
        },
        {
          "name": "calendar-check"
        },
        {
          "name": "frame-corners"
        },
        {
          "name": "image"
        },
        {
          "name": "table"
        },
        {
          "name": "arrow-up-right"
        },
        {
          "name": "circle-fill"
        },
        {
          "name": "arrow-circle-right-fill"
        },
        {
          "name": "note"
        },
        {
          "name": "headset"
        },
        {
          "name": "minus"
        },
        {
          "name": "phone-call"
        },
        {
          "name": "phone-incoming"
        },
        {
          "name": "phone-outgoing"
        },
        {
          "name": "phone-pause"
        },
        {
          "name": "phone-transfer"
        },
        {
          "name": "phone-x"
        },
        {
          "name": "phone-disconnect"
        },
        {
          "name": "question"
        },
        {
          "name": "bell-ringing"
        },
        {
          "name": "bell-slash"
        },
        {
          "name": "toggle-left"
        },
        {
          "name": "toggle-right-fill"
        },
        {
          "name": "toggle-left-fill"
        },
        {
          "name": "pencil-ruler"
        },
        {
          "name": "sign-in"
        },
        {
          "name": "sign-out"
        },
        {
          "name": "plus-circle"
        },
        {
          "name": "user-plus"
        },
        {
          "name": "chart-bar-thin"
        },
        {
          "name": "users"
        },
        {
          "name": "users-four"
        },
        {
          "name": "clock"
        },
        {
          "name": "mouse-simple"
        },
        {
          "name": "arrows-out"
        },
        {
          "name": "eye"
        },
        {
          "name": "eye-slash"
        },
        {
          "name": "floppy-disk"
        },
        {
          "name": "funnel"
        },
        {
          "name": "user-check"
        },
        {
          "name": "paper-plane-tilt"
        },
        {
          "name": "house-line"
        },
        {
          "name": "head-circuit"
        },
        {
          "name": "bug-beetle"
        },
        {
          "name": "tree-structure"
        },
        {
          "name": "flow-arrow"
        },
        {
          "name": "tree-view"
        },
        {
          "name": "file"
        },
        {
          "name": "seal-check"
        },
        {
          "name": "x-circle"
        },
        {
          "name": "download-simple"
        },
        {
          "name": "file-dashed"
        },
        {
          "name": "list-numbers"
        },
        {
          "name": "sort-descending"
        },
        {
          "name": "text-aa"
        },
        {
          "name": "user-list"
        },
        {
          "name": "list-checks"
        },
        {
          "name": "key-return"
        },
        {
          "name": "file-xls"
        },
        {
          "name": "copy"
        },
        {
          "name": "prohibit"
        },
        {
          "name": "receipt-x"
        },
        {
          "name": "stop"
        },
        {
          "name": "stop-fill"
        },
        {
          "name": "pause"
        },
        {
          "name": "pause-fill"
        },
        {
          "name": "play"
        },
        {
          "name": "play-fill"
        },
        {
          "name": "play-pause"
        },
        {
          "name": "play-pause-fill"
        },
        {
          "name": "cursor"
        },
        {
          "name": "seal-check-fill"
        },
        {
          "name": "layout"
        },
        {
          "name": "sliders-horizontal"
        },
        {
          "name": "chart-line-up"
        },
        {
          "name": "chart-line"
        },
        {
          "name": "pulse"
        },
        {
          "name": "ranking"
        },
        {
          "name": "address-book-tabs"
        },
        {
          "name": "folder-simple-plus"
        },
        {
          "name": "folder"
        },
        {
          "name": "folder-simple-fill"
        },
        {
          "name": "folder-open"
        },
        {
          "name": "folder-simple-lock-fill"
        },
        {
          "name": "folder-simple-lock"
        },
        {
          "name": "arrow-circle-left"
        },
        {
          "name": "arrow-circle-left-fill"
        },
        {
          "name": "file-doc|| type"
        },
        {
          "name": "file-pdf"
        },
        {
          "name": "file-pptx"
        },
        {
          "name": "file-txt"
        },
        {
          "name": "file-xlsx"
        },
        {
          "name": "file-csv"
        },
        {
          "name": "file-arrow-down-fill"
        },
        {
          "name": "file-arrow-down"
        },
        {
          "name": "file-arrow-up"
        },
        {
          "name": "file-arrow-up-fill"
        },
        {
          "name": "arrow-circle-right"
        },
        {
          "name": "tray"
        },
        {
          "name": "newspaper-clipping"
        },
        {
          "name": "crosshair"
        },
        {
          "name": "trend-down"
        },
        {
          "name": "trend-up"
        },
        {
          "name": "plug"
        },
        {
          "name": "link-simple"
        },
        {
          "name": "paperclip"
        },
        {
          "name": "database"
        },
        {
          "name": "memory"
        },
        {
          "name": "user-circle-dashed"
        },
        {
          "name": "qr-code"
        },
        {
          "name": "shield-star"
        },
        {
          "name": "book"
        },
        {
          "name": "kanban"
        },
        {
          "name": "flag"
        },
        {
          "name": "list-magnifying-glass"
        },
        {
          "name": "list-star"
        },
        {
          "name": "list-bullets"
        },
        {
          "name": "palette"
        },
        {
          "name": "hand-grabbing"
        },
        {
          "name": "cloud-arrow-up"
        },
        {
          "name": "cloud-slash"
        },
        {
          "name": "flask"
        },
        {
          "name": "microscope"
        },
        {
          "name": "test-tube"
        },
        {
          "name": "swap"
        },
        {
          "name": "thumbs-up"
        },
        {
          "name": "thumbs-down"
        },
        {
          "name": "mailbox"
        },
        {
          "name": "chat-dots"
        },
        {
          "name": "equals"
        },
        {
          "name": "package"
        },
        {
          "name": "identification-card"
        },
        {
          "name": "user-minus"
        },
        {
          "name": "star"
        },
        {
          "name": "sort-ascending"
        },
        {
          "name": "stack-plus"
        },
        {
          "name": "wifi-high"
        },
        {
          "name": "coins"
        },
        {
          "name": "gender-female"
        },
        {
          "name": "gender-male"
        },
        {
          "name": "cpu"
        },
        {
          "name": "triangle-dashed"
        },
        {
          "name": "recycle"
        },
        {
          "name": "shield-check"
        },
        {
          "name": "notepad"
        },
        {
          "name": "crown"
        },
        {
          "name": "caret-up-fill"
        },
        {
          "name": "map-pin"
        },
        {
          "name": "caret-down-bold"
        },
        {
          "name": "building-apartment"
        },
        {
          "name": "hand-heart"
        },
        {
          "name": "calendar-heart"
        },
        {
          "name": "clock-user"
        },
        {
          "name": "clock-clockwise"
        },
        {
          "name": "book-open-text"
        },
        {
          "name": "database-arrow"
        },
        {
          "name": "hand-key"
        },
        {
          "name": "mailbox-gear"
        },
        {
          "name": "megaphone-window"
        },
        {
          "name": "clipboard-text"
        },
        {
          "name": "laptop-user"
        },
        {
          "name": "map-pin-everlead"
        },
        {
          "name": "article"
        },
        {
          "name": "article-ny-times"
        },
        {
          "name": "user-gear"
        },
        {
          "name": "users-three"
        },
        {
          "name": "plugs"
        },
        {
          "name": "timer"
        },
        {
          "name": "browsers"
        },
        {
          "name": "shapes"
        },
        {
          "name": "file-magnifying-glass"
        },
        {
          "name": "cloud-check"
        },
        {
          "name": "puzzle-gear"
        },
        {
          "name": "clock-countdown"
        },
        {
          "name": "grid-nine"
        },
        {
          "name": "identification-badge"
        },
        {
          "name": "user-sound"
        },
        {
          "name": "target"
        },
        {
          "name": "calendar"
        },
        {
          "name": "address-book"
        },
        {
          "name": "stack"
        }
      ].map((item) =>{
        return{...item, type:'svg' }
      }).sort((a,b)  => a.name > b.name ? 1 : - 1);



    const librarySVG = [
        {
            type:0, 
            svgLibrary: designerIconsArray.map((item) =>
        { return {...item, type:'svg' } }) },
        
            { type: 1 , svgLibrary: allIconsLibrary.map((item) =>
            { return {...item, type:'svg' } }) } ]

    export { designerIconsArray, allIconsLibrary, librarySVG ,}

