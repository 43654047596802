import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';

import { Subscription } from 'rxjs';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnDestroy, OnChanges {
  
  constructor(
    private commonService: CommonService, 
    private axivasTransateService: AxivasTranslateService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService
  ){}
  
  
  customEventSubscription: Subscription = new Subscription();
  @Input() colors: string[]=['#5465FF', '#788BFF','#9BB1FF', '#BFD7FF', '#E2FDFF', '#3EA4C3'];
  @Input() chartData: any=[];
  @Input() height: any;
  @Input() width: any;
  @Input() toolboxInfo: boolean = false;
  @Input() xAxis: boolean = false;
  @Input() yAxis: boolean = true;
  @Input() component: string | null= null;
  @Input() controlid: string | number = null;
  @Input() barHeader: string | number = null;
  // Consent
  @Input() consentRef: number = 1;
  @Input() type : any = '';

  options:EChartsOption={};
  dataAvailable 
  showInfo 

  chart:any | null

  asterisk=[]
  
  ngOnInit(): void {
    this.customEventSubscription =this.eventService.customEvent.subscribe((event) =>{
    })

    if(this.controlid === 'dataclosed'){
      this.createReportProjectOverviewChart()
     }

     if(this.controlid === 'dataprogress'){
      this.createReportProjectOverviewChart()
     }

     if(this.component === 'contact_Objections'){
      this.createReportProjectOverviewContact();
     }

     if(this.component === 'accountOwner'){
      this.createAccountOwnerChart();
     }
     
     if(this.component === 'questionnaireDashoard'){
      this.createQuestionnaireDashboardChart();
     }
     

     if(this.component === 'mailshotKPIChart'){
      this.createMailshotKPIChart();
     }

     if(this.component === 'agentwvl'){
      this.createAgentWVLChart();
     }

     if(this.component === 'agentwvloverall'){
      this.createAgentWVLChartOverall();
     }

     if(this.component === 'dashboard_activity'){
      this.createReportDashboardActivity()
     }

     if(this.component === 'consentDashboardBar'){
      this.createReportDashboardConsent();
     }

     if(this.component === 'utmSourcesPerCampaign'){
      this.utmSourcesPerCampaign();
     }

     if(this.component === 'utmSourcesPerCampaignOverview'){
      this.utmSourcesPerCampaignOverview();
     }

     if(this.component === 'campaignDashboard'){
      if(this.controlid === 'mailcampaign'){
        this.createMailCampaignChart()
      }

      if(this.controlid === 'microcampaign'){
        this.createMicroCampaignChart()
      }      
     }

     if(this.component === 'entityTable'){
      if(this.controlid === 'prospects'){
        this.createProspectChart()
      }
     }

     if(this.controlid === 'webleads+webvisitors'){
      this.createWebleadsVisitors();
     }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['chartData'] && !changes['chartData'].firstChange) {
       this.chartData = changes['chartData'].currentValue
       if(this.controlid === 'dataclosed'){
        this.createReportProjectOverviewChart()
       }

       if(this.component === 'agentwvloverall'){
        this.createAgentWVLChartOverall();
       }

       if(this.controlid === 'dataprogress'){
        this.createReportProjectOverviewChart()
       }

       if(this.component === 'contact_Objections'){
        this.createReportProjectOverviewContact();
       }

       if(this.component === 'agentwvl'){
        this.createAgentWVLChart();
       }
       
       if(this.component === 'dashboard_activity'){
        this.createReportDashboardActivity()
       }

       if(this.component === 'campaignDashboard'){
        if(this.controlid === 'mailcampaign'){
          this.createMailCampaignChart()
        } 
        if(this.controlid === 'microcampaign'){
          this.createMicroCampaignChart()
        }
        
       }

       if(this.component === 'entityTable'){
        if(this.controlid === 'prospects'){
          this.createProspectChart();
        }
       }

       if(this.controlid === 'webleads+visitors'){
        this.createWebleadsVisitors()
       }

  /*     if(this.component === 'consentDashboardBar'){
         this.createReportDashboardConsent();
        }  */
    } 
  }


  createWebleadsVisitors() {
    const colorsArray = ['#C94506', '#FB5607','#FC7839', '#FD9A6A', '#FDBB9C', '#FECCB5', '#FEDDCD', '#FFEEE6']


    const yAxisLabel ={
      name: this.axivasTransateService.getTranslationTextForToken('WebsiteDashboard.Label.MyWebleads'),
      nameLocation:'middle',

    }
    const type = this.type === 'myvisitors' ? 'WebsiteDashboard.Label.MyVisitors' : 'WebsiteDashboard.Label.MyWebleads'

/*     Grid before type addition
    left:  0,
    right: 5, */

    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
    xAxis:{
        show:false, 
        type:'category',
        data:this.chartData.map((item) => this.axivasTransateService.getTranslationTextForToken(item.source)),
        axisTick:{
          show:true,
        },
        axisLine:{
          show:true,
        },
        axisLabel:{
          show:true,
          color:'transparent'
          },
        },
    yAxis:{ 
        show:true,
        type:'value',
        name: this.axivasTransateService.getTranslationTextForToken(type),
        nameLocation: 'middle',
        nameTextStyle:{
          fontSize:11,
          fontFamily:'Poppins'
        },
        splitLine:{
          show:true,
        },
        axisLabel:{
          show:false,
          color:'#788BFF',
        },
        axisTick:{
          show:false,
        },
      },
    grid:{
      left: 30,
      right:5,
      bottom:0,
      top:50,
      containLabel: false
      },
      series:[{
        type:'bar',
        barMaxWidth:100,
        emphasis: {
          focus: 'series'
        },
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: this.axivasTransateService.getTranslationTextForToken(bar.source),
          value: bar.count ,
          itemStyle:{
            color:colorsArray[index],
          },
          label: {
            show: false,
            position: 'bottom',
            align:'center',
            distance: 5,
            rotate: 0,
            fontSize: 14,
            color: 'black',
            padding:[10,10],
            formatter:[
              '{value| {c} }',
              '{title| {b} }'
            ].join('\n'),
  
            rich: {
              space:{},
              value:{ fontSize:12, fontWeight:700, padding:[2,2]},
              title: { fontSize:12, fontWeight:300 , padding:[2,2]},
            },
          },
         }
        }),
        }]

  }
  }


  // todo
  createMicroCampaignChart(){}



  createProspectChart(){
    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
    xAxis:{
        show:true, 
        type:'value',
        axisTick:{
          show:false,
        },
        axisLine:{
          show:false,
        },
        axisLabel:{
          show:true,
        }
      },

    yAxis:{ 
        show:true,
        type:'category',
        data: this.chartData.map((item) => item.companyname).reverse(),
        axisLabel:{
          show:true,
          color:'#788BFF',
        },
        axisTick:{
          show:false,
        },
      },
      grid:{
        top:10,
        bottom:10,
        left:0,
        containLabel: true
      },
      series:[{
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: bar.companyname,
          type:'bar',
          value: Math.floor(bar?.leadscore * 10) / 10 ,
          itemStyle:{
            color:bar?.color ? bar.color : '#5465FF',
            //color: this.colors.length > 0 ? this.colors[index] : '#5162F6'
          }
         }
        }).reverse(),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'right',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: 'black',
            rich: {
              name: {}
            },
          },
        }]

  }

  }

  utmSourcesPerCampaign() {
    const labelOption = {
      show: true,
      formatter: function(param) { return param.data == 0 ? '': ''; }, fontSize: 14, rich: { name: {}}
    };

    let yAxis = [];
    yAxis = [...new Set(this.chartData.map(item => item.source))];
    const barValues = [...new Set(this.chartData.map(item => item.content))];

    const series = [];
    barValues.forEach((content, index) => {
      const item = { 
        name: content, 
        type: 'bar', 
        label: labelOption,
        itemStyle:{ color: this.colors[index]},
        emphasis: { focus: 'series' },
        data: []
      }
      yAxis.forEach(axis => {        
        item.data.push(this.chartData.filter(item => item.content == content && item.source == axis).length);
      });
      series.push(item);
    });
    
    this.options = {
      title: { text: this.axivasTransateService.getTranslationTextForToken(this.barHeader) },
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: { type: 'scroll', top: 30, left: 'left' },
      grid: { top: 80, left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis: { type: 'value', boundaryGap: [0, 0.01] },
      yAxis: { 
        show: true,
        type:'category',
        data: yAxis,
        axisLabel:{
          formatter: (value:string) =>{
            if(this.applicationInfoService.isMobile()){
              return value.split(' ').join('\n')
            } else {
              return value
            }
          }
        }
      },
      series: series
    };
  }

  utmSourcesPerCampaignOverview() {
    const labelOption = {
      show: true,
      formatter: function(param) { return param.data == 0 ? '': ''; }, fontSize: 14, rich: { name: {}}
    };

    let yAxis = [];
    yAxis = [...new Set(this.chartData.map(item => item.source))];
    const barValues = [...new Set(this.chartData.map(item => item.campaign))];

    const series = [];
    barValues.forEach((campaign, index) => {
      const item = { 
        name: campaign, 
        type: 'bar', 
        label: labelOption,
        itemStyle:{ color: this.colors[index]},
        emphasis: { focus: 'series' },
        data: []
      }
      yAxis.forEach(axis => {        
        item.data.push(this.chartData.filter(item => item.campaign == campaign && item.source == axis).length);
      });
      series.push(item);
    });
    
    this.options = {
      title: { text: this.axivasTransateService.getTranslationTextForToken(this.barHeader) },
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: { type: 'scroll', top: 30, left: 'left' },
      grid: { top: 80, left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis: { type: 'value', boundaryGap: [0, 0.01] },
      yAxis: { 
        show: true,
        type:'category',
        data: yAxis,
        axisLabel:{
          formatter: (value:string) =>{
            if(this.applicationInfoService.isMobile()){
              return value.split(' ').join('\n')
            } else {
              return value
            }
          }
        }
      },
      series: series
    };
  }

  createMailCampaignChart(){

    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
    xAxis:{
        show:true, 
        type:'category',
        axisTick:{
          show:false,
        },
        axisLine:{
          show:false,
        },
        axisLabel:{
          show:false,
        }
      },

    yAxis:{ 
        show:true,
        type:'value',
        axisLabel:{
          show:false,
        }
      },
      grid:{
        top:0,
        left:0,
        right:0,
        bottom:0,
      },
      series:[{
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: this.axivasTransateService.getTranslationTextForToken(bar?.name),
          type:'bar',
          value: bar?.value,
          itemStyle:{
            color:bar?.color ? bar.color : '#5465FF',
            //color: this.colors.length > 0 ? this.colors[index] : '#5162F6'
          }
         }
        }),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: 'black',
            rich: {
              name: {}
            },
          },
        }]

  }
  }

  createAgentWVLChartGetData(section, arraValue) {
    let dataArray = []; 
    this.chartData.forEach(dataItem => {
      dataArray.push(dataItem[arraValue]);
    })    
    let returnValue = { 
      name: section, 
      type: 'bar', 
      stack: 'total',
      label: { show: true },
      emphasis: { focus: 'series' },
      data: dataArray
    }
    return returnValue;
  }

  createAgentWVLChart() {  
    const dataSeries = [];    
    let yAxisData = [];    
    this.chartData.forEach(dataItem => {
      yAxisData.push(dataItem.user_name);
    });
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.Past'), 'past'));     
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.Today'), 'today'));         
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.NearFuture'), 'near_future'));         
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.Future'), 'future'));         
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.FarFuture'), 'far_future'));         

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: {},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ 
        show: true,  
        type: 'value',
      },
      yAxis:{ 
        show: true, 
        type: 'category', 
        data: yAxisData,
        axisLabel:{ 
          formatter: ( value:string): any => {
            if(this.applicationInfoService.isMobile()){
              return value.split(' ').join('\n')
            } else {
              return value
            }
          }
        }
      },
      series: dataSeries.map((item, index) => {
        return {
          ...item,
          itemStyle:{ color: this.colors[index] }
        }
      })
    }
  }

  createAccountOwnerChart() {  
    let yAxisData = [];     
    const dataSeriesValue = [];
    this.chartData.forEach(dataItem => {
      yAxisData.push(this.axivasTransateService.getTranslationTextForToken(dataItem.user_name));
      dataSeriesValue.push(dataItem.cnt);
    });
    
    const dataSeries = [];     
    dataSeries.push({ 
      name: this.axivasTransateService.getTranslationTextForToken(this.barHeader), 
      type: 'bar', 
      stack: 'total',
      label: { show: true },
      itemStyle:{ color:'#5465FF' },
      emphasis: { focus: 'series' },
      data: dataSeriesValue
    })     

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ 
        show: true, 
        type: 'category', 
        data: yAxisData,
        axisLabel:{
          formatter: (value:string) => {
            if(this.applicationInfoService.isMobile()){
              return value.split(' ').join('\n')
            } else {
              return value
            }            
          },
        }
      },
      series: dataSeries
    }
  }

  createQuestionnaireDashboardChart() {  
    let yAxisData = [];     
    const dataSeriesValue = [];
    this.chartData.forEach(dataItem => {
      yAxisData.push(this.axivasTransateService.getTranslationTextForToken(dataItem.answer));
      dataSeriesValue.push(dataItem.count);
    });
    
    const dataSeries = [];     
    dataSeries.push({ 
      name: this.axivasTransateService.getTranslationTextForToken(this.barHeader), 
      type: 'bar', 
      stack: 'total',
      label: { show: true },
      emphasis: { focus: 'series' },
      data: dataSeriesValue
    })     

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: {},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ show: true, type: 'category', data: yAxisData},
      series: dataSeries
    }
  }

  createMailshotKPIChart() {  
    let yAxisData = [];     
    const dataSeriesValue = [];
    this.chartData.forEach(dataItem => {
      yAxisData.push(this.axivasTransateService.getTranslationTextForToken(dataItem.translation));
      dataSeriesValue.push(dataItem.count);
    });
    
    const dataSeries = [];     
    dataSeries.push({ 
      name: this.axivasTransateService.getTranslationTextForToken('Mailshots.Label.Overview'), 
      type: 'bar', 
      stack: 'total',
      itemStyle:{ color: '#5465FF' },
      label: { show: true },
      emphasis: { focus: 'series' },
      data: dataSeriesValue
    })     

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ 
        show: true, 
        type: 'category', 
        data: yAxisData,
        axisLabel:{
          formatter: (value: string) =>{
            if(this.applicationInfoService.isMobile()){
              return value.split(' ').join('\n')
            } else {
              return value
            }
          }
        }
      },
      series: dataSeries
    }
  }

  createAgentWVLChartOverall() {
    const dataSeries = [];    
    let yAxisData = [];    
    this.chartData.forEach(dataItem => {
      yAxisData.push(dataItem.user_name);
    });
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.Sum'), 'count'));     

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ 
        show: true, 
        type: 'category', 
        data: yAxisData,
        axisLabel:{ 
          formatter: ( value:string): any => {
            if(this.applicationInfoService.isMobile()){

              return value.split(' ').join('\n')
            } else {
              return value
            }
          }
        },
      },
      series: dataSeries.map((item, index) =>{
        return{
          ...item,
          itemStyle:{ color : this.colors[index] }
        }
      })
    }
  }

  createReportDashboardConsent() {
    let yAxisData = [];     
    const dataSeriesValue = [];
    this.chartData.forEach(dataItem => {
      let yName = '';
      if (this.consentRef != 1) { yName = yName.concat(dataItem.medium, ' '); }
      if (this.consentRef != 2) { yName = yName.concat(dataItem.typ, ' '); }
      if (this.consentRef != 3) { yName = yName.concat(dataItem.entry_source, ' '); }
      yAxisData.push(yName);
      dataSeriesValue.push(dataItem.count);
    });
    
    const dataSeries = [];     
    dataSeries.push({ 
      name: this.axivasTransateService.getTranslationTextForToken('Report.ProjectOverview.Anzahl'), 
      type: 'bar', 
      stack: 'total',
      itemStyle:{color:'#5465FF'},
      label: { show: true },
      emphasis: { focus: 'series' },
      data: dataSeriesValue
    })     

    this.options = {
    tooltip: {
        trigger:'axis',
        extraCssText: 'z-index: 9999;',
        confine: false,
        appendToBody: true,
        axisPointer: { type: 'shadow' }
      },
      legend: {},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ show: true, type: 'category', data: yAxisData},
      series: dataSeries
    }
  }

  createReportDashboardActivity(){
    let steps
    let series;
    let data;
    let sum;
    let results;
    this.asterisk = []

    let xAxis=undefined;

    let yAxis=undefined;


      if(this.controlid == 'allstepsActivities'){
        data = this.chartData.flat();
        results= undefined;
        steps = Array.from(new Set(data.map((item) => item.step)))
        sum = this.chartData.map((stepGroup) => this.commonService.sumArrayValue(stepGroup, 'anzahl'))
        series = [{
          type:'bar',
          data:sum,
          itemStyle:{
            color:'#5465FF',
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
        }]
      } else {
        series = undefined;
        results = this.chartData.map((item) => item.result);

        results.map((item) => {
          if(item.includes(':')){
            this.asterisk.push(item)
          }
        })


        yAxis={
          ...yAxis,
          show:true,
          type:'category',
          data: this.chartData.map((item) => item.result).reverse(),
          axisLabel:{
            color:'#808080',
            fontSize:12,
            interval:0,
            formatter: (value:string) => {
              if(this.applicationInfoService.isMobile()){
                if(value.includes(':')){
                  return value.split(':')[0] + ' *'
                } else {
                  return value.split(' ').join('\n')
                }
              } else {
                return value
              }
            },
            //rotate:25
          },
        }

        xAxis = {
          ...xAxis,
          show:true,
          type:'value',
          axisLabel:{
            interval:0,
            formatter: (value:string) => {
              
            },
          }
        }

      }

    this.options = {
      //...this.colors,
      tooltip: {
        trigger:'axis',
        extraCssText: 'z-index: 9999;',
        confine: false,
        appendToBody: true,
        axisPointer: { type: 'shadow' }
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      grid:{
        containLabel:true,
        width:'auto',
        left:15,
        right:45,
        bottom:0,
      },
      xAxis:xAxis ? xAxis : {
          show:true, 
          type:'category',
          data:steps ? steps : results,
          axisLabel:{
            color:'#808080',
            fontSize:this.applicationInfoService.isMobile() ? 10 : 12 ,
            interval:0,
            formatter: (value:string) => {
              if(this.applicationInfoService.isMobile()){
            
                  let string = value.split(' ');

                  if(string.length > 1){
  
                    return value.split(' ').join('\n')
                  } else {
                    let maxLength = 7
                    let soloString = value.split(' ')[0];
                    let innerString = soloString?.match(new RegExp(`.{1,${maxLength}}`, 'g')) || [];
                    let spaceString = innerString.join(' ')
                    return spaceString.split(' ').join('\n')
                  }

              } else {
                return value
              }
            },
          },
        },
      yAxis:yAxis ? yAxis : { 
          show:true,
          type:'value',
          axisLabel:{
            interval:0,
            formatter: (value:string) => {         
            },
          }
        },
      series: series ? series : [{
          type:'bar',
          data: this.chartData?.map((bar:any, index:any) => bar.anzahl).reverse(),
          itemStyle:{
            color:'#5465FF',
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'right',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
          barWidth:'50%',
          barMaxWidth: '50%',
        }]
    }

  }

  createReportProjectOverviewContact(){
    let steps
    let answers
    let series
    let data

    if(this.controlid == 'allsteps'){
        data = this.chartData.flat();
       steps = Array.from(new Set(data.map((item) => item.step)))
       answers = Array.from(new Set(data.map((item) => item.answer)))

       series = answers.map((answer) =>{
        return{
          name: answer,
          type:'bar',
          itemStyle:{
            color:'#5465FF',
          },
          data:steps.map((step) => {
            const item = data.find((item) => item.step === step && item.answer === answer)
            return item ? item.anzahl : null;
          }),
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
          labelLayout:{
            hideOverlap:true
          },
          tooltip:{
      
              trigger:'axis',
              extraCssText: 'z-index: 9999;',
              confine: false,
              appendToBody: true,
              axisPointer: { type: 'shadow' },
            formatter: '{a}'},
        }
       })
    } else {
      steps= undefined;
      answers = this.chartData?.map((item:any) => item?.answer)
      series = undefined;
    }

    this.options = {
      //...this.colors,
      tooltip: {
        trigger:'axis',
        extraCssText: 'z-index: 9999;',
        confine: false,
        appendToBody: true,
        axisPointer: { type: 'shadow' }
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      grid:{
        containLabel:true,
        width:'auto',
        bottom:0,
        left:15,
        right:15,
      },
    xAxis:{
        show:true, 
        type:'category',
        data:steps ? steps : answers,
        axisLabel:{
          fontSize:this.applicationInfoService.isMobile() ? 10 : 12 ,
          interval:0,
          formatter: (value:string) => {
            if(this.applicationInfoService.isMobile()){

                let string = value.split(' ');

                if(string.length > 1){
   
                  return value.split(' ').join('\n')
                } else {
                  let maxLength = 7
                  let soloString = value.split(' ')[0];
                  let innerString = soloString?.match(new RegExp(`.{1,${maxLength}}`, 'g')) || [];
                  let spaceString = innerString.join(' ')
                  return spaceString.split(' ').join('\n')
                }

            } else {
              return value
            }
          },
        }
      },
    yAxis:{ 
        show:true,
        type:'value',
      },
      labelLayout:{
        hideOverlap:true
      },
      series: series ? series : [{
          type:'bar',
          data: this.chartData?.map((bar:any, index:any) => bar.value),
          itemStyle:{
            color:'#5465FF',
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
          labelLayout:{
            hideOverlap:true
          }
        }]
    }
  };

  createReportProjectOverviewChart(){

    this.options = {
      //...this.colors,

      tooltip:{
        trigger:'axis',
        extraCssText: 'z-index: 9999;',
        confine: false,
        appendToBody: true,
        axisPointer: { type: 'shadow' }
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      grid:{
        containLabel:true,
        width:'auto',
        bottom:0,
      },
    xAxis:{
        show:true, 
        type:'value',
      },
    yAxis:{ 
        show:true,
        type:'category',
        nameTextStyle:{
          color:'#808080',
        },
        axisLabel:{
          color:'#808080',
          fontSize:12,
        },
        data:this.chartData?.map((item:any) => item.name).reverse(),
      },
      series:[{
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: this.axivasTransateService.getTranslationTextForToken(bar.name),
          type:'bar',
          value: bar.value,
          itemStyle:{
            color:bar.color,
          }
         }
        }).reverse(),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'right',
            distance: 5,
            rotate: 0,
            fontSize: 16,
            color: '#808080',
            rich: {
              name: {}
            },
          },
          barWidth:'50%',
          barMaxWidth: '50%',
        }]
    }
  };

  createChart(){

    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
    xAxis:{
        show:true, 
        type:'value',
      },

    yAxis:{ 
        show:true,
        type:'category',
        data:this.chartData?.map((item:any) => item.name).reverse(),
      },

      series:[{
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: this.axivasTransateService.getTranslationTextForToken(bar?.name),
          type:'bar',
          value: bar?.value,
          itemStyle:{
            color:bar?.color ? bar.color : '#FF5050',
            //color: this.colors.length > 0 ? this.colors[index] : '#5162F6'
          }
         }
        }).reverse(),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'right',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: 'black',
            rich: {
              name: {}
            },
          },
          barWidth:'50%',
          barMaxWidth: '50%',
        }]
    }
  };


  getHeight() {
    if (isNaN(this.height)) {
      return this.height;
    } else {
      return this.height.toString().concat('px');
    }
  };

  getWidth() {    
    if (isNaN(this.width)) {
      return '100%';
    } else {
      return this.width.toString().concat('px');
    }
  }


  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

}
