import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { GraphQLService, JJApolloClient, ApolloMethod } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { UserService } from 'app/core/authentication/services/user.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit, OnDestroy {
  @Input() exportOnly = false;

  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  filterSegments = [];
  dataFilter = null;
  dataFilterCaller = 'reportingform';
  filterCollapsed = false;
  downloadContent = [];
  ngValues: any[] = [];
  paramterExternalDataSourceResults: any[] = [];
  mode = 1;
  selectedTemplate;
  fileName = 'test';
  templates = [];
  defaultBodyTemplate = `{
    "additionalFilters": <0>,
    "userReportParameters": <1>
  }`;
  
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService,
    public axivasTranslateService: AxivasTranslateService,
    private sharedAPI: SharedAPI,
    public commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private datafilterService: DatafilterService,
    private eventService: EventService,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    public userService: UserService
  ) { }

  ngOnDestroy() {
    if (this.selectCurrentDataFilterEventSubscription) { this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }    
  }

  ngOnInit() {
    this.getAvailableTemplates();
    this.getDownloadContent();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.initReportTemplate();
      }      
    });

    this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(event => {
      if (event.target === this.dataFilterCaller) {
        this.datafilterService.getDataFilterInformation(event.arguments[0].id)
        .then(getDataFilterInformationResult => {
          this.filterSegments = [];
          if (getDataFilterInformationResult) {
            getDataFilterInformationResult.filterSegments.forEach(filterSegment => {
              this.filterSegments.push(filterSegment);
            });
            this.dataFilter = event.arguments[0].id;
          }
        });
      }
    });

    if (this.applicationInfoService.miscSettings['showReportingDownload'] == true) {
      this.mode = 2;
      this.applicationInfoService.miscSettings['showReportingDownload'] = null;
    }
  }

  executeReporting() {
    this.requestReport();
  }

  getAvailableTemplates(): Promise<any> {
    return new Promise((getAvailableLevelsResolve, getAvailableLevelsReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.CentralAPI, ApolloMethod.Query,
        this.graphqlqueryService.getQuery('reportingBaseReports'), [])
      .then(reportingBaseReportsResult => {
        this.templates = reportingBaseReportsResult.data.baseReports;        
        if (this.applicationInfoService.applicationSettings['useSplittedReportView'] == 'true') {
          if (this.exportOnly) {
            this.templates = this.templates.filter(item => item.lookupCategoryId == 10805);
          } else {
            this.templates = this.templates.filter(item => item.lookupCategoryId == 10804);
          }  
        }

        if (!this.userService.hasPermission('CanUseNonSelfServiceNaviagionItem')) {
          this.templates = this.templates.filter(item =>  item.projectId == this.applicationInfoService.projectID );
        }
        this.templates.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
        getAvailableLevelsResolve(reportingBaseReportsResult);
      })
      .catch(error => { getAvailableLevelsReject(error); });
    });
  }

  initReportTemplate(template = null) {
    if (template != null) {
      this.selectedTemplate = template;      
    }

    if (this.commonService.isNullOrUndefined(this.selectedTemplate)) {
      return;
    }
    
    this.ngValues = [];
    this.paramterExternalDataSourceResults = [];
    if (this.selectedTemplate.reportParameters != null) {
      this.selectedTemplate.reportParameters.sort((a, b) => a['order'] < b['order'] ? -1 : 1);
      this.selectedTemplate.reportParameters.forEach(reportParameter => {      
        if (!this.commonService.isNullOrUndefined(reportParameter.defaultValue)) {
          switch (reportParameter.parameterTypeLookup.defaultName) {
            case 'BOOLEAN':
              this.ngValues[reportParameter.id] = JSON.parse(reportParameter.defaultValue);
              break;
            default:
              this.ngValues[reportParameter.id] = reportParameter.defaultValue;
              break;
          }
        }
        if (!this.commonService.isNullOrUndefined(reportParameter.externalDataSource)) {
          this.externaldatasourceService.executeExternalDataSource(reportParameter.externalDataSource.id, [])
          .then(externalDataSourceResult => {
            this.paramterExternalDataSourceResults[reportParameter.externalDataSource.id] = externalDataSourceResult;
          })
          .catch(error => {
            console.error(error);
          });
        }
        if (reportParameter.defaultName == 'poolId') {
          this.ngValues[reportParameter.id] = this.applicationInfoService.poolId;
        }
      });
    }
  }

  requestReport(): Promise<any> {
    return new Promise<any>((requestReportResolve, requestReportReject) => {
      let mandatoryFieldsNotFilled = false;

      const body = [];
      if (this.selectedTemplate.reportParameters != null) {      
        this.selectedTemplate.reportParameters.forEach(reportParameter => {
          const element = document.getElementById(this.getSubControlId(reportParameter, 'label'));
          if (element) { element.style.color = 'black'; }
          if (reportParameter.isMandatory && reportParameter.isUserParameter) {
            if (this.commonService.isNullOrUndefined(this.ngValues[reportParameter.id]) || this.ngValues[reportParameter.id] === '') {
              mandatoryFieldsNotFilled = true;
              if (element) { element.style.color = 'red'; }
              console.warn('required field missing', reportParameter.defaultName);
            }
          }
          if (!this.commonService.isNullOrUndefined(this.ngValues[reportParameter.id])) {
            let value = this.ngValues[reportParameter.id];
            if (reportParameter.parameterTypeLookup.defaultName === 'date') {
                const date = new Date(value);
                value = ''.concat(
                  date.getFullYear().toString(), '-',
                  (date.getMonth() + 1).toString().padStart(2, '0'), '-',
                  date.getUTCDate().toString().padStart(2, '0')
                );
            }
            body.push({id: reportParameter.id, value: value.toString() });
          }
        });
      }

      if (mandatoryFieldsNotFilled) {
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info, '',
          this.axivasTranslateService.getTranslationTextForToken('Reporting.Message.MandatoryFieldsMissing'), false);
        requestReportReject(this.axivasTranslateService.getTranslationTextForToken('Reporting.Message.MandatoryFieldsMissing'));
      } else {
        // Finalizing the body
        let finalBody = this.defaultBodyTemplate;
        let filter = '[]';
        if (!this.commonService.isNullOrUndefined(this.dataFilter)) {
          filter = `[{ "filterId": <0>, "parameters": []}]`.replace('<0>', this.dataFilter.toString());
        }
        finalBody = finalBody.replace('<0>', filter);
        finalBody = finalBody.replace('<1>', JSON.stringify(body));
        requestReportResolve(this.requestAndDownloadReport(finalBody));
      }
    });
  }

  requestAndDownloadReport(body): Promise<any> {
    this.fileName = 'Report '.concat(this.applicationInfoService.projectName, ' ',
      this.commonService.getDateTimeString(new Date(Date.now())));
    return new Promise<any>((executeLeadReportResolve, executeLeadReportReject) => {
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info, '',
        this.axivasTranslateService.getTranslationTextForToken('Reporting.Request.Message'), false);
      this.sharedAPI.executeReporting(
        '/Report/get/<0>/<1>/?projectId=<2>',
        this.selectedTemplate.id,
        'report',
        this.applicationInfoService.projectID,
        body
      )
      .subscribe(executeReportingResult => {
        if (executeReportingResult == null) {
          this.eventService.customEvent.emit({ id: 'startReportTimer' });
        } else {
          this.commonService.downloadFile(executeReportingResult, 'application/pdf', this.fileName + '.xlsx');
          executeLeadReportResolve(executeReportingResult);  
        }
      }, (error) => {
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, '',
          this.axivasTranslateService.getTranslationTextForToken('Reporting.Message.ErrorDescription'), false);
        executeLeadReportReject(error);
      });
    });
  }

  editDataFilter() {
    this.datafilterService.setFilterEnvironment(
      this.dataFilter,
      1,
      this.dataFilterCaller,
      null,
      151
    );
    this.eventService.showJjPopup('DataFilterMainPanel.Label.SectionHeader', 'datafiltermainpanel', '80');
  }

  toggleItem(toggleName: string) {
    console.warn(toggleName, this[toggleName], this['exportTemplateCollapsed']);
    this[toggleName] = !this[toggleName];
  }

  getSubControlId(parameter: any, addition = '') {
    return 'jjReportingId'.concat(addition, parameter.id);
  }

  clearSelectedValue(valueId: any) {
    this.ngValues[valueId] = null;
  }

  getDownloadContent() {
    this.externaldatasourceService.executeExternalDataSource(951, []).then(getDownloadContentResult => this.downloadContent = getDownloadContentResult);
  }

  downloadFile(downloadContentItem) {
    this.sharedAPI.contentLibraryDownloadFile(downloadContentItem.contentLibraryFileId, downloadContentItem.contentLibraryFile.contentLibraryId)
    .subscribe(downloadFileResult => {
      this.externaldatasourceService.executeExternalDataSource(953, [downloadContentItem.id]);        
      let fileDetails = downloadContentItem.contentLibraryFile.name.split('.');
      this.commonService.downloadFile(
        downloadFileResult, 
        'application/'.concat(fileDetails[fileDetails.length-1]), 
        downloadContentItem.contentLibraryFile.name
      );
    });  
  }

  getHeader() {
    if (this.exportOnly) {
      return 'Supervisor.Label.Export'
    } else {
      return 'Reporting.Label.Header';
    }
  }

  getParamLabel(parameter) {
    if (parameter.nameTranslationToken) {
      return parameter.nameTranslationToken.tokenFullName;
    } else {
      return parameter.defaultName;
    }
  }
}
