<div class="selectedDashboard">
    <app-layout [header]="selectedReport.defaultName" [subheader]="selectedReport?.subHeader">
        <div class="selectedDashboard__headerNav">
            <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back" type="small"></app-main-button>
       </div> 
        <div class="selectedDashboard__innerBorderLayer">
            <div class="selectedDashboard__nav">
                <div class="selectedDashboard__filter">
                    <app-select-input label="Task.Label.Campaign" [(ngModel)]="selectedCampaignId" [options]="mailCampaigns"
                        display="defaultName" bind="id" (valueChange)="getMailCampaignData()"></app-select-input>
                </div>
                <div class="selectedDashboard__filterMobile">
                    <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                        <div class="selectedDashboard__filterMobile">
                            <app-select-input label="Task.Label.Campaign" [(ngModel)]="selectedCampaignId" [options]="mailCampaigns"
                            display="defaultName" bind="id" (valueChange)="getMailCampaignData()"></app-select-input>
                        </div>
                    </app-accordion>
                </div>
            </div>
             <div class="selectedDashboard__content">

                <div *ngIf="loadingData">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>
                    {{ 'General.Label.LoadingData' | texttransform }}
                </div>

                <div class="defaultDashboardMailshotKPIs" *ngIf="selectedCampaignId && !loadingData && kpis == null">
                    {{ 'General.Label.NoDataAvailable' | texttransform }}
                </div>
                
                <div class="defaultDashboardMailshotMode2" *ngIf="kpis != null && mode == 2">
                    <div class="defaultDashboardMailshotMode2Header">
                        <div class="defaultDashboardMailshotMode2Info">
                            {{ 'Mailshots.Label.EventTypeListDesc' | texttransform }}
                        </div>
                        <app-select-input label="Mailshots.Label.EventTypeList" [(ngModel)]="selectedMailshotDetailList"
                            [options]="mailshotDetailListOptions" display="defaultName" bind="id"
                            (valueChange)="changeSelectedMailshotDetailList()"></app-select-input>
                    </div>
                    <div class="defaultDashboardMailshotMode2Data" *ngIf="selectedMailshotDetailListArray != null">
                        <div *ngFor="let selectedMailshotDetailListItem of selectedMailshotDetailListArray" class="mailshotDetailItem everleadTableRow">                    
                            <div class="mailshotDetailItem__name">{{ selectedMailshotDetailListItem.first_name }} {{ selectedMailshotDetailListItem.last_name }}</div>
                            <div class="mailshotDetailItem__email">{{ selectedMailshotDetailListItem.email }} {{ selectedMailshotDetailListItem.last_name }}</div>
                            <div class="mailshotDetailItem__buttons">
                                <app-jj-icons type="eye" [width]="20" [height]="20" (click)="openContactPopup(selectedMailshotDetailListItem)"></app-jj-icons>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="defaultDashboardMailshotKPIs" *ngIf="kpis != null && mode == 1" (click)="getDashboardResults()">
                    <div class="defaultDashboardMailshotKPIs__boxes">
                        <div *ngFor="let kpiItem of kpis" [ngClass]="{
                            hideInDom: kpiItem?.event_type == 'Failure' || kpiItem?.event_type == 'Error' || kpiItem?.event_type == 'Complaint'
                        }" class="defaultDashboardMailshotKPIs__box">
                            <div class="defaultDashboardMailshotKPIs__box__percentage">{{ kpiItem.percentage }}%</div>
                            <div class="defaultDashboardMailshotKPIs__box__type">{{ kpiItem.translation | texttransform }}</div>
                        </div>
                    </div>
                    <div class="defaultDashboardMailshotKPIs__charts">
                        <div class="defaultDashboardMailshotKPIs__chart defaultDashboardMailshotKPIs__chartArrange"
                            *ngIf="kpisChart != null">
                            <app-bar-chart [height]="300" [chartData]="kpisChart" component="mailshotKPIChart"
                                [controlid]="agentwvlChart"></app-bar-chart>
                        </div>
                        <div class="defaultDashboardMailshotKPIs__chart defaultDashboardMailshotKPIs__chartArrange"
                            *ngIf="kpisChart == null">
                            {{ 'General.Label.NoDataAvailable' | texttransform }}
                        </div>

                        <div *ngIf="deviceDataPie != null" class="defaultDashboardMailshotKPIs__chart"
                            [ngClass]="{ defaultDashboardMailshotKPIs__chartArrange: kpisChartArrange }">
                            <app-echarts class="maxWidth" id="#4567" [chart]="'pie'"
                                [title]="'Mailshots.Label.Devices' | texttransform" [chartData]="deviceDataPie"
                                [height]="175"></app-echarts>
                        </div>
                        <div *ngIf="deviceDataPie == null" class="defaultDashboardMailshotKPIs__chart"
                            [ngClass]="{ defaultDashboardMailshotKPIs__chartArrange: kpisChartArrange }">
                            {{ 'General.Label.NoDataAvailable' | texttransform }}
                        </div>
                    </div>
    
                    <!-- <div class="defaultDashboardMailshot24hr" *ngIf="eventHoursChart != null"
                        class="defaultDashboardMailshot24Hours">
                        <app-echarts class="maxWidth" id="#mailshotChart" [createChartOnLoad]="false" [height]="400"
                            [options]="eventHoursChart"></app-echarts>
                    </div> -->
                </div>

             </div>
           
    </div>
    </app-layout>
</div>