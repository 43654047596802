
<div class="selectedDashboard">
    <app-layout [header]="selectedReport.defaultName" [subheader]="selectedReport?.subHeader">
        <div class="selectedDashboard__headerNav">
            <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back" type="small"></app-main-button>
        </div>
         
        <div class="selectedDashboard__innerBorderLayer">
            <div class="selectedDashboard__nav">
                <div class="selectedDashboard__filterMobile">
                    <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                       
                            <app-select-input label="Pool" [(ngModel)]="poolId" [options]="pools" display="defaultName"
                                bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                            <app-select-input label="OpportunityDashboard.Label.OpportunityTimeRange"
                                [(ngModel)]="timeRange" [options]="opportunityTimeRanges" display="defaultName" bind="id"
                                (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                            <app-date-input *ngIf="timeRange == 4" label="OptInOptOut.Label.StartDate"
                                [(ngModel)]="dateFrom" (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>
                            <app-date-input *ngIf="timeRange == 4" label="OptInOptOut.Label.EndDate" [(ngModel)]="dateUntil"
                                (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>
                       
                    </app-accordion>
                </div>
                <div class="selectedDashboard__filter">
                        
                            <app-select-input label="Pool" [(ngModel)]="poolId" [options]="pools" display="defaultName"
                                bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                            <app-select-input label="OpportunityDashboard.Label.OpportunityTimeRange"
                                [(ngModel)]="timeRange" [options]="opportunityTimeRanges" display="defaultName" bind="id"
                                (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                            <app-date-input *ngIf="timeRange == 4" label="OptInOptOut.Label.StartDate"
                                [(ngModel)]="dateFrom" (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>
                            <app-date-input *ngIf="timeRange == 4" label="OptInOptOut.Label.EndDate" [(ngModel)]="dateUntil"
                                (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>
                
                </div>

            </div>
           

            <span id="defaultDashboardOpportunity"></span>
            <div 
            [style.height.px]="getMaxHeight(9)"
            [style.max-height.px]="getMaxHeight(9)"
            class="selectedDashboard__content">
            <div class="defaultDashboardOpportunityContent">
                <div class="defaultDashboardOpportunityFunnels">
                    <app-layout header="Report.Opportunity.Volume" class="opportunityLayoutItem">
                        <div class="opportunityFunnels">
                            <app-echarts *ngIf="showFunnelData" [chart]="'funnel'" [chartData]="funnelValue"
                                controlid="opportunityValue" [legend]="false" [legendPosition]="{'bottom': 0}"
                                height="250" [sort]="'none'" [orient]="'vertical'" [gap]="1"
                                [noFunnelBackground]="false" [funnelAlign]="'center'" [max]=0
                                [funnelLabelPosition]="{'position': 'center', 'show': true }"
                                [showAsCurrency]="true"></app-echarts>
                        </div>
                    </app-layout>

                    <app-layout header="Report.Opportunity.Amount" class="opportunityLayoutItem">
                        <div class="opportunityFunnels">
                            <app-echarts *ngIf="showFunnelAmount" [chart]="'funnel'" [chartData]="funnelAmount"
                                controlid="opportunityAmount" [legend]="false" [legendPosition]="{'bottom': 0}"
                                height="250" [sort]="'none'" [orient]="'vertical'" [gap]="1"
                                [noFunnelBackground]="false" [funnelAlign]="'center'" [max]=0
                                [funnelLabelPosition]="{'position': 'center', 'show': true }"></app-echarts>
                        </div>
                    </app-layout>
                </div>
                <div class="opportunityLists">
                    <app-dashboard-list component="opportunity" [data]="tableData" controlid="opportunityList"
                        type="opportunityLists"></app-dashboard-list>
                </div>
            </div>
            </div>
        </div>
    </app-layout>
</div>