<app-layout [header]="step.defaultName">
    <div class="stepWrapper">
        <div class="stepHeader">
            <div class="stepHeader__name">
                <app-checkbox label="" [(ngModel)]="step.isActive" (valueChange)="saveStep()"></app-checkbox>
                <app-wizard-translations svgW=20, svgH=20 [array]="step" updateBaseArrayExternaldatasourceId=568 
                    [exclude]="excludeItemsStep" mode=3></app-wizard-translations>    
                <div class="stepId" *ngIf="applicationInfoService.isDeveloper">{{ step.id }}</div>
                <input [(ngModel)]="step.defaultName" (change)="saveStep()">
            </div>
            <app-jj-icons type="pencil-simple-line" [width]="20" [height]="20" (click)="showWizard()"></app-jj-icons>
        </div>
        <div class="resultListHeader" *ngIf="step.results?.length > 0">{{'ProcessDesign.Label.StepResults' | texttransform }}</div>
        <div class="resultList">
            <div *ngFor="let result of step.results" class="everleadTableRow resultRow">
                <app-process-stepresult [result]="result" [workflows]="workflows" [qmUserGroups]="qmUserGroups" 
                    *ngIf="result.isActive || showOnlyActive == false"
                    [eventTrigger]="eventTrigger" [step]="step" [resultCounts]="resultCounts" [leadStates]="leadStates"></app-process-stepresult>
            </div>    
        </div>
        <div class="newResult">
            <app-main-button type="small" svg="tree-view" (onClick)="createNewStepResult()" label="ProcessDesign.Label.CreateNewResultForThisStep"
            [tippy]="'ProcessDesign.Label.CreateNewResultForThisStep' | texttransform" [tippyOptions]="{placement:'bottom'}" class="agComponentHeader__item"></app-main-button>
        </div>

        <!-- <div class="newResult" (click)="createNewStepResult()">
            {{ 'ProcessDesign.Label.CreateNewResultForThisStep' | texttransform }}
        </div> -->
    </div>
</app-layout>