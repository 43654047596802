import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import tippy from 'tippy.js';



@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent implements OnInit, OnChanges, AfterViewInit, AfterContentInit{

  constructor(
    public commonService: CommonService,
    private elementRef: ElementRef){}
 
  
  @Input() options: any | null;
  @Input() uiType: string | null;
  @Input() type: string | null;
  @Input() value: any | null;
  @Input() svg: string  | null;
  @Input() smallFont = false;

  @Output() onClick = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<void>();

  @ViewChild('dropdownProcessState', { static: false } ) dropdownProcessState!: ElementRef;
  @ViewChild('dropdownProcessStateContent', { static: false } ) dropdownProcessStateContent!: ElementRef;
  //@ViewChild('tooltipButtonMobile', { static: false } ) tooltipButtonMobile!: ElementRef;

  @ViewChild('sortEntityProcessStateDropdown', { static: false } ) sortEntityProcessStateDropdown!: ElementRef;
  @ViewChild('sortEntityProcessStateContent', { static: false } ) sortEntityProcessStateContent!: ElementRef;


  selectedValue: any | null = {};
  tippyProcessStateInstance: any;
  tippySortProcessStateInstance: any;

  tippyInstance: any;
  isOpen: boolean= false;


  ngOnInit(): void {
    if(this.options && this.value){
      this.selectedValue = this.options.find((item) => item.id === this.value);
    }
  }


  ngAfterViewInit(): void {

    if(this.type === 'processState'){
      this.getProcessStateTippyInstance();
    }

    if(this.type === 'sortHeaderLookUpTable'){
      this.getSortHeaderLookUpTableInstance();
    }
     
  }

  ngAfterContentInit(): void {
/*     if(this.type === 'tab'){
      this.uicontentprops = {
        ...this.uicontentprops,
        arrow: false,
      }
    } */
    this.selectedValue = this.options.find((item) => item.id === this.value);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['options'] && !changes['options'].firstChange){
      this.options= changes['options'].currentValue
    }

    if(this.value && this.options.length > 0){
      this.selectedValue = this.options.find((item) => item.id === this.value);
    }
  }

  openMenu(){
    this.isOpen = !this.isOpen;
  }


  valueChanged(option){
    this.selectedValue= option;
  /*   console.log('option', option)
    console.log('type option', typeof option)
    console.log('this.selectedValue', this.selectedValue.id , typeof this.selectedValue.id) */
    this.onClick.emit(this.selectedValue.id)
    this.tippyInstance.hide();
    this.isOpen=false;
  }


  getSortHeaderLookUpTableInstance(){
    if(this.tippyInstance){
      this.tippyInstance.destroy()
    }

    this.tippyInstance = tippy(this.sortEntityProcessStateDropdown?.nativeElement,{
      content: this.sortEntityProcessStateContent?.nativeElement,
      placement:'bottom',
      trigger: 'click',
      theme:'light-border',
      touch:true,
      interactive:true,
      appendTo:document.body,
      zIndex: 9999999,
      onHide(instance) {
        this.isOpen= false;
        },
        onHidden:()=>{
            this.isOpen= false; 
        }
    })
  }

 getProcessStateTippyInstance(){

  if(this.tippyInstance){
    this.tippyInstance.destroy()
  }

  this.tippyInstance = tippy(this.dropdownProcessState?.nativeElement,{
    content: this.dropdownProcessStateContent?.nativeElement,
    placement:'bottom',
    trigger: 'click',
    theme:'light-border',
    touch:true,
    interactive:true,
    appendTo:document.body,
    zIndex: 9999999,
    onHide(instance) {
      this.isOpen= false;
      },
    onHidden:()=>{
          this.isOpen= false; 
      }
  })
 }




}
