import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EntityService, ValueType } from 'app/jollyjupiter/service/entity.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { isNull } from 'util';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { DuplicatecheckService } from 'app/jollyjupiter/service/duplicatecheck.service';

@Component({
  selector: 'app-newaccount',
  templateUrl: './newaccount.component.html',
  styleUrls: ['./newaccount.component.scss']
})
export class NewaccountComponent implements OnInit, OnDestroy {
  updateEventSubscription: Subscription = new Subscription();
  entityKeyDownEventSubscription: Subscription = new Subscription();
  newAccountContainerInnerHtmlBase = '<jj-container controluiid=<0>></jj-container>';  
  newAccountContainerListInnerHtmlBase = '<jj-container controluiid=53126></jj-container>';  
  newAccountContainerListInnerHtmlBaseProspect = '<jj-container controluiid=<0>></jj-container>';  
  newAccountContainerInnerHtml = null;
  newAccountContainerListInnerHtml = null;
  newAccountContainerListInnerHtmlProspect = null;

  newAccountEntityId = 46;
  poolId = null;
  assignContactAfterCreation = false;
  useNewAccountCreationForm = false;
  accountsSearched = false;
  useNewAccountCreationFormMatTableId = null;
  keyWords = '';
  poolSelectionBlocked = false;
  newAccountText = 'NewAccount.Label.CreateNewAccountAnyway';
  newAccountEnterText = 'NewAccount.Label.CreateNewAccountAnywayAndEnter';
  newAccountSearchText = 'NewAccount.Label.SearchAccounts';
  searchOption = true;
  prospectToConvert = null;
  page = 1;
  keywords = 'test';
  projectUsers = [];
  doublicateCheckResult = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private uiService: UiService,
    private messagingService: MessagingService,
    private methodService: MethodService,
    private eventService: EventService,
    private commonService: CommonService,
    private projectService: ProjectService,
    private entityService: EntityService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    public applicationInfoService: ApplicationInfoService,
    private duplicatecheckService: DuplicatecheckService
  ) { }

  ngOnDestroy() {
    if (this.updateEventSubscription) { this.updateEventSubscription.unsubscribe(); }
    if (this.entityKeyDownEventSubscription) { this.entityKeyDownEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    // resetting old stuff
    // this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['keywords'] = '';
    this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data = new Object();

    this.updateEventSubscription = this.eventService.updateEntityEvent.subscribe(event => {
      if (Number(event.target) === 46) {
        this.accountsSearched = false;
      }
    });
    this.entityKeyDownEventSubscription = this.eventService.entityKeyDownEvent.subscribe(event => {
      if (event === 46) {
        this.accountsSearched = false;
      }
    });

    this.newAccountContainerInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(
      this.newAccountContainerInnerHtmlBase.replace('<0>', this.applicationInfoService.applicationSettings['createNewAccountContainerId'])
    );
    
    this.newAccountContainerListInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(this.newAccountContainerListInnerHtmlBase);
    this.newAccountContainerListInnerHtmlProspect = this.domSanitizer.bypassSecurityTrustHtml(this.newAccountContainerListInnerHtmlBaseProspect
      .replace('<0>', this.applicationInfoService.applicationSettings['createNewAccountProspectEntityTable']));

    if (this.applicationInfoService.applicationSettings['useNewAccountCreationForm'] == 'true') {
      this.useNewAccountCreationForm = true;
    }
    this.useNewAccountCreationFormMatTableId = this.applicationInfoService.applicationSettings['useNewAccountCreationFormMatTableId']    
    this.poolId = this.applicationInfoService.poolId;

    if (this.applicationInfoService.blockSettingChange == true) {
      this.poolId = this.applicationInfoService.poolId;
      this.poolSelectionBlocked = true;
    }
  
    if (this.applicationInfoService.miscSettings['convertProspectAndAssignContactToIt'] == true) {
      this.assignContactAfterCreation = true;
      this.applicationInfoService.miscSettings['convertProspectAndAssignContactToIt'] = false;
    }

    // Label & Co
    if (this.applicationInfoService.applicationSettings['newAccountHidePreview'] == 'true') {
      this.searchOption =false;
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['newAccountLabel'])) {
      this.newAccountText = this.applicationInfoService.applicationSettings['newAccountLabel'];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['newAccountSearchLabel'])) {
      this.newAccountSearchText = this.applicationInfoService.applicationSettings['newAccountSearchLabel'];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['newAccountAndEnterLabel'])) {
      this.newAccountEnterText = this.applicationInfoService.applicationSettings['newAccountAndEnterLabel'];
    }    
    this.setPoolId();


    // Sonderfall Verbindung zu Prospects
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['prospectToConvert'])) {
      this.prospectToConvert = this.applicationInfoService.miscSettings['prospectToConvert'];
      this.applicationInfoService.miscSettings['prospectToConvertAttach'] = this.prospectToConvert
      this.applicationInfoService.miscSettings['prospectToConvert'] = null;
      if (this.commonService.isNullOrUndefined(this.prospectToConvert._917_companyname)) {
        this.modifyNewAccountWithProspectInfo('name1', 'companyName');
        this.modifyNewAccountWithProspectInfo('url', 'url');
        this.modifyNewAccountWithProspectInfo('city', 'city');
        this.modifyNewAccountWithProspectInfo('zipcode', 'postalcode');
        this.modifyNewAccountWithProspectInfo('phone', 'phone');
        this.modifyNewAccountWithProspectInfo('street', 'street');  
      } else {
        this.modifyNewAccountWithProspectInfo('name1', '_917_companyname');
        this.modifyNewAccountWithProspectInfo('url', '_917_url');
        this.modifyNewAccountWithProspectInfo('city', '_917_city');
        this.modifyNewAccountWithProspectInfo('zipcode', '_917_postalcode');
        this.modifyNewAccountWithProspectInfo('phone', '_917_phone');
        this.modifyNewAccountWithProspectInfo('street', '_917_street');  
        this.createKeywords();
      }
      this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['calculatedLeadStateId'] =
        Number(this.applicationInfoService.applicationSettings['prospectConversionLeadstateId']);
      this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['calculatedEditingState'] =
        Number(this.applicationInfoService.applicationSettings['prospectConversionEditingstateId']);
      this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['qualifyEditingState'] =
        Number(this.applicationInfoService.applicationSettings['prospectConversionQualificationId']);
        
    } else {
      // New account
    }

    if (this.applicationInfoService?.applicationSettings['addOwnerToNewAccount']=='true') {
      this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['ownerId'] = this.applicationInfoService.userID;      
      this.projectUsers = this.applicationInfoService.projectUsers.toArray();
      this.projectUsers.sort((a, b) => a.userName < b.userName ? -1 : 1);
    }
    
  }

  createKeywords() {
    let keyWordsValue = this.applicationInfoService.entities.Item('46').data.name1;
    if (keyWordsValue?.length > 3) {
      keyWordsValue = keyWordsValue.substr(0, 3);
    }
    
    if (this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['keywords'] != '' && 
        this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['keywords'] != null &&
        this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['keywords'] != undefined
    ) {
    } else {
      this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['keywords'] = keyWordsValue;
    }   
  }

  modifyNewAccountWithProspectInfo(target, value) {
    if (!this.commonService.isNullOrUndefined(value)) {
      this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data[target] = this.prospectToConvert[value];
    }    
  }

  setPoolId() {
    this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.poolId = Number(this.poolId);    
  }

  getMaximumHeightOfSubContainer(containerName) {
    return this.uiService.getDesignSpanPosition(containerName, 50);
  }

  createNewAccount(enterAccount: boolean = false, enrich: boolean = false) {
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.name1) ||
      this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.name1 == '') {
        this.messagingService.showDefaultWarning('', 'NewAccount.Message.EnterName')
        return;
    }
    this.loaderService.display(true);    
    this.externaldatasourceService.executeExternalDataSource(
      this.applicationInfoService.applicationSettings['createNewAccountExternalDataSourceId'],
      [
        this.commonService.getModifyArrayBody(this.applicationInfoService.entities.Item(
        this.newAccountEntityId.toString()).data, ['id', 'keywords', 'allkeywords'], [],
        this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()))
      ])
    .then(createNewAccountResult => {
      if (this.applicationInfoService.campaingnModeId != null && this.applicationInfoService.useNewDesign == true) {
 /*        console.log('Campaign mode - creating ') */
        this.externaldatasourceService.executeExternalDataSource(425, [createNewAccountResult.id]);
      }

      if (this.commonService.isNullOrUndefined(this.prospectToConvert)) {
        this.messagingService.showDefaultSuccess('', 'NewAccount.Label.CreateNewAccountSuccess', false);
        this.entityService.overwriteEntityData(this.newAccountEntityId.toString(), {});
        this.setPoolId();
        if (enterAccount) {
          this.applicationInfoService.currentContact = null;
          this.applicationInfoService.currentTask = null;
          this.entityService.clearEntityData(this.applicationInfoService.contactEntityId);
          this.entityService.clearEntityData(this.applicationInfoService.taskEntityId);
          this.projectService.getCurrentAccount(createNewAccountResult.id)
          .then(() => {
            this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'account'])
            .then(() => {
              this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
            });
          });
        }
        if (this.assignContactAfterCreation) {
          this.methodService.launchInternalMethod(InternalMethodType.AssignAccountToContact, [createNewAccountResult.id, 'loadContact'])
          .then(() => {
            this.loaderService.display(false);
            this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
          });
        } else {
          this.loaderService.display(false);
        }      
      } else {
        // Prospect - Konvertierung
        const accountId = createNewAccountResult.id;
        this.externaldatasourceService.executeExternalDataSource(291, [this.commonService.getModifyArrayBody(
          { 
            id: this.prospectToConvert.id,
            accountId: accountId,
            projectId: this.applicationInfoService.projectID
          }
        , [])]).then(() => {
          this.loaderService.display(false);
          this.createTaskAfterSignalConversion(accountId);
          this.eventService.customEvent.emit({
            id: 'updateEntityValueConnectedProspect',
            value: {
              id: this.prospectToConvert.id,
              accountId: accountId,
              street: this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['street'],
              zipcode: this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['zipcode'],
              city: this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['city'],
              name1: this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['name1'],
              myValueGuid: this.prospectToConvert.myValueGuid
            }
          });

          if (enrich == true) {
            this.applicationInfoService.miscSettings['enrichDataWithExternalInterfaceRefId'] = accountId;
            this.page = 3;
          } else {
            this.eventService.closeJjPopup();
          }          
        }).catch(error => { 
          this.loaderService.display(false);
        });
      }
      
    })
    .catch(error => {
      if (this.applicationInfoService.checkLastGraphQLErrorMessage('Maximum number of accounts has exceeded')) {
        this.messagingService.showDefaultWarning(
          'CreateAccount.Message.MaximumAmountOfAccountsReachedHeader', 
          'CreateAccount.Message.MaximumAmountOfAccountsReached'
        )
      }
      this.loaderService.display(false);
    });
  }

  createSearchOrNewAccount() { 
    if (this.applicationInfoService.applicationSettings['newDoublicateCheck'] == 'true') {
      // "Neuer Weg"
      this.duplicatecheckService.checkAccount(this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data).then(result => {        
        this.doublicateCheckResult = JSON.parse(result);
        this.doublicateCheckResult.results.sort((a, b) => a.total_similarity < b.total_similarity ? -1 : 1);
       /*  console.warn(this.doublicateCheckResult) */
        this.accountsSearched = true;
        this.page = 4;
      });      
    } else {
      // "Alter" Weg
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.keywords)) {
        this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.keywords = 
          this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.name1.substr(0, 3);
      }
      this.entityService.checkValuesForAllRequiredControls(46, true) 
      .then(() => {
        let finalRequestQuery = 'query { accountDuplicates(projectId: <projectId>, account: { <0> }, keywords: <1> mustMatchEveryKeyword: <3>) { id <2> }}';
        finalRequestQuery = this.commonService.modifyQueryStringWithApplicationInfos(finalRequestQuery);
        finalRequestQuery = finalRequestQuery.replace('<0>', this.commonService.getModifyArrayBody(this.applicationInfoService.entities.Item(
          this.newAccountEntityId.toString()).data, ['id', 'keywords', 'allkeywords', 'owner'], [], this.applicationInfoService.entities.Item(this.newAccountEntityId.toString())));
  
        this.keyWords = this.entityService.getEntityValue(46, 'keywords', 1);
        if (this.keyWords != '' && !this.commonService.isNullOrUndefined(this.keyWords)) {
          let keyWords = this.keyWords.split(' ');
          finalRequestQuery = finalRequestQuery.replace('<1>', JSON.stringify(keyWords));
        } else {
          finalRequestQuery = finalRequestQuery.replace('<1>', '[]');      
        }
        const controlFields = this.uiService.getQueryFieldsFromListControl(
          this.applicationInfoService.controls.Item(this.useNewAccountCreationFormMatTableId)
        );
        finalRequestQuery = finalRequestQuery.replace('<2>', controlFields);
        let allKeyWords = this.keyWords = this.entityService.getEntityValue(46, 'allkeywords', 1);
        if (allKeyWords == undefined) {
          allKeyWords = false;
        }
        finalRequestQuery = finalRequestQuery.replace('<3>', allKeyWords);
        this.eventService.externaldDataQueryEvent.emit(new JJEvent(this.useNewAccountCreationFormMatTableId, [finalRequestQuery]));
        this.eventService.customEvent.emit({ id: 'updateEntityTableViewByEntityId', value: 1 });
        this.accountsSearched = true;
        this.page = 2;
      });
    }      
  }

  backToPageOne() {
    this.accountsSearched = false;
    this.page = 1;
  }

  createTaskAfterSignalConversion(accountId) {
    if (this.applicationInfoService.applicationSettings['prospectQualificationTaskCreation'] == 'true') {
      const stepId = Number(this.applicationInfoService.applicationSettings['prospectQualificationTaskStepId']);
      const campaignId = Number(this.applicationInfoService.applicationSettings['prospectQualificationTaskCampaignId']);
      this.externaldatasourceService.executeExternalDataSource(270, [
        this.commonService.getModifyArrayBody({
          stepId: stepId,
          followUpUserId: this.applicationInfoService.userID,
          followUpDate: new Date(Date.now()),
          isFollowUpAppointment: true,
          campaignId: campaignId,
          accountId: accountId,
          contactId: null        
        }, [])
      ]);  
    }
  }

  loadAccountPopup(accountId) {
    this.methodService.launchInternalMethod(InternalMethodType.ShowAccountDataPopup, [accountId])    
  }

  missingAddress() {
    let returnValue = {
      missingInfo: false,
      city: false,
      zipcode: false,
      street: false
    };
    const entityData = this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data;
    if (this.commonService.isNullOrUndefined(entityData.street) || entityData.street == '') {
      returnValue.missingInfo = true;
      returnValue.street = true;
    }
    if (this.commonService.isNullOrUndefined(entityData.city) || entityData.city == '') {
      returnValue.missingInfo = true;
      returnValue.city = true;
    }
    if (this.commonService.isNullOrUndefined(entityData.zipcode) || entityData.zipcode == '') {
      returnValue.missingInfo = true;
      returnValue.zipcode = true;
    }
    return returnValue;
  }

  getSimilarity(doublicateItem) {
    return (doublicateItem.total_similarity * 100).toFixed(0);
  }
}
