<div class="customTextControlWrapper" [class.disabled]="!checkIfControlIsEnabled()">
    <label class="customTextControlWrapper__label"
    [class.disabled]="!checkIfControlIsEnabled()"
    [class.active]="isFocused && (checkIfControlIsEnabled() == true)"
    >{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
    <div class="customTextControlWrapper__valueWrapper d-flex"
    [class.disabled]="!checkIfControlIsEnabled()"
    [class.active]="isFocused && (checkIfControlIsEnabled() == true)">
        <input class="customTextControlWrapper__textbox customTextControlWrapper__oneIcon"
            [disabled]="!checkIfControlIsEnabled()" 
            (change)="callMethod('onchange')" 
            [(ngModel)]="controlValue"
            (blur)="handleBlur()"
            (focus)="handleFocus()"
            [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly
            appendTo="body" aria-modal="true"
            (dateTimeChange)="callMethod('onchange')" name="custom-calendar-{{getControlMainId()}}">
        <owl-date-time #dt7></owl-date-time>
        <!-- <input type="datetime"> -->
         <app-jj-icons *ngIf="controlValue" (click)="checkIfControlIsEnabled() ? clearControlValue() : undefined" type="x" [width]="20" [height]="20" [color]="!checkIfControlIsEnabled() ? 'disabledGray' :'red'"></app-jj-icons>
         <app-jj-icons  *ngIf="!controlValue" (click)="checkIfControlIsEnabled() ? clearControlValue() : undefined" type="calendar-dots" [width]="20" [height]="20" [color]="!checkIfControlIsEnabled() ? 'disabledGray' : 'secondary'"></app-jj-icons>
<!--         <svg-icon src="assets/images/svg/calendar.svg" class="fingerCursor customTextControlWrapper__button"
            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" (click)="clearControlValue()"></svg-icon> -->
    </div>
</div>