<div class="userHierarchyWrapper" *ngIf="userService.hasPermission('CanReadSecurityUserHierarchyTeam')">
    <app-layout header="UserHierarchy.Label.UserHierarchy">
        <div class="userHierarchyNewButton">

            <app-checkbox label="UserHierarchy.Label.ShowDeletedGroups" [(ngModel)]="showDeletedHierarchyGroups"></app-checkbox>
            
            <div class="userHierarchyNewButton__buttons">
                <app-main-button svg="question" type="small" (onClick)="showHelp()" label="UserHierarchy.Label.CreateUserHierarchyGroupHelp" ></app-main-button>
                <app-main-button svg="plus" type="small" (onClick)="createGroup()" label="UserHierarchy.Label.CreateUserHierarchyGroup" 
                    *ngIf="selectedGroup == null && userService.hasPermission('CanCreateSecurityUserHierarchyTeam')"></app-main-button>
            </div>
        </div>

        <!-- Infos zur Policy -->
        <div class="accessPolicyInfo" *ngIf="policy == 'none' && createGroupMode == false && selectedGroup == null">
            <div class="accessPolicyInfo__desc">
                <div class="userHierarchyGroupList__header">{{ 'UserHierarchy.Label.ActivateToUse' | texttransform }}</div>
                <div class="userHierarchyGroupList__desc">
                    {{ 'UserHierarchy.Label.ActivateToUseDesc' | texttransform }}
                </div>
            </div>
            <div class="accessPolicyInfo__button">
                <app-main-button type="small" (onClick)="activatePolicy()" label="UserHierarchy.Label.ActivatePolicy" ></app-main-button>
            </div>
        </div>
        <div class="accessPolicyInfo" *ngIf="policy != 'none'">
            <div class="accessPolicyInfo__desc">
                <!-- <div class="userHierarchyGroupList__header">{{ 'UserHierarchy.Label.CantEditGroups' | texttransform }}</div> -->
                <div class="userHierarchyGroupList__desc">
                    {{ 'UserHierarchy.Label.CantEditGroupsDesc' | texttransform }}
                </div>
            </div>
            <div class="accessPolicyInfo__button">
                <app-main-button type="small" (onClick)="deactivatePolicy()" label="UserHierarchy.Label.DeactivatePolicy" ></app-main-button>
            </div>
        </div>

        <!-- Gruppenliste -->        
        <div class="userHierarchyGroupList" *ngIf="selectedGroup == null && createGroupMode == false && userHierarchyGroups.length == 0">
            <div class="userHierarchyGroupList__header">{{ 'UserHierarchy.Label.NoGroupsAvailableYet' | texttransform }}</div>
        </div>
        <div class="userHierarchyGroupList" *ngIf="selectedGroup == null && createGroupMode == false && userHierarchyGroups.length > 0">
            <div class="userHierarchyGroupList__header">{{ 'UserHierarchy.Label.AvailableGroups' | texttransform }}</div>
            <div *ngFor="let userHierarchyGroup of userHierarchyGroups" class="everleadTableRow fingerCursor" (click)="setSelectedGroup(userHierarchyGroup)"
                [ngClass]="{ hideInDom: userHierarchyGroup.isDeleted && !showDeletedHierarchyGroups }">
                <div class="userHierarchyGroup">
                    <div class="userHierarchyGroup__name">{{ userHierarchyGroup.name }}</div>
                    <div class="userHierarchyGroup__buttons">
                        <app-jj-icons type="pencil-simple-line" [width]="20" [height]="20"></app-jj-icons>
                        <app-jj-icons *ngIf="!userHierarchyGroup.isDeleted" [tippy]="'UserHierarchy.Label.DeleteGroup' | texttransform" type="trash" [width]="20" [height]="20" (click)="deleteUserHierarchie(userHierarchyGroup);$event.stopPropagation()"></app-jj-icons>
                        <app-jj-icons *ngIf="userHierarchyGroup.isDeleted" [tippy]="'UserHierarchy.Label.ReactivateGroup' | texttransform" type="star" [width]="20" [height]="20" (click)="reactivateUserHierarchie(userHierarchyGroup);$event.stopPropagation()"></app-jj-icons>
                    </div>
                    <!-- <div class="assignedUserList">
                        <div class="assignedUserList__starter">{{ 'UserHierarchy.Label.AssignedUsers' | texttransform }}:</div>
                        <div *ngFor="let groupUser of getGroupUsers(userHierarchyGroup)">
                            {{ getUserName(groupUser) }}
                        </div>
                    </div> -->
                </div>                                
            </div>
        </div>

        <!-- Gruppendetails -->
        <div class="userHierarchyGroupList" *ngIf="selectedGroup != null && createGroupMode == false">
            <div class="userHierarchyDesign" cdkDropListGroup>
                <div class="userHierarchyGroups">
                    <app-layout [header]="selectedGroup.name" *ngIf="users.length > 0 && groupConnections != null">
                        <app-text-input label="UserHierarchy.Label.Name" [(ngModel)]="selectedGroup.name" (valueChange)="updateGroup()"></app-text-input>
                        <div class="userHierarchyGroupList__desc">
                            {{ 'UserHierarchy.Label.DragDesc' | texttransform }}
                        </div>
                        <app-main-button type="small" (onClick)="selectedGroup = null" label="General._.Back" class="backButton"></app-main-button>
                        <div class="userHierarchyGroupItem">
                            <app-userhierarchy-designer-item 
                                [child]="{ userId: selectedGroup.mainUserId }"
                                [users]="users"
                                [groupId]="selectedGroup.id"
                                [groupConnections]="groupConnections"
                                [isGroupOwner]="true"
                                [parentGuid]="commonService.createGuid()"
                                [canEditGroups]="true"
                                [group]="selectedGroup"
                            ></app-userhierarchy-designer-item>
                        </div>
                    </app-layout>
                    
                </div>    
                <div class="hierarchyDragWrapper">
                    <div class="hierarchyDragWrapper__availableUser">
                        {{ 'UserHierarchy.Label.AvailableUser' | texttransform }}
                    </div>
                    <div class="hierarchyDragWrapper__filter">
                        <app-jj-icons type="funnel" [width]="20" [height]="20"></app-jj-icons>
                        <input [(ngModel)]="filterString">
                    </div>
                    <div class="hierarchyDrag" cdkDropList [cdkDropListData]="users">
                        <div *ngFor="let user of users" class="hierarchyDrag__user fingerCursor" 
                            [cdkDragData]="user" 
                            cdkDrag data-html="true"
                            [ngClass]="{ hideInDom: userAlreadyDragged(user) || itemIsFiltered(user) }"
                        >
                            <div class="ellipsisText">{{ user.userName }}</div>
                            <app-jj-icons type="equals" [width]="20" [height]="20"></app-jj-icons>                    
                        </div>
                    </div>    
                </div>
            </div>    
        </div>

        <!-- Neue Gruppe -->
        <div class="userHierarchyNewGroup" *ngIf="createGroupMode == true">
            <div class="userHierarchyNewGroup__desc">
                {{ 'UserHierarchy.Label.NewGroupDesc' | texttransform }}
            </div>
            <div class="userHierarchyNewGroup__name">
                <app-text-input label="UserHierarchy.Label.Name" [(ngModel)]="newGroup.name"></app-text-input>
            </div>
            <div class="userHierarchyNewGroup__mainUser">
                <app-select-input label="UserHierarchy.Label.MainUser" [(ngModel)]="newGroup.mainUserId" [options]="users" 
                    display="userName" bind="id"></app-select-input>
            </div>
            <div class="userHierarchyNewGroup__create">
                <app-main-button type="small" (onClick)="createNewHierarchyGroup()" label="UserHierarchy.Label.CreateUserHierarchyGroupFinal"
                    [disabled]="newGroup.name.length < 1 || newGroup.mainUserId == null"></app-main-button>
                <app-main-button type="borderSmall" (onClick)="createGroupMode = false" label="General._.Back"></app-main-button>
            </div>
        </div>
    </app-layout>
</div>
