<div *ngIf="component ==='logo'" class="userAsLogo">
    <div class="userLogo userLogoNoAvater" *ngIf="applicationInfoService.userAvatar == 'none'">
        {{ getUserNameShortCut(applicationInfoService?.user) }}
    </div>

    <div class="userLogo" *ngIf="applicationInfoService.userAvatar != 'none'">
        <img [src]="applicationInfoService.userAvatar" class="userAvatar">
    </div>

    <div class="userAsLogo__message">
        <h1 class="greeting">{{ getGreetingToken() | texttransform }} {{applicationInfoService.user.firstName}} {{applicationInfoService.user.lastName}}</h1>
        <div class="message">{{ 'WelcomePage.Label.WelcomeText' | texttransform }}</div>
    </div>

</div>



<!-- todo -->

<div *ngIf="component === 'menu'">
    <div class="userAsMenu">
        <div class="userLogoOuter" #tooltipButtonMobile>
            <div class="userLogo userLogoNoAvater" *ngIf="applicationInfoService.userAvatar == 'none'">
            <div class="userLogo__newMessages" *ngIf="getNewUserMessages() != 0 && userService.hasPermission('CanReadUserMessage')">{{ getNewUserMessages() }}</div>
                {{ getUserNameShortCut(applicationInfoService?.user) }}
            </div>

            <div class="userLogo" *ngIf="applicationInfoService.userAvatar != 'none'" >
                <img [src]="applicationInfoService.userAvatar" class="userAvatar">
                <div class="userLogo__newMessages"
                    *ngIf="getNewUserMessages() != 0 && userService.hasPermission('CanReadUserMessage')">
                    {{ getNewUserMessages() }}
                </div>
            </div>
        </div>
        
        <div class="userSection">
            <div class="userRole" *ngIf="mobile == false">
                <div class="userRole__input userRole__inputMin">
                    <select [(ngModel)]="applicationInfoService.currentUserRoleId" (change)="changeUserRole()"
                        class="projectSelectionRedDropdown">
                        <option [ngValue]="role.roleId" *ngFor="let role of userService?.userRoles">
                            {{ role.role.nameTranslationToken.tokenFullName | texttransform }}
                        </option>
                    </select>
                </div> 
            </div>

            <div #tooltipButton (click)="toggleMenu()">
                <div class="placeholderUserMenu" *ngIf="!applicationInfoService.isMobile()">
                    <div class="userRole__input__label ellipsisUser" [ngClass]="{
                        userRole__inputMin: userService?.userRoles?.length > 1 && applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true',
                        ellipsisUser__solo: userService?.userRoles?.length < 2
                    }">{{applicationInfoService?.user?.firstName}} {{applicationInfoService?.user?.lastName}}</div>
                    <app-jj-icons [type]="isOpen ? 'caret-up' : 'caret-down'" [width]="12" [height]="12"></app-jj-icons>
                </div>
            </div>
        </div>
    </div>

    <div #tooltipUserMenu class="userMenu">
        <div class="userMenu__item" *ngIf="mobile">
            <span class="userRole__input__label ellipsisText">{{ applicationInfoService?.user?.firstName }} {{ applicationInfoService?.user?.lastName }}</span>
        </div>
        <div class="userMenu__item userMenu__project" *ngIf="mobile" (click)="showProjectSelection()">
            <span>{{ applicationInfoService?.projectInfo?.projectName }}</span>
        </div>
        <div class="userMenu__item" *ngIf="mobile">
            <div class="userRole__input maxWidth">
                <select [(ngModel)]="applicationInfoService.poolId" (change)="changeUserRole(); closePopup()"
                    class="projectSelectionRedDropdown maxWidth">
                    <option [ngValue]="pool.id" *ngFor="let pool of getPools()">
                        {{ pool.defaultName | texttransform }}
                    </option>
                </select>
            </div>
        </div>
        <div class="userMenu__item" *ngIf="mobile">
            <div *ngIf="userService?.userRoles?.length > 1 && applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true'"
                class="userRole__input maxWidth">
                <select [(ngModel)]="applicationInfoService.currentUserRoleId" (change)="changeUserRole(); closePopup()"
                    class="projectSelectionRedDropdown maxWidth">
                    <option [ngValue]="role.roleId" *ngFor="let role of userService?.userRoles">
                        {{ role.role.nameTranslationToken.tokenFullName | texttransform }}
                    </option>
                </select>
            </div> 
        </div>
        <div class="userMenu__item" *ngIf="userService.hasPermission('CanUpdateExpertMode') &&
            userService.hasPermission('CanUseNonSelfServiceNaviagionItem')" (click)="changeExpertMode()">
            <app-jj-icons *ngIf="applicationInfoService.expertMode == false" type="toggle-left" [width]="25" [height]="25" color="secondary"></app-jj-icons>
            <app-jj-icons *ngIf=" applicationInfoService.expertMode == true," type="toggle-right-fill" [width]="25" [height]="25" color="secondary"></app-jj-icons>
            <div class="userMenu__item__label ellipsisText">{{ 'UserSettings.Label.ExpertMode' | texttransform }}</div>
        </div>
        <div class="userMenu__item" *ngIf="userService.hasPermission('CanReadUiDesigner') && canUseDesigner" (click)="changeDesignerMode()">
            <app-jj-icons type="pencil-ruler" [width]="25" [height]="25"></app-jj-icons>

            <div class="userMenu__item__label ellipsisText">{{ getModeText() | texttransform }}</div>
        </div>
        <div class="userMenu__item" *ngIf="userService.hasPermission('CanReadUserMessage')" (click)="showMailbox()">
            <app-jj-icons type="envelope" [width]="25" [height]="25"></app-jj-icons>
            <div class="userLogo__newMessages" *ngIf="getNewUserMessages() != 0">
            </div>
            <div class="userMenu__item__label ellipsisText">{{ 'UserSettings.Label.Mailbox' | texttransform }}</div>
        </div>
        <div class="userMenu__item" (click)="showUserSettings()">
            <app-jj-icons type="gear" [width]="25" [height]="25"></app-jj-icons>
            <div class="userMenu__item__label ellipsisText">{{ 'General.Menu.Settings' | texttransform }}</div>
        </div>
        <div class="userMenu__item"  (click)="logout()">
            <app-jj-icons type="sign-out" [width]="25" [height]="25"></app-jj-icons>
            <div class="userMenu__item__label ellipsisText">{{ 'General.Menu.Logout' | texttransform }}</div>
        </div>
    </div> 
</div>
