import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
  selector: "[appScrollable]",
  exportAs: "appScrollable"
})
export class ScrollableDirective {
  constructor(private elementRef: ElementRef) {}

  @Input() scrollUnit: number;
  overflow= this.isOverflow

/*   @Output() visibileScroll = new EventEmitter(); */

  private get element() {
    return this.elementRef.nativeElement;
  }

  get isOverflow() {
/*     let visibility = this.element.scrollWidth > this.element.clientWidth;
    this.visibileScroll.emit(visibility); */
    return this.element.scrollWidth > this.element.clientWidth;
  }

  get elementList(){
    return this.elementRef.nativeElement;
  }

  scroll(direction: number) {
    this.element.scrollLeft += this.scrollUnit * direction;
  }

  get canScrollStart() {
    return this.element.scrollLeft > 0;
  }

  get canScrollEnd() {
    return this.element.scrollLeft + this.element.clientWidth != this.element.scrollWidth;
  }

  @HostListener("window:resize")
  onWindowResize() {} // required for update view when windows resized
}