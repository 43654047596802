import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService, ExternalDataSource_QueryType } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { WizardService } from '../wizard.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { environment } from 'environments/environment';
import { MicrocampaignService } from 'app/jollyjupiter/service/microcampaign.service';

@Component({
  selector: 'app-wizard-mailshot',
  templateUrl: './wizard-mailshot.component.html',
  styleUrls: ['./wizard-mailshot.component.scss'],
})
export class WizardMailshotComponent implements OnInit, AfterViewInit, OnDestroy {
  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  showEmailTemplatePreviewEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();

  // Testmail
  users = [];
  testMailUser = '';
  testMailAdress = '';

  // Filter
  dataFilterCaller = 'wizardmailshot';
  dataFilter = null;
  filterExpanded = false;
  filterSegments = [];
  filterSegmentMemberOperators = null;
  validEntities = null;
  selectedTemplate = null;

  // Step or Template
  workflows = [];
  steps = [];
  innerHTMLEMailPreview = null;
  textArea = null;

  // Misc
  entityId = '4';
  campaigns = [];
  taskCreationGuid = '';
  richTextEditorValue = '';
  emailContents = null;
  caretPos = null;
  isPublishedValue = true;

  constructor(
    public wizardService: WizardService,
    private externaldatasourceService: ExternaldatasourceService,
    public loaderService: LoaderService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private entityService: EntityService,
    private eventService: EventService,
    private datafilterService: DatafilterService,
    private emailtemplateService: EmailtemplateService,
    private domSanitizer: DomSanitizer,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private confirmationService: ConfirmationService,
    private refreshValueService: RefreshValueService,
    public microcampaignService: MicrocampaignService
  ) { }

  ngOnInit(): void {
    this.applicationInfoService.mailShotDesignerActive = true;
    if (this.applicationInfoService.applicationSettings['isDemoproject'] != 'true') {
      this.getValidCampaigns();
    } else {
      this.wizardService.wizardArray.campaignId = this.applicationInfoService.campaingnModeId;
    }
    this.getUsers();
    this.getWorkflows();
    this.getEMailcontents();
    this.setIgnoreOptin(false);
    this.wizardService.wizardArray.poolId = this.applicationInfoService.poolId;
    this.isPublishedValue = JSON.parse(environment.publishTemplate);
    this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(event => {
      if (event.target === this.dataFilterCaller) {
        this.applyFilter(event.arguments[0].id);
      }
    });

    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true') {
      this.wizardService.wizardArray.sender = 'demo@everlead.ai';
    }

    this.showEmailTemplatePreviewEventSubscription = this.eventService.showEmailTemplatePreview.subscribe(event => {
      this.getTemplatePreview(event.emailTemplateId);
    });

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {      
      if(event.id == 'entityTableMicroCampaignChanged') {
        if (event.value == null) {
          this.wizardService.wizardArray.newsletter = false;
        } else {
          this.wizardService.wizardArray.newsletter = true;
        }
        this.wizardService.wizardArray.newsletterCampaignId = event.value;
        this.updateEMailBatch();
      }
      if(event.id == 'lastMatTableResultWithoutControlFilters') {
        this.wizardService.wizardArray.templateDefinitionId = this.applicationInfoService.miscSettings['lastMatTableResultTableDefinitionId'];
        this.wizardService.wizardArray.columnFilters = this.applicationInfoService.miscSettings['lastColumnFilters'];
        this.wizardService.wizardArray.receivers = this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFiltersCountTotalRecords'];
        this.wizardService.wizardArray.testContact = this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'][0];
        const consentData = this.applicationInfoService.miscSettings['lastMatTableConsentData'];
        if (!this.commonService.isNullOrUndefined(consentData)) {
          if (consentData.consentTypes.length == 0) {
            this.wizardService.wizardArray.ignoreOptin = true;
            this.wizardService.wizardArray.mediumTypes = [];
            this.wizardService.wizardArray.consentTypes = [];
          } else {
            this.wizardService.wizardArray.mediumTypes = consentData.mediumTypes;
            this.wizardService.wizardArray.consentTypes = consentData.consentTypes;
            this.wizardService.wizardArray.ignoreOptin = false;
          }
        } else {
          this.wizardService.wizardArray.ignoreOptin = true;
          this.wizardService.wizardArray.mediumTypes = [];
          this.wizardService.wizardArray.consentTypes = [];
        }
        this.updateEMailBatch();
      }
    });



    if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray.filterId)) {
      this.applyFilter(this.wizardService.wizardArray.filterId, false);
    }
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.templateLanguageId)) {
      this.wizardService.wizardArray.templateLanguageId = 2;
    }

    if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray.workflowId)) {
      this.changeWorkflow(this.wizardService.wizardArray.workflowId, false);
    }
    if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray.stepId)) {
      this.getWorkflowSteps(this.wizardService.wizardArray.workflowId, false)
      .then(() => {
        this.getPreview(this.wizardService.wizardArray.stepId, true, false);
      });
    }

    if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray.newsletterCampaignId)) {
      this.applicationInfoService.miscSettings['newsletterCampaignId'] = this.wizardService.wizardArray.newsletterCampaignId
    }
  }

  ngOnDestroy(): void {
    if (this.selectCurrentDataFilterEventSubscription) { this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
    if (this.showEmailTemplatePreviewEventSubscription) { this.showEmailTemplatePreviewEventSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    this.applicationInfoService.miscSettings['newsletterCampaignId'] = null;
    this.applicationInfoService.mailShotDesignerActive = false;
  }

  ngAfterViewInit(): void {

  }

  getLanguageContent() {
    this.emailtemplateService.getTempalateLanguage(
      this.wizardService.wizardArray.createdTemplate.id,
      this.wizardService.wizardArray.templateLanguageId)
    .then(getLanguageContentResult => {
      this.wizardService.wizardArray.currentLanguageArray = getLanguageContentResult;
      this.wizardService.wizardArray.subject = getLanguageContentResult.subject;
      this.wizardService.wizardArray.templateText = getLanguageContentResult.bodyHtml;
      this.wizardService.wizardArray.templateHTML = getLanguageContentResult.bodyHtml;
      this.updateEMailBatch();
    });
    return;
  }

  updateTemplate() {
    this.saveTemplate();
    this.updateEMailBatch();
  }

  updateTemplateLanguage(templateLanguage, updateAndRefresh = false): Promise<any> {
    return new Promise((updateTemplateLanguageResolve, updateTemplateLanguageReject) => {
      templateLanguage.isPublished = this.isPublishedValue;
      templateLanguage.subject = this.wizardService.wizardArray.subject;
      templateLanguage.bodyHtml = this.wizardService.wizardArray.templateHTML;
      this.externaldatasourceService.executeExternalDataSource(434, [
        this.commonService.getModifyArrayBody(templateLanguage, [])
      ]).then(updateTemplateLanguageResult => {
        updateTemplateLanguageResolve(updateTemplateLanguageResult);
        if (updateAndRefresh) {
          this.getLanguageContent();
        }
      })
      .catch(error => { updateTemplateLanguageReject(error) });
    })
  }

  getValidCampaigns() {
    this.campaigns = [];
    this.externaldatasourceService.executeExternalDataSource(695, [])
    .then(getValidCampaignsResult => {
      getValidCampaignsResult.forEach(campaign => {
        if (!this.commonService.isNullOrUndefined(campaign.sesConfigSetId)) {
          this.campaigns.push(campaign);
        }
      });
    });
  }

  getEMailcontents() {
    this.emailtemplateService.getEMailContents()
    .then(getEMailcontentsResult => {
      this.emailContents = getEMailcontentsResult;
    });
  }

  getWorkflows() {
    this.workflows = [];
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(501, [null])
    .then(result => {
      this.loaderService.display(false);
      this.workflows = result;
      if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.workflowId)) {
        this.workflows.forEach(workflow => {
          if (!this.checkIfWorkflowNeedsToBeHidden(workflow)) {
            this.wizardService.wizardArray.workflowId = workflow.id;
          }
        });
      }
      if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray.workflowId)) {
        this.changeWorkflow(this.wizardService.wizardArray.workflowId, false);
      }
    })
    .catch(() => { this.loaderService.display(false); });
  }

  getWorkflowSteps(workflowId, changedByUi = false): Promise<any> {
    return new Promise((getWorkflowStepsResolve, getWorkflowStepsReject) => {
      this.loaderService.display(true);
      let externalDataSourceId = 104;
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [workflowId])
      .then(result => {
        this.loaderService.display(false);
        this.steps = result;
        getWorkflowStepsResolve(result);
      })
      .catch(() => {
        this.loaderService.display(false);
        getWorkflowStepsReject(null);
      });
    });
  }

  getUsers() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(14, [])
    .then(result => {
      this.loaderService.display(false);
      this.users = result;
      this.testMailUser = this.users.find(user => user.id == this.applicationInfoService.userID);
      this.testMailAdress = this.users.find(user => user.id == this.applicationInfoService.userID).email;
    })
    .catch(() => {
      this.loaderService.display(false);
     });
  }

  getPreview(stepId, showBookingPreview = true, updateEMailBatch = true) {
    const selectedStep = this.steps.find(step => step.id == stepId);
    if (selectedStep) {
      const selectedResult = selectedStep.results.find(result => result.id == selectedStep.bookTaskAfterXDaysResultId);
      if (selectedResult) {
        if (selectedResult.emailTemplateId != null) {
          if (showBookingPreview) {
            this.eventService.showBookingPreviewEventForResultIdEvent.emit(selectedResult.id);
          }
          this.getTemplatePreview(selectedResult.emailTemplateId);
        }
      }
    }
    if (updateEMailBatch) {
      this.updateEMailBatch();
    }
  }

  changeStep() {
    const step = this.steps.find(step => step.id == this.wizardService.wizardArray.stepId)
    this.wizardService.wizardArray.resultId = step.bookTaskAfterXDaysResultId;
    this.getPreview(this.wizardService.wizardArray.stepId);
  }

  getTemplatePreview(templateId) {
    this.selectedTemplate = templateId;
    this.emailtemplateService.getEMailTemplate(templateId, 2, true)
    .then(getEMailTemplateResult => {
      let templateResult = null;
      templateResult = getEMailTemplateResult
      this.innerHTMLEMailPreview = this.domSanitizer.bypassSecurityTrustHtml(
        this.emailtemplateService.renderEMail(templateResult.bodyHtml, [], false)
      );
    });
  }

  clearDataFilter() {
    this.dataFilter = null;
    this.wizardService.wizardArray.filterId = null;
    this.filterExpanded = false;
    this.applicationInfoService.myLeadsSelectedFilter = null;
    this.filterSegments = [];
    this.updateEMailBatch();
  }

  editDataFilter() {
    const externalDataSource = this.entityService.getExternalDataSourceByType(
      this.applicationInfoService.entities.Item(this.entityId),
        ExternalDataSource_QueryType.MyLeadsQuery + 15);
    if (!this.commonService.isNullOrUndefined(externalDataSource)) {
      this.applicationInfoService.dataFilterPreviewControl = 113088;
      this.applicationInfoService.dataFilterPreviewControQuery = externalDataSource.dataQuery;
    }
    this.datafilterService.setFilterEnvironment(
      this.dataFilter,
      this.entityId,
      this.dataFilterCaller,
      null,
      36
    );
    this.eventService.showJjPopup('DataFilterMainPanel.Label.SectionHeader', 'datafiltermainpanel', '80');
  }

  applyFilter(filterId, updateEMailBatch = true) {    
    this.datafilterService.getDataFilterInformation(filterId)
    .then(getDataFilterInformationResult => {
      this.dataFilter = filterId;
      this.wizardService.wizardArray.filterId = filterId;
      if (updateEMailBatch) {
        this.updateEMailBatch();
      }
      this.datafilterService.getSegmentMemberOperators()
      .then(getFilterSegmentMemberOperatorsResult => {
        this.filterSegmentMemberOperators = getFilterSegmentMemberOperatorsResult;
      });
      this.validEntities = this.datafilterService.getValidEntities(0);
      this.applicationInfoService.myLeadsSelectedFilter = getDataFilterInformationResult;
      // this.filterSegments = [];
      // if (getDataFilterInformationResult.length > 0) {
      //   getDataFilterInformationResult.filterSegments.forEach(filterSegment => {
      //     this.filterSegments.push(filterSegment);
      //   });
      // }
    });
  }

  testMail() {
    const step = this.applicationInfoService.steps.Item(this.wizardService.wizardArray.stepId)
    const selectedResult = step.results.find(result => result.id == step.bookTaskAfterXDaysResultId);
    if (selectedResult) {
      if (selectedResult.emailTemplateId != null) {
        const testMailAdressDetails = this.testMailAdress.split(';');
        testMailAdressDetails.forEach(adress => {
          this.emailtemplateService.sendTestEmail(
            selectedResult.emailTemplateId,
            this.wizardService.wizardArray.campaignId,
            this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'][0].id,
            adress
          ).then(() => {  })
        });
      }
    }
  }

  checkIfStepNeedsToBeHidden(step) {
    let returnValue = false;
    let result = this.applicationInfoService.results.Item(step.bookTaskAfterXDaysResultId);
    if (this.commonService.isNullOrUndefined(result)) {
      returnValue = true;
    } else {
      if (result.emailTemplateId == null) {
        returnValue = true;
      }
    }
    if (step.isActive == false) {
      returnValue = true;
    }
    if (step.bookTaskAfterXDays != 0) {
      returnValue = true;
    }
    return returnValue;
  }

  checkIfWorkflowNeedsToBeHidden(workflow) {
    let validStepCounter = 0;
    let returnValue = false;
    workflow.workflowSteps.forEach(step => {
      if (!this.checkIfStepNeedsToBeHidden(step)) {
        validStepCounter ++;
      }
    });
    if (workflow.isActive == false || validStepCounter == 0) {
      returnValue = true;
    }
    return returnValue;
  }

  setTemplateSource(source) {
    this.wizardService.wizardArray.templateSource = source;
  }

  createNewCampaign():Promise<any> {
    let sesName = 'ses' + this.wizardService.wizardArray.campaignName;
    sesName= sesName.replace(/ü/g, "ue");
    sesName= sesName.replace(/ä/g, "ae");
    sesName= sesName.replace(/ö/g, "oe");
    sesName= sesName.replace(/ /g, "");
    sesName= sesName.replace(/[^a-zA-Z ]/g, "");
    sesName = sesName.concat(
      this.applicationInfoService.projectID.toString(),
      this.commonService.getDateTimeString(new Date(Date.now()))
    );

    this.loaderService.display(true);
    return new Promise((createNewCampaignResolve, createNewCampaignReject) => {
      this.externaldatasourceService.executeExternalDataSource(875, [sesName])
      .then(createNewCampaignResult => {
        this.externaldatasourceService.executeExternalDataSource(136, [
          this.commonService.getModifyArrayBody({
            projectId: this.applicationInfoService.projectID,
            sesConfigSetId: createNewCampaignResult.id,
            defaultName: this.wizardService.wizardArray.campaignName
          }, [])
        ]).then(campaignResult => {
          this.wizardService.wizardArray.campaignId = campaignResult.id;
          this.getValidCampaigns();
          this.updateEMailBatch()
          this.loaderService.display(false);
        }).catch(() => { this.loaderService.display(false); });
      }).catch(() => { this.loaderService.display(false); });;
    });
  }

  createNewWorkflowForTemlate(templateId):Promise<any> {
    return new Promise((createNewWorkflowForTemlateResolve, createNewWorkflowForTemlateReject) => {
      this.emailtemplateService.createProcessForEmailTemplate({ defaultName: this.wizardService.wizardArray.templateName, id: templateId })
      .then(createNewWorkflowForTemlateResult => {
        this.wizardService.wizardArray.workflowId = createNewWorkflowForTemlateResult.workflowId;
        this.wizardService.wizardArray.resultId = this.applicationInfoService.miscSettings['templateStepResultId'];
        this.wizardService.wizardArray.stepId = createNewWorkflowForTemlateResult.stepId;
        this.wizardService.wizardArray.newTemplateStepId = createNewWorkflowForTemlateResult.stepId;
        this.getWorkflows();
        this.refreshValueService.getWorkflows();
        this.getWorkflowSteps(this.wizardService.wizardArray.workflowId);
        this.wizardService.wizardArray.templateSaved = true;
        createNewWorkflowForTemlateResolve(null);
        this.updateEMailBatch();
      }).catch(error => { createNewWorkflowForTemlateReject(error); });
    });
  }

  saveTemplate() {
    this.loaderService.display(true);
    const promiseArray: Promise<any>[] = [];
    const promiseArray2: Promise<any>[] = [];
    if (this.wizardService.wizardArray.createdTemplate == null) {
      this.externaldatasourceService.executeExternalDataSource(675, [
        this.wizardService.wizardArray.templateName,
        this.wizardService.wizardArray.sender
      ])
      .then(createNewTemplate => {
        this.wizardService.wizardArray.createdTemplate = createNewTemplate;
        this.prepareNewTemplateLanguages(createNewTemplate);

        promiseArray.push(this.externaldatasourceService.executeExternalDataSource(670, [
          this.commonService.getModifyArrayBody(this.wizardService.wizardArray.customTemplateDe, [])
        ]).then(deResult => { this.wizardService.wizardArray.customTemplateDe.id = deResult.id; }));

        promiseArray.push(this.externaldatasourceService.executeExternalDataSource(670, [
          this.commonService.getModifyArrayBody(this.wizardService.wizardArray.customTemplateEn, [])
        ]).then(enResult => { this.wizardService.wizardArray.customTemplateEn.id = enResult.id; }));

        Promise.all(promiseArray).then(() => {
          promiseArray.push(this.updateTemplateLanguage(this.wizardService.wizardArray.customTemplateDe));
          promiseArray.push(this.updateTemplateLanguage(this.wizardService.wizardArray.customTemplateEn));
          Promise.all(promiseArray2).then(() => {
            this.getLanguageContent();
          });
        })
        this.createNewWorkflowForTemlate(createNewTemplate.id).then(() => { this.loaderService.display(false); }).catch(() => { this.loaderService.display(false); });
      })
      .catch(error => {
        this.loaderService.display(false);
      });

    } else {
      this.externaldatasourceService.executeExternalDataSource(431, [
        this.commonService.getModifyArrayBody({
          id: this.wizardService.wizardArray.createdTemplate.id,
          defaultName: this.wizardService.wizardArray.templateName,
          sender: this.wizardService.wizardArray.sender
        }, [])
      ]);
      promiseArray.push(this.updateTemplateLanguage(this.wizardService.wizardArray.currentLanguageArray, true));
      Promise.all(promiseArray).then(() => {
        this.updateEMailBatch();
        this.loaderService.display(false);
      });
    }
  }

  setIgnoreOptin(updateBatch = true) {
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.ignoreOptin)) {
      this.wizardService.wizardArray.ignoreOptin = false;
    }
    this.applicationInfoService.miscSettings['ignoreOptin'] = this.wizardService.wizardArray.ignoreOptin;
    if (updateBatch) {
      this.updateEMailBatch();
    }
  }






  // refreshTemplateLanguages(templateId, createNewTemplate = false) {
  //   this.loaderService.display(true);
  //   const promiseArray: Promise<any>[] = [];
  //   if (createNewTemplate) {
  //     promiseArray.push(this.emailtemplateService.getTempalateLanguage(templateId, 1).then(resultEn => {
  //       this.wizardService.wizardArray.customTemplateEn = resultEn;
  //     }));
  //     promiseArray.push(this.emailtemplateService.getTempalateLanguage(templateId, 2).then(resultDe => {
  //       this.wizardService.wizardArray.customTemplateDe = resultDe;
  //     }));
  //   } else {
  //     promiseArray.push(this.emailtemplateService.getTempalateLanguage(templateId, this.wizardService.wizardArray.templateLanguage).then(resultDe => {
  //       this.wizardService.wizardArray.customTemplateDe = resultDe;
  //     }));
  //   }
  //   Promise.all(promiseArray).then(() => {
  //     this.updateEMailBatch();
  //     this.loaderService.display(false);
  //   }).catch(() => { this.loaderService.display(false); });
  // }

  prepareNewTemplateLanguages(createNewTemplate) {
    this.wizardService.wizardArray.customTemplateDe = {
      defaultName: this.wizardService.wizardArray.templateName,
      version: 1,
      emailTemplateId: createNewTemplate.id,
      languageId: 2,
      projectId: this.applicationInfoService.projectID,
      subject: this.wizardService.wizardArray.subject,
      bodyHtml: this.wizardService.wizardArray.templateHTML
    }
    this.wizardService.wizardArray.customTemplateEn = {
      defaultName: this.wizardService.wizardArray.templateName,
      version: 1,
      emailTemplateId: createNewTemplate.id,
      languageId: 1,
      projectId: this.applicationInfoService.projectID,
      subject: this.wizardService.wizardArray.subject,
      bodyHtml: this.wizardService.wizardArray.templateHTML
    }
  }

  getSendDate(date, step) {
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step).bookTaskAfterXDays)) {
      return new Date(date.getTime());
    } else {
      return new Date(date.getTime() + (1000 * 60 * 60 * 24 * this.applicationInfoService.steps.Item(step).bookTaskAfterXDays));
    }
  }

  openProjectSettingsInNewTab() {
    localStorage.setItem('oneTimeShowSettingPage', '4');
    this.commonService.openUrl(document.location.href, '_new')
  }

  checkDomain() {
    this.wizardService.wizardArray.senderDomainValid = this.emailtemplateService.checkSenderDomain(this.wizardService.wizardArray.sender);
    this.updateEMailBatch();
  }

  addMemberToTemplate(emailContent) {
    this.eventService.addTextToHTMLText.emit('{{' + emailContent.defaultName + '}}');
  }

  changeWorkflow(workflowId, changedByUi) {
    if (changedByUi) {
      this.wizardService.wizardArray.stepId = null;
    }
    this.getWorkflowSteps(workflowId, changedByUi);
    this.updateEMailBatch();
  }

  htmlEdit(event) {
    this.wizardService.wizardArray.templateHTML = event.renderedData;
    this.wizardService.wizardArray.templateText = event.textData;
    this.updateEMailBatch();
  }

  updateEMailBatch() {
    const array = {
      id: this.wizardService.wizardArray.id,
      batchName: this.wizardService.wizardArray.batchName,
      status: this.wizardService.wizardArray.status,
      wizardValues: JSON.stringify(this.wizardService.wizardArray)
    }

    let externalDataSourceId = 680;
    if (this.wizardService.wizardArray.bookingVersion == 2) {
      externalDataSourceId = 855;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [
      this.commonService.getModifyArrayBody(array, [])])
    .then(() => {
      this.eventService.customEvent.emit({
        id: this.wizardService.wizardArray.id,
        type: 'updateEMailBatch',
        value: JSON.stringify(this.wizardService.wizardArray)
      });
    });
  }

  templateSourceChanged() {
    this.wizardService.wizardArray.stepId = null;
    if (this.wizardService.wizardArray.templateSource == '2') {
      if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray.newTemplateStepId))
      this.wizardService.wizardArray.stepId = this.wizardService.wizardArray.newTemplateStepId;
    }
    // console.warn(this.wizardService.wizardArray.stepId);
  }

  setMicroCampaignIdForNewsletter(microcampaignId) {
    this.wizardService.wizardArray.newsletterMicroCampaignResultId = microcampaignId;
    this.updateEMailBatch();
  }
}
