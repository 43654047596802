import { Component, OnDestroy, OnInit } from '@angular/core';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { isNullOrUndefined } from 'util';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { Subscription } from 'rxjs';
import { LoaderService } from 'app/shared/service/loader-service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';

@Component({
  selector: 'app-controls-design-main',
  templateUrl: './controls-design-main.component.html',
  styleUrls: ['./controls-design-main.component.scss']
})
export class ControlsDesignMainComponent implements OnInit, OnDestroy {
  refreshControlInfoSubscription: Subscription = new Subscription();
  visibleTabs: any[] = [];
  selectedTabIndex = 0;
  tabControlId = '6746';
  currentControlToModify = null;
  containerControls = null;
  containerControlsBackup = null;
  lookupTable = null;
  externalDatasourcesList = [];

  constructor(
    private axivasTranslateService: AxivasTranslateService,
    public applicationInfoService: ApplicationInfoService,
    public designerService: DesignerService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private uiService: UiService,
    private eventService: EventService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private graphqlqueryService: GraphqlqueryService
  ) { }

  ngOnInit() {
    this.eventService.refreshControlInfo.subscribe(event => {      
      if (event.id == this.currentControlToModify.id) {
        this.externaldatasourceService.executeExternalDataSource(594, [event.id, this.graphqlqueryService.uiServiceControlBaseString])
        .then(updateControlResult => {
  /*         console.log('event', updateControlResult); */
          this.currentControlToModify = updateControlResult[0];          
        });
      }
    });

    this.currentControlToModify = this.designerService.currentControl;
    this.designerService.currentControl = null;
    this.lookupTable = this.uiService.getEntityMemberLookupTable(this.currentControlToModify);
    this.getContainerControls();
    if (this.applicationInfoService.isDeveloper) {
      this.addProjectSettingMenuItem(true, { id: 0, displayText:
        this.axivasTranslateService.getTranslationTextForToken('Designer.Label.Admin') });
      this.addProjectSettingMenuItem(false, { id: 1, displayText:
        this.axivasTranslateService.getTranslationTextForToken('Designer.Label.Supervisor') });
      this.addProjectSettingMenuItem(false, { id: 2, displayText:
        this.axivasTranslateService.getTranslationTextForToken('Designer.Label.SubControls') });  
      this.addProjectSettingMenuItem(false, { id: 3, displayText:
        this.axivasTranslateService.getTranslationTextForToken('Designer.Label.Methods') });  
      this.addProjectSettingMenuItem(false, { id: 4, displayText:
        this.axivasTranslateService.getTranslationTextForToken('Designer.Label.ExternalDataSources') });   
      this.addProjectSettingMenuItem(false, { id: 5, displayText:
        this.axivasTranslateService.getTranslationTextForToken('Designer.Label.AttributeModifier') });   
      this.addProjectSettingMenuItem(false, { id: 6, displayText:
        this.axivasTranslateService.getTranslationTextForToken('Designer.Label.AccessRights') });   
      this.addProjectSettingMenuItem(false, { id: 7, displayText:
        this.axivasTranslateService.getTranslationTextForToken('Designer.Label.Settings') });   
      
      if (this.applicationInfoService.isDeveloper)  {
        this.addProjectSettingMenuItem(false, { id: 99, displayText: 'JSON' });          
      }
      this.getExternalDataSourcesList();
    }
  }

  ngOnDestroy(): void {
    if (this.refreshControlInfoSubscription) { this.refreshControlInfoSubscription.unsubscribe(); }
  }

  setCurrentChild(id) {
    this.selectedTabIndex = Number(id);
  }

  addProjectSettingMenuItem(requiresAdmin: boolean, item: any) {
    if (requiresAdmin) {
      if (this.applicationInfoService.isDeveloper) {
        this.visibleTabs.push(item);
      }
    } else {
      this.visibleTabs.push(item);
    }
  }

  checkIfControlIsModified() {
    if (JSON.stringify(this.currentControlToModify) ===
      JSON.stringify(this.applicationInfoService.controlsBackUp.Item(this.currentControlToModify.logicalControlId))) {
        return false;
      } else {
        return true;
      }
  }

  undoChanges() {
    this.currentControlToModify = this.commonService.createCopyOfArray(
      (this.applicationInfoService.controlsBackUp.Item(this.currentControlToModify.logicalControlId)));
    this.eventService.updateControlDefinition.emit(
       this.applicationInfoService.controlsBackUp.Item(this.currentControlToModify.logicalControlId));
  }

  getMaxHeight() {
    return this.uiService.getDesignSpanPosition('uiContainerDesignSpan', 20);
  }

  saveControl() {
    if (isNullOrUndefined(this.currentControlToModify.controlType) || this.currentControlToModify.controlType === 0) {
      this.designerService.saveUiContainer(this.currentControlToModify, this.containerControls, this.containerControlsBackup);
    } else {
      this.designerService.saveUiControl(this.currentControlToModify);
    }
  }

  getContainerControls() {
    if (!isNullOrUndefined(this.currentControlToModify.logicalControlId)) {
      this.containerControls = [];
      this.externaldatasourceService.executeExternalDataSource(
        this.applicationInfoService.applicationSettings['getContainerControlsExternalDataSourceId'],
        [this.currentControlToModify.logicalControlId])
      .then(getContainerControlsResult => {
        getContainerControlsResult.forEach(control => {
          this.containerControls.push(this.applicationInfoService.controls.Item(control.logicalControlId.toString()));
        });
        this.containerControlsBackup =
          this.commonService.createCopyOfArray(this.containerControls);
      })
      .catch(error => {
        console.error('getContainerControls', error);
      });
    }
  }

  showItemInfo() {
/*     console.warn('showItemInfo current', this.applicationInfoService.controls.Item(this.currentControlToModify.logicalControlId));
    console.warn('showItemInfo backup', this.applicationInfoService.controlsBackUp.Item(this.currentControlToModify.logicalControlId)); */
  }

  addNewExternalDataSource(externalDataSourceId) {
    this.externaldatasourceService.executeExternalDataSource(333, [this.currentControlToModify.id, externalDataSourceId])
    .then(() => {
      this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe(() => {
        this.refreshUiControlExternalDataSources();
      }); 
    });
  }

  refreshUiControlExternalDataSources() {
    this.externaldatasourceService.executeExternalDataSource(594, [this.currentControlToModify.id])
      .then(control => {
       this.currentControlToModify.uiControlExternalDataSources = control[0].uiControlExternalDataSources;
    });
  }

  deleteUiControlExternalDataSource(id) {
    this.externaldatasourceService.executeExternalDataSource(334, [id])
    .then(() => {
      this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe(() => {
        this.refreshUiControlExternalDataSources();
      });
    });
  }

  getExternalDataSourcesList() {
    this.externaldatasourceService.executeExternalDataSource(327, [])
    .then(result => {
      this.externalDatasourcesList = result;
    });
  }

  addNewSubControl() {
    this.designerService.saveControl({
      childs: [],
      parentControlId: this.currentControlToModify.id
    })
    .then(result => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(319, [result.id])
      .then(control => {
        control[0].childs = [];
        this.uiService.cleanControl(control[0]);
        this.currentControlToModify.childs.push(control[0]);
        this.loaderService.display(false);
      }).catch(error => { this.loaderService.display(false); });
    });
  }

  getShortenedQuery(dataQuery) {
    let maxLength = dataQuery.length;
    let addon = '';
    if (maxLength > 50) { 
      maxLength = 50; 
      addon = '...'
    }
    return dataQuery.substr(0, maxLength) + addon;
  }
}
