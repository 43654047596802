<div class="result">
    <div class="result__name">
        <app-checkbox label="" [(ngModel)]="result.isActive" (valueChange)="saveResult()"></app-checkbox>
        <app-wizard-translations svgW=20, svgH=20 [array]="result" updateBaseArrayExternaldatasourceId=570 [exclude]="excludeItems" mode=3></app-wizard-translations>    
        <div class="result__id" *ngIf="applicationInfoService.isDeveloper">{{ result.id }}</div>
        <input [(ngModel)]="result.defaultName" (change)="saveResult()">
    </div>

    <div class="result__smallText">
        <app-jj-icons type="list-numbers" [width]="20" [height]="20" [tippy]="'ProcessDesign.Label.ResultOrder' | texttransform"></app-jj-icons>
        <input [(ngModel)]="result.resultOrder" (change)="saveResult()" type="number">
    </div>
    <div class="result__smallText">
        <app-jj-icons type="timer" [width]="20" [height]="20" [tippy]="'ProcessDesign.Label.CloseTaskAfterXResults' | texttransform"></app-jj-icons>
        <input [(ngModel)]="result.closeTaskAfterXResults" (change)="saveResult()" type="number">
    </div>
    <div class="result__nextstep">
        <app-jj-icons type="calendar-check" [width]="20" [height]="20" [tippy]="'ProcessDesign.Label.NextStep' | texttransform"></app-jj-icons>
        <div class="result__nextstep__inner">
            <select [(ngModel)]="result.nextStepWorkflowId" (change)="nextStepChanged(result)">
                <option [ngValue]=null></option>
                <option *ngFor="let workflow of workflows" [ngValue]="workflow.id">
                    {{ workflow.defaultName }}
                </option>
            </select> 
            <select [(ngModel)]="result.nextStepId" (change)="saveResult()" *ngIf="result.nextStepWorkflowId">
                <option *ngFor="let step of getWorkflowSteps(result.nextStepWorkflowId)"
                    [ngValue]="step.id">
                    {{ step.defaultName }}
                </option>
            </select>    
        </div>
    </div>

    <div class="result__leadstate">
        <app-jj-icons type="chart-bar" [width]="20" [height]="20" [tippy]="'ProcessDesign.Label.Leadstate' | texttransform"></app-jj-icons>
        <select [(ngModel)]="result.leadStatusId" (change)="saveResult()">
            <option [ngValue]="null"></option>
            <option *ngFor="let leadstate of leadStates" [ngValue]="leadstate.lookupTable.id">{{ commonService.getTranslationValueFromArray(leadstate.lookupTable) | texttransform }}</option>
        </select>
    </div>

    <div class="result__qmgroup">
        <app-jj-icons type="test-tube" [width]="20" [height]="20" [tippy]="'ProcessDesign.Label.QM' | texttransform"></app-jj-icons>
        <select [(ngModel)]="result.qmUserGroupId" (change)="saveResult()">
            <option [ngValue]="null"></option>
            <option *ngFor="let qmUserGroup of qmUserGroups" [ngValue]="qmUserGroup.id">
                {{ qmUserGroup.defaultName }}
            </option>
        </select>
    </div>

    <div class="result__options">
        <app-jj-icons type="phone" (click)="toggle('isCallAttempt')" [color]="stateCheckColor('isCallAttempt')" [width]="20" [height]="20" [tippy]="'ProcessDesign.Label.IsCallAttempt' | texttransform"></app-jj-icons>
        <app-jj-icons type="star"  (click)="toggle('isNetResult')" [color]="stateCheckColor('isNetResult')" [width]="20" [height]="20" [tippy]="'ProcessDesign.Label.IsNetResult' | texttransform"></app-jj-icons>        
        <app-jj-icons type="user"  (click)="toggle('isContactRequired')" [color]="stateCheckColor('isContactRequired')" [width]="20" [height]="20" [tippy]="'ProcessDesign.Label.IsContactRequired' | texttransform"></app-jj-icons>
        <app-jj-icons type="calendar-dots" [width]="20" [height]="20" [color]="getEventColor()" (click)="showEventWizard()" [tippy]="'ProcessDesign.Label.EventTriggerConnected' | texttransform"></app-jj-icons>        
        <app-jj-icons type="envelope" [width]="20" [height]="20" [color]="getEnvelopeColor()" [pointer]="false" [tippy]="'ProcessDesign.Label.EMailTemplate' | texttransform"></app-jj-icons>
        <app-jj-icons type="question" [width]="20" [height]="20" (click)="callSubquestionForm()" [tippy]="'ProcessDesign.Label.EditSubResults' | texttransform"></app-jj-icons>
    </div>

    <div class="result__buttons">
        <app-jj-icons type="pencil-simple-line" [width]="20" [height]="20" (click)="editResult()" [tippy]="'General._.Edit' | texttransform"></app-jj-icons>
    </div>
</div>
