<div class="selectedDashboard">
<app-layout header="Dashboard.Item.Activity" [subheader]="selectedReport?.subHeader">
    <div class="selectedDashboard__headerNav">
        <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back" type="small"></app-main-button>
   </div> 
    <div class="selectedDashboard__innerBorderLayer">
        <div class="selectedDashboard__nav">
            <div class="selectedDashboard__filterMobile">
                <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                    <div class="selectedDashboard__filterMobile">
                        <div class="selectedDashboard__filterMobile__select" *ngFor="let reportParameter of reportTemplate?.reportParameters">                                    
                                <app-select-input *ngIf="
                                        reportParameter.parameterTypeLookupId == 16 && 
                                        reportParameter.isUserParameter &&
                                        reportParameter.defaultName != 'language_id'"
                                    [label]="commonService.getTranslationValueFromArray(reportParameter)"
                                    [(ngModel)]="reportParameterValues[reportParameter.defaultName]"
                                    [options]="paramterExternalDataSourceResults[reportParameter.externalDataSource?.id]"
                                    [bind]="reportParameter.externalDataSource?.dataKey"
                                    [display]="reportParameter.externalDataSource?.dataValue"
                                    (valueChange)="getDashboardDataArray(17)"></app-select-input>
                            </div>
        
                            <app-select-input 
                                label="Task.Label.Campaign" 
                                [(ngModel)]="reportParameterValues['campaign_id']" 
                                bind="id" 
                                display="defaultName"
                                *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                                [options]="this.applicationInfoService.campaigns.toArray()"
                                (valueChange)="getDashboardDataArray(17)"></app-select-input>

                            <app-checkbox [(ngModel)]="onlyLeads" label="DefaultActivityDashboard.Label.ShowOnlyLeads"
                                    (valueChange)="getDashboardDataArray(17)" class="checkBoxStyler"></app-checkbox>
                      
                        </div>
                </app-accordion>
            </div>
            <div class="selectedDashboard__filter">
                <div class="selectedDashboard__filter__select" *ngFor="let reportParameter of reportTemplate?.reportParameters">                                    
                    <app-select-input *ngIf="
                        reportParameter.parameterTypeLookupId == 16 && 
                        reportParameter.isUserParameter &&
                        reportParameter.defaultName != 'language_id'"
                        [label]="commonService.getTranslationValueFromArray(reportParameter)"
                        [(ngModel)]="reportParameterValues[reportParameter.defaultName]"
                        [options]="paramterExternalDataSourceResults[reportParameter.externalDataSource?.id]"
                        [bind]="reportParameter.externalDataSource?.dataKey"
                        [display]="reportParameter.externalDataSource?.dataValue"
                        (valueChange)="getDashboardDataArray(17)">
                    </app-select-input>
                </div>
    
                <app-select-input 
                    label="Task.Label.Campaign" 
                    [(ngModel)]="reportParameterValues['campaign_id']" 
                    bind="id" display="defaultName"
                    *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                    [options]="this.applicationInfoService.campaigns.toArray()"
                    (valueChange)="getDashboardDataArray(17)"></app-select-input>
                
                <app-checkbox [(ngModel)]="onlyLeads" label="DefaultActivityDashboard.Label.ShowOnlyLeads"
                    (valueChange)="getDashboardDataArray(17)" class="checkBoxStyler"></app-checkbox>
            </div>          
        </div>

        <div class="projDashboardActivity__errors" *ngIf="loading || nodata">
            <div *ngIf="loading">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>
                {{ 'General.Label.LoadingData' | texttransform }}
            </div>

            <div class="marginContainer noDataContainer" *ngIf="nodata">
                {{ 'General.Label.NoDataAvailable' | texttransform }}
            </div>
        </div>
        
        
        <span id="defaultDashboardActivity"></span>
        <div class="selectedDashboard__content"
        [style.height.px]="getMaxHeight(5)" 
        [style.max-height.px]="getMaxHeight(5)"
        *ngIf="!loading && !nodata">
            <div class="projDashboardActivity__navigation">
                 <app-select-input 
                 label="Task.Label.Step" placeholder="ProjectOverview.Label.All"
                 [(ngModel)]="step" bind="id" display="step" 
                 [options]="stepsOptions"
                 (valueChange)="onSelectChange()"
                ></app-select-input>
                <div class="projDashboardActivity__navigation__buttons">
                    <app-main-button svg="chart-bar" [type]=" viewChart ? 'small' : 'borderSmall'" label="Dashboard.Label.ViewChart" (onClick)="viewChart=true"></app-main-button>
                    <app-main-button svg="list" [type]="viewChart ? 'borderSmall': 'small'" label="Dashboard.Label.ViewList" (onClick)="viewChart = false"></app-main-button>
                </div>
            </div>

            <div class="projDashboardActivity__content__chart" *ngIf="viewChart">
               <app-bar-chart [height]="450" [controlid]="step === null ? 'allstepsActivities' : undefined" 
                [chartData]="selectedData" component="dashboard_activity"></app-bar-chart>
            </div>

            <div class="projDashboardActivity__content__list" *ngIf="!viewChart">
               <app-dashboard-list-dropdown [controlid]="step === null ? 'nestedArray' : undefined" [step]="step"
                [data]="selectedData" component="activity"></app-dashboard-list-dropdown>
            </div>
        </div>
    </div>
</app-layout>

</div>