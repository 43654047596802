import { Component, Injector, OnInit } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';

@Component({
  selector: 'app-custom-mynextfollowup',
  templateUrl: './custom-mynextfollowup.component.html',
  styleUrls: ['./custom-mynextfollowup.component.scss']
})
export class CustomMynextfollowupComponent extends CustomControlBaseClass implements OnInit {
  startDate = null;

  constructor(
    private injector: Injector,
    private projectService: ProjectService   
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit(): void {
  }

  getNextFollowup() {
    this.loaderService.display(true);   
    let startDate = this.startDate;
    if (startDate != null) {
      startDate = '"'.concat(startDate.toUTCString(), '"');
    }
    this.externalDatasourceService.executeExternalDataSource(563, [
      this.applicationInfoService.lastLogicalItemFromMyFollowups,
      startDate
    ])
    .then(getNextLogicalItemFromMyFollowupsResult => {
      this.loaderService.display(false);
      this.startDate = null;
      if (getNextLogicalItemFromMyFollowupsResult != null) {
        this.projectService.getCurrentAccount(getNextLogicalItemFromMyFollowupsResult.accountId)
        .then(() => {
          if (getNextLogicalItemFromMyFollowupsResult.contactId == null) {
            this.applicationInfoService.currentContact = null;
            this.eventService.updateControlAttributes();
            this.eventService.updateContactQuestionaire();          
          } else {
            this.projectService.getCurrentContact(getNextLogicalItemFromMyFollowupsResult.contactId)
            .then(() => {});
          }  
        });
        this.applicationInfoService.lastLogicalItemFromMyFollowups = getNextLogicalItemFromMyFollowupsResult.id;
      } else {
        if (this.applicationInfoService.lastLogicalItemFromMyFollowups != null) {
          this.messagingService.showDefaultInfo('', 'MyNextFollowUp.Message.NoMoreFollowUpsFound');
        } else {
          this.messagingService.showDefaultInfo('', 'MyNextFollowUp.Message.NoFollowUpFound');
        }        
        this.applicationInfoService.lastLogicalItemFromMyFollowups = null;
      }
    })
    .catch(error => { 
      console.error('getNextFollowup', error)
      this.loaderService.display(false);
    });
  }

  clearDate() {
    this.startDate = null;
    this.clearLastLogicalTask();
  }

  clearLastLogicalTask() {
    this.applicationInfoService.lastLogicalItemFromMyFollowups = null;
  }

  showList() {
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['Agent.Menu.MyFollowUps', 'myfollowups', '80']);
  }
}
