<div class="orderDLCWrapper">
    <div class="orderDLCWrapper__info">
        <div class="orderDLCDescription" *ngIf="dlcOrdered == false">
            {{ dlc.settingJson.packageSubSectionName | texttransform }}
        </div>   
        <div *ngIf="dlcOrdered == false" class="orderDLCLicenseText" [innerHTML]="dlc.settingJson.licenseText | texttransform"></div>
        <app-checkbox *ngIf="dlcOrdered == false" label="General.Label.AcceptLicense" [(ngModel)]="acceptLicense"></app-checkbox> 
        <div class="orderDLCDescription" *ngIf="dlcOrdered == true" [innerHTML]="dlc.settingJson.orderSuccessMessage | texttransform"></div>
    </div>

    <div class="orderDLCWrapper__bottom">
        <div class="dlcPackagePrice" *ngIf="dlcOrdered == false">
            <div>{{ getDLCPrice(dlc) | texttransform }}</div>                            
            <div class="oldPrice">
                <span *ngIf="dlc.settingJson.packageDiscount != null">
                    {{ 'PackageUpgrade.Label.OldPrice' | texttransform }}
                    <span class="oldPrice__strike">{{ dlc.settingJson.packageDiscountOldPrice }}</span>
                    ({{ dlc.settingJson.packageDiscount | texttransform }})
                </span>
            </div>                                
        </div>

        <div class="orderDLCButtons">
            <app-main-button svg="arrow-up-right" class="maxWidth" width="100%" position="right" (onClick)="orderDLC()" 
                label="General.Label.OrderFinally" fontSize="14px" [disabled]="!acceptLicense" *ngIf="dlcOrdered == false"></app-main-button>
            <app-main-button svg="x" class="maxWidth" width="100%" (onClick)="closeDLC()" 
                label="General.Label.Close" fontSize="14px" *ngIf="dlcOrdered == true"></app-main-button>
        </div>    
    </div>
</div>