
<div *ngIf="lookupTable?.lookupTableId != null" class="lookupTable__wrapper">
    <app-main-button svg="plus" (onClick)="createNewLookupTableEntry" label="UiControlDesign.Label.CreateNewEntry" type="small"></app-main-button>
    <div class="lookupTable__info">
        {{ 'UiControlDesign.Label.ExistingItems' | texttransform }}
    </div>
    <div class="uiDesignLookupTableList" class="lookupTable__content">
        <div *ngFor="let lookupTableEntry of lookupTableEntries" class="everleadTableRow lookupTableListItem">
            <div class="lookupTableListItem__active" [tippy]="'CampaignDesign.Label.IsActive' | texttransform">
                <input [(ngModel)]="lookupTableEntry.isActive" type="checkbox" (change)="updateLookupTableEntry(lookupTableEntry)">
            </div>
            <div class="lookupTableListItem__name">            
                <input [(ngModel)]="lookupTableEntry.defaultName" (change)="updateLookupTableEntry(lookupTableEntry)">                
            </div>
            <div class="lookupTableListItem__translation">
                <app-wizard-translations *ngIf="updateExternalDataSourceId != null" [array]="lookupTableEntry" [updateBaseArrayExternaldatasourceId]=updateExternalDataSourceId mode="3"></app-wizard-translations>    
            </div>
        </div>
    </div>
</div>
