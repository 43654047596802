<div id="wizardWrapper" class="wizardWrapper">
    <div class="wizardCore">
        <div id="wizardHeader" class="wizardCore__header" *ngIf="!applicationInfoService.isMobile() && wizardService.pages.length > 1" [ngClass]="{ hideInDom : wizardService.wizardArray.duplicateTemplateId }">
            
            
            <div id="wizardCore__header__container" class="wizardCore__header__container">


                <div class="wizardCore__header__tabContainer">
                    <div [ngClass]="{
                        wizardCore__header__tab: !wizardService.showOptionalPages,
                        wizardCore__header__tabExpanded: wizardService.showOptionalPages }">
                        <app-navbar [options]="filterWizardOptions" [selectedId]="wizardService.wizardPage" (onNavItemChange)="changeToPage($event, true)"></app-navbar>
                        
                    </div>
                    
                </div>

        <!--         <div *ngFor="let page of wizardService.pages; let i = index" (click)="changeToPage(i + 1, true)" class="wizardCore__header__page" 
                  [style.width]="domSanitizer.bypassSecurityTrustStyle(getPageWidth(i + 1))"
                  [ngClass]="{
                    wizardCore__header__page__active: wizardService.wizardPage == (i + 1),
                    wizardCore__header__page__available: isPageAvailable(i + 1),
                    wizardCore__header__page__passed: isPagePassed(i + 1),
                    wizardCore__header__page__coming: isPageComing(i + 1),
                    hideInDom: checkIfPageIsOptional(i)                    
                }">
                    <app-tab svg="magicwand" label="page.name"></app-tab>
                    <div class="wizardCore__header__number">{{ i + 1 }}</div>
                    <div class="ellipsisText wizardCore__header__text" *ngIf="isPageAvailable(i + 1) && wizardService.pages.length > 1">{{ page.name | texttransform }}</div>
                </div>              
                    <div class="wizardCore__header__number">{{ i + 1 }}</div>
                    <div class="ellipsisText wizardCore__header__text" *ngIf="isPageAvailable(i + 1) && wizardService.pages.length > 1">{{ page.name | texttransform }}</div> -->
                </div>                
        </div>
    
        

        <div id="wizardHeader" class="wizardCore__header__tabContainer" *ngIf="applicationInfoService.isMobile()">
                
                <div class="wizardCore__header__tabsMobile">
                    <div class="everleadMobileTab">
                        <div class="everleadMobileTab__valueWrapper">
                            <select [(ngModel)]="wizardService.wizardPage" class="selectTab__box">
                                <option *ngFor="let page of wizardService.pages; let i = index" [ngValue]="page.pageId" [ngClass]="{
                                            hideInDom: checkIfPageIsOptional(i)                    
                                        }" (change)="changeToPage(page.pageId, true, pageId)">{{ page.name | texttransform }}</option>
                            </select>
                        </div>
                    </div>
                </div>
        </div>
        <div id="wizardBody" class="wizardCore__body" [ngClass]="{ wizardCore__body__noHeader: wizardService.pages.length == 1 }">
            <app-wizard-campaign *ngIf="wizardService.wizardMode == 1"></app-wizard-campaign>
            <app-wizard-workflow *ngIf="wizardService.wizardMode == 2"></app-wizard-workflow>
            <app-wizard-step *ngIf="wizardService.wizardMode == 3"></app-wizard-step>        
            <app-wizard-stepresult *ngIf="wizardService.wizardMode == 4"></app-wizard-stepresult>        
            <app-wizard-leadreportsettings *ngIf="wizardService.wizardMode == 5"></app-wizard-leadreportsettings>
            <app-wizard-leadstate *ngIf="wizardService.wizardMode == 6"></app-wizard-leadstate>        
            <app-wizard-datarightsgroup *ngIf="wizardService.wizardMode == 7"></app-wizard-datarightsgroup>        
            <app-wizard-translation-popup *ngIf="wizardService.wizardMode == 8"></app-wizard-translation-popup>        
            <app-wizard-emailcontent *ngIf="wizardService.wizardMode == 9"></app-wizard-emailcontent>        
            <app-wizard-emailtemplate *ngIf="wizardService.wizardMode == 10"></app-wizard-emailtemplate>        
            <app-wizard-entitymember *ngIf="wizardService.wizardMode == 11"></app-wizard-entitymember>        
            <app-wizard-dataimport *ngIf="wizardService.wizardMode == 13"></app-wizard-dataimport>        
            <app-wizard-moco *ngIf="wizardService.wizardMode == 14"></app-wizard-moco>        
            <app-wizard-eventassignment *ngIf="wizardService.wizardMode == 15"></app-wizard-eventassignment>
            <app-wizard-emailsettings *ngIf="wizardService.wizardMode == 16"></app-wizard-emailsettings>
            <app-wizard-mailshot *ngIf="wizardService.wizardMode == 17"></app-wizard-mailshot>
            <app-wizard-mailtocontact *ngIf="wizardService.wizardMode == 18"></app-wizard-mailtocontact>
            <app-wizard-eventtrigger *ngIf="wizardService.wizardMode == 19"></app-wizard-eventtrigger>
            <app-wizard-mailshot-final *ngIf="wizardService.wizardMode == 20"></app-wizard-mailshot-final>
            <app-wizard-email-smtpcontent *ngIf="wizardService.wizardMode == 21"></app-wizard-email-smtpcontent>
            <app-wizard-blacklistimport *ngIf="wizardService.wizardMode == 22"></app-wizard-blacklistimport>
            <app-wizard-voicetemplates *ngIf="wizardService.wizardMode == 23"></app-wizard-voicetemplates>
            <app-wizard-microcampaign *ngIf="wizardService.wizardMode == 24"></app-wizard-microcampaign>
        </div>

        <div id="wizardFooter" class="wizardCore__footer customTextControlWrapper__baseBackground" *ngIf="!applicationInfoService.isMobile()" [ngClass]="{
            hideInDom: checkIfFooterIsHidden()
        }">
            <div class="wizardCore__footer__left">

            </div>
            <div class="wizardCore__footer__center" *ngIf="wizardService.pages.length > 1 && !wizardService.wizardArray.duplicateTemplateId">
                
                <app-main-button 
                    type="border" 
                    svg="arrow-left"
                    [disabled]="wizardService.wizardPage == 1"
                    (onClick)="previousPage()"
                    label="Wizard.Label.Back"
                    ></app-main-button>

                <app-main-button
                type="border"
                svg="arrow-right"
                label="Wizard.Label.Next"
                [disabled]="checkIfNextIsBlocked() || 
                    wizardService.nextDisabled || wizardService.wizardPage == getRealPageAmount()"
                (onClick)="nextPage()"
                ></app-main-button>
<!--                 <button class="wizardButton customTextControlWrapper__button" [disabled]="wizardService.wizardPage == 1" (click)="previousPage()">Zurück</button>            
                <button class="wizardButton customTextControlWrapper__button" [disabled]="checkIfNextIsBlocked() || 
                    wizardService.nextDisabled || wizardService.wizardPage == getRealPageAmount()" (click)="nextPage()">Weiter</button>      -->           
            </div>
            <div class="wizardCore__footer__right">
                <div class="wizardCore__footer__right__content" *ngIf="
                    !wizardService.wizardArray.duplicateTemplateId &&
                    wizardService.wizardHasOptionalContent() &&
                    userAllowedToSeeAdditionalOptions()
                " (click)="wizardService.showOptionalPages = !wizardService.showOptionalPages; checkWizardOptions()">
                <app-jj-icons [type]="wizardService.showOptionalPages == true ? 'toggle-right-fill' : 'toggle-left'"></app-jj-icons>    
                {{ 'Wizard.Label.OptionalContent' | texttransform }}
                </div>
                <app-main-button type="border" svg="plus" label="General._.Save" (onClick)="wizardService.saveWizardItem()"
                    *ngIf="showSaveButton()" [disabled]="!wizardService.checkIfArrayCanBeSaved()"></app-main-button>                
            </div>        
        </div>
        <div id="wizardFooterDesignSpan"></div>
        <div id="wizardFooter" class="wizardCore__footer customTextControlWrapper__baseBackground" *ngIf="applicationInfoService.isMobile()">
            <div class="wizardCore__footer__center">
                <app-main-button
                type="borderSmall"
                svg="plus"
                label="General._.Save"
                (onClick)="wizardService.saveWizardItem()"
                *ngIf="showSaveButton()"
                [disabled]="!wizardService.checkIfArrayCanBeSaved()"

                ></app-main-button>                    
        <!--         <button class="wizardButton customTextControlWrapper__button" (click)="wizardService.saveWizardItem()"
                    *ngIf="showSaveButton()"
                    [disabled]="!wizardService.checkIfArrayCanBeSaved()">{{ 'General._.Save' | texttransform }}</button>   -->          
            </div>
        </div>
    </div>    
    <div class="wizardWrapper__page" *ngIf="!applicationInfoService.isMobile() && wizardService.pages.length > 1" [ngClass]="{ 
        hideInDom : wizardService.wizardArray.duplicateTemplateId || checkIfFooterIsHidden()
    }">
        <div class="wizardWrapper__page__subPage">
            {{ wizardService.wizardPage }}
        </div>        
    </div>
</div>

