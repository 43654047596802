import { Component, Injector, OnInit } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';

@Component({
  selector: 'app-custom-eventinfo',
  templateUrl: './custom-eventinfo.component.html',
  styleUrls: ['./custom-eventinfo.component.scss']
})
export class CustomEventinfoComponent extends CustomControlBaseClass implements OnInit {
  eventData = [];
  constructor(
    private injector: Injector,
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit() {
    this.getTaskEventInfo();
  }

  getTaskEventInfo() {
    this.controlValue = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId).data[this.controlDefinition.value];
    if (this.commonService.isNullOrUndefined(this.controlValue)) {
      // console.log('Taskeventinfo => no event id', this.controlValue);
      return;
    }

    this.externalDatasourceService.executeExternalDataSource(520, [this.controlValue])
    .then(result => {
      this.eventData = [];
      let jsonValue = result.transformedData.toString();
      jsonValue = jsonValue.replace(/\'/g, '"');
      jsonValue = JSON.parse(jsonValue);
      const keys = Object.keys(jsonValue);
      keys.forEach(key => {
        this.eventData.push({ name: key, value: jsonValue[key] })
      });
      this.loaderService.display(false);
    })
    .catch(error => {
      this.loaderService.display(false);
    });
  }
}
