import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-emailreview',
  templateUrl: './emailreview.component.html',
  styleUrls: ['./emailreview.component.scss']
})
export class EmailreviewComponent implements OnInit {
  emailInfo = null;
  mailEvent = null;
  template = null;
  innerHTML = null;

  mailTo = '';
  mailSubject = '';
  mailDate = null;
  noMailData = false;
  loadingData = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private domSanitizer: DomSanitizer,
    private emailtemplateService: EmailtemplateService
  ) { }

  ngOnInit(): void {
    this.emailInfo = this.applicationInfoService.miscSettings['emailreview'];
    this.getMailInfo();
  }
  
  getMailInfo() {    
    this.noMailData = false;
    this.loadingData = true;

    if (this.emailInfo.type == 'mail' || this.commonService.checkIfStringContainsString(this.emailInfo.taskMailId, '@everlead-mail')) {
      let mailId = this.emailInfo.id;
      let externalDataSourceId = 873;
      if (this.commonService.checkIfStringContainsString(this.emailInfo.taskMailId, '@everlead-mail')) {
        mailId = this.emailInfo.taskMailId;
        externalDataSourceId = 883;
      }

      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [mailId])
      .then(getMailInfoResult => {        
        this.loadingData = false;
        if (getMailInfoResult == null) {
          this.noMailData = true;
          return;
        }
        this.template = getMailInfoResult.htmlBody;
        this.mailSubject = getMailInfoResult.subject;
        this.mailTo = getMailInfoResult.to;          
        this.mailDate = getMailInfoResult.time;        
        if (this.template == null) {            
          this.template = getMailInfoResult.textBody;
        }                
        this.renderContent(this.template);        
      }).catch(() => {
        this.loadingData = false;
        this.noMailData = true;
      });  
    } else {
      // Mailshot oder Task mit Mail
      let mailEventId = this.emailInfo.maileventMailId;
      if (this.emailInfo.taskMailId != null) {
        mailEventId = this.emailInfo.taskMailId;
      }
      this.externaldatasourceService.executeExternalDataSource(541, [mailEventId])
      .then(result => {
        this.loadingData = false;
        if (result == null) {
          this.noMailData = true;
          return;
        }
        if (this.commonService.isNullOrUndefined(result.to)) {
          this.noMailData = true;
          return;
        }
        this.mailEvent = result;
        this.mailTo = result.to;
        this.mailDate = result.dateSent;
        
          this.externaldatasourceService.executeExternalDataSource(542, [result.emailTemplateLanguageId])
          .then(emailTemplateLanguageIdResult => {                      
            this.template = emailTemplateLanguageIdResult;
            this.mailSubject = emailTemplateLanguageIdResult.subject;
            this.renderContent();
            // if (this.commonService.isNullOrUndefined(result.body)) {              
            // } else {
            //   this.renderContent(result.body);
            // }            
          })  
        
      }).catch(() => {
        this.loadingData = false;
        this.noMailData = true;
      });  
    }
  }

  renderContent(content = null) {
    if (content != null) {
      this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.emailtemplateService.renderEMail(content, [], false));
      return;
    } else {
      if (this.mailEvent.templateContentOverride != null) {
        const overrideArray = JSON.parse(this.mailEvent.templateContentOverride);
        Object.keys(overrideArray).forEach(overrideArrayItem => {
          this.template.usedEmailTemplateContents.forEach(content => {
            if (content.defaultName == overrideArrayItem) {
              content.content = overrideArray[overrideArrayItem];
            }          
          })
        })      
      }

      if (!this.commonService.isNullOrUndefined(this.mailEvent.body)) {
        const bodyValues = JSON.parse(this.mailEvent.body);
        Object.keys(bodyValues).forEach(bodyValuesItem => {
          this.template.usedEmailTemplateContents.forEach(content => {
            const replaceString = '{{' + bodyValuesItem + '}}';
            content.content = content.content.replace(replaceString, bodyValues[bodyValuesItem]);
          })
        });  
      }
  
      this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(
        this.emailtemplateService.renderEMail(this.template.bodyHtml, this.template.usedEmailTemplateContents, true)
      );
    }
  }

  getMailRef(eMail) {
    return 'mailto:'.concat(eMail);
  }
}
