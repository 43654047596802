import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { LoaderService } from 'app/shared/service/loader-service';
import { ExternalContentService } from 'app/jollyjupiter/service/external-content.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { isUndefined, isNullOrUndefined } from 'util';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-admin-query-builder',
  templateUrl: './admin-query-builder.component.html',
  styleUrls: ['./admin-query-builder.component.scss']
})
export class AdminQueryBuilderComponent implements OnInit {
  report = null;
  query = null;
  queryResult = null;
  @Input() connectionGroups = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private sharedApi: SharedAPI,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit() {
    this.query = this.applicationInfoService.miscSettings['queryEditorReportQuery'];
    this.report = this.applicationInfoService.miscSettings['queryEditorReport'];
  }

  testQuery() {
    let testQuery = this.query.query.queryTemplate;
    this.report.reportParameters.forEach(parameter => {
      if (isNullOrUndefined(parameter.queryEditorDebugValue)) {
        if (Number(parameter.parameterTypeLookupId) !== 17) {
          testQuery = testQuery.split('\'<' + parameter.defaultName + '>\'').join(null);
        }
        testQuery = testQuery.split('<' + parameter.defaultName + '>').join(null);
        // testQuery = testQuery.replaceAll('\'<' + parameter.defaultName + '>\'', null);
      } else {
        if (Number(parameter.parameterTypeLookupId) !== 17) {
          testQuery = testQuery.split('\'<' + parameter.defaultName + '>\'').join(parameter.queryEditorDebugValue);
        }
        testQuery = testQuery.split('<' + parameter.defaultName + '>').join(parameter.queryEditorDebugValue);
      }
    });
    testQuery = testQuery.replace(/"/g, '\\"');
    testQuery = '"' + testQuery + '"';
    this.sharedApi.queryTest(
      this.applicationInfoService.projectID.toString(),
      this.query.query.queryConnectionGroupId,
      testQuery
    ).subscribe(result => {
      console.log('QueryResult', result);
      this.queryResult = result;
    });
  }

  saveQuery() {
    this.eventService.closeJjPopup();
  }

  getConnectionGroups(): Promise<any> {
    return new Promise((getConnectionGroupsResolve, getConnectionGroupsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(1)
      .then(executeExternalDataSourceResult => {
        this.connectionGroups = executeExternalDataSourceResult;
        getConnectionGroupsResolve(executeExternalDataSourceResult);
      })
      .catch(error => {
        getConnectionGroupsReject(error);
      })
      .finally(() => {
        this.loaderService.display(false);
      });
    });
  }
}
