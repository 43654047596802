<div class="selectedDashboard">
<app-layout [header]="selectedReport.defaultName" [subheader]="selectedReport?.subHeader">
    <div class="selectedDashboard__headerNav">
        <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back" type="small"></app-main-button>
   </div> 
    
    <div class="selectedDashboard__innerBorderLayer">
        <div class="selectedDashboard__nav">
            <div class="selectedDashboard__filterMobile">
                <app-accordion titel="DefaultDashboard.Label.Filters" [isOpen]="true">
                    <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                </app-accordion>
            </div>
            <div class="selectedDashboard__filter">
                <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
            </div>
        </div>

        <div class="selectedDashboard__content">
            <div *ngIf="loadingData == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </div>
            
            <div class="dataDiv">
                <div *ngIf="dashboardResultData[this.dashboardId]==null && loadingData == false">
                    {{ 'General.Label.NoDataAvailable' | texttransform }}
                </div>

                <div *ngIf="loadingData == false" class="defaultDashboardConsentTableContainer">
                    <div *ngFor="let questionnaire of questionnairesList">                                            
                        <app-layout [header]="questionnaire">
                            <div  class="questionAnswerList">
                                <div *ngFor="let question of getQuestions(questionnaire)">
                                    <app-bar-chart 
                                        height="200px"
                                        *ngIf="getQuestionnaireData(question, questionnaire).length > 0"
                                        [barHeader]="question"
                                        [chartData]="getQuestionnaireData(question, questionnaire)" 
                                        component="questionnaireDashoard" 
                                        [controlid]="questionnaireDashoardChart">
                                    </app-bar-chart>            
                                </div>    
                            </div>
                        </app-layout>                    
                    </div>
                </div>    
            </div>
        </div>
    </div>
</app-layout>
</div>