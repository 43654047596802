<button [hidden]="hidden"
    class="navBarItem"
    [ngClass]="{ 
        navBarItem__first: first && !overflow, 
        navBarItem__last: last && !overflow }" 
    [disabled]="disabled" 
    (click)="buttonClick()" 
    [class.disabled]="disabled" 
    [class.active]="active">
    <div class="navBarItem__inner">
        <span class="navBarItem__label">{{ label | texttransform }}</span>
    </div>
</button>
