import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-admin-managerights',
  templateUrl: './admin-managerights.component.html',
  styleUrls: ['./admin-managerights.component.scss']
})
export class AdminManagerightsComponent implements OnInit {
  moduleTypes = [];
  newRightName = '';
  selectedModule = null;
  selectedModuleType = null;
  modules = [];
  moduleTypeModules = [];
  filterString = '';

  permissionRights = [
    { id: 2, nameTranslationTokenId: 19 },
    { id: 3, nameTranslationTokenId: 20 },
    { id: 4, nameTranslationTokenId: 21 },
    { id: 5, nameTranslationTokenId: 22 },
    { id: 6, nameTranslationTokenId: 23 },
    { id: 7, nameTranslationTokenId: 24 },
    { id: 8, nameTranslationTokenId: 724 },
    { id: 9, nameTranslationTokenId: 5613 },
    { id: 10, nameTranslationTokenId: 10001 },
  ];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslateService: AxivasTranslateService,
    private loaderService: LoaderService,
    private uiService: UiService
  ) { }

  ngOnInit(): void {
    this.getModuleTypes();
    this.getModules();
    this.getPermissionRights();
  }

  getPermissionRights() { 
    this.externaldatasourceService.executeExternalDataSource(914).then(getPermissionRightsResult => { this.permissionRights = getPermissionRightsResult; })
  }

  getModuleTypes() { 
    this.externaldatasourceService.executeExternalDataSource(912).then(getModuleTypeResult => { this.moduleTypes = getModuleTypeResult; })
  }

  getModules(): Promise<any> {
    return new Promise((getModulesResolve, getModulesReject) => {
      this.externaldatasourceService.executeExternalDataSource(913).then(getModulesTypeResult => { 
        this.commonService.sortArrayWithTranslationToken(getModulesTypeResult);
        this.modules = getModulesTypeResult; 
        if (this.modules.length > 0) {
          this.getModulesForType(this.selectedModuleType);
        }      
        getModulesResolve(getModulesTypeResult);
      }).catch(error => { getModulesReject(error) });
    });
  }

  getModulesForType(selectedModuleType) {
    this.moduleTypeModules = this.modules.filter(module => module.moduleTypeId == selectedModuleType);   
  }

  moduleHasPermission(module, permissionRight) {
    if (module.permissions.find(permission => permission.permissionRightId == permissionRight.id)) {
      return true
    } else { return false; }
  }

  addModuleToType(moduleName) {
    this.newRightName = '';
    const token = new Object();
    token['group'] = 'Security';
    token['subgroup'] = 'Module';
    token['tokenName'] = moduleName;   
    token['projectId'] = null;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(910, [moduleName, this.selectedModuleType, null])
    .then(() => {
      this.getModules().then(() => {             
        this.filterString = moduleName;
        this.loaderService.display(false); 
      });        
    }); 
    // this.externaldatasourceService.executeExternalDataSource(109, [this.commonService.getModifyArrayBody(token, [])])
    // .then(translationToken => {
    //   const promiseArray: Promise<any>[] = [];
    //   promiseArray.push(this.externaldatasourceService.executeExternalDataSource(113, [1, translationToken.id, moduleName]));
    //   promiseArray.push(this.externaldatasourceService.executeExternalDataSource(113, [2, translationToken.id, moduleName]));
    //   Promise.all(promiseArray).then(() => {
    //     this.axivasTranslateService.updateTranslationCache().then(() => {
    //       this.axivasTranslateService.getTranslationIds();
    //     });
    //     this.externaldatasourceService.executeExternalDataSource(910, [moduleName, this.selectedModuleType, translationToken.id])
    //     .then(() => {
    //       this.getModules().then(() => {             
    //         this.filterString = moduleName;
    //         this.loaderService.display(false); 
    //       });        
    //     }); 
    //   });
    // });
  }

  addPermissionToModule(module, permissionRight) {
    if (this.moduleHasPermission(module, permissionRight)) {
      return;
    }
    this.loaderService.display(true);
    const token = new Object();
    token['group'] = 'Security';
    token['subgroup'] = 'Permission';
    token['tokenName'] = ''.concat(permissionRight.defaultName, module.defaultName);   
    token['projectId'] = null;
    this.externaldatasourceService.executeExternalDataSource(911, [permissionRight.id, module.id, token['tokenName'], null])
    .then(() => {
      this.getModules().then(() => { this.loaderService.display(false); });        
    }); 

    // this.externaldatasourceService.executeExternalDataSource(109, [
    //   this.commonService.getModifyArrayBody(token, [])
    // ])
    // .then(translation => {
    //   this.externaldatasourceService.executeExternalDataSource(911, [permissionRight.id, module.id, token['tokenName'], translation.id])
    //   .then(() => {
    //     this.getModules().then(() => { this.loaderService.display(false); });        
    //   });      
    // });  
  }

  itemIsFiltered(module) {
    let returnValue = true;
    const moduleName = this.axivasTranslateService.getTranslationTextForToken(this.commonService.getNameTranslationOrDefaultName(module));
    if (this.commonService.checkIfStringContainsString(moduleName, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  getMaxHeight() {
    return this.uiService.getDesignSpanPosition('userPermissionDesign', 60);
  }
}
