export const colorLibrary =[
    '#60C28E',
    '#4F9D75',
    '#2B8D5F',
    '#7D5C98',
    '#A16BB5',
    '#9B6E8E',
    '#E14B6A',
    '#F0748E',
    '#B55F9F',
    '#FF5D88',
    '#6D4C41',
    '#5E4B8B',
    '#4A90E2',
    '#B4D9A0',
    '#D6A0D4',
    '#FF8F1F',
    '#F4C2C2',
    '#F1C6E6',
    '#4F9D76',
    '#A68F70'
]

