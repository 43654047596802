<div class="everleadToolBarHeader">
    <div class="everleadToolBarHeader__wrapper">
        <div *ngIf="applicationInfoService.applicationSettings['showFirstSteps'] == 'true' && ringcentralwebphoneService.session == null">
            <app-main-button (onClick)="showFirstSteps()" [label]="'DemoBanner.Label.VisitWebsite' | texttransform " type="small"></app-main-button>
        </div>

        <div class="toolbar__item fingerCursor" (click)="showSupportContent()">
            <app-jj-icons type="headset" [width]="30" [height]="30" [tippy]="'Toolbar.Label.ShowSupport' | texttransform" 
            [tippyOptions]="{theme: 'everleadRed', placement:'bottom'}"></app-jj-icons>
        </div>
    <div class="toolbar__item" id="toolbarUserLogo">
        <app-user-avatar component="menu"></app-user-avatar>
    </div>
</div>

</div>

