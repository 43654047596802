<app-layout [header]="getHeader()">
    <div class="reporting">
        <div class="reporting__tabsMobile" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem') && exportOnly == false">
            <div class="everleadMobileTab">
                <div class="everleadMobileTab__valueWrapper">
                    <select [(ngModel)]="mode" class="selectTab__box">
                        <option [ngValue]="1">{{'Reporting.Label.AvailableTemplates' | texttransform }}</option>
                        <option [ngValue]="2">{{'Reporting.Label.ReportToDownload' | texttransform }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="reporting__tabs" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem') && exportOnly == false">
            <app-scroll-tabs>
                <app-tab svg="file" [active]="mode === 1" label="Reporting.Label.AvailableTemplates" (onClick)="mode = 1"></app-tab>
                <app-tab svg="file" [active]="mode === 2" label="Reporting.Label.ReportToDownload" (onClick)="mode = 2"></app-tab>
            </app-scroll-tabs>

        </div>

        <section class="reporting__content">
            <app-layout header="Reporting.Label.ReportToDownload" *ngIf="mode==2" class="modeSection">
                <div class="downloadContentItem">
                    <div class="downloadContentItem__name">Name</div>        
                    <div class="downloadContentItem__date">Datum</div>
                    <div class="downloadContentItem__download"></div>
                </div>
                <div *ngFor="let downloadContentItem of downloadContent" class="downloadContentItem everleadShadowBox" [ngClass]="{
                    hideInDom: downloadContentItem.contentLibraryFileId == null
                }">
                    <div class="downloadContentItem__name ellipsisText">{{ downloadContentItem?.contentLibraryFile?.name }}</div>        
                    <div class="downloadContentItem__date">{{ downloadContentItem.finishedAt | date:'medium' }}</div>
                    <div class="downloadContentItem__download"><i class="fas fa-download fingerCursor" (click)="downloadFile(downloadContentItem)"></i></div>
                </div>
            </app-layout>

            <div *ngIf="selectedTemplate == null && mode == 1 && exportOnly == false" class="reportListWrapper">
                <app-layout [header]="'Reporting.Label.AvailableTemplates' | texttransform">
                    <div class="reportListList">
                        <div class="everleadShadowBox reportBox fingerCursor" *ngFor="let template of templates" (click)="initReportTemplate(template)">
                            <div class="reportBox__icon">
                                <app-jj-icons [type]="'file-xls'" [width]="60" [height]="60"></app-jj-icons>                     
                            </div>
                            <div class="reportBox__label">
                                <div class="reportBox__label__name">
                                    {{ commonService.getTranslationValueFromArray(template, 'defaultName') | texttransform }}
                                </div>
                                <div class="reportBox__label__description" *ngIf="template.descriptionTranslationTokenId != null">
                                    {{ axivasTranslateService.getTranslationTextForId(template.descriptionTranslationTokenId) | texttransform }}
                                </div>                
                            </div>
                        </div>
                    </div>
                </app-layout>   
            </div>

            <div *ngIf="selectedTemplate == null && mode == 1 && exportOnly == true" class="reportListWrapper">
                    <div class="reportListList">
                        <div class="everleadShadowBox reportBox fingerCursor" *ngFor="let template of templates" (click)="initReportTemplate(template)">
                            <div class="reportBox__icon">
                                <app-jj-icons [type]="'file-xls'" [width]="60" [height]="60"></app-jj-icons>                     
                            </div>
                            <div class="reportBox__label">
                                <div class="reportBox__label__name">
                                    {{ commonService.getTranslationValueFromArray(template, 'defaultName') | texttransform }}
                                </div>
                                <div class="reportBox__label__description" *ngIf="template.descriptionTranslationTokenId != null">
                                    {{ axivasTranslateService.getTranslationTextForId(template.descriptionTranslationTokenId) | texttransform }}
                                </div>                
                            </div>
                        </div>
                    </div>
            </div>

            <app-layout *ngIf="selectedTemplate != null && mode == 1" 
                [header]="commonService.getTranslationValueFromArray(selectedTemplate, 'defaultName') | texttransform">
                <app-layout class="reportingDetails ">                 
                    <div class="" *ngIf="selectedTemplate?.reportParameters?.length == 0">
                        {{ 'Reporting.Label.ThisReportHasNoParameters' | texttransform }}
                    </div>
                    <div class="" *ngIf="selectedTemplate?.reportParameters?.length > 0">
                        <div *ngFor="let parameter of selectedTemplate?.reportParameters" class="reportingParameterWrapper">
                            <div *ngIf="parameter.isUserParameter" class="reportingParameter" [ngClass]="{
                                hideInDom: applicationInfoService.applicationSettings['isDemoproject'] == 'true' && (
                                    parameter.defaultName == 'isLeadExport' ||
                                    parameter.defaultName == 'leadExportDate'
                                )
                            }">                                 
                                <app-number-input 
                                    [label]="getParamLabel(parameter)" 
                                    class="maxWidth"
                                    *ngIf="parameter.parameterTypeLookup?.defaultName == 'integer' && parameter.externalDataSource == null" 
                                    [(ngModel)]="ngValues[parameter.id]"
                                ></app-number-input>

                                <app-select-input 
                                    [label]="getParamLabel(parameter)" 
                                    class="maxWidth"
                                    [(ngModel)]="ngValues[parameter.id]" 
                                    *ngIf="parameter.externalDataSource != null && parameter.parameterTypeLookup?.defaultName == 'integer'" 
                                    [options]="paramterExternalDataSourceResults[parameter.externalDataSource.id]" 
                                    [display]="parameter.externalDataSource.dataValue" 
                                    [bind]="parameter.externalDataSource.dataKey"
                                ></app-select-input>

                                <app-checkbox 
                                    [label]="getParamLabel(parameter)" 
                                    class="maxWidth"
                                    *ngIf="parameter.parameterTypeLookup?.defaultName == 'boolean'" 
                                    [(ngModel)]="ngValues[parameter.id]"
                                ></app-checkbox>

                                <app-date-input 
                                    [label]="getParamLabel(parameter)" 
                                    class="maxWidth"
                                    *ngIf="parameter.parameterTypeLookup?.defaultName == 'date'" 
                                    [(ngModel)]="ngValues[parameter.id]"
                                ></app-date-input>	

                                <app-text-input 
                                    [label]="getParamLabel(parameter)" 
                                    class="maxWidth"
                                    *ngIf="parameter.parameterTypeLookup?.defaultName == 'string'" 
                                    [(ngModel)]="ngValues[parameter.id]"
                                ></app-text-input>
                             
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="reportButtons">
                        <button class="everleadCustomButton" (click)="executeReporting()">{{ 'Reporting.Label.RequestReport' | texttransform }}</button>
                        <button class="everleadCustomButton" (click)="selectedTemplate = null">{{ 'General.Label.Back' | texttransform }}</button>
                    </div>
                </app-layout> 
            </app-layout>
     
        </section>

    </div>

</app-layout>
