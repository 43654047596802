<div class="quickSearchItem fingerCursor" (click)="loadItem()" [ngClass]="{
    quickSearchOddItem: !isOdd
}">
    <div class="quickSearchCell iconwidth noborder">
        <div class="quickSearchIcon">
            <app-jj-icons [type]="'building'" [width]="32" [height]="32" [color]="'secondary'"
                *ngIf="itemType == 0"></app-jj-icons>
            <app-jj-icons [type]="'user'" [width]="32" [height]="32" [color]="'secondary'"
                *ngIf="itemType == 1"></app-jj-icons>
            <app-jj-icons [type]="'file'" [width]="32" [height]="32" [color]="'secondary'"
                *ngIf="itemType == 2"></app-jj-icons>
        </div>
    </div>

    <div class="quickSearchCell itemContentWidth">
        <div class="quickSearchItemContent">
            <div [innerHTML]="displayText"></div>
            <div [innerHTML]="accountText" class="quickSearchItemContent__account" *ngIf="accountText != null"></div>
        </div>
    </div>

    <div class="quickSearchCell poolWidth">
        <div class="quickSearchPool">
            <div class="quickSearchPoolName">{{ item.poolname }}</div>
        </div>
    </div>
</div>

<!-- <div class="quickSearchItem fingerCursor" (click)="loadItem()" [ngClass]="{
    quickSearchOddItem: !isOdd
}">
 
        <div class="quickSearchIcon">
            <app-jj-icons [type]="'building'" [width]="32" [height]="32" [color]="'secondary'"
                *ngIf="itemType == 0"></app-jj-icons>
            <app-jj-icons [type]="'user'" [width]="32" [height]="32" [color]="'secondary'"
                *ngIf="itemType == 1"></app-jj-icons>
            <app-jj-icons [type]="'file'" [width]="32" [height]="32" [color]="'secondary'"
                *ngIf="itemType == 2"></app-jj-icons>
        </div>

        <div class="quickSearchItemContent">
            <div [innerHTML]="displayText"></div>
            <div [innerHTML]="accountText" class="quickSearchItemContent__account" *ngIf="accountText != null"></div>
        </div>
   

   
        <div class="quickSearchPool">
            <div class="quickSearchPoolName">{{ item.poolname }}</div>
        </div>

</div> -->