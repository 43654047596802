import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { ControlType } from 'app/jollyjupiter/service/ui.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LoaderService } from 'app/shared/service/loader-service';
import { ConfirmationService } from 'primeng/api';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { WizardMode, WizardService } from 'app/jollyjupiter/controls/static/wizard/wizard.service';
import { Subscription } from 'rxjs';
import { UserService } from 'app/core/authentication/services/user.service';

@Component({
  selector: 'app-uicontainer-design',
  templateUrl: './uicontainer-design.component.html',
  styleUrls: ['./uicontainer-design.component.scss']
})
export class UicontainerDesignComponent implements OnInit, OnDestroy {
  wizardFinishedEventSubscription: Subscription = new Subscription()
  @Input() currentControlToModify = null;
  containerControls = [];
  // containerControlsBackup = [];
  availableEntities = [];
  specialArray = [];
  selectedEntity = null;
  controlTypes = ControlType;
  expanded = false;
  expandedMembers = false;
  controlTypesAllowedForEditing = []
  hoverId = null;
  checkArray = [];
  projects = [];
  public controlTypeOptions = [];

  constructor(
    public dragdropService: DragdropService,
    public designerService: DesignerService,
    private uiService: UiService,
    private eventService: EventService,
    public commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService,
    private confirmationService: ConfirmationService,
    public axivasTranslateService: AxivasTranslateService,
    private wizardService: WizardService,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.controlTypeOptions = this.uiService.getControlTypeArray();
    this.getContainerControls();
    this.setAvailableEntities();

    this.wizardFinishedEventSubscription = this.wizardService.wizardFinishedEvent.subscribe(() => {
      this.setAvailableEntities();
    });
    console.warn(this.applicationInfoService.user)
    this.projects = this.applicationInfoService.user.userProjects;
  }

  ngOnDestroy(): void {
    if (this.wizardFinishedEventSubscription) { this.wizardFinishedEventSubscription.unsubscribe(); }
  }

  removeItem(control) {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('UiControlDesign.DeleteUiContainerUiControl.Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('UiControlDesign.DeleteUiContainerUiControl.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        this.externaldatasourceService.executeExternalDataSource(610, [control.id])
        .then(() => {
          this.eventService.refreshContentEvent.emit(this.currentControlToModify.logicalControlId);
          this.getContainerControls();
          this.loaderService.display(false);
        }).catch(() => { this.loaderService.display(false); });
      },
    });
  }

  getContainerControls() {
    this.loaderService.display(true);
    const preloadedControlInfo = this.applicationInfoService.preloadedControlInfo.find(
      control => control.logicalControlId == this.currentControlToModify.logicalControlId
    );

    if (preloadedControlInfo) {
      this.consumeContainerControls(preloadedControlInfo);
      return;
    }

    this.externaldatasourceService.executeExternalDataSource(616, [this.currentControlToModify.logicalControlId])
    .then(result => {
      this.consumeContainerControls(result);
    })
    .catch(() => { this.loaderService.display(false); })
  }

  consumeContainerControls(result) {
    const removeItems = [];
    result.forEach(resultItem => {
      resultItem['control'] = this.applicationInfoService.controls.Item(resultItem.uiControlLogicalId);
      if (this.commonService.isNullOrUndefined(resultItem.order)) {
        if (this.applicationInfoService.controls.Item(resultItem.uiControlLogicalId)) {
          resultItem.order = this.applicationInfoService.controls.Item(resultItem.uiControlLogicalId).order;
          resultItem.orderAdded = true;
        }
      }
      if(resultItem['control']) {
        if (!this.applicationInfoService.isDeveloper && !resultItem['control'].isActive) {
          removeItems.push(resultItem);
        }
      } else {
        removeItems.push(resultItem);
      }
    });
    result.sort((a, b) => a.order < b.order ? -1 : 1);
    removeItems.forEach(removeItem => {
      this.commonService.removeItemFromArray(result, removeItem);
    });
    this.containerControls = result;
    this.checkArray = JSON.parse(JSON.stringify(this.containerControls));
    this.loaderService.display(false);
  }

  closeWithoutSaving() {
    this.eventService.closeJjPopup();
  }

  toNumber(value) {
    return Number(value);
  }

  refreshContainer() {
    this.eventService.refreshContentEvent.emit(this.currentControlToModify.logicalControlId);
  }

  setAvailableEntities() {
    this.availableEntities = [];
    this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
    this.availableEntities.push(this.applicationInfoService.entities.Item('2'));
    this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
    this.availableEntities.push(this.applicationInfoService.entities.Item('99'));
  }

  addLabel() {
    const control = this.designerService.createNewControl(null, null);
    control.controlType = 8;
    this.addToContainedControls(null, null, control);
  }

  addSpacer() {
    const control = this.designerService.createNewControl(null, null);
    control.controlType = 17;
    control.height = '15px';
    this.addToContainedControls(null, null, control);
  }

  addToContainedControls(entity = null, member = null, existingControl = null, addOnTop = false) {
    let control = existingControl;
    if (!control) {
      control = this.designerService.createNewControl(entity, member);
    }
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(174, [
      this.commonService.getModifyArrayBody(control, [])
    ])
    .then(result => {
      control.id = result.id;
      control.logicalControlId = result.id;
      let order = null;
      if (this.containerControls[this.containerControls.length -1]) {
        order = this.containerControls[this.containerControls.length -1].order;
      }
      if (this.commonService.isNullOrUndefined(order)) {
        order = this.containerControls.length;
      }

      this.designerService.saveControl(control);
      this.applicationInfoService.controls.Add(result.id, control);
      this.externaldatasourceService.executeExternalDataSource(208, [
        this.applicationInfoService.projectID,
        control.id,
        this.currentControlToModify.logicalControlId,
        order + 1
      ])
      .then(() => {
        this.loaderService.display(false);
        this.eventService.refreshContentEvent.emit(this.currentControlToModify.logicalControlId);
        this.getContainerControls();
      })
      .catch(() => { this.loaderService.display(false); });
    });
  }

  getMaxHeight() {
    return this.uiService.getDesignSpanPosition('uiContainerDesignSpan', 20);
  }

  saveControl(control) {
    this.designerService.saveControl(control);
  }

  moveInList(event: CdkDragDrop<string[]>, item: any) {
    this.checkArray = JSON.parse(JSON.stringify(this.containerControls));
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      // if (this.applicationInfoService.isDeveloper) {
         this.saveSorting();
      // }
    }
  }

  saveSorting() {
    const promiseArray: Promise<any>[] = [];
    this.loaderService.display(true);
    this.containerControls.forEach((control, index) => {
      if(
        control.logicalControlId != this.checkArray[index].logicalControlId ||
        control.order != index
      ) {
        control['order'] = index;
        promiseArray.push(this.designerService.updateUiContainerUiControl(control).then(() => {  }));
      }
    });
    Promise.all(promiseArray).then(() => {
      this.eventService.refreshContentEvent.emit(this.currentControlToModify.logicalControlId);
      this.loaderService.display(false);
    });
    if (promiseArray.length == 0) {
      this.loaderService.display(false);
    }
  }

  toggleView() {
    this.expanded = !this.expanded;
  }

  setHoverId(logicalControlId) {
    this.applicationInfoService.controlDesignerHoverId = logicalControlId;
  }

  canRemoveItem(containerControl) {
    let returnValue = true;
    if (!this.applicationInfoService.isDeveloper) {
      if (
        containerControl.control.controlType == null ||
        containerControl.control.controlType == 0 ||
        containerControl.control.controlType == 27
      ) {
        returnValue = false;
      }
    } else {
      returnValue = true
    }
    return returnValue;
  }

  addMember() {
    const newMember = new Object();
    newMember['isMandatory'] = false;
    newMember['defaultName'] = '';
    newMember['memberType'] = 'TEXT';
    newMember['entity'] = this.selectedEntity.entity;
    this.wizardService.showWizard(WizardMode.EntityMember, newMember);
  }

  setControlWidth(control, width) {
    control.width = width;
    this.designerService.saveControl(control).then(() => { });
  }

  getControlLabel(control) {
    let returnValue = control.displayText;
    if (this.commonService.isNullOrUndefined(returnValue) || returnValue == '' || returnValue == ' ') {
      this.commonService.getTranslationValueFromArray(control);
    }
    if (this.commonService.isNullOrUndefined(returnValue) || returnValue == '' || returnValue == ' ') {
      if (!this.commonService.isNullOrUndefined(control.entity)) {
        const entity = this.applicationInfoService.entities.Item(control.uiEntityInstanceId.toString());
        const member = entity.entityMembers.find(member => member.defaultName == control.value); {
          if (member) {
            returnValue = '('.concat(
              this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(member)),
              ')'
            );
          }
        }
      }
    }
    return returnValue;
  }

  editMember(control) {
    const entity = this.applicationInfoService.entities.Item(control.uiEntityInstanceId.toString());
    const member = entity.entityMembers.find(member => member.defaultName == control.value); {
      if (member) {
        this.wizardService.showWizard(WizardMode.EntityMember, member);
      }
    }
  }

  editControlLight(control) {
    this.applicationInfoService.miscSettings['controlEditLightControl'] = control;
    this.applicationInfoService.miscSettings['controlEditLightControlMode'] = 1;
    this.eventService.showJjPopup('UIControlSettings.Label.Designer', 'controleditlight', '1400px;800px');
  }

  changeContainerControlProject(containerControl) {
    this.externaldatasourceService.executeExternalDataSource(608, [
      this.commonService.getModifyArrayBody({
        id: containerControl.id,
        projectId: containerControl.projectId
      }, [])
    ]);
  }
}

