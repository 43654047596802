<span id="newAccountDesignSpan"></span>
<!-- <div class="newAccount__wrapper" [style.max-height.px]="getMaximumHeightOfSubContainer('newAccountDesignSpan')"> -->
<div class="newAccount__wrapper">
    <!-- Page 1 -->
    <div *ngIf="page==1" class="newAccount__controls" [innerHTML]="newAccountContainerInnerHtml"></div>    
    <div *ngIf="applicationInfoService?.applicationSettings['addOwnerToNewAccount']=='true' && page==1" class="newAccountSpacer">
        <app-select-input label="Account.Label.AccountOwner" 
            [(ngModel)]="applicationInfoService.entities.Item(newAccountEntityId.toString()).data['ownerId']" 
            [options]="projectUsers" display="userName" bind="id"></app-select-input>       
    </div>
    <div class="newAccountSpacer newAccountKeywords" 
        *ngIf="page==1 && applicationInfoService.applicationSettings['newDoublicateCheck'] != 'true'">
        <app-text-input label="Account.Label.Keywords" [(ngModel)]="applicationInfoService.entities.Item(newAccountEntityId.toString()).data.keywords"></app-text-input>
        <div class="newAccountKeywords__desc">{{ 'NewAccount.Label.KeywordsDescription' | texttransform}}</div>
    </div>
    
    <!-- Page 2 -->
    <div class="keyWordSearch" *ngIf="page==2">
        <div class="keyWordSearch__name">
            {{ applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['name1'] }}
        </div>
        <div class="prospectInfo">
            <div [innerHTML]="'NewAccount.Label.ProspectInfo' | texttransform" *ngIf="prospectToConvert != null" ></div>
            <div>
                {{ 'NewAccount.Label.KeywordsUsed' | texttransform}}: 
                <app-text-input label="Account.Label.Keywords" (change)="createSearchOrNewAccount()"
                    [(ngModel)]="applicationInfoService.entities.Item(newAccountEntityId.toString()).data.keywords"></app-text-input>
                <!-- <span class="keyWordSpan">
                    {{ applicationInfoService.miscSettings['entityTableKeywords'] }}
                </span>     -->
            </div>
        </div>    
    </div>
    <div *ngIf="(prospectToConvert == null || prospectToConvert == undefined) && page==2" class="newAccount__search" [ngClass]="{ hideInDom: accountsSearched == false }">
        <div [innerHTML]="newAccountContainerListInnerHtml" class="maxHeight"></div>
    </div>    
    <div *ngIf="prospectToConvert != null && prospectToConvert != undefined && page==2" class="newAccount__search" [ngClass]="{ hideInDom: accountsSearched == false }">
        <div [innerHTML]="newAccountContainerListInnerHtmlProspect" class="maxHeight"></div>
    </div>
    
    <!-- Page 3 -->
    <div *ngIf="page==3" class="dataEnrichment">
        <div class="dataEnrichment__label">
            {{ 'NewAccount.Label.DataEnrichmentHeader' | texttransform}}
        </div>
        <app-echobot-interface></app-echobot-interface>
    </div>

    <!-- Page 4 -->
    <div *ngIf="page==4" class="newDoublicateCheck">
        <h2>{{ 'NewAccount.Label.DoublicateCheck' | texttransform}}</h2>        
        <div *ngIf="doublicateCheckResult.results.length > 0" class="newDoublicateCheck__header">
            <div>{{ 'NewAccount.Label.DoublicateCheckResult' | texttransform}}</div>
            <div class="newDoublicateCheck__missingAddress" *ngIf="missingAddress().missingInfo">
                {{ 'NewAccount.Label.MissingAddress' | texttransform}}
                <div *ngIf="missingAddress().street" class="jjBold">{{ 'Account.Label.Street' | texttransform }}</div>
                <div *ngIf="missingAddress().zipcode" class="jjBold">{{ 'Account.Label.ZipCode' | texttransform }}</div>
                <div *ngIf="missingAddress().city" class="jjBold">{{ 'Account.Label.City' | texttransform }}</div>
            </div>    
        </div>
        <div class="newDoublicateCheck__notFoundItemsList" *ngIf="doublicateCheckResult.results.length == 0">
            {{ 'NewAccount.Label.NoDoublicatesFound' | texttransform }}
        </div>
        <div class="newDoublicateCheck__foundItemsList" *ngIf="doublicateCheckResult.results.length > 0">
            <div *ngFor="let doublicateItem of doublicateCheckResult.results" class="newDoublicateCheckItem everleadTableRow">
                <div class="newDoublicateCheckItem__data">
                    <div class="newDoublicateCheckItem__name">
                        {{ doublicateItem.company.name }}
                    </div> 
                    <div class="newDoublicateCheckItem__address">
                        <div>{{ doublicateItem.company.street }}</div>
                        <div>{{ doublicateItem.company.zipcode }}</div>
                        <div>{{ doublicateItem.company.city }}</div>            
                    </div>
                </div>
                <div class="newDoublicateCheckItem__similarity">
                    {{ 'NewAccount.Label.Similarity' | texttransform }}: {{ getSimilarity(doublicateItem) }} %
                </div>
                <div class="newDoublicateCheckItem__buttons">
                    <app-jj-icons type="eye" [width]="28" [height]="28" (click)="loadAccountPopup(doublicateItem.company.id)"
                        [tippy]="'NewAccount.Label.ShowAccountInPopup' | texttransform"></app-jj-icons>
                </div>                
            </div>
        </div>        
    </div>

    <div class="newAccount__buttons" id="newAccount__buttons" *ngIf="page==1">
        <app-main-button
            *ngIf="accountsSearched == false && searchOption == true" 
            (onClick)="createSearchOrNewAccount();$event?.stopPropagation()" 
            [disabled]="poolId == null && applicationInfoService.entities.Item(newAccountEntityId.toString()).data.name1?.length < 3"
            title="{{ 'NewAccount.Label.CreateNewAccount' | texttransform }}"
            [label]="newAccountSearchText | texttransform"></app-main-button>
    </div>

    <div class="newAccount__buttons" id="newAccount__buttons" *ngIf="page==2 || page==4">
        <div class="newAccountButtonLine" *ngIf="(accountsSearched == true || searchOption == false) && prospectToConvert == null" >
            <div class="newAccountButtonLine__text">{{ 'NewAccount.Label.CreateNewAccountDesc' | texttransform }} </div>
            <app-main-button                 
                (onClick)="createNewAccount();$event.stopPropagation()" 
                [disabled]="poolId == null"
                width="360px"
                title="{{ 'NewAccount.Label.CreateNewAccount' | texttransform }}"
                [label]="newAccountText | texttransform "></app-main-button>
        </div>

        <div class="newAccountButtonLine" *ngIf="((assignContactAfterCreation == false && accountsSearched == true) || searchOption == false)  && prospectToConvert == null" >
            <div class="newAccountButtonLine__text">{{ 'NewAccount.Label.CreateNewAccountAndEnterDesc' | texttransform }} </div>
            <app-main-button (onClick)="createNewAccount(true);$event.stopPropagation()"                
                [disabled]="poolId == null" 
                width="360px"
                title="{{ 'NewAccount.Label.CreateNewAccountAndEnter' | texttransform }}"
                [label]="newAccountEnterText | texttransform"></app-main-button>     
        </div>
        <!-- Prospect -->
        <div class="newAccountButtonLine" *ngIf="(accountsSearched == true || searchOption == false) && prospectToConvert != null" >
            <div class="newAccountButtonLine__text">{{ 'NewAccount.Label.CreateNewAccountDesc' | texttransform }} </div>
            <app-main-button                 
                (onClick)="createNewAccount()" 
                [disabled]="poolId == null" 
                width="360px"
                type="border"
                title="{{ 'NewAccount.Label.CreateAndConnectToProspect' | texttransform }}"
                label="NewAccount.Label.CreateAndConnectToProspect"></app-main-button>
        </div>    

        <div class="newAccountButtonLine">
            <div class="newAccountButtonLine__text">{{ 'NewAccount.Label.BackToPage1Desc' | texttransform }} </div>
            <app-main-button (onClick)="backToPageOne()" label="General._.Back" width="360px" ></app-main-button>
        </div>
    </div>
</div>