<div [id]="getEntityTableId()" class="entityTable" *ngIf="contentFound && contentSearched" 
    [style.border]="!isMainComponent ? 0 : undefined"
    [ngClass]="{ noOuterBorder: tableSettingsJson['noOuterBorder'] == true }"
>
    <div class="entityTable__wrapper">
        <div class="entityTableMenu" [style.background-color]="getTableMenuBackgroundColor()" [style.margin-bottom]="!isMainComponent ? 0 : undefined">                     
                <div class="entityTableHeaderLine">
                    <div class="componentHeader" [style.border-bottom]="!isMainComponent ? '1px solid #EDEDED' : undefined" [style.margin-bottom]="!isMainComponent ? 0 : undefined">
                        <div class="componentHeader__top" [style.padding]="!isMainComponent ? '0 0.5rem' : undefined">
                            <span *ngIf="isMainComponent"></span>
                            <h1 [style.fontSize.px]="!isMainComponent ? 24 : undefined">
                                <div *ngIf="controlDefinition.displayText == null || controlDefinition.displayText == ''">
                                    <div *ngIf="dataTableItem.mainEntityId == 4">{{ 'Contact.Label.Header' | texttransform }}</div>
                                    <div *ngIf="dataTableItem.mainEntityId == 1">{{ 'Account.Label.Header' | texttransform }}</div>
                                    <div *ngIf="dataTableItem.mainEntityId == 2">{{ 'Task.Label.Header' | texttransform }}</div>
                                    <div *ngIf="dataTableItem.mainEntityId == 462">{{ 'EntityTableDesigner.Label.Conversion' | texttransform }}</div>
                                    <div *ngIf="dataTableItem.mainEntityId == 917">{{ 'EntityTableDesigner.Label.Prospect' | texttransform }}</div>
                                    <div *ngIf="dataTableItem.mainEntityId == 99">{{ 'EntityTableDesigner.Label.Opportunity' | texttransform }}</div>                                
                                </div>
                                <div *ngIf="controlDefinition.displayText != null && controlDefinition.displayText != ''">
                                    {{ commonService.getTranslationValueFromArray(controlDefinition, 'displayText') | texttransform }}
                                </div>
                            </h1>
                        </div>
                        <div class="componentHeader__controls">
                            <div class="componentHeader__controls__inner">
                                <div class="componentHeader__controls__count" *ngIf="controlData.totalCount > 0">
                                    {{ controlData?.totalCount }} {{ 'EntityTableDesigner.Label.Results' | texttransform }}
                                </div>
                                <div class="componentHeader__controls__buttons">
                                    <app-svg-button label="General.Label.Export" svg="export" type="md" (onClick)="entityTableExport()" *ngIf="tableSettingsJson['canBeExported'] == true && userService.hasPermission('CanCreateUiDataExport')"></app-svg-button>
                                    <app-svg-button label="General.Label.Editor" svg="editor" type="md" (onClick)="showEntityTableDesigner()" *ngIf="canEditControl"></app-svg-button>
                                    <app-svg-button label="General.Label.Filter" svg="sliders" type="md" (onClick)="showEntityTableFilter()" [color]="getSliderColor()"></app-svg-button>
                                    <app-svg-button label="General.Label.Add" svg="plus" type="md" (onClick)="createNewOptin()" [tippy]="'OptinOptOutEditor.Label.CreateOptInOptOut' | texttransform" *ngIf="dataTableItem.mainEntityId == 20"></app-svg-button>
                                    <app-svg-button label="General.Label.Refresh" svg="arrows-counter-clockwise" type="md" (onClick)="getData()"></app-svg-button>    
                                </div>

                        
                                
                              <!--   <app-jj-icons [tippy]="'General.Label.Export' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}" [pointer]="true" [type]="'export'" [width]="24" [height]="24" (click)="entityTableExport()" *ngIf="tableSettingsJson['canBeExported'] == true && userService.hasPermission('CanCreateUiDataExport')"></app-jj-icons> -->
<!--                                 <app-jj-icons [tippy]="'General.Label.Editor' | texttransform " [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}" [pointer]="true" [type]="'editor'" [width]="24" [height]="24" (click)="showEntityTableDesigner()" *ngIf="canEditControl"></app-jj-icons>
                                <app-jj-icons [tippy]="'General.Label.Filter' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}" [pointer]="true" [type]="'sliders'" [width]="24" [height]="24" (click)="showEntityTableFilter()" [color]="getSliderColor()"></app-jj-icons>
                                <app-jj-icons [tippy]="'General.Label.Add' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}" [pointer]="true" [type]="'plus'" [width]="24" [height]="24" (click)="createNewOptin()" [tippy]="'OptinOptOutEditor.Label.CreateOptInOptOut' | texttransform" *ngIf="dataTableItem.mainEntityId == 20"></app-jj-icons>
                                <app-jj-icons [tippy]="'General.Label.Refresh' |texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}" [pointer]="true" [type]="'arrows-counter-clockwise'" [height]="24" [width]="24" (click)="getData()"></app-jj-icons> -->
                            </div>


                           <!--  <app-filter ()></app-filter> -->

                            <div class="entityTableGlobalFilter" *ngIf="!reducedFeatures">
                                <div class="entityTableGlobalFilter__wrapper">
                                <input (change)="setFilter()" [placeholder]="'General.Label.Search' | texttransform" #input [(ngModel)]="tableSettings.globalFilter" class="entityTableGlobalFilter">
                                <!-- <mat-icon svgIcon="ph-regular:magnifying-glass"></mat-icon> -->
                                <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>        
        </div>

        <!-- Zusatzleiste -->

        <div class="entityTableNewAditionalFilters" *ngIf="tableSettingsJson['additionalToolbar'] == true && !reducedFeatures">
            <div class="entityTableNewAditionalFilters__inner">
                <app-accordion [title]="'EntityTableDesigner.Label.AdditionalFilters' | texttransform" [isOpen]="true" [noBorder]="true">
                <div class="entityTableAdditionalFilter" *ngIf="tableSettingsJson['additionalToolbar'] == true && !reducedFeatures" [ngClass]="{
                    'entityTableAdditionalFilter__spaceBetween' : tableSettingsJson['vip'] == true
                }">
             <!--    {{ tableSettingsJson | json }} -->
                    
                    <div class="entityTableAdditionalFilter__item maxWidth" *ngIf="tableSettingsJson['showMailshotEventTypeSelect']">
                        <app-select-input label="Event.Label.EventTypeLabel" 
                            [(ngModel)]="applicationInfoService.selectedEventBookingEventtype" 
                            [options]="eventTypes" 
                            display="eventType" 
                            bind="eventType"
                            class="maxWidth"
                            (valueChange)="getData()"
                        ></app-select-input>
                    </div>

                    <!-- Opportunity -->
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showLostOpportunitiesSettingVisible']">
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.ShowLostOpportunities'"
                        [value]="tableSettingsJson['showLostOpportunities']" (click)="toggleValue('showLostOpportunities')"></app-entitytable-additionaltoolbaritem>
                    </div>
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showSelectAllSwitchOpportunity'] &&
                        userService.hasPermission('CanReadAllOpportunities')">
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.ShowAllOpportunities'"
                            [value]="tableSettingsJson['showAllOpportunities']" (click)="toggleValue('showAllOpportunities')"></app-entitytable-additionaltoolbaritem>
                 
                    </div>
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showSelectAllSwitchTask'] &&
                        userService.hasPermission('CanReadAllTask')">
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.ShowAllTasks'"
                            [value]="tableSettingsJson['showAllTasks']" (click)="toggleValue('showAllTasks')"></app-entitytable-additionaltoolbaritem>
                        <app-jj-icons type="info-fill" width="20" height="20" color="secondary" [tippy]="'EntityTable.Label.AllTaskTooltip' | texttransform" ></app-jj-icons>
                    </div>
                    
                    <!-- Optins -->
                    <div *ngIf="tableSettingsJson['onlyOptinsForContactSwitch'] == true && applicationInfoService?.currentAccount?.isDummyAccount == false" class="entityTableAdditionalFilter__item">
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.OnlyOptinsForContact'"
                            [value]="tableSettingsJson['onlyOptinsForContact']" (click)="toggleValue('onlyOptinsForContact')"></app-entitytable-additionaltoolbaritem>           
                    </div>
        
                    <!-- MicroCampaigns -->
                    <div *ngIf="tableSettingsJson['useMicroCampaignContacts'] == true" class="entityTableAdditionalFilterCampaign maxWidth">
                        <div class="entityTableAdditionalFilterCampaign__campaign">
                            <select [(ngModel)]="useMicroCampaignContactsCampaign" (change)="changeMicroCampaign()">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let microcampaign of microcampaignService.microcampaigns" [ngValue]="microcampaign.id">
                                    <!-- {{ commonService.getNameTranslationFromIdOrDefaultName(microcampaign) }} -->
                                    {{ microcampaign.defaultName }}
                                </option>
                            </select>
                        </div>
                        <div class="entityTableAdditionalFilterCampaign__spacer"></div>
                        <div class="entityTableAdditionalFilterCampaign__stopCampaign" *ngIf="tableSettingsJson['microCampaignNewslettermode'] != true">
                            <button class="everleadCustomButton" (click)="batchStopMicroCampaign()">
                            {{ 'MicroCampaign.Label.CancelCampaign' | texttransform }}</button>                    
                        </div>
                        <div class="entityTableAdditionalFilter__newsletteritem entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['microCampaignNewslettermode'] == true">
                            <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.DoubleOptInRequired'"
                                [value]="tableSettingsJson['doubleOptInRequired']" (click)="toggleValue('doubleOptInRequired')"></app-entitytable-additionaltoolbaritem>        
                        </div>
                    </div>
                    <div *ngIf="tableSettingsJson['useMicroCampaignContacts'] == true && 
                                tableSettingsJson['microCampaignNewslettermode'] == true &&
                                useMicroCampaignContactsCampaign != null && 
                                useMicroCampaignContactsCampaign != undefined"
                    class="microCampaignNewslettermodeDescription">
                        {{ 'MicroCampaign.Label.CampaignSelectedForNewsletter' | texttransform }}
                    </div>
                    
                    <div *ngIf="tableSettingsJson['showMicroCampaignCreationSwitch'] == true" class="entityTableAdditionalFilter__item">
                        <button class="everleadCustomButton" (click)="toggleValue('showMicroCampaignCreation')"
                            *ngIf="tableSettingsJson['showMicroCampaignCreation'] != true">
                            {{ 'MicroCampaign.Label.StartCampaign' | texttransform }}</button>
                    </div>            
        
                    <!-- Account && Contact -->
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showOnlyAccountVipsSettingVisible'] == true">                
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.OnlyVip'"
                            [value]="tableSettingsJson['showOnlyAccountVips']" (click)="toggleValue('showOnlyAccountVips')"></app-entitytable-additionaltoolbaritem>        
                    </div>
                    
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showAccountOwnerDistributionOption'] == true">                
                        <button class="everleadCustomButton" (click)="toggleValue('showAccountOwnerDistribution')"
                            *ngIf="tableSettingsJson['showAccountOwnerDistribution'] != true">
                            {{ 'AccountOwnerOrganisation.Label.StartEntityTableAssignment' | texttransform }}</button>
                    </div>
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showAccountOwnerNullValuesOption'] == true &&
                        tableSettingsJson['showAccountOwnerDistribution'] != true">                
                        <app-entitytable-additionaltoolbaritem [label]="'AccountOwnerOrganisation.Label.ShowAccountOwnerNullValues'"
                            [value]="tableSettingsJson['showAccountOwnerNullValues']" (click)="toggleValue('showAccountOwnerNullValues')"></app-entitytable-additionaltoolbaritem>        
                    </div>            
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showAccountOwnerDistribution'] == true">
                        <app-main-button type="small" (onClick)="tableSettingsJson['showAccountOwnerDistribution'] = false;" 
                            label="MicroCampaign.Label.BackToList"></app-main-button>
                    </div>
        
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showTastsWithoutContactSwitch'] == true">                
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.ShowTastsWithoutContact'"
                            [value]="tableSettingsJson['showTastsWithoutContact']" (click)="toggleValue('showTastsWithoutContact')"></app-entitytable-additionaltoolbaritem>        
                    </div>
        
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showOnlyContactVipsSettingVisible']">                
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.OnlyVip'"
                            [value]="tableSettingsJson['showOnlyContactVips']" (click)="toggleValue('showOnlyContactVips')"></app-entitytable-additionaltoolbaritem>
                    </div>
                    
                    <!-- Optin -->
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['doubleOptinOptionVisivle'] == true && tableSettingsJson['microCampaignNewslettermode'] != true">
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.DoubleOptInRequired'"
                            [value]="tableSettingsJson['doubleOptInRequired']" (click)="toggleValue('doubleOptInRequired')"></app-entitytable-additionaltoolbaritem>
                    </div>
        
                    <!-- VIP / Prospect -->
                        <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['vip'] == true">
                            <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.OnlyVip'"
                                [value]="tableSettingsJson['vipActive']" (click)="toggleValue('vipActive')"></app-entitytable-additionaltoolbaritem>
                        </div>
                        <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['blacklistedSwitch'] == true">
                            <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.DontShowBlacklistedItems'"
                            [value]="tableSettingsJson['filterBlacklisted']" (click)="toggleValue('filterBlacklisted')"></app-entitytable-additionaltoolbaritem>
                        </div>
                        <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['alreadyInEverleadSwitch'] == true">
                            <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.AlreadyInEverlead'"
                            [value]="tableSettingsJson['alreadyInEverlead']" (click)="toggleValue('alreadyInEverlead')"></app-entitytable-additionaltoolbaritem>        
                        </div>
                        <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['notInEverleadSwitch'] == true">
                            <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.NotInEverlead'"
                                [value]="tableSettingsJson['notInEverlead']" (click)="toggleValue('notInEverlead')"></app-entitytable-additionaltoolbaritem>        
                        </div>
                        <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showIspItemsSwitch'] == true">
                            <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.ShowIspItems'"
                                [value]="tableSettingsJson['showIspItems']" (click)="toggleValue('showIspItems')"></app-entitytable-additionaltoolbaritem>        
                        </div>
        
                    <!-- AI -->
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['aicontacts'] == true">
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.OnlyAIContacts'"
                            [value]="tableSettingsJson['aicontactsActive']" (click)="toggleValue('aicontactsActive')"></app-entitytable-additionaltoolbaritem>
                         <app-jj-icons type="info-fill" color="secondary" width="20" height="20" [tippy]="'EntityTableValue.Label.AISContatcsDescription' | texttransform"></app-jj-icons>           
                    </div>
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['aicontacts'] == true">
                        <app-entitytable-additionaltoolbaritem [label]="'EntityTableDesigner.Label.OnlyAIHighPotential'"
                            [value]="tableSettingsJson['aicontactsHighPotentialActive']" (click)="toggleValue('aicontactsHighPotentialActive')"></app-entitytable-additionaltoolbaritem>
                            <app-jj-icons type="info-fill" color="secondary" width="20" height="20" [tippy]="'EntityTableValue.Label.AISuggestionDescription' | texttransform"></app-jj-icons>        
                    </div>
                    <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['aicontacts'] == true">
                        {{ 'EntityTableDesigner.Label.MinLeadscore' | texttransform}}
                        <input [(ngModel)]="minLeadScore" (change)="getData()" type="number" class="entityTableAdditionalFilter__leadscore">
                    </div>
        
                    <!-- VIP Part 2 -->
                    <div *ngIf="tableSettingsJson['vip'] == true">
                        <button class="everleadCustomButton" (click)="showVIP()">{{ 'General.Menu.VipAlert' | texttransform}}</button>
                    </div>
                </div>
                </app-accordion>
            </div>
        </div>


        <div class="entityTableBodyBorder" [style.padding]="!isMainComponent ? 0 : undefined">
            <div
            #entityTable 
            (scroll)="onScrollX($event)"
            [ngClass]="{ 
                noHorizontalScrolling: loadingData && !lazyloading,
                entityTableBodyWrapper: isMainComponent, 
                entityTableInternalBodyWrapper: !isMainComponent 
            }">
        <!-- Hier beginnt der Header -->
            <div class="entityTableHeader"
                [id]="getControlId('entityTableDataContainerHeader')"
                [style.max-width]="getMaxHeaderWidth()" 
                [style.min-width]="getMinHeaderWidth()"
                [ngClass]="{ 
                    hideInDom: 
                        tableSettingsJson['showMicroCampaignCreation'] == true || 
                        tableSettingsJson['showAccountOwnerDistribution'] == true
                }">
                <div *ngFor="let tableLayoutItem of headerColumns; let i = index" class="entityTableHeaderItem fingerCursor"
                    [style.max-width]="getMaxWidth(tableLayoutItem)"
                    [style.min-width]="getMinWidth(tableLayoutItem)"
                >
                    <div class="entityTableHeaderItem__wrapper">
                        <div class="entityTableHeaderItem__label">
                            <div *ngIf="tableLayoutItem.filterable && tableLayoutItem.lookupTableId == null &&
                                        tableLayoutItem.alternateLookupTable == null &&
                                        (tableLayoutItem.controlType != 1 && tableLayoutItem.controlType != 2 && tableLayoutItem.controlType != 3) &&
                                        tableLayoutItem.hidefilterbox != true
                            " class="entityTableHeaderItem__label__filter">
                                
                            <div *ngIf="
                                selectedHeaderItemForFiltering != i && (columnFilter[tableLayoutItem.memberName] == null 
                                || columnFilter[tableLayoutItem.memberName] == '' || columnFilter[tableLayoutItem.memberName] == undefined)" (click)="selectedHeaderItemForFilter(i)">
                                    <label class="fingerCursor">{{ tableLayoutItem.displayText | texttransform }}</label>
                                    <app-jj-icons [type]="'magnifying-glass'" [height]="16" [width]="16" [color]="'secondary'"></app-jj-icons>  
                                </div>
                                <div *ngIf="
                                    selectedHeaderItemForFiltering == i || 
                                    (
                                        columnFilter[tableLayoutItem.memberName] != null && columnFilter[tableLayoutItem.memberName] != '' 
                                        && columnFilter[tableLayoutItem.memberName] != undefined
                                    )"
                                    class="headerFilterActive entityTableHeaderItem__highlighted">
                                    <input [id]="getHeaderItemForFilterId(i)" [(ngModel)]="columnFilter[tableLayoutItem.memberName]" (change)="setFilter()" 
                                        class="entityTableHeaderItem__input" [placeholder]="'General.Label.InsertSearchText' | texttransform"
                                        (focusout)="selectedHeaderItemForFilter(null)">
                                    <app-jj-icons [type]="'magnifying-glass'" [height]="16" [width]="16" [color]="'secondary'"></app-jj-icons>  
                                </div>
                            </div>

                            <!-- Filter -->
                            <div *ngIf="tableLayoutItem.filterable && 
                                        tableLayoutItem.lookupTableId != null && 
                                        (tableLayoutItem.controlType != 1 && 
                                        (tableLayoutItem.alternateLookupTable == null || tableLayoutItem.alternateLookupTable == undefined) &&
                                        tableLayoutItem.controlType != 2)"
                                [ngClass]="{ highlightedFilter: selectedHeaderItemForFiltering == i || 
                                    (
                                        columnFilter[tableLayoutItem.memberName] != null && columnFilter[tableLayoutItem.memberName] != '' 
                                        && columnFilter[tableLayoutItem.memberName] != undefined
                                    ) }"
                                class="entityTableHeaderItemFilterHeader" (click)="editMultifilter(tableLayoutItem)">
                                <label class="fingerCursor">{{ tableLayoutItem.displayText | texttransform }}</label>
                                <app-jj-icons [type]="'caret-down'" [height]="12" [width]="12" [color]="'secondary'"></app-jj-icons>  
                            </div>                            
                            
                            <!-- ALTERNATIVE MULTI FILTER -->
                            <div *ngIf="tableLayoutItem.filterable && 
                                        tableLayoutItem.alternateLookupTable != null && 
                                        (tableLayoutItem.controlType != 1 && 
                                        tableLayoutItem.controlType != 2)"
                                class="entityTableHeaderItemFilterHeader" 
                                [ngClass]="{ highlightedFilter: selectedHeaderItemForFiltering == i || 
                                    (
                                        columnFilter[tableLayoutItem.memberName] != null && columnFilter[tableLayoutItem.memberName] != '' 
                                        && columnFilter[tableLayoutItem.memberName] != undefined
                                    )}"
                                (click)="editAlternatefilter(tableLayoutItem)">
                                <label class="fingerCursor">{{ tableLayoutItem.displayText | texttransform }}</label>
                                <app-jj-icons [type]="'caret-down'" [height]="12" [width]="12" [color]="'secondary'"></app-jj-icons>  
                            </div>

                            <!-- Datumsfilter -->
                            <div *ngIf="tableLayoutItem.filterable && (tableLayoutItem.controlType == 1 || tableLayoutItem.controlType == 2)" 
                                class="entityTableHeaderItemFilterHeader" 
                                [ngClass]="{ highlightedFilter: selectedHeaderItemForFiltering == i || 
                                    (
                                        columnFilter[tableLayoutItem.memberName] != null && columnFilter[tableLayoutItem.memberName] != '' 
                                        && columnFilter[tableLayoutItem.memberName] != undefined
                                )}" (click)="editDateFilter(tableLayoutItem)">
                                <label class="fingerCursor">{{ tableLayoutItem.displayText | texttransform }}</label>
                                <app-jj-icons [pointer]="true" [type]="'caret-down'" [height]="12" [width]="12" [color]="'secondary'"></app-jj-icons>  
                            </div>
                            <div *ngIf="!tableLayoutItem.filterable">{{ tableLayoutItem.displayText | texttransform }}</div>
                        </div>
                        <div class="entityTableHeaderItem__sort fingerCursor" *ngIf="tableLayoutItem.sortable" (click)="sortDataByColIndex(tableLayoutItem, true)">
                            <app-jj-icons [pointer]="true" [type]="'arrow-up'" [height]="12" [width]="12" [color]="'secondary'" class="entityTableHeaderItem__sort__hover" *ngIf="selectedSortCol.columnName != tableLayoutItem.memberName"></app-jj-icons>  
                            <app-jj-icons [pointer]="true" [type]="'arrow-up'" [height]="12" [width]="12" [color]="'secondary'" *ngIf="selectedSortCol.id == tableLayoutItem.memberName + 'ASC'"></app-jj-icons>  
                            <app-jj-icons [pointer]="true" [type]="'arrow-down'" [height]="12" [width]="12" [color]="'secondary'" *ngIf="selectedSortCol.id == tableLayoutItem.memberName + 'DESC'"></app-jj-icons>  
                        </div>
                    </div>
                </div>
            </div>

            <!-- Hier beginnen die Daten! -->
            <span id="getMaxHeightSpan"></span>
            
            <!-- Owner assignment -->
            <div [id]="getControlId('entityTableDataContainer')" class="entityTableDataContainer entityTableMicroCampaignContainer"
                [style.max-height.px]="getMaxHeight()" 
                [style.min-width]="getMinHeaderWidth()"             
                *ngIf="tableSettingsJson['showAccountOwnerDistribution'] == true">
                <div class="entityTableOwnerAssignmentUserInfo">
                    {{ 'AccountOwnerOrganisation.Label.MoveAccountOwnersMultipleEntityTableText' | texttransform }}
                </div>
                <div class="entityTableOwnerAssignmentUserList">
                    <div *ngFor="let user of applicationInfoService.projectUsers.toArray()" [ngClass]="{ accountOwnerUserActive: isAccountOwnerUser(user) }"
                        class="entityTableOwnerAssignmentUserList__item everleadTableRow" (click)="toggleAccountOwnerUser(user)">
                        {{ user.userName }}
                    </div>    
                </div>
                <div class="entityTableAccountAssignmentReturn">
                    <app-main-button type="small" (onClick)="assignAccountOwners()" label="AccountOwnerOrganisation.Label.AssignEntityAccountOwners"></app-main-button>
                </div>
            </div>

            <!-- MicroCampaign -->
            <div [id]="getControlId('entityTableDataContainer')" class="entityTableDataContainer entityTableMicroCampaignContainer" 
                [style.max-height.px]="getMaxHeight()" 
                [style.min-width]="getMinHeaderWidth()"                
                *ngIf="tableSettingsJson['showMicroCampaignCreation'] == true">
                
                <div class="entityTableMicroCampaignHeader">{{ 'MicroCampaign.Label.EntityTableHeader' | texttransform }}</div>
                <div class="entityTableMicroCampaignSubHeader">{{ getMicroCampaignSubHeader() | texttransform }}</div> 
                <div class="entityTableMicroCampaignStarterList">
                    <div class="entityTableMicroCampaignStarterListItem everleadTableRow" *ngFor="let microcampaign of microcampaignService.microcampaigns" [ngClass]="{
                        hideInDom: microcampaign.nextStepCampaignId == null && applicationInfoService.applicationSettings['isDemoproject'] != 'true'
                    }">
                        <div class="entityTableMicroCampaignStarterListItem__name">
                            <!-- <span *ngIf="applicationInfoService.isDeveloper" class="entityTableMicroCampaignStarterListItem__id everleadCoral">{{ microcampaign.id }}</span> -->
                            {{ microcampaign.defaultName | texttransform }}</div>
                        <div class="entityTableMicroCampaignStarterListItem__status">
                            <app-main-button type="borderSmall" (onClick)="smartStartMicroCampaign(microcampaign)" label="MicroCampaign.Label.StartCampaign"></app-main-button>
                        </div>
                    </div>
                </div>
                <div class="entityTableMicroCampaignReturn">
                    <app-main-button type="small" (onClick)="tableSettingsJson['showMicroCampaignCreation'] = false;
                        tableSettingsJson['additionalToolbar'] = true" label="MicroCampaign.Label.BackToList"></app-main-button>
                </div>
            </div>

      <!--       [style.min-width]="getMinHeaderWidth()" -->
            <!-- Normal data -->
            <div *ngIf="!chartView" [id]="getControlId('entityTableDataContainer')" class="entityTableDataContainer" 
                (scroll)="checkBottom($event)"
                [style.max-height.px]="getMaxHeight()"
                [style.min-width]="getScrollWidthX()"
                [style.max-width]="getTableMaxWidth()"
                [ngClass]="{ hideInDom: 
                    tableSettingsJson['showMicroCampaignCreation'] == true || 
                    tableSettingsJson['showAccountOwnerDistribution'] == true
                }"
                [style.overflow-x]="'hidden'"
            >
                <div *ngIf="controlData.totalCount == 0 && !loadingData" class="entityTableNoData">
                    {{ 'General.Label.NoDataAvailable' | texttransform }}
                </div>

                <div *ngIf="loadingData" class="loadingDataSpinner">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
                </div>
                <div *ngFor="let dataItem of controlDataValues; let rowIndex = index"                
                    class="entityTableData everleadTableRow"
                    (click)="cellClickedEvent(dataItem)"
                    (dblclick)="cellDblClickedEvent(dataItem)"
                    [ngClass]="{
                        hideInDom: loadingData && !lazyloading,                        
                        fingerCursor: controlDefinition?.methods?.length > 0
                    }"
                    [style.height]="getDataRowHeight(rowIndex)"
                    [style.background-color]="getDataRowBackgroundColor()"
                >
                    <div *ngFor="let tableLayoutItem of headerColumns; let itemIndex = index"
                        class="entityTableDataItem maxHeight"
                        [id]="getCellId(rowIndex, itemIndex)"
                        [style.min-width]="getMinWidth(tableLayoutItem)"
                        [style.max-width]="getMaxWidth(tableLayoutItem)"
                        [ngClass]="{    'd-flex': tableLayoutItem.subControlsInRow,
                                        noPadding: tableLayoutItem.controlType == 12 ||
                                                tableLayoutItem.controlType == 13 ||
                                                tableLayoutItem.controlType == 16 ||
                                                tableLayoutItem.controlType == 17,
                                        entityTableDataItem__border: itemIndex > 0
                                    }">
                        <app-entitytable-valuecontrol
                            [value]="dataItem[getTableLayoutItemDataFieldName(tableLayoutItem)]"
                            [valueline]="dataItem"
                            [dataTableItem]="dataTableItem"
                            [darkmode]="tableSettingsJson['darkMode']"
                            [control]="tableLayoutItem"
                            [userRoles]="userRoles"
                            [isSmallControlWidth]="getEntityTableWidth() < 700"
                            [subcontrols]="subControlArray(subControls[tableLayoutItem.entityId + tableLayoutItem.memberName])"
                        >
                        </app-entitytable-valuecontrol>
                        
                        <app-entitytable-valuecontrol
                            *ngFor="let tableLayoutItemSubControl of subControls[tableLayoutItem.entityId + tableLayoutItem.memberName]"
                            [value]="dataItem[getTableLayoutItemDataFieldName(tableLayoutItemSubControl)]"
                            [valueline]="dataItem"
                            [control]="tableLayoutItemSubControl"
                            [userRoles]="userRoles"
                            [dataTableItem]="dataTableItem"
                            [darkmode]="tableSettingsJson['darkMode']"
                            [isSmallControlWidth]="getEntityTableWidth() < 700"
                            subcontrol=true
                        >
                        </app-entitytable-valuecontrol>
                
                    </div>
                </div>
            </div>

            <div id="lazyLoadingSection" class="entityTableLazyLoadingSection" *ngIf="lazyloading">
                <div class="entityTableLazyLoadingSection__inner">
                    <i class="fas fa-spinner fa-spin"></i>{{ 'General.Label.LoadingData' | texttransform }}
                </div>
            </div>
            </div>
        </div>
    </div>
    <div *ngIf="tableSettingsJson['canStartMicroCampaign'] == true || tableSettingsJson['canStartMailshot'] == true"
        class="entityTablebottomToolbar everleadShadowBox2" [style.background-color]="getTableMenuBackgroundColor()">
        <div>
            <select class="microCampaignList" [(ngModel)]="selectedMicroCampaign">
                <option *ngFor="let microcampaign of microcampaigns" [ngValue]="microcampaign">{{ microcampaign.defaultName | texttransform }}</option>
            </select>
            <button class="everleadCustomButton" *ngIf="tableSettingsJson['canStartMicroCampaign'] == true" (click)="startMicroCampaign()">
                {{'ConsentManagementDashboard.Label.StartMicroCampaign'|texttransform}}
            </button>
        </div>
        <button class="everleadCustomButton" *ngIf="tableSettingsJson['canStartMailshot'] == true">
            {{'ConsentManagementDashboard.Label.StartMailShot'|texttransform}}
        </button>
    </div>
</div>

<!-- Spinner während projectData abgeholt wird -->
<span *ngIf="applicationInfoService.getCompleteProjectInformatonLoaded==false">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</span>

<!-- Wenn keine Definition gefunden wurde -->
<div class="entityTable__wrapper" *ngIf="!contentFound && contentSearched && applicationInfoService.getCompleteProjectInformatonLoaded">
    <div class="everleadShadowBox entityTableNewDefinition">
        <label>{{ 'EntityTableDesigner.Label.NoDefinitionFound' | texttransform }}</label>
        <button class="everleadCustomButton" (click)="showEntityTableDesigner()">{{ 'EntityTableDesigner.Label.CreateDefinition' | texttransform }}</button>
    </div>
</div>


<div [id]="getFilterBoxId()" [ngClass]="{ hideInDom: lookupFilter==false }" class="entityTableMultiFilterBox" [style.top]="getMultifilterTop()">    
    <div class="everleadShadowBox entityTableMultiFilterBox__inner__wrapper">
        <div class="entityTableMultiFilterBox__toolbar">
            <div class="entityTableMultiFilterBox__toolbar__label">{{ 'General.Label.Filter' | texttransform }}</div>
            <div class="entityTableMultiFilterBox__toolbar__buttons">
                <app-jj-icons type="x" [width]="20" [height]="20" (click)="lookupFilter = false"></app-jj-icons>
            </div>
        </div>
        <div class="entityTableMultiFilterBox__inner">
            <div *ngFor="let multiListFilterItemsItem of multiListFilterItems" class="entityTableMultiFilterBox__inner__item" [ngClass]="{
                hideInDom: isItemFiltered(multiListFilterItemsItem) || checkIfFilterItemIsDoublicate(multiListFilterItemsItem)
            }">
                <app-jj-icons [type]="'square'" [width]="16" [height]="16" (click)="clickFilterItem(multiListFilterItemsItem)" 
                    *ngIf="filterLookupItem.filter[multiListFilterItemsItem.id] != true" [pointer]="true"></app-jj-icons>
                <app-jj-icons [type]="'check-square'" [width]="16" [height]="16" (click)="clickFilterItem(multiListFilterItemsItem)"
                    *ngIf="filterLookupItem.filter[multiListFilterItemsItem.id] == true" [pointer]="true"></app-jj-icons>
    
                <!-- <input type="checkbox" [(ngModel)]="filterLookupItem.filter[multiListFilterItemsItem.id]" (change)="checkEqualItems(multiListFilterItemsItem)"> -->
                <label class="filterBoxLabel ellipsisText" *ngIf="filterLookupItem['filterListDisplayValue'] == null">{{ commonService.getNameTranslationOrDefaultName(multiListFilterItemsItem) | texttransform }}</label>
                <label class="filterBoxLabel ellipsisText" *ngIf="filterLookupItem['filterListDisplayValue'] != null">{{ multiListFilterItemsItem[filterLookupItem['filterListDisplayValue']] | texttransform }}</label>
            </div>
        </div>
        <div class="entityTableDateFilterBox__buttons">
            <button class="everleadCustomButton" (click)="removeMultiFilter()">{{ 'EntityTableDesigner.Label.RemoveSelection' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="setMultiFilter()">{{ 'EntityTableDesigner.Label.SetFilter' | texttransform }}</button>
        </div>    
    </div>
</div>

<!-- Filterbox (Daterange)-->
<!-- <div *ngIf="dateFilter" class="entityTableMultiFilterBoxBackground"></div> -->
<div *ngIf="dateFilter" class="entityTableMultiFilterBox entityTableDateFilterBox">
    <div class="entityTableMultiFilterBox__header">
        <div class="entityTableMultiFilterTitle">
            <label class="ellipsisText">{{ 'EntityTableDesigner.Label.SelectDateRange' | texttransform }}</label>
            <div>
                <svg-icon src="assets/images/svg/close.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" class="fingerCursor" (click)="dateFilter=false"></svg-icon>
            </div>
        </div>
    </div>
    <div class="entityTableMultiFilterBox__inner">
        <div class="">
            <div class="entityTableDateFilterBox__line">
                <label>{{ 'EntityTableDesigner.Label.DateFilterFrom' | texttransform }}</label>
                <input [(ngModel)]=dateRangeFilterItem.dateFilter.dateFrom [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly  appendTo="body" aria-modal="true">
                <owl-date-time #dt7></owl-date-time>
            </div>
            <div class="entityTableDateFilterBox__line">
                <label>{{ 'EntityTableDesigner.Label.DateFilterUntil' | texttransform }}</label>
                <input [(ngModel)]=dateRangeFilterItem.dateFilter.dateUntil [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly  appendTo="body" aria-modal="true">
                <owl-date-time #dt8></owl-date-time>
            </div>
        </div>
        <div class="entityTableDateFilterBox__buttons">
            <button class="everleadCustomButton" (click)="removeDateFilter()">{{ 'EntityTableDesigner.Label.RemoveSelection' | texttransform }}</button>
            <button class="everleadCustomButton" [disabled]="dateRangeFilterItem.dateFilter.dateFrom == null || dateRangeFilterItem.dateFilter.dateUntil == null"
                (click)="setDateFilter()">{{ 'EntityTableDesigner.Label.SetFilter' | texttransform }}</button>
        </div>
    </div>
</div>
