import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { WizardMode, WizardService } from '../wizard/wizard.service';
import { EventbookingBatchType, EventbookingbatchService } from './eventbookingbatch.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-eventbookingbatch',
  templateUrl: './eventbookingbatch.component.html',
  styleUrls: ['./eventbookingbatch.component.scss']
})
export class EventbookingbatchComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  batchTypeLookupId = 0;
  eventBookingBatches = [];
  filterString = '';

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,    
    public userService: UserService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private wizardService: WizardService,
    public eventbookingbatchService: EventbookingbatchService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'updateBookingBatch' || event == 'getCompleteProjectInformaton' || event.type == 'updateEMailBatch') {
        this.getEventBookingBatches();
      }
    });
    switch (this.route.url['value'][0].path) {
      case 'bookingbatch':
        this.batchTypeLookupId = EventbookingBatchType.MicroCampaign;
        break;
      case 'bookingbatchmailshot':
        this.batchTypeLookupId = EventbookingBatchType.Mailshot;
        break;
    }
    this.getEventBookingBatches()
  }

  ngOnDestroy(): void {
      if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getEventBookingBatches() {
    if (this.applicationInfoService.getCompleteProjectInformatonLoaded == false) { return; }
    this.externaldatasourceService.executeExternalDataSource(this.eventbookingbatchService.getBookingBatchExternalDataSourceId).then(getEventBookingBatchesResult => { 
      getEventBookingBatchesResult.sort((a, b) => a.id > b.id ? -1 : 1);
      this.eventBookingBatches = getEventBookingBatchesResult.filter(batch => 
        batch.batchTypeLookupId == this.batchTypeLookupId &&
        batch.isDeleted == false
      );       
      this.eventBookingBatches.forEach(batchType => {
        batchType['wizardValueArray'] = JSON.parse(batchType.wizardValues);
      });
      if (this.applicationInfoService.campaingnModeId != null) {
        this.eventBookingBatches = this.eventBookingBatches.filter(batch => batch.wizardValueArray.campaignId == this.applicationInfoService.campaingnModeId);
      }      
    });    
  }

  changeFollowUpDate(eventBookingBatch) {
    this.eventbookingbatchService.updateBatch(eventBookingBatch);
  }

  editMicroCampaign(microCampaign) {
    const wizardArray = JSON.parse(microCampaign.wizardValues);
    this.wizardService.showWizard(WizardMode.MicroCampaignBatch, wizardArray);
  }

  editMailshot(eventBookingBatch) {
    const wizardArray = JSON.parse(eventBookingBatch.wizardValues);
    this.wizardService.showWizard(WizardMode.Mailshot, wizardArray);
  }

  createNewBookingBatch(newsletter = false) {
    switch (this.batchTypeLookupId) {
      case this.batchTypeLookupId = EventbookingBatchType.MicroCampaign:
        this.eventbookingbatchService.createMicroCampaign('New batch', EventbookingBatchType.MicroCampaign, true, [], [], 0, newsletter)
        .then(() => { this.getEventBookingBatches(); });
        break;
      case this.batchTypeLookupId = EventbookingBatchType.Mailshot:
        this.eventbookingbatchService.createMicroCampaign('New batch', EventbookingBatchType.Mailshot, true, [], [], 0, newsletter)
        .then(() => { this.getEventBookingBatches(); });
        break;
    }
  }

  isFiltered(eventBookingBatch) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(eventBookingBatch.batchName, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  showStats(eventBookingBatch) {

    if(eventBookingBatch.startedAt == null){
      return
    } else {
      this.applicationInfoService.miscSettings['eventBookingBatch'] = eventBookingBatch;
      this.eventService.showJjPopup('Mailshots.Label.Details', 'emailbatchdetails', '100');
    }
  }
}
