import { Component, OnInit, Injector, OnDestroy, AfterViewInit } from '@angular/core';
import { ValueType } from '../../../../jollyjupiter/service/entity.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { Subscription } from 'rxjs';
import { isArray, isNullOrUndefined } from 'util';
import { ObjectDeserializeService } from 'app/jollyjupiter/pipe/object-deserialize.service';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent extends CustomControlBaseClass implements OnInit, AfterViewInit, OnDestroy {
  updateCustomEventSubscription: Subscription = new Subscription();
  updateControlContentEventSubscription: Subscription = new Subscription();
  updateLookupTableEventSubscription: Subscription = new Subscription();
  dropdownGuid = '';

  constructor(
    private injector: Injector,
    private objectDeserializeService: ObjectDeserializeService
  ) {
    super(injector);
    this.valueType = ValueType.Number;
  }

  clearValue() {
    this.controlValue = null;
    this.callMethod('onchange');
  }

  ngOnDestroy() {    
    if (this.updateCustomEventSubscription) { this.updateCustomEventSubscription.unsubscribe(); }
    if (this.updateControlContentEventSubscription) { this.updateControlContentEventSubscription.unsubscribe(); }
    if (this.updateLookupTableEventSubscription) { this.updateLookupTableEventSubscription.unsubscribe(); }
  }

  ngAfterViewInit(): void {
    setTimeout(() => { 
      this.getDropdownData();
      this.checkAttributes();
    }, 200);
  }

  ngOnInit() {    
    this.updateCustomEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id=='refreshLookupTables') {
        this.getDropdownData();
      }
    });

    this.updateLookupTableEventSubscription = this.eventService.updateLookupTableEvent.subscribe(event => {
      if (!isNullOrUndefined(this.entityMemberlookupTable)) {
        if (Number(event.lookupTableId) === Number(this.entityMemberlookupTable.lookupTableId)) {
          this.getDropdownData();
        }
      }
    });

    this.updateControlContentEventSubscription = this.eventService.updateControlContentEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        // console.warn('updateControlContentEventSubscription - valid', this.controlDefinition.id, event);
        this.getDropdownData();
        this.onControlValueChanged(null);
      }
    });

    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }    
  }

  getDropdownData() {
    if (this.controlSettings['dropdownUseFullProjectUserAsData'] == true) {
      this.data = [];
      return;
    }
    let alternateLookupTable = this.controlDefinition.additionalSetting3;
    if (this.uiService.getControlSettingOrDefault(this.controlDefinition, 'dropdownAlternateLookupTable', null) != null) {
      alternateLookupTable = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'dropdownAlternateLookupTable', null);
    }

    if (alternateLookupTable != '' && alternateLookupTable != null) {
      const lookupTableInfo = alternateLookupTable.split(';');
      const tableData = this.applicationInfoService.projectLookupTables.find(
        projectLookupTable => projectLookupTable.definitionId == Number(lookupTableInfo[1]) && projectLookupTable.source == lookupTableInfo[0]        
      );
      if (tableData) {
        this.data = tableData.lookups;
      }    
      return;  
    }
    
    if (this.controlDefinition.isActive == false) {
      return;
    }

    if (this.externalDataSource) {
      this.dataKey = this.externalDataSource.dataKey;
      this.loadingControlData = true;
      this.externalDatasourceService.executeExternalDataSource(this.externalDataSource.id)
        .then(data => {
          this.cleanUpResult(data);
        })
        .catch(error => {
          this.loadingControlData = false;
          console.error(error);
        });
    } else {
      this.loadingControlData = true;
      if (!isNullOrUndefined(this.entityMemberlookupTable)) {
        this.externalDatasourceService.executeLookUpTable(this.entityMemberlookupTable)
        .then(result => {
          this.cleanUpResult(result);
        })
        .catch(error => {
          this.loadingControlData = false;
          console.error(error);
        });
      }
    }
  }

  cleanUpResult(data) {
    this.loadingControlData = false;
    this.data = data;
    if (this.data) {
      if (isArray(this.data)) {
        this.data.forEach(dataElement => {
          if (!isNullOrUndefined(this.externalDataSource)) {
            if ((this.externalDataSource.dataValue === 'defaultName') && (dataElement.nameTranslationToken)) {
              dataElement.defaultName = dataElement.nameTranslationToken.tokenFullName;
            }
          } else {
            if (!isNullOrUndefined(dataElement.nameTranslationToken)) {
              dataElement.defaultName = dataElement.nameTranslationToken.tokenFullName;
            }
          }
        });
      } else {
        this.data = [data];
      }
    }
  }

  onControlValueChanged(event) {
    this.controlValue = event;
    this.callMethod('onchange');
  }

  setDropdownStyle() {
    if (this.applicationInfoService.useNewDesign) {
      return {
        'width': '100%'
      };
    } else {
      return {
        'width': '100%',
        'height': this.controlDefinition.height
      };
    }
  }

  setButtonStyle() {
    return {
      'width': '10%',
      'height': this.controlDefinition.height
    };
  }
}
