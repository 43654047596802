
    
<div class="toolbarPopupOuter" *ngIf="showPopup" (click)="closePopup()">
    <div class="toolbarPopup" *ngIf="showPopup">
        <div class="toolbarArrow" [style.right]="arrowRight"></div>
        <div class="toolbarPopup__content">
            <div *ngIf="section == 'showDemoLandingPageNotVisitedInfo'" class="showDemoLandingPageNotVisitedInfo">
                {{ 'Toolbar.Label.ShowDemoLandingPageNotVisitedInfo' | texttransform }}
            </div>

        </div>
    </div>
</div>


