import { AfterContentInit, Component, EventEmitter, Input , OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrl: './action-button.component.scss'
})

export class ActionButtonComponent implements OnInit, AfterContentInit, OnChanges{
  
  constructor(){}
  
  @Input() id: string | number | null = null ;
  @Input() disabled: boolean | null = false;
  @Input() buttons: Array<{svg:string; label:string; callback: () => void }> = [];
  @Input() svg: string | any = null;
  @Input() data: any | null;
  @Input() type: 'small' | null = null;
  @Input() width:number | null = null;
  @Input() height: number | null = null;

  @Output() onClick= new EventEmitter<any>();

  color: string = 'fontDark'

  actionbuttonsProps={
      placement:'right',
      theme:'light-border',
      trigger:'mouseenter',
      touch:true,
      interactive:true,
      appendTo:document.body,
      arrow:false,
      // todo if needed offset: [0, 10],
  }


  ngOnInit(){
  }


  ngAfterContentInit(): void {

    // todo if different componentes for different positions
    this.actionbuttonsProps={
      placement:'right',
      theme:'light-border',
      trigger:'mouseenter',
      touch:true,
      interactive:true,
      appendTo:document.body,
      arrow:false,
  }
      
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['data'] && changes['data'].previousValue && !changes['data'].firstChange){
        this.data = changes['data'].currentValue;
        this.buttons = changes['buttons'].currentValue;
      }
  }



  buttonClick(){
    this.onClick.emit()
  }


  arrayButtonClick(callback:(data?:any, context?:any) => void){
/*     console.log('callback', callback) */
    if(callback){
      callback(this.data, this)
    }

  }

}


