<div *ngIf="applicationInfoService.isDeveloper">
    <div class="everleadShadowBox everleadHorizontalFlex">
        <label class="adminMainHeaderLabel">Adminbereich:</label>
        <select [(ngModel)]="selectedTab" class="maxWidth">
            <option [ngValue]=1>{{ 'AdminPanel.Label.Customer' | texttransform }}</option>
            <option [ngValue]=2>{{ 'AdminPanel.Label.Entity' | texttransform }}</option>
            <option [ngValue]=3>Berechtigungen verwalten</option>
            <option [ngValue]=4>Job-Verwaltung</option>
            <option [ngValue]=5>Report-Verwaltung</option>
            <option [ngValue]=6>FTP-Verwaltung</option>
            <option [ngValue]=7>ExternalDataSources</option>
            <option [ngValue]=8>Scoring-Parameter</option>
            <option [ngValue]=9>Scoring-Content</option>
            <option [ngValue]=10>UiControl Method Parameter Information</option>
            <option [ngValue]=11>Filter</option>
            <option [ngValue]=12>Rechte anlegen</option>
            <option [ngValue]=13>AWS Identities</option>
            <option [ngValue]=14>Distribution Lists</option>
            <option [ngValue]=15>Dashboards</option>
            <option [ngValue]=16>AI Prompts</option>
            <option [ngValue]="17">Application Settings</option>
        </select>
    </div>
    
    <span id="adminContentSpan"></span>
    <div [style.height.px]="uiService.getDesignSpanPosition('adminContentSpan', 40)">    
        <app-admin-customer *ngIf="selectedTab == 1" class="maxHeight adminContentBlock"></app-admin-customer>
        <app-admin-entity *ngIf="selectedTab == 2" class="maxHeight adminContentBlock"></app-admin-entity>
        <app-user-rights *ngIf="selectedTab == 3" class="maxHeight adminContentBlock"></app-user-rights>
        <app-admin-jobs *ngIf="selectedTab == 4" class="maxHeight adminContentBlock"></app-admin-jobs>
        <app-admin-reports *ngIf="selectedTab == 5" class="maxHeight adminContentBlock"></app-admin-reports>
        <app-admin-ftp *ngIf="selectedTab == 6" class="maxHeight adminContentBlock"></app-admin-ftp>
        <app-admin-external-datasources *ngIf="selectedTab == 7" class="maxHeight adminContentBlock"></app-admin-external-datasources>
        <app-admin-scoringparameter *ngIf="selectedTab == 8" class="maxHeight adminContentBlock"></app-admin-scoringparameter>
        <app-admin-scoringcontenttype *ngIf="selectedTab == 9" class="maxHeight adminContentBlock"></app-admin-scoringcontenttype>
        <app-admin-uicontrolmethodparameterinformation *ngIf="selectedTab == 10" class="maxHeight adminContentBlock"></app-admin-uicontrolmethodparameterinformation>    
        <app-admin-filter *ngIf="selectedTab == 11" class="maxHeight adminContentBlock"></app-admin-filter>
        <app-admin-managerights *ngIf="selectedTab == 12" class="maxHeight adminContentBlock"></app-admin-managerights>
        <app-admin-awsidentities *ngIf="selectedTab == 13" class="maxHeight adminContentBlock"></app-admin-awsidentities>
        <app-emaildistributor *ngIf="selectedTab == 14" class="maxHeight adminContentBlock"></app-emaildistributor>
        <app-admin-dashboards *ngIf="selectedTab == 15" class="maxHeight adminContentBlock"></app-admin-dashboards>
        <app-admin-aiprompts *ngIf="selectedTab == 16" class="maxHeight adminContentBlock"></app-admin-aiprompts>
        <app-admin-application-settings *ngIf="selectedTab == 17" class="maxHeight adminContentBlock"></app-admin-application-settings>
    </div>
</div>

<div *ngIf="!applicationInfoService.isDeveloper">
    You shouldn't be here...
</div>