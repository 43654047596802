import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss'
})
export class StatusComponent implements OnInit{

  @Input() component:string | null = null ;
  @Input() label:string | null = null;
  @Input() noLabelColor: boolean | null = null;
  @Input() tooltipOptions: any | undefined = { placement:'bottom' };
  @Input() showtippy: boolean = true;


  @Input() blue: boolean  = false;
  @Input() green: boolean = false;
  @Input() yellow: boolean = false;
  @Input() red: boolean = false;
  @Input() orange: boolean = false;
  @Input() color: string | null = null;


  @Input() width: number;
  @Input() height: number;
  @Input() isOdd = false;
  @Input() ellipsis = false;

  ngOnInit() {

/*     console.log('tooltip', this.tooltipOptions); */
/*     console.log('this.noLabel', this.noLabelColor);
    console.log('THIS LABEL', this.label); */
  }

  getColor(){

    if(this.color){
      return this.color;
    }

    if(this.blue){
      return '#5465FF'
    }

    if(this.green){
      return '#3FE163';
    }

    if(this.yellow){
      return '#FFD600';
    }

    if(this.red){
      return '#FF5050';
    }

    if(this.orange){
      return '#FF6B00';
    }
    
  }

}
