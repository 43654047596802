<div class="selectedDashboard">
    <app-layout [header]="selectedReport?.defaultName" [subheader]="selectedReport?.subHeader">
        <div class="selectedDashboard__headerNav">
            <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back" type="small"></app-main-button>
       </div> 
        <div class="selectedDashboard__innerBorderLayer">
            <div class="selectedDashboard__nav">
                <div class="selectedDashboard__filterMobile">
                    <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                        <div class="defaultDashboardLeadDevToolbar">
                            <app-date-input [calendarOnly]="true" label="OptInOptOut.Label.StartDate" [(ngModel)]="dateFrom" (dateChange)="getDashboardDetails()"></app-date-input>	
                            <app-date-input [calendarOnly]="true" label="OptInOptOut.Label.EndDate" [(ngModel)]="dateUntil" (dateChange)="getDashboardDetails()"></app-date-input>	
                            <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDetails()"></app-select-input>
                            <app-select-input *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')" label="Task.Label.Campaign" [(ngModel)]="campaignId" [options]="campaigns" display="defaultName" bind="id" (valueChange)="getDashboardDetails()"></app-select-input>
                            <app-select-input label="Task.Label.ResultUser" [(ngModel)]="userId" [options]="users" display="userName" bind="id" (valueChange)="getDashboardDetails()"></app-select-input>
                        </div>
                    </app-accordion>
                </div>

                <div class="selectedDashboard__filter">
                    <app-date-input [calendarOnly]="true" label="OptInOptOut.Label.StartDate" [(ngModel)]="dateFrom"
                        (dateChange)="getDashboardDetails()"></app-date-input>
                    <app-date-input [calendarOnly]="true" label="OptInOptOut.Label.EndDate" [(ngModel)]="dateUntil"
                        (dateChange)="getDashboardDetails()"></app-date-input>
                    <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName"
                        bind="id" (valueChange)="getDashboardDetails()"></app-select-input>
                    <app-select-input *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                        label="Task.Label.Campaign" [(ngModel)]="campaignId" [options]="campaigns" display="defaultName" bind="id"
                        (valueChange)="getDashboardDetails()"></app-select-input>
                    <app-select-input label="Task.Label.ResultUser" [(ngModel)]="userId" [options]="users" display="userName"
                        bind="id" (valueChange)="getDashboardDetails()"></app-select-input>
                </div>
            </div>

            <div *ngIf="loadingData == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </div>

            <div class="selectedDashboard__content">
                <div *ngIf="loadingData == false" class="defaultDashboardLeadDevTableContainer">
                    <div *ngFor="let userWithDataItem of userWithData" class="everleadTableRow">
                        <div class="failedEMailUser">
                            <div class="failedEMailUser__name">{{ userWithDataItem }}</div>                          
                            <div class="failedEMailUser__failedMails">{{ getDataFromUser(userWithDataItem).length }} 
                                {{ 'Dashboard.Label.FaileMailsCount' | texttransform }}
                            </div>
                            <div class="failedEMailUser__button">
                                <app-jj-icons type="eye" [width]="20" [height]="20" (click)="showUserDetails(userWithDataItem)"></app-jj-icons>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedUser != null" class="failedUserDetailViewContainer">
                <app-layout [header]="selectedUser">
                    <div class="failedEMailUserItem__navigation">
                        <app-checkbox label="General.Label.ShowDetails" [(ngModel)]="showMoreDetails"></app-checkbox>
                        <app-jj-icons type="x" [width]="20" [height]="20" (click)="selectedUser = null"></app-jj-icons>
                    </div>
                    <div class="failedEMailUserItem failedEMailUserItem__header">
                        <div class="failedEMailUserItem__info">{{ 'Account.Label.Header' | texttransform }}</div>
                        <div class="failedEMailUserItem__date">{{ 'General.Label.Date' | texttransform }}</div>
                        <div class="failedEMailUserItem__email">{{ 'Contact.Label.EMail' | texttransform }}</div>
                        <div class="failedEMailUserItem__button"></div>
                    </div>
                    <div *ngFor="let dataItem of getDataFromUser(selectedUser)" class="everleadTableRow">
                        <div class="failedEMailUserItem">
                            <div class="failedEMailUserItem__info">{{ dataItem.account_name }}</div>
                            <div class="failedEMailUserItem__date">{{ dataItem.result_date | date:'medium' }}</div>
                            <div class="failedEMailUserItem__email">{{ dataItem.account_email }} </div>                            
                            <div class="failedEMailUserItem__button">
                                <app-jj-icons type="eye" [width]="20" [height]="20" 
                                    (click)="showAccount(dataItem)"></app-jj-icons>            
                            </div>                            
                        </div>
                        <div *ngIf="showMoreDetails" class="detailPanel">
                            <div class="failedEMailUserItem__note">{{ dataItem.qm_rejected_text }}</div>    
                        </div>         
                    </div>    
                </app-layout>
            </div>
        </div>
    </app-layout>
</div>