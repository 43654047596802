import { Injectable } from '@angular/core';
import { ExternaldatasourceService } from './externaldatasource.service';
import { CommonService } from './common.service';
import { ProjectsettingsService } from './projectsettings.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

export enum PolicyState {
  None = "none",
  DataRights = "datarights",
  Hierarchy = "hierarchy"
}

@Injectable({
  providedIn: 'root'
})

export class DataaccessService {
  currentPolicy = 'none';

  constructor(
    private commonService: CommonService,
    private externalDataSourceService: ExternaldatasourceService,
    private projectSettingsService: ProjectsettingsService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  getCurrentPolicy() {
    this.projectSettingsService.getProjectSettings();
    let policyType = this.projectSettingsService.projectSettings.find(setting => setting.setting == 'activePolicyType');
    if (policyType) {
      this.currentPolicy = policyType.value;
      return policyType.value;
    } else {
      return PolicyState.None;
    }
  }

  setSecurityPolicy(PolicyState): Promise<any> {
    return new Promise((setSecurityPolicyResolve, setSecurityPolicyReject) => {
      this.externalDataSourceService.executeExternalDataSource(1019, [PolicyState]).then(() => {
        let policyType = this.projectSettingsService.projectSettings.find(setting => setting.setting == 'activePolicyType');
        if (policyType) {
          policyType.value = PolicyState;
          this.projectSettingsService.updateProjectSetting(policyType);
        } else {
          this.projectSettingsService.createProjectSetting({
            projectId: this.applicationInfoService.projectID,
            setting: 'activePolicyType',
            value: PolicyState
          });
        }
        this.currentPolicy = PolicyState;
        setSecurityPolicyResolve(null);
      }).catch(() => { setSecurityPolicyReject(null) });
    });
  }
}
