import { Component, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-defaultdashboard-utm',
  templateUrl: './defaultdashboard-utm.component.html',
  styleUrl: './defaultdashboard-utm.component.scss'
})
export class DefaultdashboardUtmComponent {
  @Input() reports = null;
  @Input() selectedReport = null;
  loadingData = false;
  dashboardResultData = [];
  poolId = null;
  pools = [];
  dashboardId = null;

  source = null;
  sources = [];
  campaign = null;
  campaigns = [];
  content = null;
  contents = [];
  
  fromDate = new Date(2024, 8, 1, 0, 0, 0 , 0);
  toDate = new Date(Date.now());
  resultDataCache = [];
  openFilter= true;

  constructor(
      public applicationInfoService: ApplicationInfoService,
      public dashboardcoreService: DashboardcoreService,
      private commonService: CommonService,
      public userService: UserService,
      private externaldatasourceService: ExternaldatasourceService,
      private uiService: UiService
  ) {}

  ngOnInit(): void {
    this.dashboardResultData['getFilteredDataArray'] = null;
    this.dashboardId = this.selectedReport.dashboardId
    this.pools = this.applicationInfoService.pools.toArray();
    this.poolId = this.applicationInfoService.poolId;
    this.getDashboardDataArray(this.dashboardId);
  }

  getDashboardDataArray(dashboardId) {    
    this.loadingData= true
    let params = this.fillParams();
    this.dashboardResultData[dashboardId] = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {
      this.dashboardResultData[dashboardId] = null;
      if (getDashboardDataArrayResult) {
        if (getDashboardDataArrayResult[0]?.length > 0) {
          this.loadingData = false;          
          this.dashboardResultData[dashboardId] = JSON.parse(JSON.stringify(getDashboardDataArrayResult[0]));      
          this.dashboardResultData[dashboardId].forEach(item => {
            item.campaign = item.campaign.replace('%26', '&');
          })          
          this.getArrays();
          this.updateDashboardValues();
        } else {
          this.loadingData = false;
        } 
      } else {
        this.loadingData = false;
      }
    }).catch((error)=>{
      console.log(error);
      this.loadingData = false;
    });
  }

  fillParams() {   
    const params = [];
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@pool_id', value: this.poolId });
    params.push({ key: '@language_id', value: this.applicationInfoService.languageId });    
    return params;
  }

  getArrays() {   
    this.sources = [...new Set(this.dashboardResultData[this.dashboardId].map(item => item.source))];
    this.contents = [...new Set(this.dashboardResultData[this.dashboardId].map(item => item.content))];
    this.campaigns = [...new Set(this.dashboardResultData[this.dashboardId].map(item => item.campaign))];       
  }

  updateDashboardValues() {
    this.loadingData = true;    
    this.dashboardResultData['getFilteredDataArray'] = null;
    this.resultDataCache = [];
    this.dashboardResultData['contentSave'] = [];
    this.dashboardResultData['campaignSave'] = [];
    this.dashboardResultData['sourceSave'] = [];
    this.dashboardResultData['data'] = this.getFilteredDataArray();
    this.dashboardResultData['data'].forEach(item => {      
      if (this.commonService.isNullOrUndefined(this.dashboardResultData['contentSave'][item.content])) {
        this.dashboardResultData['contentSave'][item.content] = 1;
      } else {
        this.dashboardResultData['contentSave'][item.content] ++;
      }

      if (this.commonService.isNullOrUndefined(this.dashboardResultData['campaignSave'][item.campaign])) {
        this.dashboardResultData['campaignSave'][item.campaign] = 1;
      } else {
        this.dashboardResultData['campaignSave'][item.campaign] ++;
      }

      if (this.commonService.isNullOrUndefined(this.dashboardResultData['sourceSave'][item.source])) {
        this.dashboardResultData['sourceSave'][item.source] = 1;
      } else {
        this.dashboardResultData['sourceSave'][item.source] ++;
      }
    });

    setTimeout(() => {
      this.loadingData = false;
    }, 500);
  }

  getDataArray(source) {
    const targetArray = { data: [], type:'pie' };
    for (const key in this.dashboardResultData[source])
    {
      const indexedItem = this.dashboardResultData[source][key];
      targetArray.data.push({ name: this.commonService.capitalizeFirstLetter(key), value: indexedItem});
    }
    return targetArray;
  }

  getFilteredDataArray() {
    if (this.dashboardResultData['getFilteredDataArray'] != null) {
      return this.dashboardResultData['getFilteredDataArray'];
    }
    this.dashboardResultData['getFilteredDataArray'] = JSON.parse(JSON.stringify(this.dashboardResultData[this.dashboardId]));
    if (this.campaign != null) {
      this.dashboardResultData['getFilteredDataArray'] = this.dashboardResultData['getFilteredDataArray'].filter(item => item.campaign == this.campaign);
    }
    if (this.content != null) {
      this.dashboardResultData['getFilteredDataArray'] = this.dashboardResultData['getFilteredDataArray'].filter(item => item.content == this.content);
    }
    if (this.source != null) {
      this.dashboardResultData['getFilteredDataArray'] = this.dashboardResultData['getFilteredDataArray'].filter(item => item.source == this.source);
    }

    // Date filter  
    let endDate = this.toDate;
    if (this.commonService.isNullOrUndefined(endDate)) {
      endDate = new Date(Date.now());
    }
    const end = endDate.getTime();

    let startDate = this.fromDate;
    if (this.commonService.isNullOrUndefined(startDate)) {
      startDate = new Date(1990, 1, 1, 0, 0, 0 , 0);
    }
    const start = startDate.getTime();
    this.dashboardResultData['getFilteredDataArray'] = this.dashboardResultData['getFilteredDataArray']
    .filter(item => { var time = new Date(item.conversion_date).getTime();
      return (start < time && time < end);
    });
    return this.dashboardResultData['getFilteredDataArray'];
  }

  getBarChartHeight() {
    let returnValue = 10;
    const array = this.getFilteredDataArray();
    const campaigns = [...new Set(array.map(item => item.campaign))].length;
    const sources = [...new Set(array.map(item => item.source))].length;
    const items = campaigns * sources;
    returnValue = returnValue + (items * 10);
    if (returnValue < 200) {
      returnValue = 200;
    }
    return returnValue;
  }

  getControlHeight(control, substractValue = 40) {
    let tableSize = Number(this.uiService.getDesignSpanPosition(control, substractValue));
    return tableSize; 
  }

  getOverviewItems(itemName) {
    const dataArray = this.getFilteredDataArray();
    return [...new Set(dataArray.map(item => item[itemName]))]
  }

  getFilteredOverviewDataArray(itemName) {
    const dataArray = this.getFilteredDataArray();
    this.dashboardResultData['overView'.concat('', itemName)] =  dataArray.filter(item => item.source == itemName.toLowerCase());
    return this.dashboardResultData['overView'.concat('', itemName)];
  }

  getOverviewRowValue(source, campaign, content = null, getTotal = false) {
    if (!this.commonService.isNullOrUndefined(this.resultDataCache[''.concat(source, campaign, content, getTotal.toString())])) {
      return this.resultDataCache[''.concat(source, campaign, content, getTotal.toString())];
    }
    
    if (source == null) { return []; }
    const dataArray = this.getFilteredDataArray();
    let finalData = null;

    if (getTotal) {
      if (content == null) {
        finalData = dataArray.filter(item => 
          item.source == source.toLowerCase()
        );        
      } else {
        finalData = dataArray.filter(item => 
          item.source == source.toLowerCase() &&
          item.content == content.toLowerCase() 
        );  
      }  
    } else {
      if (content == null) {
        finalData = dataArray.filter(item => 
          item.source == source.toLowerCase() &&
          item.campaign == campaign.toLowerCase() 
        );
      } else {
        finalData = dataArray.filter(item => 
          item.source == source.toLowerCase() &&
          item.campaign == campaign.toLowerCase() &&
          item.content == content.toLowerCase() 
        );  
      }  
    }

    this.resultDataCache[''.concat(source, campaign, content, getTotal.toString())] = finalData;
    return finalData;
  }

  getOverviewSourceItems(source) {    
    const dataArray = this.getFilteredDataArray();
    let detailArray =  dataArray.filter(item => 
      item.source == source.toLowerCase() 
    );
    return [...new Set(detailArray.map(item => item['content']))];
  }
}
