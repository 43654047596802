import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DataaccessService, PolicyState } from 'app/jollyjupiter/service/dataaccess.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-userhierarchy-designer',
  templateUrl: './userhierarchy-designer.component.html',
  styleUrl: './userhierarchy-designer.component.scss'
})

export class UserhierarchyDesignerComponent implements OnInit, OnDestroy {
  eventServiceSubscription: Subscription = new Subscription();
  userHierarchyGroups = [];
  users = [];
  groupConnections = null;
  createGroupMode = false;
  selectedGroup = null;
  newGroup = {
    name: '',
    mainUserId: null,
    projectId: this.applicationInfoService.projectID,
    isDeleted: false
  };
  filterString = '';
  showDeletedHierarchyGroups = false;
  policy = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    public dragdropService: DragdropService,
    private eventService: EventService,
    private dataaccessService: DataaccessService,
    private packagefeatureService: PackagefeatureService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    if (!this.userService.hasPermission('CanReadSecurityUserHierarchyTeam')) {
      return;
    }
    this.eventServiceSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'removeHierarchyChild') {
        this.getGroupHierarchyItems();
      }
      if (event.id == 'pushHierarchyChild') {
        this.groupConnections.push(event.value);
      }
      if (event == 'getCompleteProjectInformaton') { 
        this.getPolicy();
        this.getGroupHierarchyItems();
      }          
    });
    
    this.getUsers();
    this.getPolicy();
    this.getUserGroups();
    this.getGroupHierarchyItems();    
  }

  ngOnDestroy(): void {
    if (this.eventServiceSubscription) { this.eventServiceSubscription.unsubscribe(); }
  }

  getPolicy() {
    this.policy = this.dataaccessService.getCurrentPolicy();
  }

  getUsers() {
    this.externaldatasourceService.executeExternalDataSource(14, []).then(userList => {
      this.users = userList
    });
  }

  createGroup() {
    this.newGroup = {
      name: '',
      mainUserId: null,
      projectId: this.applicationInfoService.projectID,
      isDeleted: false
    };
    this.createGroupMode = true;
  }

  setSelectedGroup(userHierarchyGroup) {
    this.getGroupHierarchyItems();
    this.selectedGroup = userHierarchyGroup;    
  }

  getUserGroups() {
    this.externaldatasourceService.executeExternalDataSource(1013, []).then(getUserGroupsResult => {
      this.userHierarchyGroups = getUserGroupsResult;
    });
  }

  createNewHierarchyGroup() {
    this.externaldatasourceService.executeExternalDataSource(1014, [
      this.commonService.getModifyArrayBody(this.newGroup, [])
    ]).then(() => {
      this.getUserGroups();
      this.createGroupMode = false;
    });
  }

  getGroupHierarchyItems() {
    this.externaldatasourceService.executeExternalDataSource(1012, []).then(getGroupHierarchyItemsResult => {
      this.groupConnections = getGroupHierarchyItemsResult;
    });
  }

  userAlreadyDragged(user) {
    if (user.id == this.selectedGroup.mainUserId) {
      return true;
    }
    if (this.groupConnections == null) {
      return false;
    }
    const foundUser = this.groupConnections.filter(connection => 
      ( connection.userId == user.id || connection.managerId == user.id ) &&
      connection.securityUserHierarchyTeamId == this.selectedGroup.id
    );
    if (foundUser.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  updateGroup() {
    this.externaldatasourceService.executeExternalDataSource(1015, [this.commonService.getModifyArrayBody(this.selectedGroup, [])]);
  }

  getGroupUsers(userHierarchyGroup) {    
    const userList = [];
    if (this.groupConnections == null) {
      return userList;
    }
    this.groupConnections.forEach(connection => {
      if (userHierarchyGroup.id == connection.securityUserHierarchyTeamId) {
        if (!this.commonService.checkIfItemIsInArray(userList, connection.userId)) {
          userList.push(connection.userId)
        }
        if (!this.commonService.checkIfItemIsInArray(userList, connection.managerId)) {
          userList.push(connection.managerId)
        }  
      }
    });
    return userList;
  }

  getUserName(userId) {
    const user = this.users.find(user => user.id == userId);
    if (user) {
      return user.userName;
    } else {
      return '-';
    }
  }

  itemIsFiltered(user) {
    return !this.commonService.checkIfStringContainsString(user.userName, this.filterString);
  }

  deactivatePolicy() {
    this.dataaccessService.setSecurityPolicy(PolicyState.None).then(() => { this.policy = PolicyState.None; });
  }

  activatePolicy() {
    this.dataaccessService.setSecurityPolicy(PolicyState.Hierarchy).then(() => { this.policy = PolicyState.Hierarchy; });
  }

  showHelp() {
    this.packagefeatureService.showFeatureInfoPage(37);
  }

  deleteUserHierarchie(userHierarchyGroup) {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('UserHierarchy.Label.DeleteMessage'),
      header: this.axivasTranslateService.getTranslationTextForToken('UserHierarchy.Label.UserHierarchy'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.externaldatasourceService.executeExternalDataSource(1016, [userHierarchyGroup.id]).then(() => {
          userHierarchyGroup.isDeleted = true;
          // this.commonService.removeItemFromArray(this.userHierarchyGroups, userHierarchyGroup);
        });
      },
    });  
  }

  reactivateUserHierarchie(userHierarchyGroup) {
    userHierarchyGroup.isDeleted = false;
    this.externaldatasourceService.executeExternalDataSource(1015, [this.commonService.getModifyArrayBody(userHierarchyGroup, [])]);
  }
}
