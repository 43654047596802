<span id="campaignDashboardDesignSpan"></span>
<div class="campaignDashboard">
    <app-layout [header]="applicationInfoService.breadCrumbText">
        <div class="campaignDashboard__inner" >
            <div class="campaignDashboard__tabsMobile">
                <div class="everleadMobileTab">
                    <div class="everleadMobileTab__valueWrapper">
                        <select [(ngModel)]="page" class="selectTab__box">
                            <option [ngValue]="0">{{'CampaignDashboard.Label.MyMailCampaigns' | texttransform }}</option>
                            <option [ngValue]="1">{{'CampaignDashboard.Label.MyMicroCampaigns' | texttransform }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="campaignDashboard__tabs">
                <app-scroll-tabs>
                    <app-tab label="CampaignDashboard.Label.MyMailCampaigns" svg="funnel" [active]="page === 0"
                    (onClick)="page= 0"></app-tab>
                    <app-tab label="CampaignDashboard.Label.MyMicroCampaigns" svg="chart-bar" [active]="page === 1"
                    (onClick)="page=1"></app-tab>
                </app-scroll-tabs>
            </div>

            <div class="campaignDashboard__nav">
                <div class="campaignDashboard__nav__label">
                    <span *ngIf="page === 0"> {{ 'CampaignDashboard.Label.MyMailCampaigns' | texttransform }}</span>
                    <span *ngIf="page === 1">{{ 'CampaignDashboard.Label.MyMicroCampaigns' | texttransform }}</span>
                </div>
                <div class="campaignDashboard__nav__options" *ngIf="page === 0">
                    <app-main-button type="small" svg="plus" label="UserDashboard.Label.NewCampaign"
                        *ngIf="applicationInfoService?.applicationSettings['isDemoproject'] != 'true'"
                        (onClick)="createMailshot()" [disabled]="!this.userService.hasPermission('CanCreateMailshotList')"></app-main-button>
                </div>
            </div>

            <span id="campaignDashboardDesignSpan"></span>
            <div class="campaignDashboard__globalContent" [style.height.px]="getContentHeight()">
                <div *ngIf="page === 0 && !serviceDashboardError" class="campaignDashboard__mailListContent">
                    <app-campaigndashboardlist *ngIf="mailCampaigns && !loadingmailCampaigns" [data]="mailCampaigns" [page]="page"></app-campaigndashboardlist>
                    
                    <span *ngIf="loadingmailCampaigns">
                        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform
                       }}
                    </span>

                    <div *ngIf="mailcampaignserror">
                        {{ 'General.Label.NoDataAvailable' | texttransform }}
                    </div>
                </div>

                <div class="campaignDashboard__content" *ngIf="page === 1 && !serviceDashboardError">
                    <div class="campaignDashboard__content__left">
                        <app-campaigndashboardlist [page]="page" [data]="microCampaignOverview" (dataChange)="showMicroCampaignDetails($event)"></app-campaigndashboardlist>
                    </div>

                    <div class="campaignDashboard__content__right">
                        <app-campaigndashboardchart [page]="page"
                            [selectedMicroCampaign]="selectedMicroCampaign"
                            [chartData]="chartDataMicroCampaign"></app-campaigndashboardchart>
                    </div>
                </div>

                <div *ngIf="serviceDashboardError">
                    {{ 'General.Label.NoDataAvailable' | texttransform }}
                </div>
            </div>
        </div>
    </app-layout>
</div>