

    <div class="customOptinOptoutOverview">
        <div class="customOptinOptoutOverview__header">
            <app-component-header header="OptInOptOut.Label.OptInOptOut"></app-component-header>
            <app-main-button type="small" svg="plus" [label]="'OptinOptOut.Label.NewItem' | texttransform" (onClick)="newOptinOptOutItem()" [disabled]="applicationInfoService?.currentContact == null"></app-main-button>
        </div>
      <!--   <span id="getMaxHeightSpan"></span> -->
        <div class="customOptinOptoutOverview__inner">
            <div class="customOptinOptoutOverview__innerWrapper">
                <div class="customOptinOptoutOverview__tabsMobile">
                    <div class="everleadMobileTab">
                        <div class="everleadMobileTab__valueWrapper">
                            <select class="selectTab__box" [(ngModel)]="viewMode">
                                <option [ngValue]="1">{{ axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Overview') | texttransform }} </option>
                                <option [ngValue]="2">{{ axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Detailview') | texttransform }} </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="customOptinOptoutOverview__tabs">
                    <app-scroll-tabs>
                        <app-tab [active]="viewMode === 1" [label]="axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Overview')" (onClick)="viewMode = 1"></app-tab>
                        <app-tab [active]="viewMode === 2" [label]="axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Detailview')" (onClick)="viewMode = 2"></app-tab>
                    </app-scroll-tabs>
                </div>
                
                <div *ngIf="optOutsCollected && viewMode==1 && applicationInfoService?.currentContact == null" class="customOptinOptoutOverview__message">
                    {{ 'Account.Label.PleaseSelectContact' | texttransform }}
                </div>

                <span id="getMaxHeightSpan"></span>

                <div
                [style.max-height.px]="getMaxHeight(5)"
                *ngIf="optOutsCollected && viewMode==1 && applicationInfoService?.currentContact != null" 
                class="optinList">
                    <div class="optinList__header">
                        <div class="optinList__header__state"></div>
                        <div class="optinList__header__name">{{ 'OptinOptOut.Label.Medium' | texttransform }}</div>
                        <div class="optinList__header__type">{{ 'OptinOptOut.Label.Type' | texttransform }}</div>
                        <div class="optinList__header__startDate">{{ 'OptinOptOut.Label.LatestEntry' | texttransform }}</div>
                        <div class="optinList__header__endDate">{{ 'OptinOptOut.Label.EndDate' | texttransform }}</div>
                        <div class="optinList__header__status">{{ 'OptinOptOut.Label.IP' | texttransform }}</div>
                        <div class="optinList__header__status">{{ 'OptinOptOut.Label.User' | texttransform }}</div>
                        <div class="optinList__header__comment">Comment</div>            
                    </div>
                    <div class="optinList__content">
                        <div *ngFor="let availableMediaItem of availableMedia" class="optinList__row">
                                <div class="optinList__cell">
                                    <div class="optinList__cell__state">
                                        <app-status width="20px" height="20px"
                                            [red]="(getStatus(availableMediaItem)?.lookupTypeRefId == 44 && getStatus(availableMediaItem)?.endDateInPast == false)"
                                            [green]="(getStatus(availableMediaItem)?.lookupTypeRefId == 1815 && getStatus(availableMediaItem)?.endDateInPast == false)"
                                            [yellow]="(getStatus(availableMediaItem)?.lookupTypeRefId == 43 && getStatus(availableMediaItem)?.endDateInPast == false)"
                                            [blue]=" getStatus(availableMediaItem)?.endDateInPast == true"
                                            [tippy]="getStateName(availableMediaItem) | texttransform"></app-status>
                                    </div>
                                </div>
                    
                                <div class="optinList__cell">
                                    <div class="optinList__cell__name">
                                        {{ availableMediaItem.defaultName }}
                                    </div>
                                </div>
                    
                                <div class="optinList__cell">
                                    <div class="optinList__cell__type">
                                        <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 1815">{{
                                            'OptInOptOut.Label.DoubleOptIn' | texttransform }}</span>
                                        <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 44">{{ 'LookupTable.OptInType.OptOut'
                                            | texttransform }}</span>
                                        <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 43">{{ 'LookupTable.OptInType.OptIn' |
                                            texttransform }}</span>
                    
                                    </div>
                                </div>
                    
                                <div class="optinList__cell">
                                    <div class="optinList__cell__startDate">
                                        <span *ngIf="getStatus(availableMediaItem).startDate != null">{{
                                            getStatus(availableMediaItem).startDate | date:'medium' }}</span>
                                    </div>
                                </div>
                                <div class="optinList__cell">
                                    <div class="optinList__cell__endDate">
                                        <span *ngIf="getStatus(availableMediaItem).endDate != null" [ngClass]="{
                                                            everleadCoral: getStatus(availableMediaItem)?.endDateInPast == true
                                                        }">{{ getStatus(availableMediaItem).endDate | date:'medium' }}</span>
                                    </div>
                                </div>
                    
                                <div class="optinList__cell">
                                    <div class="optinList__cell__status">
                                        <div *ngIf="getStatus(availableMediaItem).ip != null">{{ getStatus(availableMediaItem).ip }}</div>
                                    </div>
                                </div>
                    
                                <div class="optinList__cell">
                                    <div class="optinList__cell__status">
                                        <div *ngIf="getStatus(availableMediaItem).userId != null" class="ellipsisText"
                                            [tippy]="getUser(getStatus(availableMediaItem).userId)">{{
                                            getUser(getStatus(availableMediaItem).userId) }}</div>
                                    </div>
                                </div>
                    
                                <div class="optinList__cell">
                                    <div class="optinList__cell__comment">
                                        <app-jj-icons svg="note" [width]="20" *ngIf="getStatus(availableMediaItem).comment != null"
                                            [height]="20" color="secondary" [tippy]="getStatus(availableMediaItem).comment"></app-jj-icons>
                                    </div>
                                </div>
                           
                        </div>
                    </div>
                </div>
            
            <div 
                *ngIf="optOutsCollected && viewMode==2"
                [style.max-height.px]="getMaxHeight(5)" 
                class="customOptinOptoutOverview__view2">
                    <div [innerHTML]="innerHTML"></div>
                </div>
        </div>
        </div>
    </div>
 



<!-- <div class="optinOverview__wrapper" [ngClass]="{ optinOverviewDarkmode: darkMode }">
    <ng-container *ngIf="optOutsCollected == false" class="optinOverview__spinner">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
    </ng-container>
    <div class="radioButtonSelector">
        <div class="radioButtonSelector__buttons">
            <app-radio-button type="row" [(ngModel)]="viewMode" [options]="[{value:1, label: axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Overview')},{ value:2, label: axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Detailview')}]"></app-radio-button>
            <!-- <div class="radioButtonSelector__button"><input type="radio" [(ngModel)]="viewMode" [value]=1>{{ 'OptinOptOut.Label.Overview' | texttransform }}</div>
            <div class="radioButtonSelector__button"><input type="radio" [(ngModel)]="viewMode" [value]=2>{{ 'OptinOptOut.Label.Detailview' | texttransform }}</div>   
        </div>
        <div class="radioButtonSelector__new" (click)="newOptinOptOutItem()">
            <i class="fas fa-plus"></i>{{ 'OptinOptOut.Label.NewItem' | texttransform }}
        </div>
    </div>
    <div *ngIf="optOutsCollected && viewMode==1">
        <div class="optinOverviewItem optinOverviewItem__small">
            <div class="optinOverviewItem__name">Medium</div>
            <div class="optinOverviewItem__type">Typ</div>
            <div class="optinOverviewItem__startDate">{{ 'OptinOptOut.Label.LatestEntry' | texttransform }}</div>
            <div class="optinOverviewItem__endDate">Enddatum</div>
            <div class="optinOverviewItem__status">IP</div>
            <div class="optinOverviewItem__status">Benutzer</div>
            <div class="optinOverviewItem__comment"></div>            
        </div>
        <div *ngFor="let availableMediaItem of availableMedia">
            <div class="optinOverviewItem everleadShadowBox everleadShadowBox" [ngClass]="{
                    everleadCoralBackground:  (getStatus(availableMediaItem)?.lookupTypeRefId == 44 && getStatus(availableMediaItem)?.endDateInPast == false),
                    everleadGreenBackground:  (getStatus(availableMediaItem)?.lookupTypeRefId == 43 && getStatus(availableMediaItem)?.endDateInPast == false),
                    goldStatus:               (getStatus(availableMediaItem)?.lookupTypeRefId == 1815 && getStatus(availableMediaItem)?.endDateInPast == false),
                    endDateInPast:            getStatus(availableMediaItem)?.endDateInPast == true
                }">
                <div class="optinOverviewItem__name">{{ availableMediaItem.defaultName }}</div>
                <div class="optinOverviewItem__type">
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 1815">DoubleOptIn</span>
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 44">OptOut</span>
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 43">OptIn</span>
                </div>
                <div class="optinOverviewItem__startDate"><span *ngIf="getStatus(availableMediaItem).startDate != null">{{ getStatus(availableMediaItem).startDate | date:'medium' }}</span></div>
                <div class="optinOverviewItem__endDate">
                    <span *ngIf="getStatus(availableMediaItem).endDate != null" [ngClass]="{
                        everleadCoral: getStatus(availableMediaItem)?.endDateInPast == true
                    }">{{ getStatus(availableMediaItem).endDate | date:'medium' }}</span>
                </div>
                <div class="optinOverviewItem__status">
                    <div *ngIf="getStatus(availableMediaItem).ip != null">{{ getStatus(availableMediaItem).ip }}</div>
                </div>
                <div class="optinOverviewItem__status">
                    <div *ngIf="getStatus(availableMediaItem).userId != null" class="ellipsisText"
                        [tippy]="getUser(getStatus(availableMediaItem).userId)">{{ getUser(getStatus(availableMediaItem).userId) }}</div>
                </div>
                <div class="optinOverviewItem__comment">
                    <i class="fa-lg far fa-sticky-note" *ngIf="getStatus(availableMediaItem).comment != null" [tippy]="getStatus(availableMediaItem).comment"></i>
                </div>
            </div>
        </div>
    </div>    
    <div *ngIf="optOutsCollected && viewMode==2">
        <div [innerHTML]="innerHTML"></div>
    </div>
</div> -->