
<div *ngIf="applicationInfoService?.applicationSettings['isDemoproject'] == 'true'" class="demoProjectInfo">
    {{ 'Demo.Label.MailToUser' | texttransform }}
</div> 

<div class="emailEditorWrapper" *ngIf="controlValue != null">   
    <div class="emailEditorEditableContent__wrapper" *ngIf="showPreviewOnly == false">
        <div class="emailEditorEditableContent" [style.min-width.px]="editableContentMinWidth">
            <div class="emailEditorHeader">{{ 'EMailEditor.Label.EditableContent' | texttransform }}</div>        
            <div *ngIf="selectedContent" class="emailEditorMaxHeight emailEditorContentList">
                <!-- {{ contents | json }} -->
             <!--    <textarea *ngFor="let emailContent of contents" 
                    class="emailEditorTextArea" 
                    [(ngModel)]="emailContent.content" 
                    (keyup)="renderContent(emailContent, emailContent.content)" 
                    [ngClass]="{ hideInDom: emailContent.isEditable == false }" 
                    [disabled]="emailContent.isEditable == false">
                </textarea> -->
                <app-text-area-input *ngFor="let emailContent of contents"
                    [(ngModel)]="emailContent.content" 
                    [ngClass]="{ hideInDom: emailContent.isEditable == false }" 
                    (keyup)="renderContent(emailContent, emailContent.content)" 
                    [disabled]="emailContent.isEditable == false"
                    [label]="emailContent.defaultName"
                    height="300"
                    [fire]="true"
                ></app-text-area-input>

            </div>            
        </div>
        <div class="grabber" id="grabber"></div>
    </div>

    <div class="emailEditorSection">
        <div class="emailEditorHeader">{{ 'EMailEditor.Label.Preview' | texttransform }}</div>
        <div id="emailRenderedContent" class="emailEditorRenderedContent emailEditorMaxHeight">            
            <div [innerHTML]="innerHTML"></div>
        </div>
    </div>
</div>