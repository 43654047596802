import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild } from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-scroll-tabs',
  templateUrl: './scroll-tabs.component.html',
  styleUrl: './scroll-tabs.component.scss'
})



export class ScrollTabsComponent implements OnInit, OnChanges, AfterViewInit, AfterContentInit, OnDestroy{
  
  constructor( 
    private cdr: ChangeDetectorRef , 
    private eventService: EventService, 
    private applicationInfoService: ApplicationInfoService
  ){}
  
 /*  customEventSubscripion: Subscription = new Subscription(); */
  showButtons=false;
  rightDisabled=false;
  leftDisabled=false;
  elementList: any;

  
/*   @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef | undefined;
  @ContentChildren(TabComponent, { descendants:true }) tabItems!: QueryList<TabComponent>

  @Input() startIndex: 0 | 1 = 0;
  @Input() expand : boolean = false;
  
  @Output() onTabChange = new EventEmitter<any>();
  
  @HostListener('window:resize', ['$event'])
  onResize(){
    console.log('window-resize')
    this.checkContainer();
  } */



  ngOnInit(){
/*     console.log('INIT')
    this.createElementList();
    this.setTabsIndex(0);

    this.customEventSubscripion = this.eventService.customEvent.subscribe((event) =>{
      console.log('EVENT', event);
      this.checkContainer();
    }) */
  };

  ngAfterContentInit(){
 /*    console.log(' AFTER CONTENT')
    this.checkContainer();
    this.createElementList(); */
  }

  ngAfterViewInit(): void {
/*     console.log('AFTER VIEW INIT')
    console.log('this.scroll Container', this.scrollContainer);
    this.checkContainer() */
  }



  ngOnChanges(changes: SimpleChanges): void {
/*     console.log('change',changes)
    if(changes['expand']){
      this.createElementList();
      this.checkContainer();
    }     */
  }


  createElementList(){
/*     if(this.tabItems){
      this.elementList = this.tabItems.toArray().filter((item) => item.hidden === false);
    } */
  }


  checkContainer(){

/*     if(!this.scrollContainer){
      return
    }

  setTimeout(() =>{
    if(this.scrollContainer.nativeElement){

      console.log('scroll element', this.scrollContainer);
      console.log('scrollWidth', this.scrollContainer?.nativeElement?.scrollWidth);
      console.log('clientWidth', this.scrollContainer?.nativeElement?.clientWidth)


      if (this.scrollContainer.nativeElement.scrollWidth > this.scrollContainer.nativeElement.clientWidth) {
        this.showButtons = true;
      } else {
        this.showButtons = false; 
      } 
      this.cdr.detectChanges();
    }
  },0) */

}





  scrollTabs(direction){
  /*   const container = this.scrollContainer.nativeElement;
    const scrollAmount = 100;
    const tabItems = this.elementList
    const lastTab = this.elementList.length - 1 ;

    console.log('items length', tabItems.length , 'last tab', lastTab)

    let activeTabIndex = tabItems.findIndex((tab) => tab.active);
    let index ;

    if(direction ==='left'){
      // * scroll container
      container.scrollLeft -= scrollAmount;

      // * activates tab and component on click
      index = activeTabIndex === 0 ?  0 : activeTabIndex - 1

       if(index >= 0 ){
        this.setTabsIndex(index);
        this.onTabChange.emit(this.startIndex === 0 ? index : index + 1);
      } else {
        this.leftDisabled= true;
      } 

    } else{
      // * scroll container
      container.scrollLeft += scrollAmount;      
      
      // * activate tab and component on click
      index = activeTabIndex === lastTab ?  lastTab : activeTabIndex + 1

      if(index <= lastTab){
        this.setTabsIndex(index)
        this.onTabChange.emit(this.startIndex === 0  ? index : index + 1)
      } else {
        this.rightDisabled= true;
      }
    } */
  }



  setTabsIndex(index){
/*     if(this.tabItems && this.elementList){
      this.elementList.forEach((tab, i) =>{
        tab.active= i === index
      });
    } */
  }

  ngOnDestroy(){
 /*    if(this.customEventSubscripion){
      this.customEventSubscripion.unsubscribe()
    } */

    /* if(this.tabsItemSubscripion){
      this.tabsItemSubscripion.unsubscribe()
    } */
  }

 


}
