<div class="component">
    <div class="component__header">
        <app-component-header [header]="applicationInfoService.breadCrumbText || 'Audit'"></app-component-header>

        <div class="component__nav">            
            <app-main-button
            class="everleadPadding"
            type="small"
            (onClick)="createAuditDefinition()"
            label="General.Label.CreateNewWithDefault"
            svg="plus"
            [disabled]="mode == 2"></app-main-button>

            <app-filter  [disabled]="mode == 2" (keyUp)="filterStringDefinition = $event"></app-filter>

        </div>
    </div>
    <div class="component__inner">
        <div class="audit">
            <div class="audit__tabsMobile">
                <div class="everleadMobileTab">
                    <div class="everleadMobileTab__valueWrapper">
                        <select [(ngModel)]="mode" class="selectTab__box">
                            <option [ngValue]="2" *ngIf="onlySettings == false">{{ 'Audit.Label.HeaderRevert' | texttransform }}</option>
                            <option [ngValue]="1">{{'Audit.Label.HeaderSettings' | texttransform }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="audit__tabs">
                <app-scroll-tabs>
                    <app-tab *ngIf="onlySettings == false" label="Audit.Label.HeaderRevert" svg="swap" [active]="mode === 2" (onClick)="mode=2"></app-tab>
                    <app-tab label="Audit.Label.HeaderSettings" svg="gear-six" [active]="mode === 1" (onClick)="mode=1"></app-tab>
                </app-scroll-tabs>
            </div>
        
          <!--   <div class="audit__description">
                <span *ngIf="mode == 1">{{ 'Audit.Label.HeaderSettings' | texttransform }}</span>
                <span *ngIf="mode == 2">{{ 'Audit.Label.HeaderRevert' | texttransform }}</span>
            </div> -->

            <div class="audit__content" *ngIf="mode == 2">
                <div class="audit__subHeader">
                    <div class="audit__subNav">
                        <app-main-button
                        class="everleadPadding"
                        [disabled]="applicationInfoService.currentAccount == null && applicationInfoService.currentAccount?.isDummyAccount != false"
                        [type]="auditSource == 1 ? 'small' : 'borderSmall'"
                        (onClick)="setAuditSource(1)"
                        label="Audit.Label.CurrentAccount"
                        svg="building"
                        [disbaled]="auditSource == 1"></app-main-button>

                        <app-main-button
                        class="everleadPadding" 
                        [disabled]="applicationInfoService.currentContact == null"
                        (onClick)="setAuditSource(2)"
                        [type]="auditSource == 2 ? 'small' : 'borderSmall'"
                        label="Audit.Label.CurrentContact"
                        svg="user"
                        ></app-main-button>

                        <app-main-button
                        class="everleadPadding"
                        [type]="auditSource == 3 ? 'small' : 'borderSmall'"
                        (onClick)="setAuditSource(3)"
                        label="Audit.Label.ExpertMode"
                        svg="microscope"
                        [disabled]="!applicationInfoService.isDeveloper"
                        ></app-main-button>

                    </div>
            
                    <div class="audit__subNav__result">
                        <div *ngIf="auditSource == 1">
                            {{ applicationInfoService?.currentAccount?.name1 }}
                        </div>
                        <div *ngIf="auditSource == 2">
                            {{ applicationInfoService?.currentContact?.firstName }} {{
                            applicationInfoService?.currentContact?.lastName }}
                        </div>
                        <div *ngIf="auditSource == 3">

                        </div>
                    </div>            
                </div>

                <div class="audit__contentScroll2">
                    <table mat-table #table [dataSource]="dataSource" style="width: 1000px" matSort>       
                        <ng-container matColumnDef="field">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.ChangedValue' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">            
                                {{ getEntityMemberName(element.entityId, element.entityMemberId) | texttransform }}
                            </td>
                        </ng-container>
                
                        <ng-container matColumnDef="oldValue">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.OldValue' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">            
                                {{ element.oldValueText }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="newValue">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.NewValue' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">            
                                {{ element.newValueText }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="user">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.User' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">            
                                {{ getUserName(element.userId) }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="source">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.Source' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">            
                                <ng-container *ngIf="element.source != null">{{ element.source }}</ng-container>
                                <ng-container *ngIf="element.source == null">{{ 'Audit.Label.SourceEverlead' | texttransform }}</ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.Date' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">            
                                {{ element.timestamp | date: 'medium' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="button">
                            <th mat-header-cell *matHeaderCellDef class="audit__button"></th>
                            <td mat-cell *matCellDef="let element">            
                                <div class="audit__changedData__item__value audit__changedData__item__revert fingerCursor"
                                (click)="revertItem(element)">
                                <app-jj-icons type="swap" [width]="20" [height]="20"></app-jj-icons>
                            </div>
                
                            </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{             
                            hideInDom: (row.isActive == false && showOnlyActive)  || itemIsFiltered(row) == true,
                            projectSettings__isInactive: row.isActive == false
                        }"></tr>
                    </table>
                </div>
            </div>

            <div class="audit__content" *ngIf="mode == 1">
                <div class="audit__contentScroll1">
                    <table mat-table #table [dataSource]="dataSourceDefinitions" style="width: 1000px" matSort>       
                        <ng-container matColumnDef="entity">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.Entity' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element"> 
                                <app-select-input
                                [(ngModel)]="element.entityId"
                                bind="id"
                                display="displayName"
                                [options]="entities"
                                (valueChange)="saveAuditDefinition(element)"></app-select-input>
                               <!--  <select [(ngModel)]="element.entityId">
                                    <option [ngValue]=null></option>
                                    <option [ngValue]="entity.id" *ngFor="let entity of entities">
                                        <span *ngIf="!entity.nameTranslationToken">{{ entity.entityName }}</span>
                                        <span *ngIf="entity.nameTranslationToken">{{ entity.nameTranslationToken.tokenFullName | texttransform }}</span>
                                    </option>
                                </select> -->
                            </td>
                        </ng-container>
            
                        <ng-container matColumnDef="member">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.ChangedValue' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">
                                <app-select-input
                                [(ngModel)]="element.entityMemberId" 
                                (valueChange)="saveAuditDefinition(element)"
                                bind="id"
                                display="displayName"
                                [options]="getEntityMember(element.entityId)"
                                ></app-select-input>            
                               <!--  <select [(ngModel)]="element.entityMemberId"
                                    (change)="saveAuditDefinition(element)">
                                    <option [ngValue]=null></option>
                                    <option [ngValue]="member.id" *ngFor="let member of getEntityMember(element.entityId)">
                                        <span *ngIf="!member.nameTranslationToken">{{ member.defaultName | texttransform }}</span>
                                        <span *ngIf="member.nameTranslationToken">{{ member.nameTranslationToken.tokenFullName | texttransform }}</span>
                                    </option>
                                </select> -->
                            </td>
                        </ng-container>
            
                        <ng-container matColumnDef="start">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.Start' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">
                                <app-date-input [(ngModel)]="element.auditStart" (dateChange)="saveAuditDefinition(element)"></app-date-input>            
<!--                                 <input [(ngModel)]="element.auditStart"
                                    (dateTimeChange)="saveAuditDefinition(element)" [owlDateTimeTrigger]="dt7"
                                    [owlDateTime]="dt7" readonly class="audit__dateTime" appendTo="body" aria-modal="true">
                                <owl-date-time #dt7></owl-date-time> -->
                            </td>
                        </ng-container>
            
                        <ng-container matColumnDef="end">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.End' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element"> 
                                <app-date-input [(ngModel)]="element.auditEnd" (dateChange)="saveAuditDefinition(element)"></app-date-input>           
                               <!--  <input [(ngModel)]="element.auditEnd"
                                    (dateTimeChange)="saveAuditDefinition(element)" [owlDateTimeTrigger]="dt8"
                                    [owlDateTime]="dt8" readonly class="audit__dateTime" appendTo="body" aria-modal="true">
                                <owl-date-time #dt8></owl-date-time> -->
                            </td>
                        </ng-container>
            
                        <ng-container matColumnDef="button">
                            <th mat-header-cell *matHeaderCellDef class="audit__button"></th>
                            <td mat-cell *matCellDef="let element">            
                                <!-- <div class="audit__changedData__item__value audit__changedData__item__revert fingerCursor"
                                    (click)="deleteAuditDefinition(element)">
                                    <i class="fas fa-trash fa-lg"></i>
                                </div> -->
                                <app-jj-icons type="trash" [width]="20" [height]="20" (click)="deleteAuditDefinition(element)"></app-jj-icons>
                            </td>
                        </ng-container>
            
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsDefinitions; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsDefinitions;" [ngClass]="{             
                            hideInDom: (row.isActive == false && showOnlyActive)  || definitionItemIsFiltered(row) == true,
                            projectSettings__isInactive: row.isActive == false
                        }"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
