import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from '../../../../core/application/application-info.service';
import { AuthService } from '../../../../core/authentication/services/auth.service';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../core/authentication/services/user.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { QuestionaireService } from 'app/jollyjupiter/service/questionaire.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { PopupMode } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MailService } from 'app/jollyjupiter/service/mail.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';

@Component({
  selector: 'app-debugpanel',
  templateUrl: './debugpanel.component.html',
  styleUrls: ['./debugpanel.component.scss']
})
export class DebugpanelComponent implements OnInit {
  apiRefreshUser = '0';
  apiRefreshEntity = 'Translation';

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private authService: AuthService,
    public userService: UserService,
    public projectService: ProjectService,
    private eventService: EventService,
    private messagingService: MessagingService,
    private methodService: MethodService,
    public loaderService: LoaderService,
    private commonService: CommonService,
    private refreshValueService: RefreshValueService,
    private axivasTranslateService: AxivasTranslateService,
    private designerService: DesignerService,
    private questionaireService: QuestionaireService,
    private entityServvice: EntityService
  ) { }

  ngOnInit() {
  }

  tranlateText() {
    console.error(this.axivasTranslateService.getTranslationTextForToken('DesignTranslation.Label.ProjectRelated', true));
  }

    // Debug
    showApplicationInfo() {
      this.messagingService.showDebugMessageInConsole('Logical entities:', [this.applicationInfoService.logicalEntities]);
      this.messagingService.showDebugMessageInConsole('Entities:', [this.applicationInfoService.entities]);
      this.messagingService.showDebugMessageInConsole('Member:', [this.applicationInfoService.entityMember]);
      this.messagingService.showDebugMessageInConsole('EntitiesOrig:', [this.applicationInfoService.entitiesorig]);
      this.messagingService.showDebugMessageInConsole('Schemes:', [this.applicationInfoService.schemes]);
      this.messagingService.showDebugMessageInConsole('Controls:', [this.applicationInfoService.controls]);
      this.messagingService.showDebugMessageInConsole('Methods:', [this.applicationInfoService.methods]);
      this.messagingService.showDebugMessageInConsole('ControlValues:', [this.applicationInfoService.controlValues]);
      this.messagingService.showDebugMessageInConsole('AvailableActivities:', [this.applicationInfoService.availableActivities]);
      this.messagingService.showDebugMessageInConsole('Campaigns:', [this.applicationInfoService.campaigns]);
      this.messagingService.showDebugMessageInConsole('Steps:', [this.applicationInfoService.steps]);
      this.messagingService.showDebugMessageInConsole('StepWorkflow:', [this.applicationInfoService.stepworkflow]);
      this.messagingService.showDebugMessageInConsole('Workflows:', [this.applicationInfoService.workflows]);
      this.messagingService.showDebugMessageInConsole('Results:', [this.applicationInfoService.results]);
      this.messagingService.showDebugMessageInConsole('ContainerData:', [this.applicationInfoService.containerData]);
      this.messagingService.showDebugMessageInConsole('Leadstati:', [this.applicationInfoService.leadstates]);
      this.messagingService.showDebugMessageInConsole('Fragebogen:', [this.applicationInfoService.questionaires]);
      this.messagingService.showDebugMessageInConsole('entitiesDynamicFields:', [this.applicationInfoService.entitiesDynamicFields]);
      this.messagingService.showDebugMessageInConsole('loaderService.loaderCounter:', [this.loaderService.loaderCounter]);
      this.messagingService.showDebugMessageInConsole('workflowSteps:', [this.applicationInfoService.workflowSteps]);
      this.messagingService.showDebugMessageInConsole('externalDataSourceCache:', [this.applicationInfoService.externalDataSourceCache]);
      this.messagingService.showDebugMessageInConsole('externalDataSources:', [this.applicationInfoService.externalDataSources]);
      this.messagingService.showDebugMessageInConsole('availableUserGroups:', [this.applicationInfoService.availableUserGroups]);
      this.messagingService.showDebugMessageInConsole('applicationSettings:', [this.applicationInfoService.applicationSettings]);
      this.messagingService.showDebugMessageInConsole('miscSettings:', [this.applicationInfoService.miscSettings]);
      this.messagingService.showDebugMessageInConsole('LookupTableDefinitions:', [this.applicationInfoService.dataLookupTableDefinitions]);
      this.messagingService.showDebugMessageInConsole('Project:', [this.applicationInfoService.projectInfo]);
    }

    showUserInformation() {
    /*   console.log('applicationInfoService.user',  this.applicationInfoService.user);
      console.log('applicationInfoService.userID', this.applicationInfoService.userID);
      console.log(
        'User information',
        this.applicationInfoService.userID,
        this.userService.getUserId(),
        this.applicationInfoService.userName,
        JSON.stringify(this.applicationInfoService.user));
      console.log('User Permissions',
        this.userService.permission);     */  
    }

    showTranslationUpdateCache() {
      this.authService.AuthGet(environment.apiUrl +
        '/AdminRefreshRepositoryCache/refresh/entity/' + this.apiRefreshEntity + '/' + this.apiRefreshUser).subscribe();
    }

    toggleDesignMode() {
      this.applicationInfoService.designerMode = !this.applicationInfoService.designerMode;
    }

    projectManagement() {
      this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['jjsection', 'developerprojectmanagement']);
    }

    importMask() {
      this.applicationInfoService.baseLogicalEntityIdForFilter = 1;
      this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['jjsection', 'import-main']);
    }

    loadAdminPanel() {
      this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['jjsection', 'admin-main']);
    }

    exportFormControl() {
      this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['jjsection', 'exportform']);
    }

    showTranslationForm() {
      this.eventService.showJjPopup('', 'designtranslation', '100', true);
    }


    getModifiedQuestionaires() {
      this.questionaireService.getModifiedQuestionaires();
    }

    showTaskInfo() {
      this.messagingService.showDebugMessageInConsole('Task:', [this.applicationInfoService.currentTask]);
      this.messagingService.showDebugMessageInConsole('Account:', [this.applicationInfoService.currentAccount]);
      this.messagingService.showDebugMessageInConsole('Contact:', [this.applicationInfoService.currentContact]);
    }


    debug() {
      this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [1]);
    }

    showSoftphone() {
      this.methodService.launchInternalMethod('showsoftphonepopup', [PopupMode.Call]);
    }

    createTask() {
      this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['jjsection', 'createtaskmain']);
    }

    optInoptOut() {
      this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['jjsection', 'optinoptout']);
    }

    getQuestions() {
      this.designerService.editUiControl(this.applicationInfoService.controls.Item('6259'));
    }

    callNewAccountPopup() {
      this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['', 'newaccount', '60', true]);
    }

    createAccountEnvironmentForProjectSetup() {
      this.applicationInfoService.currentTask = [{ id: 1}];
    }

    newTasks() {
      this.applicationInfoService.miscSettings['createMode'] = 'single';
      this.applicationInfoService.miscSettings['newtaskcontactid'] = 2693;
      this.applicationInfoService.miscSettings['newtaskaccountid'] = 378;
      this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['', 'newtask', '60', true]);
    }

    refreshProject() {
      this.refreshValueService.refreshProject();
    }

    openAudit() {
      this.applicationInfoService.miscSettings['auditEntityId'] = null;
      this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['Audit', 'audit', '80', true]);
    }
}
