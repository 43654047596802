<div class="shortcut" [class.active]="active" (click)="buttonClick()">

    <div class="shortcut__checkbox">
        <app-jj-icons *ngIf="active" type="x" [width]="12" [height]="12" class="shortcut__checkbox__x" color="primaryLight"></app-jj-icons>
        <app-jj-icons type="square" width="16" height="16"
            [color]="active ? 'primaryLight' : 'secondary'"></app-jj-icons>
    </div>

    <div class="shortcut__label">
        <app-jj-icons type="layout" [width]="32" [height]="32"
            [color]="active ? 'primaryLight' : 'secondary'"></app-jj-icons>
        <span class="shortcut__label__text" [style.color]="active ? 'primaryLight' : 'secondary'">{{ label |
            texttransform }}</span>
    </div>

</div>