<div class="cloudConnectionWrapper" *ngIf="!userService.hasPermission('CanReadCloudConnection')">
    {{ 'general.Label.NoPermission' | texttransform }}
</div>

<div class="component">
    <div class="component__header">
        <!-- Header -->
        <app-component-header
            [header]="applicationInfoService.breadCrumbText || 'Cloud Connection'"></app-component-header>
        <div class="component__nav">
            <app-main-button type="small" label="CloudConnection.Label.New" svg="cloud-arrow-up"
                (onClick)="createNewCloudConnection()"
                [disabled]="!userService.hasPermission('CanCreateCloudConnection') || selectedConnection"></app-main-button>
            
            <app-filter (keyUp)="filterString = $event"></app-filter>
            
        </div>
    </div>

    <div class="component__inner">
        <!-- Liste -->
        <div class="cloudConnection__listContent"
            *ngIf="userService.hasPermission('CanReadCloudConnection') && selectedConnection == null && createNewConnection == false">

            <div class="cloudConnection__row" *ngFor="let cloudConnection of cloudConnections" [ngClass]="{
                                        hideInDom: checkIfItemIsFiltered(cloudConnection) || cloudConnection.connectionTypeLookupId == 8185,
                                        fingerCursor: userService.hasPermission('CanUpdateCloudConnection'),
                                        isInactive: cloudConnection.isActive == false
                                    }" (click)="selectConnection(cloudConnection)">
                <div class="cloudConnection__row__col">
                    <app-status class="cloudConnection__row__col__active" [green]="cloudConnection?.isActive"
                        [width]="20" [height]="20"></app-status>
                    <div class="cloudConnection__row__col__id everleadCoral">{{ cloudConnection.id }}</div>
                    <div class="cloudConnection__row__col__name">{{ cloudConnection.defaultName }}</div>
                    <div class="cloudConnection__row__col__buttons"
                        *ngIf="cloudConnection.connectionTypeLookupId == 7942">
                        <app-jj-icons type="test-tube" [color]="cloudConnection.isActive ? 'secondary' : 'gray0'"
                            (click)="$event.stopPropagation(); testConnection(cloudConnection)"
                            [tippy]="'CloudConnection.Label.TestConnection' | texttransform"
                            [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}" [width]="20"
                            [height]="20"></app-jj-icons>
                        <app-jj-icons type="sign-in" [color]="cloudConnection.isActive ? 'secondary' : 'gray0' "
                            (click)="$event.stopPropagation(); getSMTPMsoCode(cloudConnection.id)"
                            [tippy]="'CloudConnection.Label.SMTPMsoCODE' | texttransform"
                            [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}" [width]="20"
                            [height]="20"></app-jj-icons>
                    </div>
                </div>
            </div>
        </div>


        <!-- Edit -->
        <div class="cloudConnection__editorContent"
            *ngIf="userService.hasPermission('CanUpdateCloudConnection') && selectedConnection != null && createNewConnection == false"
            id="cloudConnectionEdit">

            <div class="cloudConnection__editorContent__nav">
                <app-main-button type="small" label="General.Label.Back" svg="arrow-left"
                    (onClick)="selectedConnection = null"></app-main-button>
                <app-main-button type="small" label="CloudConnection.Label.UpdateConnection"
                    [disabled]="checkIfDisabled()" (onClick)="updateCloudConnection()"
                    svg="cloud-arrow-up"></app-main-button>
            </div>

            <div class="cloudConnection__editorContent__content">

                <div class="padding">
                    <app-layout>
                        <app-text-input label="CloudConnection.Label.ConnectionName"
                            [(ngModel)]="selectedConnection.defaultName"></app-text-input>
                    </app-layout>
                </div>

                <div class="padding">
                    <app-layout>
                        <app-checkbox label="CloudConnection.Label.IsActive"
                            [(ngModel)]="selectedConnection.isActive"></app-checkbox>

                        <app-checkbox label="CloudConnection.Label.AutoContactAssignment"
                            [(ngModel)]="connectionSettingsJson.AutoContactAssignment"></app-checkbox>

                        <app-checkbox label="CloudConnection.Label.MoveSynchronizedMails"
                            [(ngModel)]="connectionSettingsJson.MoveSynchronizedMails"></app-checkbox>

                        <app-checkbox label="CloudConnection.Label.DeleteSynchronizedMails"
                            [(ngModel)]="connectionSettingsJson.DeleteSynchronizedMails"></app-checkbox>

                        <app-checkbox *ngIf="isCustomerMode == false" class="isDeveloper" label="CloudConnection.Label.AutoContactCreation"
                            [(ngModel)]="connectionSettingsJson.AutoContactCreation"></app-checkbox>
                    </app-layout>
                </div>

                <div class="padding" *ngIf="connectionDataJson != null">
                    <app-layout header="CloudConnection.Label.AuthorityHeader">
                        <div>{{ 'CloudConnection.Label.AuthorityDesc' | texttransform }}</div>
                        <app-text-input label="CloudConnection.Label.Authority"
                            [(ngModel)]="connectionDataJson.Authority"></app-text-input>

                        <app-text-input label="CloudConnection.Label.ClientId"
                            [(ngModel)]="connectionDataJson.ClientId"></app-text-input>

                        <app-text-input label="CloudConnection.Label.ClientSecret" type="password"
                            [(ngModel)]="connectionDataJson.ClientSecret"></app-text-input>

                    </app-layout>
                </div>

                <div class="padding" *ngIf="connectionSettingsJson != null">
                    <app-layout header="CloudConnection.Label.Settings">

                        <app-text-input label="CloudConnection.Label.Email"
                            (ngModel)]="connectionSettingsJson.Email"></app-text-input>

                        <app-text-input label="CloudConnection.Label.DisplayName"
                            [(ngModel)]="connectionSettingsJson.DisplayName"></app-text-input>

                    </app-layout>

                </div>

                <div class="padding isDeveloper" *ngIf="applicationInfoService.isDeveloper">
                    <app-layout>
                        <app-select-input label="CloudConnection.Label.ConnectionProject"
                            [(ngModel)]="selectedConnection.projectId"
                            (valueChange)="updateCloudConnection(selectedConnection)" [option]="projects" bind="value"
                            display="label"></app-select-input>

                        <!--   <select class="cloudConnectionType__select" [(ngModel)]="selectedConnection.projectId"
                        (change)="updateCloudConnection(selectedConnection)">
                        <option [ngValue]=null></option>
                        <option *ngFor="let project of projects" [ngValue]="project.project.id">{{ project.project.projectName |texttransform }}</option>
                    </select> -->
                    </app-layout>
                </div>

                <div class="padding">
                    <app-layout header="User Managment">
                        <div class="userManagement__wrapper">
                            <div class="userManagement__left">
                                <div class="userManagement__sectionHeader">
                                    <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' |
                                        texttransform }}
                                    </div>
                                </div>
                                <div id="userManagementUserList" class="userManagement__itemContainer">
                                    <div id="userWrapper" *ngFor="let user of availableUsers">
                                        <div class="userManagement__item__container fingerCursor">
                                            <div class="userManagement__item__name ellipsisText">{{ user.user?.userName }}
                                            </div>
                                            <div class="userManagement__item__move">
                                                <svg-icon src="assets/images/svg/icon-plus+.svg"
                                                    class="userManagement__moveIcon"
                                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                                    [tippy]="'General.User.AddUser' | texttransform"
                                                    (click)="addUser(user)"></svg-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="userManagement__right">
                                <div class="userManagement__sectionHeader">
                                    <div class="userManagement__sectionHeader__label">{{ 'General.User.AssignedUsers' |
                                        texttransform }}
                                    </div>
                                </div>
                                <div id="userManagementUserList" class="userManagement__itemContainer">
                                    <div id="userWrapper" *ngFor="let user of assignedUsers">
                                        <div class="userManagement__item__container fingerCursor">
                                            <div class="userManagement__item__name ellipsisText">{{ user.user?.userName }}
                                            </div>
                                            <div class="userManagement__item__move">
                                                <svg-icon src="assets/images/svg/icon-minus+.svg"
                                                    class="userManagement__moveIcon"
                                                    [tippy]="'General.User.RemoveUser' | texttransform"
                                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                                    (click)="removeUser(user)"></svg-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-layout>
                </div>

            </div>

        </div>

    </div>
</div>