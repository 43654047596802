import { Injectable, EventEmitter } from '@angular/core';
import { Observable, map } from "rxjs";
import { LoggerService } from '../../core/logger.service';
import { environment } from '../../../environments/environment';
import { AuthService, AuthHTTPMethods } from '../../core/authentication/services/auth.service';
import { TranslationLanguage } from '../translation/translationLanguage';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { HttpResponse } from '@angular/common/http';

const API_URL = environment.translationAPI;
// const API_Backend = this.applicationInfoService.apiBackendUrl;
const API_DATA = environment.apiData;
const API_MAIL = environment.apiMail;

@Injectable()
export class SharedAPI  {
  public  logger: LoggerService;
  private authService: AuthService;
  public eventAxivasLogoClickBlocked: EventEmitter<boolean> = new EventEmitter();
  public eventSilentRenewError: EventEmitter<boolean> = new EventEmitter();

    public constructor(
      logger: LoggerService,
      authService: AuthService,
      private applicationInfoService: ApplicationInfoService,
    ) {
      this.logger = logger;
      this.authService = authService;
      this.authService.eventSilentRenewError.subscribe(errorThrown => {
      this.throwSilentRenewError(errorThrown);
    });
  }

  public getDataFromAPI(apiUrl: string) {
    apiUrl = apiUrl.replace('{apiUrl}', environment.apiUrl);
    apiUrl = apiUrl.replace('{trApiUrl}', environment.trApiUrl);
    console.log('APIUrl', apiUrl);
    return this.authService
      .AuthGet(apiUrl)
      .pipe(
        map(response => {
          const regEx = new RegExp(/{(\d+)}/g);
          const returnValue = JSON.parse(JSON.stringify(response).replace(regEx, '{{$1}}'));
          // this.logger.Debug([{ source: 'Calling' + apiUrl,  returnValue: returnValue,  response : response}]);
          return returnValue;
        })
      );
  }

  public uploadFile(formData) {
    const apiRoute = this.applicationInfoService.apiBackendUrl + '/Import/ExtractData';
    return this.authService
      .UploadFile(apiRoute, formData)
      .pipe(
        map(response => {
          console.log('response uploadFile', response)
          const regEx = new RegExp(/{(\d+)}/g);
          const returnValue = JSON.parse(JSON.stringify(response).replace(regEx, '{{$1}}'));
          return returnValue;
        })
      );
  }

  public trackPage(database, urlParam, refUrl, email) {
    let url = 'https://tr.marketing-cloud.io/?tzOff=-60&url=' + urlParam + '&acId=' + database + '&type=pageView&platform=Win32&ref_url=' + refUrl + '&email=' + email;
    return this.authService.Get(url).subscribe();
  }

  public restartJob(jobId) {
    const apiRoute = this.applicationInfoService.apiBackendUrl.concat('/Job/run/', jobId.toString());
    return this.authService.AuthPost(apiRoute, null);
  }

  checkMicroCampaignQueue() {
    const apiRoute = this.applicationInfoService.apiBackendUrl.concat('/Email/checkMicrocampaignQueue');
    return this.authService.AuthPost(apiRoute, null);
  }  

  clearProject(projectId) {
    const apiRoute = environment.apiUrl.concat('/AdminRefreshRepositoryCache/clearProject?projectId=', projectId.toString());
    return this.authService.AuthPost(apiRoute, null);
  }  

  public setMailJobStatus(batchId, statusId) {
    const apiRoute = this.applicationInfoService.apiBackendUrl.concat(
      '/EmailEvent/setQueueStatus?projectId=' +
      this.applicationInfoService.projectID +
      '&batchEmailId=' + batchId +
      '&status=' + statusId
    );
    return this.authService.AuthGet(apiRoute, null);
  }

  public extendTrial(guid, considerNurturingTasks = true) {
    const apiRoute = API_DATA.concat('/DemoUser/demo/extendTrialPeriod/', guid , '?considerNurturingTasks=', considerNurturingTasks);
    return this.authService.AuthPost(apiRoute, null);
  }

  public refreshSMTPSetting(settingId) {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/Email/syncMailBox/<0>/<1>/0');
    apiRoute = apiRoute.replace('<0>', this.applicationInfoService.projectID);
    apiRoute = apiRoute.replace('<1>', settingId);
    return this.authService.AuthPost(apiRoute, null);
  }

  public smartStartMicroCampaign(body) {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/microcampaign/smartStart');
    return this.authService.AuthPost(apiRoute, body);
  }

  public smartAssignAccounts(body) {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/accountOwner/smartAssign');
    return this.authService.AuthPost(apiRoute, body);
  }

  public smartDistributeAccounts(body) {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/accountOwner/smartDistribute');
    return this.authService.AuthPost(apiRoute, body);
  }

  public smartStopMicroCampaign(body) {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/microcampaign/smartStop');
    return this.authService.AuthPost(apiRoute, body);
  }

  public createPDFFromHTML(body) {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/Export/convertToPdf');
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Post, body);
  }

  public checkEmailBatchQueue() {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/Email/checkEmailBatchQueue');
    return this.authService.AuthPost(apiRoute, null);
  }

  public runJobStep(stepId) {
    const apiRoute = this.applicationInfoService.apiBackendUrl.concat('/Job/runStep/', stepId.toString());
    return this.authService.AuthPost(apiRoute, null);
  }

  public getTranslationIdsForLanguage(lang: string): Observable<any> {
    const apiRoute = API_URL + '/Translation/TokensMin/' + lang;
    return this.authService
      .AuthGet(apiRoute)
      .pipe(
        map(response => { return response; })
      );
  }

  public activateProject(projectId) {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/ProjectSetup/confirm/projectValidity');
    const body = { projectId: projectId };    
    return this.authService.AuthPost(apiRoute, body);
  }

  public getTranslationsForLanguage(lang: string): Observable<any> {
    const apiRoute = API_URL + '/Translation/Tokens/' + lang + '.json';
    return this.authService
      .AuthGet(apiRoute)
      .pipe(
        map(response => { return response; })
      );
  }

  public getNotImportedRecordCounts(body): Observable<any> {
    const apiRoute = this.applicationInfoService.apiBackendUrl + '/Import/HasNotImportedData?projectId=' + this.applicationInfoService.projectID;
    return this.authService.AuthGetPostBody(apiRoute, body);
  /*   return this.authService.AuthGetPostBody(apiRoute, body).pipe(map(response => {
      console.log('response', response)
      return response
    })) */
  }

  public cloneProject(sourceProjectId: string, customerName: string, newProjectName: string, schemaDataSource: string, 
    schemaDataDestination: string): Observable<any> {
    newProjectName = newProjectName.replace('&', '%26');
    const apiRoute = this.applicationInfoService.apiBackendUrl + 
      '/ProjectSetup/clone/project/' + 
      sourceProjectId + '/'
      + customerName + '/'
      + newProjectName + '/'
      + schemaDataSource + '/'
      + schemaDataDestination  
    ;

    return this.authService.AuthGet(apiRoute).pipe(map(response => {
        const returnValue = JSON.parse(JSON.stringify(response));
        return returnValue;
      })
    );
  }

  public queryTest(projectId: string, connectionGroupId, body): Observable<any> {
    let apiRoute = this.applicationInfoService.apiBackendUrl + '/Report/TestQuery?projectId=<0>&queryConnectionGroupId=<1>';
    apiRoute = apiRoute.replace('<0>', projectId);
    apiRoute = apiRoute.replace('<1>', connectionGroupId);
    return this.authService.AuthGetPostBody(apiRoute, body);
  }

  updateDataSchema(projectId) {
    let apiRoute = environment.apiData + '/Postgraphile/metadata/reload?projectId=<0>';
    apiRoute = apiRoute.replace('<0>', projectId);
    return this.authService.AuthPost(apiRoute, null);
  }

  unloadDataSchema(projectId) {
    let apiRoute = environment.apiData + '/Postgraphile/metadata/unload?projectId=<0>';
    apiRoute = apiRoute.replace('<0>', projectId);
    return this.authService.AuthPost(apiRoute, null);
  }

  deleteProject(projectId) {
    let apiRoute = this.applicationInfoService.apiBackendUrl + '/ProjectSetup/delete/project/<0>';
    apiRoute = apiRoute.replace('<0>', projectId);
    return this.authService.AuthPost(apiRoute, null);
  }

  createAPIAccount(array) {
    let apiRoute = environment.apiData + '/ExternalUser/CreateAPIAccount';
    return this.authService.AuthGetPostBody(apiRoute, array);
  }

  checkDemoAccount(userEMail) {
    let apiRoute = environment.apiData + '/DemoUser/demo/existsDemoAccount?email=<0>'.replace(
      '<0>',
      encodeURIComponent(userEMail)
    );
    return this.authService.AuthGet(apiRoute);
  }

  createDemoAccount(demoUserArray) {
    let apiRoute = environment.apiData + '/DemoUser/demo/createNewDemoAccount';
    return this.authService.AuthPost(apiRoute, demoUserArray);
  }

  createExternalAccount(array) {
    let apiRoute = environment.apiData + '/ExternalUser/CreateExternalAccount';
    return this.authService.AuthGetPostBody(apiRoute, array);
  }

  updateCentralSchema() {
    let apiRoute = environment.apiUrl + '/Postgraphile/metadata/reload';
    return this.authService.AuthPost(apiRoute, null);
  }

  public createSchema(dataInstanceSchema: string): Observable<any> {
    const apiRoute = this.applicationInfoService.apiBackendUrl + '/DataInstance/create/template/' + dataInstanceSchema + '/';
    return this.authService
      .AuthGet(apiRoute)
      .pipe(
        map(response => {
          const returnValue = JSON.parse(JSON.stringify(response));
          // this.logger.Debug([{ source: 'Calling createSchema',  returnValue: returnValue,  response : response}]);
          return returnValue;
        })
      );
  }

  public getTranslationLanguages(): Observable<TranslationLanguage[]> {
    const apiRoute = API_URL + '/Translation/Languages';
    return this.authService
      .AuthGet(apiRoute)
      .pipe(
        map(response => {
          console.log('response', response)
          const returnValue =  response.map(data => new TranslationLanguage({identifier: data}));
          // this.logger.Debug([{ source: 'Calling getTranslationLanguages',  returnValue: returnValue,  response : response}]);
          return returnValue;
        })
      );
  }

  public throwSilentRenewError(value: boolean) {
    this.eventSilentRenewError.emit(value);
  }

  public axivasLogoClickBlocked(value: boolean) {
    this.eventAxivasLogoClickBlocked.emit(value);
  }

  public executeReporting(route: any, templateId: any, fileName: any, projectId: any, body: any): any {
    let apiRoute = this.applicationInfoService.apiBackendUrl + route;
    apiRoute = apiRoute.replace('<0>', templateId);
    apiRoute = apiRoute.replace('<1>', fileName);
    apiRoute = apiRoute.replace('<2>', projectId);
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Post, body);
  }

  public importData(url: string, body: any, toTempTable: boolean = false): any {
    const apiRoute = this.applicationInfoService.apiBackendUrl + url;
    if (toTempTable) {
      return this.authService.UploadFile(apiRoute, body);
    } else {
      return this.authService.UploadFile(apiRoute, body);
    }
  }

  public importBlacklistData(url: string, body: any, importData, blacklistType, fieldName): any {
    let apiRoute = this.applicationInfoService.apiBackendUrl + url + '?projectId=<0>&blacklistType=<1>&sheetName=<2>&fieldName=<3>';
    apiRoute = apiRoute.replace('<0>', this.applicationInfoService.projectID.toString());
    apiRoute = apiRoute.replace('<1>', blacklistType);
    apiRoute = apiRoute.replace('<2>', importData);
    apiRoute = apiRoute.replace('<3>', fieldName);
    return this.authService.UploadBlacklistFile(apiRoute, body, importData, blacklistType, fieldName);
  }


  public checkImportData(url: string, body: any): any {
    const apiRoute = this.applicationInfoService.apiBackendUrl + url;
    return this.authService.UploadFile(apiRoute, body);
  }

  public getImportDetails(guid: any, projectId: any, isSkipped: any = null): any {
    let apiRoute = this.applicationInfoService.apiBackendUrl + '/Import/ImportResult?projectId=<0>&guid=<1>';
    if (!isSkipped === null) {
      apiRoute = apiRoute.concat('&isSkipped=<2>');
    }
    apiRoute = apiRoute.replace('<0>', projectId);
    apiRoute = apiRoute.replace('<1>', guid);
    apiRoute = apiRoute.replace('<2>', isSkipped);
    const body = '';
    return this.authService.AuthGetPostBody(apiRoute, body);
  }

  createFromPassedData(body: any) {
    const apiRoute = this.applicationInfoService.apiBackendUrl + '/Export/createFromPassedData';
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Post, body);
  }

  exportMatTableData(body: any) {
    const apiRoute = this.applicationInfoService.apiBackendUrl + '/ControlData/createExcel';
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Post, body);
  }

  exportNotImportedData(projectId, guid) {
    const body = null;
    const apiRoute = this.applicationInfoService.apiBackendUrl + '/Import/NotImportedList?projectId=' + projectId + '&guid=' + guid;
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Get, body);
  }

  public executeLeadReport(route: any, accountId: any, templateId: any, languageId: any,
    onlyNetResults: any, level: any, contactId: any, fileType: any): Observable<HttpResponse<any>> {
    // let apiRoute = this.applicationInfoService.apiBackendUrl + '/Leadreport/create/<0>/<1>/?languageId=<2>';
    let apiRoute = this.applicationInfoService.apiBackendUrl + route;
    apiRoute = apiRoute.replace('<0>', templateId);
    apiRoute = apiRoute.replace('<1>', accountId);
    apiRoute = apiRoute.replace('<2>', contactId);
    apiRoute = apiRoute.replace('<3>', languageId);
    apiRoute = apiRoute.replace('<4>', onlyNetResults);
    apiRoute = apiRoute.replace('<5>', level);
    apiRoute = apiRoute.replace('<6>', fileType);
    return this.authService.AuthGetBlobResponse(apiRoute);
  }

  downloadAttachment(attachmentId, body: any) {
    // const apiRoute = this.applicationInfoService.apiBackendUrl.concat(
    //   '/Email/getAttachement/',
    //   this.applicationInfoService.projectID,
    //   '/',
    //   attachmentId
    // );
    let apiRoute = API_MAIL + '/Attachment/getAttachement/{projectId}/{ressourceId}'
    apiRoute = apiRoute.replace('{projectId}', this.applicationInfoService.projectID.toString());
    apiRoute = apiRoute.replace('{ressourceId}', attachmentId);
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Get, body);
  }

  downloadRecording(recording, body: any) {
    let url = recording.partialFileStorageUrl;
    if (url == undefined || url == null) {
      url = recording.storageUrl;
    }
    body = {'uri': url };
    const apiRoute = this.applicationInfoService.apiBackendUrl.concat(
      '/Recording/download'
    );
    console.warn(url, apiRoute)
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Post, body);
  }

  public syncRecordings() {
    const apiRoute = this.applicationInfoService.apiBackendUrl.concat('/Recording/syncRecordings/', this.applicationInfoService.projectID);
    return this.authService.AuthGet(apiRoute, null);
  }

  uploadAttachment(files) {
    const apiRoute = API_MAIL + '/Email/attachments/upload?projectId=<0>'.replace('<0>', this.applicationInfoService.projectID.toString());
    const formData = new FormData();
    const fileArray = [];
    for ( let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    return this.authService.UploadFile(apiRoute, formData)
      .pipe(
        map(response => {
          const regEx = new RegExp(/{(\d+)}/g);
          const returnValue = JSON.parse(JSON.stringify(response).replace(regEx, '{{$1}}'));
          return returnValue;
        })
      );
  }

  uploadEmailTemplateAttachment(file, templateId, languageId) {
    //let apiRoute = API_Backend + '/Email/uploadEmailTemplateAttachment/{projectId}/{templateId}/{languageId}';
    let apiRoute = API_MAIL + '/Attachment/uploadEmailTemplateAttachment/{projectId}/{templateId}/{languageId}'
    apiRoute = apiRoute.replace('{projectId}', this.applicationInfoService.projectID.toString());
    apiRoute = apiRoute.replace('{templateId}', templateId);
    apiRoute = apiRoute.replace('{languageId}', languageId);
    const formData = new FormData();
    formData.append('file', file);
    return this.authService.UploadFile(apiRoute, formData)
      .pipe(
        map(response => {
          const regEx = new RegExp(/{(\d+)}/g);
          const returnValue = JSON.parse(JSON.stringify(response).replace(regEx, '{{$1}}'));
          return returnValue;
        })
      );
  }

  uploadEntityFile(file, entityId, refId) {
    let apiRoute = API_MAIL + '/Attachment/uploadEmailTemplateAttachment/{projectId}/{templateId}/{languageId}'
    apiRoute = apiRoute.replace('{projectId}', this.applicationInfoService.projectID.toString());
    apiRoute = apiRoute.replace('{entityId}', entityId);
    apiRoute = apiRoute.replace('{refId}', refId);
    const formData = new FormData();
    formData.append('file', file);
    return this.authService.UploadFile(apiRoute, formData)
      .pipe(
        map(response => {
          const regEx = new RegExp(/{(\d+)}/g);
          const returnValue = JSON.parse(JSON.stringify(response).replace(regEx, '{{$1}}'));
          return returnValue;
        })
      );
  }

  public executeExportTemplatePost(route: any, body: any): any {
    const apiRoute = this.applicationInfoService.apiBackendUrl + route;
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Post, body);
  }

  public entityTableExport(entityTableId, exportName, body: any): any {
    let apiRoute = this.applicationInfoService.apiBackendUrl + '/EntityTableExport/get/<0>/<1>';
    apiRoute = apiRoute.replace('<0>', entityTableId);
    apiRoute = apiRoute.replace('<1>', exportName);
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Post, body);
  }

  public getBlob(route) {
    route = route.replace('{apiUrl}', environment.apiUrl);
    route = route.replace('{trApiUrl}', environment.trApiUrl);
    route = route.replace('{backendApiUrl}',  this.applicationInfoService.apiBackendUrl);
    return this.authService.AuthGetBlob(route)
  }

  public executeExportTemplate(templateId: any, sheetName: any, fileName, languageId: any, filters: any): any {
    const filterAsJson = [...filters].map(([key, value]) => `${key}:${value}`).join(', ');
    let apiRoute = this.applicationInfoService.apiBackendUrl + '/Export/create/<0>/<1>/<2>/?languageId=<3>&filterAsJson=<4>';

    apiRoute = apiRoute.replace('<0>', templateId);
    apiRoute = apiRoute.replace('<1>', sheetName);
    apiRoute = apiRoute.replace('<2>', fileName);
    apiRoute = apiRoute.replace('<3>', languageId);
    apiRoute = apiRoute.replace('<4>', '{' + filterAsJson + '}');
    return this.authService.AuthGetBlob(apiRoute);
  }

  public repoCacheClearCentral(): any {
    const apiRoute = environment.apiUrl + '/AdminRefreshRepositoryCache/clear';
    return this.authService.AuthGet(apiRoute);
  }

  sendSMTPMail(body) {
    const apiRoute = API_MAIL + '/IMAP/sendMail';
    return this.authService.AuthPost(apiRoute, body);
  }

  exportQuestionnaire(body) {
    let apiRoute = this.applicationInfoService.apiBackendUrl.concat('/Export/exportQuestionnaire');
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Post, body);
  }

  getSMTPMsoCode(cloudConnectionId) {
    const apiRoute = API_MAIL + '/IMAP/mso-code-flow/<0>'.replace('<0>', cloudConnectionId);
    return this.authService.AuthGet(apiRoute);
  }

  syncCloucConnetions() {
    const apiRoute = API_MAIL + '/IMAP/sync';
    return this.authService.AuthPost(apiRoute, 2);
  }

  compareValues(key, order= 'asc') {
    return function(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
          return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  refreshBoldBi() {
    let apiRoute = this.applicationInfoService.apiBackendUrl + '/BoldBi/refreshDataMart/<0>';
    apiRoute = apiRoute.replace('<0>', this.applicationInfoService.projectID.toString());
    return this.authService.AuthGet(apiRoute, null);
  }

  getBoldBiTimeStamp() {
    let apiRoute = this.applicationInfoService.apiBackendUrl + '/BoldBi/getLastSyncTime/<0>';
    apiRoute = apiRoute.replace('<0>', this.applicationInfoService.projectID.toString());
    return this.authService.AuthGet(apiRoute, null);
  }

  resetUserPassword(body) {
    let apiRoute = environment.apiData + '/ExternalUser/resetPassword';
    return this.authService.AuthPost(apiRoute, body);
  }

  // ContentLibrary
  contentLibraryCreatFolder(body) {
    let apiRoute = environment.apiData + '/ContentLibrary/folder/create';
    return this.authService.AuthPost(apiRoute, body);
  }

  contentLibraryUploadFile(body) {
    let apiRoute = environment.apiData + '/ContentLibrary/file/upload';
    return this.authService.UploadFile(apiRoute, body);
  }

  contentLibraryDeleteFile(id, contentLibraryId) {
    let apiRoute = environment.apiData + '/ContentLibrary/file/delete/<0>/<1>/<2>';
    apiRoute = apiRoute.replace('<0>', this.applicationInfoService.projectID.toString());
    apiRoute = apiRoute.replace('<1>', contentLibraryId);
    apiRoute = apiRoute.replace('<2>', id);
    return this.authService.AuthGet(apiRoute, null);
  }

  contentLibraryDownloadFile(id, contentLibraryId) {
    let apiRoute = environment.apiData + '/ContentLibrary/file/download/<0>/<1>/<2>';
    apiRoute = apiRoute.replace('<0>', this.applicationInfoService.projectID.toString());
    apiRoute = apiRoute.replace('<1>', contentLibraryId);
    apiRoute = apiRoute.replace('<2>', id);
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Get, null);
  }

  getTemplateAttachement(id) {
    let apiRoute = API_MAIL + '/Attachment/getTemplateAttachement/{ressourceId}';
    apiRoute = apiRoute.replace('{ressourceId}', id);
    return this.authService.AuthGetBlob(apiRoute, AuthHTTPMethods.Get, null);
  }

  /// CustomerAPI
  getEonVCheck(url, body) {
    return this.authService.AuthPost(url, body)
  }


  // Stripe
  selfServiceUpdateLicences(newLicenseCount, perMonth) {    
    let apiRoute = environment.apiBackend + '/SelfService/updateLicenses';
    return this.authService.AuthPost(apiRoute, {
      projectId: this.applicationInfoService.projectID,
      newLicenseCount: newLicenseCount,
      paymentPerMonth: perMonth
    });
  }

  selfServiceUpgradePackage(packageId, newLicenseCount, perMonth) {    
    let apiRoute = environment.apiBackend + '/SelfService/upgradePackage';
    return this.authService.AuthPost(apiRoute, {
      projectId: this.applicationInfoService.projectID,
      newPackageId: packageId,
      newQuantity: newLicenseCount,
      paymentPerMonth: perMonth
    });
  }

  selfServiceCancelSubscription(immediateCancellation = false) {
    let apiRoute = environment.apiBackend + '/SelfService/CancelSubscription';
    return this.authService.AuthPost(apiRoute, {
      projectId: this.applicationInfoService.projectID,
      immediateCancellation: immediateCancellation
    });
  }

  // UserData
  getUserData(email) {
    let apiRoute = environment.apiUrl + '/User/ByEmail?email='.concat(encodeURIComponent(email));
    return this.authService.JustGet(apiRoute);    
  }
  getOTPByGUID(guid) {
    let apiRoute = environment.apiUrl + '/User/OTPByGUID?guid='.concat(encodeURIComponent(guid));
    return this.authService.JustGet(apiRoute);    
  }
}
