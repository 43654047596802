<div class="licenseNavigation">
    <app-navbar [selectedId]="1" [options]="[
        { id: 1, label: 'Supervisor.Label.SelfServiceLicense', hidden: false },
        { id: 2, label: 'DLC.Label.EVERLEADCoins', hidden: false }
    ]" (onNavItemChange)="mainViewMode = $event"></app-navbar> 
</div>

<div class="packageUpgradeWrapper">
    <div class="myPlan" *ngIf="mainViewMode == 1">
    <div class="packageUpgradeCurrentPlan" *ngIf="mainViewMode == 1">
        <!-- My account -->
        <app-layout header="Licensing.Label.CurrentPlan">
            <app-main-button width="260px" (onClick)="showInvoicePage()" label="Licensing.Label.Invoice" svg="arrow-up-right" position="right" class="packageUpgradeCurrentPlanInvoice"></app-main-button>

            <div class="licensingUserlistHeader">
                <div class="licensingUserlistHeader__item licensingUserlistHeader__border">
                    <div class="licensingUserlistHeader__item__label ellipsisText">{{ license?.defaultName }}</div>
                    <div class="licensingUserlistHeader__item__value ellipsisText">
                        {{ licensingService.licenseInfo.licenseCount }} {{ 'Licensing.Label.LicenseAmount' | texttransform }}
                    </div>
                </div>
                <div class="licensingUserlistHeader__item licensingUserlistHeader__border">
                    <div class="licensingUserlistHeader__item__label
                        licensingUserlistHeader__item__label__uppercase ellipsisText" *ngIf="licensingService.isMonthlySubscription()">
                        {{ license?.pricePerMonth }} {{ license?.currency }}
                    </div>
                    <div class="licensingUserlistHeader__item__label
                        licensingUserlistHeader__item__label__uppercase ellipsisText" *ngIf="!licensingService.isMonthlySubscription()">
                        {{ license?.pricePerMonth }} {{ license?.currency }}
                    </div>
                    <div class="licensingUserlistHeader__item__value ellipsisText" *ngIf="licensingService.isMonthlySubscription()">
                        {{ 'PackageUpgrade.Label.LicenseUserMonth' | texttransform }}
                    </div>
                    <div class="licensingUserlistHeader__item__value ellipsisText" *ngIf="!licensingService.isMonthlySubscription()">
                        {{ 'PackageUpgrade.Label.LicenseUserYear' | texttransform }}
                    </div>
                </div>
                <div class="licensingUserlistHeader__item licensingUserlistHeader__item__end">
                    <app-main-button width="260px" svg="plus" (onClick)="addLicenses()" label="Licensing.Label.AddLicense"></app-main-button>
                </div>
            </div>
        </app-layout>
    </div>
    

    <div class="processingChange" *ngIf="processingChange">
        {{ 'PackageUpgrade.Label.ProcessingChange' | texttransform }}
    </div>    

    <div class="packageUpgradeCurrentMore" *ngIf="mainViewMode == 1">
        <app-licensing-package-upgradeitem *ngIf="packagesSales.length > 0" [packages]="packagesSales" [showLicenseIntervall]="true"
            [showHighlight]="true" [license]="license"></app-licensing-package-upgradeitem>
        <app-licensing-package-upgradeitem  *ngIf="packagesMarketing.length > 0" [packages]="packagesMarketing" [license]="license"
        ></app-licensing-package-upgradeitem>
        <app-licensing-package-upgradeitem *ngIf="packagesWebhub.length > 0" [packages]="packagesWebhub" [license]="license"
        ></app-licensing-package-upgradeitem>
    </div>
    </div>

    <div class="packageUpgradeCurrentMore" *ngIf="mainViewMode == 2">
        <!-- EVERLEAD Coins -->
        <div *ngIf="everleadCoinOffers.length > 0" class="dlcHeaderBox">
            <div class="dlcHeader" [innerHTML]="'DLC.Label.EVERLEADCoins' | texttransform"></div>            
            <div class="dlcSubHeader" [innerHTML]="'PackageUpgrade.Label.EVERLEADCoinSubheader' | texttransform"></div>            
            <div class="packageUpgradeItemWrapperLineSmallBorder"></div>
        </div>

        <div class="packageUpgradeDLC">            
            <div *ngFor="let dlcCategory of everleadCoinOffers">
                <!-- <div class="dlcCategoryHeader">{{ dlcCategory.defaultName | texttransform }}</div> -->
                <div class="packagesBox">
                    <div *ngFor="let dlc of dlcCategory.dlcPackages">
                        <div class="dlcPackageWrapper">
                            <div class="dlcPackage">
                                <div class="dlcPackage__title">                                
                                    {{ dlc.settingJson.packageSectionHeader | texttransform }}
                                </div>
                                <div class="dlcPackage__title">                                
                                    {{ dlc.settingJson.packageSectionName | texttransform }}
                                </div>
                                <div class="dlcPackage__subtitle">{{ dlc.settingJson.packageSubSectionName | texttransform }}</div>
                            </div>
                            <div class="dlcPackagePrice">
                                <div>{{ getDLCPrice(dlc) | texttransform }}</div>                            
                                <div class="oldPrice">
                                    <span *ngIf="dlc.settingJson.packageDiscount != null">
                                        {{ 'PackageUpgrade.Label.OldPrice' | texttransform }}
                                        <span class="oldPrice__strike">{{ dlc.settingJson.packageDiscountOldPrice }}</span>
                                        ({{ dlc.settingJson.packageDiscount | texttransform }})
                                    </span>
                                </div>                                
                            </div>
                            <div class="dlcContentLine"></div>
                            <div class="dlcPackage__items">
                                <div class="dlcPackageItem" *ngFor="let dlcItem of dlc.settingJson.dlcItems">
                                    <div class="dlcPackageItem__icon">
                                        <app-jj-icons [type]="dlcItem.itemIcon" [width]="20" [height]="20" disabled="true"
                                            color="fontDark"></app-jj-icons>
                                    </div>
                                    <div class="dlcPackageItem__amount">{{ dlcItem.itemAmount }}</div>
                                    <div class="dlcPackageItem__name">{{ dlcItem.itemName | texttransform }}</div>
                                </div>
                            </div>                    
                            <div class="dlcPackageButtons">
                                <app-main-button svg="arrow-up-right" class="maxWidth" width="100%" position="right" (onClick)="orderDLC(dlc)" 
                                    [label]="dlc.settingJson.buttonText" fontSize="14px"></app-main-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="packageUpgradeCurrentMore" *ngIf="mainViewMode == 3">
            <!-- DLC -->
        </div>    
    </div>
</div>