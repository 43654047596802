import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GraphQLService, ApolloMethod, JJApolloClient } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DragDropelements, UiService } from 'app/jollyjupiter/service/ui.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { Dictionary } from 'app/core/dictionary';
import { ConfirmationService } from 'primeng/api';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { LoaderService } from 'app/shared/service/loader-service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { isNullOrUndefined } from 'util';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';

@Component({
  selector: 'app-automatic-record-assignment',
  templateUrl: './automatic-record-assignment.component.html',
  styleUrls: ['./automatic-record-assignment.component.scss']
})
export class AutomaticRecordAssignmentComponent implements OnInit, OnDestroy {
  @ViewChild(Table) dt: Table;
  prioritiesList = new Object([
    {'field': 'entity.entityName', 'header': 'AutomaticRecordAssignment.Priority.Entity', 'width': '40%' },
    {'field': 'entityMember.defaultName', 'header': 'AutomaticRecordAssignment.Priority.Member', 'width': '40%' },
    {'field': 'ascending', 'header': 'AutomaticRecordAssignment.Priority.Ascending', 'width': '20%' }
  ]);

  createNewGroup = false;
  selectedGroup = null;
  loadingData = true;
  dataFilterObject = null;
  prioritiesListData = [];
  priorityMode = 1;
  modifiedPriority = null;
  priorityEntities = [];
  resubmissionActivityArray: any [] = [];
  expandedPanel = 0;
  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  dataFilterCaller = 'automatic-record-assignment';
  tab = 0;
  optionThreeEnabled = false;
  minutesUntilRelease = 0;
  hoursNoDialing = 0;
  hoursAutoFollowup = 0;
  userGroupDefaultName = '';
  public currentFollowUpUserGroup = -1;
  currentCampaign = null;
  campaigns = null;
  followUpUserGroups = [];
  followUpUserGroupTypes = [];
  componentMode = 1;
  newUserGroupName = '';
  dataFilter = null;
  dragdropElements: DragDropelements[] = [];
  followUpUserGroupPriorities = [];
  priorityEntitiyMembers = [];
  selectedRecordAssignment = null;


  constructor(
    private graphQLService: GraphQLService,
    public applicationInfoService: ApplicationInfoService,
    private graphqlqueryService: GraphqlqueryService,
    public commonService: CommonService,
    private confirmationService: ConfirmationService,
    private messagingService: MessagingService,
    private jjtranslationService: JjtranslationService,
    private externaldatasourceService: ExternaldatasourceService,
    public dragdropService: DragdropService,
    public eventService: EventService,
    private datafilterService: DatafilterService,
    private projectService: ProjectService,
    private refreshValueService: RefreshValueService,
    private axivasTranslateService: AxivasTranslateService,
    private loaderService: LoaderService
  ) { }

  ngOnDestroy() {
    if (this.selectCurrentDataFilterEventSubscription) { this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.getProjectEntities();
    this.getCampaigns();
    this.dragdropElements.push(new DragDropelements());
    this.dragdropElements.push(new DragDropelements());
    this.getFollowUpUserGroups();
    this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(event => {
      if (event.target === this.dataFilterCaller) {
        this.selectedRecordAssignment.filterId = event.arguments[0].id;
        this.saveUserGroup(this.selectedRecordAssignment);

        this.dataFilter = event.arguments[0].id;
        this.dataFilterObject = event.arguments[0];
      }
    });
  }

  saveUserGroup(selectedGroup) {
    return new Promise((updateFollowUpUserGroupMutationSuccess, updateFollowUpUserGroupMutationReject) => {
      this.externaldatasourceService.executeExternalDataSource(826, [
        this.commonService.getModifyArrayBody(selectedGroup, [])
      ])
      .then(result => { updateFollowUpUserGroupMutationSuccess(result);})
      .catch(error => { updateFollowUpUserGroupMutationReject(error);});
    });
  }

  deleteDataFilter() {
    this.dataFilter = null;
    this.dataFilterObject = null;
    this.selectedRecordAssignment.filterId = null;
    this.saveUserGroup(this.selectedRecordAssignment);
  }

  getCampaigns() {
    this.refreshValueService.getProjectCampaigns()
    .then(result => {
      this.campaigns = result.data.campaigns;
    });
  }

  moveInList(event: CdkDragDrop<string[]>, isPriority = false, isType = false) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    if (isPriority) {
      this.regroupPriorities();
    }
    if (isType) {
      this.regroupTypes();
    }
  }

  setExpandedPanel(panelId: any) {
    if (this.expandedPanel === panelId) {
      this.expandedPanel = -1;
    } else {
      this.expandedPanel = panelId;
    }
  }

  getPriorityList() {
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
      this.graphqlqueryService.automaticRecordAssignmentFollowUpUserGroupPriorities, [this.selectedRecordAssignment.id])
    .then(automaticRecordAssignmentFollowUpUserGroupPrioritiesResult => {
      automaticRecordAssignmentFollowUpUserGroupPrioritiesResult.data.followUpUserGroupPriorities.sort((a, b) =>a.order < b.order ? -1 : 1);
      this.followUpUserGroupPriorities = automaticRecordAssignmentFollowUpUserGroupPrioritiesResult.data.followUpUserGroupPriorities
    });
  }

  changeUserGroup(userGroup) {
    this.selectedGroup = userGroup;
    this.loadUserGroupData(userGroup.id);
  }

  deleteUserGroupPriority(priority) {
    this.externaldatasourceService.executeExternalDataSource(828, [priority.id])
    .then(() => {
      this.commonService.removeItemFromArray(this.followUpUserGroupPriorities, priority);
    });
  }

  regroupPriorities() {
    let count = 1;
    this.followUpUserGroupPriorities.forEach(priority => {
      priority.order = count;
      this.updateFollowUpPriority(priority);
      count ++;
    });
  }

  regroupTypes() {
    let counter = 1;
    this.followUpUserGroupTypes.forEach(type => {
      type.order = counter;
      this.updateUserGroupType(type);
      counter ++;
    });
  }

  updateFollowUpPriority(priority) {
    this.externaldatasourceService.executeExternalDataSource(829, [this.commonService.getModifyArrayBody(priority, ['entity', 'entityMember'])]);
  }

  createFollowUpPriority() {
    const priority = {
      entityId: 1,
      memberId: 1,
      ascending: true,
      order: this.followUpUserGroupPriorities.length + 1,
      followupUserGroupId: this.selectedRecordAssignment.id
    }
    this.externaldatasourceService.executeExternalDataSource(827, [
      this.commonService.getModifyArrayBody(priority, [])
    ])
    .then(createFollowUpPriorityResult => {
      priority['id'] = createFollowUpPriorityResult.id;
      this.followUpUserGroupPriorities.push(priority);
    });
  }

  loadUserGroupData(userGroup: any) {
    this.getFollowUpUserGroupTypes(userGroup);
    this.loaderService.display(true);
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
      this.graphqlqueryService.automaticRecordAssignmentFollowUpUserGroup, [userGroup])
    .then(result => {
      this.loaderService.display(false);
      if (result.data.followUpUserGroup) {
        this.selectedRecordAssignment = result.data.followUpUserGroup;
        this.currentCampaign = result.data.followUpUserGroup.campaignId;
        this.userGroupDefaultName = result.data.followUpUserGroup.defaultName;
        this.minutesUntilRelease = result.data.followUpUserGroup.minutesUntilRelease;
        if (!isNullOrUndefined(this.minutesUntilRelease)) {
          this.optionThreeEnabled = true;
        }
        this.hoursAutoFollowup = result.data.followUpUserGroup.hoursAutoFollowup;
        this.hoursNoDialing = result.data.followUpUserGroup.hoursNoDialing;
        if (result.data.followUpUserGroup.filterId != null) {
          this.dataFilter = result.data.followUpUserGroup.filterId;
          this.getDataFilterObject(result.data.followUpUserGroup.filterId);
        } else {
          this.dataFilter = null;
          this.dataFilterObject = null;
        }
        this.getPriorityList();
        this.updateGroupUserList();
      }
    })
    .catch(error => {
      this.loaderService.display(false);
      console.error(error);
    });
  }

  updateGroupUserList() {
    this.getAssignedUsers().then(() => { this.getAvailableUsers(); });
  }

  getFollowUpUserGroups() {
    this.loadingData = true;
    this.followUpUserGroups = [];
    this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.automaticRecordAssignmentFollowUpUserGroups)
    .then(result => {
      this.loadingData = false;
      result.data.followUpUserGroups.forEach(followUpUserGroup => {
        followUpUserGroup.defaultName = this.commonService.getTranslationValueFromArray(followUpUserGroup);
        this.followUpUserGroups.push(followUpUserGroup);
      });
    })
    .catch(error => {
      this.loadingData = false;
      console.error(error);
    });
  }

  getFollowUpUserGroupTypes(userGroupId: any) {
    this.followUpUserGroupTypes = [];
    this.resubmissionActivityArray = [];
    this.externaldatasourceService.executeExternalDataSource(91, [userGroupId])
    .then(result => {
/*       console.log('getFollowUpUserGroupTypes result', result); */
      result.sort((a, b) => a['order'] < b['order'] ? -1 : 1);
      result.forEach(followUpUserGroupType => {
        followUpUserGroupType.followupType.defaultName =
          this.commonService.getTranslationValueFromArray(followUpUserGroupType.followupType);
        if (!isNullOrUndefined(followUpUserGroupType.isActive)) {
          this.resubmissionActivityArray[followUpUserGroupType.id] = JSON.parse(followUpUserGroupType.isActive);
        }
        this.followUpUserGroupTypes.push(followUpUserGroupType);
      });
    })
    .catch(error => {
      console.error('getFollowUpUserGroupTypes', error);
    });
  }

  updateUserGroupType(type) {
    setTimeout(() => {
      this.externaldatasourceService.executeExternalDataSource(830, [
        this.commonService.getModifyArrayBody(type, ['followupType'])
      ]);
    }, 200);

  }

  updateFollowUpUserGroupType(): Promise<any> {
    return new Promise((updateFollowUpUserGroupTypeResolve, updateFollowUpUserGroupTypeReject) => {
      const promiseArray: Promise<any>[] = [];
      this.followUpUserGroupTypes.forEach((followUpUserGroupType, index) => {
        let settings = null;
        if (followUpUserGroupType.settings != null) {
          settings = '"' + this.commonService.cleanStringValue(followUpUserGroupType.settings) + '"';
        }
        promiseArray.push(
        this.externaldatasourceService.executeExternalDataSource(90, [followUpUserGroupType.id,
          index, this.resubmissionActivityArray[followUpUserGroupType.id], settings]));
      });
      Promise.all(promiseArray)
      .then(() => {
     /*    console.log('updateFollowUpUserGroupType updated'); */
        updateFollowUpUserGroupTypeResolve(null);
      })
      .catch(error => {
        console.error(error);
        updateFollowUpUserGroupTypeReject(error);
      });
    });
  }


  // User assignment
  getAvailableUsers() {
    this.dragdropElements[0] = new DragDropelements();
    // this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.projectUsersDomainUsers)
    this.externaldatasourceService.executeExternalDataSource(14)
    .then(result => {
      result.forEach(projectUsers => {
        let dragDropItem: Object = [];
        dragDropItem = projectUsers;
        dragDropItem['userName'] = projectUsers.userName;
        dragDropItem['workID'] = projectUsers.id;
        dragDropItem['id'] = projectUsers.id;
        if (!this.dragdropService.checkIfItemExistsInOtherLists(0, dragDropItem, this.dragdropElements)) {
          this.dragdropService.pushItemToDragDropDicts(this.dragdropElements[0], dragDropItem);
        }
      });
    })
    .catch(error => { console.error(error); });
  }

  getAssignedUsers(): Promise<void> {
    return new Promise<void>(getAssignedUsersResolve => {
    this.dragdropElements[1] = new DragDropelements();
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
      this.graphqlqueryService.automaticRecordAssignmentFollowUpUserGroupUsers, [this.selectedRecordAssignment.id])
    .then(result => {
      result.data.followUpUserGroupUsers.forEach(followUpUserGroupUser => {
        let dragDropItem: Object = [];
        dragDropItem = followUpUserGroupUser;
        dragDropItem['userName'] = followUpUserGroupUser['user'].userName;
        dragDropItem['workID'] = followUpUserGroupUser.id;
        dragDropItem['id'] = followUpUserGroupUser['user'].id;
        this.dragdropService.pushItemToDragDropDicts(this.dragdropElements[1], dragDropItem);
        });
        getAssignedUsersResolve();
      });
    });
  }

  addUser(user) {
/*     console.log(user) */
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Mutation,
    this.graphqlqueryService.automaticRecordAssignmentCreateFollowUpUserGroupUserMutation, [
      this.selectedRecordAssignment.id,
      user.id]
    )
    .then(() => {
      this.getAssignedUsers().then(()=> {
        this.getAvailableUsers();
      });
    });
  }

  removeUser(user) {
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Mutation,
    this.graphqlqueryService.automaticRecordAssignmentDeleteFollowUpUserGroupUsersMutation, [user.workID])
    .then(() => {
      this.getAssignedUsers().then(()=> {
        this.getAvailableUsers();
      });
    });
  }

  setControlStyle(type: string, modifier: number = 0) {
    switch (type) {
      case 'dragpanel':
        if (this.applicationInfoService.isMobile()) {
          return {
            'width': '50%',
          };
        } else {
          return {
            'width': '50%',
        };
      }
    }
  }

  newGroup() {
    this.createNewGroup = true;
    this.componentMode = 2;
  }

  cancelCreationOfNewUserGroup() {
    this.componentMode = 1;
  }

  createNewUserGroup(): Promise<any> {
    return new Promise((createNewUserGroupResolve, createNewUserGroupReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
      this.graphqlqueryService.automaticRecordAssignmentCreateFollowUpUserGroupMutation, [this.newUserGroupName])
      .then(automaticRecordAssignmentCreateFollowUpUserGroupMutationResult => {
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
          this.axivasTranslateService.getTranslationTextForToken('AutomaticRecordAssignment.Label.CreateNewUserGroupSuccess'), false);
        this.getFollowUpUserGroups();
        // this.currentFollowUpUserGroup =
        //   automaticRecordAssignmentCreateFollowUpUserGroupMutationResult.data.createFollowUpUserGroupMutation.id;
        // this.loadUserGroupData(this.currentFollowUpUserGroup);
        this.componentMode = 1;
        this.createNewGroup = false;
        this.selectedRecordAssignment = null;
        createNewUserGroupResolve(null);
      })
      .catch(error => { createNewUserGroupReject(error); });
    });
  }

  editDataFilter() {
    this.datafilterService.setFilterEnvironment(
      this.dataFilter,
      this.applicationInfoService.accountEntityId,
      this.dataFilterCaller,
      null,
      11
    );
    this.eventService.showJjPopup('DataFilterMainPanel.Label.SectionHeader', 'datafiltermainpanel', '80', true);
  }

  getSelecteEntityMember(entityId) {
    let returnValue = [];
    if (entityId != null) {
      const entity = this.applicationInfoService.entities.Item(entityId);
      if (entity) {
        returnValue = entity.entityMembers;
      }
    }
    return returnValue;
  }

  deletePriority(priority: any) {
    this.deleteFromPriorityList(priority);
  }

  getProjectEntities(): Promise<any> {
    return new Promise<void>((getProjectEntitiesResolve, getProjectEntitiesReject) => {
      this.priorityEntities = [];
      this.datafilterService.getRelatedEntites(this.applicationInfoService.accountEntityId)
      .then(result => {
        result.data.entitySubRelationships.forEach((entitySubRelationship, entitySubRelationshipIndex) => {
          if (entitySubRelationshipIndex === 0) {
            this.priorityEntities.push(entitySubRelationship.entity);
          }
          this.priorityEntities.push(entitySubRelationship.otherEntity);
        });
        getProjectEntitiesResolve(result);
      })
      .catch(error => getProjectEntitiesReject(error));
    });
  }

  addToPriorityList(priority) {
    this.followUpUserGroupPriorities.push(priority);
  }

  deleteFromPriorityList(priorityToDelete) {
    this.followUpUserGroupPriorities.forEach((priority, index) => {
      if (priority.id === priorityToDelete.id) {
        this.followUpUserGroupPriorities.splice(index, 1);
      }
    });
  }

  enrichType() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(837).then(followupTypes => {
      const promiseArray: Promise<any>[] = [];
      let order = this.followUpUserGroupTypes.length + 1;
      followupTypes.forEach(type => {
        const foundType = this.followUpUserGroupTypes.find(followUpUserGroupType => followUpUserGroupType.followupTypeId == type.id);
        if (!foundType) {
          // console.log('Not found', this.followUpUserGroupTypes, type.id, foundType);
          promiseArray.push(this.externaldatasourceService.executeExternalDataSource(838, [
            type.id,
            this.selectedGroup.id,
            order,
            false
          ]));
          order ++;
        }
      });
      Promise.all(promiseArray).then(() => {
        this.loaderService.display(false);
        this.getFollowUpUserGroupTypes(this.selectedGroup.id);
      }).catch(() => {
        this.loaderService.display(false);
      });
    }).catch (() => { this.loaderService.display(false); });
  }

  refreshCache(followUpUserGroup) {
    this.externaldatasourceService.executeExternalDataSource(848, [followUpUserGroup.id]);
  }

  getDataFilterObject(filterId) {
    this.externaldatasourceService.executeExternalDataSource(596, [filterId]).then(getDataFilterObjectResult => {
      this.dataFilterObject = getDataFilterObjectResult;
    });
  }
}
