import { Component , OnInit, HostListener, Injector  } from '@angular/core';
import { AuthService } from './core/authentication/services/auth.service';
import { LoaderService } from './shared/service/loader-service';
import { Router } from '@angular/router';
import { MasterPageService, PageType } from './shared/service/masterpage.service';
import { Subscription, timer } from 'rxjs';
import { environment } from '../environments/environment';
import { createCustomElement } from '@angular/elements';

// Elements
import { CustomcontainerComponent } from './jollyjupiter/controls/custom/custom-container/custom-container.component';
import { CustomUniversalComponent } from './jollyjupiter/controls/custom/custom-universal/custom-universal.component';
import { EventService } from './jollyjupiter/service/event.service';
import { CustomDragdropComponent } from './jollyjupiter/controls/custom/custom-dragdrop/custom-dragdrop.component';
import { ProjectusersComponent } from './jollyjupiter/controls/static/projectusers/projectusers.component';
import { CustomDropdownComponent } from './jollyjupiter/controls/custom/custom-dropdown/custom-dropdown.component';
import { CustomRadioComponent } from './jollyjupiter/controls/custom/custom-radio/custom-radio.component';

import { ApplicationInfoService } from './core/application/application-info.service';
import { ProjectselectionComponent } from './jollyjupiter/controls/static/projectselection/projectselection.component';
import { UsersettingsComponent } from './jollyjupiter/usersettings/usersettings.component';
import { AccountComponent } from './jollyjupiter/controls/static/account/account.component';
import { CustomTextareaComponent } from './jollyjupiter/controls/custom/custom-textarea/custom-textarea.component';
import { Location, registerLocaleData } from '@angular/common';

// Locale
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { BrakepopupComponent } from './jollyjupiter/controls/static/brakepopup/brakepopup.component';
import { CustomerManagementComponent } from './jollyjupiter/controls/static/customer-management/customer-management.component';
import { AutomaticRecordAssignmentComponent } from './jollyjupiter/controls/static/automatic-record-assignment/automatic-record-assignment.component';
import { NonDatabaseProjectDashboardComponent } from './jollyjupiter/controls/static/non-database-project-dashboard/non-database-project-dashboard.component';
import { ProjectManagementComponent } from './jollyjupiter/controls/static/developer/project-management/project-management.component';
import { DatafilterMainpanelComponent } from './jollyjupiter/controls/static/datafilter/datafilter-mainpanel/datafilter-mainpanel.component';
import { TranslateTokenPopupComponent } from './jollyjupiter/controls/static/supervisor/translate-token-popup/translate-token-popup.component';
import { ExportFormComponent } from './jollyjupiter/controls/static/export-form/export-form.component';
import { CustomQuestionnaireComponent } from './jollyjupiter/controls/custom/custom-questionnaire/custom-questionnaire.component';
import { MyleadsComponent } from './jollyjupiter/controls/static/myleads/myleads.component';
import { LeadreportComponent } from './jollyjupiter/controls/static/leadreport/leadreport.component';
import { CustomMenubarComponent } from './jollyjupiter/controls/custom/custom-menubar/custom-menubar.component';
import { NoTaskAvailableComponent } from './jollyjupiter/controls/static/no-task-available/no-task-available.component';
import { CustomSubresultComponent } from './jollyjupiter/controls/custom/custom-subresult/custom-subresult.component';
import { CreateTaskMainComponent } from './jollyjupiter/controls/static/task-creation/create-task-main/create-task-main.component';
import { OptinoptoutComponent } from './jollyjupiter/controls/static/optinoptout/optinoptout.component';
import { AppointmentViewComponent } from './jollyjupiter/controls/static/appointment-view/appointment-view.component';
import { SupervisorMainComponent } from './jollyjupiter/controls/static/supervisor/supervisor-main/supervisor-main.component';
import { ReportingComponent } from './jollyjupiter/controls/static/reporting/reporting.component';
import { UsergroupsComponent } from './jollyjupiter/controls/static/supervisor/usergroups/usergroups.component';
import { QuicksearchComponent } from './jollyjupiter/controls/static/quicksearch/quicksearch.component';
import { DesignTranslationComponent } from './jollyjupiter/controls/designer/design-translation/design-translation.component';
import { DeveloperMainpanelComponent } from './jollyjupiter/controls/static/developer/developer-mainpanel/developer-mainpanel.component';
import { LeadstateComponent } from './jollyjupiter/controls/static/supervisor/leadstate/leadstate.component';
import { ProcessComponent } from './jollyjupiter/controls/static/supervisor/process/process.component';
import { AccountPopupComponent } from './jollyjupiter/controls/static/account-popup/account-popup.component';
import { CampaignComponent } from './jollyjupiter/controls/static/supervisor/campaign/campaign.component';
import { AdminMainComponent } from './jollyjupiter/controls/admin/admin-main/admin-main.component';
import { QuestionanswersComponent } from './jollyjupiter/controls/static/supervisor/questionanswers/questionanswers.component';
import { UicontrolDesignComponent } from './jollyjupiter/controls/designer/controls/uicontrol-design/uicontrol-design.component';
import { AdminEntityComponent } from './jollyjupiter/controls/admin/admin-entity/admin-entity.component';
import { UserRightsComponent } from './jollyjupiter/controls/admin/user-rights/user-rights.component';
import { ImportMainComponent } from './jollyjupiter/controls/static/supervisor/import/import-main/import-main.component';
import { ImportHelptableViewComponent } from './jollyjupiter/controls/static/supervisor/import/import-helptable-view/import-helptable-view.component';
import { NewLogicalEntityComponent } from './jollyjupiter/controls/static/myleads/new-logical-entity/new-logical-entity.component';
import { NewaccountComponent } from './jollyjupiter/controls/static/newaccount/newaccount.component';
import { UicontainerDesignComponent } from './jollyjupiter/controls/designer/controls/uicontainer-design/uicontainer-design.component';
import { ControlsDesignMainComponent } from './jollyjupiter/controls/designer/controls/controls-design-main/controls-design-main.component';
import { ControlsDesignLookupTableComponent } from './jollyjupiter/controls/designer/controls/controls-design-lookup-table/controls-design-lookup-table.component';
import { UiService } from './jollyjupiter/service/ui.service';
import { AdminQueryBuilderComponent } from './jollyjupiter/controls/admin/admin-reports/admin-query-builder/admin-query-builder.component';
import { AttributeModifierOverviewComponent } from './jollyjupiter/controls/static/supervisor/attribute-modifier-overview/attribute-modifier-overview.component';
import { LeadreportsettingsComponent } from './jollyjupiter/controls/static/supervisor/leadreportsettings/leadreportsettings.component';
import { GeneralsettingsComponent } from './jollyjupiter/controls/static/supervisor/generalsettings/generalsettings.component';
import { NewtaskComponent } from './jollyjupiter/controls/static/newtask/newtask.component';
import { CustomCopytoclipboardButtonComponent } from './jollyjupiter/controls/custom/custom-copytoclipboard-button/custom-copytoclipboard-button.component';
import { AccountFooterComponent } from './jollyjupiter/controls/static/account-footer/account-footer.component';
import { ProspectComponent } from './jollyjupiter/controls/static/prospect/prospect.component';
import { ProspectDetailComponent } from './jollyjupiter/controls/static/prospect/prospect-detail.component';
import { ContactsComponent } from './jollyjupiter/controls/static/contacts/contacts.component';
import { AccountsComponent } from './jollyjupiter/controls/static/accounts/accounts.component';
import { ConversionComponent } from './jollyjupiter/controls/static/conversion/conversion.component';
import { MyFollowupsComponent } from './jollyjupiter/controls/static/my-followups/my-followups.component';
import { OpportunityComponent } from './jollyjupiter/controls/static/opportunity/opportunity.component';
import { AuditComponent } from './jollyjupiter/controls/static/supervisor/audit/audit.component';
import { AxivasTranslateService } from './shared/translation/axivas-translate.service';
import { CommonService } from './jollyjupiter/service/common.service';
import { ConversionListComponent } from './jollyjupiter/controls/static/conversion-list/conversion-list.component';
import { LeadscoringDetailComponent } from './jollyjupiter/controls/static/leadscoring-detail/leadscoring-detail.component';
import { AwsCognitoService } from './cognito/aws-cognito.service';
import { ApplicationinitService } from './jollyjupiter/service/applicationinit.service';
import { ContainerViewComponent } from './jollyjupiter/controls/static/container-view/container-view.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomActivityListComponent } from './jollyjupiter/controls/custom/custom-activity-list/custom-activity-list.component';
import { CustomTimelineComponent } from './jollyjupiter/controls/custom/custom-timeline/custom-timeline.component';
import { LeadscoringDetailPotentialComponent } from './jollyjupiter/controls/static/leadscoring-detail/leadscoring-detail-potential/leadscoring-detail-potential.component';
import { CustomMicroCampaignsComponent } from './jollyjupiter/controls/custom/custom-micro-campaigns/custom-micro-campaigns.component';
import { RingcentralPopupComponent } from './jollyjupiter/controls/static/ringcentral-popup/ringcentral-popup.component';
import { BoldbiDashboardlistComponent } from './jollyjupiter/boldbi/boldbi-dashboardlist/boldbi-dashboardlist.component';
import { BlacklistComponent } from './jollyjupiter/controls/static/blacklist/blacklist.component';
import { InternalMethodType, MethodService } from './jollyjupiter/service/method.service';
import { ExternalusersComponent } from './jollyjupiter/controls/static/supervisor/externalusers/externalusers.component';
import { CustomEmaileditorComponent } from './jollyjupiter/controls/custom/custom-emaileditor/custom-emaileditor.component';
import { BookingComponent } from './jollyjupiter/controls/static/booking/booking.component';
import { ProjectService } from './jollyjupiter/service/project.service';
import * as moment from 'moment';
import { CompactTasklistComponent } from './jollyjupiter/controls/static/compact-tasklist/compact-tasklist.component';
import { EventtriggerComponent } from './jollyjupiter/controls/static/supervisor/eventtrigger/eventtrigger.component';
import { CustomEmailComponent } from './jollyjupiter/controls/custom/custom-email/custom-email.component';
import { CustomProjectoverviewComponent } from './jollyjupiter/controls/custom/custom-projectoverview/custom-projectoverview.component';
import { TrackingService } from './jollyjupiter/service/tracking.service';
import { AdminEntityValidityComponent } from './jollyjupiter/controls/admin/admin-entity/admin-entity-validity/admin-entity-validity.component';
import { ConversioneditorComponent } from './jollyjupiter/controls/static/conversioneditor/conversioneditor.component';
import { EmailreviewComponent } from './jollyjupiter/controls/static/emailreview/emailreview.component';
import { VipComponent } from './jollyjupiter/controls/static/vip/vip.component';
import { NewcontactComponent } from './jollyjupiter/controls/static/newcontact/newcontact.component';
import { DatarightsComponent } from './jollyjupiter/controls/static/datarights/datarights.component';
import { SettingsService } from './shared/service/settings.service';
import { UserService } from './core/authentication/services/user.service';
import { DatarightsInfoComponent } from './jollyjupiter/controls/static/datarights/datarights-info/datarights-info.component';
import { WizardComponent } from './jollyjupiter/controls/static/wizard/wizard.component';
import { MatTableSupervisorComponent } from './jollyjupiter/controls/custom/custom-mattable/mat-table-supervisor/mat-table-supervisor.component';
import { EmailbatchdetailsComponent } from './jollyjupiter/controls/static/emailbatchdetails/emailbatchdetails.component';
import { EmaileditorSyncComponent } from './jollyjupiter/controls/static/emaileditor-sync/emaileditor-sync.component';
import { MailshotsComponent } from './jollyjupiter/controls/static/mailshots/mailshots.component';
import { UsermailboxComponent } from './jollyjupiter/controls/static/usermailbox/usermailbox.component';
import { SmtpsettingsComponent } from './jollyjupiter/controls/static/supervisor/smtpsettings/smtpsettings.component';
import { PoolsComponent } from './jollyjupiter/controls/static/supervisor/pools/pools.component';
import { EmailcontentComponent } from './jollyjupiter/controls/static/supervisor/emailcontent/emailcontent.component';
import { EmailtemplatesComponent } from './jollyjupiter/controls/static/supervisor/emailtemplates/emailtemplates.component';
import { UsermanagementComponent } from './jollyjupiter/controls/static/usermanagement/usermanagement.component';
import { UserdashboardComponent } from './jollyjupiter/controls/static/userdashboard/userdashboard.component';
import { TelekomcreatetakeComponent } from './jollyjupiter/controls/projectspecific/telekomcreatetake/telekomcreatetake.component';
import { ProcessvisualComponent } from './jollyjupiter/controls/static/processvisual/processvisual.component';
import { EntitytableDesignerComponent } from './jollyjupiter/controls/custom/entitytable/entitytable-designer/entitytable-designer.component';
import { EventlistComponent } from './jollyjupiter/controls/static/supervisor/eventlist/eventlist.component';
import { CustomDashoardcontrolComponent } from './jollyjupiter/controls/custom/custom-dashoardcontrol/custom-dashoardcontrol.component';
import { CustomAipromptresultComponent } from './jollyjupiter/controls/custom/custom-aipromptresult/custom-aipromptresult.component';
import { RingcentralwebphoneService } from './jollyjupiter/service/ringcentralwebphone.service';
import { AccountGroupComponent } from './jollyjupiter/controls/static/account-group/account-group.component';
import { CallednumberhistoryComponent } from './jollyjupiter/controls/static/callednumberhistory/callednumberhistory.component';
import { VoicescriptcontrolComponent } from './jollyjupiter/controls/static/voicescriptcontrol/voicescriptcontrol.component';
import { MessagePosition, MessageSeverity, MessagingService } from './jollyjupiter/service/messaging.service';
import { NavigationdesignComponent } from './jollyjupiter/controls/static/navigationdesign/navigationdesign.component';
import { CustomMicrocampaignCancelComponent } from './jollyjupiter/controls/custom/custom-micro-campaigns/custom-microcampaign-cancel/custom-microcampaign-cancel.component';
import { CreatedemoaccountComponent } from './jollyjupiter/controls/static/createdemoaccount/createdemoaccount.component';
import { CloudconnectionLoginComponent } from './jollyjupiter/controls/static/cloudconnections/cloudconnection-login/cloudconnection-login.component';
import { MicrocampaignbatchstarterComponent } from './jollyjupiter/controls/static/microcampaignbatchstarter/microcampaignbatchstarter.component';
import { ProcessvisualPopupComponent } from './jollyjupiter/controls/static/processvisual-popup/processvisual-popup.component';
import { OptinoptouteditorComponent } from './jollyjupiter/controls/static/optinoptouteditor/optinoptouteditor.component';
import { QuestionaireDesignerControlComponent } from './jollyjupiter/controls/static/questionaire-control/questionaire-designer-control/questionaire-designer-control.component';
import { ExpandflowchartComponent } from './jollyjupiter/controls/dashboards/charts/flowchart/expandflowchart/expandflowchart.component';
import { AdminJobCheckResultComponent } from './jollyjupiter/controls/admin/admin-jobs/admin-job-check-result/admin-job-check-result.component';
import { CustomPaletteEditorComponent } from './jollyjupiter/controls/custom/custom-palette/custom-palette-editor/custom-palette-editor.component';
import { ControleditLightComponent } from './jollyjupiter/controls/designer/controledit-light/controledit-light.component';
import { CustomContactleadstateoverviewDetailsComponent } from './jollyjupiter/controls/custom/custom-contactleadstateoverview/custom-contactleadstateoverview-details/custom-contactleadstateoverview-details.component';
import { FollowupsComponent } from './jollyjupiter/controls/static/followups/followups.component';
import { FeaturesComponent } from './jollyjupiter/controls/static/features/features.component';
import { ContactProtectionPopupComponent } from './jollyjupiter/controls/static/contact-protection/contact-protection-popup/contact-protection-popup.component';
import { NavigationShortcutsComponent } from './shared/navigation/navigation-shortcuts/navigation-shortcuts.component';
import { ReportingSignalrService } from './jollyjupiter/signalr/reporting-signalr.service';
import { ExternaldatasourceService } from './jollyjupiter/service/externaldatasource.service';
import { HttpClient } from '@angular/common/http';
import { AdminNewentitymemberComponent } from './jollyjupiter/controls/admin/admin-entity/admin-newentitymember/admin-newentitymember.component';
import { LookuptablesComponent } from './jollyjupiter/controls/static/lookuptables/lookuptables.component';
import { UsermanagementuserdetailsComponent } from './jollyjupiter/controls/static/usermanagement/usermanagementuserdetails/usermanagementuserdetails.component';
import { LicensingAdduserComponent } from './jollyjupiter/controls/static/licensing/licensing-adduser/licensing-adduser.component';
import { LicensingDeactivateUserComponent } from './jollyjupiter/controls/static/licensing/licensing-deactivate-user/licensing-deactivate-user.component';
import { EntitytableFilterComponent } from './jollyjupiter/controls/custom/entitytable/entitytable-filter/entitytable-filter.component';
import { EchobotInterfaceComponent } from './jollyjupiter/controls/static/echobot-interface/echobot-interface.component';
import { QualificationComponent } from './jollyjupiter/controls/static/qualification/qualification.component';
import { PackagefeatureService } from './jollyjupiter/service/packagefeature.service';
import { ExpandcampaignflowComponent } from './jollyjupiter/controls/dashboards/charts/designer-campaign-flowchart/expandcampaignflow/expandcampaignflow.component';
import { AdminAipromptsComponent } from './jollyjupiter/controls/admin/admin-aiprompts/admin-aiprompts.component';
import { EverleadSupportComponent } from './jollyjupiter/controls/static/everlead-support/everlead-support.component';
import { AdminSettingsPopupComponent } from './jollyjupiter/controls/admin/admin-application-settings/admin-settings-popup/admin-settings-popup.component';
import { UsertokenDistributionComponent } from './jollyjupiter/controls/static/usertoken-distribution/usertoken-distribution.component';
import { OrderdlcComponent } from './jollyjupiter/controls/static/licensing/orderdlc/orderdlc.component';
import { AwslogooutComponent } from './jollyjupiter/controls/static/awslogoout/awslogoout.component';
import { EchobotInterfaceConfirmComponent } from './jollyjupiter/controls/static/echobot-interface/echobot-interface-confirm/echobot-interface-confirm.component';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public innerHTML = null;
  title = 'app';
  loggedIn: Boolean;
  isDebug = false;
  showLoader: boolean;
  searchActive = false;
  loaderServiceSubscription: Subscription = new Subscription();
  accountLockSubscription: Subscription = new Subscription();
  reportTimerSubscription: Subscription = new Subscription();
  lastLocation = '';
  contentMaxHeight = 22000;
  featurePhoneNotFound = null;
  showPyro = false;

  @HostListener('window:resize', ['$event'])
  @HostListener("window:beforeunload", ["$event"])

  unloadHandler(event) {
    if(this.ringcentralwebphoneService.established) {
      event.preventDefault();
      return event.returnValue = 'Call running';
    } else {
      return;
    }
  }

  onResize() {
    this.applicationInfoService.innerHeight = document.documentElement.clientHeight; // window.innerHeight;
    this.applicationInfoService.innerWidth = document.documentElement.clientWidth; // window.innerWidth;
    setTimeout(() => this.applicationInfoService.resetHeaderCardsPosition(), 0);
    this.eventService.customEvent.emit({ id: 'applicationPageResized' });
  }

  constructor(private authService: AuthService
            , private loaderService: LoaderService
            , private router: Router
            , private uiService: UiService
            , private masterPageService: MasterPageService
            , private injector: Injector
            , private methodService: MethodService
            , private eventService: EventService
            , public axivasTranslateService: AxivasTranslateService
            , public commonService: CommonService
            , private awsCognitoService: AwsCognitoService
            , public applicationInfoService: ApplicationInfoService
            , private applicationinitService: ApplicationinitService
            , private location: Location
            , private sanitizer: DomSanitizer
            , private projectService: ProjectService
            , private trackingService: TrackingService
            , private settingsService: SettingsService
            , public userService: UserService
            , private ringcentralwebphoneService: RingcentralwebphoneService
            , private messagingService: MessagingService
            , private reportingSignalrService: ReportingSignalrService
            , private externaldatasourceService: ExternaldatasourceService
            , private http: HttpClient
            , private packagefeatureService: PackagefeatureService
            ) {
  }



  ngOnInit() {    
    let initHref = window.location.href;
    initHref = initHref.replace(window.location.origin, '');
    let storedInitHref = localStorage.getItem('initHref');
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    localStorage.setItem('locationStartInfo', JSON.stringify({
      pid: urlParams.get('pid'),
      aid: urlParams.get('aid'),
      cid: urlParams.get('cid'),
      clid: urlParams.get('clid'),
      cliid: urlParams.get('cliid')
    }));
    
    // this.reportingSignalrService.initService();
    
    this.http.get(environment.externalIPCheckLink, {responseType: 'text'}).subscribe(externalIPCheckLinkResult => {
      this.applicationInfoService.clientIP = externalIPCheckLinkResult;
    });


    let localVideoElement = null;
    localVideoElement = document.getElementById("localVideo");
    if (localVideoElement) {
      localVideoElement.volume = 0;
    }

    this.eventService.navigateToContent.subscribe(event => {
      if (event == 'account') {
        const accountId = this.commonService.getSessionStorageItem('CurrentAccount');
        const contactId = this.commonService.getSessionStorageItem('CurrentContact');
        const taskId = this.commonService.getSessionStorageItem('CurrentTask');
        if (!this.commonService.isNullOrUndefined(taskId)) {
          this.methodService.launchInternalMethod(InternalMethodType.ShowTaskByTaskId, [taskId])
          .then(() => {
            this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, [event, event]);
          })
        } else {
          if (!this.commonService.isNullOrUndefined(accountId)) {
            this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [accountId]).then(() => {
              if (!this.commonService.isNullOrUndefined(contactId)) {
                this.methodService.launchInternalMethod(InternalMethodType.LoadContact, [contactId]).then(() => {
                  this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, [event, event]);
                });
              } else {
                this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, [event, event]);
              }
            })
          } else {

          }
        }
      } else {
        this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, [event, event]);
      }
    });

    this.eventService.navigateToContainerId.subscribe(event => {
      this.methodService.launchInternalMethod(InternalMethodType.ChangePageContentToContainer, [event]);
    });

    this.eventService.initPhoneSystemEvent.subscribe(() => {
      this.applicationinitService.initSoftphone();
    });
    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'ShowNewVersionInfo') {
        this.messagingService.showDefaultInfo('NewVersionAvailable.Label.Header', 'NewVersionAvailable.Label.Description', true, { 
          type: 'newversion',
          closeButton: false
        });
      }

      if (event.id == 'showPyro') {        
        let pyroTime = 4000;
        if (!this.commonService.isNullOrUndefined(event.value)) {
          pyroTime = Number(event.value)
        }
        if (this.applicationInfoService.applicationSettings['showPyro'] == 'true') {          
          this.showPyro = true;
          setTimeout(() => {
            this.showPyro = false;
          }, pyroTime);  
        }
      }

      if (event.id == 'startReportTimer') {
        this.startReportTimer();
      }
      if (event == 'getCompleteProjectInformaton') {
        this.getPhoneFeature();
      }
    });

    
    this.initElements();
    registerLocaleData(localeDE, 'de-DE');
    registerLocaleData(localeEN, 'en-GB');

    if (environment.testEnvironment == 'true') {
      this.applicationInfoService.testEnvironment = true;
    }

    /* Browser check*/
    if (navigator.userAgent.indexOf('Edge') !== -1) {
      this.router.navigate(['notSupported']);
    }
    this.masterPageService.checkPageType();
    const token = localStorage.getItem('awsrefreshtoken');
    if (token === null) {
      this.noTokenFound(initHref, storedInitHref);
    } else {
      this.tokenFound();
    }

    this.loaderServiceSubscription = this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    this.applicationInfoService.innerHeight = window.innerHeight;
    this.applicationInfoService.innerWidth = window.innerWidth;
    this.isDebug = environment.isDebug;

    // Initializing the core application
    this.startLockAccountTimer();
    this.checkReport();
  }

  tokenFound() {
    this.awsCognitoService.silentRenew().subscribe(result => {
      this.awsCognitoService.setTokenDetails(result, true);
      this.applicationinitService.startApplication();
    }, error => {
      this.awsCognitoService.handleLoginError();
    });
  }

  noTokenFound(initHref, storedInitHref) {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const code: string = urlParams.get('code');
    if (code) {
      this.awsCognitoService.getTokenDetailsFromCognito(code)
      .subscribe(result => {
        this.awsCognitoService.setTokenDetails(result);
        if (!this.commonService.isNullOrUndefined(storedInitHref)) {
          localStorage.removeItem('initHref');
          this.location.replaceState(storedInitHref);
          const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
          localStorage.setItem('locationStartInfo', JSON.stringify({
          pid: urlParams.get('pid'),
          aid: urlParams.get('aid'),
          cid: urlParams.get('cid'),
          clid: urlParams.get('clid'),
          cliid: urlParams.get('cliid')
        }));
      }
      this.applicationinitService.startApplication();
    }, error => {
      this.awsCognitoService.handleLoginError();
     });
    } else {
      localStorage.setItem('initHref', initHref);
      this.applicationInfoService.showAWsLoginPage = true;

      // this.awsCognitoService.showAwsLoginPage();
    }
  }

  initElements() {
        // AElements
        this.addCustomElement(CustomcontainerComponent, 'jj-container');
        this.addCustomElement(CustomUniversalComponent, 'jj-universal');
        this.addCustomElement(CustomDragdropComponent, 'jj-dragdrop');
        this.addCustomElement(CustomDropdownComponent, 'jj-dropdown');
        this.addCustomElement(CustomRadioComponent, 'jj-radio');
        this.addCustomElement(ProjectusersComponent, 'jj-projectusers');
        this.addCustomElement(ProjectselectionComponent, 'jj-projectselection');
        this.addCustomElement(UsersettingsComponent, 'jj-usersettings');
        this.addCustomElement(ProspectComponent, 'jj-prospects');
        this.addCustomElement(ProspectDetailComponent, 'jj-prospectsdetail');
        this.addCustomElement(CustomTextareaComponent, 'jj-textarea');
        this.addCustomElement(AutomaticRecordAssignmentComponent, 'jj-automaticrecordassignment');
        this.addCustomElement(AccountComponent, 'jj-account');
        this.addCustomElement(BlacklistComponent, 'jj-blacklist');
        this.addCustomElement(BrakepopupComponent, 'jj-brakepopup');
        this.addCustomElement(CustomerManagementComponent, 'jj-customermanagement');
        this.addCustomElement(ProjectManagementComponent, 'jj-developerprojectmanagement');
        this.addCustomElement(DatafilterMainpanelComponent, 'jj-datafiltermainpanel');
        this.addCustomElement(ExportFormComponent, 'jj-exportform');
        this.addCustomElement(CustomQuestionnaireComponent, 'jj-questionnaire');
        this.addCustomElement(MyleadsComponent, 'jj-myleads');
        this.addCustomElement(LeadreportComponent, 'jj-leadreport');
        this.addCustomElement(CustomMenubarComponent, 'jj-menubar');
        this.addCustomElement(NoTaskAvailableComponent, 'jj-notaskavailable');
        this.addCustomElement(CustomSubresultComponent, 'jj-subresult');
        this.addCustomElement(CreateTaskMainComponent, 'jj-createtaskmain');
        this.addCustomElement(OptinoptoutComponent, 'jj-optinoptout');
        this.addCustomElement(AppointmentViewComponent, 'jj-appointment-view');
        this.addCustomElement(SupervisorMainComponent, 'jj-supervisor-main');
        this.addCustomElement(ReportingComponent, 'jj-reporting');
        this.addCustomElement(UserdashboardComponent, 'jj-userdashboard');
        this.addCustomElement(UsergroupsComponent, 'jj-usergroups');
        this.addCustomElement(QuicksearchComponent, 'jj-quicksearch');
        this.addCustomElement(DesignTranslationComponent, 'jj-designtranslation');
        this.addCustomElement(DeveloperMainpanelComponent, 'jj-developermain');
        this.addCustomElement(LeadstateComponent, 'jj-leadstate');
        this.addCustomElement(CustomDashoardcontrolComponent, 'jj-customdashboard');
        this.addCustomElement(ProcessComponent, 'jj-process');
        this.addCustomElement(AccountPopupComponent, 'jj-account-popup');
        this.addCustomElement(TranslateTokenPopupComponent, 'jj-translation-token-popup');
        this.addCustomElement(CampaignComponent, 'jj-campaign');
        this.addCustomElement(AdminMainComponent, 'jj-admin-main');
        this.addCustomElement(QuestionanswersComponent, 'jj-questionanswers');
        this.addCustomElement(UicontrolDesignComponent, 'jj-uicontrol-design');
        this.addCustomElement(UicontainerDesignComponent, 'jj-uicontainer-design');
        this.addCustomElement(AdminEntityComponent, 'jj-admin-entity');
        this.addCustomElement(UserRightsComponent, 'jj-user-rights');
        this.addCustomElement(NonDatabaseProjectDashboardComponent, 'jj-non-database-project-dashboard');
        this.addCustomElement(ImportMainComponent, 'jj-import-main');
        this.addCustomElement(ImportHelptableViewComponent, 'jj-import-helptable-view');
        this.addCustomElement(NewLogicalEntityComponent, 'jj-new-logical-entity');
        this.addCustomElement(NewaccountComponent, 'jj-newaccount');
        this.addCustomElement(ControlsDesignMainComponent, 'jj-uicontrol-design-main');
        this.addCustomElement(ControlsDesignLookupTableComponent, 'jj-uicontrol-design-lookuptable');
        this.addCustomElement(AdminQueryBuilderComponent, 'jj-admin-query-builder');
        this.addCustomElement(AttributeModifierOverviewComponent, 'jj-attribute-modifier-overview');
        this.addCustomElement(CustomProjectoverviewComponent, 'jj-project-overview');
        this.addCustomElement(LeadreportsettingsComponent, 'jj-leadreportsettings');
        this.addCustomElement(GeneralsettingsComponent,'jj-generalsettings');
        this.addCustomElement(NewtaskComponent, 'jj-newtask');
        this.addCustomElement(ProspectDetailComponent, 'jj-prospectdetail');
        this.addCustomElement(CustomCopytoclipboardButtonComponent, 'jj-copytoclipboard');
        this.addCustomElement(AccountFooterComponent, 'jj-account-footer');
        this.addCustomElement(ConversionComponent, 'jj-conversions');
        this.addCustomElement(NewcontactComponent, 'jj-newcontact');
        this.addCustomElement(ContactsComponent, 'jj-contacts');
        this.addCustomElement(AccountsComponent, 'jj-accounts');
        this.addCustomElement(MyFollowupsComponent, 'jj-myfollowups');
        this.addCustomElement(OpportunityComponent, 'jj-opportunity');
        this.addCustomElement(AuditComponent, 'jj-audit');
        this.addCustomElement(ConversionListComponent, 'jj-conversionlist');
        this.addCustomElement(LeadscoringDetailComponent, 'jj-leadscoringdetail');
        this.addCustomElement(LeadscoringDetailPotentialComponent, 'jj-leadscoringdetailpotential');
        this.addCustomElement(ContainerViewComponent, 'jj-containerview');
        this.addCustomElement(CustomActivityListComponent, 'jj-activity-list');
        this.addCustomElement(CustomTimelineComponent, 'jj-timeline');
        this.addCustomElement(CustomMicroCampaignsComponent, 'jj-micro-campaigns');
        this.addCustomElement(RingcentralPopupComponent, 'jj-ringcentralpopup');
        this.addCustomElement(BoldbiDashboardlistComponent, 'jj-dashboardlist');
        this.addCustomElement(CustomEmaileditorComponent, 'jj-maileditor');
        this.addCustomElement(ExternalusersComponent, 'jj-externalusers');
        this.addCustomElement(BookingComponent, 'jj-booking');
        this.addCustomElement(CompactTasklistComponent, 'jj-tasklistcomponent');
        this.addCustomElement(EventtriggerComponent, 'jj-eventtrigger');
        this.addCustomElement(CustomEmailComponent, 'jj-email');
        this.addCustomElement(EmailreviewComponent, 'jj-emailreview');
        this.addCustomElement(AdminEntityValidityComponent, 'jj-entity-validity');
        this.addCustomElement(ConversioneditorComponent, 'jj-conversion-editor');
        this.addCustomElement(VipComponent, 'jj-vip');
        this.addCustomElement(DatarightsComponent, 'jj-datarights');
        this.addCustomElement(WizardComponent, 'jj-wizard');
        this.addCustomElement(DatarightsInfoComponent, 'jj-datarightsinfo');
        this.addCustomElement(MatTableSupervisorComponent, 'jj-mattable-supervisor');
        this.addCustomElement(EmailbatchdetailsComponent, 'jj-emailbatchdetails');
        this.addCustomElement(EmaileditorSyncComponent, 'jj-emaileditorsync');
        this.addCustomElement(MailshotsComponent, 'jj-mailshots');
        this.addCustomElement(UsermailboxComponent, 'jj-mailbox');
        this.addCustomElement(PoolsComponent, 'jj-pools');
        this.addCustomElement(SmtpsettingsComponent, 'jj-smtpsettings');
        this.addCustomElement(ProcessvisualComponent, 'jj-processvisual');
        this.addCustomElement(EntitytableDesignerComponent, 'jj-entitytable-designer');
        this.addCustomElement(EntitytableFilterComponent, 'jj-entitytable-filter');
        this.addCustomElement(EventlistComponent, 'jj-eventlist');
        this.addCustomElement(CustomAipromptresultComponent, 'jj-aipromptresult');
        this.addCustomElement(AccountGroupComponent, 'jj-accountgroup');
        this.addCustomElement(CallednumberhistoryComponent, 'jj-callednumberhistory');
        this.addCustomElement(VoicescriptcontrolComponent, 'jj-voicescript');
        this.addCustomElement(NavigationdesignComponent, 'jj-navigationdesign');
        this.addCustomElement(CustomMicrocampaignCancelComponent, 'jj-microcampaigncancel');
        this.addCustomElement(CreatedemoaccountComponent, 'jj-createdemoaccount');
        this.addCustomElement(CloudconnectionLoginComponent, 'jj-cloudconnectionlogin');
        this.addCustomElement(OptinoptouteditorComponent, 'jj-optinoptouteditor');
        this.addCustomElement(QuestionaireDesignerControlComponent, 'jj-questionairedesigner');
        this.addCustomElement(AdminJobCheckResultComponent, 'jj-adminjobcheckresult');
        this.addCustomElement(CustomPaletteEditorComponent, 'jj-paletteeditor');
        this.addCustomElement(ControleditLightComponent, 'jj-controleditlight');
        this.addCustomElement(CustomContactleadstateoverviewDetailsComponent, 'jj-customcontactleadstatusdetails');
        this.addCustomElement(AdminNewentitymemberComponent, 'jj-newentitymember');        
        this.addCustomElement(LookuptablesComponent, 'jj-lookuptables');        
        this.addCustomElement(UsermanagementuserdetailsComponent, 'jj-usermanagementuserdetails');        
        this.addCustomElement(EchobotInterfaceComponent, 'jj-echobotinterface');
        this.addCustomElement(QualificationComponent, 'jj-qualification');
        this.addCustomElement(AdminAipromptsComponent, 'jj-adminaiprompt');
        this.addCustomElement(AwslogooutComponent, 'jj-awslogout');
        
        // !ivo
        this.addCustomElement(FollowupsComponent, 'jj-followups');
        this.addCustomElement(ContactProtectionPopupComponent, 'jj-contactprotectionpopup');

        this.addCustomElement(AdminSettingsPopupComponent,'jj-adminsettingspopup')
        this.addCustomElement(UsertokenDistributionComponent, 'jj-usertokendistribution')

        // confirmation
        this.addCustomElement(EchobotInterfaceConfirmComponent, 'jj-echobotconfirm')


        // New toolbar
        this.addCustomElement(EmailtemplatesComponent, 'jj-emailtemplate');
        this.addCustomElement(EmailcontentComponent, 'jj-emailcontent');
        this.addCustomElement(UsermanagementComponent, 'jj-usermanagement');
        this.addCustomElement(MicrocampaignbatchstarterComponent, 'jj-microcampaignbatchstarter');
        this.addCustomElement(ProcessvisualPopupComponent, 'jj-processvisualpopup')
        this.addCustomElement(ExpandflowchartComponent, 'jj-expandflowchartoverview')
        this.addCustomElement(ExpandcampaignflowComponent, 'jj-expandcampaignflowcomponent')

        // project specific
        this.addCustomElement(TelekomcreatetakeComponent, 'jj-telekomtake');

        // Features
        this.addCustomElement(FeaturesComponent, 'jj-features');        
        this.addCustomElement(NavigationShortcutsComponent, 'jj-navigationshortcuts');

        this.addCustomElement(LicensingAdduserComponent, 'jj-licenseadduser');
        this.addCustomElement(LicensingDeactivateUserComponent, 'jj-deaktivatesecurityuser');        
        this.addCustomElement(EverleadSupportComponent, 'jj-everleadsupport');                
        this.addCustomElement(OrderdlcComponent, 'jj-orderdlc');                
  }

  addCustomElement(control: any, controlname: string) {
    const elementcontrol = createCustomElement(control, { injector: this.injector });
    customElements.define(controlname, elementcontrol);
  }

  login() {

  }

  setLocation() {
    if (this.applicationInfoService.projectID != 0) {
      let routerValue = this.router.url.split('?');
      let url = routerValue[0].concat('?', 'pid=', this.applicationInfoService.projectID.toString());
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['currentContentLibraryFolder'])) {
        url = url.concat('&cliid=', this.applicationInfoService.miscSettings['currentContentLibraryFolder']);
      }
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['currentContentLibary'])) {
        url = url.concat('&clid=', this.applicationInfoService.miscSettings['currentContentLibary'].id);
      }
      if (this.applicationInfoService.currentAccount) {
         url = url.concat('&aid=', this.applicationInfoService.currentAccount.id);
      }
      if (this.applicationInfoService.currentContact) {
        url = url.concat('&cid=', this.applicationInfoService.currentContact.id);
      }
      if (localStorage.getItem('containerViewId')) {
        url = url.concat('&cvid=', localStorage.getItem('containerViewId'));
      }

      //! workaround
      const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
      if (urlParams.get('gid')) {
        url = url.concat('&gid=', urlParams.get('gid'));
      }

      if (url != this.lastLocation) {
        this.location.replaceState(url);        
        this.lastLocation = url;
      }
    }
    return true;
  }

  checkAppVisibilityRequirements(): boolean {
    let invisible = false;
    if (this.applicationInfoService.cssInitialized === false) { invisible = true; }
    if (this.applicationInfoService.projectSelected === false) { invisible = true; }
    if (this.applicationInfoService.applicationInitialized === false) { invisible = true; }
    if (this.applicationInfoService.showLoadProjectSpinner === true) { invisible = true; }
    if (this.commonService.isNullOrUndefined(this.axivasTranslateService.translationTextArrayProject)) { invisible = true; }
    if (this.applicationInfoService.errorOccured) { invisible = false; }
    if (this.applicationInfoService.noProject) { invisible = false; }
    return invisible;
  }

  getMaximumHeightOfSubContainer(elementId, substractValue = 10) {
    let calculatedHeight = Number(this.uiService.getDesignSpanPositionMobile(elementId, substractValue));
    // if (!isNaN(calculatedHeight) && this.contentMaxHeight == 22000 ) {
    //   this.contentMaxHeight = calculatedHeight;
    // }
    // if (calculatedHeight > this.contentMaxHeight) {
    //   calculatedHeight = this.contentMaxHeight;
    // }
    return calculatedHeight;
    if (this.applicationInfoService.innerWidth < this.applicationInfoService.maxScrollWidth) {
      return null
    } else {
      return calculatedHeight;    
    }    
  }

  calcOutletWidth(referenceControlId) {
    return this.applicationInfoService.innerWidth - this.uiService.getDomControlWidth(referenceControlId, 0) - 10;
  }

  checkIfDashboard(noCalc = false) {
    let returnValue = false;
    if ((
      this.applicationInfoService.miscSettings['navigatedSection'] == 'dashboard' ||
      this.applicationInfoService.miscSettings['navigatedSection'] == '1200'
    )) {
      if (noCalc && this.applicationInfoService.mainViewMaximixed) {
        returnValue = false;
      } else {
        returnValue = true;
      }
    }
    return returnValue;
  }

  startReportTimer() {
    const reportTimerTick = timer(5000, 20000);
    this.reportTimerSubscription = reportTimerTick.subscribe(tick => {      
      this.checkReport();
    });
  }

  checkReport() {
    this.externaldatasourceService.executeExternalDataSource(952, [this.applicationInfoService.userID]).then(availableReportsToDownload => {
      this.applicationInfoService.availableReportsToDownload = availableReportsToDownload;
      if (availableReportsToDownload > 0) {          
        this.messagingService.showDefaultSuccess('Report', 'Report.Message.ReportAvailableForDownload');
        this.reportTimerSubscription.unsubscribe();
      }          
    });
  }

  startLockAccountTimer() {
    const lockAccountTimer = timer(5000, 25000);
    const guid = this.commonService.createGuid();
    this.accountLockSubscription = lockAccountTimer.subscribe(timerTick => {
      if (this.applicationInfoService.accountLockInformation) {
        const dateDiff = moment(Date.now()).diff(this.applicationInfoService.accountLockInformation.lockTill, 'second');
        if (dateDiff > -70 && isNaN(dateDiff) === false) {
          this.projectService.lockAccount()
          .catch(error => {
            this.applicationInfoService.accountLockInformation.lockTill = new Date(Date.now());
            console.log('tryLockAccountError', error, this.applicationInfoService.accountLockInformation.lockTill, dateDiff, guid);
            return;
          });
        }
      }
    });
  }

  getModeText(){
    return (this.applicationInfoService.designerMode ? "Designermodus deaktivieren" : "Designermodus aktivieren" )
  }

  showProjectSelection() {
    this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '80');
  }

  logout() {
    this.awsCognitoService.logoutUserFromCognito();
  }

  changeExpertMode() {
    if (this.applicationInfoService.expertMode == true) {
      this.applicationInfoService.expertMode = false;
      this.settingsService.userSettings['ExpertMode'].value = 'false'
    } else {
      this.applicationInfoService.expertMode = true;
      this.settingsService.userSettings['ExpertMode'].value = 'true'
    }
    this.settingsService.setUserSettingEx(this.settingsService.userSettings['ExpertMode'])
  }

  reloadPage() {
    window.open(environment.serverUrl + environment.baseRef, '_self');
  }

  showMailbox() {
    this.eventService.showJjPopup('UserSettings.Label.Mailbox', 'mailbox', 90);
  }

  getNewUserMessages() {
    try {
      const unreadMessages = this.applicationInfoService.userMessages.filter(message => message.userMessages[0].readDateAt == null);
      if (this.commonService.isNullOrUndefined(unreadMessages)) {
        return 0;
      } else {
        return unreadMessages.length;
      }
    }
    catch {
      console.error('getNewUserMessages error');
    }
  }

  getCurrentApplicationVersionFromServer() {
    let currentVersionFromServer = '0815';

    if (currentVersionFromServer != this.applicationInfoService.versionNumber) {

    }
  }

  getPhoneFeature() {
    if (this.packagefeatureService.selectedPackageHasFeature(28) == false) {
      this.featurePhoneNotFound = true;
    } else {
      this.featurePhoneNotFound = false;
	  }   
  }
}
