import { AfterContentInit, AfterViewInit, Directive , ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { InstancesChanges, NgxTippyService } from 'ngx-tippy-wrapper';
import { Subscription } from 'rxjs';
import tippy,{ Instance } from 'tippy.js';


@Directive({
  selector: '[tippy]'
})
export class TippyDirective implements OnInit , AfterViewInit, OnDestroy, OnChanges, AfterContentInit{


  customEventSubscription:Subscription = new Subscription();
  
/*   @Input('tippy') public tippyContent: string; */
  @Input('tippyOptions') public tippyOptions: Object;
  @Input('dynamic-content') public dynamicContent: TemplateRef<any>
  @Input('static-content') public staticContent: ElementRef<any>

  @Input('tippy') public tippyContent: string | TemplateRef<any> | ElementRef;


  private tippyInstance;

  constructor( 
    private el: ElementRef,
    private eventService: EventService,
    private viewContainerRef: ViewContainerRef
    ) { 


    }

  ngOnInit(): void {

/*     console.log('this.tippyContent', this.tippyContent);
    console.log('this.tippyOptions', this.tippyOptions); */

    this.customEventSubscription = this.eventService.customEvent.subscribe((event) => {
      if(this.tippyInstance){
        this.tippyInstance.hide()
      }
    })
    this.buildTippy()

/*     console.log('this.tippy Instance', this.tippyInstance) */
 
  }




  ngAfterContentInit(): void {
    if(this.tippyInstance){
      this.tippyInstance.destroy()
    }

    this.buildTippy()
  }


  checkContent(){

    let selectedContent
    if(this.tippyContent === 'string'){
      selectedContent = this.tippyContent
    } else if(this.tippyContent instanceof TemplateRef){
      const ref = this.tippyContent.createEmbeddedView({})
      selectedContent = ref.rootNodes[0];
    } else if (this.tippyContent instanceof ElementRef){
      selectedContent = this.tippyContent.nativeElement
    } else {
      selectedContent = undefined
    }
    return selectedContent
  }



  buildTippy(){
    if(!this.tippyContent){
      return
    }

    this.tippyInstance = tippy(this.el.nativeElement,{
      content: this.checkContent(),
      appendTo: document.body,
      zIndex: 9999999,
      placement:'auto',
      theme:'light-border',
      allowHTML: true,
      touch:true,
      interactive:true,
      duration:[0,0],
      ...this.tippyOptions,
      
    })
  }

  ngOnChanges(changes: SimpleChanges){
    //console.log('changes first change',changes)

    if(changes.tippyContent.previousValue == null || changes.tippyContent.previousValue == undefined || changes.tippyContent.currentValue == undefined){
      this.updateInstance();
      /* console.log('this tippy', this.tippyInstance) */
      //this.tippyInstance.show();
    }

    if(changes.tippyContent.currentValue == undefined && (changes.tippyContent.previousValue ||  !changes.tippyContent.previousValue) ){
      //console.log('here')
      
      this.updateInstance();
    }

    if(changes.tippyContent){
     this.updateInstance()
    }

  }

  ngAfterViewInit(): void {

    if(this.tippyInstance && this.tippyContent){
      this.tippyInstance.setContent(this.tippyContent)
    }
  }

  ngOnDestroy(): void {

    if(this.customEventSubscription){
      this.customEventSubscription.unsubscribe();
    }

    if(this.tippyInstance){
      this.tippyInstance.destroy();
    }
  }

  updateInstance(){
    if(this.tippyInstance){
/*       console.log('TIPPY UPDATE CONTENT', this.tippyContent); */
      this.tippyInstance.setContent(this.tippyContent)
      if(this.tippyContent === null){
   /*      console.log('disabled') */
        this.tippyInstance.disable()
      }
    }
  }


}
