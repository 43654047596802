<div class="dataRights">
    <div class="dataRights__header">
        <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>

        <div class="dataRights__header__nav">

            <app-checkbox *ngIf="project" label="GeneralSettings.Setting.IsUsingDataRights" [(ngModel)]="project.isUsingDataRights" (valueChange)="saveProjectInfo()"></app-checkbox>   
            
            <div class="dataRights__header__nav__buttons">
                <app-main-button
                type="small"
                [svg]="generalFilterUser == null && generalFilterString == '' ? 'plus' : 'warning'"
                (onClick)="createDataRightsGroup()"
                label="DataRights.Label.CreateNewGroup"
                [disabled]="generalFilterUser != null || generalFilterString != ''"
                ></app-main-button>
    
                <app-main-button
                type="borderSmall"
                (onClick)="expandAll = !expandAll"
                label="DataRights.Label.ExpandAll"
                svg="arrows-out"></app-main-button>

            </div>
           

        </div>
    </div>

    <div class="dataRights__inner">

        <div class="dataRightsWarning" *ngIf="generalFilterUser != null || generalFilterString != ''">
            <app-jj-icons type="warning" color="red" [width]="20" [height]="20"></app-jj-icons>
            {{'DataRights.Label.CantCreateNewGroup' | texttransform }}
        </div>


        <div class="dataRights__content">
                
            <div class="dataRights__content__nav">
                <div class="dataRights__content__selectContainer">
           <!--          <app-select-input label="DataRights.Label.AllUsers" [(ngModel)]="generalFilterUser" bind="id"
                        display="userName" [options]="users" placeholder="DataRights.Label.AllUsers"
                        (valueChange)="handle($event)"></app-select-input> -->

                    <div class="dataRights__content__specialSelect">
                        <app-select-input label="DataRights.Label.AllUsers" [(ngModel)]="generalFilterUser" bind="id"
                        display="userName" [options]="users" placeholder="DataRights.Label.AllUsers"
                        (valueChange)="handle($event)"></app-select-input>
                        <app-jj-icons type="user-list" *ngIf="generalFilterAccessRights == 'all'"
                            [tippy]="'DataRights.Label.AccessLevel' | texttransform"
                            [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></app-jj-icons>
                        <app-jj-icons type="lock-simple-fill" *ngIf="generalFilterAccessRights == 'readonly'"
                            [tippy]="'DataRights.Label.AccessLevel' | texttransform"
                            [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></app-jj-icons>
                        <app-jj-icons type="lock-simple-open" *ngIf="generalFilterAccessRights == 'full'"
                            [tippy]="'DataRights.Label.AccessLevel' | texttransform"
                            [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></app-jj-icons>

                            <app-select-input label="DataRights.Label.AcessRights" [(ngModel)]="generalFilterAccessRights" bind="value"
                            display="label" [options]="[
                                        { label:'DataRights.Label.AllAccessLevels', value: 'all' },
                                        { label:'DataRights.Label.AccessLevelOnlyRead', value: 'readonly' },
                                        { label:'DataRights.Label.AccessLevelOnlyFullAccess', value: 'full' }]"></app-select-input>
                    </div>
                </div>

                <app-filter (keyUp)="generalFilterString = $event"></app-filter>

            </div>

            <div class="dataRights__content__warning">

                <div class="dataRightsReminder" *ngIf="applicationInfoService.isDeveloper">
                    <app-jj-icons pointer="false" type="info"[width]="20" [height]="20" color="secondary"></app-jj-icons>
                    {{'DataRights.Label.AdminReminder' | texttransform }}
                </div>
                
            </div>


            <div class="dataRights__list">

                <div *ngIf="getDataRightGroupsForParent().length != 0" class="dataRights__list__header">
                    <div class="dataRights__list__header__item">
                        {{'DataRights.Label.DataGroup' | texttransform }}
                    </div>
                    <div class="dataRights__list__header__item listHeader__item dataRights__list__header__item__right">
                        {{'DataRights.Label.AdditionalInfo' | texttransform }}
                    </div>
                </div>

                <div *ngIf="getDataRightGroupsForParent().length == 0" class="dataRights__list__noHeader">    
                    {{'DataRights.Label.NoGroupsYet' | texttransform }}
                </div>
            
                <div class="dataRights__list__body">
                    <div *ngFor="let dataRightGroup of getDataRightGroupsForParent()">
                        <app-datarights-item
                            [dataRightGroup]="dataRightGroup"
                            level=0
                            [availableUsers]="users"
                            [availableFilters]="availableFilters"
                            [expandAll]="expandAll"
                            [generalFilterString]="generalFilterString"
                            [generalFilterUser]="generalFilterUser"
                            [generalFilterAccessRights]="valueStringToBoolean(generalFilterAccessRights)"
                        ></app-datarights-item>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
