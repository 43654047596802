<div class="flowchartExpanded">
    <div class="flowchartExpanded__wrapper">
    
    <app-designer-campaign-flowchart
    [chartData]="flowChartData"
    [controlid]="flowChartOverviewId"
    [initialTreeDepth]="2"
    [treeWidth]="'65%'"
    [treeHeight]="'95%'"
    [height]="'100%'"
    [width]="'100%'"
    [top]="'10%'"
    [toolboxInfo]="true"
    ></app-designer-campaign-flowchart>

    </div>
</div>

