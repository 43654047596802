<div class="everleadCheckboxWrapper">
    <label [class.disabled]="disabled" [class.required]="required" [class.error]="error">
        <input type="checkbox" 
            [checked]="value" 
            (change)="setValue($event.target.checked)" 
            [disabled]="disabled"
            [value]="label | texttransform"
        >
        
        <span *ngIf="labelLeft" class="labelLeft">
            <app-wizard-infobutton *ngIf="info" [displayText]="info" [width]="17" [height]="17"></app-wizard-infobutton>
            {{  label | texttransform }}
        </span>

        <svg *ngIf="!value" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#5465FF"/>
        </svg>
            
        <svg *ngIf="value" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#5465FF" stroke="#5465FF"/>
            <path d="M4 8.375L6.73913 11L13 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="labelRight" *ngIf="!labelLeft">
            {{ label | texttransform }}
            <app-wizard-infobutton *ngIf="info" [displayText]="info" [width]="17" [height]="17"></app-wizard-infobutton>
        </span>

    </label>
</div>


