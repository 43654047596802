<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>

<span id="aiPromptResultDesign"></span>
<div class="aiPromptResult__wrapper" *ngIf="featureNotFound == false && type == 1" [style.max-height.px]="getMaxHeight()">
    <div class="aiPromptResult__selection" *ngIf="aiPrompts.length > 0">
        <app-select-input 
            (valueChange)="runPrompt(selectedPompt?.id)" 
            [(ngModel)]="selectedPompt" 
            [label]="'AIPrompt.Label.BattleCards' | texttransform"
            [options]="aiPrompts"
            [complex]="true" 
            display="defaultName" 
        ></app-select-input>
        <app-main-button [label]="'AIPrompt.Label.ManageBattleCards' | texttransform"  type="borderSmall" svg="plus" 
            *ngIf="userService.hasPermission('CanCreateAiPrompt')" (click)="showDesigner()"></app-main-button>
    </div>

    <div *ngIf="aiPrompts.length == 0" class="noBattleCardAvailable">
        <div>{{ 'AIPrompt.Label.NoBattleCardAvailable' | texttransform }}</div>
        <app-main-button [label]="'AIPrompt.Label.ManageBattleCards' | texttransform"  type="borderSmall" svg="plus" 
            *ngIf="userService.hasPermission('CanCreateAiPrompt')" (click)="showDesigner()"></app-main-button>
    </div>
    <div class="aiPromptResult__result" *ngIf="!loadingData && aiPrompts.length > 0 && selectedPompt!=null">
        <label id="promptResultVisual" [innerHTML]="resultToDisplay" *ngIf="resultToDisplay != null"></label>
        <label class="promptResultVisualPdf" id="promptResultVisualPdf" [innerHTML]="resultToDisplayPdf" *ngIf="resultToDisplay != null"></label>
    </div>    

    <label class="aiPromptResult__result aiPromptResult__wait" *ngIf="loadingData">        
        <svg-icon src="assets/images/svg/battlecard/everlead_battlecard_wait.svg" class="aiPromptSVG" [stretch]="true" *ngIf="selectedPompt.pictureData == null"></svg-icon>
        <img [src]="selectedPompt.pictureData" class="aiPromptPicture" *ngIf="selectedPompt.pictureData != null">
        <div><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'AIPrompt.Label.GeneratingData' | texttransform }}</div>
    </label>

    <div class="aiPromptResult__infoMessage" *ngIf="aiPrompts.length > 0 && selectedPompt!=null">
        <label class="ellipsisText noMargin">{{ 'AIPrompt.Label.GeneratingBattleCard' | texttransform }}</label>
    </div>


    <div clasS="aiPromptResult__runOptions" *ngIf="aiPrompts.length > 0 && selectedPompt!=null">
        <app-main-button type="border" [label]="'AIPrompt.Label.ForceUpdate' | texttransform" [disabled]="selectedPompt == null"
            (click)="runPrompt(selectedPompt.id, true)" svg="arrows-counter-clockwise" ></app-main-button>

        <app-main-button  
            *ngIf="applicationInfoService.applicationSettings['showAIPromptExportToPDFButton'] == 'true'" 
            [label]="'AIPrompt.Label.ExportToPdf' | texttransform"  type="border" svg="file-pdf" 
            (click)="exportPDF()"  [disabled]="resultToDisplay == null"></app-main-button>
    </div>
</div>


<div *ngIf="type == 2" class="smallAiPrompt__wrapper">
    <div class="smallAiPrompt__body">
        <div class="smallAiPrompt__header">
            <h2>{{ 'AIPrompt.Label.BattleCardsSmall' | texttransform }}</h2>
            <div class="smallAiPrompt__header__popup">
                <app-main-button type="borderSmall" svg="arrow-square-out" [label]="'AIPrompt.Label.OpenPopup' | texttransform" (onClick)="openPopup()"></app-main-button>
            </div>
        </div>        
        <app-select-input 
            (valueChange)="runPrompt($event.id)" 
            [(ngModel)]="selectedPompt" 
            [label]="'AIPrompt.Label.SelectBattleCard' | texttransform" 
            [complex]="true" 
            display="defaultName" 
            [options]="aiPrompts">
        </app-select-input>
    </div>
    <div class="smallAiPrompt__content">
        <div class="smallAiPrompt__result" *ngIf="!loadingData">
            <label id="promptResultVisual" [innerHTML]="resultToDisplay" *ngIf="resultToDisplay != null"></label>
        </div> 
        <label class="aiPromptResult__result aiPromptResult__wait" *ngIf="loadingData">        
            <svg-icon src="assets/images/svg/battlecard/everlead_battlecard_wait.svg" class="aiPromptSVGsmall" [stretch]="true" *ngIf="selectedPompt?.pictureData == null"></svg-icon>
            <img [src]="selectedPompt.pictureData" class="aiPromptPicture" *ngIf="selectedPompt.pictureData != null">
            <div><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'AIPrompt.Label.GeneratingData' | texttransform }}</div>
        </label>  
    </div>

</div>