<div class="entitytableAdditionaltoolbarItem">   
    <div class="entitytableAdditionaltoolbarItemIcon">
        
        <svg *ngIf="value !== true" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#5465FF"/>
        </svg>
            

        <svg *ngIf="value == true" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#5465FF" stroke="#5465FF"/>
            <path d="M4 8.375L6.73913 11L13 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
  <!--   ! old svg from new design please do not delete just in case 
     <svg *ngIf="value !== true" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5"  [attr.stroke]="disabled ? 'gray' : '#5465FF'"/>
        </svg>

        <svg *ngIf="value == true" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" [attr.stroke]="disabled ? 'gray' : '#5465FF'" />
            <path d="M2.5 9L6 12.5L14 4.5"  [attr.stroke]="disabled ? 'gray' : '#5465FF'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> -->
<!--         <app-jj-icons [type]="'square'" [width]="20" [height]="20" *ngIf="value != true"></app-jj-icons>
        <app-jj-icons [type]="'check-square'" [width]="20" [height]="20" *ngIf="value == true"></app-jj-icons> -->
    </div>
    <div class="entitytableAdditionaltoolbarItemLabel">{{ label | texttransform }}</div>     
</div>