
<div>
    <app-layout
        [header]="type === 'myvisitors' ? 'WebsiteDashboard.Label.MyVisitors' : 'WebsiteDashboard.Label.MyWebleads'">

        <div class="flexDashboard__content">
            <div class="flexDashboard__nav">
                <app-select-input *ngIf="type === 'myvisitors'"
                    label="WebsiteDashboard.Label.Domain"
                    [(ngModel)]="selectedDomain" (valueChange)="getMyVisitorsKPIs()" [options]="domains"
                    [complex]="true" display="option"></app-select-input>
          
                <app-select-input 
                label="WebsiteDashboard.Label.IntervallLast" 
                [(ngModel)]="lastXDays"
                (valueChange)="type === 'myvisitors' ? getMyVisitorsKPIs() : getMyConversionsKPIs()"
                [options]="options" 
                bind="id" display="label"></app-select-input>
            </div>


            <div class="flexDashboard__contentInner">
                <span *ngIf="loading" class="loader">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                    texttransform }}
                </span>

                <span *ngIf="nodata && !loading" class="noDataAvailable">
                    {{ 'General.Label.NoDataAvailable' | texttransform }}
                </span>

                <div class="flexDashboard__info" *ngIf="kpis != null && !loading && !nodata">
                    <section class="flexDashboard__info__inner" *ngIf="type === 'myvisitors'">
                        <div class="flexDashboard__info__label">
                            <div class="flexDashboard__info__label__value">
                                <app-jj-icons [pointer]="false" [type]="kpis.trend > -0.001 ? 'trend-up' : 'trend-down'"
                                    [width]="25" [height]="25"></app-jj-icons>
                                {{ kpis.trend }}%
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                    [tippy]="'WebsiteDashboard.Label.TrendDescription' | texttransform"
                                    [tippyOptions]="{placement:'top'}"></app-jj-icons>
                            </div>

                            <div class="flexDashboard__info__label__name"> 
                               {{'WebsiteDashboard.Label.WebvisitorsTrend' | texttransform }} 
                              <!--   {{ 'WebsiteDashboard.Label.Web' | texttransform }} {{ 'WebsiteDashboard.Label.IntervallVisitors' | texttransform}} --> 
                            </div>
                        </div>

                        <div class="flexDashboard__info__label">
                            <div class="flexDashboard__info__label__value">
                                {{ kpis.activeVisitorCountInterval }}
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                    [tippy]="'WebsiteDashboard.Label.MyVisitorsHoverText' | texttransform"
                                    [tippyOptions]="{placement:'top'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name"> {{ 'WebsiteDashboard.Label.MyVisitors' |
                                texttransform }}</div>
                        </div>

                        <div class="flexDashboard__info__label" *ngIf="applicationInfoService.applicationSettings['showCompareValueInWebvisitorDashboard'] == 'true'">
                            <div class="flexDashboard__info__label__value">
                                {{ kpis.activeVisitorCountCompareInterval }}
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                    [tippy]="'WebsiteDashboard.Label.MyVisitorsCompareHoverText' | texttransform"
                                    [tippyOptions]="{placement:'top'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name"> {{ 'WebsiteDashboard.Label.MyVisitorsCompare' |
                                texttransform }}</div>
                        </div>

                        <!-- <div class="flexDashboard__info__label">
                            <div class="flexDashboard__info__label__value">
                                {{ kpis.activeUsers }}
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                    [tippy]="'WebsiteDashboard.Label.ActiveVisitorsHoverText' | texttransform"
                                    [tippyOptions]="{placement:'top'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name"> {{ 'WebsiteDashboard.Label.ActiveVisitors' |
                                texttransform }}</div>
                        </div> -->

                        <div class="flexDashboard__info__label">
                            <div class="flexDashboard__info__label__value">
                                {{ kpis.identifiedCompanies }}
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                    [tippy]="'WebsiteDashboard.Label.IdentifiedCompaniesHoverText' | texttransform"
                                    [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name">
                                {{ 'WebsiteDashboard.Label.IdentifiedCompanies' | texttransform }}
                            </div>
                        </div>

             <!--    <div class="flexDashboard__info__label">
                            <div class="flexDashboard__info__label__value">
                                {{ kpis.avgPageViewTime }}
                                    <app-jj-icons type="info" [width]="16" [height]="16"
                                    [tippy]="'WebsiteDashboard.Label.AveragePageViewTime' | texttransform"
                                    [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name">
                                {{ 'WebsiteDashboard.Label.Duration' | texttransform }}
                            </div>
                        </div> -->
                    </section>

                    <section class="flexDashboard__info__inner" *ngIf="type === 'myconversions'">
                        <div class="flexDashboard__info__label">
                            <div class="flexDashboard__info__label__value">
                                <app-jj-icons [pointer]="false" [type]="kpis.trend > -0.001  ? 'trend-up' : 'trend-down'"
                                    [width]="25" [height]="25"></app-jj-icons>
                                {{ kpis.trend }}%
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                    [tippy]="'WebsiteDashboard.Label.TrendDescription' | texttransform"
                                    [tippyOptions]="{placement:'top'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name">
                                {{ 'WebsiteDashboard.Label.ConversionsTrend' | texttransform }}
                            </div>
                        </div>

                        <div class="flexDashboard__info__label">
                            <div class="flexDashboard__info__label__value">
                                {{ kpis.conversionCountInterval }}
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                [tippy]="'WebsiteDashboard.Label.CountIntervalDescription' | texttransform"
                                [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name">
                                {{ 'WebsiteDashboard.Label.CountInterval' | texttransform }}
                            </div>
                        </div>

                        <div class="flexDashboard__info__label" *ngIf="applicationInfoService.applicationSettings['showCompareValueInWebleadDashboard'] == 'true'">
                            <div class="flexDashboard__info__label__value">
                                {{ kpis.conversionCountCompareInterval }}
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                [tippy]="'WebsiteDashboard.Label.CountCompareIntervalConversionDescription' | texttransform"
                                [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name">
                                {{ 'WebsiteDashboard.Label.CountCompareIntervalConversions' | texttransform }}
                            </div>
                        </div>

                    

                        <div class="flexDashboard__info__label">
                            <div class="flexDashboard__info__label__value">
                                {{ kpis.conversionCountAllTime }}
                                <app-jj-icons type="info" [width]="16" [height]="16"
                                [tippy]="'WebsiteDashboard.Label.AllWebleadDescription' | texttransform"
                                [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                            </div>
                            <div class="flexDashboard__info__label__name">
                                {{ 'WebsiteDashboard.Label.AllConversions' | texttransform }}
                            </div>
                        </div>
                    </section>
                </div>

                <div class="flexDashboard__chart" *ngIf="kpis != null && !loading && !nodata">
                    <div class="flexDashboard__chartInner">
                        <app-bar-chart
                        controlid="webleads+webvisitors"
                        [chartData]="kpis.eventSources"
                        height="288"
                        [type]="type"
                        ></app-bar-chart>
                    </div>
                    <div class="flexDashboard__chartLabel">
                        <div class="flexDashboard__chartLabel__inner">
                            <div *ngFor="let label of kpis.eventSources; let i = index" class="flexDashboard__info__label" [style.margin-left.px]="i === 0 ? 15 : 0">
                                <div class="flexDashboard__info__label__value">{{label?.count}}</div>
                                <div class="flexDashboard__info__label__name" [tippy]="translationAxivas(label?.source).length >= optimalStringLength ? translationAxivas(label?.source) : undefined" [tippyOptions]="{ placement:'bottom' }">
                                    {{ checkLabelNameLength(label?.source)  | texttransform }}
                                </div>
                            </div>
                        </div>
                        <div class="flexDashboard__sources" *ngIf="kpis.eventSources?.length > 0">
                            {{ 'WebsiteDashboard.Label.Sources' | texttransform }}
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </app-layout>
</div>