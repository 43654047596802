<div *ngIf="applicationInfoService.projectSelected && showOnlySettings == true" class="projectSelectionRedWrapper">
    <div *ngIf="applicationInfoService?.availableUserGroups?.length > 1 && applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true'"
        class="everleadSelectWrapper">
        <label
            class="everleadSelectWrapper__label">{{ 'AutomaticRecordAssignment.Label.UserGroup' | texttransform }}</label>
        <div class="everleadSelectWrapper__valueWrapper">
            <select (change)="changeUserGroup()" [(ngModel)]="applicationInfoService.currentUserGroup"
            class="select__box">
                <option *ngFor="let userGroup of applicationInfoService.availableUserGroups"
                    class="topLevelHeaderDropDownOption" [ngValue]="userGroup.id">
                    <span *ngIf="!userGroup.nameTranslationToken">{{ userGroup.defaultName }}</span>
                    <span
                        *ngIf="userGroup.nameTranslationToken">{{ userGroup.nameTranslationToken.tokenFullName | texttransform }}</span>
                </option>
            </select>
        </div>
    </div>


    <div *ngIf="applicationInfoService.projectInfo?.pools?.length > 1 && applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true'"
        class="everleadSelectWrapper">
        <label class="everleadSelectWrapper__label">{{ 'TopLevelHeader.Label.Pool' | texttransform }}</label>
        <div class="everleadSelectWrapper__valueWrapper">
            <select [(ngModel)]="applicationInfoService.poolId" class="select__box" (change)="setSessionItem('lastSelectedPool', applicationInfoService.poolId)">
                <option [ngValue]="pool.id" *ngFor="let pool of applicationInfoService.projectInfo?.pools" [ngClass]="{ hideInDom: pool.isActive == false }"
                    class="topLevelHeaderDropDownOption">
                    {{ pool.defaultName }}
                </option>campaign
            </select>
        </div>
    </div>


    <div *ngIf="userService?.userRoles?.length > 1 && applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true'"
        class="everleadSelectWrapper">
        <label class="everleadSelectWrapper__label">{{ 'TopLevelHeader.Label.Role' | texttransform }}</label>
        <div class="everleadSelectWrapper__valueWrapper">
            <select [(ngModel)]="applicationInfoService.currentUserRoleId" (change)="changeUserRole()"
                class="select__box">
                <option [ngValue]="role.roleId" *ngFor="let role of userService?.userRoles"
                    class="topLevelHeaderDropDownOption">
                    {{ role.role.nameTranslationToken.tokenFullName | texttransform }}
                </option>
            </select>
        </div>
    </div>


    <div *ngIf="
            applicationInfoService.applicationSettings['showCampaignMode'] == 'true' &&  
            applicationInfoService.currentUserRoleId != 5 && 
            applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true' &&
            userGroupHasCampaignId() == false &&
            userService.hasPermission('CanUseNonSelfServiceNaviagionItem')
        "
        class="everleadSelectWrapper">
        <label class="everleadSelectWrapper__label">{{ 'TopLevelHeader.Label.CampaignMode' | texttransform }}</label>
        <span *ngIf="!allCampaigns">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </span>
        <div class="everleadSelectWrapper__valueWrapper">
            <select *ngIf="allCampaigns" [(ngModel)]="applicationInfoService.campaingnModeId" class="select__box"
                [title]="applicationInfoService.campaingnModeId" (change)="setSessionItem('lastSelectedCampaignId', applicationInfoService.campaingnModeId)">
                <option [ngValue]="null" class="topLevelHeaderDropDownOption"></option>
                <option [ngValue]="campaign.id" *ngFor="let campaign of allCampaigns"
                    class="topLevelHeaderDropDownOption topLevelHeaderCategoryDropDownOption" [title]=campaign.defaultName>
                    {{ campaign.defaultName }}
                </option>
            </select>
        </div>
    </div>

    <div *ngIf="applicationInfoService.applicationSettings['showSpecialRoleSelection'] == 'true'"
        class="everleadSelectWrapper">
        <label class="everleadSelectWrapper__label">{{ 'TopLevelHeader.Label.Special' | texttransform }}</label>
        <div class="everleadSelectWrapper__valueWrapper">
            <select [(ngModel)]="specialRole" (change)="changeSpecialRole()"
                class="select__box">
                <option [ngValue]="role" *ngFor="let role of applicationInfoService?.availableRoles"
                    class="topLevelHeaderDropDownOption">
                    {{ role.role.nameTranslationToken.tokenFullName | texttransform }} {{ role.roleText | texttransform }}
                </option>
            </select>
        </div>
    </div>

    <!-- Spezialfall testing -->
    <div  class="everleadSelectWrapper" *ngIf="applicationInfoService.applicationSettings['showCampaignMode'] == 'true' && applicationInfoService.currentUserRoleId == 5">
        <label class="everleadSelectWrapper__label">{{ 'TopLevelHeader.Label.CampaignMode' | texttransform }}</label>
        <div class="everleadSelectWrapper__valueWrapper">
            <select [(ngModel)]="applicationInfoService.campaingnModeId" class="select__box"
            [title]="applicationInfoService.campaingnModeId">
                <option [ngValue]="null" class="topLevelHeaderDropDownOption"></option>
                <option [ngValue]="campaign.id" *ngFor="let campaign of campaigns"
                    class="topLevelHeaderDropDownOption topLevelHeaderCategoryDropDownOption" [title]=campaign.defaultName>
                    {{ campaign.defaultName }}
                </option>
            </select>
        </div>
    </div>
</div>

<app-custom-projectoverview [onlyProjects]=true *ngIf="showOnlySettings == false">
</app-custom-projectoverview>
