
<div class="customTextControlWrapper" [class.disabled]="!checkIfControlIsEnabled()">
  <label 
  class="customTextControlWrapper__label"
  [class.active]="isFocused && (checkIfControlIsEnabled()  == true )" 
  [class.disabled]="!checkIfControlIsEnabled()"
  >{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
<!--   <app-jj-icons [type]="'frame-corners'" [width]="20" [height]="20" class="textAreaExpand" (click)="popupOpen = true;"></app-jj-icons> -->
  <div class="customTextControlWrapper__valueWrapperTextArea"
  [class.active]="isFocused && (checkIfControlIsEnabled()  == true )" 
  [class.disabled]="!checkIfControlIsEnabled()">
    <app-jj-icons [type]="'frame-corners'" [width]="20" [height]="20" class="textAreaExpand" (click)="popupOpen = true;"></app-jj-icons>
    <textarea *ngIf="aiReplacementText == null"
      class="customTextControlWrapper__textArea"
      [disabled]="!checkIfControlIsEnabled()"      
      [placeholder]="controlDefinition.displayText | texttransform" 
      (change)="callMethod('onchange')"
      [(ngModel)]="controlValue"
      (blur)="handleBlur()"
      (focus)="handleFocus()"
      [style.font-size.px]="getFontSize()"
    ></textarea>
    <textarea *ngIf="aiReplacementText != null"
      class="customTextControlWrapper__textArea"
      [disabled]="!checkIfControlIsEnabled()"      
      [placeholder]="controlDefinition.displayText | texttransform" 
      [(ngModel)]="aiReplacementText"
      (blur)="handleBlur()"
      (focus)="handleFocus()"
      [style.font-size]="getFontSize()"
    ></textarea>
  </div>  
  <div *ngIf="controlDefinition.controlType == '47' || 
              controlDefinition.controlType == '48' || 
              controlDefinition.controlType == '49'" class="aiTextarea">
    <button class="everleadCustomButton" (click)="getContactAiText(12)" *ngIf="controlDefinition.controlType == '48'">{{ 'TextArea.Label.AITransform' | texttransform }}</button>
    <button class="everleadCustomButton" (click)="getContactAiText(13)" *ngIf="controlDefinition.controlType == '47'">{{ 'TextArea.Label.AITransform' | texttransform }}</button>
    <button class="everleadCustomButton" (click)="getContactAiText(14)" *ngIf="controlDefinition.controlType == '49'">{{ 'TextArea.Label.AITransform' | texttransform }}</button>
    <div *ngIf="aiGenerating == true" class="aiTextarea__icons">
      {{ 'TextArea.Label.AICalculating' | texttransform }}
    </div>
    <div *ngIf="aiReplacementText != null && aiGenerating == false" class="aiTextarea__icons">
      <i class="fas fa-check everleadGreen fingerCursor" (click)="acceptAiText()"></i>
      <i class="fas fa-times everleadCoral fingerCursor" (click)="cancelAiText()"></i>        
    </div>
  </div>
</div>

<div [id]="textAreaPopupId()" [ngClass]="{ hideInDom: popupOpen==false }" class="customTextAreaPopupBackground">
  <div class="customTextAreaPopup">
      <app-text-area-input [label]="commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform" class="maxHeight"
        [(ngModel)]=controlValue (valueChange)="callMethod('onchange')" [maxHeight]="true"></app-text-area-input>
      
      <div class="customTextAreaPopup__closeButton">
        <app-main-button svg="x" (onClick)="popupOpen = false;" [label]="'General.Label.Close' | texttransform"></app-main-button>
      </div>
  </div>
</div>