import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-newcontact',
  templateUrl: './newcontact.component.html',
  styleUrls: ['./newcontact.component.scss']
})
export class NewcontactComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  newContactLabel = 'Button.Contact.CreateNewContact';
  newContactAndLoadLabel = 'Button.Contact.CreateAndLoadContact';
  dummyAccounts = [];
  dummyAccountId = null;
  currentAccount = null;

  constructor(
    private methodService: MethodService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private externalDataSourceService: ExternaldatasourceService,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['newContactLabel'])) {
      this.newContactLabel = this.applicationInfoService.applicationSettings['newContactLabel'];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['newContactAndLoadLabel'])) {
      this.newContactAndLoadLabel = this.applicationInfoService.applicationSettings['newContactAndLoadLabel'];
    }
    if (this.applicationInfoService.currentAccount != null) {
      this.currentAccount = JSON.parse(JSON.stringify(this.applicationInfoService.currentAccount));
    }    
    this.getDummyAccounts();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'SetAccountForNewContact') {
        this.currentAccount = {
          id: event.value.id,
          name1: event.value._1_name1,
          city: event.value._1_city,
          zipcode: event.value._1_zipcode,
          street: event.value._1_street,
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }
  
  getDummyAccounts() {
    this.externalDataSourceService.executeExternalDataSource(684).then(dummyAccounts => {
      this.dummyAccounts = dummyAccounts;
      const currentDummy = this.dummyAccounts.find(dummy => dummy.poolId == this.applicationInfoService.poolId);
      if (currentDummy) {
        this.dummyAccountId = currentDummy.id;
      }
    });
  }

  createContact(loadContact = false) {
    this.loaderService.display(true);
      let contact = this.applicationInfoService.entities.Item('6').data;
      if (this.currentAccount == null) {
        contact['accountId'] = this.dummyAccountId;
      } else {
        contact['accountId'] = this.currentAccount.id;
      }
      this.externalDataSourceService.executeExternalDataSource(685, [
        this.commonService.getModifyArrayBody(contact, ['id'])
      ])      
      //this.methodService.launchInternalMethod(InternalMethodType.SaveEntityToServer, ['6'])
      .then(createContactResult => {
        this.loaderService.display(false);
        this.applicationInfoService.entities.Item('6').data.id = createContactResult.id;
        this.applicationInfoService.entities.Item('6').data.isBlacklisted = false,
        this.applicationInfoService.entities.Item('6').data.isDeleted = false;
        if (this.applicationInfoService.currentAccount) {
          this.applicationInfoService.currentAccount.contacts.push(this.applicationInfoService.entities.Item('6').data);
        }
        if (loadContact) {
          this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [createContactResult.id]).then(() => {
            this.eventService.closeJjPopup();
          })
        } else {
          this.eventService.updateControlContent(6187);
          this.eventService.updateControlContent(22);
          this.eventService.closeJjPopup();  
        }
      })
      .catch(() => {
        if (this.applicationInfoService.checkLastGraphQLErrorMessage('Maximum number of')) {
          this.messagingService.showDefaultWarning(
            'CreateContact.Message.MaximumAmountOfContactsReachedHeader', 
            'CreateContact.Message.MaximumAmountOfContactsReached'
          )
        }
        this.loaderService.display(false);
      });
  }

  removeAccount() {
    this.currentAccount = null;
  }

  changeAccount(header) {
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [
      header, 
      this.applicationInfoService.applicationSettings['newContactSelectAccountContainer'],
      '1200px;800px'
    ])
  }

  checkIfContactCanBeSaved() {
    if (
      this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item('6').data.lastName) ||
      this.applicationInfoService.entities.Item('6').data.lastName == "" 
    ) {
      return false;
    } else {
      return true;
    }
  }
}
