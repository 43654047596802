import { Injectable } from '@angular/core';
import { ExternaldatasourceService } from './externaldatasource.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsettingsService {
  projectSettings = [];

  constructor(
    private commonService: CommonService,
    private externalDataSourceService: ExternaldatasourceService
  ) { }

  getProjectSettings(): Promise<any> {
    return new Promise((getProjectSettingsResolve, getProjectSettingsReject) => { 
      this.externalDataSourceService.executeExternalDataSource(970, []).then(getProjectSettingsResult => {
        this.projectSettings = getProjectSettingsResult;
        getProjectSettingsResolve(getProjectSettingsResult);
      }).catch(() => { getProjectSettingsReject(null) });
    });
  }

  updateProjectSetting(setting): Promise<any> {
    return new Promise((updateProjectSettingResolve, updateProjectSettingReject) => { 
      this.externalDataSourceService.executeExternalDataSource(1000, [
        this.commonService.getModifyArrayBody(setting, ['lastModified'])
      ]).then(getProjectSettingsResult => {
        updateProjectSettingResolve(getProjectSettingsResult);
      }).catch(() => { updateProjectSettingReject(null) });
    });
  }

  createProjectSetting(setting) {
    return new Promise((createProjectSettingResolve, createProjectSettingReject) => { 
      this.externalDataSourceService.executeExternalDataSource(999, [
        this.commonService.getModifyArrayBody(setting, [])
      ]).then(getProjectSettingsResult => {
        setting.id = getProjectSettingsResult.id;
        this.projectSettings.push(setting);
        createProjectSettingResolve(getProjectSettingsResult);
      }).catch(() => { createProjectSettingReject(null) });
    });
  }

  deleteProjectSetting(settingId) {
    return new Promise((deleteProjectSettingResolve, deleteProjectSettingReject) => { 
      this.externalDataSourceService.executeExternalDataSource(1001, [settingId]).then(() => {
        deleteProjectSettingResolve(null);
      }).catch(() => { deleteProjectSettingReject(null) });
    });
  } 
  
  getSettingValue(settingName) {
    const setting = this.projectSettings.find(setting => setting.setting == settingName);
    if (setting) {
      return setting.value;
    } else {
      return null;
    }
  }
}
