import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-accountowner-management',
  templateUrl: './accountowner-management.component.html',
  styleUrl: './accountowner-management.component.scss'
})
export class AccountownerManagementComponent {
  customEventSubscription: Subscription = new Subscription();
  mode = 0;
  fromUserId = null;
  toUserId = null;
  multiUserIds = [];
  projectUsers = [];
  distributionMode = 0;
  proModeControlId = 276773;
  innerHTMLContent = ` <jj-container controluiid="276773"> </jj-container>`;
  innerHTML = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    private eventService: EventService,
    public commonService: CommonService,
    private messagingService: MessagingService,
    private domSanitizer: DomSanitizer,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private loaderService: LoaderService,
    private sharedAPI: SharedAPI
  ) {}

  ngOnInit(): void {
    this.getProjectUsers();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getProjectUsers();
      }
    });
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
  }

  getProjectUsers() {
    if (this.userService.hasPermission('CanUpdateAccount')) {
      this.projectUsers = this.applicationInfoService.projectUsers.toArray();
    }
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  assignAccounts(multiIds = true) {
    let userIds = [];
    if (multiIds) {
      if (this.commonService.checkIfItemIsInArray(this.multiUserIds, this.fromUserId)) {
        this.commonService.removeItemFromArray(this.multiUserIds, this.fromUserId);
      }
      userIds = this.multiUserIds;
    } else {
      userIds.push(this.toUserId);
    }
    this.externaldatasourceService.executeExternalDataSource(1018, [userIds]).then(() => {
      this.messagingService.showDefaultSuccess('AccountOwnerOrganisation.Label.MoveAccountOwners', 
        'AccountOwnerOrganisation.Label.AssignAccountOwnersSuccess');
    });
  }

  reassignAccounts(multiIds = false) {
    let userIds = [];
    if (multiIds) {
      if (this.commonService.checkIfItemIsInArray(this.multiUserIds, this.fromUserId)) {
        this.commonService.removeItemFromArray(this.multiUserIds, this.fromUserId);
      }
      userIds = this.multiUserIds;
    } else {
      userIds.push(this.toUserId);
    }
    this.externaldatasourceService.executeExternalDataSource(1017, [this.fromUserId, userIds]).then(() => {
      this.messagingService.showDefaultSuccess('AccountOwnerOrganisation.Label.MoveAccountOwners', 
          'AccountOwnerOrganisation.Label.MoveAccountOwnersSuccess');
    });
  }

  toggleUser(userId) {
    if (this.commonService.checkIfItemIsInArray(this.multiUserIds, userId)) {
      this.commonService.removeItemFromArray(this.multiUserIds, userId);
    } else {
      this.multiUserIds.push(userId);
    }
  }

  assignAllUnassigned() {
    const body = new Object();
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('AccountOwnerOrganisation.Label.SmartAssignAllUnassignedMessage'),
      header: this.axivasTranslateService.getTranslationTextForToken('AccountOwnerOrganisation.Label.SmartAssignHeader'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        body['projectId'] = this.applicationInfoService.projectID;
        body['orderBy'] = null;
        body['columnFilter'] = [];
        body['isOnlyActive'] = true;
        body['tableLayoutId'] = null;
        body['crossColumnFilter'] = '';
        body['doCheckFollowups'] = true;        
        body['filterId'] = null;
        body['users'] = this.multiUserIds;
        this.sharedAPI.smartAssignAccounts(body)
        .subscribe(
          (next) => { 
            this.messagingService.showDefaultInfo(
              'AccountOwnerOrganisation.Label.AssignEntityAccountOwners',
              'AccountOwnerOrganisation.Label.AssignEntityAccountOwnersMessage');
            this.loaderService.display(false); 
          }, 
          (error) => { 
            console.error(error); this.loaderService.display(false); 
          }
        );
      },
      reject: () => {
        return;
      }
    });
  }
}