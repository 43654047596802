<app-layout header="Application-settings" class="applicationSettings">

    <div class="applicationSettings__inner">
        <div class="applicationSettings__selector">
            <span *ngIf="projectLoading">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform
               }}
            </span>

        <div class="applicationSettings__selector__placeholder" *ngIf="!projectLoading">
            <app-select-input
            label="Projects" 
            [(ngModel)]="projectId" 
            [options]="projectOptions" bind="id" display="projectName" 
            (valueChange)="getApplicationSettings()"></app-select-input>
         <span *ngIf="projectId">= {{ projectId }} </span>
        </div>

            <app-main-button type="small" svg="gear" label="Create Setting"
            (onClick)="createSetting()"></app-main-button>
        </div>
        <div class="applicationSettings__filter">
            <app-filter (keyUp)="filterTable($event)"></app-filter>
        </div>
        <div *ngIf="noData && !loading">
            <div>
                {{ 'General.Label.NoDataAvailable' | texttransform }}<br>
                <br>
            </div>
        </div>

        <span *ngIf="loading">
         <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform
        }}
        </span>
        <div *ngIf="!noData && !loading" class="applicationSettings__list">
            <div class="applicationSettings__list__header">
                <div class="applicationSettings__list__header__id">id</div>
                <div class="applicationSettings__list__header__projectId">projectId</div>
                <div class="applicationSettings__list__header__lookupSettingType">lookupSettingType</div>
                <div class="applicationSettings__list__header__setting">setting</div>
                <div class="applicationSettings__list__header__value">value</div>
                <div class="applicationSettings__list__header__comment">comment</div>
                <div class="applicationSettings__list__header__actions"> actions </div>
            </div>
            <div class="applicationSettings__list__content">
                <div class="applicationSettings__list__row everleadTableRow"
                    *ngFor="let setting of filteredArray; let i= index">
                    <div class="applicationSettings__list__cell">
                        <div class="applicationSettings__list__cell__id">{{setting?.id}}</div>
                    </div>
                    <div class="applicationSettings__list__cell">
                        <div class="applicationSettings__list__cell__projectId">
                            {{setting?.projectId ? setting?.projectId : '- - -'}}
                        </div>
                    </div>
                    <div class="applicationSettings__list__cell">
                        <div class="applicationSettings__list__cell__lookupSettingType">
                            {{ setting?.lookupSettingType ? ellipsis(setting?.lookupSettingType, 25) : '- - -'}}
                        </div>
                    </div>
                    <div class="applicationSettings__list__cell">
                        <div class="applicationSettings__list__cell__setting"
                            [tippy]="setting.setting.length > 35 ? setting.setting : undefined"
                            [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}">
                            {{ setting?.setting ? ellipsis(setting.setting, 35) : '- - -'}}
                        </div>
                    </div>
                    <div class="applicationSettings__list__cell">
                        <div class="applicationSettings__list__cell__value"
                            [tippy]="setting?.value?.length > 40 ? setting.value : undefined"
                            [tippyOptions]="{theme:'everleadBlack'}">
                            {{ setting?.value ? ellipsis(setting?.value,50) : '- - -'}}
                        </div>
                    </div>
                    <div class="applicationSettings__list__cell">
                        <div class="applicationSettings__list__cell__comment"
                            [tippy]="setting?.comment?.length > 60 ? setting.comment : undefined"
                            [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}">
                            {{ setting?.comment ? ellipsis(setting?.comment, 60) : '- - -'}}
                        </div>
                    </div>
                    <div class="applicationSettings__list__cell">
                        <div class="applicationSettings__list__cell__actions">
                            <!--   <app-action-button svg="gear" [tippy]="'Admin.Label.EditSetting'" (onClick)="updateSetting(setting)"></app-action-button> -->

                            <app-action-button svg="gear" [data]="setting"
                                [buttons]="[
                            {id:1, svg: 'editor', disabled: false, label:'Edit', callback:updateSetting },
                            {id:2, svg: 'trash', disabled: true, label:'Delete', callback:deleteSetting },]"></app-action-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</app-layout>
