<div class="aiPrompt__wrapper" *ngIf="userService.hasPermission('CanCreateAiPrompt')">
    <div class="agComponentHeader__wrapper">        
        <app-component-header class="agComponentHeader__header" [header]="'Supervisor.Label.AIPromptDesigner' | texttransform"></app-component-header>
        <div class="agComponentHeader__menu">                    
            <div class="agComponentHeader__item">
                <app-main-button type="small" svg="info" label="AIPrompts.Label.PromptHelp"
                    (onClick)="showAIPromptHelp()"
                    [tippyOptions]="{placement:'bottom'}"></app-main-button>

                <app-main-button type="small" svg="plus-circle" label="AIPrompts.Label.NewPrompt"
                    (onClick)="createAiPrompt()" [tippy]="'General.Label.CreateNewWithDefault' | texttransform"
                    [tippyOptions]="{placement:'bottom'}"></app-main-button>
            </div>
            <app-filter (keyUp)="filterString = $event" class="agComponentHeader__item"></app-filter>        
        </div>
    </div>    

    <div class="aiPromptInner">
    <div class="aiPromptList" *ngIf="selectedPrompt == null">
        <div *ngFor="let aiPrompt of aiPrompts; let promptIndex = index; track: aiPrompt" class="everleadTableRow aiPrompt__item" [ngClass]="{
            hideInDom: aiPrompt.promptLookupId != 6666 && !applicationInfoService.isDeveloper
        }">
            <div class="aiPrompt__item__header" (click)="selectedPrompt = aiPrompt" *ngIf="applicationInfoService.isDeveloper ||
                aiPrompt.promptLookupId == 6666">
                <div class="everleadCoral aiPrompt__item__id">{{ aiPrompt.id }}</div>
                <label class="maxWidth">{{ aiPrompt.defaultName }}</label>
                <div class="aiPrompt__item__header__delete">
                    <app-jj-icons [type]="'pencil-simple-line'" [width]="20" [height]="20"></app-jj-icons>
                    <app-jj-icons [type]="'trash'" [width]="20" [height]="20" (click)="deleteAiPrompt(aiPrompt);$event.stopPropagation()"
                        *ngIf="userService.hasPermission('CanDeleteAiPrompt')"></app-jj-icons>                    
                </div>
            </div>                
        </div> 
    </div>
  
        <app-layout *ngIf="selectedPrompt != null" [header]="selectedPrompt.defaultName">
        <div class="backButton">
            <app-main-button (onClick)="selectedPrompt=null" type="small" label="General.Label.Back"></app-main-button>
        </div>
    
        <app-text-input label="Name" [(ngModel)]="selectedPrompt.defaultName" (valueChange)="saveAiPrompt(selectedPrompt)"></app-text-input>
        <app-checkbox *ngIf="applicationInfoService?.isDeveloper" label="AIPrompts.Label.IsDefault" [(ngModel)]="selectedPrompt.isDefault" (valueChange)="saveAiPrompt(selectedPrompt)"></app-checkbox>
        <app-checkbox label="AIPrompts.Label.IsActive" [(ngModel)]="selectedPrompt.isActive" (valueChange)="saveAiPrompt(selectedPrompt)"></app-checkbox>
        <app-select-input label="AIPrompts.Label.Model" *ngIf="applicationInfoService?.isDeveloper" [(ngModel)]="selectedPrompt.modelLookupId" [options]="promptModels" display="defaultName" bind="modelLookupId" (valueChange)="saveAiPrompt(selectedPrompt)"></app-select-input>
        <app-select-input label="AIPrompts.Label.Response" *ngIf="applicationInfoService?.isDeveloper" [(ngModel)]="selectedPrompt.responseLookupId" [options]="responseLookups" display="defaultName" bind="id" (valueChange)="saveAiPrompt(selectedPrompt)"></app-select-input>
        <app-select-input label="AIPrompts.Label.Prompt" *ngIf="applicationInfoService?.isDeveloper" [(ngModel)]="selectedPrompt.promptLookupId" [options]="promptLookups" display="defaultName" bind="id" (valueChange)="saveAiPrompt(selectedPrompt)"></app-select-input>
        <app-select-input label="AIPrompts.Label.Language" [(ngModel)]="selectedPrompt.languageId" [options]="languages" display="languageName" bind="id" (valueChange)="saveAiPrompt(selectedPrompt)"></app-select-input>

       
        <app-component-header class="agComponentHeader__header" [header]="'AIPrompts.Label.PromptHeader' | texttransform"></app-component-header>
        <div class="aiPrompt__item__promptRow">
            <!-- <label>{{ 'AIPrompts.Label.PromptHeader' | texttransform }}</label> -->
            <div class="aiPrompt__item__promptRow__text">
                <div class="aiPrompt__item__promptRow__text__prompt">
                    <textarea #promptEditor [(ngModel)]="selectedPrompt.prompt" (change)="saveAiPrompt(selectedPrompt)"
                        (keyup)="getCaretPos(promptEditor)" (click)="getCaretPos(promptEditor)"></textarea>
                </div>
                <div class="aiPrompt__item__promptRow__text__placeholder">
                    <select [(ngModel)]="placeholderEntity" class="placeholderEntitySelect">                        
                        <option *ngFor="let entityOption of entityOptions" [ngValue]="entityOption.id">{{ entityOption.defaultName | texttransform }}</option>
                    </select>
                    <div class="placeholderEntityFilter">
                        <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                        <input [(ngModel)]="filterPlaceholderString">
                    </div>
                    <div class="aiPrompt__item__promptRow__text__placeholder__list">  
                        <div class="everleadTableRow placeholderItem" *ngFor="let placeholder of getPlaceholderList(placeholders)" [ngClass]="{ hideInDom: isPlaceholderFiltered(placeholder) }">
                            <app-jj-icons [type]="'plus'" [width]="20" [height]="20" (click)="addMemberToPrompt(placeholder.tokenName, selectedPrompt)"></app-jj-icons>
                            <span *ngIf="placeholder.alternateText == null">
                                <span *ngIf="placeholder.nameTranslationTokenId != null" [ngClass]="{ placeHolderItemText: placeholderEntity!='questionnaire' }">
                                    {{ axivasTranslateService.getTranslationTextForId(placeholder.nameTranslationTokenId) | texttransform }}
                                </span>
                                <div *ngIf="placeholder.nameTranslationTokenId == null">
                                    {{ placeholder.tokenName }} 
                                </div>    
                                <div class="placeholderAlternateInfo" *ngIf="placeholder.nameTranslationTokenId != null">{{ placeholder.tokenName }} </div>                                
                            </span>
                            <span *ngIf="placeholder.alternateText != null" [ngClass]="{ placeHolderItemText: placeholderEntity!='questionnaire' }">
                                <div>
                                    <span *ngIf="placeholder.alternateTextPrefix != null">{{ placeholder.alternateTextPrefix | texttransform }}:</span>
                                    {{ placeholder.alternateText }}
                                </div>
                                <div class="placeholderAlternateInfo">{{ placeholder.tokenName }} </div>
                            </span>                            
                        </div>                            
                    </div>   
                </div>
            </div>
        </div>

        <app-component-header class="agComponentHeader__header" [header]="'AIPrompts.Label.Settings' | texttransform" *ngIf="applicationInfoService?.isDeveloper"></app-component-header>
        <div class="aiPrompt__item__settings" *ngIf="applicationInfoService?.isDeveloper">
            <app-number-input label="AIPrompts.Label.Temperature" [(ngModel)]="selectedPrompt.settingsArray.Temperature" (change)="saveAiPrompt(selectedPrompt)" 
                step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)"></app-number-input>
            <app-number-input label="AIPrompts.Label.TopP" [(ngModel)]="selectedPrompt.settingsArray.TopP" (change)="saveAiPrompt(selectedPrompt)" 
                step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)"></app-number-input>
            <app-number-input label="AIPrompts.Label.MaxToken" [(ngModel)]="selectedPrompt.settingsArray.MaxTokens" (change)="saveAiPrompt(selectedPrompt)" ></app-number-input>
            <app-number-input label="AIPrompts.Label.PresencePenalty" [(ngModel)]="selectedPrompt.settingsArray.PresencePenalty" (change)="saveAiPrompt(selectedPrompt)"
                step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)"></app-number-input>
            <app-number-input label="AIPrompts.Label.FrequencyPenalty" [(ngModel)]="selectedPrompt.settingsArray.FrequencyPenalty" (change)="saveAiPrompt(selectedPrompt)"
                step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)"></app-number-input>
            <app-checkbox label="AIPrompts.Label.UseTemperature" [(ngModel)]="selectedPrompt.settingsArray.UseTemperature" (valueChange)="saveAiPrompt(selectedPrompt)"></app-checkbox>
             
            <br>
            <app-component-header class="agComponentHeader__header" [header]="'AIPrompts.Label.Image' | texttransform"></app-component-header>
            <div class="aiPrompt__item__settings__headersmall">{{ 'AIPrompts.Label.ImageDesc' | texttransform }}</div>
            <div class="aiPrompt__item__settings__line">                        
                <div class="aiPromptImage">
                    <div class="aiPromptImage__left">
                        <img [src]="selectedPrompt.pictureData" class="aiPromptImagePreview" *ngIf="selectedPrompt.pictureData != '' && selectedPrompt.pictureData != undefined">
                        <i class="fa-4x far fa-image" *ngIf="selectedPrompt.pictureData == '' || selectedPrompt.pictureData == undefined"></i>
                    </div>
                    <div class="aiPromptImage__right">
                        <input type="file" #file accept="image/*" placeholder="Choose file" (change)="uploadLoadingImage($event, aiPrompt)" style="display:none;">
                        <div class="aiPromptImage__buttons">
                            <app-main-button type="small" svg="trash" *ngIf="this.applicationInfoService.userAvatar != ''" [label]="'AIPrompts.Label.RemoveLoadingImage' | texttransform" (onClick)="removeLoadingImage(aiPrompt)"></app-main-button>
                            <app-main-button type="small" svg="image" (onClick)="file.click()" [label]="'AIPrompts.Label.UploadLoadingImage' | texttransform"></app-main-button>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>        
        </app-layout>
    </div>

</div>