import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { WizardMode, WizardService } from '../../../wizard/wizard.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-process-step',
  templateUrl: './process-step.component.html',
  styleUrl: './process-step.component.scss'
})
export class ProcessStepComponent implements OnInit {
  @Input() step;
  @Input() eventTrigger;
  @Input() resultCounts;
  @Input() leadStates;
  @Input() qmUserGroups;
  @Input() workflows;
  @Input() showOnlyActive;

  excludeItemsStep = ['lookupTable', 'length'];

  constructor(
    private axivasTranslateService: AxivasTranslateService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private wizardService: WizardService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService
  ) {}

  ngOnInit(): void {
    this.step.results.forEach(result => {
      if (result.nextStep != null) {
        result.nextStepWorkflowId = result.nextStep.workflowId;
      }
    });
    this.step.results.sort((a, b) => a.resultOrder < b.resultOrder ? -1 : 1);
  }

  createNewStepResult() {
      let newResult = {
        isActive: true,
        stepId: this.step.id,
        defaultName: this.axivasTranslateService.getTranslationTextForToken('ProcessDesign.Label.NewResult'),
        resultOrder: 0,
        isPersonalFollowup: false,
        isNetResult: false,
        isCallAttempt: false,
        nextStepId: null,
        emailTemplateId: null,
        leadStatusId: null
      }
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(569, [
        this.commonService.getModifyArrayBody(newResult, [])
      ]).then(result => {
        newResult['id'] = result.id;
        this.wizardService.showWizard(WizardMode.Result, newResult);
        this.loaderService.display(false);        
        this.wizardService.wizardFinishedEvent.emit();
    })
    .catch(() => {
      this.loaderService.display(false);
    });
  }

  showWizard() {
    this.wizardService.showWizard(WizardMode.Step, this.step);
  }

  saveStep() {
    this.externaldatasourceService.executeExternalDataSource(568, [
      this.commonService.getModifyArrayBody(this.step, this.excludeItemsStep)
    ]);
  }
}
