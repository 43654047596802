import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-row-marker',
  templateUrl: './row-marker.component.html',
  styleUrl: './row-marker.component.scss'
})
export class RowMarkerComponent implements OnInit{

  @Input() blue: boolean  = false;
  @Input() green: boolean = false;
  @Input() yellow: boolean = false;
  @Input() red: boolean = false;
  @Input() orange: boolean = false;
  @Input() color: string | null = null;

  @Input()active : boolean | null = null;



  ngOnInit(): void {
/*     console.log('blue', this.blue) */
  }


    getColor(){

      if(this.color){
        return this.color;
      }
  
      if(this.blue){
        return '#5465FF'
      }
  
      if(this.green){
        return '#3FE163';
      }
  
      if(this.yellow){
        return '#FFD600';
      }
  
      if(this.red){
        return '#FF5050';
      }
  
      if(this.orange){
        return '#FF6B00';
      }
      
    }
  
}
