import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboardmaincontainer',
  templateUrl: './dashboardmaincontainer.component.html',
  styleUrl: './dashboardmaincontainer.component.scss'
})
export class DashboardmaincontainerComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription()
  reports = null;
  selectedReport = null;
  dashboards = [];  
  
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    public dashboardcoreService: DashboardcoreService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.dashboardcoreService.selectedReport = null;
    this.getReports();
    const dashboards = JSON.parse(this.applicationInfoService.applicationSettings['defaultDashboards']);
    dashboards.forEach(dashboard => {
      if (this.userService.hasPermissions(dashboard.requiredRights)) {
        this.dashboards.push(dashboard);
      }
    });

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'navigationMenuItemClicked' && event.target == 'defaultdashboards') {
        this.dashboardcoreService.selectedReport = null;
      }
    })
  }
  
  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getReports() {
    this.externaldatasourceService.executeExternalDataSource(956).then(getReportsResult => {
      this.reports = getReportsResult;      
    });
  }

  getDashboardDefaultName() {
    const dashboard = this.dashboards.find(dashboard => dashboard.id == this.dashboardcoreService.selectedReport);
    if (dashboard) {
      return dashboard.defaultName;
    } else {
      return '';
    }
  }
}
