<div class="selectedDashboard">
<app-layout header="Dashboard.Item.AgentProgress" [subheader]="selectedReport?.subHeader">
    <div class="selectedDashboard__headerNav">
        <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back" type="small"></app-main-button>
   </div> 
   <div class="selectedDashboard__innerBorderLayer">
        <div class="selectedDashboard__nav">
            <div class="selectedDashboard__filterMobile">
                <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                    <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                    <app-date-input [calendarOnly]="true" label="General.Label.Date" [(ngModel)]="fromDate" (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>	
                </app-accordion>

            </div>
            <div class="selectedDashboard__filter">
                <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                    <app-date-input [calendarOnly]="true" label="General.Label.Date" [(ngModel)]="fromDate" (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>	
            </div>
        </div>
        <div class="selectedDashboard__content">
            <div *ngIf="loadingData == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </div>
            
            <div class="dataDiv" *ngIf="dashboardResultData[this.dashboardId]==null && loadingData == false">
                {{ 'General.Label.NoDataAvailable' | texttransform }}
            </div>

            <div *ngIf="loadingData == false && dashboardResultData[this.dashboardId]!=null" class="defaultDashboardConsentTableContainer">
                <div class="dailyInfo">
                    <div class="dailyInfo__parameter"></div>            
                    <div class="dailyInfo__target">{{ 'DefaultDashboardAgent.Label.Target' | texttransform }}</div>
                    <div class="dailyInfo__value">{{ 'DefaultDashboardAgent.Label.Value' | texttransform }}</div>
                    <div class="dailyInfo__perhour">{{ 'DefaultDashboardAgent.Label.PerHour' | texttransform }}</div>
                    <div class="dailyInfo__quota">{{ 'DefaultDashboardAgent.Label.Quota' | texttransform }}</div>
                </div>

                <div class="dailyInfo everleadTableRow" *ngFor="let parameter of getParameters()">
                    <div class="dailyInfo__parameter">{{ getValue(parameter).Parameter }} </div>            
                    <div class="dailyInfo__target">{{ getValue(parameter).Target }} </div>
                    <div class="dailyInfo__value">{{ getValue(parameter).Value }} </div>
                    <div class="dailyInfo__perhour">{{ getValue(parameter).per_Hour }} </div>
                    <div class="dailyInfo__quota">{{ getValue(parameter).Quota }} </div>
                </div>
                <!-- <app-bar-chart 
                    [barHeader]="pool.defaultName"
                    [chartData]="getPoolData(pool)" 
                    component="accountOwner" 
                    [consentRef]="sortArrayId"
                    [controlid]="accountOwnerChart">
                </app-bar-chart> -->
            </div>
        </div>
    </div>
</app-layout>
</div>