<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name |
            texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc |
            texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="maxHeight autoOverflowY">
                <div class="maxHeight noOverflow columnFlex">
                    <div class="">
                        <label class="bookingEntityMemberLabel">{{ 'Task.Label.TaskOwner' | texttransform }}</label>
                        <select [(ngModel)]="wizardService.wizardArray.followUpUserId" class="bookingDropdown maxWidth" (change)="updateEMailBatch()">
                            <option *ngFor="let user of users" [ngValue]="user.id">
                                {{ user.userName }}
                            </option>
                        </select>
                    </div>
                    <div class="">
                        <label class="bookingEntityMemberLabel">{{ 'Task.Label.MailshotSendDate' | texttransform }}</label>
                        <input [(ngModel)]="wizardService.wizardArray.followUpDate" [owlDateTimeTrigger]="dt8"
                            [owlDateTime]="dt8" readonly class="bookingInput" aria-modal="true" (dateTimeChange)="updateEMailBatch()">
                    </div>
                    <owl-date-time #dt8></owl-date-time>
                    <div class="bookingSpacer" *ngIf="wizardService.wizardArray.followUpDate && 
                        wizardService.wizardArray.stepId &&
                        wizardService.wizardArray.newsletter != true
                    ">
                        <label class="bookingEntityMemberLabel">{{ 'Task.Label.SendDate' | texttransform }}:</label>
                        <label class="everleadCoral">{{ getSendDate(wizardService.wizardArray.followUpDate,
                            wizardService.wizardArray.stepId) | date:'medium' }} </label>
                    </div>
                    <div class="everleadCoral fontSmall bookingSpacer" *ngIf="wizardService.wizardArray.receivers > 50">
                        {{ 'Task.Label.EMailBatchInfo' | texttransform }} ({{ wizardService.wizardArray.receivers }})
                    </div>
                    <div class="mailshotTemplateError everleadCoral" *ngIf="wizardService.wizardArray.campaignId == null">{{ 'EMailTemplate.Label.CampaignMissing' | texttransform }}</div>
                    <div class="mailshotTemplateError everleadCoral" *ngIf="wizardService.wizardArray.receivers == null && wizardService.wizardArray.newsletter != true">{{ 'EMailTemplate.Label.ReceiversNotSet' | texttransform }}</div>
                    <div class="mailshotTemplateError everleadCoral" *ngIf="wizardService.wizardArray.stepId == null">{{ 'EMailTemplate.Label.StepMissing' | texttransform }}</div>
                    <div *ngIf="errorMessage != ''" class="mailshotTemplateError">
                        <label class="everleadSmallHeader">{{ 'EMailTemplate.Label.AttibuteMissing' | texttransform }}</label>
                        <label class="everleadCoral maxWidth">{{ errorMessage }}</label>                        
                    </div>
                    <div>
                        <label *ngIf="applicationInfoService.applicationSettings['isDemoproject'] == 'true'" class="everleadCoral" style="font-size: large">
                            {{'CreateTask.Label.NoMailshotInDemo'| texttransform}}   
                        </label>
                        <button class="jjButtonStyle jjButtonColor bookingButton" (click)="createMailShot()" 
                            *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true' || applicationInfoService.isDeveloper"
                            [disabled]="wizardService.wizardArray.campaignId == null ||
                                    (wizardService.wizardArray.receivers == null && wizardService.wizardArray.newsletter != true) ||
                                    (wizardService.wizardArray.newsletterMicroCampaignResultId == null && wizardService.wizardArray.newsletter == true) ||
                                    wizardService.wizardArray.stepId == null"
                        >
                            <span *ngIf="wizardService.wizardArray.newsletter != true">{{'CreateTask.Label.CreateMailshot'| texttransform}}</span>
                            <span *ngIf="wizardService.wizardArray.newsletter == true">{{'CreateTask.Label.CreateNewsletter'| texttransform}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>