<span id="defaultDashboardAgentWVL"></span>
<div class="selectedDashboard">
    <app-layout header="Defaultdashboard.Label.AgentWVL" [subheader]="selectedReport?.subHeader">
        <div class="selectedDashboard__headerNav">
            <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back"
                type="small"></app-main-button>
        </div>
        <div class="selectedDashboard__innerBorderLayer">

            <div class="selectedDashboard__nav">
                <div class="selectedDashboard__tabs">
                    <app-scroll-tabs>
                        <app-tab label="Account.Label.Header" svg="building" (onClick)="switchTab(1)"
                        [active]="tab===1"></app-tab>
                        <app-tab label="Contact.Label.Header" svg="user" (onClick)="switchTab(2)"
                        [active]="tab===2"></app-tab>
                    </app-scroll-tabs>
                
                </div>
                <div class="selectedDashboard__tabsMobile">
                    <div class="everleadMobileTab">
                        <div class="everleadMobileTab__valueWrapper">
                            <select [(ngModel)]="tab" class="selectTab__box" (change)="switchTab(tab)">
                                <option [ngValue]="1">{{'Account.Label.Header' | texttransform }}</option>
                                <option [ngValue]="2">{{'Contact.Label.Header' | texttransform }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="selectedDashboard__filterMobile">
                    <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                        <div class="selectedDashboard__filterMobile__select" *ngFor="let reportParameter of reportTemplate?.reportParameters">
                            <app-select-input
                                *ngIf="reportParameter.parameterTypeLookupId == 16 && reportParameter.isUserParameter"
                                [label]="commonService.getTranslationValueFromArray(reportParameter)"
                                [(ngModel)]="reportParameterValues[reportParameter.id]"
                                [options]="paramterExternalDataSourceResults[reportParameter.externalDataSource?.id]"
                                [bind]="reportParameter.externalDataSource?.dataKey"
                                [display]="reportParameter.externalDataSource?.dataValue"
                                (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                        </div>
                    </app-accordion>
                </div>

                <div class="selectedDashboard__filter">
                    <div class="selectedDashboard__filter__select" *ngFor="let reportParameter of reportTemplate?.reportParameters">
                        <app-select-input
                            *ngIf="reportParameter.parameterTypeLookupId == 16 && reportParameter.isUserParameter"
                            [label]="commonService.getTranslationValueFromArray(reportParameter)"
                            [(ngModel)]="reportParameterValues[reportParameter.id]"
                            [options]="paramterExternalDataSourceResults[reportParameter.externalDataSource?.id]"
                            [bind]="reportParameter.externalDataSource?.dataKey"
                            [display]="reportParameter.externalDataSource?.dataValue"
                            (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                    </div>

                </div>
                <div class="selectedDashboard__toggleChartsSimple" *ngIf="loadingData == false && steps.length > 0">
                    <app-main-button [type]="viewChart === true ? 'small' : 'borderSmall'" svg="chart-bar-horizontal"
                        label="Dashboard.Label.ViewChart" (onClick)="viewChart=true"></app-main-button>
                    <app-main-button [type]="viewChart === false ? 'small' : 'borderSmall'" svg="list"
                        label="Dashboard.Label.ViewList" (onClick)="viewChart=false"></app-main-button>
                </div>
            </div>

        
            <div class="selectedDashboard__content">
            
                    <!-- <app-select-input label="Pool" [(ngModel)]="poolId" 
                            [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                        <app-select-input label="Task.Label.Campaign" [(ngModel)]="campaignId" 
                            *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                            [options]="campaigns" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input> -->
        
                <div *ngIf="loadingData == true">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                    texttransform }}
                </div>
                <div *ngIf="loadingData == false && steps.length == 0" class="defaultDashboardAgentWVLTableContainer">
                    {{ 'General.Label.NoDataAvailable' | texttransform }}
                </div>

                <div *ngIf="loadingData == false && steps.length > 0 && viewChart == true"
                    class="defaultDashboardAgentWVLTableContainer">
                    <div>
                        <app-layout header="DashboardWVL.Label.SumOverall">
                            <app-bar-chart [height]="getChartHeight(userSumOverallArray)"
                                [chartData]="userSumOverallArray.reverse()" component="agentwvloverall"
                                [controlid]="agentwvlChart">
                            </app-bar-chart>
                        </app-layout>
                    </div>
                    <div>
                        <app-layout header="DashboardWVL.Label.Sum">
                            <app-bar-chart [height]="getChartHeight(userSumOverallDetailArray)"
                                [chartData]="userSumOverallDetailArray.reverse()" component="agentwvl"
                                [controlid]="agentwvlChart">
                            </app-bar-chart>
                        </app-layout>
                    </div>
                    <div *ngFor="let step of steps">
                        <app-layout [header]="step">
                            <app-bar-chart [height]="getChartHeight(getStepArray(step))" [chartData]="getStepArray(step)"
                                component="agentwvl" [controlid]="agentwvlChart">
                            </app-bar-chart>
                        </app-layout>
                    </div>
                </div>


                <div *ngIf="loadingData == false && steps.length > 0 && viewChart == false"
                    class="defaultDashboardAgentWVLTableContainer" (scroll)="open= false">
                    <div>
                        <app-layout header="DashboardWVL.Label.Sum">
                            <div class="defaultDashboardAgentWVLStepItem defaultDashboardAgentWVLStepItemHeader">
                                <div class="defaultDashboardAgentWVLStepItem__user">{{ 'DashboardWVL.Label.User' |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.Past' |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.Today' |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{
                                    'DashboardWVL.Label.NearFuture' | texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.Future' |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.FarFuture'
                                    | texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.Sum' |
                                    texttransform }}</div>
                            </div>
                            <div *ngFor="let stepDataItem of userSumOverallDetailArray"
                                class="defaultDashboardAgentWVLStepItem everleadTableRow">
                                <div class="defaultDashboardAgentWVLStepItem__user">{{ stepDataItem.user_name |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.past }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.today }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.near_future }}
                                </div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.future }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.far_future }}
                                </div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.anzahl }}</div>
                            </div>
                        </app-layout>
                    </div>

                    <div *ngFor="let step of steps">
                        <app-layout [header]="step">
                            <div class="defaultDashboardAgentWVLStepItem defaultDashboardAgentWVLStepItemHeader">
                                <div class="defaultDashboardAgentWVLStepItem__user">{{ 'DashboardWVL.Label.User' |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.Past' |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.Today' |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{
                                    'DashboardWVL.Label.NearFuture' | texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.Future' |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.FarFuture'
                                    | texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ 'DashboardWVL.Label.Sum' |
                                    texttransform }}</div>
                            </div>
                            <div *ngFor="let stepDataItem of stepData(step)"
                                class="defaultDashboardAgentWVLStepItem everleadTableRow">
                                <div class="defaultDashboardAgentWVLStepItem__user">{{ stepDataItem.user_name |
                                    texttransform }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.past }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.today }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.near_future }}
                                </div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.future }}</div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.far_future }}
                                </div>
                                <div class="defaultDashboardAgentWVLStepItem__numberValue">{{ stepDataItem.anzahl }}</div>
                            </div>
                        </app-layout>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</div>