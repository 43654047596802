<!-- [ngClass]="{'startPageDashboardContent': applicationInfoService.isHeaderVisible, 'startPageDashboardContentTwo': !applicationInfoService.isHeaderVisible}"  -->

<div class="startpageDashboard">
  <div class='startPageDashboardContent'>    
    <div [ngClass]="{'startpageDashboard__userRow': applicationInfoService.isHeaderVisible, 'startpageDashboard__userRowTwo': !applicationInfoService.isHeaderVisible}">
      <app-user-avatar [component]="'logo'"></app-user-avatar>
    </div>
    <div class="startPageDashboardDashboards" (scroll)="applicationInfoService.onWindowScroll($event)" *ngIf="showDashboards">
      <div class="startPageDashboardDashboardsItem">
        <app-dashbdashboardcomponent-myday></app-dashbdashboardcomponent-myday>
      </div>
      <div class="startPageDashboardDashboardsItem" *ngIf="applicationInfoService.projectInfo.packageId != 4">
        <app-dashdashboardcomponent-lastactivity></app-dashdashboardcomponent-lastactivity>
      </div>
      <div class="startPageDashboardDashboardsItem" *ngIf="applicationInfoService.projectInfo.packageId == 4">
        <app-dashdashboardcomponent-updates></app-dashdashboardcomponent-updates>
      </div>
      <div class="startPageDashboardDashboardsItem">
        <app-dashbdashboardcomponent-mytopcontacts></app-dashbdashboardcomponent-mytopcontacts>
      </div>  
    </div>
  </div>
</div>
