import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cloudconnections',
  templateUrl: './cloudconnections.component.html',
  styleUrls: ['./cloudconnections.component.scss']
})
export class CloudconnectionsComponent implements OnInit, OnDestroy {
  customEventSubstription: Subscription = new Subscription();
  cloudConnections = [];
  connectionTypes = [];
  filterString = '';
  selectedConnectionType = null;
  extendedConnections = [];
  selectedConnection = null;
  availableUsers = [];
  assignedUsers = [];
  projectUsers = [];
  providers = [];
  projects = [];
  connectionSettingsJson = null;
  connectionDataJson = null;
  createNewConnectionOAuthObject = {
    Authority: '',
    ClientId: '',
    ClientSecret: ''
  };
  createNewConnectionProvider = null;
  createNewConnection = false;
  createNewConnectionType = null;
  createNewConnectionName = 'New connection';
  createNewConnectionSettings = {
    Email: "<sender>",
    ProviderId: 7944,
    DisplayName: "<sender displayname>",
    AutoContactCreation: false,
    AutoContactAssignment: false,
    MoveSynchronizedMails: true,
    DeleteSynchronizedMails: false

  };
  createNewConnectionData = '';
  isCustomerMode = true;

  constructor(
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    private messagingService: MessagingService,
    private eventService: EventService,
    private sharedApi: SharedAPI,
    private loaderService: LoaderService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit(): void {
    if (this.userService.hasPermission('CanReadCloudConnection')) {
      this.getConnectionTypes()
      this.getCloudConnections();
      this.getProvider();
      this.customEventSubstription = this.eventService.customEvent.subscribe(event => {
        if (event == 'getCompleteProjectInformaton') {
          this.getConnectionTypes();
          this.getProvider();
        }
      });  
      this.applicationInfoService.user.userProjects.forEach(project => {
        const checkProject = this.projects.find(availableProject => availableProject.project.id == project.project.id);
        if (this.commonService.isNullOrUndefined(checkProject)) {
          this.projects.push(project);
          this.projects = this.projects.map((item) =>{
            return{
              ...item,
              label:item.project.projectName,
              value:item.project.id
            }
          })
        }
      });       
      this.getProjectUsers();

      if (this.applicationInfoService.isDeveloper) {
        this.isCustomerMode = false;
      }
    }    
  }

  ngOnDestroy(): void {
    if (this.customEventSubstription) { this.customEventSubstription.unsubscribe(); }
  }

  getProvider() {
    this.providers = this.commonService.getProjectLookupTableValues(97, 'central');
   /*  console.log(':(', this.providers) */
  }

  getConnectionTypes() {
    this.connectionTypes = this.commonService.getProjectLookupTableValues(48, 'central');
  }

  getCloudConnections() {
    this.externaldatasourceService.executeExternalDataSource(845).then(cloudConnections => {
      this.cloudConnections = cloudConnections.filter(connection => 
        (connection.projectId == null || 
        connection.projectId == this.applicationInfoService.projectID)
      );
      if (this.isCustomerMode) {
        this.cloudConnections = this.cloudConnections.filter(connection =>           
          connection.connectionTypeLookupId == 7942
        );
      }
    });
  }

  updateCloudConnection() {
    this.selectedConnection.connectionSettings = JSON.stringify(this.connectionSettingsJson);
    this.selectedConnection.connectionData = JSON.stringify(this.connectionDataJson);

    if (this.userService.hasPermission('CanUpdateCloudConnection')) {
      this.externaldatasourceService.executeExternalDataSource(847, [this.commonService.getModifyArrayBody(this.selectedConnection, [])]);      
    };
  }

  selectConnection(cloudConnection) {
    if (this.userService.hasPermission('CanUpdateCloudConnection')) {
      this.connectionSettingsJson = null;
      this.connectionDataJson = null;
      this.externaldatasourceService.executeExternalDataSource(874, [cloudConnection.id]).then(selectConnectionResult => {
        this.selectedConnection = selectConnectionResult;
        this.connectionSettingsJson = JSON.parse(selectConnectionResult.connectionSettings);
        this.connectionDataJson = JSON.parse(selectConnectionResult.connectionData);
     /*    console.warn(this.connectionDataJson, this.connectionSettingsJson) */
        this.getUsers();
      });
    }    
  }

  checkIfItemIsFiltered(cloudConnection) {
    let returnValue = true;
    if ((cloudConnection.connectionTypeLookupId == this.selectedConnectionType || this.selectedConnectionType == null) && 
      this.commonService.checkIfStringContainsString(cloudConnection.defaultName, this.filterString)
    ) {
      returnValue = false;
    }
    return returnValue;
  }

  toggleConnection(cloudConnection) {
    if (this.extendedConnections[cloudConnection.id] != true) {
      this.extendedConnections[cloudConnection.id] = true;
    } else {
      this.extendedConnections[cloudConnection.id] = false;
    }
  }

  getProjectUsers() {
    this.externaldatasourceService.executeExternalDataSource(139).then(getProjectUsersResult => {
      this.projectUsers = getProjectUsersResult;
    });
  }

  getUsers() {    
    if (this.commonService.isNullOrUndefined(this.selectedConnection.userGroupId)) {
      this.assignedUsers = [];
      this.availableUsers = [];
    } else {
      this.externaldatasourceService.executeExternalDataSource(96, [this.selectedConnection.userGroupId]).then(userGroupInfo => {
        this.assignedUsers = userGroupInfo.userGroupUsers;
        this.availableUsers = [];
        this.projectUsers.forEach(projectUser => {    
          const isUserAssigned = this.assignedUsers.find(assignedUser => assignedUser.user.id == projectUser.id);
          if (this.commonService.isNullOrUndefined(isUserAssigned)) {
            this.availableUsers.push({ user: projectUser });
          }          
        });
      });
    }        
    this.availableUsers = this.applicationInfoService.projectUsers.toArray().filter(user => user.id != 100);
  }

  addUser(user) {
    const userString = ''.concat('{ userId:' , user.user.id, ' userGroupId: ', this.selectedConnection.userGroupId, '}');
    this.externaldatasourceService.executeExternalDataSource(101, [userString]).then(newUserGroupUser => {
      this.commonService.removeItemFromArray(this.availableUsers, user);
      user['id'] = newUserGroupUser[0].id;
      this.assignedUsers.push(user);
    });
  }

  removeUser(user) {
    this.externaldatasourceService.executeExternalDataSource(102, [user.id]).then(() => {
      this.commonService.removeItemFromArray(this.assignedUsers, user);
      this.availableUsers.push(user);  
    })
  }

  createNewCloudConnection(): Promise<any> {
    return new Promise((createNewCloudConnectionResolve, createNewCloudConnectionReject) => {
      this.createNewConnectionType = 7942;
      this.createNewConnectionProvider = 7944;  

      if (this.isCustomerMode) {
        this.createNewConnectionType = 7942;
        this.createNewConnectionProvider = 7944;  
      }

      let settings = JSON.stringify(this.createNewConnectionSettings);
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(846, [
        this.createNewConnectionName,
        this.createNewConnectionType,
        JSON.stringify(JSON.stringify(this.createNewConnectionOAuthObject)),
        JSON.stringify(settings)
      ]).then(newConnection => {
        this.cloudConnections.unshift(newConnection);
        this.createNewConnection = false;
        this.loaderService.display(false);
        createNewCloudConnectionResolve(null);
      }).catch(() => {
        this.loaderService.display(false);
        createNewCloudConnectionReject(null);
      });
    });
  }

  getSMTPMsoCode(cloudConnectionId) {
    this.loaderService.display(true);
    this.sharedApi.getSMTPMsoCode(cloudConnectionId).subscribe(msoCode => {
      this.loaderService.display(false);
      this.applicationInfoService.miscSettings['msoCode'] = msoCode;
      this.eventService.showJjPopup('CloudConnectionLogin.Label.Header', 'cloudconnectionlogin', '400px;300px');
    });
  }

  checkIfDisabled() {
    let returnValue = false;    
    return returnValue;
  }

  testConnection(cloudConnection) {
    const mailObject = {
      cloudConnectionId: cloudConnection.id,
      to: this.applicationInfoService.user.email,
      subject: 'EVERLEAD Connection Test',
      isHtml: true,
      htmlBody: 'Testing connection: '.concat(cloudConnection.defaultName)
    }
    
    this.loaderService.display(true);
    this.sharedAPI.sendSMTPMail(mailObject).subscribe(
      (mailResult) => {
        this.loaderService.display(false);
        this.messagingService.showDefaultSuccess(cloudConnection.defaultName, 'CloudConnection.Message.TestMailSentSuccessfully');
      },
      (error) => {
        this.loaderService.display(false);
        this.messagingService.showDefaultError(error.statusText, error._body);
      }
    );     
  }
}
