import { AfterContentInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrl: './color-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true
    }
  ]
})
export class ColorInputComponent implements ControlValueAccessor, AfterContentInit{
 

  constructor(){}

  
  @Input() id : string | number | null = null;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() info: string | null = null;
  
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  value: any;

  onChange = (value: any) => {};
  onTouched = () => {};


  ngAfterContentInit(): void {    
/* 
    console.log('value', this.value) */
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  handleInput(value){
    if(!this.disabled){
      this.value = value
    /*   console.log('handle Input value', this.value) */
      this.onChange(this.value);
      this.valueChange.emit(this.value)
      this.onTouched();
    }
  }

}
