import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { trigger, transition, style, animate } from '@angular/animations'
import { Subscription } from 'rxjs';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { SettingsService } from 'app/shared/service/settings.service';


export type Severity= 'error' | 'info' | 'warn' | 'success';

export interface ToastObject{
  severity:Severity;
  summary: string;
  sticky?: any;
  detail:string | any; 
  lightColor?:string;
  color?:string;
  timeoutId?:any;
  id?: number;
}


@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrl: './toasts.component.scss',
  animations:[
    trigger('toastAnimation', [
      transition(':enter', [
        style({transform:'translateX(100%)', opacity:0}),
        animate('300ms ease-out', style({transform:'translateX(0)', opacity:1}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform:'translateX(100%)', opacity:0}))
      ])
    ])

  ]
})

export class ToastsComponent implements OnInit, OnDestroy{
  constructor(
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService,
    private awsCognitoService: AwsCognitoService,
    public settingsService: SettingsService
  ){}

  messageSubscription = new Subscription();
  toastArray=[]

  vipArray = [
    { defaultName: 'UserSettings.Label.NormalVisitorAlertFrequency.None', id: 'none' },
    { defaultName: 'UserSettings.Label.NormalVisitorAlertFrequency.Direct', id: 'direct' },
    { defaultName: 'UserSettings.Label.NormalVisitorAlertFrequency.Daily', id: 'daily' },
    { defaultName: 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly', id: 'weekly' }
  ]

  ngOnInit(){
    this.messageSubscription = this.eventService.toasterEvent.subscribe((event) =>{
      this.addToast(event);
    });
  }

  addToast(event){
    let toast = {
      ...event,
      ...this.getColor(event.severity), 
      id: this.toastArray.length,
      sticky: event.sticky ? event.sticky : false, 
    }
    this.toastArray.push(toast)

    if(toast.sticky === false){
      toast.timeoutId = setTimeout(() =>{
        this.deleteToast(toast);
      }, 5000);


    }

  };


  deleteToast(toast){
    if(toast.timeoutId){
      clearTimeout(toast.timeoutId);
    }
    this.toastArray = this.toastArray.filter((item) => item.id !== toast.id);
  };


  activateToast(toast){
    if(toast.sticky === true){
      return
    } else {
      clearTimeout(toast.timeoutId);
      toast.timeoutId = null;
    }
  }


  deactivateToast(toast){
    if(!toast.sticky){
      toast.timeoutId = setTimeout(() =>{
        this.deleteToast(toast);
      },1000);
  }
}

// todo add to HTML container__inner (mouseenter) if all toast
activateAllToasts(){
  this.toastArray.map((toast) =>{
    if(!toast.sticky && toast.timeoutId){
      clearTimeout(toast.timeoutId);
      toast.timeoutId = null;
    }
  } )
}

// todo add to HTML container__inner (mouseleave) if all toast
deactivateAllToasts(){
  this.toastArray.map((toast) =>{
    if(!toast.sticky){
      if(toast.timeoutId){
        clearTimeout(toast.timeoutId)
      } else {
        toast.timeoutId = setTimeout(() =>{
          this.deleteToast(toast)
        },1000);
      }
    }
  })
}

  getEllipsis(string, toast){    
    if (toast.options != null) {
      return string;
    }

    if(!string){
      return
    }else {
      return  string?.length >= 90 ? string.slice(0, 90) + '...' : string
    }
  }

  getColor(type){
    switch(type){
      case 'info':
        return { lightColor:'#BFD7FF', color: '#5465FF'};
      case 'warn':
        return { lightColor: '#FFFBE6', color: '#FFD600'};
      case 'error':
        return { lightColor: '#FFCBCB', color:'#FF5050'};
      case 'success':
        return { lightColor:'#ECFCEF', color: '#3FE163'};
        default:
        return
    }
  }

  getIcon(severity){
    if(severity !== 'error'){
      return 'info-bold'
    } else {
      return 'warning-bold'
    }
  }

  getStyleForToast(toast){
    return { 
      'backgroundColor': toast.lightColor,
      'borderRight': '5px solid' + toast.color 
    }
  }

  ngOnDestroy(){
    if(this.messageSubscription){
      this.messageSubscription.unsubscribe();
    }
  }

  awsLoginAfterFailedRefresh() {
    if (this.applicationInfoService.user.isExternalUser) {      
      this.applicationInfoService.showAWsLoginPage = true;
      this.applicationInfoService.showBanner = false;
      this.applicationInfoService.projectLoaded = false;
      this.applicationInfoService.projectSelected = false;
      this.applicationInfoService.awsSilentRenewError = false;
      this.applicationInfoService.userID = 0;
      return;
    } else {
      localStorage.removeItem('awstoken');
      localStorage.removeItem('awsrefreshtoken');
      localStorage.removeItem('awsrefreshtoken');
      localStorage.removeItem('awstokenclientid');
      localStorage.removeItem('awstokentokenurl');  
      let initHref = window.location.href;
      initHref = initHref.replace(window.location.origin, '');
      localStorage.setItem('initHref', initHref);
      this.awsCognitoService.showAwsLoginPage();     
    }
  }

  saveVIPChanges(toast) {
    this.settingsService.setUserSettingEx(this.settingsService.userSettings['VipAlertFrequency']);
    this.deleteToast(toast);
  }
}
