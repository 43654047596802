<div class="uiContainerDesign__wrapper">
    <!-- Member -->
    <div class="inline uiContainerDesign__member everleadShadowBox noTopPadding">
        <div class=" uiContainerDesign__member__header">
            <label>{{ 'UiControlDesign.Label.AvailiableDataFields' | texttransform}}</label>
        </div>       
        <select style="width: 100%; margin-bottom: 4px;" [(ngModel)]="selectedEntity">
            <option *ngFor="let availableField of availableEntities" [ngValue]=availableField>
                {{ commonService.getTranslationValueFromArray(availableField, 'entityName') | texttransform }}
            </option>
        </select>   

        <div class="uiContainerDesign__member__content">
            <div class="everleadShadowBox uiContainerDesign__listItem">
                <label class="uiContainerDesignItemAvailableLabel ellipsisText">{{ 'UiControlDesign.Label.AddLabel' | texttransform }}</label>
                <svg-icon src="assets/images/svg/icon-plus+.svg"  class="fingerCursor" (click)="addLabel()" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
            </div>
            <div class="everleadShadowBox uiContainerDesign__listItem verticalSpacer">
                <label class="uiContainerDesignItemAvailableLabel ellipsisText">{{ 'UiControlDesign.Label.SpacerItem' | texttransform }}</label>
                <svg-icon src="assets/images/svg/icon-plus+.svg"  class="fingerCursor" (click)="addSpacer()" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
            </div>            

            <div *ngIf="selectedEntity != null">
                <div class="everleadShadowBox uiContainerDesign__listItem"
                    *ngFor="let item of selectedEntity.entityMembers">
                    <label class="uiContainerDesignItemAvailableLabel ellipsisText" *ngIf="item.nameTranslationTokenId==null"
                        [tippy]="item.defaultName">
                        {{ item.defaultName }}
                    </label>
                    <label class="uiContainerDesignItemAvailableLabel ellipsisText" *ngIf="item.nameTranslationTokenId!=null"
                        [tippy]="axivasTranslateService.getTranslationTextForId(item.nameTranslationTokenId) | texttransform">
                        {{ axivasTranslateService.getTranslationTextForId(item.nameTranslationTokenId, item.defaultName) | texttransform }}
                    </label>
                    <svg-icon src="assets/images/svg/icon-plus+.svg" (click)="addToContainedControls(selectedEntity, item)"
                        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" class="fingerCursor"></svg-icon>
                </div>
            </div>
        </div>
        <div class="uiContainerDesign__member__newmember fingerCursor" (click)="addMember()">                
            <i class="fas fa-plus floatRight "></i>
            <label class="ellipsisText fingerCursor">{{ 'AdminEntity.Label.CreateNewMember' | texttransform }}</label>
        </div>
    </div>




    <!-- Center -->
    <div class="inline dragColumnDiv uiContainerDesign__controls everleadShadowBox noTopPadding" cdkDropList [cdkDropListData]="containerControls"
        (cdkDropListDropped)="moveInList($event, '0')">
        <div class=" uiContainerDesign__controls__header uiContainerDesign__controls__content__spacer">
            <label style="margin: 0px">{{ 'UiControlDesign.Label.FormView' | texttransform}}</label>
            <div>
                <i class="fas fa-save fingerCursor" (click)="saveSorting()" *ngIf="applicationInfoService.isDeveloper"></i>    
                <i class="fas fa-plus-square fingerCursor" (click)="addToContainedControls()" *ngIf="applicationInfoService.isDeveloper"></i>
                <i class="fas fa-refresh fingerCursor" (click)="refreshContainer()" *ngIf="applicationInfoService.isDeveloper"></i>    
            </div>            
        </div>

        <div class="uiContainerDesign__controls__content ">
            <div class="uiContainerDesign__containerItem__wrapper"
                *ngFor="let containerControl of containerControls" cdkDrag [cdkDragData]="item" data-html="true"
                [ngClass]="{ 
                    noHeight: !containerControl?.control?.isActive && !applicationInfoService.isDeveloper 
                }"
                (mouseenter)="setHoverId(containerControl.control.logicalControlId)"
                (mouseleave)="setHoverId(null)"                 
            >
                <div class="everleadShadowBox uiContainerDesign__containerItem">                  
                <ng-container *ngIf="containerControl.control" >
                    <div class="uiContainerDesign__containerItem__isActive">
                        <label class="ellipsisText everleadSmallHeader">
                            <input type="checkbox" [(ngModel)]=containerControl.control.isActive (change)="saveControl(containerControl.control)" 
                                class="uiContainerDesign__checkbox" *ngIf="applicationInfoService.isDeveloper">
                            
                            <span *ngIf="containerControl.control.controlType != 17 &&
                                         containerControl.control.controlType != null &&
                                         containerControl.control.controlType != 0 &&
                                         containerControl.control.controlType != 8
                            ">
                                {{ getControlLabel(containerControl.control) | texttransform }}
                            </span>

                            <label *ngIf="containerControl.control.controlType == 17" class="ellipsisText">
                                {{ 'UiControlDesign.Label.SpacerItem' | texttransform }}</label>
                            <label *ngIf="containerControl.control.controlType == null || containerControl.control.controlType == 0" 
                                class="ellipsisText">{{ 'UiControlDesign.Label.Container' | texttransform }}</label>                                
                            <input *ngIf="containerControl.control.controlType == 8" [placeholder]="'UiControlDesign.Label.FreeText' | texttransform" 
                                (change)="saveControl(containerControl.control)" class="ellipsisText freeTextInput" [(ngModel)]="containerControl.control.displayText">                                                
                        </label>
                    </div>
                    <div class="uiContainerDesign__containerItem__largeSelect" *ngIf="applicationInfoService.isDeveloper">
                        <select [(ngModel)]="containerControl.control.controlType" *ngIf="controlTypeOptions != null"
                            (change)="saveControl(containerControl.control)">
                            <option *ngFor="let key of controlTypeOptions" [ngValue]=key.id>{{ key.value }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="uiContainerDesign__containerItem__grid" title="width" 
                        *ngIf="!applicationInfoService.isDeveloper && 
                                containerControl.control.controlType != 17"
                    >
                        <svg-icon class="fingerCursor" src="assets/images/grid/grid33.svg" title="33%" [ngClass]="{ everleadCoral: containerControl.control.width == '33%' }"  [svgStyle]="{ 'width.px': 30, 'height.px': 24 }" (click)="setControlWidth(containerControl.control, '33%')"></svg-icon>
                        <svg-icon class="fingerCursor" src="assets/images/grid/grid50.svg" title="50%" [ngClass]="{ everleadCoral: containerControl.control.width == '50%' }"  [svgStyle]="{ 'width.px': 30, 'height.px': 24 }" (click)="setControlWidth(containerControl.control, '50%')"></svg-icon>
                        <svg-icon class="fingerCursor" src="assets/images/grid/grid66.svg" title="66%" [ngClass]="{ everleadCoral: containerControl.control.width == '66%' }"  [svgStyle]="{ 'width.px': 30, 'height.px': 24 }" (click)="setControlWidth(containerControl.control, '66%')"></svg-icon>
                        <svg-icon class="fingerCursor" src="assets/images/grid/grid100.svg" title="100%" [ngClass]="{ everleadCoral: containerControl.control.width == '100%' }"  [svgStyle]="{ 'width.px': 30, 'height.px': 24 }" (click)="setControlWidth(containerControl.control, '100%')"></svg-icon>
                    </div>
                    <div class="uiContainerDesignContainerProject" *ngIf="applicationInfoService.isDeveloper">
                        <select [(ngModel)]="containerControl.projectId" (change)="changeContainerControlProject(containerControl)"
                            tippy="Das Projekt der UiContainer-UiControl - Verbindung. Wichtig, nicht das Projekt des eigentlichen UIControls, das stellt man in den Details des Controls ein">
                            <option [ngValue]="null">Alle</option>
                            <option [ngValue]="project.project.id" *ngFor="let project of projects">{{ project.project.projectName }}</option>
                        </select>
                    </div>
                    <div class="uiContainerDesign__containerItem__width" title="width">
                        <select [(ngModel)]="containerControl.control.width" *ngIf="controlTypeOptions != null"
                            (change)="saveControl(containerControl.control)">
                            <option [ngValue]=null></option>
                            <option value='10%'>10%</option>
                            <option value='20%'>20%</option>
                            <option value='33%'>33%</option>
                            <option value='34%'>34%</option>
                            <option value='50%'>50%</option>
                            <option value='67%'>67%</option>
                            <option value='80%'>80%</option>
                            <option value='90%'>90%</option>
                            <option value='100%'>100%</option>
                        </select>
                    </div>
                </ng-container>
                <div class="uiContainerDesign__containerItem__delete">
                    <i class="fas fa-edit fingerCursor" (click)="editControlLight(containerControl.control)"
                        *ngIf="applicationInfoService.isDeveloper" style="margin-left: 5px;"></i>
                    <i class="fas fa-trash everleadCoral fingerCursor" *ngIf="canRemoveItem(containerControl)" (click)="removeItem(containerControl)"></i>
                    <i class="fas fa-grip-lines fingerCursor" cdkDragHandle></i>                
                </div>
            </div>
            <div *ngIf="containerControl.control && containerControl.control.controlType != 17" class="uiContainerDesign__containerItem__additional">
                <app-wizard-translations defaultValue="displayText" [array]="containerControl.control" updateBaseArrayExternaldatasourceId=161 mode="3"></app-wizard-translations>
                <label class="everleadSmallHeader">{{ 'UiControlDesign.Label.TranslateThisControl' | texttransform}}</label>
            </div>
    
            </div>        
        </div>
    </div>



    <!-- Preview -->
    <div class="uiContainerDesign__preview maxHeight noTopPadding" [ngClass]="{
        'uiContainerDesign__preview__expanded': expanded ,
        'everleadShadowBox': expanded
    }">
        <div class="uiContainerDesign__preview__content__overlay"></div>
        <div class=" uiContainerDesign__controls__header fingerCursor" *ngIf="expanded" (click)="toggleView()">
            <label *ngIf="expanded" style="margin: 0px">{{ 'UiControlDesign.Label.Preview' | texttransform}}</label>
            <i class="fa-lg fas " [ngClass]="{ 'fa-arrow-alt-circle-left': !expanded, 'fa-arrow-alt-circle-right': expanded }"></i>    
        </div>
            <div *ngIf="!expanded" class="uiContainerDesign__preview__notexpanded fingerCursor everleadShadowBox" (click)="toggleView()">
                <i class="fa-lg fas " [ngClass]="{
                    'fa-arrow-alt-circle-left': !expanded,
                    'fa-arrow-alt-circle-right': expanded
                }"></i>    
                <label>{{ 'UiControlDesign.Label.Preview' | texttransform}}</label>
            </div>
            <div *ngIf="expanded" class="uiContainerDesign__preview__content" (click)="$event.stopPropagation()">                            
                <ng-container *ngFor="let containerControl of containerControls" class="containerContent" >
                    <app-custom-universal [controlid]=containerControl?.uiControlLogicalId ignoreDesigner="true"
                        *ngIf=" applicationInfoService.controls.Item(containerControl.uiControlLogicalId)?.controlType != null &&
                                                applicationInfoService.controls.Item(containerControl.uiControlLogicalId)?.controlType != 0"></app-custom-universal>
                    <app-custom-container [controluiid]=containerControl?.uiControlLogicalId
                        *ngIf=" applicationInfoService.controls.Item(containerControl.uiControlLogicalId)?.controlType == null ||
                                                applicationInfoService.controls.Item(containerControl.uiControlLogicalId)?.controlType == 0">
                    </app-custom-container>
                </ng-container>
        </div>
    </div>
</div>