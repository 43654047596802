import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-defaultdashboard-activity',
  templateUrl: './defaultdashboard-activity.component.html',
  styleUrl: './defaultdashboard-activity.component.scss'
})
export class DefaultdashboardActivityComponent implements OnInit {
  @Input() reports = null;
  @Input() selectedReport = null;
  
  dashboardResultData = [];
  reportParameterValues = [];
  paramterExternalDataSourceResults = [];
  projectId = null;
  projectOptions=[];
  reportTemplate = null;
  poolId = null;
  poolOptions=[]

  campaignId = null;
  campaignOptions=[];
  
  stepId = 0;

  onlyLeads = false;
  languageId = 2;
  languageOptions=[{id:2, label:'English'}, {id:1, label:'German'}]
  
  reportType = 'Inside Sales';
  //reportType = 0;
  reportTypeOptions=['Inside Sales', 'Digital'];
  //reportTypeOptions= [{label:'Inside Sales', id:0}, {label:'Digital', id:1}]
  tab=1;
  dashboardId
  allStepsData=[];
  stepsOptions=[];
  selectedData=[];
  step;
  viewChart: boolean = true;
  loading= false;
  nodata= true;

  alldataArray=[];
  openFilter = true;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public dashboardcoreService: DashboardcoreService,
    private commonService: CommonService,
    public userService: UserService,
    private externaldatasourceService: ExternaldatasourceService,
    private uiService:UiService,
  ) {}

  ngOnInit(): void {
    this.poolId = this.applicationInfoService.poolId;
    this.projectId = this.applicationInfoService.projectID;
    this.getAllParams();
    this.initReportTemplate(this.selectedReport.reportId);    
  }


  getAllParams(){
    this.getProjects();
    this.languageId = this.applicationInfoService.languageId;    
  }

  getDashboardDataArray(dashboardId) {    
    this.loading= true
    this.nodata=false;
    let params = this.fillParams();
    this.dashboardResultData[dashboardId] = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {
      this.dashboardResultData[dashboardId] = null;
      if (getDashboardDataArrayResult[0]) {
        if (getDashboardDataArrayResult[0].length > 0) {
          this.loading = false;
          this.dashboardId= dashboardId;
          this.dashboardResultData[dashboardId] = JSON.parse(JSON.stringify(getDashboardDataArrayResult[0]));      
          this.createActivityData();
    
        } else {
          this.nodata = true;
          this.loading = false;
        } 


      } else {
        this.nodata = true;
        this.loading = false;
      }
    }).catch((error)=>{
      console.log(error);
      this.nodata = true;
      this.loading = false;
    });
  }

  fillParams() {   
    const params = [];
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@only_leads', value: this.onlyLeads ?  1 : 0 });
    params.push({ key: '@step_id', value: this.stepId });    
    params.push({ key: '@language_id', value: this.applicationInfoService.languageId });    
    this.reportTemplate.reportParameters.forEach(parameter => {
      if (parameter.defaultName != 'language_id') {
        if (this.commonService.isNullOrUndefined(this.reportParameterValues[parameter.defaultName])) {
          params.push({ key: '@'.concat(parameter.defaultName), value: null });
          params.push({ key: '@'.concat(parameter.id), value: null });
        } else {
          params.push({ key: '@'.concat(parameter.defaultName), value: this.reportParameterValues[parameter.defaultName] });
          params.push({ key: '@'.concat(parameter.id), value: this.reportParameterValues[parameter.defaultName] });
        }  
      }
    });
    return params;
  }

  createActivityData(){
    this.step=null
    const options =[]
    this.dashboardResultData[this.dashboardId].map((step) =>{
      const find = options.find((item)=> item.step === step.step);
      if(!find){
        options.push({id:step.step_id, step:step.step})
      } 
    })

    this.stepsOptions= options
    this.createAllStepsData();


  }

  createAllStepsData(){
    const array = [];
     this.stepsOptions.map((step) => {
      const stepGroup = this.dashboardResultData[this.dashboardId].filter((item) => item.step_id === step.id)
      if(stepGroup.length > 0){
        const group = this.dashboardcoreService.getPercentagefromArray(stepGroup, 'anzahl', 'percentage', 1);
        array.push(group);
      }
     }) 
     this.selectedData = array;
  }

  onSelectChange(){
    if(this.step === null){
      this.createAllStepsData()
    } else {
      this.selectedData = this.dashboardResultData[this.dashboardId].filter((item) => item.step_id === this.step)

    }
  }


  getBestTableHeight(array) {
    if (this.commonService.isNullOrUndefined(array)) {
      return 0;
    }
    let height = 50 + (array.length * 40);
    if (height < 140) { height = 140; }
    return height;
  }


  getPools(){
    this.poolOptions = this.applicationInfoService.pools.toArray();
    if ( this.applicationInfoService.poolId) {
      this.poolId = this.applicationInfoService.poolId;
    } else {
      this.poolId = this.poolOptions[0]?.id;
    }
  }

  getCampaigns() {
    this.campaignOptions = this.applicationInfoService.campaigns.toArray();
    if (this.applicationInfoService.campaingnModeId) {
      this.campaignId = this.applicationInfoService.campaingnModeId;
    } else {
      this.campaignId = this.campaignOptions[0]?.id;
    }
  }


  getProjects(){
    this.projectOptions = this.getFilteredProjects(this.applicationInfoService.user.userProjects).map((item) =>{
      return{
        ...item,
        id:item.projectId,        
        projectName: item.project.projectName
      }
    }); 
   }


  getFilteredProjects(array){
    return array.reduce((acc, current) => {
      const projectName = current.project.projectName;
      if (!acc.find(item => item.project.projectName === projectName)) {
          acc.push(current);
      }
      return acc;
  }, []);
  }

  initReportTemplate(reportId) {
    this.reportParameterValues = [];
    this.paramterExternalDataSourceResults = [];
    const template = this.reports.find(report => report.id == reportId);
    if (template) {
      this.reportTemplate = template;
    } else {
      return;
    }
    this.reportTemplate.reportParameters.sort((a, b) => a['order'] < b['order'] ? -1 : 1);
    const promiseArray: Promise<any>[] = [];
    this.reportTemplate.reportParameters.forEach(reportParameter => {      
      if (!this.commonService.isNullOrUndefined(reportParameter.value)) {        
        switch (reportParameter.parameterTypeLookupId) {
          case 16:
            this.reportParameterValues[reportParameter.defaultName] = Number(reportParameter.value);
            break;
          case 104:
            this.reportParameterValues[reportParameter.defaultName] = JSON.parse(reportParameter.value);
            break;
          default:[0]
            this.reportParameterValues[reportParameter.defaultName] = reportParameter.value;
            break;
        }
      }
      if (!this.commonService.isNullOrUndefined(reportParameter.externalDataSource)) {
        promiseArray.push(this.externaldatasourceService.executeExternalDataSource(reportParameter.externalDataSource.id, [])
          .then(externalDataSourceResult => {
            this.paramterExternalDataSourceResults[reportParameter.externalDataSource.id] = externalDataSourceResult;
            if ( externalDataSourceResult.length > 0 && 
                 this.commonService.isNullOrUndefined(reportParameter.defaultValue) &&
                 reportParameter.defaultName != 'user_id'
            ) {
              this.reportParameterValues[reportParameter.defaultName] = externalDataSourceResult[0][reportParameter.externalDataSource.dataKey];
            }           
            if (reportParameter.defaultName == 'language_id') { this.reportParameterValues[reportParameter.defaultName] = this.applicationInfoService.languageId; }
         })
         .catch(error => {
          console.error(error);
        }));
      } else {
        if (reportParameter.defaultName == 'language_id') { this.reportParameterValues[reportParameter.defaultName] = this.applicationInfoService.languageId; }  
      }
    });    
    Promise.all(promiseArray).then(() => {
      this.getDashboardDataArray(17);
    });
  }


  getMaxHeight(additionalValueToSubstractFromHeight){
    return Number(this.uiService.getDesignSpanPositionMobile('defaultDashboardActivity', (50) + additionalValueToSubstractFromHeight))
  }
}
