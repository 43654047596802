<div class="everleadTextField" [class.disabled]="disabled" [class.error]="error">
    <label class="everleadTextField__label" *ngIf="label" [class.disabled]="disabled" [class.active]="isFocused && !disabled">
        {{ label | texttransform }}
        <app-wizard-infobutton *ngIf="info" [displayText]="info" [width]="17" [height]="17"></app-wizard-infobutton>
    </label>
    <div class="everleadTextField__value"[class.disabled]="disabled" [class.error]="error" [class.active]="isFocused && !disabled">
        <app-jj-icons *ngIf="type=== 'password'" [type]="isOpen ? 'eye': 'eye-slash'" (click)="isOpen= !isOpen" [width]="20" [height]="20"></app-jj-icons>
        <input 
            *ngIf="fire"
            [id]="id" 
            [type]="type === 'text' ? type : toggleType()"
            [placeholder]="placeholder | texttransform" 
            [(ngModel)]="value"
            (ngModelChange)="handleInput(value)"            
            (keydown)="handleKeydown($event)"
            (focus)="handleFocus()"
            (blur)="handleBlur()"
            [disabled]="disabled">

            <input 
            *ngIf="!fire"
            [id]="id" 
            [type]="type === 'text' ? type : toggleType()"
            [placeholder]="placeholder | texttransform" 
            [(ngModel)]="value"
            (keydown)="handleKeydown($event)"
            (focus)="handleFocus()"
            (blur)="handleBlur()"
            (change)="handleInput($event.target.value)"            
            [disabled]="disabled">

    </div>
</div>
