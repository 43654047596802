<div class="processvisualFirstlayer">
<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>

<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>

<span id="processvisual"></span>
<div *ngIf="featureNotFound == false">

    <div class="processVisual">
        <div class="processVisual__header">
            <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>
        
            <app-select-input
                *ngIf="!applicationInfoService.isMobile()" 
                [(ngModel)]="applicationInfoService.selectedProcessVisualWorkflow"
                (valueChange)="showWorkflowDetails($event)"
                bind="id"
                [options]="applicationInfoService.workflows.toArray()"
                display="defaultName"
                label="Campaigns"
                [fire]="true"
                ></app-select-input>
        </div>
        <section class="processVisual__wrapper">
         
        <div class="processVisual__inner" [style.height.px]="getContentHeight()">
          
            <div class="processVisual__inner__message" *ngIf="applicationInfoService.isMobile()">
                <app-jj-icons type="warning" [width]="40" [height]="40"></app-jj-icons>
                {{ 'General.Label.forDesktopOnly' | texttransform }}
            </div>
          
            
            <div  *ngIf="!applicationInfoService.isMobile()" [ngClass]="{hideInDom: (applicationInfoService.selectedProcessVisualWorkflow != 0) }">
                <span *ngIf="loading == true">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
                </span>
                <div *ngIf="loading == false" class="processVisual__imagePlaceholder">
                    <img src="assets/images/flowchart/shadowFlowChart.png"/>
                </div>
            </div>
            <div  *ngIf="!applicationInfoService.isMobile()" [ngClass]="{ hideInDom: applicationInfoService.selectedProcessVisualWorkflow == 0 }" class="processVisual__flowchartContainer">
                <app-designer-flowchart 
                [chartData]="workflowObject" 
                [controlid]="flowChartId"
                [initialTreeDepth]="2"
                [treeWidth]="'65%'"
                [treeHeight]="'70%'"
                [height]="'100%'"
                [width]="'100%'"
                [top]="'10%'"
                [toolboxInfo]="true"
                [toolboxExpand]="false"
                ></app-designer-flowchart>       
            </div>
        </div>
    </section>
    </div>
</div>
</div>

