import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UiService } from '../../../service/ui.service';
import { EventService, JJEvent } from '../../../service/event.service';
import { Subscription } from 'rxjs';
import { ApplicationInfoService, VariableType } from '../../../../core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { isNullOrUndefined } from 'util';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-custom-container',
  templateUrl: './custom-container.component.html',
  styleUrls: ['./custom-container.component.scss']
})
export class CustomcontainerComponent implements OnInit, OnDestroy {
  loadNewContainerContentEventSubscription: Subscription;
  showNewContentEventSubscription: Subscription;
  updateControlAttributesEventSubscription: Subscription;
  refreshContentEventEventSubscription: Subscription;
  containerControls = [];

  isVisible = true;
  public minimizedView = false;
  public showToggleHeader = false;
  public innerHTML = null;
  public controlDefinition: any = null;
  getUiContent = false;

  // Mobile
  @Input() inititalid = '';
  @Input() controluiid = '0';
  @Input() width = '';
  @Input() height = '';
  @Input() isinline = 0;
  @Input() additionalClass = '';

  constructor(
      private domSanitizer: DomSanitizer
    , public userService: UserService
    , private methodService: MethodService
    , private uiService: UiService
    , private designerService: DesignerService
    , private eventService: EventService
    , private commonService: CommonService
    , public applicationInfoService: ApplicationInfoService
    , private externaldatasourceService: ExternaldatasourceService
  ) {}

  ngOnInit() {
    this.controlDefinition = this.applicationInfoService.controls.Item(this.controluiid);
    //console.log('custom-container : control definition', this.controlDefinition);

    if (this.inititalid === '') { this.inititalid = this.controluiid; }
    this.loadNewContainerContentEventSubscription = this.eventService.loadNewContainerContentEvent.subscribe(event => {
      if (this.inititalid.toString() === event.target) {        
        this.controluiid = event.arguments[0];
        this.controlDefinition = this.applicationInfoService.controls.Item(this.controluiid);
        // this.getUiContent = true;
        // this.getContainerControls();
        // this.uiService.getUIfromID(event.arguments[0], this.inititalid)
        // .then(() => { this.getUiContent = false; })
        // .catch(error => { console.log('CustomcontainerComponent', error); this.getUiContent = false; });
        this.getControlContent();
      }
    });

    this.updateControlAttributesEventSubscription = this.eventService.updateControlAttributesEvent.subscribe(() => {
      if (this.controlDefinition) {
        this.checkAttributes();
      }
    });

    this.refreshContentEventEventSubscription = this.eventService.refreshContentEvent.subscribe(event => {
      if (!this.commonService.isNullOrUndefined(this.controlDefinition)) {
        if (Number(this.controlDefinition.logicalControlId) === Number(event)) {
          this.applicationInfoService.containerData.Remove(this.controlDefinition.logicalControlId);
          this.getControlContent(true);
        }
      }
    });

    this.showNewContentEventSubscription = this.eventService.showNewContentEvent.subscribe(event => {
      if (this.controluiid === event.target) {
        this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(event.arguments[0]);
        this.controlDefinition = this.uiService.getControlDefinitions(this.controluiid);
        if (!isNaN(Number(this.controluiid))) {
          this.uiService.getControlDetails(this.controluiid)
          .then(() => {
            this.controlDefinition = this.uiService.getControlDefinitions(this.controluiid);
            this.checkAttributes();
          })
          .catch(error => { 
            if (this.applicationInfoService.applicationSettings['showDebugMessages'] == 'true') {
              console.warn(error); 
            }
          });
        }
      }
    });

    this.getControlContent();
    this.checkAttributes();
  }

  getPreloadedContentForContainer(containerId) {
    //console.log('get preload content : this.applicationInfoService', this.applicationInfoService.preloadedControlInfo)
    const data = this.applicationInfoService.preloadedControlInfo.filter((control) => control.uiContainerLogicalId === containerId);
    return data;
  }

  getControlContent(refreshCache = false) {
    if (this.controlDefinition) {
      this.getContainerControls(refreshCache);
    } else {
      if (this.controluiid !== '0') {
        this.getUiContent = true;
         this.uiService.getUIfromID(this.controluiid, this.inititalid)
         .then(() => {
            this.getUiContent = false;
         });
      }      
    }
  }

  ngOnDestroy() {
    if (this.loadNewContainerContentEventSubscription) { this.loadNewContainerContentEventSubscription.unsubscribe(); }
    if (this.showNewContentEventSubscription) { this.showNewContentEventSubscription.unsubscribe(); }
    if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }
    if (this.refreshContentEventEventSubscription) { this.refreshContentEventEventSubscription.unsubscribe(); }
  }

  designElement() {
    this.designerService.currentControl = this.controlDefinition;
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['', 'uicontainer-design', '80', true]);
  }

  checkVisibility(): boolean {
    this.checkAttributes();
    return this.isVisible;
  }

  toggleContainer() {
    this.minimizedView = !this.minimizedView;
  }

  checkAttributes() {
    if (this.controlDefinition) {
      this.isVisible = this.uiService.checkAttributeChanges('visible', this.controlDefinition, true, VariableType.Boolean);
      if (this.controlDefinition.isActive === false) {
        this.isVisible = false;
      }
    }
  }

  GetContainerCSS() {
    let containerCSS = '';
    if (this.controlDefinition) {
      if (this.controlDefinition.additionalCssClassesMainControl) {
        containerCSS = containerCSS.concat(this.controlDefinition.additionalCssClassesMainControl);
      }
    }
    containerCSS = containerCSS.concat(' ', this.additionalClass);
    return containerCSS;
  }

  GetContainerSubCSS() {
    let containerCSS = ' ';
    if (this.controlDefinition) {
      if (this.controlDefinition.additionalCssClasses) {
        containerCSS = containerCSS.concat(this.controlDefinition.additionalCssClassesMainControl);
      }
    }
    return containerCSS;
  }

  containerStyle() {
    this.checkAttributes();
    if (this.controlDefinition) {
      let controlHeight = this.controlDefinition.height;
      let controlWidth = this.controlDefinition.width;
      if (controlWidth === '0' || controlWidth == null) { controlWidth = '100%'; }
      if (controlHeight == null || controlHeight === '0' || this.applicationInfoService.designerMode) {
        controlHeight = '100%';
      }

      // Sonderfall Übergang zum neuen Design
      if (this.applicationInfoService.useNewDesign) {
        if ((this.controlDefinition.logicalControlId === 1300 || this.controlDefinition.logicalControlId === 1400)) {
          controlWidth = '100%';
        }
        // dconsole.warn('XAA', this.applicationInfoService.useNewDesign, this.controlDefinition, this.controlDefinition.logicalControlId)
      }
      
      if (this.applicationInfoService.isMobile()) {
        return {
          'height': controlHeight,
          'width': '100%',
          'max-height': controlHeight,
          'padding': '1px',
          'max-width': '100%',
        };
      } else {
        return {
          'height': controlHeight,
          'width': controlWidth,
          'max-height': controlHeight,
          'max-width': '100%',
        };
      }
    }
  }

  infoAboutElement() {
 /*    console.log('Info about element',
    this.controlDefinition,
    'Backup:',
    this.applicationInfoService.controlsBackUp.Item(this.controlDefinition.logicalControlId)); */
  }

  undoChanges() {
    this.controlDefinition = this.commonService.createCopyOfArray(
      (this.applicationInfoService.controlsBackUp.Item(this.controlDefinition.logicalControlId)));
    this.eventService.updateControlDefinition.emit(
       this.applicationInfoService.controlsBackUp.Item(this.controlDefinition.logicalControlId));
  }

  getContainerControls(refreshCache) {    
    if (this.controlDefinition == null) {
      return;
    }
    if (this.applicationInfoService.projectID == 0) {
      return;
    }
    let externalDataSourceId = 609;
    if (refreshCache) {
      externalDataSourceId = 616;
    }

    const preload = this.getPreloadedContentForContainer(this.controlDefinition.logicalControlId);
    //console.log('preload', preload); 

    if (preload.length > 0) {
      this.organizeContainerControls(preload);
      return;
    }

    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [this.controlDefinition.logicalControlId], null, refreshCache)
    .then(result => { 
      //console.log('result',result)
      this.organizeContainerControls(result);
    })
  }

  organizeContainerControls(controls) {
    controls.forEach(resultItem => {
      resultItem['control'] = this.applicationInfoService.controls.Item(resultItem.uiControlLogicalId);
      if (this.commonService.isNullOrUndefined(resultItem.order)) {
        if (this.applicationInfoService.controls.Item(resultItem.uiControlLogicalId)) {
          resultItem.order = this.applicationInfoService.controls.Item(resultItem.uiControlLogicalId).order;
          resultItem.orderAdded = true;
        }                    
      }
    });
    controls.sort((a, b) => a.order < b.order ? -1 : 1);
    this.containerControls = controls; 
  }

  userCanSeeDesigner() {
    let returnValue = false;
    if (this.applicationInfoService.containerDesignerMode == true && this.controlDefinition) {
      if (this.applicationInfoService.isDeveloper) {
        returnValue = true;
      } else {
        if (this.controlDefinition.uiEntityInstanceId) {
          return true;
        }
      }
    }
    return returnValue;
  }

  onScroll(event, from?:string) {
    let referenceHeight = event.target.scrollHeight - event.target.offsetHeight;
    referenceHeight = referenceHeight - 60;
    if( event.target.scrollTop >= (referenceHeight)) {
      if (this.controlDefinition == null) {
        this.eventService.customEvent.emit({ id: 'scrolledToBottom' });
      }      
    }
  }

  editContainer() {
    this.applicationInfoService.miscSettings['controlEditLightControl'] = this.controlDefinition;
    this.applicationInfoService.miscSettings['controlEditLightControlMode'] = 2; 
    this.eventService.showJjPopup('UIControlSettings.Label.Designer', 'controleditlight', '1400px;800px');
  }
}
