<span id="designSpan"></span>

<app-layout header="Website-integration">

    <div class="ajTracker__outer">
        <div class="ajTracker__nav">
            <div class="ajTracker__tabs">
                <app-scroll-tabs>
                    <app-tab [active]="section === 0" label="AJTRACKER.Label.AccountId" (onClick)="section = 0"></app-tab>
                    <app-tab [active]="section === 1" label="AJTracker.Label.Common" (onClick)="section = 1"></app-tab>
                    <app-tab [active]="section === 2" label="AJTracker.Label.Implementation"
                        (onClick)="section = 2"></app-tab>
                    <app-tab [active]="section === 3" label="AJTracker.Label.Usage" (onClick)="section = 3"></app-tab>
                    <app-tab [active]="section === 4" label="AJTracker.Label.API" (onClick)="section = 4"></app-tab>
                    <app-tab [active]="section === 5" label="AJTracker.Label.Downloads" (onClick)="section = 5"></app-tab>
                </app-scroll-tabs>

            </div>
            <div class="ajTracker__tabsMobile">
                <div class="everleadMobileTab">
                    <div class="everleadMobileTab__valueWrapper">
                        <select [(ngModel)]="section" class="selectTab__box">
                            <option [ngValue]="0">{{'AJTRACKER.Label.AccountId' | texttransform }}</option>
                            <option [ngValue]="1">{{'AJTracker.Label.Common' | texttransform }}</option>
                            <option [ngValue]="2">{{'AJTracker.Label.Implementation' | texttransform }}</option>
                            <option [ngValue]="3">{{'AJTracker.Label.Usage' | texttransform }}</option>
                            <option [ngValue]="4">{{'AJTracker.Label.API' | texttransform }}</option>
                            <option [ngValue]="5">{{'AJTracker.Label.Downloads' | texttransform }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="ajTracker__content" [style.height]="getContentHeight()">
            <div *ngIf="section === 0">
                <app-layout header="AJTracker.Label.YourDMCAccountId">
                    <div *ngIf="applicationInfoService.currentProject.dmcAccountId">
                        <div class="ajTrackerHTML">{{ applicationInfoService.currentProject.dmcAccountId }}</div>
                        <div>{{ 'AJTracker.Label.YourDMCAccountIdDescription' | texttransform }}</div>
                        <div>{{ 'AJTracker.Label.ScriptAlreadyUpdated' | texttransform }}</div>
                        <br>
                        <app-main-button type="borderSmall" svg="copy" label="AJTracker.Label.CopyScriptToClipboard" (onClick)="copyScriptToClipboard()"></app-main-button>
                 <!--        <div (click)="copyScriptToClipboard()"
                            *ngIf="applicationInfoService.currentProject.dmcAccountId"
                            class="ajTracker__content__dmccontainer__clipboard">
                            <app-jj-icons type="copy" width="20" height="20"></app-jj-icons>
                            <i class="fas fa-copy"></i>
                            {{ 'AJTracker.Label.CopyScriptToClipboard' | texttransform }}
                        </div> -->
                        <br>
                    </div>

                    <div *ngIf="!applicationInfoService.currentProject.dmcAccountId">
                        {{ 'AJTracker.Label.YourDMCAccountIdNotYetAssigned' | texttransform }}
                    </div>
                </app-layout>
            </div>

            <div *ngIf="section === 1">
                <app-layout header="AJTracker.Label.Common">
                    <label [innerHTML]="innerHTMLAJTrackerCommon"></label>
                </app-layout>
            </div>

            <div *ngIf="section === 2">
                <app-layout header="AJTracker.Label.Implementation">
                    <label [innerHTML]="innerHTMLAJTrackerImplementation"></label>
                    <br>
                    <app-main-button *ngIf="applicationInfoService.currentProject.dmcAccountId" type="borderSmall" svg="copy" label="AJTracker.Label.CopyScriptToClipboard" (onClick)="copyScriptToClipboard()"></app-main-button>

                </app-layout>
<!--                 <label [innerHTML]="innerHTMLAJTrackerImplementation"></label>
                <br>
                <app-main-button *ngIf="applicationInfoService.currentProject.dmcAccountId" type="borderSmall" svg="copy" label="AJTracker.Label.CopyScriptToClipboard" (onClick)="copyScriptToClipboard()"></app-main-button> -->
       <!--          <div (click)="copyScriptToClipboard()" *ngIf="applicationInfoService.currentProject.dmcAccountId"
                    class="ajTracker__content__dmccontainer__clipboard">
                    <i class="fas fa-copy"></i>
                    {{ 'AJTracker.Label.CopyScriptToClipboard' | texttransform }}
                </div> -->
            </div>

            <div *ngIf="section === 3">
                <app-layout header="AJTracker.Label.Usage" subHeader="AJTracker.Label.FootNote">
                    <label [innerHTML]="innerHTMLAJTrackerUsage"></label>
                </app-layout>
            <!--     <div class="ajTracker__footNote">
                    <app-jj-icons type="info" color="secondary" width="20" height="20"></app-jj-icons>
                    {{ 'AJTracker.Label.FootNote' | texttransform }}
                </div> -->
           
            </div>

            <div *ngIf="section === 4">
                <app-layout header="AJTracker.Label.API" subheader="AJTracker.Label.FootNote">
               <!--      <div class="ajTracker__footNote">
                        <app-jj-icons type="info" color="secondary" width="20" height="20"></app-jj-icons>
                        {{ 'AJTracker.Label.FootNote' | texttransform }}
                    </div> -->
                    <div [innerHTML]="innerHTMLAJTrackerAPI"></div>
                </app-layout>
     
            </div>

            <div *ngIf="section === 5">
                <app-layout header="AJTracker.Label.Downloads">
                    <div>{{ 'AJTracker.Label.DownloadsDescription' | texttransform }}</div>
                    <br>
                    <a class="ajTracker__download" download
                        href="../../../../../../assets/documents/AJ Tracker_de_v2.pdf">
                        <app-jj-icons type="file-pdf" width="20" height="20"></app-jj-icons>
                        {{ 'AJTracker.Label.DocumentationGerman' | texttransform }}
                    </a>
                    <a class="ajTracker__download" download
                        href="../../../../../../assets/documents/AJ Tracker_en_v2.pdf">
                        <app-jj-icons type="file-pdf" width="20" height="20"></app-jj-icons>
                        {{ 'AJTracker.Label.DocumentationEnglish' | texttransform }}
                    </a>
                </app-layout>
            </div>

        </div>
    </div>
</app-layout>

