import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Component({
  selector: 'app-leadmanagementdashboard',
  templateUrl: './leadmanagementdashboard.component.html',
  styleUrls: ['./leadmanagementdashboard.component.scss']
})
export class LeadmanagementdashboardComponent implements OnInit {
  featureId = 9;
  featureNotFound = null;

  variable='puzzle-piece';

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private packagefeatureService: PackagefeatureService
  ) { }

  ngOnInit(): void {
  }

}
