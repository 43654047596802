import {  Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { MessagePosition, MessageSeverity, MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { OpportunityService } from 'app/jollyjupiter/service/opportunity.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { ApolloMethod, GraphQLService, JJApolloClient } from 'app/shared/service/graphql.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnInit, OnDestroy {
  controlMode = 1;
    // 1 BookTask
    // 2 CreateTask
    // 3 EditTask
    // 4 SingleAction
    microCampaignToStart = null;

  public innerHTMLAdd1 = null;
  innerHTMLContentAdd1 = '<jj-container controluiid="63985" isinline="1"> </jj-container>';
  public innerHTMLAdd2 = null;
  innerHTMLContentAdd2 = '<jj-container controluiid="63986" isinline="1"> </jj-container>';
  public innerHTMLAdd3 = null;
  innerHTMLContentAdd3 = '<jj-container controluiid="63987" isinline="1"> </jj-container>';
  public innerHTMLAdd4 = null;
  innerHTMLContentAdd4 = '<jj-container controluiid="63988" isinline="1"> </jj-container>';
  createTaskCount = 0;
  createTaskList = null;
  entityId = '15';
  page = 1;
  taskCreationGuid = '';
  campaigns = [];
  workflows = [];
  steps = [];
  results = [];
  contacts = [];
  users = [];
  bookingPreview = null;
  bookingMailMode = 0;
  selectedResult = null;
  innerHtmlPreview = '';
  deleteTaskUserRightName = '';
  innerHTMLEMailPreview = null;
  externalDataSource = null;
  additionalTaskInformation = null;
  bookingWorkflowTypeId = null;
  selectedTemplate = null;
  showSendMailContainer = false;
  preventStepChange = false;
  testMailUser = null;
  testMailAdress = '';
  testMailContact = null;
  controlDefinition = { height: null, width: "100%", uiEntityInstanceId: '15', value: "subResults"
  , controlAttributeModifierGroups: [], methods: [], uiControlExternalDataSources: [], uiControlSecurityPermissions: [], attributeModifiers: []
  }
  fullQuery = `query {
    workflows(onlyNotDeleted: false projectId: <projectId> typeLookupId: <0>) {
      id
      defaultName
      nameTranslationToken{tokenFullName}
      nameTranslationTokenId
      descriptionTranslationToken{tokenFullName}
      descriptionTranslationTokenId
      isActive
      isCallingPerAccount
      projectId
      typeLookupId
      isActive
      workflowSteps {
        isActive
        defaultName
        id
        isProcessStarter
        isSingleAction
        bookTaskAfterXDays
        bookTaskAfterXDaysResultId
        isFollowUp
      }
    }

    projectUsers ( projectId: <projectId> campaignId: <campaingnModeIdNull> ) {
      id
      userName
      email
    }

    campaigns (projectId: <projectId> onlyNotDeleted: true ) {
      id	defaultName nameTranslationToken{tokenFullName} sesConfigSetId
    }
  }`;
  aiReplacementText = null;
  aiGenerating = false;
  bookingContactInfo = null;
  accountSave = null;
  contactSave = null;
  nextStep = null;
  filterArrayResults = [];
  isStartingMicroCampaignShowPreview = false;
  aiStringDisabled

  // Visuals
  showCampaignDropdown = true;
  showProcessDropdown = true;

  constructor(
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private axivasTranslateService: AxivasTranslateService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private eventService: EventService,
    public userService: UserService,
    private projectService: ProjectService,
    private messagingService: MessagingService,
    private methodService: MethodService,
    private domSanitizer: DomSanitizer,
    private emailtemplateService: EmailtemplateService,
    public opportunityService: OpportunityService
  ) { }


  ngOnInit(): void {
    // this.getWorkflows();
    // this.getCampaigns();

    this.getUsers();
    this.createTaskCreationGuid();

    // TODO: GetAll    

    if (this.applicationInfoService.currentAccount) { this.accountSave = JSON.parse(JSON.stringify(this.applicationInfoService.currentAccount)); }
    if (this.applicationInfoService.currentContact) { this.contactSave = JSON.parse(JSON.stringify(this.applicationInfoService.currentContact)); }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['microCampaignContactInfo'])) {
      this.bookingContactInfo = JSON.parse(JSON.stringify(this.applicationInfoService.miscSettings['microCampaignContactInfo']));
      this.applicationInfoService.currentAccount = this.bookingContactInfo.account;
      this.applicationInfoService.currentContact = this.bookingContactInfo;
    }

    this.innerHTMLAdd1 = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentAdd1);
    this.innerHTMLAdd2 = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentAdd2);
    this.innerHTMLAdd3 = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentAdd3);
    this.innerHTMLAdd4 = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentAdd4);
    this.additionalTaskInformation = new Object();
    this.deleteTaskUserRightName = this.applicationInfoService.applicationSettings['deleteTaskUserRigthName'];
    if (this.applicationInfoService.miscSettings['bookingMode'] != null) {
      this.controlMode = Number(this.applicationInfoService.miscSettings['bookingMode'])
      this.applicationInfoService.miscSettings['bookingMode'] = null;
    }

    // Booking mode
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['bookingMailMode'])) {
      this.bookingMailMode = Number(this.applicationInfoService.miscSettings['bookingMailMode']);
      this.applicationInfoService.miscSettings['bookingMailMode'] = null;
    }
    this.bookingMailMode = 3;

    // Info
    this.getAllProcessInfo();

    // Beim Start einer MicroCampaign
    if (this.applicationInfoService.miscSettings['isStartingMicroCampaign']) {
      this.microCampaignToStart = this.applicationInfoService.miscSettings['isStartingMicroCampaign'];
      this.applicationInfoService.entities.Item(this.entityId).data.resultId = this.microCampaignToStart.id;
      this.applicationInfoService.entities.Item(this.entityId).data.stepId = this.microCampaignToStart.stepId;
      this.applicationInfoService.miscSettings['isStartingMicroCampaign'] = null;      
    }

    if (this.applicationInfoService.miscSettings['preventStepChange'] == true) {
      this.preventStepChange = true;
      this.applicationInfoService.miscSettings['preventStepChange'] = null;
    }
    if (this.controlMode == 4) {
      if (this.applicationInfoService.currentContact != null) {
        this.applicationInfoService.entities.Item(this.entityId).data.contactId = this.applicationInfoService.currentContact.id;
      }
    }
    if (this.controlMode == 3) {
      this.applicationInfoService.entities.Item(this.entityId).data.workflowId =
        this.applicationInfoService.steps.Item(this.applicationInfoService.entities.Item(this.entityId).data.stepId).workflowId;
    }
    if (this.controlMode == 2) {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'])) {
        this.createTaskList = this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'];
        this.createTaskCount = this.createTaskList.length;
      }
      this.applicationInfoService.entities.Item(this.entityId).data['level'] = this.applicationInfoService.miscSettings['createTasksLevel']
      this.applicationInfoService.entities.Item(this.entityId).data['followUpDate'] = new Date(Date.now());
    } else {
      this.getContacts();
    }

    if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data)) {
      this.applicationInfoService.entities.Item(this.entityId).data = new Object();
    } else {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.id)) {
        this.getAdditionalTaskInformation(this.applicationInfoService.entities.Item(this.entityId).data.id);
      }

      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.workflowId)) {
        this.getWorkflowSteps(this.applicationInfoService.entities.Item(this.entityId).data.workflowId);
      }
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.stepId)) {
        this.getWorkflowStepResults(this.applicationInfoService.entities.Item(this.entityId).data.stepId, false);
      }
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.resultId)) {
        this.resultChanged(this.applicationInfoService.entities.Item(this.entityId).data.resultId);
      }
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.followUpUserId)) {
        this.applicationInfoService.entities.Item(this.entityId).data.followUpUserId = this.applicationInfoService.user.id;
      }
      if (
        this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.campaignId) &&
        this.bookingMailMode != 2
      ) {
        this.applicationInfoService.entities.Item(this.entityId).data.campaignId = this.commonService.getCampaignModeCampaignId();
      }
    }

    if (this.bookingMailMode == 2) {
      this.getTestEmailContactDetails(this.createTaskList[0].id);
    }

    this.opportunityService.clearTempOpportunity();    
    if (this.applicationInfoService.miscSettings['isStartingMicroCampaignShowPreview'] == true ) {
      this.isStartingMicroCampaignShowPreview = true;
      this.changePage(2);
      this.applicationInfoService.miscSettings['isStartingMicroCampaignShowPreview'] == false;
    } else {
      this.changePage(1);
    }

    // Campaign visual    
    if (this.filterArrays(this.campaigns, 'campaigns').length < 2) {
      this.showCampaignDropdown = false;
    }

    // Process visual
    if (this.workflows.length < 2) {
      this.showProcessDropdown = false;
    }
  }

  ngOnDestroy(): void {
    this.applicationInfoService.miscSettings['isStartingMicroCampaignShowPreview'] = null;
  }

  createTaskCreationGuid() {
    this.taskCreationGuid = '';
  }

  getUsers() {
      this.loaderService.display(true);    
      this.externaldatasourceService.executeExternalDataSource(14, [])
      .then(result => {
        this.loaderService.display(false);
        this.users = result;
        this.testMailUser = this.users.find(user => user.id == this.applicationInfoService.userID);
        this.testMailAdress = this.users.find(user => user.id == this.applicationInfoService.userID).email;
      })
      .catch(() => { 
        this.loaderService.display(false);
       });
    }

  getAllProcessInfo() {
    if (this.applicationInfoService.miscSettings['bookingWorkflowTypeId'] != null) {
      this.bookingWorkflowTypeId = Number(this.applicationInfoService.miscSettings['bookingWorkflowTypeId'])
      this.applicationInfoService.miscSettings['bookingWorkflowTypeId'] = null;
    }

    this.applicationInfoService.workflows.toArray().forEach(workflowItem => {
      if (workflowItem.id != 174 && workflowItem.isVisible == true && workflowItem.isActive == true) {
        this.workflows.push(workflowItem);
        this.workflows = this.workflows.map((item)=>{
          return{
            ...item,
            displayName:item.nameTranslationToken == null ? item.defaultName : item.nameTranslationToken.tokenFullName,
          }
        })
      }
    });
    
    if (this.controlMode == 4) {
      this.applicationInfoService.entities.Item(this.entityId).data.workflowId = this.applicationInfoService.projectInfo.defaultWorkflowId;
      this.getWorkflowSteps(this.applicationInfoService.entities.Item(this.entityId).data.workflowId)
    }

    this.testMailUser = this.applicationInfoService.user;
    this.testMailAdress = this.applicationInfoService.user.email;

    this.campaigns = this.applicationInfoService.campaigns.toArray();
  
    if (this.bookingMailMode == 2) {
      this.campaigns.forEach(campaign => {
        if (this.checkIfCampaignNeedsToBeHidden(campaign) == false
          && this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.campaignId))
        {
          this.applicationInfoService.entities.Item(this.entityId).data.campaignId = campaign.id;
        }
      });
    }
    return;
  }

  getAdditionalTaskInformation(id) {
    // this.loaderService.display(true);
    if (this.commonService.isNullOrUndefined(id)) { return; }
    if (Number(id) == -1) { return; }

    this.externaldatasourceService.executeExternalDataSource(20, [id])
    .then(result => {
      // this.loaderService.display(false);
      this.additionalTaskInformation = result;
      if (!this.commonService.isNullOrUndefined(this.additionalTaskInformation.resultId)) {
        this.applicationInfoService.entities.Item(this.entityId).data.resultDate = this.additionalTaskInformation.resultDate;
        this.applicationInfoService.entities.Item(this.entityId).data.resultUserId = this.additionalTaskInformation.resultUserId;
        this.applicationInfoService.entities.Item(this.entityId).data.questionAnswers = this.additionalTaskInformation.questionAnswers;
        if (result.questionAnswers.length > 0) {
          let answerArray = [];
          result.questionAnswers.forEach(answer => {
            answerArray.push(answer.answerId);
          });
          this.applicationInfoService.entities.Item(this.entityId).data.subResults = answerArray;
        }
        this.eventService.updateSubResultsEvent.emit(null);
      }
    })
    .catch(() => {
      // this.loaderService.display(false);
    });
  }

  getWorkflowSteps(workflowId, changedByUi = false) {
    const workflow = this.applicationInfoService.workflows.toArray().find(workflow => workflow.id == workflowId);
    if (workflow) {
      if (this.controlMode == 4) {
        this.steps = workflow.workflowSteps.filter(step => step.isSingleAction == true && step.isActive == true);
      } else {
        this.steps = workflow.workflowSteps.filter(step => step.isActive == true);
      }

      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['createTaskDefaultStepId'])) {
        const foundStep = this.steps.find(step => step.id == Number(this.applicationInfoService.applicationSettings['createTaskDefaultStepId']));
        if (foundStep && this.preventStepChange == false) {
          this.applicationInfoService.entities.Item(this.entityId).data.stepId = foundStep.id;
        }
      }
    }
    this.filterArrayResults['steps'] = null;
    if (changedByUi) {
      this.applicationInfoService.entities.Item(this.entityId).data.resultId = null;
    }
  }


  getContacts() {
    if (this.applicationInfoService.currentAccount) {
      if (this.applicationInfoService.currentAccount.isDummyAccount) {
        //console.log('is dummy account')
        this.externaldatasourceService.executeExternalDataSource(85, [
          this.applicationInfoService.entities.Item(this.entityId).data.contactId
        ]).then(result => {
          if (result == null) {
            this.contacts = []
            this.filterArrayResults['contacts'] = [];
          } else {
            this.contacts = [result];
            this.filterArrayResults['contacts'] = result;
          }
        });
      } else {
        this.externaldatasourceService.executeExternalDataSource(492).then(result => { 
          this.contacts = result; 
          this.filterArrayResults['contacts'] = result;
        });        
      }
      
    } else {
      this.contacts = [];
      // this.externaldatasourceService.executeExternalDataSource(492).then(result => { this.contacts = result; });
    }
  }

  getWorkflowStepResults(stepId, clearResult = true) {
    this.results = [];    
    const step = this.applicationInfoService.steps.toArray().find(step => step.id == stepId);
    step.results.sort((a, b) => a.resultOrder < b.resultOrder ? -1 : 1);
    if (step) {
      step.results.forEach(result => {
        if (result.isEventResult == false && result.isActive == true) {
          this.results.push(result);
        }
      });
    }
    this.filterArrayResults['results'] = null;
    if (clearResult) {
      this.applicationInfoService.entities.Item(this.entityId).data.resultId = null;
    }

    // Sonderfall für CreateTask, E-Mail-Preview anzeigen
    if (this.controlMode == 2) {
      const selectedStep = this.steps.find(step => step.id == stepId);
      if (selectedStep) {
        const selectedResult = selectedStep.results.find(result => result.id == selectedStep.bookTaskAfterXDaysResultId);
        if (selectedResult) {
          if (selectedResult.emailTemplateId != null) {
            this.getTemplatePreview(selectedResult.emailTemplateId);
          }
        }
      }
    }
  }

  resultChanged(resultId) {
    if (resultId == null || resultId =='null') {
      this.bookingPreview = null;
      this.selectedResult = null;
      return;
    }
    this.loaderService.display(true);
    this.selectedResult = this.applicationInfoService.results.Item(this.applicationInfoService.entities.Item(this.entityId).data.resultId);
    this.applicationInfoService.entities.Item(this.entityId).data.isNotifyFollowUpOwner = this.selectedResult.isSendEmailToFollowUpUser;

    // this.graphQLService.apolloGQLpromiseWithParameter('data', ApolloMethod.Query, this.graphqlqueryService.resultPreviewControlPreviewBookingTask, [resultId])
    this.externaldatasourceService.executeExternalDataSource(888, [resultId])
    .then(resultValue => {
      const resultJSON = JSON.parse(resultValue);
      this.eventService.showBookingPreviewEventForResultIdEvent.emit(resultId);
      this.loaderService.display(false);
      this.bookingPreview = resultJSON;
      this.getPreviewDefaultText();
    })
    .catch(() => {
      this.loaderService.display(false);
    });
  }

  getPreviewDefaultText() {
    if (this.bookingPreview == null) {
      return;
    }
    // this.innerHtmlPreview = this.axivasTranslateService.getTranslationTextForToken(this.bookingPreview.previewText.messageTokenName);
    // let param = 0;
    // this.bookingPreview.previewText.messageParameters.forEach(parameter => {
    //   this.innerHtmlPreview = this.innerHtmlPreview.replace('{{' + param + '}}', this.axivasTranslateService.getTranslationTextForToken(parameter));
    //   param ++;
    // })
    // if (this.bookingPreview.hasFollowUpTask == false) {
    //   this.innerHtmlPreview = '';
    // }

    // this.previewBookingTask = result.data.previewBookingTask;
    // this.innerHTML = this.axivasTranslateService.getTranslationTextForToken('TaskBooking.Preview.PreviewTextNoMaxFollowUp');
    // if (this.innerHTML.indexOf('{{') >= 0) {
    //   variableIdentifierLeft = '{{';
    //   variableIdentifierRight = '}}';
    // }

    // Followup
    this.nextStep = null;
    let showFollowUpTask = null;
    let showFollowUpDate = null;
    if (this.bookingPreview.HasFollowUpTask === true) {
      showFollowUpTask = true;
      if ((this.selectedResult.workflowStepResultTypeId === 29) ||
        (this.selectedResult.workflowStepResultTypeId === 30)) {
        showFollowUpDate = null;
      } else {
        showFollowUpDate = true;
      }
    }

    if (this.selectedResult.nextStepId != null) {
      this.nextStep = this.applicationInfoService.steps.toArray().find(step => step.id == this.selectedResult.nextStepId);
    }

    this.methodService.launchInternalMethod('updateentityvalue', [this.entityId, 'uiFollowUpTask', showFollowUpTask]);
    this.methodService.launchInternalMethod('updateentityvalue', [this.entityId, 'followUpUserId', this.userService.getUserId()]);
    this.methodService.launchInternalMethod('updateentityvalue', [this.entityId, 'uiFollowUpDate', showFollowUpDate]);
    this.methodService.launchInternalMethod('updateentityvalue', [this.entityId, 'isFollowUpAppointment', this.selectedResult.isPersonalFollowup]);
    this.methodService.launchInternalMethod('updateentityvalue', [this.entityId, 'uiNextFollowUpDate', null]);
    if (this.bookingPreview.NextFollowUpDate) {
      this.methodService.launchInternalMethod('updateentityvalue', [this.entityId, 'uiNextFollowUpDate', this.bookingPreview.NextFollowUpDate]);
      if (this.controlMode == 4) {
        this.methodService.launchInternalMethod('updateentityvalue', [this.entityId, 'followUpDate', this.bookingPreview.NextFollowUpDate]);
      }
    }
    this.innerHtmlPreview = this.innerHtmlPreview.replace(/\n/g, '<br>');
  }

  canDeleteTask() {
    let returnValue = false;
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.resultId)) {
      returnValue = true;
    }
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.id)) {
      returnValue = false;
    }
    if (this.applicationInfoService.entities.Item(this.entityId).data.id == -1) {
      returnValue = false;
    }
    return returnValue;
  }

  canBookTask() {
    let returnValue = true;
    if (this.selectedResult == null) {
      returnValue = false;
    } else {
      if (this.opportunityService.checkIfResultRequiresOpportunity(this.selectedResult.id)) {
        if (!this.opportunityService.checkIfTempOpportunityIsValid()) {
          returnValue = false;
        }
        if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.contactId)) {
          returnValue = false;
        }
      }
    }
    
    return returnValue;
  }

  canUpdateTask() {
    let returnValue = true;
    return returnValue;
  }

  canCreateTask() {
    let returnValue = false;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.stepId)) {
      returnValue = true;
    }
    return returnValue;
  }

  deleteTask() {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('CreateTask.Message.DeleteTaskMessage'),
      header: this.axivasTranslateService.getTranslationTextForToken('CreateTask.Message.DeleteTaskHeader'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        const deletedTask = new Object();
        deletedTask['id'] = this.applicationInfoService.entities.Item(this.entityId).data.id;
        deletedTask['isDeleted'] = true;
        this.externaldatasourceService.executeExternalDataSource(418, [
          this.commonService.getModifyArrayBody(deletedTask, [])
        ]).then(() => {
          this.loaderService.display(false);
          this.eventService.updateMicroCampaignViewEvent.emit();
          this.eventService.updateCombinedActivityList.emit();
          this.eventService.updateControlContentEvent.emit(new JJEvent('24915', []))
          this.eventService.closeJjPopup();
        }).catch(() => {
          this.loaderService.display(false);
        });
      },
    })
  }

  updateTask() {
    const promiseArray: Promise<any>[] = [];
    if (this.applicationInfoService.entities.Item(this.entityId).data) {
      if (this.applicationInfoService.entities.Item(this.entityId).data.questionAnswers == null) {
        delete this.applicationInfoService.entities.Item(this.entityId).data['questionAnswers'];
      }
    }

    Promise.all(promiseArray).then(() => {
      this.methodService.launchInternalMethod(InternalMethodType.CreateSingleAction, [this.entityId])
      .then(() => {
        this.applicationInfoService.currentAccount = this.accountSave;
        this.applicationInfoService.currentContact = this.contactSave;
        const result = this.applicationInfoService.results.toArray().find(result => result.id == this.applicationInfoService.entities.Item(this.entityId).data.resultId)
        const leadstateToUpdate = this.applicationInfoService.leadstates.toArray().find(leadstate => leadstate.lookupTable.id == result.leadStatusId);
        
        this.loaderService.display(false);
        this.eventService.closeJjPopup();
        if (this.applicationInfoService.currentContact) {
          this.eventService.customEvent.emit({ id: 'updateContactLeadstateDone', value: leadstateToUpdate.lookupTable.id });
        }
        this.eventService.customEvent.emit({ id: 'updateEverleadAccountHeader' });
        this.eventService.updateControlContent(24915);
      })
      .catch(() => {
        this.applicationInfoService.currentAccount = this.accountSave;
        this.applicationInfoService.currentContact = this.contactSave;
        this.loaderService.display(false);
      });
    });    
  }

  createTask() {
    let taskCreationMessage = this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.Message');
    if (this.bookingMailMode == 2) {
      taskCreationMessage = this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.MailMessage');
    }
    taskCreationMessage = taskCreationMessage.replace('<0>', this.createTaskList.length());
    this.confirmationService.confirm({
      message: taskCreationMessage,
      header: this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.applicationInfoService.entities.Item(this.entityId).data['batchId'] = this.taskCreationGuid;
        this.methodService.createNewTasks(this.applicationInfoService.entities.Item(this.entityId).data, true);
        this.createTaskCreationGuid();
      }
    });
  }

  checkResultAttribute(attribute, checkValue) {
    let returnValue = false;
    if (this.selectedResult != null) {
      if (this.selectedResult[attribute] == checkValue) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  changePage(page) {
    switch (page) {
      case 1:
        if (this.microCampaignToStart != null) {
          // const image = this.applicationInfoService.applicationSettings['bookingImage'.concat(this.microCampaignToStart.id, this.axivasTranslateService.currentLanguage)];
          this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: '80%;80%' });
        } else {
          this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: '800px;690px' });
        }
        break;
      case 2:
        this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: '80%;80%' });
        break;
    }
    this.page = page;
  }


  bookTask(checkEmail = false) {
    if ((checkEmail && this.checkResultAttribute('isEditableEmail', true) == true)) {
      this.changePage(2);
      return;
    }

    if (this.selectedResult.isContactRequired &&
      this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId).data.contactId)
    ) {
      this.messagingService.showDefaultWarning('', this.axivasTranslateService.getTranslationTextForToken('Task.Message.BookingRequiresContact'), false);
      return;
    }

    if (this.controlMode == 1) {
      this.loaderService.display(true);
      const promiseArrayPrep: Promise<any>[] = [];
      if (this.opportunityService.checkIfResultRequiresOpportunity(this.selectedResult.id)) {
        promiseArrayPrep.push(
          this.opportunityService.createOpportunity(this.opportunityService.getTempOpportunity()).then(createOpportunityResult => {
            this.applicationInfoService.entities.Item(this.entityId).data.opportunityId = createOpportunityResult.id;
          })
        );
      }
      Promise.all(promiseArrayPrep).then(() => {
        this.applicationInfoService.entities.Item(this.applicationInfoService.taskEntityId).data = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(this.entityId).data));
        this.applicationInfoService.currentTask = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(this.entityId).data));
        const promiseArray: Promise<any>[] = [];
        if (this.applicationInfoService.entities.Item(this.entityId).data.id > 0) {
          promiseArray.push(this.projectService.saveTaskInformation(Number(this.entityId)));
        }
    
        Promise.all(promiseArray).then(() => {
          this.projectService.bookTask(Number(this.entityId)).then(() => {
            this.eventService.closeJjPopup();
            this.eventService.customEvent.emit({ id: 'updateEverleadAccountHeader' });
            this.eventService.updateControlContent(24915);
            this.eventService.updateMicroCampaignViewEvent.emit(null);
            this.eventService.updateCombinedActivityList.emit(null);
            this.loaderService.display(false);
            this.eventService.customEvent.emit({ id: 'bookingFormTaskBooked' })
          })
          .catch(error => {
            console.error(error);            
            this.loaderService.display(false);
          });
        })
        .catch(() => {
          this.loaderService.display(false);
        });
      });
      return;
    }



    if (this.controlMode == 4) {
      this.loaderService.display(true);
      const promiseArray: Promise<any>[] = [];
      
      if (this.opportunityService.checkIfResultRequiresOpportunity(this.selectedResult.id)) {
        promiseArray.push(
          this.opportunityService.createOpportunity(this.opportunityService.getTempOpportunity()).then(createOpportunityResult => {
            this.applicationInfoService.entities.Item(this.entityId).data.opportunityId = createOpportunityResult.id;
          })
        );
      }

      Promise.all(promiseArray).then(() => {
        this.methodService.launchInternalMethod(InternalMethodType.CreateSingleAction, [this.entityId])
        .then(() => {
          this.applicationInfoService.currentAccount = this.accountSave;
          this.applicationInfoService.currentContact = this.contactSave;
          const result = this.applicationInfoService.results.toArray().find(result => result.id == this.applicationInfoService.entities.Item(this.entityId).data.resultId)
          const leadstateToUpdate = this.applicationInfoService.leadstates.toArray().find(leadstate => leadstate.lookupTable.id == result.leadStatusId);        
          this.loaderService.display(false);
          this.eventService.closeJjPopup();
          if (this.applicationInfoService.currentContact) {
            this.eventService.customEvent.emit({ id: 'updateContactLeadstateDone', value: leadstateToUpdate.lookupTable.id });
          }
          this.eventService.customEvent.emit({ id: 'updateEverleadAccountHeader' });
          this.eventService.updateControlContent(24915);
        })
        .catch(() => {
          this.applicationInfoService.currentAccount = this.accountSave;
          this.applicationInfoService.currentContact = this.contactSave;
          this.loaderService.display(false);
        });
      });
      return;
    }

    this.applicationInfoService.entities.Item(this.applicationInfoService.taskEntityId).data = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(this.entityId).data));
    this.applicationInfoService.currentTask = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(this.entityId).data));

    this.loaderService.display(true);
    const promiseArray: Promise<any>[] = [];
    if (this.applicationInfoService.entities.Item(this.entityId).data.id > 0) {
      promiseArray.push(this.projectService.saveTaskInformation(Number(this.entityId)));
    }

    Promise.all(promiseArray).then(() => {
      this.projectService.bookTask(Number(this.entityId)).then(() => {
        this.eventService.closeJjPopup();
        this.eventService.updateControlContent(24915);
        this.eventService.updateMicroCampaignViewEvent.emit(null);
        this.eventService.updateCombinedActivityList.emit(null);
        this.loaderService.display(false);
        this.eventService.customEvent.emit({ id: 'bookingFormTaskBooked' })
      })
      .catch(error => {
        console.error(error);
        this.loaderService.display(false);
      });
    })
    .catch(() => {
      this.loaderService.display(false);
    });
  }

  canEdit(value) {
    switch(value) {
      case 'workflow':
        if (this.applicationInfoService.applicationSettings['allowProcessChangeInBookingForm'] == 'true') { return true; }
        if (this.additionalTaskInformation != null) {
          if (!this.commonService.isNullOrUndefined(this.additionalTaskInformation.followUpTaskId)) { return false; }
        }
        if (this.controlMode == 3) { return false; }
        if (this.preventStepChange) { return false; }
        break;
      case 'step':
        if (this.applicationInfoService.applicationSettings['allowProcessChangeInBookingForm'] == 'true') { return true; }
        if (this.additionalTaskInformation != null) {
          if (!this.commonService.isNullOrUndefined(this.additionalTaskInformation.followUpTaskId)) { return false; }
        }
        if (this.controlMode == 3) { return false; }
        if (this.preventStepChange) { return false; }
        break;
      case 'result':
        if (this.additionalTaskInformation != null) {
          if (!this.commonService.isNullOrUndefined(this.additionalTaskInformation.followUpTaskId)) { return false; }
        }
        break;
      case 'campaign':
        if (this.applicationInfoService.campaingnModeId != null) { return false; }
        if (this.controlMode == 3) { return false; }
        break;
      }
    return true;
  }

  uiNextFollowUpDateChanged(date) {
    if (this.controlMode == 4) {
      this.applicationInfoService.entities.Item(this.entityId).data.followUpDate = date;
    }
  }

  checkIfStepNeedsToBeHidden(step) {
    let returnValue = false;
    if (this.controlMode == 2) {
      if (this.bookingMailMode != 0) {
        let result = this.applicationInfoService.results.Item(step.bookTaskAfterXDaysResultId);
        if (this.bookingMailMode == 2) {
          if (this.commonService.isNullOrUndefined(result)) {
            returnValue = true;
          } else {
            if (result.emailTemplateId == null) {
              returnValue = true;
            }
          }
        }
        if (this.bookingMailMode == 1) {
          if (!this.commonService.isNullOrUndefined(result)) {
            if (result.emailTemplateId != null) {
              returnValue = true;
            }
          }
        }
        if (step.isProcessStarter == false && step.isSingleAction == false && step.isFollowUp == false) {
          returnValue = true;
        }
      }
    }
    if (step.isActive == false) {
      returnValue = true;
    }
    return returnValue;
  }

  checkIfResultNeedsToBeHidden(result) {
    let returnValue = false;
    if (this.controlMode == 2) {
      if (this.bookingMailMode == 1) {
        if (result.emailTemplateId != null) {
          returnValue == null;
        }
      }
      if (this.bookingMailMode == 2) {
        if (result.emailTemplateId == null) {
          returnValue = true;
        }
      }
      if (result.isActive == false) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  checkIfCampaignNeedsToBeHidden(campaign) {
    let returnValue = false;
    if (this.bookingMailMode == 2) {
      if (this.commonService.isNullOrUndefined(campaign.sesConfigSetId)) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  checkIfWorkflowNeedsToBeHidden(workflow) {
    let validStepCounter = 0;
    let returnValue = false;
    if (this.controlMode == 2) {
      workflow.workflowSteps.forEach(step => {
        if (!this.checkIfStepNeedsToBeHidden(step)) {
          validStepCounter ++;
        }
      });
      if (workflow.isActive == false || validStepCounter == 0) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  getTemplatePreview(templateId) {
    this.selectedTemplate = templateId;
    this.emailtemplateService.getEMailTemplate(templateId, 2, true)
    .then(getEMailTemplateResult => {
      let templateResult = null;
      templateResult = getEMailTemplateResult
      this.innerHTMLEMailPreview = this.domSanitizer.bypassSecurityTrustHtml(
        this.emailtemplateService.renderEMail(templateResult.bodyHtml, [], false)
      );
    });
  }

  checkFollowUpDate() {
    const date1 = new Date(this.applicationInfoService.entities.Item(this.entityId).data.followUpDate);
    const date2 = new Date(Date.now())
    if (date1 < date2) {
      this.applicationInfoService.entities.Item(this.entityId).data.followUpDate = date2;
      this.messagingService.showDefaultInfo('', 'Task.Label.ResultDateCantBeInPast');
    }
  }

  getSendDate(date, step) {
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step).bookTaskAfterXDays)) {
      return new Date(date.getTime());
    } else {
      return new Date(date.getTime() + (1000 * 60 * 60 * 24 * this.applicationInfoService.steps.Item(step).bookTaskAfterXDays));
    }
  }

  sendEmail() {
    const step = this.applicationInfoService.steps.Item(this.applicationInfoService.entities.Item(this.entityId).data.stepId)
    const selectedResult = step.results.find(result => result.id == step.bookTaskAfterXDaysResultId);
    if (selectedResult) {
      if (selectedResult.emailTemplateId != null) {
        const testMailAdressDetails = this.testMailAdress.split(';');
        testMailAdressDetails.forEach(adress => {
          this.emailtemplateService.sendTestEmail(
            selectedResult.emailTemplateId,
            this.applicationInfoService.entities.Item(this.entityId).data.campaignId,
            this.createTaskList[0].id,
            adress
          ).then(() => { this.showSendMailContainer = false; })
        });
      }
    }
  }

  getTestEmailContactDetails(contactId) {
    this.externaldatasourceService.executeExternalDataSource(85, [contactId])
    .then(getTestEmailContactDetailsResult => {
      this.testMailContact = getTestEmailContactDetailsResult;
    });
  }

  createMailShot() {
    // console.warn(this.applicationInfoService.entities.Item(this.entityId).data);
    // return;
    let myLeadsFilter = this.applicationInfoService.myLeadsSelectedFilter;
    if (myLeadsFilter == undefined) {
      myLeadsFilter = null;
    }
    let filterId = null;
    if (myLeadsFilter) {
      filterId = myLeadsFilter.id;
    }
    let taskCreationMessage = this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.MailMessage');
    taskCreationMessage = taskCreationMessage.replace('<0>', this.createTaskList.length);
    this.confirmationService.confirm({
      message: taskCreationMessage,
      header: this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        this.externaldatasourceService.executeExternalDataSource(649, [
          this.applicationInfoService.entities.Item(this.entityId).data['followUpDate'],
          this.applicationInfoService.entities.Item(this.entityId).data['followUpUserId'],
          this.applicationInfoService.entities.Item(this.entityId).data['campaignId'],
          this.applicationInfoService.entities.Item(this.entityId).data['stepId'],
          this.taskCreationGuid,
          4,
          filterId
        ])
        .then(createMailShotResult => {
          this.loaderService.display(false);
          this.messagingService.showDefaultSuccess('', 'Booking.MailshotMessage.Message')
          this.createTaskCreationGuid();
        }).catch(() => { this.loaderService.display(false); });
      }
    });

  }

  aiTransformText(section) {
    let promptTextOverride = this.applicationInfoService.entities.Item(this.entityId).data[section];
    if (promptTextOverride != null) {
      promptTextOverride = '"'.concat(promptTextOverride, '"');
    }

    if (this.commonService.isNullOrUndefined(promptTextOverride) || promptTextOverride == '') {
      return;
    }

    this.aiGenerating = true;
    let accountId = this.applicationInfoService.currentAccount.id;
    let contactId = null;
    if (this.applicationInfoService.currentContact != null) {
      contactId = this.applicationInfoService.currentContact.id;
    }
    this.externaldatasourceService.executeExternalDataSource(780, [
      accountId,
      contactId,
      14,
      null,
      promptTextOverride
    ])
    .then(result => {
      if (result) {
        if (result.responseText != null) {
          result.responseText = this.commonService.removeQuotes(result.responseText);
        }
        this.aiReplacementText = result.responseText;
        this.aiGenerating = false;
      };
    });
  }

  acceptAiText(section) {
    this.applicationInfoService.entities.Item(this.entityId).data[section] = this.aiReplacementText;
    this.aiReplacementText = null;
  }
  cancelAiText() {
    this.aiReplacementText = null;
  }

  getMicroCampaignResultName() {
    const result = this.applicationInfoService.results.toArray().find(result => result.id == this.microCampaignToStart.id);
    if (result) {
      return this.commonService.getTranslationValueFromArray(result);
    } else {
      return '';
    }
  }

  showMail() {
    if (this.selectedResult == null) {
      return;
    }
    if (this.selectedResult.emailTemplateId == null) {
      return;
    }
    this.applicationInfoService.miscSettings['bookingPreviewEMailTemplateId'] = this.selectedResult.emailTemplateId;
    this.eventService.showJjPopup('BookingPreview.Label.EMailPreview', 'maileditor', '80');
  }

  
  editOpportunity() {
    if (this.commonService.isNullOrUndefined(this.opportunityService.getTempOpportunity())) {
      this.opportunityService.createTempOpportunity(this.applicationInfoService.entities.Item(this.entityId).data.contactId);
    }    
    this.opportunityService.showOpportunityForm();
  }



  checkMicroCampaignLabel(){
    return this.microCampaignToStart ? 'MicroCampaign.Label.StartCampaign' : 'CreateTask.Label.BookTask'
  }


  filterArrays(array, type: string){
    switch(type){
      case 'campaigns':
        if (this.commonService.isNullOrUndefined(this.filterArrayResults[type])) {
          array = array.filter((item) => !this.checkIfCampaignNeedsToBeHidden(item)).map((item) => {
            return{ ...item, displayName: item.nameTranslationToken == null ? item.defaultName : item.nameTranslationToken.tokenFullName }});
          this.filterArrayResults[type] = array;
        } else {
          return this.filterArrayResults[type];
        }
       
      case 'workflows':
        if (this.commonService.isNullOrUndefined(this.filterArrayResults[type])) {
          array = array.filter((item) => !this.checkIfWorkflowNeedsToBeHidden(item)).map((item) => {
            return{ ...item, displayName: item.nameTranslationToken == null ? item.defaultName : item.nameTranslationToken.tokenFullName }});
          this.filterArrayResults[type] = array;
        } else {
          return this.filterArrayResults[type];
        }

      case 'steps':
        if (this.commonService.isNullOrUndefined(this.filterArrayResults[type])) {
          array = array.filter((item) => !this.checkIfStepNeedsToBeHidden(item)).map((item) => {
            return{ ...item, displayName: item.nameTranslationToken == null ? item.defaultName : item.nameTranslationToken.tokenFullName }});
          this.filterArrayResults[type] = array;
        } else {
          return this.filterArrayResults[type];
        }
       
      case 'results':
        if (this.commonService.isNullOrUndefined(this.filterArrayResults[type])) {
          array = array.filter((item) => !this.checkIfResultNeedsToBeHidden(item)).map((item) => {
            return{ ...item, displayName: item.nameTranslationToken == null ? item.defaultName : item.nameTranslationToken.tokenFullName }});
          this.filterArrayResults[type] = array;
        } else {
          return this.filterArrayResults[type];
        }

      case'contacts':
        return this.contacts;
      // if (this.commonService.isNullOrUndefined(this.filterArrayResults[type])) {
      //   array = array.map((item) => {
      //     return{ ...item, displayName: item.firstName + ' ' + item.lastName }});
      //   this.filterArrayResults[type] = array;
      // } else {
      //   return this.filterArrayResults[type];
      // }
      // default:
      //   return array;
    }
  }


  checkAIStringLength(value){
    this.aiStringDisabled = value;
    //console.log('string lenght', this.aiStringDisabled)
  }

  filterResultsArray(results) {
    return this.commonService.getFilteredResults(this.filterArrays(results, 'results'));    
  }
}
