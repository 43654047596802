import { Component, OnInit, Injector, Input, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-accordeon',
  templateUrl: './custom-accordeon.component.html',
  styleUrls: ['./custom-accordeon.component.scss']
})
export class CustomAccordeonComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription()
  @Input() controlDefinition: any = null;
  selectedControlId = null;
  onlyOpenCurrentAccordeonTab = false;

  constructor(
    public injector: Injector,
  ) {
    super(injector);
    this.valueType = null;    
  }

  ngOnInit(): void {
    this.applicationInfoService.accordeonOpenedTabs[this.controlDefinition.id] = [];
    // if (this.commonService.isNullOrUndefined(this.applicationInfoService.accordeonOpenedTabs[this.controlDefinition.id])) {
    //   this.applicationInfoService.accordeonOpenedTabs[this.controlDefinition.id] = [];
    // }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.userSettings['onlyOpenCurrentAccordeonTab'])) {
      this.onlyOpenCurrentAccordeonTab = this.applicationInfoService.userSettings['onlyOpenCurrentAccordeonTab'];
    } 

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'updateRegisterControl' && event.value.id == this.controlDefinition.id) {        
      }
    });

    if (this.controlDefinition.childs.length > 0) {
      if (this.uiService.getControlSettingOrDefault(this.controlDefinition, 'expandFirstTab', false) == true) {
        this.selectedControlId = this.controlDefinition.childs[0].id;
        this.toggleTab(this.controlDefinition.childs[0].id);
      }      
    }
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  isVisible(control) {
    let returnValue = true;
    if (!control.isActive) {
      returnValue = false;
    }
    return returnValue;
  }

  selectChild(childId) {
    if (this.selectedControlId == childId) {
      this.selectedControlId = null;
    } else {
      this.selectedControlId = childId;
    }    
  }

  toggleTab(tabId) {
    if (this.onlyOpenCurrentAccordeonTab == true) {
      return;
    }
    this.commonService.toggleArrayItem(this.applicationInfoService.accordeonOpenedTabs[this.controlDefinition.id], tabId);    
  }

  tabSelected(control) {
    let returnValue = false;
    if (
      this.commonService.checkIfItemIsInArray(this.applicationInfoService.accordeonOpenedTabs[this.controlDefinition.id], control.id) &&
      this.onlyOpenCurrentAccordeonTab == false
    ) {
      returnValue = true;
    }
    return returnValue;
  }
}

