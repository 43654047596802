<div class="packageUpgradeItemWrapper">
    <div class="packageUpgradeItemWrapperLine packageUpgradeItemWrapperLineBigBorder">
        <div class="packageUpgradeItemWrapperHeader__text packageUpgradeItemWrapperHeader__buttons">
            <div *ngIf="showLicenseIntervall" (click)="changePaymentIntervall()">
                <span [ngClass]="{ disabledValue: monthlySubscription }">
                    {{ 'PackageUpgrade.Label.YearlyInterval' | texttransform }}
                </span>
                <app-jj-icons [type]="'toggle-left-fill'" *ngIf="!monthlySubscription" [width]="60"
                    [height]="28"></app-jj-icons>
                <app-jj-icons [type]="'toggle-right-fill'" *ngIf="monthlySubscription" [width]="60"
                    [height]="28"></app-jj-icons>
                <span [ngClass]="{ disabledValue: !monthlySubscription }">
                    {{ 'PackageUpgrade.Label.MonthlyInterval' | texttransform }}
                </span>
            </div>
        </div>
        <div class="packageUpgradeItemWrapperHeader__package" *ngFor="let package of packages">
            <div class="packageUpgradeItemPackageName">{{ package.settingJson?.packageSubSectionName | texttransform }}</div>
        </div>
    </div>
    <div class="packageUpgradeItemWrapperLineSmallBorderLine"></div>
    <div class="packageUpgradeItemWrapperLine">
        <div class="packageUpgradeItemWrapperHeader__text packageUpgradeItemWrapperHeader__textCol">
            <div class="sectionName">{{ packages[0]?.settingJson?.packageSectionName | texttransform }}</div>
            <div class="sectionNameAdditionalText" *ngIf="monthlySubscription">{{ packages[0]?.settingJson?.additionalText | texttransform }}</div>
            <div class="sectionNameAdditionalText" *ngIf="!monthlySubscription">{{ packages[0]?.settingJson?.additionalTextYear | texttransform }}</div>
        </div>
        <div class="packageUpgradeItemWrapperHeader__package" *ngFor="let package of packages">
            <div class="packageUpgradeItemPackageValue">{{ getPackagePrice(package) | texttransform }}</div>            
        </div>
    </div>
    <div class="packageUpgradeItemWrapperLineSmallBorder"></div>
    <div class="highlighted" *ngIf="showHighlight" [style.right]="getHighlightRight()">
        <div class="highlightedHeader">
            {{ 'Licensing.Label.CurrentPlan' | texttransform}}
        </div>
    </div>
    <div class="packageUpgradeItemWrapperLine">
        <div class="packageUpgradeItemWrapperHeader__text">
            <div class="packageUpgradeItemWrapperHeader__text__dotted">
                <span class="packageUpgradeItemWrapperHeader__text__implementation">
                    {{ 'PackageUpgrade.Label.Implementation' | texttransform }}
                </span>
                -
                <span class="packageUpgradeItemWrapperHeader__text__implementationAdditional">
                    {{ 'PackageUpgrade.Label.ImplementationDesc' | texttransform }}
                </span>
            </div>
        </div>
        <div class="packageUpgradeItemWrapperHeader__package" *ngFor="let package of packages">
            <div class="packageUpgradeItemPackageValue">{{ getSetupPrice(package) | texttransform }}</div>
        </div>
    </div>
    <div class="packageUpgradeItemWrapperLineSmallBorder"></div>
    <div class="packageUpgradeItemWrapperLine">
        <div class="packageUpgradeItemWrapperHeader__text packageUpgradeItemWrapperHeader__details fingerCursor"
            (click)="showDetails()">
            <app-jj-icons [type]="'plus'" [width]="20" [height]="20"></app-jj-icons>
            <div class="packageUpgradeItemPackageShowdetails">{{ 'PackageUpgrade.Label.ShowDetails' | texttransform }}
            </div>
        </div>
        <div class="packageUpgradeItemWrapperHeader__package" *ngFor="let package of packages">
            <span *ngIf="getPackagePrice(package) != 'PackageUpgrade.Label.RequestPrice'">
                <app-main-button [style.width]="getButtonWidth()" svg="arrow-up-right" position="right"
                    (onClick)="orderNow(package)"
                    *ngIf="package.id == applicationInfoService.projectInfo.packageId && monthlySubscription == licensingService.isMonthlySubscription()"
                    label="Licensing.Label.CurrentPlan" [disabled]="true" fontSize="14px"
                    [maxWidth]="true"></app-main-button>
                <app-main-button [style.width]="getButtonWidth()" svg="arrow-up-right" position="right"
                    (onClick)="changePackage(package)"
                    *ngIf="package.id != applicationInfoService.projectInfo.packageId || monthlySubscription != licensingService.isMonthlySubscription()"
                    label="PackageUpgrade.Label.Change" [disabled]="processingChange" fontSize="14px"
                    [maxWidth]="true"></app-main-button>
            </span>
            <span *ngIf="getPackagePrice(package) == 'PackageUpgrade.Label.RequestPrice'">
                <app-main-button [style.width]="getButtonWidth()" svg="arrow-up-right" position="right"
                    [label]="getPackagePrice(package)" fontSize="14px" (click)="orderNow(package)"
                    [maxWidth]="true"></app-main-button>
            </span>
        </div>
    </div>    
</div>
