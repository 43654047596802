import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { Subscription } from 'rxjs';
import tippy from 'tippy.js';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss'
})
export class UserAvatarComponent implements OnInit, AfterViewInit, OnDestroy{

  constructor(
    public commonService:CommonService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    private packagefeatureService: PackagefeatureService,
    private methodService: MethodService,
    private awsCognitoService: AwsCognitoService,
    private settingsService:SettingsService,
  ){}

  @Input() component: string = "";
  @Input() message: string = "";
  @Input() role: boolean = false;
  @Input() mobile: boolean = false;
  canUseDesigner: boolean = false;
  pools = [];

  tippyInstance;
  isOpen: boolean = false;
  customEventSubscription = new Subscription();

  @ViewChild('tooltipUserMenu', { static: false } ) tooltipUserMenu!: ElementRef;
  @ViewChild('tooltipButton', { static: false } ) tooltipButton!: ElementRef;
  @ViewChild('tooltipButtonMobile', { static: false } ) tooltipButtonMobile!: ElementRef;



  ngOnInit(): void {

      this.canUseDesigner = this.packagefeatureService.selectedPackageHasFeature(this.applicationInfoService.applicationSettings['featureId_uidesigner']);
      if (this.applicationInfoService.isDeveloper) {
        this.canUseDesigner = true;
      }
      this.canUseDesigner = true;

      this.customEventSubscription = this.eventService.customEvent.subscribe((event) =>{
        if(event.id === 'applicationPageResized'){
          setTimeout(() => {
            this.closePopup();
          },3000)
    
        }

        if(event.id === 'mainwindowscroll'){
          this.closePopup()
        }
    
      })

  }

  ngAfterViewInit(){

    if(this.tooltipButton || this.tooltipButtonMobile){
      setTimeout(() =>{
        this.getTippyInstance();
      },0)
    }
  }


  showInstance(){
    if(this.tippyInstance){
      // console.log('tippy', this.tippyInstance);
    }
  }


  toggleMenu(){

    this.isOpen = !this.isOpen
    //console.log('this.IsOpen', this.isOpen)
  }

  getTippyInstance(){

    if(this.tippyInstance){
      console.log('this.tippyInstance', this.tippyInstance);
      this.tippyInstance.destroy();
    }

    if(this.applicationInfoService.isMobile()){
      this.tippyInstance = tippy(this.tooltipButtonMobile?.nativeElement,{
        content: this.tooltipUserMenu?.nativeElement,
          placement:'auto',
          trigger: 'click',
          theme:'light-border',
          touch:true,
          interactive:true,
          appendTo:document.body,
          zIndex: 9999999,
         
      })

    } else {
      this.tippyInstance = tippy(this.tooltipButton?.nativeElement,{
        content: this.tooltipUserMenu?.nativeElement,
          placement:'auto',
          trigger: 'click',
          theme:'light-border',
          touch:true,
          interactive:true,
          appendTo:document.body,
          zIndex: 9999999,
          onHide(instance) {
          this.isOpen= false;
          },
          onHidden:()=>{
              this.isOpen= false; 
          }
      })
    }
  
  }

  
  
  getPools() {
    return this.applicationInfoService.pools.toArray().filter(pool => pool.isActive == true);
  }

  changeExpertMode() {
    if (this.applicationInfoService.expertMode == true) {
      this.applicationInfoService.expertMode = false;
      this.settingsService.userSettings['ExpertMode'].value = 'false'
    } else {
      this.applicationInfoService.expertMode = true;
      this.settingsService.userSettings['ExpertMode'].value = 'true'
    }
    this.settingsService.setUserSettingEx(this.settingsService.userSettings['ExpertMode'])
    this.closePopup();
  }


  showMailbox() {
    this.eventService.showJjPopup('UserSettings.Label.Mailbox', 'mailbox', 90);
    this.closePopup();
  }

  closePopup() {
    if(this.tippyInstance){
      this.tippyInstance.hide();
      this.isOpen= false;
    }
/*      setTimeout(() =>{
      this.tippyInstance.hide();
    },0) */
  }

  showUserSettings() {
    this.applicationInfoService.routerHeaderLabelText = 'General.Menu.Settings';
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'usersettings']);
    //this.closePopup();
    if (this.applicationInfoService.isMobile()) {
      this.applicationInfoService.navigationExpandedMobile = false;
    }

    this.closePopup();
  }


  changeDesignerMode() {
    this.applicationInfoService.changeDesignerMode();
    this.closePopup();

  }

  getModeText(){
    return (this.applicationInfoService.designerMode ? "Designermodus deaktivieren" : "Designermodus aktivieren" )
  }


  getUserNameShortCut(user) {
    let userShortCut = '';
    if (user == null) { return ''; }
    if (user.firstName != null) {
      userShortCut = userShortCut.concat(user.firstName.substring(0,1))
    }
    if (user.lastName != null) {
      userShortCut = userShortCut.concat(user.lastName.substring(0,1))
    }    
    return userShortCut;
  }

  getNewUserMessages() {
    try {
      const unreadMessages = this.applicationInfoService.userMessages.filter(message => message.userMessages[0].readDateAt == null);
      if (this.commonService.isNullOrUndefined(unreadMessages)) {
        return 0;
      } else {
        return unreadMessages.length;
      }    
    }
    catch {
      console.error('getNewUserMessages error');
    }
  }


  logout() {
    this.eventService.showJjPopup('AWS.Logout.Header', 'awslogout', '500px; 300px');
    this.closePopup();
      //this.awsCognitoService.logoutUserFromCognito();
  }


  changeUserRole() {
    this.userService.getUserPermissions().then(() => {
      this.eventService.customEvent.emit({ id: 'userRoleChanged' });
      this.eventService.customEvent.emit({ id: 'loadJsonNavigationAfterRoleChange' });  
    });    
  }

  changePool() {
    this.eventService.customEvent.emit({ id: 'selectedPoolChanged' });
    this.applicationInfoService.userSettings['lastSelectedPool'.concat(this.applicationInfoService.projectID.toString())] = this.applicationInfoService.poolId;
  }

  showProjectSelection() {
    this.closePopup()
    this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '700px;80');
    this.applicationInfoService.campaingnModeId
  }

  getGreetingToken() {
    let returnValue = 'WelcomePage.Label.GoodMorning';
    const hour = new Date().getHours();    
    if (hour > 11) {
      returnValue = 'WelcomePage.Label.GoodDay';
    }
    if (hour > 17) {
      returnValue = 'WelcomePage.Label.GoodEvening';
    }
    return returnValue;
  }

  ngOnDestroy(): void {
    if(this.customEventSubscription){
      this.customEventSubscription.unsubscribe();
    }
  }
}
