<div class="customTextControlWrapper" [class.disabled]="!checkIfControlIsEnabled()">
  <label class="customTextControlWrapper__label" 
  [class.active]="isFocused && (checkIfControlIsEnabled()  == true )" 
  [class.disabled]="!checkIfControlIsEnabled()">
    {{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
  <div 
  class="customControl__dataLine customTextControlWrapper__valueWrapper"
  [class.active]="isFocused && (checkIfControlIsEnabled()  == true )" 
  [class.disabled]="!checkIfControlIsEnabled()"
  > 
    <input class="customControl__dataLine__dataControl customTextControlWrapper__textbox"    
      type="textbox"
      [disabled]="!checkIfControlIsEnabled()"      
      (change)="callMethod('onchange')"
      [placeholder]="commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform"
      [(ngModel)]="controlValue"
      [tippy]="controlValue"
      (focus)="handleFocus()"
      (blur)="handleBlur()"
    >  
  </div> 
</div>
