<div class="statusBar" *ngIf="options === null">
    <div class="statusBar__label"> {{ label | texttransform }}</div>
    <div class="statusBar__bar" #bar>
            <div [style.width.%]="value - 8"></div>
            <div class="statusBar__bar__inner">
                <div class="statusBar__bar__marker">
                    <div class="statusBar__bar__pin">
                        <app-jj-icons type="map-pin-everlead" [width]="18" [height]="18"></app-jj-icons>
                        <!--    <app-jj-icons type="map-pin" [width]="30" [height]="30"></app-jj-icons> -->
                        <span class="statusBar__bar__value">{{ value }} % </span>
                    </div>
                    <app-jj-icons type="caret-up-fill" width="16" height="16"></app-jj-icons>
                </div>
            </div>
    </div>
</div>

<div *ngIf="options !== null">
    <div  *ngFor="let option of options" class="statusBar">
        <div class="statusBar__label" [style.max-width]="labelWidth" [style.width]="labelWidth"> {{ option.label | texttransform }}</div>
        <div class="statusBar__bar" #bar>
           <div class="statusBar__barwrapper"[style.width.px]="option.value">
                <div class="statusBar__bar__inner">
                    <div class="statusBar__bar__marker">
                        <div class="statusBar__bar__pin">
                            <span class="statusBar__bar__value" *ngIf="option.value >= 91">{{ option.value }} % </span>
                            <app-jj-icons type="map-pin-everlead"></app-jj-icons>
                            <!--    <app-jj-icons type="map-pin" [width]="30" [height]="30"></app-jj-icons> -->
                            <span class="statusBar__bar__value" *ngIf="option.value <= 90">{{ option.value }} % </span>
                        </div>
                        <app-jj-icons type="caret-up-fill" width="16" height="16"></app-jj-icons>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>