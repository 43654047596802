<div *ngIf="applicationInfoService.useNewDesign" class="customTextControlWrapper" [class.disabled]="!checkIfCallControlIsEnabled()">
  <label class="customTextControlWrapper__label"
  [class.active]="isFocused && (checkIfControlIsEnabled()  == true )" 
  [class.disabled]="!checkIfControlIsEnabled()">{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
  <div class="d-flex customTextControlWrapper__valueWrapper"
  [class.active]="isFocused && (checkIfControlIsEnabled()  == true )" 
  [class.disabled]="!checkIfControlIsEnabled()"
  >
    <input       
      class="customTextControlWrapper__textbox customTextControlWrapper__oneIcon"
      [type]="inputType" 
      [disabled]="!checkIfCallControlIsEnabled()"      
      (change)="callMethod('onchange')"
      [placeholder]="commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform"
      [(ngModel)]="controlValue"
      [tippy]="controlValue"
      (keydown)="keyDown()"
      (blur)="handleBlur()"
      (focus)="handleFocus()"
    >  
    <div class="" *ngIf="applicationInfoService.applicationSettings['showPhoneIconInControl'] == 'true'">
      <app-jj-icons type="phone" [width]="20" [height]="20" class="" (click)="callNumber()" 
        [disabled]="!checkIfCallControlIsEnabled()"></app-jj-icons>
<!--       <svg-icon src="assets/images/svg/iconset/icon-phone.svg" class="customDial__nonMobile"  
      [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="callNumber()" [ngClass]="{
        fingerCursor: checkIfCallControlIsEnabled() 
      }"></svg-icon>   -->
    </div>
  </div>  
</div>