<div class="tokenDist">
    <ng-container *ngIf="loading == true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
    </ng-container>

    <div class="tokenDist__inner" *ngIf="!loading">
        <div class="tokenDist__nav" *ngIf="createSetting">
            <app-main-button svg="coins" type="small" (onClick)="updateUserSetting()"
                label="UserToken.Label.CreateSetting"></app-main-button>
        </div>

        <div *ngIf="!createSetting && detailInfoToken !== null" class="tokenDist__info">
            <div class="tokenDist__info__header">
                <label>{{ 'UserToken.Label.DistributionDetailDescription' | texttransform }}</label>
                <app-jj-icons type="coins" width="30" height="30"></app-jj-icons>
            </div>
            <ng-container *ngIf="loadingDetailInfo == true">
                <i class="fas fa-spinner fa-spin"
                    style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
            </ng-container>
            <div class="tokenDist__info__inner" *ngIf="!loadingDetailInfo">
                <div class="tokenDist__info__item">
                    <label> {{ 'UserToken.Label.Available' | texttransform }}:</label>
                    <span>{{ detailInfoToken?.projectQuotaLeft }}</span>
                </div>
                <div class="tokenDist__info__item">
                    <label> {{ 'UserToken.Label.NotDistributed' | texttransform }}:</label>
                    <span>{{ detailInfoToken?.available }}</span>
                </div>               
                <div class="tokenDist__info__item">
                    <label>{{ 'UserToken.Label.QuotaLeft' | texttransform }} :</label>
                    <span>{{ detailInfoToken?.quotaLeft }}</span>
                </div> 
                <div class="tokenDist__info__item">
                    <label>{{ 'UserToken.Label.MaxCreditsForUser' | texttransform }} :</label>
                    <span>{{ this.userData }}</span>
                </div> 
            </div>

            <!-- <app-select-input label="UserToken.Label.DistributionMode" [(ngModel)]="distributionMode" [placeholder]="false"
                [options]="distributionModes" display="defaultName" bind="id" (valueChange)="calculateValues()"></app-select-input> -->

            <div class="distributionInfo">
                <app-number-input [(ngModel)]="currentValue" label="UserToken.Label.MaxCreditsForUser" [max]="maxValue" class="distributionInfo__value"
                    (change)="setValueToMaxValue()"></app-number-input>
                <!-- <div class="distributionInfo__maxValue">/ {{ maxValue }}</div> -->
            </div>
            
            <div class="distributionDetailDescription">
                {{ 'UserToken.Label.DistributionDetailDescription2' | texttransform }}
            </div>

            <div class="tokenDist__buttons">
                <app-main-button svg="coins" (onClick)="updateUserSetting()" type="borderSmall" label="UserToken.Label.UpdateTokens"
                    [disabled]="currentValue < 1"></app-main-button>
                <app-main-button svg="x" (onClick)="closeWindow()" type="borderSmall" label="General.Label.Close"></app-main-button>
            </div>
        </div>
    </div>
</div>



