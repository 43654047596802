import { Component, OnInit } from '@angular/core';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-awslogoout',
  templateUrl: './awslogoout.component.html',
  styleUrl: './awslogoout.component.scss'
})
export class AwslogooutComponent implements OnInit{

  constructor(
    private awsCognitoService:AwsCognitoService,
    private eventService: EventService
  ){}


  ngOnInit(): void {
   //throw new Error('Method not implemented.');
  }

  closeWindow(){
    this.eventService.closeJjPopup();
  }

  logout(){
    this.awsCognitoService.logoutUserFromCognito();
    this.eventService.closeJjPopup();
  }

}
