<div class="moveTaskWrapper" *ngIf="userService.hasPermission('CanUpdateTask')">
    <div class="moveTaskNavigationMobile">
        <div class="everleadMobileTab">
            <div class="everleadMobileTab__valueWrapper">
                <select [(ngModel)]="mode" class="selectTab__box">
                    <option [ngValue]="0">{{'TaskOrganisation.Label.MoveTasks' | texttransform }}</option>
                    <option *ngIf="applicationInfoService?.isDeveloper" [ngValue]="1">{{'TaskOrganisation.Label.CreateTasksForClosedContacts' | texttransform }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="moveTaskNavigation">
        <app-scroll-tabs>
            <app-tab svg="arrows-left-right" [active]="mode == 0" label="TaskOrganisation.Label.MoveTasks" (onClick)="mode = 0"></app-tab>
            <app-tab svg="arrows-left-right" [active]="mode == 1" label="TaskOrganisation.Label.CreateTasksForClosedContacts" 
                *ngIf="applicationInfoService?.isDeveloper" (onClick)="mode = 1"></app-tab>
        </app-scroll-tabs>
    </div>

    <div class="moveTaskContent">
        <app-layout header="TaskOrganisation.Label.MoveTasks" *ngIf="mode == 0">            
            <div class="moveTaskMain">
                <div class="moveTaskNavigationMobile">
                    <div class="everleadMobileTab">
                        <div class="everleadMobileTab__valueWrapper">
                            <select [(ngModel)]="distributionMode" class="selectTab__box">
                                <option [ngValue]="0">{{'TaskOrganisation.Label.SingleTarget' | texttransform }}</option>
                                <option [ngValue]="1">{{'TaskOrganisation.Label.MultiTarget' | texttransform }}</option>
                                <option [ngValue]="2">{{'TaskOrganisation.Label.MultiTargetPro' | texttransform }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="moveTaskNavigation">
                    <app-scroll-tabs>
                        <app-tab svg="user" [active]="distributionMode == 0" label="TaskOrganisation.Label.SingleTarget" (onClick)="distributionMode = 0"></app-tab>
                        <app-tab svg="users" [active]="distributionMode == 1" label="TaskOrganisation.Label.MultiTarget" (onClick)="distributionMode = 1"></app-tab>            
                        <app-tab svg="users" [active]="distributionMode == 2" label="TaskOrganisation.Label.MultiTargetPro" (onClick)="distributionMode = 2"></app-tab> 
                    </app-scroll-tabs>
                
                </div>

                <div *ngIf="distributionMode == 0">
                    <div class="moveTaskDescription">{{ 'TaskOrganisation.Label.MoveTasksDesc' | texttransform }}</div>
                </div>                

                <div *ngIf="distributionMode == 1">
                    <div class="moveTaskDescription">{{ 'TaskOrganisation.Label.MoveTasksMultipleDesc' | texttransform }}</div>
                </div>

                <app-select-input label="TaskOrganisation.Label.MoveTasksSourceUser" [(ngModel)]="fromUserId" 
                    [options]="projectUsers" display="userName" bind="id"></app-select-input>
                
                <ng-container *ngIf="distributionMode == 0">
                    <app-select-input label="TaskOrganisation.Label.MoveTasksTargetUser" [(ngModel)]="toUserId" 
                        [options]="projectUsers" display="userName" bind="id"></app-select-input>
                    <div *ngIf="fromUserId == toUserId && toUserId != null" class="moveTasksError">
                        {{ 'TaskOrganisation.Label.SourceAndTargetShouldNotMatch' | texttransform }}
                    </div>    
                    <app-main-button svg="arrows-left-right" (onClick)="reassignTasks()" label="TaskOrganisation.Label.MoveTasks"
                        [disabled]="fromUserId == null || toUserId == null || fromUserId == toUserId"></app-main-button>
                </ng-container>

                <div *ngIf="distributionMode == 1" class="multiUserContainer">
                    <div class="moveTaskDescription">{{ 'TaskOrganisation.Label.SelectUserToDistribute' | texttransform }}</div>
                    <div class="targetUserList">
                        <div *ngFor="let projectUser of projectUsers" class="everleadTableRow moveTaskUserList" (click)="toggleUser(projectUser.id)" 
                            [ngClass]="{ 
                                projectUserActive: commonService.checkIfItemIsInArray(multiUserIds, projectUser.id),
                                hideInDom: projectUser.id == fromUserId
                            }">
                            {{ projectUser.userName }}
                        </div>
                    </div>                    
                    <app-main-button svg="arrows-left-right" (onClick)="reassignTasks(true)" label="TaskOrganisation.Label.MoveTasks"
                        [disabled]="fromUserId == null || multiUserIds.length == 0"></app-main-button>
                </div>
            </div>
        </app-layout>

        <app-layout header="TaskOrganisation.Label.CreateTasksForClosedContacts" *ngIf="mode == 1">
            <div class="moveTaskDescription">{{ 'TaskOrganisation.Label.CreateTasksForClosedContactsDesc' | texttransform }}</div>
        </app-layout>
    </div>
</div>