import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


/* import { register as registerSwiperElements } from 'swiper/element/bundle'; */

if (environment.production) {
  enableProdMode();
}

// SWIPER package
/* registerSwiperElements() */

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
