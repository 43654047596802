import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';

@Component({
  selector: 'app-custom-microcampaign-cancel',
  templateUrl: './custom-microcampaign-cancel.component.html',
  styleUrls: ['./custom-microcampaign-cancel.component.scss']
})
export class CustomMicrocampaignCancelComponent implements OnInit {
  cancelText = '';

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private eventService: EventService,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
  }

  cancelMicroCampaign() {
    this.projectService.bookDefaultStepResult(this.applicationInfoService.miscSettings['microCampaignToCancel'].currentTask, 9616, this.cancelText);
  }
}
