import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, viewChild } from '@angular/core';
import { ScrollableDirective } from '../../custom/custom-register/scrollable.directive';
import { Subscription } from 'rxjs';


interface NavBarOptions{
  hidden:boolean,
  id:number,
  label:string
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy{

  @Input() selectedId: number= 0;
  @Input() options: NavBarOptions[] | null = null;
  @Input() children: boolean = false;


  @Output() onNavItemChange= new EventEmitter<any>();
  @Output() isOverflow = new EventEmitter<any>();
  @ViewChild(ScrollableDirective) scrollableDirective : ScrollableDirective;

  private overflowSubscription!:Subscription;

ngOnInit(): void {

  if(!this.children){
    this.options = this.options.filter((item) => item.hidden === false);
  }
}

ngOnChanges(changes: SimpleChanges): void {

  if(!this.children){
    this.options = this.options.filter((item) => item.hidden === false);
  }

}


ngAfterViewInit(): void {
  //console.log('directive',this.scrollableDirective.isOverflow)
  this.isOverflow.emit(this.scrollableDirective.isOverflow);
}

  selectItem(item){
    this.selectedId = item
    this.onNavItemChange.emit(this.selectedId)
  }

  selectorItem(item?: any){
    this.onNavItemChange.emit(this.selectedId)
  }



  checkRefsOverflow(listRefs, isOverflow){
    listRefs.forEach((item) => {
      item.overflow = isOverflow
    })
  }

  ngOnDestroy(): void {
    
  }

}
