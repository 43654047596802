<span id="defaultDashboardMailshotDetail"></span>
<div class="selectedDashboard" *ngIf="selectedReport">
    <app-layout [header]="selectedReport?.defaultName" [subheader]="selectedReport?.subHeader">
        <div class="selectedDashboard__headerNav" *ngIf="eventBookingBatch == null">
            <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back"
                type="small"></app-main-button>
        </div>

        <div class="selectedDashboard__innerBorderLayer">
            <div class="selectedDashboard__nav">
                <div class="selectedDashboard__filterMobile" *ngIf="eventBookingBatch == null">
                    <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                        <div class="selectedDashboard__filterMobile">
                            <app-select-input label="Mailshots.Label.Mailshot" [(ngModel)]="applicationInfoService.selectedEventBookingBatchId"
                                [options]="eventBookingBatches" display="batchName" bind="id"
                                (valueChange)="getEventBookingBatchData()"></app-select-input>
                            <app-select-input label="Mailshots.Label.View" [(ngModel)]="mode" [options]="modes"
                                *ngIf="applicationInfoService.selectedEventBookingBatchId != null && 
                                    getEventBookingBatch(applicationInfoService.selectedEventBookingBatchId)?.startedAt != null"
                                display="defaultName" bind="id" (valueChange)="switchMode()"></app-select-input>
                        </div>
                    </app-accordion>
                </div>
                
                <div class="selectedDashboard__filter">
                    <app-select-input label="Mailshots.Label.Mailshot" [(ngModel)]="applicationInfoService.selectedEventBookingBatchId"
                        [options]="eventBookingBatches" display="batchName" bind="id"
                        (valueChange)="getEventBookingBatchData()"></app-select-input>
                    <app-select-input label="Mailshots.Label.View" [(ngModel)]="mode" [options]="modes"
                        *ngIf="applicationInfoService.selectedEventBookingBatchId != null &&
                        getEventBookingBatch(applicationInfoService.selectedEventBookingBatchId)?.startedAt != null"
                        display="defaultName" bind="id" (valueChange)="switchMode()"></app-select-input>

                </div>
            </div>

            <div class="selectedDashboard__content"             
                [style.max-height.px]="getMaxHeight(eventBookingBatch ? 55 : 155)"
                [style.height.px]="getMaxHeight(eventBookingBatch ? 55 : 155)">
                <div class="defaultDashboardMailshotMode2" *ngIf="kpis != null && mode == 2 &&
                    getEventBookingBatch(applicationInfoService.selectedEventBookingBatchId)?.startedAt != null">
                    <div class="everleadContentContainer" class="maxHeight">
                        <div [innerHTML]="innerHTMLMode2"></div>
                    </div>
                </div>

                <div class="defaultDashboardMailshotKPIs" 
                    *ngIf=" getEventBookingBatch(applicationInfoService.selectedEventBookingBatchId)?.startedAt == null &&
                            applicationInfoService.selectedEventBookingBatchId != null">
                    {{ 'Mailshot.Label.NotStartetYet' | texttransform }}
                </div>                
                
                <div class="defaultDashboardMailshotKPIs" *ngIf="kpis != null && mode == 1 && 
                    getEventBookingBatch(applicationInfoService.selectedEventBookingBatchId)?.startedAt != null"
                    (click)="getDashboardResults()">
                    <div class="defaultDashboardMailshotKPIs__boxes">
                        <div *ngFor="let kpiItem of kpis" [ngClass]="{
                    hideInDom: kpiItem.event_type == 'Failure' || kpiItem.event_type == 'Error' || kpiItem.event_type == 'Complaint'
                }" class="defaultDashboardMailshotKPIs__box">
                            <div class="defaultDashboardMailshotKPIs__box__percentage">{{ kpiItem.percentage }}%</div>
                            <div class="defaultDashboardMailshotKPIs__box__type">{{ kpiItem.translation | texttransform
                                }}</div>
                        </div>
                    </div>
                    <div class="defaultDashboardMailshotKPIs__charts">
                        <div class="defaultDashboardMailshotKPIs__chart defaultDashboardMailshotKPIs__chartArrange chartBorder"
                            *ngIf="kpisChart != null">
                            <app-bar-chart [height]="300" [chartData]="kpisChart" component="mailshotKPIChart"
                                [controlid]="agentwvlChart"></app-bar-chart>
                        </div>
                        <div *ngIf="deviceDataPie != null" class="defaultDashboardMailshotKPIs__chart"
                            [ngClass]="{ defaultDashboardMailshotKPIs__chartArrange: kpisChartArrange }">
                            <app-layout [header]="'Mailshots.Label.Devices' | texttransform">
                                <app-echarts class="maxWidth" id="#4567" [chart]="'pie'" [chartData]="deviceDataPie"
                                    [height]="255"></app-echarts>
                            </app-layout>                           
                        </div>
                    </div>

                    <div class="defaultDashboardMailshot24hr" *ngIf="eventHoursChart != null">
                        <app-layout [header]="'Mailshots.Label.Overview24Hours' | texttransform"
                            *ngIf="eventHoursChart != null">
                            <app-echarts class="maxWidth" id="#mailshotChart" [createChartOnLoad]="false" [height]="400"
                                [options]="eventHoursChart" [legend]=showLegend [legendPosition]="{'top': 10 }"></app-echarts>
                        </app-layout>
                    </div>                   
                </div>

                <div class="defaultDashboardMailshotMode3" *ngIf="kpis != null && mode == 3 && 
                    getEventBookingBatch(applicationInfoService.selectedEventBookingBatchId)?.startedAt != null">
                    <div class="everleadContentContainer" class="maxHeight">
                        <div [innerHTML]="innerHTMLMode3"></div>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>

</div>