
<div class="customTextControlWrapper" [class.disabled]="!checkIfControlIsEnabled()">
  <label class="customTextControlWrapper__label" [class.active]="isFocused && (checkIfControlIsEnabled()  == true )" [class.disabled]="!checkIfControlIsEnabled()">{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}
    <span *ngIf="controlDefinition.isRequired"> *</span>
  </label>

  <div class="customTextControlWrapper__valueWrapper" [class.active]="isFocused && (checkIfControlIsEnabled() == true)" [class.disabled]="!checkIfControlIsEnabled()">
    <input 
    *ngIf="inputType == 'text'" 
    type="text" 
    [disabled]="!checkIfControlIsEnabled()"
    [placeholder]="getPlaceholder() | texttransform" 
    (change)="checkInputValue()" 
    [tippy]="controlValue"
    [(ngModel)]="controlValue" 
    (keydown)="keyDown()"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    >

    <input 
    *ngIf="inputType == 'number'" 
    type="number"
    [disabled]="!checkIfControlIsEnabled()"
    [placeholder]="getPlaceholder() | texttransform" 
    (change)="checkInputValue()" 
    [tippy]="controlValue"
    [(ngModel)]="controlValue"
    (focus)="handleFocus()"
    (blur)="handleBlur()" 
    (keydown)="keyDown()" onlynumber>
  </div>
</div>



<!-- <div class="customTextControlWrapper">
  <label class="customTextControlWrapper__label" [ngClass]="{ customTextControlWrapper__coralColor : isRequired }"
  >
    {{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}
    <span *ngIf="controlDefinition.isRequired"> *</span>
  </label>

  <div class="customTextControlWrapper__valueWrapper">
    <input *ngIf="inputType == 'text'"
      class="customTextControlWrapper__textbox"
      type="text"
      [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform"
      (change)="checkInputValue()"
      [tippy]="controlValue"
      [(ngModel)]="controlValue"
      (keydown)="keyDown()"
    >
    <input *ngIf="inputType == 'number'"
      class="customTextControlWrapper__textbox"
      type="number"
      [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform"
      (change)="checkInputValue()"
      [tippy]="controlValue"
      [(ngModel)]="controlValue"
      (keydown)="keyDown()"
      onlynumber
    >
  </div>

</div>
 -->
