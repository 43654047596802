<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>


<div class="prospectWrapper">
    <div class="try" *ngIf="featureNotFound == false">
        <app-navbar [options]="[
            {id: 0, hidden: false , label: 'ProspectsDashboard.Tab.Table'},
            {id:1, hidden:false, label: 'ProspectsDashboard.Tab.Chart'}
        ]" (onNavItemChange)="viewMode = $event"></app-navbar>
     </div>
    
    <div class="everleadContentContainer prospect" *ngIf="featureNotFound == false">
        <div class="prospect__layout">
            <div *ngIf="viewMode === 0">
                <div [outerHTML]="innerHTML"></div>
            </div>
            <div *ngIf="viewMode === 1">
              <app-prospects-dashboard></app-prospects-dashboard>
            </div>
        </div>    
    </div>    
</div>
<!-- 
<div class="everleadContentContainer prospect" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] == 'true' && featureNotFound == false">
    <div [outerHTML]="innerHTML"></div>
</div>
 -->
