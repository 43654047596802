<div class="processWrapper">      
    <div class="processOptions">
        <app-checkbox label="ProcessDesign.Label.ShowOnlyActive" [(ngModel)]="showOnlyActive" class="agComponentHeader__item"></app-checkbox>
        <app-main-button type="small" svg="tree-view" (onClick)="createNewWorkflow()" label="ProcessDesign.Label.NewWorkflow"
            [tippy]="'General.Label.CreateNewWithWizard' | texttransform" [tippyOptions]="{placement:'bottom'}" class="agComponentHeader__item"></app-main-button>
        <app-filter (keyUp)="filterString = $event" class="agComponentHeader__item"></app-filter>
    </div>

    <div class="jjtabWrapper" *ngIf="navBarWorkflows.length > 0">
        <app-navbar [selectedId]="navBarWorkflows[0].id" [options]="navBarWorkflows" (onNavItemChange)="selectWorkflowById($event)"></app-navbar>       
    </div>

    <app-layout [header]="selectedWorkflow.defaultName" *ngIf="selectedWorkflow != null">
        <div class="processDetails">
            <div class="processDetails__name">
                <app-wizard-translations svgW=20, svgH=20 [array]="selectedWorkflow" updateBaseArrayExternaldatasourceId=531 
                    [exclude]="excludeItemsWorkflow" mode=3></app-wizard-translations>    
                <div class="processId" *ngIf="applicationInfoService.isDeveloper">{{ selectedWorkflow.id }}</div>
                <input [(ngModel)]="selectedWorkflow.defaultName" (change)="saveWorkflow()">        
            </div>
            <div class="processDetails__buttons">
                <app-main-button type="small" svg="tree-view" (onClick)="createNewStep(selectedWorkflow)" label="ProcessDesign.Label.CreateNewStep"
                    [tippy]="'ProcessDesign.Label.CreateNewStep' | texttransform" [tippyOptions]="{placement:'bottom'}" class="agComponentHeader__item"></app-main-button>
            </div>
        </div>
    
        <div class="processContent" *ngIf="selectedWorkflow != null">
            <div class="stepList">
                <div *ngFor="let step of selectedWorkflow.workflowSteps">
                    <app-process-step [step]="step" [workflows]="workflows" [resultCounts]="resultCounts" 
                        [showOnlyActive]="showOnlyActive" *ngIf="step.isActive || showOnlyActive == false"
                        [eventTrigger]="eventTrigger" [leadStates]="leadStates" [qmUserGroups]="qmUserGroups"></app-process-step>
                </div>            
            </div>
        </div>    
    </app-layout>
</div>
