<div  class="accountGroup__wrapper">
    
    <div class="accountGroup__nav">
        <div class="accountGroup__tabs">
            <app-scroll-tabs>
                <app-tab svg="calendar-dots" [active]="mode == 1" [label]="'AccountGroup.Label.GroupHeader' | texttransform" (onClick)="mode = 1"></app-tab>
                <app-tab svg="calendar-dots" [active]="mode == 2" [label]="'AccountGroup.Label.OwnerList' | texttransform" (onClick)="mode = 2"></app-tab>
            </app-scroll-tabs>
        </div>

        <div class="accountGroup__tabsMobile">
            <div class="everleadMobileTab">
                <div class="everleadMobileTab__valueWrapper">
                    <select [(ngModel)]="mode" class="selectTab__box">
                        <option [ngValue]="1">{{'AccountGroup.Label.GroupHeader' | texttransform }}</option>
                        <option [ngValue]="2">{{'AccountGroup.Label.OwnerList' | texttransform }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mode==1">
        <span *ngIf="loading" class="accountGroup__spinner">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </span>
        <div *ngIf="accountGroup.length == 0 && !loading" class="accountGroup__infoLabel">
            {{ 'AccountGroup.Label.NoItems' | texttransform }}
        </div>    
    </div>

    <span id="accountGroupMainContainer"></span>
    <div class="accountGroup__mainComponentLayer">
        <div class="accountGroup__groups" *ngIf="accountGroup.length > 0 && mode==1" [style.height]="getControlHeight('accountGroupMainContainer')">
            <div *ngFor="let accountGroupItem of accountGroup" class="accountGroup__layoutOuter" [ngClass]="{ hideInDom: isItemFiltered(accountGroupItem) }">            
                <!-- Parent -->

                <app-layout [header]="showParents ? accountGroupItem.parent.name3 : accountGroupItem.subsidary.name1">
                    <div class="accountGroup__layer">

                        <div class="accountGroup__formLeft">
                            <div class="accountGroup__parent" *ngIf="showParents">
                                <div class="accountGroup__parent__inner">
                                    <div class="accountGroup__field">
    <!--                                     <label>Vorname</label>
                                        <input [(ngModel)]="accountGroupItem.parent.name2" (change)="updateAccount(accountGroupItem.parent)"> -->
                                        <app-text-input 
                                        [(ngModel)]="accountGroupItem.parent.name2"
                                        label="Vorname" 
                                        (valueChange)="updateAccount(accountGroupItem.parent)"></app-text-input>
                                    </div>
                                    <div class="accountGroup__field">
                <!--                       <label>Nachname</label>

                                        <input [(ngModel)]="accountGroupItem.parent.name1" (change)="updateAccount(accountGroupItem.parent)"> -->
                                        <app-text-input label="Nachname" [(ngModel)]="accountGroupItem.parent.name1" (valueChange)="updateAccount(accountGroupItem.parent)"></app-text-input>
                                    </div>
                                    <div class="accountGroup__addressBlock">
                                        <div class="accountGroup__field ellipsisText">{{ accountGroupItem.parent.street }} {{ accountGroupItem.parent.hausnummer }} {{ accountGroupItem.parent.hausnrzusatz }}</div>
                                        <div class="accountGroup__field ellipsisText">{{ accountGroupItem.parent.zipcode }} {{ accountGroupItem.parent.city }}</div>
                                        <div *ngIf="targetHasOptout(accountGroupItem.parent)" class="accountGroup__field ellipsisText">
                                            {{ 'AccountGroup.Label.OptOutFound' | texttransform }}
                                        </div>   

                                    </div>
                                    <!-- <div class="accountGroup__item__name__infoLine ellipsisText">{{ accountGroupItem.parent.street }} {{ accountGroupItem.parent.hausnummer }} {{ accountGroupItem.parent.hausnrzusatz }}</div>
                                    <div class="accountGroup__item__name__infoLine ellipsisText">{{ accountGroupItem.parent.zipcode }} {{ accountGroupItem.parent.city }}</div>
                                    <div *ngIf="targetHasOptout(accountGroupItem.parent)" class="accountGroup__item__name__infoLine__dial accountGroup__item__name__infoLine ellipsisText everleadCoral jjBold">
                                        {{ 'AccountGroup.Label.OptOutFound' | texttransform }}
                                    </div>    -->            
                                    <div class="accountGroup__flexField">
                                    <!--     <i class="fas fa-envelope accountGroup__item__name__infoLine__mail"></i>
                                        <input [(ngModel)]="accountGroupItem.parent.email" (change)="updateMail(accountGroupItem.parent)"> -->
                                        <app-jj-icons type="envelope" width="20" height="20" [pointer]="false"></app-jj-icons>
                                        <app-text-input [(ngModel)]="accountGroupItem.parent.email" (valueChange)="updateMail(accountGroupItem.parent)"></app-text-input>
                                    </div>
                                    <div class="accountGroup__flexField" *ngIf="!targetHasOptout(accountGroupItem.parent)">
                                        <app-jj-icons type="phone" width="20" height="20" (click)="callNumber(accountGroupItem.parent)" tippy="Rufen diese Nummer an" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
                                        <app-text-input [(ngModel)]="accountGroupItem.parent.phone" (valueChange)="updatePhone(accountGroupItem.parent)"></app-text-input>
                                        <app-jj-icons type="globe" width="20" height="20" tippy="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.parent)" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
                                        <app-jj-icons type="magnifying-glass" width="20" height="20" tippy="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.parent)" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>

    <!--                                     <svg-icon src="assets/images/svg/iconset/icon-phone.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="callNumber(accountGroupItem.parent)"></svg-icon>
                                        <input [(ngModel)]="accountGroupItem.parent.phone" (change)="updatePhone(accountGroupItem.parent)">
                                        <i class="fas fa-globe fa-lg fingerCursor" title="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.parent)"></i>
                                        <i class="fas fa-search fa-lg fingerCursor" title="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.parent)"></i> -->
                                    </div>
                                    <div class="accountGroup__flexField" *ngIf="!targetHasOptout(accountGroupItem.parent)">
                                        <app-jj-icons type="phone" width="20" height="20" (click)="callNumber(accountGroupItem.parent)" tippy="Rufen diese Nummer an" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
                                        <app-text-input [(ngModel)]="accountGroupItem.parent.mobile" (valueChange)="updatePhone(accountGroupItem.parent)"></app-text-input>
                                        <app-jj-icons type="globe" width="20" height="20" tippy="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.parent)" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
                                        <app-jj-icons type="magnifying-glass" width="20" height="20" tippy="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.parent)" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
    <!-- 
                                        <svg-icon src="assets/images/svg/iconset/icon-phone.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="callNumber(accountGroupItem.parent)"></svg-icon> -->
                                    <!--  <input [(ngModel)]="accountGroupItem.parent.mobile" (change)="updatePhone(accountGroupItem.parent)"> -->
    <!--                                     <i class="fas fa-globe fa-lg fingerCursor" title="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.parent)"></i>
                                        <i class="fas fa-search fa-lg fingerCursor" title="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.parent)"></i> -->
                                    </div>
                                </div>
                            </div>
                        
                            <div class="accountGroup__parent" *ngIf="!showParents">
                                <div class="accountGroup__parent__inner">
                                    <div class="accountGroupp__addressBlock">
                                        <div class="accountGroup__field">{{ accountGroupItem.subsidary.name1 }}</div>
                                    </div>
                                 
                                    <div class="accountGroup__addressBlock">
                                        <div class="accountGroup__field">
                                            {{ accountGroupItem.subsidary.id3w }}
                                        </div>
                                    </div>
                       
                                    <div class="accountGroup__addressBlock">
                                        <div class="accountGroup__field ellipsisText">{{ accountGroupItem.subsidary.street }} {{ accountGroupItem.subsidary.hausnummer }} {{ accountGroupItem.subsidary.hausnrzusatz }}</div>
                                        <div class="accountGroup__field ellipsisText">{{ accountGroupItem.subsidary.zipcode }} {{ accountGroupItem.subsidary.city }}</div>
                                        <div *ngIf="targetHasOptout(accountGroupItem.subsidary)" class="accountGroup__field ellipsisText">
                                            {{ 'AccountGroup.Label.OptOutFound' | texttransform }}
                                        </div> 
                                    </div>    
                                
                                    <div class="accountGroup__flexField" *ngIf="!targetHasOptout(accountGroupItem.subsidary)">
                                        
                                        <app-jj-icons type="phone" width="20" height="20" (click)="callNumber(accountGroupItem.subsidary)" tippy="Rufen diese Nummer an" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
                                        <app-text-input [(ngModel)]="accountGroupItem.subsidary.phone" (valueChange)="updatePhone(accountGroupItem.subsidary)"></app-text-input>
                                        <app-jj-icons type="globe" width="20" height="20" tippy="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.subsidary)" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
                                        <app-jj-icons type="magnifying-glass" width="20" height="20" tippy="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.subsidary)" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
    <!--                                     <svg-icon src="assets/images/svg/iconset/icon-phone.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="callNumber(accountGroupItem.subsidary)"></svg-icon>
                                        <input [(ngModel)]="accountGroupItem.subsidary.phone" (change)="updatePhone(accountGroupItem.subsidary)">
                                        <i class="fas fa-globe fa-lg fingerCursor" title="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.subsidary)"></i>
                                        <i class="fas fa-search fa-lg fingerCursor" title="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.subsidary)"></i> -->
                                    </div>            
                                    <div class="accountGroup__flexField" *ngIf="!targetHasOptout(accountGroupItem.subsidary)">
                                        <app-jj-icons type="phone" width="20" height="20" (click)="callNumber(accountGroupItem.subsidary)" tippy="Rufen diese Nummer an" [tippyOptions]="{placement: 'bottom'}"></app-jj-icons>
                                        <app-text-input [(ngModel)]="accountGroupItem.subsidary.mobile" (valueChange)="updatePhone(accountGroupItem.subsidary)"></app-text-input>
                                        <app-jj-icons type="globe" width="20" height="20" tippy="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.subsidary)" [tippyOptions]="{ placement: 'bottom'}"></app-jj-icons>
                                        <app-jj-icons type="magnifying-glass" width="20" height="20" tippy="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.subsidary)" [tippyOptions]="{ placement : 'bottom'}"></app-jj-icons>
                                    <!--  <svg-icon src="assets/images/svg/iconset/icon-phone.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="callNumber(accountGroupItem.subsidary)"></svg-icon> -->
                                    <!--    <input [(ngModel)]="accountGroupItem.subsidary.mobile" (change)="updatePhone(accountGroupItem.subsidary)"> -->
    <!--                                     <i class="fas fa-globe fa-lg fingerCursor" title="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.subsidary)"></i> -->
    <!--                                     <i class="fas fa-search fa-lg fingerCursor" title="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.subsidary)"></i> -->
                                    </div>            
                                    <div class="accountGroup__flexField">
                                        <app-jj-icons type="envelope" width="20" heigh="20" ></app-jj-icons>
                                        <app-text-inputs [(ngModel)]="accountGroupItem.subsidary.email" (valueChange)="updateMail(accountGroupItem.subsidary)"></app-text-inputs>
                            <!--           <i class="fas fa-envelope accountGroup__item__name__infoLine__mail"></i>
                                        <input [(ngModel)]="accountGroupItem.subsidary.email" (change)="updateMail(accountGroupItem.subsidary)"> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accountGroup__formRight">
                            <div class="accountGroup__comments">
                                <div class="accountGroup__field" *ngIf="!showParents">
    <!--                                 <label>Kommentar (Objekt)</label>
                                    <textarea [(ngModel)]="accountGroupItem.subsidary.comments" type="text" (change)="updateAccount(accountGroupItem.subsidary)"></textarea> -->
                                    <app-text-area-input resize="none" label="Kommentar (Objekt)" [(ngModel)]="accountGroupItem.subsidary.comments" (valueChange)="updateAccount(accountGroupItem.subsidary)"></app-text-area-input>
                                </div>
                                <div class="accountGroup__field" *ngIf="showParents">
                                <!--  <label>Kommentar (Eigentümer)</label>
                                    <textarea [(ngModel)]="accountGroupItem.parent.comments" type="text" (change)="updateAccount(accountGroupItem.parent)"></textarea> -->
                                    <app-text-area-input  resize="none" label="Kommentar (Eigentümer)" [(ngModel)]="accountGroupItem.parent.comments" (valueChange)="updateAccount(accountGroupItem.parent)"></app-text-area-input>
                                </div>    
                                <div class="accountGroup__field">
    <!--                                 <label>Kommentar (Verbindung)</label>
                                    <textarea [(ngModel)]="accountGroupItem.comment" type="text" (change)="updateAccountGroup(accountGroupItem)"></textarea> -->
                                    <app-text-area-input resize="none" label="Kommentar (Verbindung)" [(ngModel)]="accountGroupItem.comment" (valueChange)="updateAccountGroup(accountGroupItem)"></app-text-area-input>
                                </div>    
                            </div>

                            <div class="accountGroup__buttons">
                                <app-main-button type="borderSmall" label="CompactTaskList.Label.Delete" svg="trash"  (onClick)="deleteAccountGroup(accountGroupItem)"></app-main-button>
                                <app-main-button type="borderSmall" label="CompactTaskList.Label.LoadTask" svg="caret-circle-right" (onClick)="loadAccount(accountGroupItem)"></app-main-button>
                               
                                
                        <!--        <button (click)="loadAccount(accountGroupItem)" class="maxWidth everleadCustomButton">
                                    {{ 'CompactTaskList.Label.LoadTask' | texttransform }} <i class="far fa-caret-square-right"></i>
                                </button>
                                <button  (click)="deleteAccountGroup(accountGroupItem)" class="maxWidth everleadCustomButton" [title]="'CompactTaskList.Label.Delete' | texttransform">
                                    {{ 'CompactTaskList.Label.Delete' | texttransform }} <i class="fas fa-trash"></i>
                                </button> -->
                            </div> 

                        </div>
                    </div>
                </app-layout>

        
            </div>
        </div>

        <div class="accountGroup__groups" *ngIf="mode==2" [style.height]="getControlHeight('accountGroupMainContainer')">
            <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
        </div>
    </div>

</div>