import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-usermanagementuserdetails',
  templateUrl: './usermanagementuserdetails.component.html',
  styleUrl: './usermanagementuserdetails.component.scss'
})

export class UsermanagementuserdetailsComponent implements OnInit {
  user = null;
  tab = 0;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private sharedAPI: SharedAPI
  ) {}

  ngOnInit(): void {
    this.user = this.applicationInfoService.miscSettings['userManagementSeletedUser'];
  }

  activateUser() {

  }

  resetUserPassword() {
    this.sharedAPI.resetUserPassword({
      email: this.user.email,
      projectId: this.applicationInfoService.projectID,
      languageId: 2
    }).subscribe(() => {

    });
  }
}
