import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EventbookingbatchService } from 'app/jollyjupiter/controls/static/eventbookingbatch/eventbookingbatch.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-defaultdashboard-mailcampaign',
  templateUrl: './defaultdashboard-mailcampaign.component.html',
  styleUrl: './defaultdashboard-mailcampaign.component.scss'
})
export class DefaultdashboardMailcampaignComponent implements OnInit, OnDestroy {
  @Input() selectedReport = null;
  customEventSubscription: Subscription = new Subscription();
  dashboardResultData = null;
  loadingData = false;
  mailCampaigns = [];
  selectedCampaignId = null;
  dashboardResults = [];
  dashboardResultBoxes = [];
  deviceData = null;
  deviceDataPie = null;
  kpis = null;
  kpisChart = [];
  eventHours = [
    { event_hour: 1, event_type: 'Open', event_count: 12 },
    { event_hour: 2, event_type: 'Open', event_count: 4 },
    { event_hour: 3, event_type: 'Open', event_count: 6 },
    { event_hour: 4, event_type: 'Open', event_count: 8 },
    { event_hour: 1, event_type: 'Click', event_count: 9 },
  ];
  eventHoursChart = null;
  selectedMailshotDetailList = null;
  selectedMailshotDetailListArray = null;
  mailshotDetailListOptions = [];
  mode = 1;
  modes = [
    { id: 1, defaultName: 'Mailshots.Label.Mode1' },
    { id: 2, defaultName: 'Mailshots.Label.Mode2' }
  ];
  eventBookingBatches = [];
  loadingChart= false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public dashboardcoreService: DashboardcoreService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventbookingbatchService: EventbookingbatchService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private commonService: CommonService,
    private methodService: MethodService
  ) {}

  
  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {        
        this.getEmailCampaigns();
      }
    });    
    this.getEmailCampaigns();
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getDashboardDetails(dashboardId, type = null): Promise<any> {
    this.loadingData = true;
    return new Promise((getDashboardDetailsResolve, getDashboardDetailsReject) => {
      let params = this.fillParams(type);
      this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {      
        this.loadingData = false;
        getDashboardDetailsResolve(getDashboardDataArrayResult);
      }).catch((error)=>{
        this.loadingData = false;
        getDashboardDetailsReject(error);
      });  
    });
  }

  fillParams(eventType = null) {
    const params = [];   
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@campaign_id', value: this.selectedCampaignId });
    if (eventType) {
      params.push({ key: '@event_type', value: eventType });
    }
    return params;
  }

  getEmailCampaigns() {
    this.mailCampaigns = [];    
    this.applicationInfoService.campaigns.toArray().forEach(campaign => {
      const mailShotCampaign = this.eventBookingBatches.filter(eventBookingBatche => eventBookingBatche.wizardValueArray.campaignId == campaign.id);
      if (campaign.sesConfigSetId != null && mailShotCampaign.length == 0) {
        this.mailCampaigns.push(campaign);
      }      
    });
    if (this.selectedCampaignId == null && this.mailCampaigns.length == 1) {
      this.selectedCampaignId = this.mailCampaigns[0].id;
      this.getMailCampaignData();
    }
  }

  openContactPopup(selectedMailshotDetailListItem) {
    this.methodService.loadContactInformation(selectedMailshotDetailListItem.contact_id);
  }

  getEventBookingBatches() {
    // To separate from normal bookings
    // if (this.applicationInfoService.getCompleteProjectInformatonLoaded == false) { return; }
    // this.externaldatasourceService.executeExternalDataSource(this.eventbookingbatchService.getBookingBatchExternalDataSourceId).then(getEventBookingBatchesResult => { 
    //   this.eventBookingBatches = getEventBookingBatchesResult.filter(batch => 
    //     batch.batchTypeLookupId == 7421 &&
    //     batch.isDeleted == false
    //   );       
    //   this.eventBookingBatches.sort((a, b) => a.id < b.id ? -1 : 1);
    //   this.eventBookingBatches.forEach(batchType => {
    //     batchType['wizardValueArray'] = JSON.parse(batchType.wizardValues);
    //   });
    //   if (this.applicationInfoService.campaingnModeId != null) {
    //     this.eventBookingBatches = this.eventBookingBatches.filter(batch => batch.wizardValueArray.campaignId == this.applicationInfoService.campaingnModeId);
    //   }
    //   this.getEmailCampaigns();
    // });    
  }

  getMailCampaignData() {
    this.deviceData = null;
    this.kpis = null;
    this.kpisChart = null;
    this.selectedMailshotDetailList = null;
    this.selectedMailshotDetailListArray = null;
    this.mailshotDetailListOptions = [];
    this.getDashboardDetails(this.selectedReport.dashboardId).then(getMailCampaignDataResult => {
      setTimeout(() => {
        this.kpis = [];
        let counter = 1;
        this.selectedMailshotDetailListArray = [];
        const dashboardResult = getMailCampaignDataResult[0][0].mailcampaign_event_statistic_by_type;
        dashboardResult.forEach(item => {
          item['translation'] = this.getTypeTranslation(item.event_type);
          if (item.event_type != 'Total') {
            this.mailshotDetailListOptions.push({ id: counter, type: item.event_type, defaultName: this.getTypeTranslation(item.event_type)});  
          }
          counter ++;
        });
        this.kpis.push(dashboardResult.find(item => item.event_type == 'Send'));    
        this.kpis.push(dashboardResult.find(item => item.event_type == 'Delivery'));
        this.kpis.push(dashboardResult.find(item => item.event_type == 'Open'));
        this.kpis.push(dashboardResult.find(item => item.event_type == 'Click'));
        this.kpis.push(dashboardResult.find(item => item.event_type == 'Bounce'));   
        this.kpisChart = JSON.parse(JSON.stringify(this.kpis)).reverse();
      }, 200);
    });
    
    this.dashboardResults = [];     
    this.getDevices();
  }

  getDevices() {
    this.deviceData = null;
    this.deviceDataPie = null;
    this.getDashboardDetails(this.selectedReport.dashboardIdDevices).then(getDevicesResult => {
      if (getDevicesResult.length > 0) {
        this.deviceData = getDevicesResult[0][0].mail_campaign_device_type;
        if (this.deviceData.length > 0) {
          this.deviceData = getDevicesResult[0][0].mail_campaign_device_type.filter(items => items.event_type == 'Open' );        
          if (this.deviceData.length == 0) {
            this.deviceDataPie = { color: ['#5465FF', '#5162F6','#788BFF'], data: [], type: 'pie' }
          } else {
            this.deviceDataPie = { color: ['#5465FF', '#5162F6','#788BFF'], data: [], type: 'pie' }
            this.pushDataToArray(this.deviceDataPie.data, 'Mobile', this.deviceData, 1);
            this.pushDataToArray(this.deviceDataPie.data, 'Desktop', this.deviceData, 2);
            this.pushDataToArray(this.deviceDataPie.data, 'Unknown', this.deviceData, 3);
          }
        }          
      }
    });
  }

  pushDataToArray(data, section, deviceData, device_type) {
    const arrayItem = deviceData.find(item => item.device_type == device_type);
    let itemCount = 0;
    if (arrayItem) {
      itemCount = arrayItem.event_count;
    }
    data.push({ name: section , value: itemCount });
  }

  getDashboardResults() {
    if (this.applicationInfoService.isDeveloper) {
      console.warn(this.dashboardResults);
    }    
  }

  getTypeTranslation(event_type) {
    let returnValue = 'Mailshots.Label.';
    switch(event_type) {
      case 'Delivery': returnValue = returnValue.concat('Delivered'); break;
      case 'Click': returnValue = returnValue.concat('LinkClicked'); break;
      case 'Open': returnValue = returnValue.concat('Open'); break;
      case 'Bounce': returnValue = returnValue.concat('CountBounced'); break;
      case 'Send': returnValue = returnValue.concat('Send'); break;
      case 'Error': returnValue = returnValue.concat('Error'); break;
      case 'Complaint': returnValue = returnValue.concat('Complaint'); break;
      case 'Failure': returnValue = returnValue.concat('Failure'); break;
    }    
    return returnValue;
  }
}
