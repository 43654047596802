<div>
    <div *ngIf="asterisk.length > 0" class="asteriskLabels">
        <span *ngFor="let label of asterisk">* {{label}}</span>
    </div>
    
    <div 
    id="chartTasks" 
    echarts [options]="options" 
    class="barChart" 
    [style.height]="getHeight()"
    [style.width]="getWidth()"></div>

</div>
