import { AfterContentInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInputComponent } from '../text-input/text-input.component';

@Component({
  selector: 'app-text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrl: './text-area-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaInputComponent),
      multi: true
    },
  ]
})
export class TextAreaInputComponent implements ControlValueAccessor, AfterContentInit {

  constructor(){}

  @Input() id: number | string | null = null;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() height: number | string | null = null;
  @Input() fire: boolean | null = false;
  @Input() error: boolean | null = false;

  @Input() info: string | null = null;
  @Input() maxHeight = false;
  @Input() position: string = 'static';
  @Input() resize: string = 'both';

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() click = new EventEmitter<void>();
  @Output() keyUp = new EventEmitter<KeyboardEvent>();
  

  value: string = '';
  isFocused: boolean = false;

/*   private debounceTimer: any; */


  ngAfterContentInit(): void {
   //console.log('this.height : text-area', this.height)
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  handleFocus(){
    if(!this.disabled){
      this.isFocused = true;
    }
  }


  handleBlur(){
    if(!this.disabled){
      this.isFocused = false;
      this.onTouched();
    }
  }

  handleInput(value: string) {
    if(!this.disabled){
      this.value = value;
      this.onChange(value);
      this.valueChange.emit(value)
      this.onTouched();

       // !  if text-area fails to change try the debouncer we dont need it
 /*      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.valueChange.emit(this.value);
      }, 300);  */

    }
 
  }


  handleKeyUp(event) {
    if(!this.disabled){
      this.keyUp.emit(event);
    }
  }


  private isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }

  getHeight() {
    if (this.maxHeight) {
      return '100%';
    }

    if(!this.height){ 
      return '80px' 
    } else {
      return this.isNumeric(this.height) ? `${this.height}px` : this.height;
    }

  }



}
