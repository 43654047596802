import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Injectable({
  providedIn: 'root'
})
export class LicensingService {
  mode1Size = '612px;460px';
  mode2Size = '612px;892px';
  mode3Size = '612px;420px';
  mode4Size = '612px;892px';
  mode5Size = '90';
  mode6Size = '612px;460px';
  modeDLCSize = '612px;660px';

  upgradePackageForm = 1;

  currentLicense = null;
  licenseInfo = {
    licenseCount: 0,
    usedLicenseCount: 0,
    freeLicenses: 0
  }
  subscriptionType = '1 month'
  licenseDetails = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private sharedAPI: SharedAPI,
    private messagingService: MessagingService,
    private packagefeatureService: PackagefeatureService
  ) { 
    this.getLicensePaymentDetails(this.applicationInfoService.projectInfo.packageId, 1);
    this.getCurrentLicensingInfo();
  }

  getAllPackagePrices(): Promise<any> {
    return new Promise((getAllPackagePricesResolve, getAllPackagePricesReject) => {
      // const packageArray = [1,2,3,5,6,7,8,9,10];
      const packageArray = [];
      this.packagefeatureService.allPackages.forEach(featurePackage => {
        packageArray.push(featurePackage.id);
      });
      this.externaldatasourceService.executeExternalDataSource(973, [packageArray]).then(getAllPackagePricesResult => {
        // console.warn(getAllPackagePricesResult)
        getAllPackagePricesResolve(getAllPackagePricesResult)
      }).catch(error => { getAllPackagePricesReject(error); });
    });
  }

  getLicenses(): Promise<any> {
    return new Promise((getLicensesResolve, getLicensesReject) => {
      this.externaldatasourceService.executeExternalDataSource(965).then(getLicensesResult => {
        if (getLicensesResult != null) {
          this.licenseInfo = getLicensesResult;
        }        
        getLicensesResolve(getLicensesResult);
      }).catch(error => { getLicensesReject(error); });  
    });
  }

  getLicensePaymentDetails(packageId, licenseAmount): Promise<any> {
    return new Promise((getLicensesResolve, getLicensesReject) => {
      this.externaldatasourceService.executeExternalDataSource(966, [packageId, licenseAmount]).then(getLicenseCostResult => {
        this.licenseDetails = getLicenseCostResult;
        getLicensesResolve(getLicenseCostResult);
      }).catch(error => { getLicensesReject(error); });  
    });
  }

  callCustomerPortal() {
    this.externaldatasourceService.executeExternalDataSource(969, ['']).then(callCustomerPortalLink => {
      this.commonService.openUrl(callCustomerPortalLink);
    });
  }

  getPendingState(): Promise<any> {
    return new Promise((getPendingStateResolve, getPendingStateReject) => {
      this.externaldatasourceService.executeExternalDataSource(970)
      .then(projectSettingsResult => { 
        this.applicationInfoService.projectSettings = projectSettingsResult
        let pendingValue = projectSettingsResult.find(item => item.setting == 'Update pending');
        if (pendingValue) {
          if (pendingValue.value == 'true') {
            pendingValue = true;
          } else {
            pendingValue = false;
          }
        } else {
          pendingValue = false;
        }
        getPendingStateResolve(pendingValue); 
      })
      .catch(error => { getPendingStateReject(error); });
    });
  }

  changePaymentIntervall(packageId, licenceCount, perMonth): Promise<any> {
    return new Promise((changePaymentIntervallResolve, changePaymentIntervallReject) => {
      this.externaldatasourceService.executeExternalDataSource(971, [
        this.commonService.getModifyArrayBody({
          id: packageId, 
          quantity: licenceCount, 
          perMonth: perMonth
        }, [])
      ]).then(changePaymentIntervallResult => {
        changePaymentIntervallResolve(changePaymentIntervallResult);
      })
      .catch(error => {
        changePaymentIntervallReject(error);
      })
    });
  }

  isMonthlySubscription() {   
    if (this.subscriptionType == '1 month') {
      return true
    } else {
      return false
    }
  }

  getCurrentLicensingInfo() {    
    if (
      this.applicationInfoService.getProjectSetting('stripe_subscription_id') != null ||
      this.applicationInfoService.getProjectSetting('sandbox_stripe_subscription_id') != null
    ) {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.getProjectSetting('subscription_type'))) {
        this.subscriptionType = this.applicationInfoService.getProjectSetting('subscription_type').value;
      }
      
      this.externaldatasourceService.executeExternalDataSource(976, []).then(getCurrentLicensingInforesult => {
        this.currentLicense = getCurrentLicensingInforesult;
      });  
    }
  }

  cancelSubscription(immediateCancellation = false) {
    this.sharedAPI.selfServiceCancelSubscription(immediateCancellation).subscribe(() => {
      this.messagingService.showDefaultSuccess(
        'Licensing.Message.CancelSuccessfullHeader',
        'Licensing.Message.CancelSuccessfullMessage'
      );
    });
  }
}
