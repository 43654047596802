import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { GraphQLService, JJApolloClient, ApolloMethod } from 'app/shared/service/graphql.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { Subscription } from 'rxjs';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { WizardMode, WizardService } from '../../wizard/wizard.service';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit, OnDestroy {
  campaignListUpdatedEventSubscription: Subscription = new Subscription();
  leadStateListUpdatedEventSubscription: Subscription = new Subscription();
  wizardEventSubscription: Subscription = new Subscription()
  
  
  workflows = [];
  selectedWorkflow = null;



  workflowList = [];
  workflowSteps = [];
  leadStates = [];
  workflowStepsBackup = [];
  selectedStep = null;
  newValueAdded = false;
  qmUserGroups = [];
  campaigns = [];
  workflowStepResultTypes = [];
  stepTypes = [];
  opportunityStates = [];
  workflowArray = [];
  users = [];
  emailTemplates = [];
  resultArray = []; // resultArray[step]=stepResults
  stepArray = []; // stepArray[processId]=processSteps
  workflowTypes = [];
  newMode = true;
  eventTrigger = [];
  processView = 1;
  steps = [];
  expandAll = false;
  excludeItems = ['lookupTable', 'length', 'nextStepWorkflowId', 'nextStep', 'closingResultAfterXResults'];
  excludeItemsStep = ['lookupTable', 'length'];
  excludeItemsWorkflow = ['length', 'notRelevantStepResult', 'starterStep'];
  visitedPages = [];
  resultCounts = [];
  loadingData = false;

  // NewMode stuff
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['resultName', 'order', 'isActive', 'callAttempt', 'netCall', 'leadState', 'leadStateDesc', 'nextStep', 'nextStepCampaign', 'qm', 'dueInDays',
    'maxDueInDays', 'closeAfterXResults', 'closingResultAfterXResults', 'emailTemplate', 'consentRequired', 'contactRequired', 'personalFollowUp', 'subResults', 'progress'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  collapsedSteps = [];
  // expanded
  expandedWorkflowSteps = [];
  expandedWorkflowStepresults = [];
  showOnlyActive = true;
  filterString = '';

  // Next followup
  followUpProcess = [];
  followUpSteps = [];
  navBarWorkflows = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private messagingService: MessagingService,
    public methodService: MethodService,
    private loaderService: LoaderService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    public jjtranslationService: JjtranslationService,
    public commonService: CommonService,
    private graphQLService: GraphQLService,
    public dragdropService: DragdropService,
    public userService: UserService,
    public wizardService: WizardService,
    private uiService: UiService
  ) { }

  ngOnDestroy() {
    if (this.campaignListUpdatedEventSubscription) { this.campaignListUpdatedEventSubscription.unsubscribe(); }
    if (this.campaignListUpdatedEventSubscription) { this.campaignListUpdatedEventSubscription.unsubscribe(); }
    if (this.wizardEventSubscription) { this.wizardEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.getEventTrigger();
    this.wizardService.fillPagesAndModes();
    this.wizardEventSubscription = this.wizardService.wizardFinishedEvent.subscribe(event => {
      this.getWorkflows();
    })

    if (this.newMode) {
      this.wizardService.processExpertMode = this.applicationInfoService.isDeveloper;
      this.getWorkflows().then(() => {
        this.getLeadStates();
        this.getCampaigns();
        this.getUsers();
        this.getEMailTemplates();
        this.getWorkflowStepResultTypes();
        this.getWorkflowTypes();
        this.getQmUserGroups();
        this.getResultCounts();
      });
    }
  }

  getStepResults(stepId) {
    const step = this.steps.find(x => x.id == stepId);
    if (step) {
      return step.results;
    } else {
      return [];
    }
  }

  getLeadStateDescription(leadStatusId) {
    const leadState = this.leadStates.find(x => x.lookupTable.id == leadStatusId);
    if (leadState) {
      if (leadState.lookupTable.descriptionTranslationToken) {
        return leadState.lookupTable.descriptionTranslationToken.tokenFullName;
      } else {
        return leadState.lookupTable.defaultName;
      }
    } else {
      return '';
    }
  }

  selectWorkflowById(workflowId) {
    const workflowToShow = this.workflows.find(item => item.id == workflowId);
    if (workflowToShow) {
      this.selectWorkflow(workflowToShow);
    }
  }

  selectWorkflow(workflow) {    
    workflow.workflowSteps.sort((a, b) => a.stepOrder < b.stepOrder ? -1 : 1);
    this.selectedWorkflow = workflow;
  }

  saveWorkflow() {    
    this.externaldatasourceService.executeExternalDataSource(531, [
      this.commonService.getModifyArrayBody(this.selectedWorkflow, this.excludeItemsWorkflow)
    ]);
  }

  getWorkflowSteps(workflowId) {
    const workflow = this.workflowList.find(x => x.id == workflowId);
    if (workflow) {
      return workflow.workflowSteps;
    } else {
      return [];
    }
  }

  nextStepChanged(result) {
    result.nextStepId = null;
    this.saveResult(result);
  }

  createNewWorkflow() {
    this.wizardService.createWorkflow().then(createWorkflowResult => {
      this.getWorkflows();
      this.wizardService.showWizard(WizardMode.Process, createWorkflowResult);
    });
  }

  createNewStep(workflow) {
    this.wizardService.showWizard(WizardMode.Step, { defaultName: 'New step', workflowId: workflow.id, isActive: true, typeLookupId: null })
  }

  saveResult(result, sortResult = false, changeToNullIfEmpty = null) {
    const excludeList = ['closingResultAfterXResults', 'nextStep', 'nextStepWorkflowId'];
    if (changeToNullIfEmpty) {
      if (result[changeToNullIfEmpty] == '') {
        result[changeToNullIfEmpty] = null;
      }
    }
    if (!this.applicationInfoService.isDeveloper) {
      excludeList.push('preExecuteSql');
      excludeList.push('postExecuteSql');
    }
    this.externaldatasourceService.executeExternalDataSource(570, [
      this.commonService.getModifyArrayBody(result, excludeList)
    ]);
  }

  createNewStepResult(step) {
    let newResult = {
      isActive: true,
      stepId: step.id,
      defaultName: this.axivasTranslateService.getTranslationTextForToken('ProcessDesign.Label.NewResult'),
      resultOrder: 0,
      isPersonalFollowup: false,
      isNetResult: false,
      isCallAttempt: false,
      nextStepId: null
    }
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(569, [
      this.commonService.getModifyArrayBody(newResult, [])
    ]).then(result => {
      newResult['id'] = result.id;
      this.wizardService.showWizard(WizardMode.Result, newResult);
      this.loaderService.display(false);
      this.getWorkflows();
    })
    .catch(() => {
      this.loaderService.display(false);
    });
  }

  showWizard(array) {
    this.wizardService.showWizard(WizardMode.Process, array);
  }

  showWorkflowStepResultWizard(element) {
    this.wizardService.showWizard(WizardMode.Result, element);
  }

  showStepWizard(array) {
    this.wizardService.showWizard(WizardMode.Step, array);
  }

  showResultWizard(array) {
    this.wizardService.showWizard(WizardMode.Result, array);
  }

  getWorkflowTypes() {
    this.externaldatasourceService.executeExternalDataSource(387, [65]).then(workflowTypesResult => { this.workflowTypes = workflowTypesResult; });
  }

  getEMailTemplates() {
    this.externaldatasourceService.executeExternalDataSource(719, []).then(getEMailTemplatesResult => { this.emailTemplates = getEMailTemplatesResult; });
  }

  getUsers() {
    this.externaldatasourceService.executeExternalDataSource(139, []).then(getUsersResult => { this.users = getUsersResult; });
  }

  scrollIntoView() {
    const element = document.getElementById('scrollIntoViewEmailTemplate');
    if (element) {
      element.scrollIntoView();
    }
  }

  itemIsFiltered(row) {
    let returnValue = true;
    if (row.workflow) {
      if (this.commonService.checkIfStringContainsString(row.workflow.defaultName, this.filterString)) {
        returnValue = false
      }
    }
    if (row.step) {
      if (this.commonService.checkIfStringContainsString(row.step.defaultName, this.filterString)) {
        returnValue = false
      }
    }
    if (row.result) {
      if (this.commonService.checkIfStringContainsString(row.result.defaultName, this.filterString)) {
        returnValue = false
      }
    }
    return returnValue;
  }

  getQmUserGroups() {
    this.qmUserGroups = [];
    this.externaldatasourceService.executeExternalDataSource(95, [295])
    .then(getQmUserGroupsResult => {
      this.qmUserGroups = getQmUserGroupsResult;
    })
    .catch(error => {
      this.messagingService.showDefaultError('getCampaigns', error);
    });
  }

  getWorkflowStepResultTypes() {
    this.workflowStepResultTypes = [];
    this.externaldatasourceService.executeExternalDataSource(126)
    .then(getWorkflowStepResultTypesResult => {
      this.workflowStepResultTypes = getWorkflowStepResultTypesResult;
    })
    .catch(error => {
      this.messagingService.showDefaultError('getWorkflowStepResultTypes', error);
    });
  }

  getCampaigns() {
    this.campaigns = [];
    this.externaldatasourceService.executeExternalDataSource(695)
    .then(getCampaignsResult => {
      this.campaigns = getCampaignsResult;
    })
    .catch(error => {
      this.messagingService.showDefaultError('getCampaigns', error);
    });
  }

  getOpportunityStates() {
    this.externaldatasourceService.executeExternalDataSource(326, []).then(result => {
      this.opportunityStates = result;
    });
  }

  getLeadStates() {
    this.leadStates = [];
    this.externaldatasourceService.executeExternalDataSource(926)
    .then(getLeadStatesResult => {
      this.leadStates = getLeadStatesResult;
    })
    .catch(error => {
      this.messagingService.showDefaultError('getLeadStates', error);
    });
  }

  showEvents(result) {
    this.applicationInfoService.miscSettings['eventtriggerresult'] = result
    this.eventService.showJjPopup('', 'eventtrigger', '80');
  }

  getWorkflows(): Promise<any> {
    return new Promise((getWorkflowsResolve, getWorkflowsReject) => {
      this.externaldatasourceService.executeExternalDataSource(571, [])
      .then(executeExternalDataSourceResult => {
        this.workflows = executeExternalDataSourceResult;
        this.navBarWorkflows = [];
        this.workflows.forEach(workflow => {
          if (workflow.id != 174 || this.applicationInfoService.isDeveloper) {
            this.navBarWorkflows.push({ id: workflow.id, hidden: false, label: workflow.defaultName });
          }          
        });
        if (this.selectedWorkflow != null) {
          this.selectedWorkflow = this.workflows.find(item => item.id == this.selectedWorkflow.id);
        } else {
          this.selectedWorkflow = this.workflows[0];
        }
        getWorkflowsResolve(null);
      });

      return;
      this.workflows = [];
      this.steps = [];
      this.workflowList = [];
      this.selectedWorkflow = null;
      this.loadingData = true;
      //this.loaderService.display(true, false, 'ProcessComponent getWorkflows');
      this.externaldatasourceService.executeExternalDataSource(571, [])
      .then(executeExternalDataSourceResult => {
        this.loadingData = false;
        executeExternalDataSourceResult.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
        executeExternalDataSourceResult.forEach(workflow => {
          if (this.selectedWorkflow == null && workflow.projectId != null) {
            this.selectedWorkflow = workflow;
          }
          this.workflows.push({ workflow: workflow, step: null, result: null, isInfoLine: true, isInfoLineWorkflow: true });
          workflow.workflowSteps.sort((a, b) => a.stepOrder < b.stepOrder ? -1 : 1);
          this.workflowList.push(workflow);

          workflow.workflowSteps.forEach(step => {
            this.workflows.push({
              workflow: workflow,
              step: step,
              result: null,
              isInfoLine: true
            });
            this.steps.push(step);
            step.results.sort((a, b) => a.resultOrder < b.resultOrder ? -1 : 1);
            step.results.forEach(result => {
              result.nextStepWorkflowId = null;
              if (result.nextStep != null) {
                result.nextStepWorkflowId = result.nextStep.workflowId;
              }
              this.workflows.push({
                workflow: workflow,
                step: step,
                result: result
              });
            });
            getWorkflowsResolve(null);
          });
        });
        this.dataSource = new MatTableDataSource(this.workflows);
        this.getVisitedPages();
      })
      .catch(error => {
        this.messagingService.showDefaultError('getWorkflows', error);
        this.loadingData = false;
        getWorkflowsReject(null);
        //this.loaderService.display(false, false, 'ProcessComponent getWorkflows error');
      });
    });
  }

  showLeadstatePopup() {
    this.eventService.showJjPopup('', 'leadstate', 80);
  }

  showCampaignPopup() {
      this.eventService.showJjPopup('', 'campaign', 80);
  }

  callSubquestionForm(result: any) {
    this.applicationInfoService.miscSettings['subQuestionFormCall'] = result;
    this.eventService.showJjPopup('', 'questionanswers', 80, true);
  }

  // createNewRow() {
  //   this.workflows.push({
  //     workflow: null,
  //     step: null,
  //     result: null,
  //     isNewLine: true,
  //   });
  //   this.dataSource = new MatTableDataSource(this.workflows);
  // }

  getTableHeight(control, substractValue = 40) {
      let tableSize = Number(this.uiService.getDesignSpanPosition(control, substractValue));
      return tableSize + 'px';
  }

  stepIsCollapsed(step) {
    if (this.commonService.checkIfItemIsInArray(this.collapsedSteps, step.id)) {
      return true;
    } else {
      return false;
    }
  }

  toggleStepCollapse(step) {
    this.commonService.toggleArrayItem(this.collapsedSteps, step.id);
  }

  expandOrCollapseAll() {
    if(this.expandAll) {
      this.selectedWorkflow.workflowSteps.forEach(step => {
        if(this.commonService.checkIfItemIsInArray(this.collapsedSteps, step.id)) {
          this.commonService.toggleArrayItem(this.collapsedSteps, step.id);
        }
      });
    } else {
      this.selectedWorkflow.workflowSteps.forEach(step => {
        if(!this.commonService.checkIfItemIsInArray(this.collapsedSteps, step.id)) {
          this.commonService.toggleArrayItem(this.collapsedSteps, step.id);
        }
      });
    }
    this.expandAll = !this.expandAll;
  }

  getVisitedPages() {
    this.externaldatasourceService.executeExternalDataSource(612, [WizardMode.Result])
    .then(getVisitedPagesResult => {
      this.visitedPages = getVisitedPagesResult;
    })
  }

  getVisitedPagesForResult(resultId) {
    let returnValue = { pages: 0, maxPages: 10 }
    const count = this.visitedPages.filter(page => page.refId == resultId);
    returnValue.pages = count.length;
    // if (resultId == 3506) {
    //   console.warn(count, this.visitedPages);
    // }
    return returnValue;
  }

  getResultCounts() {
    this.externaldatasourceService.executeExternalDataSource(614, [WizardMode.Result])
    .then(getResultCountsResult => {
      this.resultCounts = getResultCountsResult;
    })
  }

  getResultCount(resultId) {
    const count = this.resultCounts.find(resultCount => resultCount.resultId == resultId);
    if (count) {
      return count.count;
    } else {
      return 0;
    }
  }

  deleteResult(step, result, row = null, element = null) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(615, [result.id])
    .then(() => {
      this.loaderService.display(false);
      this.commonService.removeItemFromArray(step.results, result);
      element.isActive = false;
      if (row) {
        this.getWorkflows();
      }
    })
    .catch(() => {
      this.loaderService.display(false); }
    );
  }

  getResultCountTitle(resultId) {
    let returnValue = this.axivasTranslateService.getTranslationTextForToken('ProcessDesign.Label.BookingAmountTitle');
    returnValue = returnValue.replace('<0>', this.getResultCount(resultId).toString());
    return returnValue;
  }

  getProgressWidth(result, id, visitedPages) {
    let percent = 2;
    const progress = this.wizardService.getProgressOfWizardType(result, id, visitedPages);
    if (progress.visited > 0) {
      percent = (progress.visited / progress.max) * 100;
    }
    return percent + '%';
  }

  getProgressTitle(result, id, visitedPages) {
    let returnValue = '';
    const progress = this.wizardService.getProgressOfWizardType(result, id, visitedPages);
    if (progress.max == progress.visited) {
      returnValue = this.axivasTranslateService.getTranslationTextForToken('ProcessDesign.Label.ProgressAllPagesChecked');
    } else {
      returnValue = this.axivasTranslateService.getTranslationTextForToken('ProcessDesign.Label.ProgressPagesLeftToCheck');
    }
    return returnValue;
  }

  showEventWizard(result, step) {
    let array = null;
    const eventTrigger = this.eventTrigger.find(trigger => trigger.newTaskWorkflowResultId == result.id);
    if (eventTrigger) {
      array = eventTrigger;
    } else {
      array = {
        newTaskWorkflowResultId: result.id,
        newTaskWorkflowStepId: step.id,
        projectId: this.applicationInfoService.projectID,
        isCreateNewTask: true
      };
    }
    this.wizardService.showWizard(WizardMode.EventAssignment, array);
  }

  getEventTrigger() {
    this.externaldatasourceService.executeExternalDataSource(478, []).then(getEventTriggerResult => {
      this.eventTrigger = getEventTriggerResult;
    });
  }

  showDetails(element) {
    console.warn(element)
  }
}
