import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-custom-optinoverview',
  templateUrl: './custom-optinoverview.component.html',
  styleUrls: ['./custom-optinoverview.component.scss']
})
export class CustomOptinoverviewComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  darkMode = false;
  availableMedia = [];
  optStatesResult = null;
  optOutsCollected = false;
  innerHTML = null;
  viewMode = 1;
  optStatesCalculated = [];

  constructor(
    private injector: Injector,
    private domSanitizer: DomSanitizer,
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslationService: AxivasTranslateService,
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngOnInit(): void {
    this.getAvailableMedia();
    this.getOptStates();
    if (this.controlDefinition.additionalSetting3 == 'darkMode') { 
      this.darkMode = true; this.commonService.getTranslationValueFromArray
    }

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.eventType == 'updateEntityTableView') {
        this.optOutsCollected = false;
        this.optStatesResult = [];
        this.optStatesCalculated = [];
        this.getOptStates();
      }
    });

    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml('<jj-universal controlid=6653></jj-universal>');
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) {  this.customEventSubscription.unsubscribe(); }
  }

  getAvailableMedia() {
    const tableData = this.applicationInfoService.projectLookupTables.find(projectLookupTable => projectLookupTable.definitionId == Number(16) && 
      projectLookupTable.source == 'central'
    );
    if (tableData) { this.availableMedia = tableData.lookups; }    
  }

  getStatus(availableMediaItem) { 
    if (!this.commonService.isNullOrUndefined(this.optStatesCalculated[availableMediaItem.id])) {
      return this.optStatesCalculated[availableMediaItem.id];
    }
   
    const returnValue = this.commonService.getOptinStateForMedia(availableMediaItem, this.optStatesResult, this.applicationInfoService.currentContact);
    this.optStatesCalculated[availableMediaItem.id] = returnValue;
    return returnValue;
  }

  getOptStates() {
    this.externaldatasourceService.executeExternalDataSource(62, []).then(getOptStatesResult => { 
      this.optStatesResult = getOptStatesResult; 
      this.optOutsCollected = true;
    });
  }

  createOptInOptOut(availableMediaItem, typeRefId) {
    this.externaldatasourceService.executeExternalDataSource(858, [
      this.commonService.getModifyArrayBody({
        contactId: this.applicationInfoService.currentContact.id,
        accountId: this.applicationInfoService.currentAccount.id,
        lookupMediumRefId: availableMediaItem.id,
        lookupTypeRefId: typeRefId,
        startDate: new Date(Date.now())
      }, [])
    ]).then(() => {
      this.getOptStates();
    })
  }

  getUser(userId) {
    let returnValue = '';
    const projectUser = this.applicationInfoService.projectUsers.toArray().find(user => user.id = userId);
    if (projectUser) {
      returnValue = projectUser.userName;
    }
    return returnValue;
  }

  newOptinOptOutItem() {
    this.applicationInfoService.miscSettings['optinoptouteditorvalue'] = null;
    this.eventService.showJjPopup('OptinOptOutEditor.Label.CreateOptInOptOut', 'optinoptouteditor', '600px; 600px')
  }

  getStateName(availableMediaItem) {
    switch(this.getStatus(availableMediaItem).lookupTypeRefId) {
      case 1815:
        return 'OptInOptOut.Label.DoubleOptIn';
      case 44:
        return 'LookupTable.OptInType.OptOut';
      case 43:
        return 'LookupTable.OptInType.OptIn';  
    }
  }

  getMaxHeight(additionalValueToSubstractFromHeight){
    return Number(this.uiService.getDesignSpanPositionMobile('getMaxHeightSpan', (45) + additionalValueToSubstractFromHeight))
  }
}
