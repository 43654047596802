<app-layout header="Supervisor.Label.ProjectFeatures">
    <div class="projectPack">
        <div class="projectPackageListToolbarMobile" *ngIf="applicationInfoService.isMobile()">
<!--             <app-select-input 
                label="Ausgewähltes Paket" 
                [(ngModel)]="applicationInfoService.projectInfo.packageId" 
                [options]="packages" 
                display="defaultName" 
                bind="id" 
                (valueChange)="selectPackageForProject(applicationInfoService.projectInfo.packageId)"
                *ngIf="applicationInfoService.isMobile()"
            ></app-select-input> -->
            
            <div class="everleadMobileTab">
                <div class="everleadMobileTab__valueWrapper">
                    <select [(ngModel)]="applicationInfoService.projectInfo.packageId" class="selectTab__box" (change)="selectPackageForProject(applicationInfoService.projectInfo.packageId)">
                        <option *ngFor="let package of packages" [ngValue]="package.id">{{ package.defaultName }}</option>
                    </select>
                </div>
            </div>

        </div>


        <div class="projectPackageListToolbar" *ngIf="!applicationInfoService.isMobile()">
            <app-scroll-tabs>
                <app-tab
                svg="package" 
                [active]="tab == package.id"
                 [label]="package.defaultName"
                (onClick)="selectPackageToDisplay(package)" 
                *ngFor="let package of packages"></app-tab>
            </app-scroll-tabs>
           
        </div>


        <div class="projectPackageFeaturesAdditionalInfo" *ngIf="selectedPackage">
            <div class="projectPackageListHeader">
                <app-tab svg="list" [active]="tab == 0" [label]=" 'Features' | texttransform" (onClick)="tab = 0"></app-tab>
            </div>
            <div class="projectPackageList">
                <div *ngFor="let featuresRelation of selectedPackage.featuresRelations"
                    class="projectPackageListFeature">
                    <div class="projectPackageListFeatureView">
                        <app-jj-icons [type]="'eye'" class="fingerCursor" [width]="20" [height]="20"
                            (click)="packagefeatureService.showFeatureInfoPopup(featuresRelation.featureId)"></app-jj-icons>
                    </div>
                    <div class="projectPackageListFeatureName">{{ featuresRelation.defaultName }}</div>
                </div>
            </div>
        </div>
    </div>
</app-layout>
