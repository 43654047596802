<div class="featuressettingsWrapper">
  <div class="featuressettingsWrapper__header">
    <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>

    <div class="featuressettingsWrapper__nav">
      <app-checkbox *ngIf="applicationInfoService.expertMode" [(ngModel)]="showOnlyActive" label="ProcessDesign.Label.ShowOnlyActive"></app-checkbox>
      <app-main-button
      type="small"
      svg="plus"
      (onClick)="addNewFeature()"
      [disabled]="selectedFeature"
      label="Feature.Label.NewFeature"></app-main-button>
      
      
    <!--   <app-filter (keyUp)="filterString"></app-filter> -->


      <div class="filter">
        <div class="filterWrapper">
          <input [(ngModel)]="filterString" [disabled]="selectedFeature">
          <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
        </div>
      </div>
    </div>
  </div>

  <div class="featuressettingsWrapper__inner">
    <div class="featuressettingsWrapper__content" *ngIf="!selectedFeature">
        @for (feature of packagefeatureService.allFeatures; track feature.id; let i = $index) { 
          <div class="featuressettingsWrapper__row" (click)="selectFeature(feature)" *ngIf="selectedFeature == null" [ngClass]="{ hideInDom: !isItemFiltered(feature)}">
            <app-wizard-translations [projectSpecific]="false" [array]="feature" [exclude]="excludeItems" updateBaseArrayExternaldatasourceId=1006 mode="3"></app-wizard-translations>
            <div>{{feature.defaultName}}</div>
          </div>
        }  
    </div>

    <div class="featuressettingsWrapper__contentWrapper" *ngIf="selectedFeature != null">
     
        <div class="featuressettings__header">
          {{ selectedFeature.defaultName }}
          <app-main-button 
          type="small"
          svg="arrow-left"
          (onClick)="selectedFeature= null; featureTab=0"
          label="General.Label.Back"
          ></app-main-button>
        </div>
        <div class="featuressettings__tabsMobile">
          <div class="everleadMobileTab">
            <div class="everleadMobileTab__valueWrapper">
                <select [(ngModel)]="featureTab" class="selectTab__box">
                    <option [ngValue]="0">{{ Settings }}</option>
                    <option [ngValue]="1">{{ Slides }}</option>
                </select>
            </div>
        </div>

        </div>
        <div class="featuressettings__tabs">
          <app-scroll-tabs>
            <app-tab label="Settings" svg="gear-six" [active]="featureTab === 0" (onClick)="featureTab=0"></app-tab>
            <app-tab label="Slides" svg="image" [active]="featureTab ===1" (onClick)="featureTab=1"></app-tab>
          </app-scroll-tabs>
        </div>

        <div class="featuressettings__wrappersettings"*ngIf="featureTab === 0">
          <div class="featuressettings__settings">
                <app-text-input 
                label="Feature.Label.DefaultName"
                [(ngModel)]="selectedFeature.defaultName"
                id="feature-name-{{i}}"
                (valueChange)="updateFeature(selectedFeature)"
                ></app-text-input>

            
            <app-checkbox label="Feature.Label.IsActive" [(ngModel)]="selectedFeature.isActive" id="feature-active-{{i}}" (valueChange)="feature.id"></app-checkbox>
          
            <app-text-area-input
            *ngIf="applicationInfoService.isDeveloper" 
            [(ngModel)]="selectedFeature.notes"
            label="Notes"
            id="feature-notes-{{i}}"
            (valueChange)="updateFeature(selectedFeature)"
            height="100"
            ></app-text-area-input>

            <app-text-area-input
              *ngIf="applicationInfoService.isDeveloper"
              label="Settings"
              [(ngModel)]="selectedFeature.settings"
              (valueChange)="updateFeature(selectedFeature.id)"
              id="feature-settings-{{i}}"
              [height]="250"></app-text-area-input>
          </div>
        </div>
          
        <div *ngIf="featureTab === 1" class="featuressettings__slides">
          <app-featuressettings-slider [featureId]="selectedFeature.id" />
        </div>
          
    </div>

  </div>

</div>

<!-- @if (!packagefeatureService.allFeatures.length) {
  <div style="height: 22px;">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
  </div>
} -->