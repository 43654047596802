import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shortcut-button',
  templateUrl: './shortcut-button.component.html',
  styleUrl: './shortcut-button.component.scss'
})
export class ShortcutButtonComponent implements OnInit{


  @Input() active: boolean | null = false;
  @Input() label: string | null = null;
  
  
  @Output() onClick = new EventEmitter<any>();


  ngOnInit(): void {
/*     console.log('label', this.label);
    console.log('active', this.active); */
  }




  buttonClick(value?:any){
    if(value){
      this.onClick.emit(value);
    } else {
      this.onClick.emit()
    }
  }


}
