<div *ngIf="mode==1" class="wizardItemWrapper__translations" (click)="$event.stopPropagation();getTranslations()"
 [tippy]="'Wizard.Label.Translations' | texttransform" 
 [tippyOptions]="{theme: (array?.nameTranslationTokenId != null || array?.descriptionTranslationTokenId != null) ? 'everleadRed': 'everleadBlue'}"
 [ngClass]="{
    wizardTranslations__editor__header__active: array?.nameTranslationTokenId != null || array?.descriptionTranslationTokenId != null
}">
<app-jj-icons type="globe"></app-jj-icons>

</div>

<div *ngIf="mode==3" class="wizardTranslations__editor__mode3">
    <app-jj-icons type="globe" [width]="svgW ? svgW : 30" [height]="svgH ? svgH : 30"
        [color]="array?.nameTranslationTokenId != null || array?.descriptionTranslationTokenId != null ? 'secondary' : 'gray0'"
        (click)="$event.stopPropagation();showPopup()" [tippy]="'Wizard.Translation.Header' | texttransform"></app-jj-icons>
</div>