<!--  
 <div class="scrollTabs" >
    <button *ngIf="showButtons" (click)="scrollTabs('left')" class="scrollTabButton scrollTabs__btnLeft" [disabled]="leftDisabled">
        <app-jj-icons type="caret-left" color="primaryLight" width="32" height="32"></app-jj-icons>
    </button>
    <div #scrollContainer class="scrollTabs__tabsWrapper">
        <ng-content></ng-content>
    </div>
    <button *ngIf="showButtons" (click)="scrollTabs('right')" class="scrollTabButton scrollTabs__btnRight" [disabled]="rightDisabled">
        <app-jj-icons type="caret-right" color="primaryLight" width="32" height="32"></app-jj-icons>
    </button>
</div> -->

<div class="appScrollTab">
    <button *ngIf="list.isOverflow" (click)="list.scroll(-1)" class="appScrollTab__button">
        <app-jj-icons type="caret-left" color="primaryLight" width="32" height="32"></app-jj-icons>
    </button>
    <div appScrollable #list="appScrollable" [scrollUnit]="150" class="appScrollTab__tabsWrapper" [ngClass]="{ appScrollTab__tabsWrapper__scroll: list.isOverflow }">
        <ng-content></ng-content>
    </div>
    <button *ngIf="list.isOverflow" (click)="list.scroll(1)" class="appScrollTab__button">
        <app-jj-icons type="caret-right" color="primaryLight" width="32" height="32"></app-jj-icons>
    </button>
</div>
