<div class="newTaskWrapper">
    <div class="newTaskBody">
        <div *ngIf="hideIdList" class="newTaskTaskCountContainer">
            <b>{{ newTask?.targetArray?.length }} Task(s) werden angelegt.</b>
            <br>
        </div>

        <app-select-input label="NewTask.Label.Campaign" [(ngModel)]="newTask.campaignId" 
            *ngIf="campaigns?.length > 1"
            [options]="campaigns" display="defaultName" bind="id"></app-select-input>

        <span *ngIf="hideStep == false">
            <app-select-input label="Contact.Label.Header" [(ngModel)]="newTask.contactId" [options]="contacts" 
                display="defaultName" displayMultiValue="firstName;lastName" bind="id"></app-select-input>    
            <app-select-input label="NewTask.Label.Workflow" [(ngModel)]="newTask.workflowId" [options]="workflows" display="defaultName" 
                *ngIf="workflows?.length > 1" bind="id" (valueChange)="workflowChanged()"></app-select-input>   
            <app-select-input label="NewTask.Label.Step" [(ngModel)]="newTask.stepId" [options]="workflowsteps" display="defaultName" bind="id"></app-select-input>    
        </span>

        <app-select-input label="NewTask.Label.FollowUpUserId" [(ngModel)]="newTask.followUpUserId" [options]="users" display="userName" bind="id"></app-select-input>

        <span *ngIf="createMode=='multi' && hideStep == false">
            <label class="newTaskLabel">{{ 'NewTask.Label.Level' | texttransform }}</label>
            <select [(ngModel)]="newTask.level" (change)="levelChanged()" class="newTaskDropdown">
                <option ngValue=273>{{ 'NewTask.Label.AccountLevel' | texttransform }}</option>
                <option ngValue=274>{{ 'NewTask.Label.ContactLevel' | texttransform }}</option>
            </select>
            <br>
        </span>

        <app-checkbox label="NewTask.Label.FollowUpAppointment" [(ngModel)]="newTask.isFollowUpAppointment"></app-checkbox>
        <app-date-input label="NewTask.Label.FollowUpDate" [(ngModel)]="newTask.followUpDate"></app-date-input>	


        <span *ngIf="createMode=='multi'">
            <label class="newTaskLabel">{{ 'NewTask.Label.AssignCp' | texttransform }}</label>
            <input type="checkbox" [(ngModel)]="newTask.assignCp" [disabled]="newTask.level == 274">
            <br>
            <span *ngIf="hideIdList == false">
                <label class="newTaskLabel">{{ 'NewTask.Label.IdList' | texttransform }}</label>
                <textarea (change)="targetChanged()" type="text" [(ngModel)]="newTask.target"></textarea>
                <br>    
            </span>
            <br>
        </span>
    </div>

    <div class="newTaskFooter">
        <app-main-button 
        type="small"
        svg="plus"
        [label]=" createMode != 'multi' ? 'NewTask.Label.CreateNewTask' : 'NewTask.Label.CreateNewTasks'"
        (onClick)="createMode != 'multi' ? methodService.createNewTask(newTask) : methodService.createNewTasks(newTask)"
        [disabled]="newTask.stepId==null || newTask.followUpUserId == null"
        ></app-main-button>
    </div>
</div>