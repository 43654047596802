import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { allIconsLibrary, designerIconsArray, librarySVG } from './icons-list';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import tippy from 'tippy.js';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrl: './icon-selector.component.scss',
      providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => IconSelectorComponent),
          multi: true
        },
      ]
})
export class IconSelectorComponent implements OnInit, AfterViewInit, OnChanges{


  constructor(public applicationInfoService: ApplicationInfoService){}

  @Input() value;
  @Input() disabled;
  @Input() label : any;
  @Input() placeholder:string = 'select svg'

  @ViewChild('selectedIcon',{ static:false}) selectedIcon!:ElementRef;
  @ViewChild('iconsList', { static:false }) iconsList!:ElementRef;
  @ViewChild('parentFieldSelector', { static: false }) parentFieldSelector!:ElementRef;
  
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  selectInstance ;
    // main 

    svgsCollection: any
    library
    selectedValue
    isOpen
  

  // data 
  designer= designerIconsArray
  allIcons = allIconsLibrary;
  parentWidth;


  ngOnInit(): void {

    this.library =  JSON.parse(this.applicationInfoService.applicationSettings['phosphorIcons'])
    this.library = this.library.map((item) =>{
      return{
        name: item.id
      }
    }).sort((a,b) => a.name > b.name ? 1 : -1 )
    this.library.unshift({name:this.placeholder, type:'placeholder'})
/*     this.library = allIconsLibrary
    this.library.unshift({name:this.placeholder, type:'placeholder'}) */
  }

  ngOnChanges(changes: SimpleChanges): void {
/*     console.log('changes', changes); */
  }

  ngAfterViewInit(): void {
    this.buildInstance();
  }

  buildInstance(){
    if(this.parentFieldSelector){
      this.parentWidth = this.parentFieldSelector.nativeElement.clientWidth
    }
    
/*     console.log('parentWidth', this.parentWidth); */

    
    if(this.selectInstance){
      this.selectInstance.destroy()
    } else {
      this.selectInstance = tippy(this.selectedIcon?.nativeElement,{
        content: this.iconsList?.nativeElement,
        placement:'bottom-start',
        trigger: 'click',
        theme:'everlead-selector',
        touch:true,
        interactive:true,
        appendTo:document.body,
        zIndex: 9999999,
        onShow:(instance) =>{

          setTimeout(() =>{
            const rect = this.iconsList.nativeElement.getBoundingClientRect();
            const availablespace = window.innerHeight - rect.top;
/* 
            console.log('rect', rect)
            console.log('available space', availablespace) */
            let maxHeight 
            if(availablespace <= 200 ){
              maxHeight = 400 - 25
              instance.setProps({placement:'top-start'})
            } else {
              maxHeight = availablespace - 25;
              instance.setProps({placement:'bottom-start'})
            }

            instance.popper.style.minWidth=`${this.parentWidth}px`;
            instance.popper.style.maxHeight=`${maxHeight}px`;
            instance.popper.style.overflowY='auto';
          },0)
        },
        onHide: () =>{
          this.isOpen=false;
        },
        onHidden:()=>{
          this.isOpen= false; 
      }
      })
    }

    if(this.parentFieldSelector){
      this.parentWidth = this.parentFieldSelector.nativeElement.clientWidth
    }
  }


  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.selectedValue = value;
    //console.log('this.value', this.value)
  }


  ngAfterContentInit(): void {
      //console.log('fire', this.fire)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    // Handle setting the disabled state if needed
  }

  handleClick(icon:any){
    if(icon.type === 'placeholder'){
      this.valueChange.emit(null)
    } else {
      this.valueChange.emit(icon.name)
    }
    this.selectedValue= icon.name;
    this.selectInstance.hide()
    this.isOpen = false;
  }

  handleArrow(){
    this.isOpen = !this.isOpen
  }


}
