
<div class="customMyNextFollowUp">
    <div class="customMyNextFollowUp__inner">
        <div class="customMyNextFollowUp__nav">
            <app-date-input *ngIf="applicationInfoService.applicationSettings['hideNextFollowUpDate'] != 'true'" 
                [(ngModel)]="startDate" [label]="'MyNextFollowUp.Label.StartDate' | texttransform"></app-date-input>
            <div class="customMyNextFollowUp__buttons">
                <app-jj-icons [type]="'table'" [width]="28" [height]="28" (click)="showList()" 
                    [tippy]="'MyNextFollowUp.Label.ToList' | texttransform"></app-jj-icons>
                <app-jj-icons [type]="!startDate ? 'calendar-dots' : 'magnifying-glass'" [width]="28" [height]="28" (click)="getNextFollowup()"
                    [tippy]="!startDate ? ('MyNextFollowUp.Label.NextAppointment' | texttransform) : ('MyNextFollowUp.Label.Search' | texttransform)"></app-jj-icons>
            </div>            
        </div>
    </div>
</div>
