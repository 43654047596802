<div class="featureWrapper">
  <div class="featureHeader">
    <span *ngIf="packagefeatureService?.getFeatureById(this.featureId).nameTranslationTokenId">
      {{ axivasTranslateService?.getTranslationTextForId(this.packagefeatureService.getFeatureById(this.featureId).nameTranslationTokenId)  | texttransform }}
    </span>
    <span *ngIf="!packagefeatureService?.getFeatureById(this.featureId).nameTranslationTokenId">
      {{ commonService.getTranslationValueFromArray(packagefeatureService.getFeatureById(featureId)) | texttransform }}
    </span>
  
  </div>
  <div class="featureSubHeader">
    {{ packagefeatureService.getFeatureSubHeaderById(featureId) | texttransform }}
  </div>

  <div class="featureBody spinnerWrapper" *ngIf="loadingData==true">
    <span>
      <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>
  </div>

  <div class="featureSpinner" *ngIf="!showPopupImage && loadingData==false">
    <div class="featureSpinner__spinnArrow" *ngIf="slider?.length > 1 && !applicationInfoService.isMobile()">
       <app-main-button type ="action" svg="arrow-left" (onClick)="changeSlide(false)"></app-main-button>
    </div>
    <div class="featureBody">
     
      <div class="featureBody__picture">
        <img class="featureBody__picture__inner fingerCursor"
            (click)="popupImage()" 
            [src]="getFeatureSliderImage(currentSlider)"
            [alt]="commonService.getTranslationValueFromArray(currentSlider)">
      </div>

      <div *ngIf="applicationInfoService.isMobile()" class="featureBody__mobileNavigator">
        <div *ngIf="slider?.length > 1">
          <app-main-button type ="action" svg="arrow-left" (onClick)="changeSlide(false)"></app-main-button>
        </div>
      
        <div class="featureBody__pageMobile" *ngIf="currentSlider">{{getCurrentIndex(currentSlider)}}</div>
      
        <div *ngIf="slider?.length > 1">
          <app-main-button type="action" svg="arrow-right" (onClick)="changeSlide(true)"></app-main-button>
        </div>
      </div>

    <div class="featureBody__page" *ngIf="currentSlider && !applicationInfoService.isMobile()">{{getCurrentIndex(currentSlider)}}</div>

      <div class="featureBody__text">
        <div [innerHTML]="getFeatureSliderInnerHtml(currentSlider)"></div>
      </div>
      <div class="featureBody__button">
        <app-main-button svg="globe" (onClick)="openMainPage()"  label="Feature.Label.StartPage"  *ngIf="feature?.settingJson?.isHelp == true"></app-main-button>
        <app-main-button svg="globe" (onClick)="openWebsite()" label="Feature.Label.OpenWebsite" *ngIf="showPageButton==true && feature?.settingJson?.isHelp == false"></app-main-button>
      </div>
    </div>
    <div class="featureSpinner__spinnArrow" *ngIf="slider?.length > 1 && !applicationInfoService.isMobile()">
      <app-main-button type="action" svg="arrow-right" (onClick)="changeSlide(true)"></app-main-button>
 <!--      <i class="fas fa-angle-double-right fingerCursor" (click)="changeSlide(true)"></i>     -->  
    </div>
  </div>

  
  <div *ngIf="showPopupImage" class="popupWrapper">
    <img class="featureBody__picture__popup fingerCursor"
      (click)="popupImage()"
      [src]="getFeatureSliderImage(currentSlider)"
      [alt]="commonService.getTranslationValueFromArray(currentSlider)">
  </div>
</div>
