import { Component, Input, OnInit } from '@angular/core';
import { WizardMode, WizardService } from '../../../wizard/wizard.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-process-stepresult',
  templateUrl: './process-stepresult.component.html',
  styleUrl: './process-stepresult.component.scss'
})
export class ProcessStepresultComponent implements OnInit {
  @Input() result;
  @Input() step;
  @Input() eventTrigger;
  @Input() resultCounts;
  @Input() leadStates;
  @Input() qmUserGroups;
  @Input() workflows;

  excludeItems = ['lookupTable', 'length', 'nextStepWorkflowId', 'nextStep', 'closingResultAfterXResults'];

  constructor(
    private wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
  }

  saveResult() {
    const excludeList = ['closingResultAfterXResults', 'nextStep', 'nextStepWorkflowId', 'settingJson'];
    if (!this.applicationInfoService.isDeveloper) {
      excludeList.push('preExecuteSql');
      excludeList.push('postExecuteSql');
    }
    this.externaldatasourceService.executeExternalDataSource(570, [
      this.commonService.getModifyArrayBody(this.result, excludeList)
    ]);
  }

  nextStepChanged(result) {
    result.nextStepId = null;
    this.saveResult();
  }

  editResult() {
    this.wizardService.showWizard(WizardMode.Result, this.result);
  }  

  getEnvelopeColor() {
    let returnValue = 'disabledGray';
    if (this.result.emailTemplateId != null) {
      returnValue = 'red';
    }
    return returnValue;
  }

  getEventColor() {
    let returnValue = 'disabledGray';
    if (this.result.workflowStepResultEventTriggers?.length > 0) {
      returnValue = 'red';
    }
    return returnValue;
  }

  getWorkflowSteps(workflowId) {
    const workflow = this.workflows.find(x => x.id == workflowId);
    if (workflow) {
      return workflow.workflowSteps;
    } else {
      return [];
    }
  }

  toggle(value) {
    if (this.commonService.isNullOrUndefined(this.result[value])) {
      this.result[value] = true;
    } else {
      this.result[value] = !this.result[value];
    }
    this.saveResult();
  }

  stateCheckColor(value) {
    if (this.result[value] == true) {
      return 'red';
    } else {
      return 'disabledGray';
    }
  }

  showEventWizard() {
    let array = null;
    const eventTrigger = this.eventTrigger.find(trigger => trigger.newTaskWorkflowResultId == this.result.id);
    if (eventTrigger) {
      array = eventTrigger;
    } else {
      array = {
        newTaskWorkflowResultId: this.result.id,
        newTaskWorkflowStepId: this.step.id,
        projectId: this.applicationInfoService.projectID,
        isCreateNewTask: true
      };
    }
    this.wizardService.showWizard(WizardMode.EventAssignment, array);
  }

  callSubquestionForm() {
    this.applicationInfoService.miscSettings['subQuestionFormCall'] = this.result;
    this.eventService.showJjPopup('', 'questionanswers', 80, true);
  }
}
