import { Component, OnInit, Injector, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { isNullOrUndefined } from 'util';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { ApplicationinitService } from 'app/jollyjupiter/service/applicationinit.service';
import { Router } from '@angular/router';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-custom-projectoverview',
  templateUrl: './custom-projectoverview.component.html',
  styleUrls: ['./custom-projectoverview.component.scss']
})
export class CustomProjectoverviewComponent extends CustomControlBaseClass implements OnInit, AfterViewInit {
  @Input() controlHeight = '400px';
  @Input() onlyProjects = false;

  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  campaignInfo = null;
  collapsedItems = [];
  collapsedAgents = [];
  filterValue = '';
  projects = [];
  displayedColumns = ['projectName'];
  projectMode = null;
  availableProjects = [];
  showInactiveProjects = false;
  
  constructor(
    private injector: Injector,
    public projectService: ProjectService,
    public axivasTranslateService: AxivasTranslateService,
    private applicationinitService: ApplicationinitService,
    public commonService: CommonService,
    private router: Router
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngOnInit() {
    if (this.onlyProjects) {
      this.getProjects();
    } else {
      this.getCampaignInfo();
    }

/*     console.log('on login ', this.applicationInfoService.applicationSettings['projectStartSection'])
    console.log('on login 2', this.applicationInfoService.applicationSettings) */
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getProjectByActivity(projects) {
    const finalProjects = [];
    projects.forEach(userProject => {
      const project = userProject.project;
      let addProject = true;
      switch (this.projectMode) {
        case true:
          if (!this.commonService.isNullOrUndefined(project.projectEnd)) {
            if (this.commonService.checkIfDateIsInPast(project.projectEnd)) {
              addProject = false;
            }
          }
          break;
        case false:
          if (!this.commonService.isNullOrUndefined(project.projectEnd)) {
            if (this.commonService.checkIfDateIsInFuture(project.projectEnd)) {
              addProject = false;
            }
          } else  {
            if (!this.commonService.isNullOrUndefined(project.projectStart)) {
              if (this.commonService.checkIfDateIsInPast(project.projectStart)) {
                addProject = false;
              }
            } else {
              addProject = false;
            }
          }
          break;
      }
      if (addProject) {
        const checkProject = finalProjects.find(finalProject => finalProject.id == project.id);
        if (!checkProject) {
          finalProjects.push(project);
        }
      }
    });
    return finalProjects;
  }

  getProjects() {
    this.availableProjects = this.getProjectByActivity(this.applicationInfoService.user.userProjects);

    if (this.availableProjects.length == 0) {
      this.eventService.closeJjPopup();
      this.applicationInfoService.noProjectsForUser = true;
      return;
    }

    this.availableProjects.sort((a, b) => a.projectName < b.projectName ? -1 : 1);
    if (this.availableProjects.length == 1 && this.applicationInfoService.projectSelected == false) {
      this.loadProjectAndShowActivityForm(this.availableProjects[0], true);
    } else {
      this.applicationInfoService.showJJPopup = true;
    }
    // this.externalDatasourceService.executeExternalDataSource(510, [this.projectMode])
    // .then(result => {
    //   result.sort((a, b) => a.project.projectName < b.project.projectName ? -1 : 1)
    //   this.dataSource = new MatTableDataSource(result);
    //   this.dataSource.sort = this.sort;
    //   this.projects = result;
    //   if (result.length == 1 && this.applicationInfoService.projectSelected == false) {
    //     this.loadProjectAndShowActivityForm(result[0].project, true);
    //   } else {
    //     this.applicationInfoService.showJJPopup = true;
    //   }
    // })
  }

  isFiltered(project) {
    let returnValue = true;
    if (
      this.commonService.checkIfStringContainsString(project.projectName, this.filterValue) ||
      project.id.toString() == this.filterValue      
    ) {
      returnValue = false;
    } 
    if (!this.showInactiveProjects) {
      if (!this.commonService.isNullOrUndefined(project.projectEnd)) {
        if (this.commonService.checkIfDateIsInPast(project.projectEnd)) {
          returnValue = true;
        }  
      }
    }
    return returnValue;
  }

  setMode(value) {
    this.projectMode = value;
    this.getProjects();
  }

  getCampaignInfo() {
    if (isNullOrUndefined(!this.controlDefinition)) {
      if (!this.controlDefinition.isActive) {
        return;
      }
    }
    this.campaignInfo = null;
    this.externalDatasourceService.executeExternalDataSource(131)
    .then(getCampaignInforesult => {
      this.campaignInfo = getCampaignInforesult;
    })
    .catch(error => {
      console.error('error', error);
    });
  }

  getAdressProgressWidth(campaign: any, type: any): number {
    let returnValue = 0;
    const maxCount = (campaign.unasssignedAccounts + campaign.openAccounts + campaign.inProcessAccounts + campaign.closedAccounts);
    const typeValue = Number(campaign[type]);
    returnValue = (100 / maxCount) * typeValue;
    // console.warn('getAdressProgressWidth', maxCount, typeValue, returnValue);
    return returnValue;
  }

  isFilterValidForProject(projectName: any) {
    return this.commonService.checkIfStringContainsString(projectName, this.filterValue);
  }

  getAgentPotential(agent: any, campaign: any) {
    const returnValue = campaign.unasssignedAccounts +
      + campaign.dummyAgent.followUpsPast
      + campaign.dummyAgent.followUpsToday
      + campaign.dummyAgent.followUpsTomorrow
      + agent.followUpsTomorrow;
    return returnValue;
  }

  getCampaignPotential(campaign) {
    const returnValue = campaign.unasssignedAccounts
      + campaign.dummyAgent.followUpsPast
      + campaign.dummyAgent.followUpsToday
      + campaign.dummyAgent.followUpsTomorrow;
    return returnValue;
  }

  getProgressBarTooltip(campaign: any): string {
    let returnValue = '';
    returnValue = returnValue.concat(
      this.axivasTranslateService.getTranslationTextForToken(
        'ProjectOverview.Label.CampaignClosedAccounts'), ' ', campaign.closedAccounts, '\n',
      this.axivasTranslateService.getTranslationTextForToken(
        'ProjectOverview.Label.CampaignInProcessAccounts'), ' ', campaign.inProcessAccounts, '\n',
      this.axivasTranslateService.getTranslationTextForToken(
        'ProjectOverview.Label.CampaignOpenAccounts'), ' ', campaign.openAccounts, '\n',
      this.axivasTranslateService.getTranslationTextForToken(
        'ProjectOverview.Label.CampaignAccountsNotAssigned'), ' ', campaign.unasssignedAccounts, '\n',
    );
    return returnValue;
  }

  loadProjectAndShowActivityForm(project, assignId = false) {
    this.applicationInfoService.showJJPopup = true;
    if (assignId) {
      project.projectId = project.id;
    }
    this.commonService.clearSessionStorageItem('LastNavigation');
    this.commonService.clearSessionStorageItem('LastNavigationContainerId');
    this.commonService.clearSessionStorageItem('CurrentAccount');
    this.commonService.clearSessionStorageItem('CurrentContact');
    this.commonService.clearSessionStorageItem('CurrentTask');
    this.applicationInfoService.projectLoaded = false;
    this.applicationInfoService.projectSelected = false;
    this.applicationInfoService.currentUserRoleId = null;
    this.eventService.closeJjPopup();
    this.applicationInfoService.showLoadProjectSpinner = true;
    this.applicationInfoService.splashScreenLoadingText = 'Loading ' + project.projectName;
    this.applicationInfoService.loadingProjectPercentage = 10;
    this.applicationinitService.loadProject(project.id)
    .then(() => {
      if (
        !this.commonService.isNullOrUndefined(localStorage.getItem('awsRedirectUrl'))
      ) {
        //console.log('here',localStorage);
        this.router.navigate([localStorage.getItem('awsRedirectUrl')]);
        localStorage.removeItem('awsRedirectUrl');
      } else {
        this.router.navigate([this.applicationInfoService.applicationSettings['projectStartSection']]);
      }
    });

    return;
    // this.projectService.loadProjectAndShowActivityForm({
    //   id: project.projectId,
    //   projectName: project.projectName,
    //   customerId: 1
    // }).then(result => {
    //   this.applicationInfoService.projectLoaded = true;
    //   this.applicationInfoService.projectSelected = true;
    //   if (result === 'startworking' ) {
    //     if (this.applicationInfoService.miscSettings['accountformactive'] === true) {
    //       this.methodService.launchInternalMethod(InternalMethodType.StartWorking, []);
    //     } else {
    //       this.methodService.launchInternalMethod(InternalMethodType.NextTask, []);
    //     }
    //   }
    // });
  }

  getControlHeight() {
    if (isNullOrUndefined(this.controlHeight)) {
      if (isNullOrUndefined(this.controlDefinition)) {
        return null;
      } else {
        return this.controlDefinition.height;
      }
    } else {
      return this.controlHeight;
    }
  }

  
}
