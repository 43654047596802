import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { MethodService , InternalMethodType} from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-visitors-list',
  templateUrl: './visitors-list.component.html',
  styleUrl: './visitors-list.component.scss'
})
export class VisitorsListComponent implements OnInit{
  constructor(
    private methodService: MethodService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService
  ){}

  @Input() data = null;
  @Input() isOdd = false;


  ngOnInit(): void {
      this.data = JSON.parse(this.data)
      /* console.log('this.data', this.data) */
  }

  navigateTo(route){
    this.methodService.launchInternalMethod(InternalMethodType.NavigateTo, [route]);
  }

  loadWebvisior() {
    this.applicationInfoService.miscSettings['currentProspect'] = this.data.id;
    this.eventService.showJjPopup(this.data['_917_companyname'], 'prospectdetail', '80');
  }
}
