import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-userhierarchy-designer-item',
  templateUrl: './userhierarchy-designer-item.component.html',
  styleUrl: './userhierarchy-designer-item.component.scss'
})

export class UserhierarchyDesignerItemComponent implements OnInit, OnDestroy {
  eventServiceSubscription: Subscription = new Subscription();
  @Input() child = null;
  @Input() groupId = null;
  @Input() group = null;
  @Input() users = [];
  @Input() level = 0;
  @Input() isGroupOwner = false  
  @Input() parentGuid = null;
  @Input() groupConnections = [];
  @Input() canEditGroups = [];
  guid = null;
  showPopup = false;
  switchManager = false;
  userChildren = [];
  tempId = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public dragdropService: DragdropService,
    private applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    public eventService: EventService
  ) {}

  ngOnInit(): void {
    this.guid = this.commonService.createGuid();
    this.eventServiceSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'removeHierarchyChild' && event.guid == this.guid) {
        this.commonService.removeItemFromArray(this.userChildren, event.value);
      }
    });
    this.getChilds();
  }

  ngOnDestroy(): void {
    if (this.eventServiceSubscription) { this.eventServiceSubscription.unsubscribe(); }
  }

  getChilds() {
    this.userChildren = this.groupConnections.filter(connection => 
      connection.managerId == this.child.userId &&
      connection.level == this.level + 1 &&
      connection.securityUserHierarchyTeamId == this.groupId
    );
  }

  getUserName() {
    const user = this.users.find(user => user.id == this.child.userId);
    if (user) {
      return user.userName;
    } else {
      return '-';
    }
  }

  addSubUser() {
    
  }

  addMe(event) {
    if (event.item.data.id == this.child.userId) {
      return;
    }
    const itemToAdd = {
      projectId: this.applicationInfoService.projectID, 
      userId: event.item.data.id,
      managerId: this.child.userId,
      level: this.level + 1,
      securityUserHierarchyTeamId: this.groupId
    }
    this.externaldatasourceService.executeExternalDataSource(1009, [
     this.commonService.getModifyArrayBody(itemToAdd, [])
    ]).then(addUserResult => {
      itemToAdd['id'] = addUserResult.id;
      this.userChildren.push(itemToAdd);
      this.eventService.customEvent.emit({ id: 'pushHierarchyChild', value: itemToAdd });  
    }).catch(error => {
      console.error(error);
    });
  } 

  deleteHierarchyEntry() {
    this.externaldatasourceService.executeExternalDataSource(1011, [this.child.id]).then(() => {
      this.eventService.customEvent.emit({
        id: 'removeHierarchyChild',
        guid: this.parentGuid,
        value: this.child
      });
    });
  }

  startSwitching() {
    this.tempId = this.group.mainUserId;
    this.switchManager = true;
  }

  saveNewManager() {
    this.externaldatasourceService.executeExternalDataSource(1015, [this.commonService.getModifyArrayBody(this.group, [])]);
    this.switchManager = false;
  }

  camcelNewManager() {
    this.group.mainUserId = this.tempId
    this.switchManager = false;
  }
}
