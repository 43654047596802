<div class="cardContainer" [ngClass]="{ 
    cardContainerAccount: type == 'account',
    noRightBorder: noRightBorder,
    small: small
}">
    <div class="card">
        <div class="cardMobile">
            <div class="cardMobile__row">
                <div class="card__col">
                    <p class="cardTitle" *ngIf="type == 'account'" >{{'Account.Label.Header' | texttransform }}</p>
                    <p class="cardTitle" *ngIf="type == 'contact'">{{'Contact.Label.Header' | texttransform }}</p>
                    <div *ngIf="type == 'account'" class="cardCompanyName" [tippy]="data?.name1">{{data?.name1}}</div>
                    <div *ngIf="type == 'contact'" class="cardName" [tippy]="getContactTippy(data)">{{data?.firstName}} {{data?.lastName}}</div>
                </div>
            </div>
            <div class="cardMobile__row" *ngIf="applicationInfoService.isCardAccordeonOpen">
                <div class="card__col">
                    <p class="cardTitle">{{'Card.Label.Bearbeitungsstatus' | texttransform}}</p>
                    <app-status 
                        noLabelColor="true" 
                        [blue]="processingStatus?.defaultName === 'closed'" 
                        [orange]="processingStatus?.defaultName === 'in process'" 
                        [green]="processingStatus?.defaultName === 'open'"
                        [width]="15" 
                        [height]="15"
                        [label]="getCurrentProcessingState() | texttransform">
                    </app-status>
                </div>

                <div class="card__col card__col__leadstate">
                    <p class="cardTitle">{{'Card.Label.Leadstatus' | texttransform }}</p>
                    <app-status 
                        noLabelColor="true" 
                        [color]="leadStatus?.leadStatusReportColor" 
                        [width]="15" 
                        [height]="15"
                        [label]="getCurrentLeadState() | texttransform"
                        [ellipsis]="true"
                    ></app-status>
                </div>

            </div>

            <div class="cardMobile__row">
                <div class="card__col" *ngIf="(data.leadScoringCalculated || data.leadScoringCalculated == 0) && small==false">
                    <p class="cardTitle">{{'Card.Label.Leadscoring' | texttransform }}</p>
                    <app-bar-leadscore [accountHeader]="true" [value]="data.leadScoringCalculated" [popupAllowed]="true"></app-bar-leadscore>
                </div>
    
                <div class="card__col" *ngIf="type == 'contact' && small==false">
                    <p class="cardTitle">{{'Card.Label.OptInOptOut' | texttransform }}</p>
                    <div class="optInCards">
                        <app-jj-icons type="phone" [pointer]="false" [color]="getOptinColor(45)" [width]="20" [height]="20"
                            [tippy]="getOptinTippyText('Card.Label.OptInOptOutDesc', 'Card.Label.OptInOptOutPhone')"></app-jj-icons>
                        <app-jj-icons type="envelope" [pointer]="false" [color]="getOptinColor(47)" [width]="20" [height]="20"
                            [tippy]="getOptinTippyText('Card.Label.OptInOptOutDesc', 'Card.Label.OptInOptOutEmail')"></app-jj-icons>
                        <app-jj-icons type="mailbox" [pointer]="false" [color]="getOptinColor(6766)" [width]="20" [height]="20"
                            [tippy]="getOptinTippyText('Card.Label.OptInOptOutDesc', 'Card.Label.OptInOptOutPapermail')"></app-jj-icons>
                        <app-jj-icons type="chat-dots" [pointer]="false" [color]="getOptinColor(50)" [width]="20" [height]="20"
                            [tippy]="getOptinTippyText('Card.Label.OptInOptOutDesc', 'Card.Label.OptInOptOutSocialMedia')"></app-jj-icons>
                    </div>
                </div>

            </div>
        </div>





        <div class="card__inner cardNonMobile">
            <div class="card__colSVG" *ngIf="small==false">
                <app-jj-icons [type]="type === 'contact' ? 'user' : 'building'" [width]="32" [height]="32"></app-jj-icons>
            </div>

            <div class="card__col">
                <p class="cardTitle" *ngIf="type == 'account' && small==false" >{{'Account.Label.Header' | texttransform }}</p>
                <p class="cardTitle" *ngIf="type == 'contact' && small==false">{{'Contact.Label.Header' | texttransform }}</p>
                <div *ngIf="type == 'account'" class="cardCompanyName" [tippy]="data?.name1">{{data?.name1}}</div>
                <div *ngIf="type == 'contact'" class="cardName" [tippy]="getContactTippy(data)">{{data?.firstName}} {{data?.lastName}}</div>
            </div>

            <div class="card__col " [ngClass]="{ cardColAccount: type == 'account' }">
                <p class="cardTitle" *ngIf="small==false">{{'Card.Label.Bearbeitungsstatus' | texttransform }}</p>
            
                <app-dropdown
                    type="processState"
                    *ngIf="type == 'contact' && showSelectionOfEditingState && showDropdown"
                    [value]="processingStateId || applicationInfoService.currentContact.calculatedEditingState"
                    [smallFont]="true"
                    [options]="processingStates"
                    (onClick)="processingStateId = $event; changeEditingState()">
                </app-dropdown>
                
                <app-status
                    id="statusContact1"
                    *ngIf="type == 'contact' && !showSelectionOfEditingState"
                    noLabelColor="true" 
                    [blue]="processingStatus?.defaultName === 'closed'" 
                    [orange]="processingStatus?.defaultName === 'in process'" 
                    [green]="processingStatus?.defaultName === 'open'"
                    [width]="15" 
                    [height]="15"
                    [label]="getCurrentProcessingState() | texttransform"></app-status>

                <app-status 
                    id="statusAccount1"
                    *ngIf="type == 'account'"
                    noLabelColor="true" 
                    [blue]="processingStatus?.defaultName === 'closed'" 
                    [orange]="processingStatus?.defaultName === 'in process'" 
                    [green]="processingStatus?.defaultName === 'open'"
                    [width]="15" 
                    [height]="15"
                    [label]="getCurrentProcessingState() | texttransform">
                </app-status>
            </div>

            <div class="card__col card__col__leadstate">
                <p class="cardTitle" *ngIf="small==false">{{'Card.Label.Leadstatus' | texttransform }}</p>
                <app-status 
                    noLabelColor="true" 
                    [color]="leadStatus?.leadStatusReportColor" 
                    [width]="15" 
                    [height]="15"
                    [label]="getCurrentLeadState() | texttransform"
                    [ellipsis]="true"
                ></app-status>
            </div>

            <div class="card__col" *ngIf="(data.leadScoringCalculated || data.leadScoringCalculated == 0) && 
                small==false &&
                applicationInfoService?.applicationSettings['showLeadscoreInDetailHeader']=='true'
            ">
                <p class="cardTitle">{{'Card.Label.Leadscoring' | texttransform }}</p>
                <app-bar-leadscore [accountHeader]="true" [value]="data.leadScoringCalculated" [popupAllowed]="true"></app-bar-leadscore>
            </div>

            <div class="card__col" *ngIf="type == 'contact' && small==false">
                <p class="cardTitle">{{'Card.Label.OptInOptOut' | texttransform }}</p>
                <div class="optInCards">
                    <app-jj-icons type="phone" [pointer]="false" [color]="getOptinColor(45)" [width]="20" [height]="20"
                            [tippy]="getOptinTippyText('Card.Label.OptInOptOutDesc', 'Card.Label.OptInOptOutPhone')"></app-jj-icons>
                        <app-jj-icons type="envelope" [pointer]="false" [color]="getOptinColor(47)" [width]="20" [height]="20"
                            [tippy]="getOptinTippyText('Card.Label.OptInOptOutDesc', 'Card.Label.OptInOptOutEmail')"></app-jj-icons>
                        <app-jj-icons type="mailbox" [pointer]="false" [color]="getOptinColor(6766)" [width]="20" [height]="20"
                            [tippy]="getOptinTippyText('Card.Label.OptInOptOutDesc', 'Card.Label.OptInOptOutPapermail')"></app-jj-icons>
                        <app-jj-icons type="chat-dots" [pointer]="false" [color]="getOptinColor(50)" [width]="20" [height]="20"
                            [tippy]="getOptinTippyText('Card.Label.OptInOptOutDesc', 'Card.Label.OptInOptOutSocialMedia')"></app-jj-icons>
                    <!-- <app-jj-icons type="phone" [pointer]="false" [color]="getOptinColor(45)" [width]="20" [height]="20"></app-jj-icons>
                    <app-jj-icons type="envelope" [pointer]="false" [color]="getOptinColor(47)" [width]="20" [height]="20"></app-jj-icons>
                    <app-jj-icons type="mailbox" [pointer]="false" [color]="getOptinColor(6766)" [width]="20" [height]="20"></app-jj-icons>
                    <app-jj-icons type="chat-dots" [pointer]="false" [color]="getOptinColor(50)" [width]="20" [height]="20"></app-jj-icons> -->
                </div>
            </div>
        </div>
    </div>
</div>