import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-new-logical-entity',
  templateUrl: './new-logical-entity.component.html',
  styleUrls: ['./new-logical-entity.component.scss']
})
export class NewLogicalEntityComponent implements OnInit {
  public newLogicalEntityName = '';
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private refreshValueService: RefreshValueService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private messagingService: MessagingService,
    private eventService: EventService,
    private uiService: UiService
  ) { }

  ngOnInit() {
  }


  check(event){
 /*    console.log('event - check', event);
    console.log('newLogicalEntityName ', this.newLogicalEntityName); */
  }

  createNewLogicalEntity() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(197, [this.applicationInfoService.miscSettings['newEntityLevelBaseId'],
      this.newLogicalEntityName])
    .then(() => {
      const promiseArray: Promise<any>[] = [];
      promiseArray.push(this.refreshValueService.getLogicalEntities());
      promiseArray.push(this.uiService.getControlsForProject());
      promiseArray.push(this.refreshValueService.getExternalDataSources());
      promiseArray.push(this.refreshValueService.getProjectEntities());
      Promise.all(promiseArray)
      .then(() => {
        this.eventService.newLogicalEntityCreatedEvent.emit();
        this.loaderService.display(false);
        this.eventService.closeJjPopup();
      })
      .catch(() => {
        this.loaderService.display(false);
      });
    })
    .catch(error => {
      this.loaderService.display(false);
      this.messagingService.showDefaultError('createNewLogicalEntity', error, true);
    });
  }
}
