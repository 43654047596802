import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss'
})
export class AccordionComponent implements OnInit, OnChanges{

  constructor(){}

  @Input() title: string | null = null;
  @Input() isOpen: boolean = false;
  @Input() date: any | null= null;
  @Input() everleadHeader: boolean | null = false;
  @Input() value: number | null = null;
  @Input() valueTwo: number | null = null; 


  // todo 
  @Input() borderBottom: boolean | null = true;
  @Input() noBorder: boolean | null = false;
  @Output() onOpen= new EventEmitter<any>();


  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
      //console.log('changes', changes);
  }
  
  

  togglePanel(){
    this.isOpen= !this.isOpen;
    this.onOpen.emit(this.isOpen);
  }

}
