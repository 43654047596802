<div *ngIf="applicationInfoService.applicationSettings['showBoldBiDeprecationInfo'] != 'true'">
    <div class="row">
        <div class="col-md-12">
            <div *ngFor="let i of dashboardsList" class="radioButtonWrapper">
                <input type="radio" (change)="renderDashboard(i)" [(ngModel)]="selectedDashboard" [value]="i"> {{ i.Name }}
            </div>
            
            <!-- <div (click)="renderDashboard(i)" *ngFor="let i of dashboardsList" class="dashboardItem">{{i.Name}}</div> -->
        </div>
        </div>
        <div [style.height]="getHeightBoldBiDashoardHeight()" style="position: relative;" [ngClass]="{
        hideInDom: applicationInfoService.isMobile() && applicationInfoService.paletteCollapsed == false
        }">
        <!-- <span *ngIf="replaceTranslationTokens()"></span> -->
        <div class="boldBiRefresh" *ngIf="applicationInfoService.isDeveloper">
            <i class="fas fa-sync fingerCursor" (click)="refreshBoldBiData()"></i>
            {{ 'BoldBi.Label.LastSyncTime' | texttransform }}
            {{ applicationInfoService.lastBoldBiSync | date: 'medium' }}
        </div>
        <span id="designSpanBoldBI"></span>
        <div id="viewer-section">
            <div id="dashboard"></div>
        </div>
    </div>
</div>

<div *ngIf="applicationInfoService.applicationSettings['showBoldBiDeprecationInfo'] == 'true'">
    {{ 'BoldBi.Label.NoDashboardsAnymoreContactIt' | texttransform }}
    <br><br>
    <div class="infoMail">
        <app-jj-icons type="envelope" [width]="20" [height]="20" [pointer]="false"></app-jj-icons>
        <a href="mailto:support@everlead.ai">EVERLEAD Support</a>
    </div>
</div>