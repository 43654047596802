import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-card-accordion',
  templateUrl: './card-accordion.component.html',
  styleUrl: './card-accordion.component.scss'
})
export class CardAccordionComponent implements OnInit, OnChanges{

  constructor(
    public applicationInfoService: ApplicationInfoService
  ){}

  @Input() title: string | null = null;
  @Input() everleadHeader: boolean | null = false;
  @Input() account: any | null;
  @Input() contact: any | null;
  @Input() closeWhenExpanding: boolean | null = false;  
  
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  togglePanel(){
    this.applicationInfoService.isCardAccordeonOpen= !this.applicationInfoService.isCardAccordeonOpen;
    //this.applicationInfoService.isCardsVisible = this.applicationInfoService.isCardAccordeonOpen;
  }
}
