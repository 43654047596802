<div>
    <app-main-button svg="plus" (onClick)="addUiControlSecurityPermissions()" type="small"
        label="Designer.Label.NewUiSecurityPermission"></app-main-button>
</div>
<div class="uiSecurityPermisson__info">
    Der Name des benötigten Rechts setzt sich aus Can + das Zugriffsrecht + der Modulname zusammen, also z.B. CanReadPools
</div>
<div *ngFor="let permission of control.uiControlSecurityPermissions" class="everleadShadowBox uiSecurityPermisson">    
    <input [(ngModel)]="permission.securityPermission" placeholder="Benötigtes Recht" (change)="updatePermission(permission)">
    <select [(ngModel)]="permission.permissionType" (change)="updatePermission(permission)">
        <option ngValue="visible">visible</option>
        <option ngValue="enabled">enabled</option>
    </select>
    <i class="fas fa-trash everleadCoral fingerCursor" (click)="removeUiControlSecurityPermissions(permission)"></i>
</div>