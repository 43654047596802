import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  showVipNotActivated = false;
  demoPage = 0;
  demoPages = [
    { header: 'DemoBanner.Page1.Header', top: 'DemoBanner.Page1.Top', bottom: 'DemoBanner.Page1.Bottom', bottom2: 'DemoBanner.Page1.Bottom2', smallText: 'DemoBanner.Page1.SmallText' },
    { header: 'DemoBanner.Page2.Header', top: 'DemoBanner.Page2.Top', bottom: 'DemoBanner.Page2.Bottom', bottom2: '', smallText: 'DemoBanner.Page2.SmallText' },
    { header: 'DemoBanner.Page3.Header', top: 'DemoBanner.Page3.Top', bottom: 'DemoBanner.Page3.Bottom', bottom2: '', smallText: 'DemoBanner.Page3.SmallText' }
  ];
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private awsCognitoService: AwsCognitoService,
    private eventService: EventService,
    private commonService: CommonService,
    public settingsService: SettingsService,
    private messagingService: MessagingService,
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslateService: AxivasTranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.eventService.showBannerEvent.subscribe(event=> {
      switch(event) {
        case 'showVipNotActivated':
          // this.showVipNotActivated = true;
          this.messagingService.showDefaultInfo('Supervisor.Label.ManageVip', 'VipNotActivatedBanner.Label.Description', true,
            { type: 'VipNotActivated' }
          )
        break;
      }
    });
  }

  closeBanner(banner) {
    this.applicationInfoService[banner] = false;
  }

  visitWebsite() {
    this.externaldatasourceService.executeExternalDataSource(493, []).then(result => {
      if (result.length > 0) {
        this.applicationInfoService.userSettings['demoLandingPageVisited' + this.applicationInfoService.projectID] = true;
        this.settingsService.updateSecurityUserSettingsJson();
        this.commonService.openUrl(result[0].domainFilter, '_blank');
      }
    });
  }

  visitYoutube() {
    this.commonService.openUrl('https://www.youtube.com/channel/UCW96mkCOb5FplgAqpsuUuqQ', '_blank');    
  }

  visitContact() {
    this.commonService.openUrl(this.applicationInfoService.applicationSettings['everleadContactPage'], '_blank');              
  }

  visitPlanner() {
    if (this.axivasTranslateService.currentLanguage == 'de') {
      this.commonService.openUrl(this.applicationInfoService.applicationSettings['demoTourLinkDe'], '_blank');              
    } else {
      this.commonService.openUrl(this.applicationInfoService.applicationSettings['demoTourLinkEn'], '_blank');
    }    
  }

  login() {
    if (this.applicationInfoService.user.isExternalUser) {      
      this.applicationInfoService.showAWsLoginPage = true;
      this.applicationInfoService.showBanner = false;
      this.applicationInfoService.projectLoaded = false;
      this.applicationInfoService.projectSelected = false;
      this.applicationInfoService.awsSilentRenewError = false;
      this.applicationInfoService.userID = 0;
      return;
    } else {
      localStorage.removeItem('awstoken');
      localStorage.removeItem('awsrefreshtoken');
      let initHref = window.location.href;
      initHref = initHref.replace(window.location.origin, '');
      localStorage.setItem('initHref', initHref);
      this.awsCognitoService.showAwsLoginPage();     
    }
  }
}
