// import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import { AfterViewInit, Component, DoCheck, Injector, Input, IterableDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { VariableType } from 'app/core/application/application-info.service';
import { ObjectDeserializeService } from 'app/jollyjupiter/pipe/object-deserialize.service';
import { ClickContext } from 'app/jollyjupiter/service/common.service';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { JJEvent } from 'app/jollyjupiter/service/event.service';
import { MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CustomControlBaseClass } from '../customcontrolbaseclass';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-custom-mattable',
  templateUrl: './custom-mattable.component.html',
  styleUrls: ['./custom-mattable.component.scss', './custom-mattable.component.dark.scss']
})
export class CustomMattableComponent extends CustomControlBaseClass implements OnInit, AfterViewInit, DoCheck, OnDestroy {
  externaldDataQueryEventSubscription: Subscription = new Subscription();
  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  updateControlContentEventSubscription: Subscription = new Subscription();
  compactListShowOnlyDataEventSubscription: Subscription = new Subscription();
  matTableHeaderItemsUpdatedEventSubscription: Subscription = new Subscription();
  compactListRunQueryWithSpecificFilterEventSubscription: Subscription = new Subscription();
  updateControlContentWithCustomParameterSubscription: Subscription = new Subscription();
  selectedRows = [];
  selectedRowsData = [];
  selectedRowsIds = [];
  lastResult = [];
  myLeadShowFilteredItemsMode = false;
  myLeadShowFilteredData = null;
  customQueryReplaced = false;
  allFilesSelected = false;
  selectedSortCol = null;
  customFilter = null;
  lastSearchFirstItem = 0;
  lastSearchLastItem = 0;
  firstPageSecurityCheck = false;
  canExportData = false;
  canEditContent = false;
  refreshBlocker = false;
  missingHeaderIndex = [];
  isDarkmode = false;

  @Input() isMultiList = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  clickContext = new ClickContext();
  cellClickedEvent = this.clickContext.clickHandler((param) => { this.rowClicked(param); });
  cellDblClickedEvent = this.clickContext.dblClickHandler((param) => { this.rowDblClicked(param); });

  // displayedHeader = [];
  displayedColumns: string[] = [];
  resultsLength = 0;
  showFilter = true;
  customParameter = null;
  showPagination = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  updateDataSource = null;
  createDataSource = null;
  queryDataSource = null;
  deleteDataSource = null;
  additionalDataSourcesArray = null;
  additionalDataSourcesDisplayValues = null;
  sortHeaders = [];
  isDisabled = false;
  isControlDataDriven = false;
  controlIdArray = [];
  filterArray = [];
  customQuery = '';
  loadDataOnInit = true;
  // Filter & Pagination
  globalFilter = '';
  columnFilters = [];
  pageSize = 1;
  currentPage = 1;
  sortString = '';
  controlDataInfoArray = [];
  differ: any;
  descriptionText = null;
  useMyLeadsFilter = false;
  maxPages = 0;
  smallVersion = false;
  ignoreMaxHeight = false;

  constructor(private injector: Injector,
    private axivasTranslateService: AxivasTranslateService,
    private objectDeserializeService: ObjectDeserializeService,
    private confirmationService: ConfirmationService,
    private sharedAPI: SharedAPI,
    private settingsService: SettingsService,
    differs: IterableDiffers
    ) {
      super(injector);
      this.valueType = ValueType.String;
      this.differ = differs.find([]).create(null);
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // if (!this.commonService.isNullOrUndefined(this.applicationInfoService.matTableUserCurrentPages[this.controlDefinition.id])) {
    //   this.dataSource.paginator.pageIndex = this.applicationInfoService.matTableUserCurrentPages[this.controlDefinition.id];
    // }
  }

  ngDoCheck() {
    const childChange = this.differ.diff(this.controlDefinition.childs);
    if (childChange != null) {
      this.getVisibleChilds();
    }
  }

  ngOnDestroy() {
    if (this.selectCurrentDataFilterEventSubscription) { this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
    if (this.externaldDataQueryEventSubscription) { this.externaldDataQueryEventSubscription.unsubscribe(); }
    if (this.compactListRunQueryWithSpecificFilterEventSubscription) { this.compactListRunQueryWithSpecificFilterEventSubscription.unsubscribe(); }
    if (this.updateControlContentEventSubscription) { this.updateControlContentEventSubscription.unsubscribe(); }
    if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }
    if (this.compactListShowOnlyDataEventSubscription) { this.compactListShowOnlyDataEventSubscription.unsubscribe(); }
    if (this.updateControlContentWithCustomParameterSubscription) { this.updateControlContentWithCustomParameterSubscription.unsubscribe(); }
    if (this.matTableHeaderItemsUpdatedEventSubscription) { this.matTableHeaderItemsUpdatedEventSubscription.unsubscribe(); }
  }

  ngOnInit() {

    //console.log('this.control definition',this.controlDefinition);
    this.updateControlAttributesEventSubscription  = this.eventService.updateControlAttributesEvent.subscribe(() => {
      if (this.applicationInfoService.lastClickedMatTable == this.controlDefinition.id) {
        this.applicationInfoService.lastClickedMatTable = null;
      } else {
        if (this.loadDataOnInit != false) {
          this.prepareAdditionalExternalDataSources();
          this.getData().then(() => {
            if (this.paginator) { this.paginator.firstPage(); }
          });
        }
      }
    });

    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true' && this.applicationInfoService.demoLandingPage == null) {
      this.externalDatasourceService.executeExternalDataSource(493, []).then(result => { if (result.length > 0) { this.applicationInfoService.demoLandingPage = result[0].domainFilter; }
      });
    }

    this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(() => {
      this.getData();
    });

    this.matTableHeaderItemsUpdatedEventSubscription = this.eventService.matTableHeaderItemsUpdatedEvent.subscribe(() => {
      this.dataSource = new MatTableDataSource<any>();
      this.getVisibleChilds();
    });

    this.updateControlContentEventSubscription = this.eventService.updateControlContentEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.prepareAdditionalExternalDataSources();
        this.getData().then(() => { 
          if (this.paginator) {
            this.paginator.firstPage();
          }          
        });
      }
    });

    this.compactListRunQueryWithSpecificFilterEventSubscription = this.eventService.compactListRunQueryWithSpecificFilterEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.customFilter = event.arguments[0];
        // console.warn('compactListRunQueryWithSpecificFilterEvent', this.customFilter, event);
        this.getData();
      }
    });

    this.compactListShowOnlyDataEventSubscription = this.eventService.compactListShowOnlyDataEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        if (this.myLeadShowFilteredItemsMode) {

        }
        this.myLeadShowFilteredItemsMode = !this.myLeadShowFilteredItemsMode;
      }
    });

    this.updateControlContentWithCustomParameterSubscription = this.eventService.updateControlContentWithCustomParameter.subscribe(event => {
      // console.warn('updateControlContentWithCustomParameterSubscription', event, this.controlDefinition.logicalControlId)
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.customParameter = event.arguments[0];
        this.prepareAdditionalExternalDataSources();
        this.getData().then(() => { this.paginator.firstPage(); });
      }
    });

    try {
      const arrayString = this.commonService.modifyQueryStringWithApplicationInfos(this.controlDefinition.additionalSetting3)
      this.controlDataInfoArray = JSON.parse(arrayString);
    }
    catch {}

    this.externaldDataQueryEventSubscription = this.eventService.externaldDataQueryEvent.subscribe(event => {
      // console.warn('externaldDataQueryEventSubscription', this.controlDefinition.logicalControlId, Number(event.target), this.currentPage);
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.customQuery = event.arguments[0];
        this.customQueryReplaced = false;
        if (this.currentPage != 1) {
          this.paginator.firstPage();
        } else {
          this.getControlData();
        }
      }
    });

    if (this.isMultiList) {
      this.queryDataSource = 306;
      this.updateDataSource = 307;
      this.createDataSource = 308;
      this.deleteDataSource = 309;
      this.controlDefinition.uiControlExternalDataSources.forEach(dataSource => {
        if (dataSource.externalDataSource.queryType === 'delete') { this.deleteDataSource = dataSource.externalDataSource.id; }
        if (dataSource.externalDataSource.queryType === 'update') { this.updateDataSource = dataSource.externalDataSource.id; }
        if (dataSource.externalDataSource.queryType === 'create') { this.createDataSource = dataSource.externalDataSource.id; }
        if (dataSource.externalDataSource.queryType === 'query') { this.queryDataSource = dataSource.externalDataSource.id; }
      });
    } else {
      if (this.controlDefinition.uiControlExternalDataSources.length === 1) {
        this.queryDataSource = this.controlDefinition.uiControlExternalDataSources[0].externalDataSource.id;
      } else {
        this.controlDefinition.uiControlExternalDataSources.forEach(dataSource => {
          if (dataSource.externalDataSource.queryType === 'delete') { this.deleteDataSource = dataSource.externalDataSource.id; }
          if (dataSource.externalDataSource.queryType === 'update') { this.updateDataSource = dataSource.externalDataSource.id; }
          if (dataSource.externalDataSource.queryType === 'create') { this.createDataSource = dataSource.externalDataSource.id; }
          if (dataSource.externalDataSource.queryType === 'query') { this.queryDataSource = dataSource.externalDataSource.id; }
        });
      }
    }
    // console.warn(this.deleteDataSource, this.updateDataSource, this.createDataSource, this.queryDataSource);

    if (this.controlDefinition.additionalSetting3 == 'isControlDataDriven') { this.isControlDataDriven = true; }
    if (this.controlDataInfoArray['isControlDataDriven'] === true) { this.isControlDataDriven = true; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['defaultFilter'])) { this.globalFilter = this.controlDataInfoArray['defaultFilter']; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['defaultSort'])) { this.sortString = this.controlDataInfoArray['defaultSort']; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['showFilter'])) { this.showFilter = this.controlDataInfoArray['showFilter']; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['showPagination'])) { this.showPagination = this.controlDataInfoArray['showPagination']; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['descriptionText'])) { this.descriptionText = this.controlDataInfoArray['descriptionText']; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['canExportData'])) { this.canExportData = true; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['darkMode'])) { this.isDarkmode = true; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['useMyLeadsFilter'])) { this.useMyLeadsFilter = true; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['canEditContent'])) { this.canEditContent = true; }
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['ignoreMaxHeight'])) { this.ignoreMaxHeight = true; }

    // PageSize from userSettings
    if (!this.commonService.isNullOrUndefined(this.settingsService.userSettings['MatTablePagesize'])) {
      const pageSizeFromUserSettings = this.settingsService.userSettings['MatTablePagesize'].value;
      if (!isNaN(pageSizeFromUserSettings)) {
        this.pageSize = Number(pageSizeFromUserSettings);
      }
    } else {
      this.pageSize = 20;
    }

    // Changes made by the user during this session will be taken into account here
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.matTableUserPageSizeSettings[this.controlDefinition.id])) {
      this.pageSize = this.applicationInfoService.matTableUserPageSizeSettings[this.controlDefinition.id];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.matTableUserGlobalFilters[this.controlDefinition.id])) {
      this.globalFilter = this.applicationInfoService.matTableUserGlobalFilters[this.controlDefinition.id];
    }

    let sortInfo = null;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.matTableSortStrings[this.controlDefinition.id])) {
      this.sortString = this.applicationInfoService.matTableSortStrings[this.controlDefinition.id];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.matTableUserCustomFilters[this.controlDefinition.id])) {
      this.filterArray = this.applicationInfoService.matTableUserCustomFilters[this.controlDefinition.id];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.matTableUserColumnFilters[this.controlDefinition.id])) {
      this.columnFilters = this.applicationInfoService.matTableUserColumnFilters[this.controlDefinition.id];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.matTableUserCurrentPages[this.controlDefinition.id])) {
      this.currentPage = this.applicationInfoService.matTableUserCurrentPages[this.controlDefinition.id];
    }
    // this.getVisibleChilds();
    if (this.deleteDataSource !== null) {
      this.displayedColumns.push('delete');
    }

    this.prepareAdditionalExternalDataSources();
    if (!this.commonService.isNullOrUndefined(this.controlDataInfoArray['loadDataOnInit'])) {
      if (this.controlDataInfoArray['loadDataOnInit'] != false) {
        this.getData();
      } else {
        this.loadDataOnInit = false;
      }
    } else {
      sortInfo = this.sortString.split(' ');
      if (sortInfo.length > 1) {
        this.sortDataByColIndex(sortInfo[0], sortInfo[1], this.currentPage);
      } else {
        this.getData();
      }
    }
  }

  getMatTableId() {
    return 'matTable' + this.controlDefinition.id;
  }

  getMaxHeight() {
    if (this.ignoreMaxHeight) { return; }
    return Number(this.uiService.getDesignSpanPositionMobile('getMaxHeightSpan', 50));
  }

  checkIfControlIsSmall() {
    if (this.uiService.getDomControlWidth(this.getMatTableId()) < 900) {
      this.smallVersion = true;
    } else {
      this.smallVersion = false;
    }
    return false;
  }

  getMobileStyle(mobileRow) {
    if(this.applicationInfoService.isMobile() && mobileRow != null) {
       return {
         'grid-row': mobileRow
       };
    }
  }


  getGridTemplateStyle(){
     if(this.applicationInfoService.isMobile()) {
      return {
        'grid-template-columns':  'repeat(' +  this.displayedColumns.length  + ', minmax(0, 100%))'
      };
    }

  }

  toNumber(value) {
    return Number(value);
  }

  firstPage() {
    this.currentPage = 1;
    this.getData();
  }

  pageSizeChanged() {
    this.currentPage = 1;
    this.getData()
  }

  pageSizeChangedManualy() {
    if (this.currentPage > this.maxPages)  { this.currentPage = this.maxPages; }
    if (this.currentPage < 1)  { this.currentPage = 1; }
    this.getData();
  }

  lastPage() {
    this.currentPage = this.maxPages;
    this.getData();
  }
  nextPage() {
    if (this.currentPage == this.maxPages) { return; }
    this.currentPage ++;
    this.getData();
  }

  previousPage() {
    if (this.currentPage == 1) { return; }
    this.currentPage --;
    this.getData();
  }

  getVisibleChilds() {
    this.displayedColumns = [];
    this.controlDefinition.childs.forEach((child, index) => {
      if (child.isActive && child.isVisible && this.uiService.checkViewModeVisibility(child) || child.isDeveloperOnly && this.applicationInfoService.isDeveloper) {
        this.displayedColumns.push('header'.concat(index));
        if (this.isControlDataDriven) {
          this.sortHeaders.push(index);
        } else {
          if (this.commonService.isNullOrUndefined(child.childs[0])) {
            this.sortHeaders.push('header'.concat(index.toString()));
          } else {
            this.sortHeaders.push(child.childs[0].value);
          }
        }
      } else {
        this.missingHeaderIndex.push(index);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.isControlDataDriven) {
      this.currentPage = 1;
      this.getData().then(() => { this.paginator.firstPage() });
    } else {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  getHeader(colIndex, checkIfMobileMultipleSubChild = false) {
    if (this.controlDefinition.childs[colIndex]) {
      if (this.controlDefinition.childs[colIndex].childs.length > 1 && this.applicationInfoService.isMobile() && checkIfMobileMultipleSubChild) {
        return '';
      } else {
        return this.axivasTranslateService.getTranslationTextForToken(this.controlDefinition.childs[colIndex].displayText);
      }
    }
  }

  sortDataByColIndex(colIndex, fixedDirection = '', customPage = null) {
    let sortDirection = 'ASC'
    this.currentPage = 1;
    if (customPage != null) {
      this.currentPage = customPage;
    }

    if (this.selectedSortCol == colIndex + 'ASC') {
      this.selectedSortCol = colIndex + 'DESC';
      sortDirection = 'DESC';
    } else if (this.selectedSortCol == colIndex + 'DESC') {
      this.selectedSortCol = colIndex + 'ASC';
      sortDirection = 'ASC';
    } else {
      this.selectedSortCol = colIndex + 'ASC';
      sortDirection = 'ASC';
    }
    if (fixedDirection != '') {
      sortDirection = fixedDirection;
    }
    this.sortString = colIndex.toString().concat(' ', sortDirection);
    this.getControlData();
  }

  sortData(event) {
    // this.sortString = event.active.concat(' ', event.direction.toUpperCase());
    this.currentPage = 1;
    if (event.active.toString() === 'header0') {
      this.sortString = '0'.concat(' ', event.direction.toUpperCase());
    } else {
      this.sortString = event.active.toString().concat(' ', event.direction.toUpperCase());
    }
    this.getControlData();
  }

  getParamArrayForExternalDataSource(): any[] {
    const queryArray = [];
    if (this.isMultiList) {
      queryArray.push(this.controlDefinition.uiEntityInstanceId);
      queryArray.push(this.controlDefinition.additionalSetting1);
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId))) {
        console.warn('Multilist - no data for entity ', this.controlDefinition.uiEntityInstanceId);
      } else {
        queryArray.push(this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId).data.id);
      }
      queryArray.push(this.uiService.getQueryFieldsFromListControl(this.controlDefinition));
    } else {
      queryArray.push(this.uiService.getQueryFieldsFromListControl(this.controlDefinition));
    }
    return queryArray;
  }

  getMatSortHeader(colIndex) {
    if (this.isControlDataDriven) {
      return colIndex;
    } else {
      return this.controlDefinition.childs[colIndex].value;
    }
  }

  getData(): Promise<any> {
    return new Promise((getDataResolve, getDataReject) => {
      if (this.refreshBlocker) {
        getDataResolve(null);
        return;
      }
      if (this.isMultiList) {
        const refId = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId).data.id;
        if (refId === undefined) {
  /*         console.log('MatTable get data ', 'ref id undefined'); */
          return;
        }
      }
      if (this.isControlDataDriven) {
        this.getControlData()
        .then(() => getDataResolve(null))
        .catch(() => { getDataReject });
      } else {
        const queryArray = this.getParamArrayForExternalDataSource();
        if (this.queryDataSource !== null) {
          this.loadingControlData = true;
          const namedArray = [];
          namedArray.push('<compactListSubControls>;' + this.uiService.getQueryFieldsFromListControl(this.controlDefinition));
          this.externalDatasourceService.executeQuery(
            this.applicationInfoService.externalDataSources.Item(this.queryDataSource)
            , queryArray, null, false, namedArray)
          .then(result => {
            if (!Array.isArray(result)) {
              result = [result];
            }
            this.lastResult = result;
            this.resultsLength = result.length;
            this.dataSource = new MatTableDataSource(result);
            this.lastSearchFirstItem = this.pageSize * (this.currentPage - 1) + 1;
            this.lastSearchLastItem = this.pageSize * this.currentPage;
            this.loadingControlData = false;
            getDataResolve(result);
          })
          .catch(error => {
            this.loadingControlData = false;
            getDataReject(error);
          });
        }
      }

    })
  }

  resetDate(element, key, value) {
    element[key] = (value != null) ? new Date(value).toUTCString() : null;
    this.saveRow(element);
  }

  getImage(element, subChild) {
    var image = this.objectDeserializeService.transform(element, subChild.value);
    if (image !== '') {
      // console.warn(image, element, subChild)
      var pattern = /^((http|https):\/\/)/;

      if(!pattern.test(image)) {
       image =  image.replace(image, 'https://'+ image);
      }

      return image;
    } else {
      return 'assets/images/svg/'.concat(subChild.additionalSetting1);
    }
  }

  rowClicked(row) {
    this.refreshBlocker = true;
    this.controlValue = row;
    this.applicationInfoService.lastClickedMatTable = this.controlDefinition.id;
    this.callMethod('onClick', this.controlDefinition);
    setTimeout(() => {
      this.refreshBlocker = false;
    }, 2000);
  }

  rowDblClicked(row) {
    this.refreshBlocker = true;
    this.controlValue = row;
    this.callMethod('onDblClick', this.controlDefinition);
    setTimeout(() => {
      this.refreshBlocker = false;
    }, 2000);
  }

  getSubChildValue(element, subChild, ignoreTrimming = true): string {
    let returnValue = '';
    let multipleValuesInOneRow = [subChild.value];
    if (this.commonService.checkIfStringContainsString(subChild.value, ';')) {
      multipleValuesInOneRow = subChild.value.split(';');
    }
    multipleValuesInOneRow.forEach(value => {
      let currentValue = '';
      if (this.commonService.checkIfStringContainsString(value, '.')) {
        currentValue = this.objectDeserializeService.transform(element, value);
      } else {
        currentValue = element[value];
      }
      if (subChild.controlType !== null) {
        if ((subChild.controlType.toString() === '5' || subChild.controlType.toString() === '16') && currentValue !== null && currentValue != '') {
          currentValue = new Date(currentValue).toLocaleString();
        }
      }
      if (currentValue !== null) {
        returnValue = returnValue.concat(currentValue, ' ');
      }
    });
    if (!this.commonService.isNullOrUndefined(this.additionalDataSourcesArray[subChild.id])) {
      this.additionalDataSourcesArray[subChild.id].forEach(value => {
        if (Number(value.id) === Number(returnValue)) {
          if (this.commonService.isNullOrUndefined(value[this.additionalDataSourcesDisplayValues[subChild.id]])) {
            returnValue = this.axivasTranslateService.getTranslationTextForObject(value, 'nameTranslationToken');
          } else {
            returnValue = this.axivasTranslateService.getTranslationTextForToken(
              value[this.additionalDataSourcesDisplayValues[subChild.id]], false);
          }
        }
      });
    }
    returnValue = this.getModifiedDemoUrl(returnValue);

    if (!ignoreTrimming) {
      returnValue = this.getShortText(returnValue, subChild)
    }

    return returnValue;
  }

  getShortText(text, subChild) {
    let returnValue = text;
    if (subChild.additionalSetting1 !== '' && subChild.additionalSetting1) {
      if (text.length > Number(subChild.additionalSetting1)) {
        returnValue = returnValue.substr(0, Number(subChild.additionalSetting1)) + '...'
      }
    }
    return returnValue;
  }

  deleteRow(row) {
    if (this.checkIfIsEnabled(this.controlDefinition) === true) { return; }
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('MatTable.DeleteItem.Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('MatTable.DeleteItem.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true, false, 'matTable - deleteRow');
        this.externalDatasourceService.executeExternalDataSource(this.deleteDataSource, [row.id])
        .then(() => {
          this.loaderService.display(false, false, 'matTable - deleteRow');
          this.getData();
        })
        .catch(error => {
          this.loaderService.display(false, false, 'matTable - deleteRow');
          console.error(error);
        });
      },
      reject: () => {
        return;
      }
    });
  }

  saveRow(element) {
    if (this.isMultiList) {
      element['entityId'] = this.controlDefinition.uiEntityInstanceId;
      element['refId'] = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId).data.id;
      element['lookupEntityMultiListTypeId'] = Number(this.controlDefinition.additionalSetting1);
    }
    this.externalDatasourceService.executeExternalDataSource(this.updateDataSource, [
      this.commonService.getModifyArrayBody(element, ['account', 'contact'], [])
    ])
    .then(() => {
      this.eventService.matTableUpdatedEvent.emit(null);
    });
  }

  createRow() {
    if (this.checkIfIsEnabled(this.controlDefinition) === true) { return; }

    let queryArray = {};
    if (this.isMultiList) {
      queryArray = {
        entityId: this.controlDefinition.uiEntityInstanceId,
        refId: this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId).data.id,
        lookupEntityMultiListTypeId: Number(this.controlDefinition.additionalSetting1)
      };
    }
    this.loaderService.display(true, false, 'matTable - createRow');
    this.externalDatasourceService.executeExternalDataSource(this.createDataSource, [this.commonService.getModifyArrayBody(queryArray, [])])
    .then(() => {
      this.eventService.matTableUpdatedEvent.emit(null);
      this.loaderService.display(false, false, 'matTable - createRow');
      this.getData();
    })
    .catch(error => {
      this.loaderService.display(false, false, 'matTable - createRow');
      console.error('createRow', error);
    });
  }

  getResultString() {
    let returnValue = '';

    if (this.resultsLength == 0) {
      return '';
    } else {
      let first = this.lastSearchFirstItem;
      let last = this.lastSearchLastItem;
      if (last > this.resultsLength) {
        last = this.resultsLength;
      }
      returnValue = returnValue.concat(
        first.toString(), ' - ',
        last.toString(), ' ',
        this.axivasTranslateService.getTranslationTextForToken('MatTable.Label.Of'), ' ',
        this.resultsLength.toString(), ' ',
        this.axivasTranslateService.getTranslationTextForToken('MatTable.Label.Rows')
      );
    }
    return returnValue;
  }

  prepareAdditionalExternalDataSources() {
    this.additionalDataSourcesArray = [];
    this.additionalDataSourcesDisplayValues = [];
    this.controlDefinition.childs.forEach(child => {
      child.childs.forEach(subChild => {
        subChild.uiControlExternalDataSources.forEach(externalDataSource => {
          this.externalDatasourceService.executeExternalDataSource(externalDataSource.externalDataSource.id, [])
          .then(result => {
            this.additionalDataSourcesArray[subChild.id] = result;
            this.additionalDataSourcesDisplayValues[subChild.id] = externalDataSource.externalDataSource.dataValue;
          });
        });

        /// Entity member
        // console.warn('YYYY', subChild,
        //   this.uiService.getExternalDatasourceFromControlEntityMember(subChild),
        //   this.uiService.getEntityMemberLookupTable(subChild)
        // );
        const memberExternalDataSource = this.uiService.getExternalDatasourceFromControlEntityMember(subChild);
        if (memberExternalDataSource) {
          // console.warn('getExternalDatasourceFromControlEntityMember', memberExternalDataSource);
          this.externalDatasourceService.executeQuery(memberExternalDataSource).then(result => {
            this.additionalDataSourcesArray[subChild.id] = result;
            this.additionalDataSourcesDisplayValues[subChild.id] = memberExternalDataSource.dataValue;
            // console.warn(this.additionalDataSourcesArray, '-', result);
          });
        } else {
          const lookUp = this.uiService.getEntityMemberLookupTable(subChild);
          if (!this.commonService.isNullOrUndefined(lookUp)) {
            this.externalDatasourceService.executeLookUpTable(lookUp)
            .then(result => {
              this.additionalDataSourcesArray[subChild.id] = result;
              this.additionalDataSourcesDisplayValues[subChild.id] = 'defaultName';
              // console.warn(this.additionalDataSourcesArray, '-', result);
              });
          }
        }
      });
    });
  }

  getVisibleItems(itemString) {
    if (!this.commonService.isNullOrUndefined(itemString)) {
      return itemString.split(';');
    } else {
      return '';
    }
  }

  getDateWarning(element, subChild) {
    if(!this.commonService.isNullOrUndefined(element[subChild.value]) && element[subChild.value] != '') {
      const date1 = new Date(element[subChild.value]);
      const date2 = new Date(Date.now());
      if (date1 < date2) {
        return '#ed5c73';
      } else {
        return '#1c3a56';
      }

    } else {
      return '#1c3a56';
    }
  }

  checkIfIsEnabled(subChild) {
    if (this.controlDefinition.isEnabled === false) {
      return true;
    }
    let isEnabled = this.uiService.checkAttributeChanges('enabled', this.controlDefinition, true, VariableType.Boolean);
    isEnabled = this.uiService.checkAttributeModifierGroups('enabled', this.controlDefinition, isEnabled);
    if (subChild.isEnabled === false) { isEnabled = false; }
    return !isEnabled;
  }

  handlePageEvent(event: PageEvent) {
    if (this.isControlDataDriven) {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex + 1;
      this.getData();
    }
  }

  getControlData(): Promise<any> {
    return new Promise((getControlDataResolve, getControlDataReject) => {
      if (!this.isControlDataDriven) {
        getControlDataResolve(null);
        return;
      }
      const paramQueryArray = this.getParamArrayForExternalDataSource();
      const controlInfo = this.generateControlInfoArray();
      let query = '';
      query = this.commonService.modifyQueryStringWithApplicationInfos(query);
      query = this.externalDatasourceService.modifyQueryWithExternalDataSourceQueryModifiers(query,
        this.applicationInfoService.externalDataSources.Item(this.queryDataSource));
      if (this.customQueryReplaced == false) {
        this.customQuery = this.customQuery.replace(/"/g, '\\"');
        this.customQueryReplaced = true;
      }

      if (this.customQuery !== '' || !this.commonService.isNullOrUndefined(this.queryDataSource)) {
        // this.loaderService.display(true, false, 'materialTable getControlData: '.concat(this.controlDefinition.id));
        this.loadingControlData = true;
        if (this.controlDefinition.value !== '' && this.controlDefinition.value !== null) {
          const valueDetails = this.controlDefinition.value.split(';');
          if (this.applicationInfoService.matTableTempFilter != null) {
            valueDetails[1] = valueDetails[1].replace('<filterId>', this.applicationInfoService.matTableTempFilter);
          }
          if (this.useMyLeadsFilter && this.applicationInfoService.myLeadsSelectedFilter != null) {
            valueDetails[1] = valueDetails[1].replace('<filterId>', this.applicationInfoService.myLeadsSelectedFilter.id);
          }
          valueDetails[1] = valueDetails[1].replace('<filterId>', this.customFilter);
          this.externalDatasourceService.executeControlData2Query(
            controlInfo,
            this.applicationInfoService.externalDataSources.Item(this.queryDataSource),
            paramQueryArray,
            this.uiService.getQueryFieldsFromListControl(this.controlDefinition),
            this.customQuery,
            valueDetails
          )
          .then(getControlDataResult => {
            // this.loaderService.display(false, false, 'materialTable getControlData: '.concat(this.controlDefinition.id));
            if (this.globalFilter == '' &&  !this.applicationInfoService.matTableCustomFiltersUsed && getControlDataResult != null) {
              this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'] = getControlDataResult.data;
              this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFiltersCountTotalRecords'] = getControlDataResult.countTotalRecords;
              this.eventService.customEvent.emit({id: 'lastMatTableResultWithoutControlFilters'});
            } else {
              this.applicationInfoService.matTableCustomFiltersUsed = true;
            }
            this.lastResult = getControlDataResult.data;
            const parsedResult = this.parseQueryResult(getControlDataResult.data);
            this.dataSource = new MatTableDataSource(parsedResult);
            this.resultsLength = getControlDataResult.countTotalRecords;
            this.maxPages = Math.ceil(this.resultsLength / this.pageSize);
            this.applicationInfoService.dataFilterFilteredItemArray[this.controlDefinition.logicalControlId] = parsedResult;
            this.loadingControlData = false;
            this.allFilesSelected = this.checkIfAllAreSelected();
            this.lastSearchFirstItem = this.pageSize * (this.currentPage - 1) + 1;
            this.lastSearchLastItem = this.pageSize * this.currentPage;
            getControlDataResolve(null);
          })
          .catch(error => {
            this.loadingControlData = false;
            getControlDataReject(error);
          });
        } else {
          this.externalDatasourceService.executeControlDataQuery(
            controlInfo,
            this.applicationInfoService.externalDataSources.Item(this.queryDataSource),
            paramQueryArray,
            this.uiService.getQueryFieldsFromListControl(this.controlDefinition),
            this.customQuery
          )
          .then(getControlDataResult => {
            this.lastResult = getControlDataResult.data;
            if (this.globalFilter == '' && !this.applicationInfoService.matTableCustomFiltersUsed && getControlDataResult != null) {
              this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'] = getControlDataResult.data;
              this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFiltersCountTotalRecords'] = getControlDataResult.countTotalRecords;
              this.eventService.customEvent.emit({id: 'lastMatTableResultWithoutControlFilters'});
            } else {
              this.applicationInfoService.matTableCustomFiltersUsed = true;
            }
            const parsedResult = this.parseQueryResult(getControlDataResult.data);
            this.dataSource = new MatTableDataSource(parsedResult);
            this.resultsLength = getControlDataResult.countTotalRecords;
            this.maxPages = Math.ceil(this.resultsLength / this.pageSize);
            this.applicationInfoService.dataFilterFilteredItemArray[this.controlDefinition.logicalControlId] = parsedResult;
            this.loadingControlData = false;
            this.allFilesSelected = this.checkIfAllAreSelected();
            this.lastSearchFirstItem = this.pageSize * (this.currentPage - 1) + 1;
            this.lastSearchLastItem = this.pageSize * this.currentPage;
            getControlDataResolve(null);
          })
          .catch(error => {
            this.loadingControlData = false;
            getControlDataReject(error);
          });

        }
      }
    });
  }

  parseQueryResult(queryResult) {
    const finalResult = [];
    const arrayItemName = this.getArrayItems();
    queryResult.forEach(resultItem => {
      let controlIndexModifier = 0;
      let resultItemObj = new Object();
      resultItem.cells.forEach((cell, index) => {
        if (this.commonService.checkIfItemIsInArray(this.missingHeaderIndex, index)) {
          controlIndexModifier ++;
        }
        let realIndex = index + controlIndexModifier;
        // value needs to be converted?
        if (!this.commonService.isNullOrUndefined(this.additionalDataSourcesArray[this.controlIdArray[realIndex]])) {
          if (cell === '') {
            cell = null;
          } else {
            cell = Number(cell);
          }
        }
        if (this.commonService.checkIfStringContainsString(arrayItemName[realIndex], '.')) {
          // create subarray -.-
          const value = arrayItemName[realIndex].split('.');
          if (this.commonService.isNullOrUndefined( resultItemObj[value[0]])) {
            resultItemObj[value[0]] = [];
          }
          if (value.length > 2) {
            resultItemObj[value[0]][value[1]] = [];
            resultItemObj[value[0]][value[1]][value[2]] = cell;
          } else {
            resultItemObj[value[0]][value[1]] = cell;
          }
        } else {
          resultItemObj[arrayItemName[realIndex]] = cell;
        }
      });
      resultItemObj['id'] = resultItem.id;
      finalResult.push(resultItemObj);
    });
    return finalResult;
  }

  generateControlInfoArray(isExport = false) {
    const obj = new Object();
    obj['controlId'] = this.controlDefinition.id;
    obj['languageId'] = 2;
    obj['rowsPerPage'] = this.pageSize;
    obj['page'] = this.currentPage;
    obj['resolveLookups'] = false;
    obj['globalFilter'] = this.globalFilter;
    obj['sort'] = this.sortString;

    this.applicationInfoService.matTableCustomFiltersUsed = false;
    let result = this.commonService.getModifyArrayBody(obj, []);
    let filterString = '';
    this.columnFilters.forEach((item, index) => {
      if (filterString !== '') {
        filterString = filterString.concat(', ');
      }
      if (this.commonService.isNullOrUndefined(this.filterArray[index]) || this.filterArray[index] == '') {
        filterString = filterString.concat('null');
      } else {
        this.applicationInfoService.matTableCustomFiltersUsed = true;
        filterString = filterString.concat('"', this.filterArray[index], '"');
      }
    });
    result = result.concat(' columnFilter: [', filterString, '] ');

    if (isExport) {
      obj['rowsPerPage'] = 999999;
      obj['page'] = 1;
      let colfilter = [];
      this.columnFilters.forEach((item, index) => {
        if (this.commonService.isNullOrUndefined(this.filterArray[index])) {
          colfilter.push(null);
        } else {
          colfilter.push(this.filterArray[index]);
        }
      });
      obj['columnFilter'] = colfilter;
      return obj;
    }
    this.applicationInfoService.matTableUserGlobalFilters[this.controlDefinition.id] = this.globalFilter;
    this.applicationInfoService.matTableUserCurrentPages[this.controlDefinition.id] = this.currentPage;
    this.applicationInfoService.matTableUserColumnFilters[this.controlDefinition.id] = this.columnFilters;
    this.applicationInfoService.matTableUserCustomFilters[this.controlDefinition.id] = this.filterArray;
    this.applicationInfoService.matTableSortStrings[this.controlDefinition.id] = this.sortString;
    this.applicationInfoService.matTableUserPageSizeSettings[this.controlDefinition.id] = this.pageSize;
    return result;
  }

  getArrayItems() {
    const returnValue = [];
    this.controlIdArray = [];
    this.controlDefinition.childs.forEach(subControl => {
      subControl.childs.forEach(subSubControl => {
        this.controlIdArray.push(subSubControl.id);
        if (!this.commonService.isNullOrUndefined(subSubControl.value)) {
          returnValue.push(subSubControl.value);
        }
      });
    });
    return returnValue;
  }

  getIconClasses(subChild) {
    return subChild.additionalCssClasses + ' fa-lg';
  }

  controlClicked(control, callChildClickElement) {
    this.controlValue = callChildClickElement;
    this.callMethod('onClick', control);
  }

  checkIfItemIsVisible(element, subChild, child) {
    let returnValue = true;
    // if (
    //   this.getSubChildValue(element, subChild) === '' ||
    //   this.getSubChildValue(element, subChild) === 'undefined' ||
    //   this.getSubChildValue(element, subChild) === null
    // ) {
    //     returnValue = false;
    // }
    subChild.attributeModifiers.forEach(modifier => {
      if (modifier.compareValueType === 5) {
        let checkValue = this.objectDeserializeService.transform(element, modifier.compareValue);
        checkValue = checkValue.toString().toLowerCase();
        returnValue = this.commonService.compare(modifier, checkValue, returnValue);
      }
    });
    child.uiControlSecurityPermissions.forEach(permission => {
      if (!this.userService.hasPermission(permission.securityPermission)) {
        returnValue = false;
      }
    });
    if (!subChild.isVisible) { returnValue = false; }
    if (!child.isVisible) { returnValue = false; }

    if (!subChild.isActive) { returnValue = false; }
    if (!this.uiService.checkViewModeVisibility(child)) { returnValue = false; }
    if (!child.isActive) { returnValue = false; }

    if (child.isDeveloperOnly && this.applicationInfoService.isDeveloper) {
        returnValue = true;
    }

    return JSON.parse(returnValue.toString());
  }

  filterColumn(changedIndex) {
    this.columnFilters = [];
    this.controlDefinition.childs.forEach((child, index) => {
      if (this.commonService.isNullOrUndefined(this.filterArray[index])) {
        this.columnFilters.push(null);
      } else {
        this.columnFilters.push(this.filterArray[index]);
      }
    });
    this.getData();
  }

  getTableHeight(subStract = 0) {
    if (this.controlDefinition.additionalSetting2 == 'fullHeight') {
      let tableSize = Number(this.uiService.getDesignSpanPosition(this.controlDefinition.id, 40));
      if (this.applicationInfoService.designerMode) {
        tableSize = tableSize - 20;
      }
      if (this.controlDefinition.height !== null && this.controlDefinition.height !== '') {
        if (tableSize < this.controlDefinition.height) {
          tableSize = this.controlDefinition.height;
        }
      }
      tableSize = tableSize - subStract - 20;
      return tableSize + 'px';
    } else {
      let tableSize = Number(this.uiService.getControlDimensionWithoutAttribute(this.controlDefinition.height)) - subStract;
      return tableSize + 'px';
    }
  }

  getTableId() {
    return
  }

  getSelectedRow(elementId) {
    // console.warn('element id', this.commonService.checkIfItemIsInArray(this.selectedRowsIds, elementId));
    if (this.commonService.checkIfItemIsInArray(this.selectedRowsIds, elementId)) {
      return true;
    } else
      return false;
  }

  selectRow(element) {
    if (this.commonService.checkIfItemIsInArray(this.selectedRowsIds, element.id)) {
      this.commonService.removeItemFromArray(this.selectedRowsIds, element.id);
      this.commonService.removeItemFromArray(this.selectedRowsData, { id: element.id });
      this.selectedRows[element.id] = false;
    } else {
      this.selectedRowsIds.push(element.id);
      this.selectedRowsData.push({ id: element.id });
      this.selectedRows[element.id] = true;
    }
    this.selectedRowsData = [];
    this.selectedRowsIds.forEach(item => { this.selectedRowsData.push({id: item}); });
    this.eventService.sendCompactListInformationEvent.emit(new JJEvent(this.controlDefinition.logicalControlId, [
      this.resultsLength,
      this.selectedRowsIds.length,
      this.selectedRowsData
    ]));
  }

  checkIfAllAreSelected() {
    let returnValue = true;
    this.lastResult.forEach(element => {
      // console.warn(element);
      if (!this.commonService.checkIfItemIsInArray(this.selectedRowsIds, element.id)) {
        returnValue = false;
      }
    });
    return returnValue;
  }

  selectAll() {
    if (!this.allFilesSelected) {
      this.lastResult.forEach(element => {
        if (this.commonService.checkIfItemIsInArray(this.selectedRowsIds, element.id)) {
          this.commonService.removeItemFromArray(this.selectedRowsIds, element.id);
          this.commonService.removeItemFromArray(this.selectedRowsData, element);
          this.selectedRows[element.id] = false;
        }
      })
      this.allFilesSelected = false;
    } else {
      this.lastResult.forEach(element => {
        if (!this.commonService.checkIfItemIsInArray(this.selectedRowsIds, element.id)) {
          this.selectedRowsIds.push(element.id);
          this.selectedRowsData.push(element);
          this.selectedRows[element.id] = true;
        }
      })
      this.allFilesSelected = true;
    }
    this.selectedRowsData = [];
    this.selectedRowsIds.forEach(item => { this.selectedRowsData.push({id: item}); });
    this.eventService.sendCompactListInformationEvent.emit(new JJEvent(this.controlDefinition.logicalControlId, [
      this.resultsLength,
      this.selectedRowsIds.length,
      this.selectedRowsData
    ]));
  }

  exportData() {
    let body = this.generateControlInfoArray(true);
    const valueDetails = this.controlDefinition.value.split(';');
    body['query'] = valueDetails[0];
    body['projectId'] = Number(this.applicationInfoService.projectID);
    valueDetails[1] = valueDetails[1].replace('<filterId>', this.customFilter);
    body['queryParams'] = [
      this.commonService.modifyQueryStringWithApplicationInfos(valueDetails[1], true)
    ];
    this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info, '',
      this.axivasTranslateService.getTranslationTextForToken('CompactList.ExportData.Message'), false);

    this.sharedAPI.exportMatTableData(body).subscribe(result => {
      this.commonService.downloadFile(result,
        'application/' + 'xlsx',
        ''.concat(this.commonService.removeIllegalCharactersFromFileName(this.applicationInfoService.projectName), '_',
          'export', '_', this.commonService.getDateTimeString(new Date(Date.now())),
          body['query'] === '' ? '' : '_' , body['query'], '.xlsx'));
    });
  }

  editContent() {
    this.applicationInfoService.miscSettings['matTableSupervisorControl'] = this.controlDefinition;
    this.eventService.showJjPopup('MatTable.Label.ModifyTable', 'mattable-supervisor', 90);
  }

  getModifiedDemoUrl(baseString) {
    if (!this.commonService.isNullOrUndefined(baseString)) {
      if (this.applicationInfoService.demoLandingPage != null) {
        baseString = baseString.replace(
          this.applicationInfoService.demoLandingPage,
          this.applicationInfoService.applicationSettings['demoLandingPageReplaceString']
        );
      }
    }
    return baseString;
  }
}
