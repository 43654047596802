import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-ajtracker',
  templateUrl: './ajtracker.component.html',
  styleUrls: ['./ajtracker.component.scss']
})
export class AjtrackerComponent implements OnInit {
  innerHTMLAJTrackerCommon = null;
  innerHTMLAJTrackerAPI = '';
  innerHTMLAJTrackerImplementation ='';  
  innerHTMLAJTrackerUsage ='';  
  section = 0;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private uiService: UiService,
    private axivasTranslateService: AxivasTranslateService,
    private commonService: CommonService,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    const language = this.axivasTranslateService.currentLanguage;
    this.innerHTMLAJTrackerCommon = this.applicationInfoService.applicationSettings['ajTrackerHTMLCommon_' + language];
    this.innerHTMLAJTrackerAPI = this.applicationInfoService.applicationSettings['ajTrackerHTMLAPI_' + language];
    this.innerHTMLAJTrackerImplementation = this.applicationInfoService.applicationSettings['ajTrackerHTMLImplementation_' + language];
    this.innerHTMLAJTrackerUsage = this.applicationInfoService.applicationSettings['ajTrackerHTMLUsage_' + language];
    if (this.applicationInfoService.currentProject.dmcAccountId != null) {
      this.innerHTMLAJTrackerImplementation = this.innerHTMLAJTrackerImplementation.replace('<AccountID>', this.applicationInfoService.currentProject.dmcAccountId);
    }
  } 

  getContentHeight() {
    let tableSize = Number(this.uiService.getDesignSpanPosition('designSpan', 168));
    return tableSize + 'px'; 
  }

  copyScriptToClipboard() {
    let clipBoardText = this.applicationInfoService.applicationSettings['ajTrackerScript'];
    clipBoardText = clipBoardText.replace('<AccountID>', this.applicationInfoService.currentProject.dmcAccountId);
    this.commonService.copyTextToClipboard(
      clipBoardText
    );
    this.messagingService.showDefaultSuccess(
      'General.CopyToClipBoard.Header',
      'General.CopyToClipBoard.Message',
      false
    );
  }
}
