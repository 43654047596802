import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ProcessvisualService } from './processvisual.service';
import { resourceLimits } from 'worker_threads';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { Subscription } from 'rxjs';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';


@Component({
  selector: 'app-processvisual',
  templateUrl: './processvisual.component.html',
  styleUrls: ['./processvisual.component.scss']
})
export class ProcessvisualComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public processvisualService: ProcessvisualService,
    private eventService:  EventService,
    private refreshValueService: RefreshValueService,
    private packagefeatureService: PackagefeatureService,
    private uiService: UiService,
  ) { }


  workflowObject;
  flowChartId = ''
  loading = false
  isDuplicated = false;
  refresh
  featureNotFound = null;
  featureId=null
  selectVar = 0
  flowChartOptions = this.applicationInfoService.workflows.toArray()


  ngOnInit(): void {
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_processvisual']);    
    if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
      this.featureNotFound = true;
    } else {
      this.featureNotFound = false;
    }

    this.flowChartId = "campaignFlowChartId";

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
     

   /*    console.log('event', event); */
      if(event == 'leadStatesLoaded'){
      this.loading = false
     };

     if(event.id === "applicationPageResized"){
      this.loading= false;
     }
  

      // ! EVENT CALLED FROM processVisual Pop-up and toolbar-popup
      if (event.id == 'updateProcessVisualWorkflow') {
        //console.log('PROCESS', event.id)
        this.loading = false;
        this.applicationInfoService.selectedProcessVisualWorkflow = event.value;
        this.showWorkflowDetails();
      };

      if (event.id == 'refreshProcessVisual') {
        this.loading = false;
        this.refreshValueService.getWorkflows();
      }


      // ! EVENT CALLED FROM wizard service and processVisual Pop-up small editor dialoge
      if (event.id == 'updateProcessVisual') {
        //console.log('PROCESS', event.id)
        this.showWorkflowUpdated();
        this.loading =  false;

      }

      // ! EVENT CALLED at the beginning of loading direct from api
      if(event.id == 'workflowUpdated'){
        //console.log('PROCESS', event.id)
        this.loading = false
        this.showWorkflowDetails();
      }

    });
  }

  showWorkflowUpdated(){
    if(this.workflowObject){
      this.applicationInfoService.selectedProcessVisualWorkflow = this.workflowObject.id
      this.eventService.customEvent.emit({
        id: 'updateFlowChart-WIZARD',
        value: this.flowChartId,
        chartData: this.workflowObject,
        editor: true,
      });
    }
  }


  showWorkflowDetails($event?:any){
/*     console.log('event', $event) */
    if(!$event){
      this.applicationInfoService.selectedProcessVisualWorkflow= 0;
    }

    this.loading = false
    this.workflowObject = this.applicationInfoService.workflows.toArray().find(workflowItem => workflowItem.id == this.applicationInfoService.selectedProcessVisualWorkflow);

    if(this.workflowObject){
      this.applicationInfoService.selectedProcessVisualWorkflow = this.workflowObject.id
      this.eventService.customEvent.emit({
        id: 'updateFlowChart',
        value: this.flowChartId,
        chartData: this.workflowObject,
        editor: true,
      });
    }
   
  }

  workflowsArray(){
    const array = this.applicationInfoService.workflows.toArray()

    return array.map((item) => {
      return { id: item.id , name: item.defaultName }
    })
  }


  getContentHeight() {
     return Number(this.uiService.getDesignSpanPosition('processvisual', 125));
    
  }


  ngOnDestroy(): void {
    this.applicationInfoService.selectedProcessVisualWorkflow = 0 ;
    this.isDuplicated = false ;
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

}
