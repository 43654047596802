<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="wizardPage">
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailContent.Label.DefaultName' | texttransform }}
                <app-wizard-infobutton displayText="EMailContent.Info.DefaultName"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.defaultName" type="text" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailContent.Label.Sender' | texttransform }}
                <app-wizard-infobutton displayText="EMailContent.Info.Sender"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.sender" type="text" class="wizardInput" (change)="checkDomain()">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailContent.Label.ReplyTo' | texttransform }}
                <app-wizard-infobutton displayText="EMailContent.Info.ReplyTo"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.replyTo" type="text" class="wizardInput">
        </div>
        <div *ngIf="senderIsValid == false" class="domainNotValid everleadCoral">
            {{ 'EMailTemplate.Label.SenderDomainInvalid' | texttransform }}
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailTemplate.Label.IsRequireDoubleOptIn' | texttransform }}
                <app-wizard-infobutton displayText="EMailContent.Info.IsRequireDoubleOptIn"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.isRequireDoubleOptIn" type="checkbox">
        </div>
        
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 5" class="wizardPage">
        <div class="projectSettings__header">
            <div class="projectSettings__header__newItem fingerCursor" (click)="createNewConfig()">
                <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"            
                    [tippy]="'General.Label.CreateNewWithWizard' | texttransform"></svg-icon>
        
                {{ 'EMailTemplate.Label.NewConfig' | texttransform }}
            </div>
            <div class="projectSettings__header__interaction">               
                <div class="projectSettings__header__filter">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                    </svg-icon>
                    <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="mailConfigSesRefIds.length == 0 && mailConfigSmtpRefIds.length == 0">
                Todo
            </div>
            <div *ngFor="let config of wizardService.wizardArray.emailTemplateConfigs" class="everleadShadowBox emailTemplateConfig__wrapper">
                <div class="maxWidth emailTemplateConfig__content">
                    <!-- SES -->
                    <div class="emailTemplateConfig__item">
                        <label>{{ 'GeneralSettings.Setting.MailConfigTypeLookupId' | texttransform }}</label>
                        <select [(ngModel)]="config.typeLookupId">
                            <option *ngFor="let mailConfigType of mailConfigTypes" [ngValue]="mailConfigType.id">
                                {{ commonService.getTranslationValueFromArray(mailConfigType) | texttransform }}
                            </option>
                        </select>
                    </div>
                    <div class="emailTemplateConfig__item" *ngIf="config.typeLookupId == 5935">
                        <label>{{ 'GeneralSettings.Setting.MailConfigSesRefId' | texttransform }}</label>
                        <select [(ngModel)]="config.campaignId" (change)="updateTemplateConfig(config, true)">
                            <option *ngFor="let mailConfigRefId of mailConfigSesRefIds" [ngValue]="mailConfigRefId.id">
                                {{ commonService.getTranslationValueFromArray(mailConfigRefId) | texttransform }}
                            </option>
                        </select>
                    </div>               

                    <!-- SMTP -->
                    <div class="emailTemplateConfig__item" *ngIf="config.typeLookupId == 5936">
                        <label>{{ 'GeneralSettings.Setting.MailConfigSMTPRefId' | texttransform }}</label>
                        <select [(ngModel)]="config.configRefId" (change)="updateTemplateConfig(config)">
                            <option *ngFor="let mailConfigRefId of mailConfigSmtpRefIds" [ngValue]="mailConfigRefId.id">
                                {{ commonService.getTranslationValueFromArray(mailConfigRefId, 'displayName') | texttransform }}
                            </option>
                        </select>
                    </div>                    
                </div>
                <div class="emailTemplateConfig__buttons">
                    <i class="far fa-star fingerCursor" (click)="highlightConfig(config)"></i>
                    <i class="fas fa-trash fingerCursor" (click)="deleteConfig(config)"></i>
                </div>
            </div>
        </div>
    </div>

    


    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 4" class="wizardPage">
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailTemplate.Label.Receiver' | texttransform }}
                <app-wizard-infobutton displayText="EMailTemplate.Info.Receiver"></app-wizard-infobutton>
            </label>            

            <select [(ngModel)]="wizardService.wizardArray.testMailUser" class="wizardInput">
                <option *ngFor="let testMailUser of testMailUsers" [ngValue]="testMailUser">{{ testMailUser.userName }}
                </option>
            </select>
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailTemplate.Label.Campaign' | texttransform }}
                <app-wizard-infobutton displayText="EMailTemplate.Info.Receiver"></app-wizard-infobutton>
            </label>            

            <select [(ngModel)]="wizardService.wizardArray.testMailCampaign" class="wizardInput">
                <option *ngFor="let campaign of applicationInfoService.campaigns.toArray()" [ngValue]="campaign.id">{{ commonService.getTranslationValueFromArray(campaign, 'defaultName') | texttransform }}
                </option>
            </select>
        </div>

        <button class="jjButtonColor jjButtonStyle" [disabled]="testMailContact == null" (click)="testTemplate()">{{ 'EMailTemplate.Label.TestMail' | texttransform }}</button>
    </div>



    <div  *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="wizardPage">
        <div class="wizardEMail__wrapper">
            <div class="wizardDetailItem wizardEMail__emailFlex">
                <div class="wizardEMail__emailEditBar__item headerRow">
                    <div>
                        <label class="wizardEMail__emailEditBar__headerLabel fingerCursor" (click)="setViewMode(1)"
                            [ngClass]="{ activeColor: viewMode == 1 }">{{ 'EMailContent.Label.Editor' | texttransform
                            }}</label>
                        <label class="wizardEMail__emailEditBar__headerLabel fingerCursor" (click)="setViewMode(2)"
                            [ngClass]="{ activeColor: viewMode == 2 }">{{ 'EMailContent.Label.Preview' | texttransform
                            }}</label>
                        <label class="wizardEMail__emailEditBar__headerLabel fingerCursor" (click)="setViewMode(3)"
                            [ngClass]="{ activeColor: viewMode == 3 }">{{ 'EMailContent.Label.PlainText' | texttransform
                            }}</label>
                    </div>
                    <div>
                        <select [(ngModel)]="emailLanguage" (change)="getEMailTemplateLanguage()">
                            <option [ngValue]=commonService.toNumber(2)>Deutsch</option>
                            <option [ngValue]=commonService.toNumber(1)>English</option>
                        </select>
                    </div>
                </div>
                <div class="maxHeight" *ngIf="(viewMode == 1 || viewMode == 3) && emailTemplateLanguage">
                    <div class="subject">
                        <label>{{ 'EMailTemplate.Label.Subject' | texttransform }}
                            <app-wizard-infobutton displayText="EMailTemplate.Info.Subject"></app-wizard-infobutton>
                        </label>
                        <input [(ngModel)]="emailTemplateLanguage.subject" type="text">
                    </div>                                    
                    <textarea *ngIf="viewMode == 1" #templateContentEditor class="wizardEMail__wizardEmailEditor"
                        [(ngModel)]="emailTemplateLanguage.bodyHtml" (click)="getCaretPos(templateContentEditor)"
                        (keyup)="getCaretPos(templateContentEditor)"></textarea>
                    <textarea *ngIf="viewMode == 3" [(ngModel)]="emailTemplateLanguage.bodyText" 
                        class="wizardEmailTemplatePlainText"></textarea>
                </div>
                <div class="wizardPage__mailEditor" *ngIf="viewMode == 2">
                    <div [innerHTML]="innerHTML" class="wizardEMail__wizardEmailEditor"></div>
                </div>
            </div>

            <div class="wizardDetailItem wizardEMail__addons" *ngIf="viewMode == 1 || viewMode == 3">
                <div class="wizardEMail__emailEditBar__item wizardEMail__emailEditBar__item__flex">
                    <label class="wizardEMail__emailEditBar__headerLabel"
                        [ngClass]="{ activeColor: viewModeToolbar == 1 }" (click)="setViewModeToolbar(1)">
                        {{ 'EMailTemplate.Label.Publish' | texttransform }}
                    </label>
                    <label class="wizardEMail__emailEditBar__headerLabel" *ngIf="viewMode == 1"
                        [ngClass]="{ activeColor: viewModeToolbar == 2 }" (click)="setViewModeToolbar(2)">
                        {{ 'EMailContent.Label.Contents' | texttransform }}
                    </label>
                </div>

                <div class="wizardEMail__addons__content wizardEMail__addons__content__flexBox"
                    *ngIf="viewModeToolbar == 1">
                    <div>
                        <label class="smallFont"
                            [innerHTML]="'EMailTemplate.Label.PublishDescription' | texttransform"></label>
                    </div>
                    <div>
                        <button class="jjButtonStyle jjButtonColor wizardEmailTemplateSaveButton" (click)="saveTemplateLanguage(true)">
                            {{ 'EMailTemplate.Label.Publish' | texttransform }}
                        </button>
                    </div>
                </div>

                <div *ngIf="viewModeToolbar == 2">
                    <label class="smallFont">{{ 'EMailContent.Label.ContentsDescription' | texttransform }}</label>
                </div>
                <div class="wizardEMail__addons__content" *ngIf="viewModeToolbar == 2">
                    <div class="wizardEMail__placeholder" *ngFor="let emailContent of emailContents"
                        [tippy]="emailContent.defaultName"
                        [ngClass]="{ everleadCoralBackground: emailContent.version == 1 }"
                    >
                        <i class="fas fa-chevron-circle-left fingerCursor" (click)="addContentToTemplate(emailContent)" *ngIf="emailContent.version != 1"></i>
                        <i class="fas fa-exclamation-triangle" [tippy]="'EMailContent.Label.ContentNotPublished' | texttransform" *ngIf="emailContent.version == 1"></i>
                        {{ emailContent.defaultName }}
                    </div>
                </div>
                <button class="jjButtonStyle jjButtonColor wizardEmailTemplateSaveButton" (click)="saveTemplateLanguage()">                    
                    {{ 'General._.Save' | texttransform }}
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 6" class="wizardPage">
        <input type="file" #file accept="*.*" placeholder="Choose file" (change)="addAttachment($event)" style="display:none;">
        <!-- <div class="wizardDetailItem">
            <label>{{ 'EMailTemplate.Label.Attachments' | texttransform }}<i class="fas fa-plus fingerCursor" (click)="file.click()"></i></label>
        </div> -->
        <div class="emailAttachments__wrapper">
            <div class="emailAttachments__existingAttachments">
                <div class="emailAttachments__existingAttachments__header">
                    <label>{{ 'EMailTemplate.Label.AlreadyAvailableAttachments' | texttransform }}</label>
                    <select [(ngModel)]="emailLanguage">
                        <option *ngFor="let language of languages" [ngValue]="language.id">
                            {{ commonService.getTranslationValueFromArray(language, 'languageName') | texttransform }}
                        </option>
                    </select>
                    <i class="fas fa-exclamation-triangle everleadCoral" *ngIf="showAttachmentWarning" 
                        [tippy]="'EMailTemplate.Label.AttachmentWarning' | texttransform"></i>
                </div>
                
                <div class="emailAttachments__files">
                    <div *ngFor="let emailTemplateAttachment of emailAttachments"  class="everleadShadowBox wizardEmailTemplate__attachment" [ngClass]="{
                        hideInDom: emailTemplateAttachment.languageId != emailLanguage
                    }">
                        <div class="maxWidth">{{ emailTemplateAttachment.filename }}</div>
                        <!-- <div class="wizardEmailTemplate__attachment__size">{{ commonService.getAttachmentSizeStringFromAttachmantSize(attachment.size) }}</div> -->
                        <div class="wizardEmailTemplate__attachment__delete"><i class="fas fa-trash fingerCursor everleadCoral" (click)="deleteAttachment(emailTemplateAttachment)"></i></div>
                    </div>    
                </div>
            </div>
            <div class="emailAttachments__dragger">
                <app-externalfiledragcontrol class="contentLibraryItem__dragDrop" (selectedFiles)="onFilesSelected($event)"></app-externalfiledragcontrol>
            </div>            
        </div>
    </div>


    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 3" class="wizardPage">
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailTemplate.Label.CreateWorkflowStep' | texttransform }}</label>
            <label class="wizardSubheader__descriptionLabel"
                [innerHTML]="'EMailTemplate.Label.CreateWorkflowStepDescription' | texttransform"></label>
            <br>
            <button class="jjButtonStyle jjButtonColor createResultButton" [disabled]="blockWorkflowCreation" (click)="createWorkflow()">
                {{ 'EMailTemplate.Label.CreateWorkflowStep' | texttransform }}
            </button>
        </div>        
        <div class="wizardDetailItem" *ngIf="resultsWithThisEMailTemplate.length > 0">
            <label class="wizardLabel">{{ 'EMailTemplate.Label.ResultsWithThisTemplate' | texttransform }}</label>
            <div *ngFor="let result of resultsWithThisEMailTemplate" class="resultListItem">
                <div class="resultListItem__step">                    
                    <label class="ellipsisText">
                        <i class="fas fa-shoe-prints"></i>
                        {{ commonService.getNameTranslationOrDefaultName(
                            applicationInfoService.steps.Item(result.stepId)
                        ) | texttransform }}    
                    </label>
                </div>
                <div class="everleadCoral">
                    {{ commonService.getNameTranslationOrDefaultName(result) | texttransform }}
                </div>                
            </div>
        </div>
    </div>
</div>