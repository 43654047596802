import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-defaultdashboard-failedmails',
  templateUrl: './defaultdashboard-failedmails.component.html',
  styleUrl: './defaultdashboard-failedmails.component.scss'
})
export class DefaultdashboardFailedmailsComponent implements OnInit {
  @Input() selectedReport = null;
  @Input() eventBookingBatch = null;
  dashboardResultData = null;
  loadingData = false;
  userWithData = [];

  pools = [];
  poolId = null;

  campaigns = [];
  campaignId = null;  
  
  users = [];
  userId = null;  

  timeRange = 2;
  dateFrom = new Date(2024, 0, 1, 0, 0, 0 , 0);
  dateUntil = new Date(Date.now());
  
  selectedUser = null;
  showMoreDetails = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public dashboardcoreService: DashboardcoreService,
    private methodService: MethodService,
    private commonService: CommonService,
    public userService: UserService
  ) {}
  
  ngOnInit(): void {
    this.getCampaigns();
    this.getUsers();
    this.getPools();
    this.getDashboardDetails();
  }
  
  getDashboardDetails(): Promise<any> {
    this.loadingData = true;
    this.selectedUser = null;
    return new Promise((getDashboardDetailsResolve, getDashboardDetailsReject) => {
      let params = this.fillParams();
      this.dashboardcoreService.getDashboardData(this.selectedReport.dashboardId, params, false).then(getDashboardDataArrayResult => {      
        this.loadingData = false;        
        if (getDashboardDataArrayResult[0][0]) {
          this.dashboardResultData = getDashboardDataArrayResult[0][0].fn_dashboard_failed_emails;
          this.userWithData = [...new Set(this.dashboardResultData.map(item => item.user_name))];
        }
        console.warn('getDashboardDetails', getDashboardDataArrayResult, this.dashboardResultData, this.userWithData);
        getDashboardDetailsResolve(getDashboardDataArrayResult);
      }).catch((error)=>{
        this.loadingData = false;
        getDashboardDetailsReject(error);
      });  
    });
  }
  
  fillParams() {
    const params = [];   
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@campaign_id', value: this.campaignId });
    params.push({ key: '@user_id', value: this.userId });
    params.push({ key: '@pool_id', value: this.poolId });
    if (this.dateFrom == null) {
      params.push({ key: '@date_from', value: null });
    } else {
      params.push({ key: '@date_from', value:this.commonService.getDateString(this.dateFrom) });
    }
    if (this.dateUntil == null) {
      params.push({ key: '@date_until', value: null });
    } else {
      params.push({ key: '@date_until', value:this.commonService.getDateString(this.dateUntil) });
    }
    return params;
  }

  getUsers() {
    this.users = this.applicationInfoService.projectUsers.toArray();
  }

  getPools() {
    this.pools = this.applicationInfoService.pools.toArray().filter(pool => pool.isActive == true);    
    this.poolId = this.applicationInfoService.poolId;
  }

  getCampaigns() {
    this.campaigns = this.applicationInfoService.campaigns.toArray().filter(campaigns => campaigns.isActive == true);
    if (this.applicationInfoService.campaingnModeId != null) {
      this.campaignId = this.applicationInfoService.campaingnModeId;
    } else {
      this.campaignId = this.campaigns[0].id;
    }    
  }

  getDataFromUser(userWithDataItem) {
    const returnValue = this.dashboardResultData.filter(item => item.user_name == userWithDataItem);
    returnValue.sort((a, b) => new Date(a.result_date) > new Date(b.result_date) ? -1 : 1);
    return returnValue;
  }

  showUserDetails(userWithDataItem) {
    this.selectedUser = userWithDataItem;
  }

  showAccount(dataItem) {
    if (dataItem.account_id) {
      this.methodService.launchInternalMethod(InternalMethodType.ShowAccountDataPopup, [dataItem.account_id]);
      if (dataItem.contact_id) {
        this.methodService.launchInternalMethod(InternalMethodType.LoadContact, [dataItem.contact_id]);
      };
    }   
  }
}
