import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UiService, ControlType } from '../../../service/ui.service';
import { EventService } from '../../../service/event.service';
import { Subscription } from 'rxjs';
import { EntityService, ValueType } from '../../../service/entity.service';
import { ApplicationInfoService, VariableType } from '../../../../core/application/application-info.service';
import { LoaderService } from '../../../../shared/service/loader-service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ControlService } from 'app/jollyjupiter/service/control.service';
import { type } from 'os';
import { isNullOrUndefined } from 'util';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

enum LabelPosition {
  NoLabel = 0,
  LeftOfControl = 1,
  TopOfControl = 2,
  RightOfControl = 3,
  BelowControl = 4
}

@Component({
  selector: 'app-custom-universal',
  templateUrl: './custom-universal.component.html',
  styleUrls: ['./custom-universal.component.scss']
})

export class CustomUniversalComponent implements OnInit, OnDestroy {
  // modeGrid
  @Input() ignoreDesigner = false;
  @Input() controlid: any;
  @Input() additionalvalues: any;
  public controlType = ControlType;
  labelPosition = LabelPosition;
  isControlActivatedForQuerys = true;

  // Style
  isVisible = true;
  isEditable = true;

  controlDefinition: any = null;
  externalDataSource: any = null;
  entityMemberlookupTable: any = null;
  innerControlOnly = false;
  controlGUID = '';
  controlValue: any;
  entity: any;
  methods: any[];
  data: any[] = [];

  // Subscriptions
  updateEntityEventSubscription: Subscription = new Subscription();
  raiseShowRequiredFieldEventSubscription: Subscription = new Subscription();
  raiseResetRequiredFieldEventSubscription: Subscription = new Subscription();
  changeControlStyleSubscription: Subscription = new Subscription();
  updateControlAttributesEventSubscription: Subscription = new Subscription();
  updateControlDefinitionSubscription: Subscription = new Subscription();
  onUiControlCacheUpdateEventSubscription: Subscription = new Subscription();

  isinline = false;
  isRequired = false;
  designMode = 2;

  public settingJSON = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    public uiService: UiService,
    private entityService: EntityService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private controlService: ControlService,
    public designerService: DesignerService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnDestroy() {
    if (this.updateEntityEventSubscription) { this.updateEntityEventSubscription.unsubscribe(); }
    if (this.raiseShowRequiredFieldEventSubscription) { this.raiseShowRequiredFieldEventSubscription.unsubscribe(); }
    if (this.raiseResetRequiredFieldEventSubscription) { this.raiseResetRequiredFieldEventSubscription.unsubscribe(); }
    if (this.changeControlStyleSubscription) { this.changeControlStyleSubscription.unsubscribe(); }
    if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }
    if (this.updateControlDefinitionSubscription) { this.updateControlDefinitionSubscription.unsubscribe(); }
    if (this.onUiControlCacheUpdateEventSubscription) { this.onUiControlCacheUpdateEventSubscription.unsubscribe(); }    
  }

  ngOnInit() {
    if (!this.controlid) { console.log('Missing control id'); return; }
    this.controlGUID = this.commonService.createGuid();
    this.controlDefinition = this.uiService.getControlDefinitions(this.controlid);
    if (!this.controlDefinition) { console.log('Control definition not found for control', this.controlid); return; }

    // External data source
    this.controlDefinition.uiControlExternalDataSources.forEach(element => {
      if (element.externalDataSource.queryType.toLowerCase() === 'query') {
        this.externalDataSource = element.externalDataSource;
      }
    });
    this.entityMemberlookupTable = this.uiService.getEntityMemberLookupTable(this.controlDefinition);
    if (this.controlDefinition.uiControlExternalDataSources.length === 0) {
      this.externalDataSource = this.uiService.getExternalDatasourceFromControlEntityMember(this.controlDefinition);
    }
    this.isinline = this.controlDefinition.inline > 0;

    // InformationPanel
    if (this.controlDefinition.controlType === ControlType.Information) { this.isVisible = false; }

    // SubScriptions
    this.onUiControlCacheUpdateEventSubscription = this.eventService.onUiControlCacheUpdateEvent.subscribe(event => {
      if (this.controlDefinition.logicalControlId == event.logicalControlId) {
        const control = JSON.parse(JSON.stringify(this.applicationInfoService.controls.Item(event.logicalControlId)));
        this.controlDefinition = control;
      }      
    });

    this.updateControlAttributesEventSubscription = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.checkVisibility();
    });

    this.updateControlDefinitionSubscription = this.eventService.updateControlDefinition.subscribe(event => {
      if (event.logicalControlId === this.controlDefinition.logicalControlId) {
        this.controlDefinition = this.commonService.createCopyOfArray(event);
      }
    });

    this.raiseShowRequiredFieldEventSubscription = this.eventService.raiseShowRequiredFieldEvent.subscribe(event => {
      if ((Number(event.target) === this.controlDefinition.uiEntityInstanceId) && (event.arguments[0] === this.controlDefinition.value)) {
        this.isRequired = true;
      }
    });

    this.raiseResetRequiredFieldEventSubscription = this.eventService.raiseResetRequiredFieldEvent.subscribe(event => {
      if (Number(event.target) === this.controlDefinition.uiEntityInstanceId) {
        this.isRequired = false;
      }
    });

    if (this.controlDefinition.isFieldActivatable) {
      this.toggleActivatedForQueries();
    }

    this.changeControlStyleSubscription = this.eventService.changeControlStyleEvent.subscribe(event => {
      if (event.target === this.controlDefinition.uiEntityInstanceId) {
        if (event.arguments[0] === '') {
            switch (event.additionalInfo[0].toLowerCase()) {
              case 'visible':
              this.isVisible = JSON.parse(event.additionalInfo[1]);
              break;
              case 'editable':
              this.isEditable = JSON.parse(event.additionalInfo[1]);
              break;
          }
        }
      }
    });

    this.updateEntityEventSubscription = this.eventService.updateEntityEvent.subscribe(event => {
      // console.warn('updateEntityEventSubscription', this.controlDefinition.id);
      if (Number(event.target) === this.controlDefinition.uiEntityInstanceId) {
            this.controlValue = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId)
              .data[this.controlDefinition.value];
    }});

    // Entity
    if (this.controlDefinition.uiEntityInstanceId) {
      this.entity = this.entityService.getEntities(this.controlDefinition.uiEntityInstanceId);
      if (!isNullOrUndefined(this.entity)) {
        if (this.entity.data) {
          if (!this.entity.data[this.controlDefinition.value]) {
            this.entity.data[this.controlDefinition.value] = null;
          }
        }
      }
      this.controlValue = this.entityService.getEntityValue(this.controlDefinition.uiEntityId, this.controlDefinition.value, 1);
      // this.entity.data[this.controlDefinition.value];
    }

    if ((this.controlDefinition.labelPos === LabelPosition.NoLabel) && (this.controlDefinition.reserverSpaceLeft === '0')) {
      this.innerControlOnly = true;
    }
    this.checkVisibility();
    //  this.loaderService.display(false);
  }

  

  getMainClass() {
    return 'customUniversalElement controlPadding globalFontSize '.concat(this.controlDefinition.additionalCssClassesMainControl);
  }

  toggleActivatedForQueries() {
    this.isControlActivatedForQuerys = !this.isControlActivatedForQuerys;
    if (this.controlDefinition.uiEntityInstanceId) {
      let uiDisabledEntityMember = this.entityService.getEntityValue(this.controlDefinition.uiEntityInstanceId,
      this.applicationInfoService.disabledEntityMemberIdentifier, 1);
      if (!uiDisabledEntityMember) {
        uiDisabledEntityMember = [];
      }
      const uiDisabledEntityMemberResult = this.commonService.toggleArrayItem(uiDisabledEntityMember, this.controlDefinition.value);
      this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId)
        .data[this.applicationInfoService.disabledEntityMemberIdentifier] = uiDisabledEntityMemberResult.resultArray;
    }
  }

  getDisplayText() {
    return this.uiService.getDisplayText(this.controlDefinition);
  }

  getSubControlWidth() {
    let finalWidth: any = '100%';
    if (!this.controlDefinition) { return finalWidth; }
    const element = document.getElementById('MainControlDiv-' + this.controlGUID + this.controlDefinition.id);
    if (element) {
      finalWidth = element.offsetWidth;
    } else {
      Number(this.uiService.getControlDimensionWithoutAttribute(this.controlDefinition.width));
    }
    if (this.controlDefinition.labelPos === 1 || this.controlDefinition.labelPos === 3) {
      finalWidth = finalWidth - Number(this.uiService.getControlDimensionWithoutAttribute(this.controlDefinition.displayTextWidth)) - 1 ;
    }
    finalWidth = finalWidth - Number(this.uiService.getControlDimensionWithoutAttribute(this.controlDefinition.reserverSpaceLeft));
    if (this.controlDefinition.isFieldActivatable) {
      finalWidth = finalWidth - 20;
    }
    return { 'width': finalWidth + 'px' };
  }

  getControlSpanId() {
    return 'cc'.concat(this.controlDefinition.logicalControlId.toString());
  }

  getControlId(): string {
    return 'MainControlDiv-' + this.controlGUID + this.controlDefinition.id;
  }

  getControlSubId() {
    return 'cid' + this.controlDefinition.logicalControlId + 'ctype' + this.controlDefinition.controlType;
  }

  infoAboutElement() {
    console.log('Info about control ' + this.controlid, this.controlDefinition);
    console.log('Controlvalue ' + this.controlValue);
  }

  // Style
  setControlStyle(styleType: string, modifier: number = 0) {
    return(this.uiService.setControlStyle(styleType, modifier, this.controlDefinition));
  }

  getUniversalLabelClass() {
    let returnValue = '';
    if (!isNullOrUndefined(this.controlDefinition.additionalCssClassesMainLabel)) {
      returnValue = returnValue.concat(this.controlDefinition.additionalCssClassesMainLabel);
    }
    return returnValue;
  }

  checkVisibility() {
    this.isVisible = this.controlService.checkIfControlIsVisible(this.controlDefinition);
    return this.isVisible;
  }

  setMainControlStyle(newDesign = false) {
    if (!this.controlDefinition) { console.error('setMainControlStyle', this.controlid); return; }
    let height = this.controlDefinition.height;
    let width = this.controlDefinition.width;
    if (this.controlDefinition?.isFieldActivatable && this.applicationInfoService.designerMode != true) {
      height = 'auto';
    }
    if (this.applicationInfoService.designerMode && this.ignoreDesigner == false) {
      height = '100%';
    }
    if (this.applicationInfoService.isMobile()) {
      width = '100%';
      height = '100%';
    }

    // Buttons create scrollbars if height is set
    if (this.controlDefinition.controlType === ControlType.Button) { return; }

    if (this.controlDefinition.height === '0') { return; }
    if (newDesign && (
        this.controlDefinition.controlType === ControlType.SpacerItem ||
        this.controlDefinition.controlType === ControlType.CheckBox ||
        this.controlDefinition.controlType === ControlType.Button ||
        this.controlDefinition.controlType === ControlType.Textbox ||
        this.controlDefinition.controlType === ControlType.Textarea ||
        this.controlDefinition.controlType === ControlType.Dial ||
        this.controlDefinition.controlType === ControlType.Research ||
        this.controlDefinition.controlType === ControlType.Website ||
        this.controlDefinition.controlType === ControlType.Calendar ||
        this.controlDefinition.controlType === ControlType.Dropdown ||
        this.controlDefinition.controlType === ControlType.Radio ||
        this.controlDefinition.controlType === ControlType.Accordeon ||
        this.controlDefinition.controlType === ControlType.Label
      )) {
      return { 'width' : width };
    }
    return {
      'height': height,
      'width': width
    };
  }

  checkIfControlIsModified() {
    if (JSON.stringify(this.controlDefinition) ===
      JSON.stringify(this.applicationInfoService.controlsBackUp.Item(this.controlDefinition.logicalControlId))) {
        return false;
      } else {
        return true;
      }
  }

  undoChanges() {
    this.controlDefinition = this.commonService.createCopyOfArray(
      (this.applicationInfoService.controlsBackUp.Item(this.controlDefinition.logicalControlId)));
    this.eventService.updateControlDefinition.emit(
       this.applicationInfoService.controlsBackUp.Item(this.controlDefinition.logicalControlId));
  }

  getLeftPadding() {
    return 0;
    let returnValue = this.controlDefinition.reserverSpaceLeft;
    if (this.applicationInfoService.isMobile()) {
      return '0';
    }
    return returnValue;
  }

  getTitle() {
    if (!this.commonService.isNullOrUndefined(this.controlDefinition.tooltip) && this.controlDefinition.tooltip != '') {
      return this.axivasTranslateService.getTranslationTextForToken(this.controlDefinition.tooltip);
    } else {
      return '';
    }
  }

  editControlLight() {
    this.applicationInfoService.miscSettings['controlEditLightControl'] = this.controlDefinition;
    this.applicationInfoService.miscSettings['controlEditLightControlMode'] = 1;
    this.eventService.showJjPopup('UIControlSettings.Label.Designer', 'controleditlight', '1400px;800px');
  }



  checkControlDefinition(controlDefinition, controlType){
    
    // todo
    
    switch(controlDefinition){
      case controlDefinition?.controlType == controlType?.EntityTableInternal:
        return true
        default: 
        return false
    }
  }
}

