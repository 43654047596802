import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-navigationitem',
  templateUrl: './navigationitem.component.html',
  styleUrls: ['./navigationitem.component.scss']
})
export class NavigationitemComponent implements OnInit {
  @Input() menuItem = null;
  @Input() parent = null;
  @Input() parents = [];
  @Input() navigationExpanded = false;
  @Input() isSublevel = false;

  constructor(
    private methodService: MethodService,
    public applicationInfoService: ApplicationInfoService,
    private settingService: SettingsService,
    private eventService: EventService,
    private commonService: CommonService,
    private axivasTranslateService: AxivasTranslateService,
    public packagefeatureService: PackagefeatureService
  ) { }

  ngOnInit(): void {
    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'toggleAllNavigations') {
        this.toggleJsonSubmenu(this.menuItem, true);
      }
    });
  }

  jsonItemVisible(menuItem) {
    let returnValue = true;
    // Expanded?
    return returnValue;
  }

  jsonMenuItemClicked(menuItem) {
    this.applicationInfoService.resetHeaderCardsPosition();
    
    if (this.packagefeatureService.navigationItemHasNonActiveFeature(menuItem)) {
      this.packagefeatureService.showFeatureInfoPage(this.packagefeatureService.navigationItemFeatureId(menuItem));
      return;
    }

    //console.log('menuItem', menuItem);
    
    if (!menuItem.isChild && menuItem.children.length > 0) {
      this.eventService.customEvent.emit({ id: 'getSubItemsOfHeader', value: menuItem });
      return;
    }
    //console.log('navigation Item', menuItem)
    switch (menuItem.navigagionSpecial) {
      case 1:

        break;
    }

    // CustomEventSupport
    if (menuItem.customEventId != '' && !this.commonService.isNullOrUndefined(menuItem.customEventId)) {
      this.eventService.customEvent.emit({ id: menuItem.customEventId });
    }
    if (menuItem.target != '' && !this.commonService.isNullOrUndefined(menuItem.target)) {
      this.eventService.customEvent.emit({ id: 'navigationMenuItemClicked', target: menuItem.target });
    }
    

    if (this.commonService.isNullOrUndefined(menuItem.adminSection) || menuItem.adminSection == '') {
      this.applicationInfoService.projectSettingPage = null;
    } else {
      this.applicationInfoService.projectSettingPage = Number(menuItem.adminSection);
    }
    switch(menuItem.clickType) {
      case 'navigateToSection':
        if (!this.commonService.isNullOrUndefined(menuItem.navigagionSpecial)) {
        }
        this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', menuItem.target]);
        break;
      case 'showPopup':
        this.eventService.showJjPopup(menuItem.popupHeader, menuItem.target, menuItem.popupDimension);
        break;
      case 'showContainer':
        //console.log('menuItem')
        this.methodService.launchInternalMethod(InternalMethodType.ChangePageContentToContainer, [menuItem.target, menuItem.target]);
        break;
      case 'loadNavigation':
        this.eventService.customEvent.emit({ 
          id: 'loadJsonNavigation',
          value: menuItem.target
        });
        break;
    }
    if (this.applicationInfoService.isMobile() && menuItem.clickType != 'loadNavigation') {
      this.applicationInfoService.navigationExpandedMobile = false;
    }
    if (menuItem.isExtendable) {
      this.toggleJsonSubmenu(menuItem);
    }
    this.eventService.customEvent.emit({ id: 'closeQuickMenu' });
  }
  
  getSelectedJsonItem(child, menuItem) {

  }

  showJsonChild(child) {
    let returnValue = true;
    return returnValue;
  }

  getJsonImage(menuItem) {
    return 'assets/'.concat(menuItem.icon);
  }

  toggleJsonSubmenu(menuItem, forceExpand = false) {
    if (!menuItem.isExtendable) {
      return;
    }
    if (forceExpand) {
      this.applicationInfoService.userSettings['extendedNavigation' + menuItem.guid] = true;
      return;
    }
    if (this.applicationInfoService.userSettings['extendedNavigation' + menuItem.guid] == true) {
      this.applicationInfoService.userSettings['extendedNavigation' + menuItem.guid] = false;
    } else {
      this.applicationInfoService.userSettings['extendedNavigation' + menuItem.guid] = true;
    }
    this.settingService.updateSecurityUserSettings();
  }

  getMenuItemText(menuItem) {
    let returnValue = menuItem.defaultName;
    if (returnValue == 'CURRENT_RECORDSET_HEADER') {
      if (this.applicationInfoService.currentContact) {
        return 'CurrentRecordset.Contact.Header';
      } else {
        return 'CurrentRecordset.Account.Header';
      }
    }
    const languageText = menuItem['translation'.concat(this.axivasTranslateService.currentLanguage)];
    if (!this.commonService.isNullOrUndefined(languageText) && languageText != '') {
      returnValue = languageText;
    }
    return returnValue;
  }

  isItemSelected() {
    let returnValue = false;
    let refValue = '';
    if (this.menuItem.target !== null && this.menuItem.target !== '') {      
      refValue = this.menuItem.target;
      let currentLocation = window.location.href.toString();
      const currentLocationDetails = currentLocation.split('?');
      currentLocation = currentLocationDetails[0];
      if (this.commonService.checkIfStringContainsString(window.location.href.toString(), refValue.concat('?')) ||
        currentLocation.endsWith(refValue) ||
        localStorage.getItem('containerViewId') == refValue
      ) {        
        // const parent = this.parents.find(parent => parent.guid == this.parent);
        // if (parent) {
        //   this.applicationInfoService.breadCrumbTextSection = this.axivasTranslateService.getTranslationTextForToken(parent.defaultName);
        // }        
        // this.applicationInfoService.breadCrumbText = this.axivasTranslateService.getTranslationTextForToken(this.menuItem.defaultName);
        returnValue = true;        
      }
    }
    return returnValue;
  }
}
