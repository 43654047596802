<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <div>{{ 'Feature.Label.FeatureNotAvailable' | texttransform }}</div>
    <div>
        <app-main-button [label]="'Feature.Label.ToFeatureInfo' | texttransform" (onClick)="packagefeatureService.showFeatureInfoPopup(featureId)"></app-main-button>
    </div>
</div>

<app-layout [header]="'General.AccountFooter.LeadScoringDetail' | texttransform" *ngIf="featureNotFound == false">
    <div class="customLeadScoringDetail__inner">
        <div class="customLeadScoringDetail__tabsMobile">
            <div class="everleadMobileTab">
                <div class="everleadMobileTab__valueWrapper">
                    <select [(ngModel)]="tab" class="selectTab__box">
                        <option [ngValue]="1">{{'LeadScoringDetail.Label.Score' | texttransform }}</option>
                        <option [ngValue]="2">{{'LeadScoringDetail.Label.UserActivityTrackingScore' | texttransform }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="customLeadScoringDetail__tabs">
            <app-scroll-tabs>
                <app-tab label="LeadScoringDetail.Label.Score" svg="chart-bar" [active]="tab === 1" (onClick)="tab=1"></app-tab>
                <app-tab label="LeadScoringDetail.Label.UserActivityTrackingScore" svg="chart-line" [active]="tab === 2" (onClick)="tab=2"></app-tab>
            </app-scroll-tabs>
    
        </div>


        <div class="customLeadScoringDetail__content">
            <div *ngIf="chartReady == false" class="globalFontSize" [ngStyle]="{color: '#5465FF'}">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{
                'General.Label.LoadingData' | texttransform }}
            </div>
            
            <div  *ngIf="chartReady == false && !showSummary" class="globalFontSize" [ngStyle]="{color: '#5465FF'}">
                {{ 'LeadScoringDetail.Label.NoDataAvailable' | texttransform }}
            </div>
            
            <div  *ngIf="applicationInfoService.currentContact == null" class="globalFontSize" [ngStyle]="{color: '#5465FF'}">
                {{ 'LeadScoringDetail.Label.NoContactAvailable' | texttransform }}
            </div>

            <div *ngIf="applicationInfoService.currentContact != null && chartReady == true">
                
                <div *ngIf="tab === 1">
                    <div class="customLeadScoringDetail__chartsWrapper">
                        <div class="row containerPadding">
                            <div class="leadScoreHeaderLine">
                                <div class="header-scoring-container">
                                    <div class="scoreCaption">
                                        <div class="value">{{ getContactFinalValue() }}</div>
                                        <div lass="text">Lead-Index</div>
                                    </div>
                                    <span class="math-operator">x</span>
                                    <div class="scoreCaptionFill scoreCaption" (click)="showPotentialPopup()">
                                        <div class="value"> {{ getAccountPotentialValue() }}% </div>
                                        <div class="text">{{ selectedAccountPotentialValue?.displayName | texttransform }}
                                            <app-jj-icons type="arrow-square-out" [width]="20" [height]="20" color="primaryLight"></app-jj-icons> 
                                            <!-- <i class="fas fa-edit fa-xs editIcon"></i> -->
                                        </div>
                                    </div>
                                    <div class="">
                                        <span class="math-operator">=</span>
                                    </div>
                                    <div class="finalScore">
                                        <app-bar-leadscore label="Lead-Score" [value]="getContactFinalCalculatedValue(true)"></app-bar-leadscore>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="row containerPadding" *ngIf="chartReady == true" [style.marginTop.px]="16">
                            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
                                <app-lead-scoringchart [chartData]="leadScoreMainchart" [height]="300" [showYaxis]="true" [showXaxis]="true"
                                    [barGap]="1" [barWidth]="45" [barMaxWidth]="45"></app-lead-scoringchart>
                            </div> 
                            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
                                <div class="calculationContainer">
                                    <div class="calculationContainer__inner">
                                        <div class="calculationContainer__wrapper">
                                            <div class="calculationContainer__wrapper__header">{{'LeadScoringDetail.Label.Content'|
                                                texttransform }}</div>
                                            <div class="diagrams__value">{{this.contactData.contentPoints}} <span class="operator">x</span>
                                                {{getPercentage('content_score_weight')}} <span class="operator">=</span> <span
                                                    class="result_content">
                                                    {{this.getCalculatedValue("content_score_weight")}}</span></div>
                                        </div>
                                        <div class="containerPlus"></div>
                                        <div class="calculationContainer__wrapper">
                                            <div class="calculationContainer__wrapper__header">{{'LeadScoringDetail.Label.Activity'|
                                                texttransform }}</div>
                                            <div class="diagrams__value">{{this.contactData.activityPoints}} <span class="operator">x</span>
                                                {{getPercentage('activity_score_weight')}} <span class="operator">=</span> <span
                                                    class="result_activity">
                                                    {{this.getCalculatedValue("activity_score_weight")}}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
                <div *ngIf="tab === 2">
                    <div class="row" *ngIf="chartReady == true">
                        <div class="col-12">
                           <!--  <label class="lineChartTitle">
                                {{'LeadScoringDetail.Label.UserActivityTrackingScore' | texttransform}}
                            </label> -->
                            <app-lead-scoring-line-chart [height]="400" [chartData]="chartData" [distinctValueList]="distinctValueList"
                                    [distinctValueIdList]="distinctValueIdList"></app-lead-scoring-line-chart>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</app-layout>
