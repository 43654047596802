<div class="featuressettings-slider">
  <div class="featuressettings-slider__nav">
    <h5>Slides</h5>
    <app-main-button (onClick)="isAddingNew = true; selectedLanguage = null" label="Add slide" svg="plus"
    type="small" [disabled]="isAddingNew"></app-main-button>
 <!--    @if (!isAddingNew) {
    <app-main-button (onClick)="isAddingNew = true; selectedLanguage = null" label="Add slide" svg="plus"
      type="small"></app-main-button>
    } -->
  </div>

  <div class="featuressettings-slider__inner">
    <div cdkDropList [cdkDropListData]="slider" class="example-list" (cdkDropListDropped)="drop($event)">
      @for (item of slider; track $index) {

      @if (isEditing && editedItem && editedItem?.id === item?.id) {

      <div class="featuressettings-slider__item">
        <div class="featuressettings-slider__image fingerCursor">
          <app-jj-icons type="hand-grabbing" [pointer]="false" color="gray0" [width]="25" [height]="25"></app-jj-icons>
          <input type="file" #file accept="image/*" (change)="uploadImage($event)" hidden>
          @if (editedItem?.base64Img) {
          <img src="{{ editedItem.base64Img }}" (click)="file.click()" />
          <app-jj-icons type="x" [width]="5" [height]="5" (click)="editedItem.base64Img = ''"></app-jj-icons>
         <!--  <i class="fas fa-close fingerCursor" (click)="editedItem.base64Img = ''"></i> -->
          } @else {
          <div
            [ngClass]="editImageRequiredMessage ? 'featuressettings-slider__imageRequired' : 'featuressettings-slider__no-image'"
            (click)="file.click()">
            Upload Image
            <span class="featuressettings-slider__requiredMessage"
              *ngIf="editImageRequiredMessage">{{'LanguageDesign.Label.ImageIsRequired' | texttransform }}</span>
          </div>
          }
        </div>

        <div class="featuressettings-slider__textwrapper">
          <div class="featuressettings-slider__selectors">

            <app-select-input 
            label="LanguageDesign.Label.SelectProject"
            [(ngModel)]="editedItem.projectId"
            placeholder="LanguageDesign.Label.Global"
            bind="projectId"
            display="projectName"
            [options]="selectProjects"
            ></app-select-input>
          
            <app-select-input [(ngModel)]="editedLanguage" label="LanguageDesign.Label.SelectLanguage"
            [options]="[{value:'text_de',label:'LanguageDesign.Label.German' },{value:'text_en', label:'LanguageDesign.Label.English'}]"
            bind="value" display="label"></app-select-input>
          </div>
          <div class="featuressettings-slider__texts">
            <div *ngIf="editedLanguage === 'text_de'">
              <app-text-area-input [(ngModel)]="editedItem.settings.text_de" label="LanguageDesign.Label.German"
              height="200"></app-text-area-input>
            </div>
            <div  *ngIf="editedLanguage === 'text_en'">
              <app-text-area-input [(ngModel)]="editedItem.settings.text_en" label="LanguageDesign.Label.English"
              [height]="200"></app-text-area-input>

            </div>
          </div>
        </div>

        <div class="featuressettings-slider__icons">
          <app-jj-icons type="floppy-disk" [width]="20" [height]="20" (click)="updateItem()"></app-jj-icons>
          <app-jj-icons type="x" [width]="20" [height]="20" (click)="isEditing = false; editImageRequiredMessage=false; editedItem = null"></app-jj-icons>
        </div>
      </div>

      } @else {

      <div class="featuressettings-slider__item" cdkDrag [cdkDragData]="item">
        <app-jj-icons type="hand-grabbing" [width]="25" [height]="25"></app-jj-icons>
        <div class="featuressettings-slider__image">
          @if (item?.base64Img) {
          <img src="{{ item.base64Img }}" />
          } @else {
          <div class="featuressettings-slider__no-image">No Image</div>
          }
        </div>

        <div class="featuressettings-slider__features">

          <div class="featuressettings-slider__features__container" *ngIf="item.projectId">
            <label>Location: {{ 'LanguageDesign.Label.SelectProject' | texttransform }}</label>
            <div *ngFor="let project of selectProjects">
              <span *ngIf="project.projectId == item.projectId">{{project?.project?.projectName}}</span>
            </div>
          </div>

          <div class="featuressettings-slider__features__container">
              <label>Description</label>
              <span>DE : <app-jj-icons [type]="item?.settings?.text_de ? 'check-square' : 'x'" [color]="item?.settings?.text_de ? 'secondary' : 'red'" [width]="20" [height]="20"></app-jj-icons></span>
              <span>EN : <app-jj-icons [type]="item?.settings?.text_en ? 'check-square' : 'x'" [color]="item?.settings?.text_en ? 'secondary' : 'red'" [width]="20" [height]="20"></app-jj-icons></span>
          </div>

         <!--  <div class="featuressettings-slider__text-item" *ngIf="item.settings.text_de">
            <label>{{ 'LanguageDesign.Label.German' | texttransform }}</label>
            <span [innerHTML]="item.settings.text_de"></span>
          </div>
          <div class="featuressettings-slider__text-item" *ngIf="item.settings.text_en">
            <label>{{ 'LanguageDesign.Label.English' | texttransform }}</label>
            <span [innerHTML]="item.settings.text_en"></span>
          </div> -->
        </div>
        <div class="featuressettings-slider__icons">
          <app-jj-icons type="editor" [width]="20" [height]="20" (click)="isEditing = true; editImageRequiredMessage=false ;editedItem = getItemDeepCopy(item)" [width]="20" [height]="20"></app-jj-icons>
          <app-jj-icons type="eye" [width]="20" [height]="20" (click)="packagefeatureService.showFeatureInfoPopup(featureId, item.id)"></app-jj-icons>
          <app-jj-icons type="trash" [width]="20" [height]="20" (click)="deleteItem(item.id)"></app-jj-icons>
        </div>
      </div>
      }

      }
    </div>

    @if (isAddingNew) {

    <div class="featuressettings-slider__item">
      <app-jj-icons type="hand-grabbing" [pointer]="false" color="gray0" [width]="25" [height]="25"></app-jj-icons>
      <div class="featuressettings-slider__image fingerCursor">
        <input type="file" #file accept="image/*" (change)="uploadImage($event)" hidden>
        @if (!newItem?.base64Img) {
        <div
          [ngClass]="createImageRequiredMessage ? 'featuressettings-slider__imageRequired' : 'featuressettings-slider__no-image'"
          (click)="file.click()">
          Upload Image
          <span class="featuressettings-slider__requiredMessage"
            *ngIf="createImageRequiredMessage">{{'LanguageDesign.Label.ImageIsRequired' | texttransform }}</span>
        </div>
        } @else {
        <img src="{{ newItem.base64Img }}" (click)="file.click()" />
        <app-jj-icons type="x" (click)="newItem.base64Img = ''"[width]="5" [height]="5"></app-jj-icons>
        }
      </div>

      <div class="featuressettings-slider__textwrapper">
        <div class="featuressettings-slider__selectors">
          <app-select-input label="LanguageDesign.Label.SelectProject" [(ngModel)]="newItem.projectId"
            [options]="selectProjects" bind="id" placeholder="LanguageDesign.Label.Global"
            display="projectName"></app-select-input>

          <app-select-input [(ngModel)]="selectedLanguage" label="LanguageDesign.Label.SelectLanguage"
            [options]="[{value:'text_de',label:'LanguageDesign.Label.German' },{value:'text_en', label:'LanguageDesign.Label.English'}]"
            bind="value" display="label"></app-select-input>
        </div>

        <div class="featuressettings-slider__texts">
          <div *ngIf="selectedLanguage === 'text_de'">
            <app-text-area-input [(ngModel)]="newItem.settings.text_de" label="LanguageDesign.Label.German"
              height="200"></app-text-area-input>
          </div>
          <div *ngIf="selectedLanguage === 'text_en'">
            <app-text-area-input [(ngModel)]="newItem.settings.text_en" label="LanguageDesign.Label.English"
              [height]="200"></app-text-area-input>
          </div>
        </div>
      </div>
      <div class="featuressettings-slider__icons">
        <app-jj-icons type="floppy-disk" (click)="saveNewItem()" [width]="20" [height]="20"></app-jj-icons>
        <app-jj-icons type="x" [width]="20" [height]="20" (click)="isAddingNew = false; createImageRequiredMessage=false; newItem = getItemDeepCopy(emptyItem)"></app-jj-icons>
      </div>
    </div>

    }
  </div>
</div>
