import { Component, OnInit } from '@angular/core';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-navigation-shortcuts',
  templateUrl: './navigation-shortcuts.component.html',
  styleUrl: './navigation-shortcuts.component.scss'
})

export class NavigationShortcutsComponent implements OnInit {
  availableOptions = [];
  mode = 1;
  jsonArray = null;
  position = null;
  settings = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private settingService: SettingsService,
    private commonService: CommonService,
    private userService: UserService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    public packagefeatureService: PackagefeatureService
  ) {

  }

  ngOnInit(): void {
    this.mode = this.applicationInfoService.miscSettings['navigationSettings'].mode;
    this.jsonArray = this.applicationInfoService.miscSettings['navigationSettings'].jsonArray;
    this.position = this.applicationInfoService.miscSettings['navigationSettings'].position;
    this.settings = this.applicationInfoService.miscSettings['navigationSettings'].settings;
    if (this.mode == 1) {
      this.consumeFields(this.jsonArray);
      this.consumeFields(this.settings);
    }   
    this.updatePopup(); 
    this.eventService.customEvent.emit({ id: 'changePopupSubHeader', value: 'Navigation.Label.ShortCutSubheader' });
  }

  consumeFields(array) {
    array.fields.forEach(field => {
      if (field.isChild == false && this.showJsonMenuItem(field)) {
        this.availableOptions.push(field);
        field.validChildren = [];
        field.children.forEach(child => {
          if (this.packagefeatureService.navigationItemHasNonActiveFeature(child)) {
            // not valid
          } else {
            if (this.showJsonMenuItem(child)) {
              field.validChildren.push(child);
            }            
          }
        });
      }      
    });    
  }  

  setChild(child) {
    // remove    
    let foundposition = null;
    let position = 0;
    this.applicationInfoService.projectShortCuts.forEach(shorty => {
      if (shorty != null) {        
        if (shorty.guid == child.guid) {
          foundposition = position;
        }  
      }
      position ++;
    });
    if (foundposition != null) {
      this.applicationInfoService.projectShortCuts[foundposition] = null;
      this.updatePopup();
      this.saveUserShortCuts();
      return;
    }
    
    // add 
    position = 0;
    let freeposition = null;
    this.applicationInfoService.projectShortCuts.forEach(shorty => {
      if (shorty == null) {
        if (freeposition == null) {
          freeposition = position;
        }
        shorty = JSON.parse(JSON.stringify(child));
        return;
      }
      position ++;
    });
    if (freeposition != null) {
      this.applicationInfoService.projectShortCuts[freeposition] = child;
      this.updatePopup();
      this.saveUserShortCuts();
    }
  }

  updatePopup() {
    let headerText = this.axivasTranslateService.getTranslationTextForToken('Navigation.Label.ShortCutHeader');
    headerText = headerText.replace ('<0>', this.applicationInfoService.projectShortCuts.filter(item => item != null).length.toString());
    headerText = headerText.replace ('<1>', this.applicationInfoService.projectShortCuts.length.toString());
    console.warn()
    this.eventService.customEvent.emit({ id: 'changePopupHeader', value: headerText });
  }

  childIsSelected(child) {
    let returnValue = false;
    this.applicationInfoService.projectShortCuts.forEach(shorty => {
      if (shorty != null) {
        if (shorty.guid == child.guid) {
          returnValue = true;
        }
      }
    });
    return returnValue;
  }

  saveUserShortCuts() {
    const shortCutString = JSON.stringify(this.applicationInfoService.projectShortCuts);
    this.applicationInfoService.userSettings['navigationShortCuts'.concat(this.applicationInfoService.projectID.toString())] = shortCutString;
    this.settingService.updateSecurityUserSettingsJson();
  }

  showJsonMenuItem(menuItem) {
    let returnValue = true;
    // Blocked
    if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedUserIds, this.applicationInfoService.userID) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedPoolIds, this.applicationInfoService.poolId) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedProjectIds, this.applicationInfoService.projectID) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedCampaignIds, this.applicationInfoService.campaingnModeId) == false) { returnValue = false; }

    // Required
    if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredProjectIds, this.applicationInfoService.projectID) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredPoolIds, this.applicationInfoService.poolId) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredCampaignIds, this.applicationInfoService.campaingnModeId) == false) { returnValue = false; }

    // Rights
    if (!this.commonService.isNullOrUndefined(menuItem.requiredRights) && menuItem.requiredRights != '') {
      const details = menuItem.requiredRights.split(';');
      details.forEach(detail => {
        if (!this.userService.hasPermission(detail)) {       
          returnValue = false;
        }
      });
    }
    return returnValue;
  }
}
