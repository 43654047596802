<app-layout [header]="user.userName" *ngIf="user">
    <div class="userDetailsWrapper">

        <div class="userDetailsToolbarMobile">
            <div class="everleadMobileTab">
                <div class="everleadMobileTab__valueWrapper">
                    <select [(ngModel)]="tab" class="selectTab__box">
                        <option [ngValue]="0">{{'Menu.General.Common' | texttransform }}</option>
                        <option [ngValue]="1">{{'Supervisor.Label.UserRoles' | texttransform }}</option>
                        <option [ngValue]="2">{{'Supervisor.Label.AutomaticRecordAssignment' | texttransform }}</option>
                        <option [ngValue]="3">{{'UserManagement.Label.AdditionalFunctions' | texttransform }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="userDetailsToolbar">
            <app-scroll-tabs>
                <app-tab svg="calendar-dots" [active]="tab == 0" label="Menu.General.Common" (onClick)="tab = 0"></app-tab>
                <app-tab svg="calendar-dots" [active]="tab == 1" label="Supervisor.Label.UserRoles" (onClick)="tab = 1"></app-tab>
                <app-tab svg="calendar-dots" [active]="tab == 2" label="Supervisor.Label.AutomaticRecordAssignment" (onClick)="tab = 2"></app-tab>
                <app-tab svg="calendar-dots" [active]="tab == 3" label="UserManagement.Label.AdditionalFunctions" (onClick)="tab = 3"></app-tab>   
            </app-scroll-tabs>        
        </div>
        <div class="userDetailsContent">
            <div *ngIf="tab == 0">
                <app-text-input label="UserManagement.Label.UserName" [(ngModel)]="user.userName"></app-text-input>
                <app-text-input label="UserManagement.Label.FirstName" [(ngModel)]="user.firstName"></app-text-input>
                <app-text-input label="UserManagement.Label.LastName" [(ngModel)]="user.lastName"></app-text-input>
                <app-text-input label="UserManagement.Label.EMail" [(ngModel)]="user.email"></app-text-input>
                <app-date-input label="UserManagement.Label.TrialEndsAt" [(ngModel)]="user.trialEndsAt"></app-date-input>	
                <app-checkbox label="UserManagement.Label.IsExternalUser" [(ngModel)]="user.isExternalUser"></app-checkbox>
                <app-checkbox label="UserManagement.Label.IsSentWelcomeMail" [(ngModel)]="user.isSentWelcomeEmail"></app-checkbox>
            </div>

            <div *ngIf="tab == 3" class="toolbar3">
                <div class="toolbar3Item">
                    <app-main-button type="small" svg="envelope" (click)="activateUser()" label="ActivateUser.Message.Header"
                    [disabled]="!user.isExternalUser"></app-main-button>
                    <div class="toolbar3Item__text">{{ 'UserManagement.Label.SendActivationMailDescription' | texttransform }}</div>
                </div>
                <div class="toolbar3Item">
                    <app-main-button type="small" svg="envelope" (click)="resetUserPassword()" label="EntityTable.ResetUserPassword.Header"
                    [disabled]="!user.isExternalUser"></app-main-button>
                    <div>{{ 'EntityTable.ResetUserPassword.Header' | texttransform }}</div>
                </div>
            </div>
        </div>
    </div>
</app-layout>
