import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ProcessvisualService } from 'app/jollyjupiter/controls/static/processvisual/processvisual.service';
import { WizardMode, WizardService } from 'app/jollyjupiter/controls/static/wizard/wizard.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-designer-flowchart',
  templateUrl: './designer-flowchart.component.html',
  styleUrl: './designer-flowchart.component.scss'
})
export class DesignerFlowchartComponent implements OnInit, OnChanges{

  refreshBlocker = false;


  constructor(
    public applicationInfoService: ApplicationInfoService,
    public processvisualService: ProcessvisualService,
    private axivasTranslationService: AxivasTranslateService,
    public commonService: CommonService,
    private eventService: EventService,
    private wizardService: WizardService,
    private userService: UserService,
    private packagefeatureService: PackagefeatureService,
    private externaldatasourceService: ExternaldatasourceService

  ) {}

  customEventSubscription: Subscription = new Subscription();
  options: any = {};
  updateOptions: EChartsOption;
  chartInstance;
  openModalConfig;
  editButton = "";
  buttonType;
  modalData;
  featureId=null;
  emailTemplateFeatureIsActive:boolean;
  //campaignName;

  isSuperVisor: boolean = true;
  isEditor: boolean = true;
  starterStepId:number = 0;
  isStarterStep:boolean = false;

  @Input() chartData;
  @Input() controlid: string = "";
  @Input() treeWidth: string = "";
  @Input() treeHeight: string = "";
  @Input() initialTreeDepth: number;
  @Input() height;
  @Input() width;
  @Input() backgroundColor;
  @Input() top;


  // * ToolBox 
  @Input() toolboxInfo : boolean = false;
  @Input() toolboxExpand : boolean = false;

  @Input() dataToolbox;
  showInfo: boolean = true;


  
  loaderService
  workflowStepResultEventTriggers
  findNestedStep

  // ! ANIMATION AND REBUILD CHART AFTER EDIT
  lastClickedNode
  currentDataBeforeEdit
  rootParents

  countCall=0;
  
  inactiveContainer
  showInactiveStatus=false;

  emailTemplates:any;

  previousData:any;



  ngOnInit(): void {
    this.checkFeatures();
    this.getEMailTemplates();

    this.customEventSubscription = this.eventService.customEvent.subscribe(
      event => {
    //console.log('event', event)
        if (this.controlid == "") {
          this.controlid = "flowChart_".concat(this.commonService.createGuid());
        }

        if (event.id == "updateFlowChart" && event.value == this.controlid) {
          this.starterStepId = event?.charData?.starterStepId;
          this.isEditor = event.editor
          //this.isEditor=false
          this.isSuperVisor = this.userService.hasPermission("CanUseProcessVisualExpertMode");

          this.findNestedStep = this.getNestedSteps(event.chartData?.workflowSteps)
          this.chartData =this.recursiveData(event.chartData);
         
          this.createFlowChart();
        }

        if(event.id == 'updateTemplateFlowChart'){
          this.isEditor = event.editor;
          //this.isEditor=false
          this.chartData = this.recursiveData(event.chartData);
          this.createFlowChart();
        }

        if(event.id == 'updateFlowChart-WIZARD'){
          this.starterStepId = event?.charData?.starterStepId;
          this.isEditor = event.editor
          //this.isEditor = false;
          this.isSuperVisor = this.userService.hasPermission("CanUseProcessVisualExpertMode");

          this.findNestedStep = this.getNestedSteps(event.chartData?.workflowSteps)
          this.chartData = this.recursiveData(event.chartData);
          this.updateChart();
          this.getPreviousChartPath();
        }
      }
    );


    if (this.controlid == 'microCampaignStarted') {
      this.isStarterStep = true;
      this.chartData = this.recursiveData(this.chartData);
      this.isEditor = false;
      this.createFlowChart();
    }

    this.createFlowChart();
    this.checkFeatures()

  }


  ngOnChanges(changes: SimpleChanges){
    if(changes['chartData'] && !changes['chartData'].firstChange){
      this.chartData = this.recursiveData(this.chartData)
    }

  }

  onChartInit(e) {
    this.chartInstance = e;
    this.chartInstance.resize();
    //console.log('instance',this.chartInstance)
  }

  getEMailTemplates(templateId = null) {
    if (this.userService.hasPermission('CanReadEmailTemplate')) {
      this.externaldatasourceService.executeExternalDataSource(429, [])
      .then(eMailTemplateResult => {
        this.emailTemplates = eMailTemplateResult;
     
        if (eMailTemplateResult.length > 0) {
          if (templateId != null) {
            const emailTemplate = this.emailTemplates.find(template => template.id == templateId);
          }
        }
      });
    }
  }

  checkFeatures(){
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_emailtemplate']);
    this.emailTemplateFeatureIsActive = this.packagefeatureService.selectedPackageHasFeature(this.featureId);
    //console.log('this.emailtemplateIsActive', this.emailTemplateFeatureIsActive);
    //this.emailTemplateFeatureIsActive = false 
  
  }

  getNestedSteps(data){ 
      //const steps = data
      //console.log('steps', steps)
      const array =[];

      data.map((item, index)=>{
        const stepId = {id:item.id , name:this.getTranslationValue("name", item)};
        //console.log('id', parentId)
        const findSteps = data.map((item, index)=>{
          return this.findStepId(item.results, stepId, item.id)
        })

        if(findSteps.includes(true)){
          array.push({
            id:item.id, 
            defaultName: item.defaultName,
            name: this.getTranslationValue("name", item)
          })
        }
      })

      return array
      
  }

  findStepId(array, stepId, currentRowId){
    //console.log('other id ',currentId)
    let found: any = false;
    array.map((item) =>{
      // console.log('StepId', stepId.id, 'StepName', stepId.name,  'currentRowId', currentRowId, 'nextStepId', item.nextStepId, 'isfound-> ', (item.nextStepId === stepId.id) , 'name',this.getTranslationValue("name", item), 'defaultName', item.defaultName)
      if(item.nextStepId === stepId?.id && item.nextStepId !== currentRowId){
        found = true
      }
      return
    })
    return found
  }

  isAnimation(params){
    if(params.event.target.style.image === "/assets/images/svg/charts/note-pencil.svg"){
      return false
    } else if(params.event.target.style.image === "/assets/images/svg/charts/envelope-open.svg"){
      return false
    } else {
      return true
    }

  }

  onChartClick(params) {

    this.lastClickedNode= undefined;
    this.currentDataBeforeEdit = this.chartInstance.getOption().series[0].data[0];
    const path = params.treeAncestors.filter(item => item.dataIndex !== 0);

    //console.log('params', params)
    const nodePathData = this.selectNodePathstate(this.currentDataBeforeEdit, path);
    this.previousData = nodePathData;

    console.log()
    this.chartInstance.setOption({
      series:[{
        data:[nodePathData],
        animation:this.isAnimation(params),
      }],
    })

    //console.log('asset', params.event.target.style.image)

    if(this.svgAsEditor(params.event.target.style.image)){
      
      this.lastClickedNode = params
      params.event.event.preventDefault();

      this.onModalOpen(params);
      const collapsedNodeData = this.preventCollapse(this.currentDataBeforeEdit, params.data)

      // todo test with both instance or ngxUpadteOptions
      this.chartInstance.setOption({
        series:[{
          data:[collapsedNodeData],
          animation:this.isAnimation(params),
        }]
      })

    }

    if(this.svgAsEmailAsset(params.event.target.style.image)){
      let result = this.applicationInfoService.results.toArray().find((arrayItem) => arrayItem.id == params.data.id);
        this.lastClickedNode = params;
        params.event.event.preventDefault();
        const nodes = this.preventCollapse(this.currentDataBeforeEdit, params.data);
        const template = this.emailTemplates.find((item) => item.id === this.lastClickedNode.data.emailTemplateId);

        if(template){
          this.wizardService.showWizard(WizardMode.EMailTemplate, template, '95vh', '95vh');
        } else {
          return
        }
       
        this.chartInstance.setOption({
          series:[{
            data:[nodes],
            animation:this.isAnimation(params),
          }]
        })

    }


    this.refreshBlocker = true;
    setTimeout(() => {
      this.refreshBlocker = false;
    }, 1000);

  }

  svgAsEditor(svg:string){
    switch(svg){
      case "/assets/NEW_EVERLEAD_ICONS/flowChart/note-pencil-black.svg":
        return true
      case "/assets/NEW_EVERLEAD_ICONS/flowChart/note-pencil-blue.svg":
        return true
        default:
          return false
    }

  }

  svgAsEmailAsset(svg:string){
    switch(svg){
      case "/assets/NEW_EVERLEAD_ICONS/flowChart/at-blue.svg":
        return true
      case "/assets/NEW_EVERLEAD_ICONS/flowChart/at-black.svg":
        return true
        default:
        return false 
    }

  }

  // ! new toggle state that allows to be display only one path on the flowchart
 selectNodePathstate(data, path){
  const treeAncestor = path.find((node) => node.value === data.value);

  if(treeAncestor){

   return {
    ...data, 
    collapsed: false,
    children: data.children ? data.children.map(child => this.selectNodePathstate(child, path)) : undefined
   }
  } else  if(data.value === path[path.lenght - 1]?.value){
    return {...data, collapsed: !data.collapsed, children: data.children.map((child) => this.selectNodePathstate(child, path))}
  }else{
    return {
      ...data, 
      collapsed:true,
      children : data.children ? data.children.map((child) => this.selectNodePathstate(child, path)) : undefined
     }
  }

  }

  preventCollapse(data, currentNode){
/*     console.log('preventCollapse', data, currentNode) */
    if(data.value === currentNode.value){
      // ! commented return makes the node children to collapse
      //return {...data, collapsed: true };
      return {...data, collapsed: data.collapsed === false ? false : true };
    } else if(data.children){
      return{...data, children: data.children.map((child)=> this.preventCollapse(child, currentNode))}
    } else {
      return {...data };
    }
  }  

  updateChart(){
    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }

    this.options = { 
      responsive: true,
      backgroundColor: this.backgroundColor ? this.backgroundColor : 'transparent',
      tooltip: { 
        show: true,
        trigger: "item",
        triggerOn: "mousemove",
        enterable: true,
        confine: true,
        formatter: ({ data }) => {
          return this.tooltipHTMLFormater(data.type, data);
        },
      },
      textStyle:{
        fontSize: 12,
      },
      grid: {
        left: 10,
        containLabel: true,
        bottom: 10,
        top: 10,
        right: 30
      },
      animation:true,
      animationDuration:0,
      animationEasing:'linear',
      series: [
        {
          name:'flowchart',
          value:0,
          id:'flowchart_0',
          type: "tree",
          data: [this.chartData],
          lineStyle:{ color: '#5465FF'},
          width: this.treeWidth ? this.treeWidth : undefined,
          height: this.treeHeight ? this.treeHeight : undefined,
          top: this.top ? this.top : '1%',
          layout: "orthogonal",
          //edgeShape:'polyline',
          orient: "LR",
          initialTreeDepth:0,
          edgeShape:"curve",
          edgeForkPosition:"50%",
          //roam: true,
          //zoom:0,
          zoom:0.8,
          roam:true,
          silent: false,
          animation:true,
          animationDuration:0,
          animationEasing:'linear',
          leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "right",
            },
          },
          emphasis:{
       /*    focus:'relative',
          disable:false, */
         },
         label:{
          formatter:(v)=>{
            //console.log('v',v)
            return this.getRegularLabelFormatter(v)
      /*       if(this.controlid == 'microCampaignStarted'){
              return this.getMicroCampaignStartedLabelFormatter(v)
            }

            if(this.controlid != 'microCampaignStarted'){
              return this.getRegularLabelFormatter(v)
            } */
          },
          rich:{
            ...this.getRichIcons(),
            leftBlueBorder:{
             borderColor:'#788BFF',
             borderWidth:1,
             height:40,
             verticalAlign:'middle',
           },
           leftBlueBorderTwo:{
             borderColor:'#788BFF',
             borderWidth:2,
             height:40,
             verticalAlign:'middle',
           },
           leftBlackBorder:{
             borderColor:'#333333',
             borderWidth:1,
             height:40,
             verticalAlign:'middle',
           },
           textWorkflow:{
             color: '#FFFFFF',
             backgroundColor:'#5465FF',
             fontSize: 12,
             fontWeight:'bold',
             align: 'center',
             height:40,
             padding:[0,5],
             borderRadius: !this.isEditor ? 4 : undefined,
             width:135,
           },
           workflowBg:{
             height:40,
             align:'right',
             backgroundColor:'#5465FF',
             borderRadius:[0, 4, 4, 0],
             width:'50%',
           },
           textStep:{
             color: '#FFFFFF',
             backgroundColor:'#5465FF',
             fontSize: 12,
             align: 'left',
             height:40,
             padding:[0,5],
             width:95,
             fontWeight:'bold'
           },
           textStepSvg:{
             color: '#FFFFFF',
             backgroundColor:'#5465FF',
             fontSize: 12,
             align: 'left',
             height:40,
             padding:[0,5],
             width:78,
             fontWeight:'bold'
           },
           stepsBg:{
             height:40,
             align:'right',
             backgroundColor:'#5465FF',
             borderRadius:[0, 4, 4, 0],
             width:'70%',
           },
           textResult:{
             color: '#333333',
             fontSize: 12,
             align: 'left',
             height:40,
             padding:[0,6],
             fontWeight:'bold',
             width:112,
           },
          
           resultsBg:{
             height:40,
             align:'right',
             backgroundColor:'#DFDFDF',
             borderRadius:[0, 4, 4, 0],
             width:'60%',
             padding:[0,5],
             },
           textChildren:{
               color: '#5162F6',
               backgroundColor:'#BFD7FF',
               fontSize: 12,
               align: 'left',
               height:40,
               fontWeight:'bold',
               padding:[0,5],
               width:115,
             },
           childrenBg:{
               height:40,
               align:'right',
               backgroundColor:'#BFD7FF',
               borderRadius:[0, 4, 4, 0],
               width:'50%',
             },
           textReaction:{
               color: '#333333',
               fontSize: 12,
               align: 'left',
               height:40,
               padding:[0,5],
               fontWeight:'bold',
               width:80,
             },
           reactionBg:{
               height:40,
               align:'right',
               backgroundColor:'#FFFFFF',
               borderRadius:[0, 4, 4, 0],
               //width:'50%'
             },
           notActive:{
               height:40,
               align:'right',
               backgroundColor:'#FFFFFF',
               borderRadius:[0, 4, 4, 0],
               width:'50%'
             },
           textNotActive:{
               color: '#333333',
               backgroundColor:'#FFCBCB',
               fontSize: 12,
               align: 'left',
               height:40,
               borderRadius:[0, 4, 4, 0],
               fontWeight:'bold',
               padding:[0,7],
               width:140,
               //width:110
             },
           text:{
               backgroundColor: 'transparent',
               color:  'black',
               padding:10,
               position: 'inside',
               //distance:10
             },
           hr:{
               padding:10,
               fontSize:12,
               height: 12,
             },
           textNoEditor:{
               width:100,
               fontSize: 12,
               align: 'left',
               fontWeight:'bold',
               padding:[0,5.5]
             },
           textNoEditorNoSvg:{
               width:120,
               fontSize: 12,
               align: 'left',
               fontWeight:'bold',
               padding:[4,12],
             },
           textNoEditorIDTemplate:{
               width:120,
               fontSize: 12,
               align: 'left',
               fontWeight:'bold',
               padding:[13,5.5],
               backgroundColor: '#FFCBCB'
             },
             textNoEditorChildren:{
               width:110,
               fontSize: 12,
               align: 'left',
               fontWeight:'bold',
               padding:[5, 5.5]
             },
             textReactionNoEditor:{
               color: '#333333',
               fontSize: 12,
               align: 'left',
               height:40,
               padding:[0, 13],
               fontWeight:'bold',
               width:80,
             },
             reactionNoEditorBG:{
               height:40,
               align:'right',
               backgroundColor:'#FFFFFF',
               borderRadius:[0, 4, 4, 0],
             },
             textResultNoTemplate:{
               color: '#333333',
               fontSize: 12,
               align: 'left',
               height:40,
               padding:[0,13],
               fontWeight:'bold',
               width:125,
             },
           },
        },
        },
      ],
    };

  }


  checkStatus(data){
    if((data.isActive === true || data.status === true )){
      if(data?.children){
         return { ...data, children: data.children.map((child) => this.checkStatus(child)).filter((child) => child !== null) }
    } else {
      return data
    }
  } else {
    if(data.type === 'bookingDays' || data.type === 'noReaction' || data.type === 'reaction'){
      return { ...data, children: data.children.map((child) => this.checkStatus(child)).filter((child) => child !== null) }
    }
    return null
  }
}

  showInactiveResults() {
    this.showInactiveStatus = !this.showInactiveStatus

    let updateData = this.chartData

    // todo later if requested p.s the result is always one step behind this maybe to prevent others to be opened.
    if(this.previousData){
      updateData = this.matchCollapseData(this.chartData, this.previousData);
    }



     this.updateOptions = {
      ...this.options,
      series: [
        {
          ...this.options.series[0],
          data: [updateData].map((item) => {
            if (this.showInactiveStatus === false) {
              return this.checkStatus(item)
            } else {
              return item
            }
          })
        }
      ]
    } 
/*     this.getPreviousChartPath() */

  }


  createFlowChart() {
    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }

    this.options = {
      responsive: true,
      backgroundColor: this.backgroundColor ? this.backgroundColor : 'transparent',
      tooltip: { 
        show: true,
        trigger: "item",
        triggerOn: "mousemove",
        enterable: true,
        confine: true,
        formatter: ({ data }) => {
          return this.tooltipHTMLFormater(data.type, data);
        },
      },
      textStyle:{
        fontSize: 12,
      },
      grid: {
        left: 0,
        containLabel: true,
        bottom: 10,
        top: 10,
        right: 30
      },
      animation:true,
      animationDuration:0,
      animationEasing:'linear',
      series: [
        {
          name:'flowchart',
          value:0,
          id:'flowchart_0',
          type: "tree",
          data: [this.chartData].map((item) => {
            if(this.showInactiveStatus === false){
              return this.checkStatus(item)
            } else {
              return item
            }
          }),
          lineStyle:{ color: '#5465FF'},
          width: this.treeWidth ? this.treeWidth : undefined,
          height: this.treeHeight ? this.treeHeight : undefined,
          top: this.top ? this.top : '1%',
          layout: "orthogonal",
          //edgeShape:'polyline',
          orient: "LR",
          initialTreeDepth:0,
          nodeGap: 500,      
          levelHeight: 180,
          edgeShape:"curve",
          edgeForkPosition:"50%",
          zoom:0,
          roam:true,
          silent: false,
          animation:true,
          animationDuration:0,
          animationEasing:'linear',
          leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "right",
            },
          },
          label:{
            formatter:(v)=>{
              //console.log('v',v)
              return this.getRegularLabelFormatter(v);
          /*     if(this.controlid == 'microCampaignStarted'){
                console.log('label formatter', this.controlid)
                return this.getMicroCampaignStartedLabelFormatter(v)
              }

              if(this.controlid != 'microCampaignStarted'){
                console.log('label formatter', this.controlid)
                return this.getRegularLabelFormatter(v)
              } */
            },
            rich:{
             ...this.getRichIcons(),
             leftBlueBorder:{
              borderColor:'#788BFF',
              borderWidth:1,
              height:40,
              verticalAlign:'middle',
            },
            leftBlueBorderTwo:{
              borderColor:'#788BFF',
              borderWidth:2,
              height:40,
              verticalAlign:'middle',
            },
            leftBlackBorder:{
              borderColor:'#333333',
              borderWidth:1,
              height:40,
              verticalAlign:'middle',
            },
            textWorkflow:{
              color: '#FFFFFF',
              backgroundColor:'#5465FF',
              fontSize: 12,
              fontWeight:'bold',
              align: 'center',
              height:40,
              padding:[0,5],
              borderRadius: !this.isEditor ? 4 : undefined,
              width:135,
            },
            workflowBg:{
              height:40,
              align:'right',
              backgroundColor:'#5465FF',
              borderRadius:[0, 4, 4, 0],
              width:'50%',
            },
            textStep:{
              color: '#FFFFFF',
              backgroundColor:'#5465FF',
              fontSize: 12,
              align: 'left',
              height:40,
              padding:[0,5],
              width:95,
              fontWeight:'bold'
            },
            textStepSvg:{
              color: '#FFFFFF',
              backgroundColor:'#5465FF',
              fontSize: 12,
              align: 'left',
              height:40,
              padding:[0,5],
              width:78,
              fontWeight:'bold'
            },
            stepsBg:{
              height:40,
              align:'right',
              backgroundColor:'#5465FF',
              borderRadius:[0, 4, 4, 0],
              width:'70%',
            },
            textResult:{
              color: '#333333',
              fontSize: 12,
              align: 'left',
              height:40,
              padding:[0,6],
              fontWeight:'bold',
              width:112,
            },
           
            resultsBg:{
              height:40,
              align:'right',
              backgroundColor:'#DFDFDF',
              borderRadius:[0, 4, 4, 0],
              width:'60%',
              padding:[0,5],
              },
            textChildren:{
                color: '#5162F6',
                backgroundColor:'#BFD7FF',
                fontSize: 12,
                align: 'left',
                height:40,
                fontWeight:'bold',
                padding:[0,5],
                width:115,
              },
            childrenBg:{
                height:40,
                align:'right',
                backgroundColor:'#BFD7FF',
                borderRadius:[0, 4, 4, 0],
                width:'50%',
              },
            textReaction:{
                color: '#333333',
                fontSize: 12,
                align: 'left',
                height:40,
                padding:[0,5],
                fontWeight:'bold',
                width:80,
              },
            reactionBg:{
                height:40,
                align:'right',
                backgroundColor:'#FFFFFF',
                borderRadius:[0, 4, 4, 0],
                //width:'50%'
              },
            notActive:{
                height:40,
                align:'right',
                backgroundColor:'#FFFFFF',
                borderRadius:[0, 4, 4, 0],
                width:'50%'
              },
            textNotActive:{
                color: '#333333',
                backgroundColor:'#FFCBCB',
                fontSize: 12,
                align: 'left',
                height:40,
                borderRadius:[0, 4, 4, 0],
                fontWeight:'bold',
                padding:[0,7],
                width:140,
                //width:110
              },
            text:{
                backgroundColor: 'transparent',
                color:  'black',
                padding:10,
                position: 'inside',
                //distance:10
              },
            hr:{
                padding:10,
                fontSize:12,
                height: 12,
              },
            textNoEditor:{
                width:100,
                fontSize: 12,
                align: 'left',
                fontWeight:'bold',
                padding:[0,5.5]
              },
            textNoEditorNoSvg:{
                width:120,
                fontSize: 12,
                align: 'left',
                fontWeight:'bold',
                padding:[4,12],
              },
            textNoEditorIDTemplate:{
                width:120,
                fontSize: 12,
                align: 'left',
                fontWeight:'bold',
                padding:[13,5.5],
                backgroundColor: '#FFCBCB'
              },
              textNoEditorChildren:{
                width:110,
                fontSize: 12,
                align: 'left',
                fontWeight:'bold',
                padding:[5, 5.5]
              },
              textReactionNoEditor:{
                color: '#333333',
                fontSize: 12,
                align: 'left',
                height:40,
                padding:[0, 13],
                fontWeight:'bold',
                width:80,
              },
              reactionNoEditorBG:{
                height:40,
                align:'right',
                backgroundColor:'#FFFFFF',
                borderRadius:[0, 4, 4, 0],
              },
              textResultNoTemplate:{
                color: '#333333',
                fontSize: 12,
                align: 'left',
                height:40,
                padding:[0,13],
                fontWeight:'bold',
                width:125,
              },

            },
          },
        },
      ],
    };

  }



  getRegularLabelFormatter(v){
        let nodeType = v.data.type
        let icon = v.data.svgLabelName
    
        if(v.data.type === 'noReaction'){
          nodeType = 'reaction'
        }
    
        if(this.isEditor){
          if(!v.data.status && nodeType !== 'reaction' && nodeType !== 'bookingDays' && nodeType !== 'eventTrigger'){
            //console.log('v.data', v.data) 
            return [` {editorBg|} {textNotActive|${this.ellipsis(v.data.name)}}`,].join('\n');
          }
          switch(nodeType){
            case 'workflows':
              return [
                ` {editorBlue|} {textWorkflow|${this.ellipsis(v.data.name)}} {caretWhite|} {workflowBg|}`,
             ].join('\n');
            case 'steps':
                if(v.data.isProcessStarter){
                  return[
                    ` {editorBlue|} {textStepSvg|${this.ellipsis(v.data.name, v.data.isProcessStarter)}} {footprint|}{${icon}WhiteBg|}{caretWhite|} {stepsBg|}`,
                  ].join('\n');
                } else {
                 return[
                   ` {editorBlue|} {textStep|${this.ellipsis(v.data.name)}} {${icon}WhiteBg|} {caretWhite|} {stepsBg|}`,
                ].join('\n');
              }
            case 'results':
            if(v.data.emailTemplateId && this.emailTemplateFeatureIsActive){
              return [` {editorBg|} {emailTemplateBlack|} {leftBlackBorder|} {textResult|${this.ellipsis(v.data.name)}}`].join('\n');
            } else 
              //console.log('result', v.data.name)
              return [` {editorBg|} {leftBlackBorder|} {textResultNoTemplate|${this.ellipsis(v.data.name)}}`].join('\n');
  
            case 'resultsChildren':
              //console.log('designer flowchart-children', v.data)
              if(v.data.emailTemplateId && this.emailTemplateFeatureIsActive){
                return[` {editorBlue|} {emailTemplateBlue|} {leftBlueBorderTwo|}{textChildren| ${this.ellipsis(v.data.name)}} {caretBlue|} {childrenBg|}`].join('\n');
              } else {
                return[` {editorBlue|} {leftBlueBorder|} {textChildren| ${this.ellipsis(v.data.name)}} {caretBlue|} {childrenBg|}`].join('\n');
              }
            case 'reaction':
              if(this.isSuperVisor){
                return [` {editorBg|} {leftBlackBorder|} {textReaction| ${this.ellipsis(v.data.name)}} {caretBlack|}{reactionBg|}`].join('\n');
              }
              return [`{textReaction| ${v.data.name}} {reactionBg|}`].join('\n');
            case 'bookingDays':
              return [` {editorBg|} {leftBlackBorder|} {textReaction|${this.ellipsis(v.data.name)}} {clock|}{caretBlack|}{reactionBg|}`].join('\n');

          }
        } else {
          if(!v.data.status && nodeType !== 'reaction' && nodeType !== 'bookingDays'){
            if(v.data.emailTemplateId){
        /*       console.log('noEditorIDTEMPLATE -> ', v.data.name)
              console.log('v.data', v.data) */
              return [`{emailTemplateBlack|} {textNoEditorIDTemplate|${this.ellipsis(v.data.name)}}`].join('\n');
            } else {
        /*       console.log('noEditorNOSvg -> ', v.data.name) */
              return [`{textNoEditorNoSvg|${this.ellipsis(v.data.name)}}`,].join('\n');
            }
          }
          switch(nodeType){
            case 'workflows':
              return [
                `{textNoEditor|${this.ellipsis(v.data.name)}} {caretWhite|}`,
             ].join('\n');
            case 'steps':
              if(v.data.isProcessStarter){
                return [
                  `{textNoEditor| ${this.ellipsis(v.data.name, v.data.isProcessStarter, v.data.emailTemplateId)}} {footprint|}{caretWhite|}`,
               ].join('\n');
              } else {
                return [
                  `{textNoEditor|${this.ellipsis(v.data.name)}} {${icon}WhiteBg|}{caretWhite|}`,
               ].join('\n');
              }
            case 'results':
            if(v.data.emailTemplateId && this.emailTemplateFeatureIsActive){
              return [` {emailTemplateBlack|} {leftBlackBorder|} {textResult|${this.ellipsis(v.data.name)}}`].join('\n');
            } else 
              return [`{textNoEditorNoSvg|${this.ellipsis(v.data.name)}}`].join('\n');
            case 'resultsChildren':
              if(v.data.emailTemplateId && this.emailTemplateFeatureIsActive){
                /* console.log('results-children', v.data, v.data.emailTemplateId); */ 
                return[` {emailTemplateBlue|} {leftBlueBorder|}{textChildren| ${this.ellipsis(v.data.name)}} {caretBlue|} {childrenBg|}`].join('\n');
              } else {
                /* console.log('result children', v.data.name); */
                return[`{textNoEditorChildren|${this.ellipsis(v.data.name)}} {caretBlue|}`].join('\n');
              }
            case 'reaction':
              return [`{textReactionNoEditor|${v.data.name}} {caretBlack|}`].join('\n');
            case 'bookingDays':
              return [`{textReactionNoEditor|${v.data.name}} {clock|}{caretBlack|}`].join('\n');
            case 'eventTrigger':
              return [`${v.data.name}`].join('\n');
        }
        }
    
      }


  getMicroCampaignStartedLabelFormatter(v){
        let nodeType = v.data.type
        let icon = v.data.svgLabelName
        // console.log('icon', icon)
        if(v.data.type === 'noReaction'){
          nodeType = 'reaction'
        }
    
        if(!v.data.status && nodeType !== 'reaction' && nodeType !== 'bookingDays'){
          if(v.data.emailTemplateId){
            return [`{textNoEditorIDTemplate|${this.ellipsis(v.data.name)}}`].join('\n');
          } else {
            return [`{textNoEditorNoSvg|${this.ellipsis(v.data.name)}}`,].join('\n');
          }
        }
        switch(nodeType){
          case 'workflows':
            return [
              `{textNoEditor|${this.ellipsis(v.data.name)}} {caretWhite|}`,
           ].join('\n');
          case 'steps':
            if(v.data.isProcessStarter){
              return [
                `{textNoEditor| ${this.ellipsis(v.data.name, v.data.isProcessStarter, v.data.emailTemplateId)}} {footprint|}{caretWhite|}`,
             ].join('\n');
            } else {
              return [
                `{textNoEditor|${this.ellipsis(v.data.name)}} {${icon}WhiteBg|}{caretWhite|}`,
             ].join('\n');
            }
          case 'results':
          if(v.data.emailTemplateId && this.emailTemplateFeatureIsActive){
            return [` {emailTemplateBlack|} {leftBlackBorder|} {textResult|${this.ellipsis(v.data.name)}}`].join('\n');
          } else 
            return [`{textNoEditorNoSvg|${this.ellipsis(v.data.name)}}`].join('\n');
          case 'resultsChildren':
            if(v.data.emailTemplateId && this.emailTemplateFeatureIsActive){
              //console.log('results-children', v.data)
              return[` {emailTemplateBlue|} {leftBlueBorder|}{textChildren| ${this.ellipsis(v.data.name)}} {caretBlue|} {childrenBg|}`].join('\n');
            } else {
              return[`{textNoEditor|${this.ellipsis(v.data.name)}} {caretBlue|}`].join('\n');
            }
          case 'reaction':
            return [`{textReaction|${v.data.name}} {caretBlack|}{reactionBg|}`].join('\n');
          case 'bookingDays':
            return [`{textReaction|${v.data.name}} {clock|}{caretBlack|}{reactionBg|}`].join('\n');
          case 'eventTrigger':
            return [`${v.data.name}`].join('\n');
        }
    
      }

  getPreviousChartPath(){ 
    if(this.lastClickedNode && this.chartInstance){
      let updatedData = this.matchCollapseData(this.chartData, this.currentDataBeforeEdit);

      this.updateOptions={
        series:[{
          data:[updatedData].map((item) => {
            if (this.showInactiveStatus === false) {
              return this.checkStatus(item)
            } else {
              return item
            }
          })
        }]
      }

      // ! not sure if this is effective enough it presents an effect that sometimes is broken
   /*    this.chartInstance.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.lastClickedNode.dataIndex,
      
      }); */
    }
  }

  matchCollapseData(newData, oldData){
    return {
      ...newData,
      collapsed: oldData.collapsed,
      children: newData?.children?.map(child1 =>{
        const child2 = oldData?.children?.find(chil2 =>chil2.id === child1.id);
        return child2 ? this.matchCollapseData(child1, child2) : child1
      })
    }
  }
  

  getToolBox(string){
    switch(string){
      case 'info':
        this.showInfo = this.showInfo === true ? false : true;
        break;
        case 'expand':
            this.applicationInfoService.miscSettings["flowChartOverviewExpanded"] = this.dataToolbox;
            this.applicationInfoService.miscSettings["flowChartOverviewExpandedType"] = this.controlid;
            this.eventService.showJjPopup('',
              "expandflowchartoverview", 100, true);
        break;
        case 'edit':
        default:
          return
    }
  }


  // * API tools 
  recursiveData(data) {
    //console.log('data', data)
    if (data.workflowSteps) {

      if(data.workflowSteps.length === 0){
        return {
          ...this.workflows(0, data),
          children:[]
        }
      } else {
        return {
          ...this.workflows(0, data),
          children: data.isActive ?  data.workflowSteps.map((item) => this.recursiveData(item)) : null,
    
        };
      }

    } else if (data.results) {
      //console.log('data steps', data)
      if (data.bookTaskAfterXDays) {
        const findTask = this.applicationInfoService.results.toArray().find((resultItem) => resultItem.id === data.bookTaskAfterXDaysResultId);
        const isSame = data.results.find((result) => result.id === data.bookTaskAfterXDaysResultId)
        // ! to implement later when requested
        //console.log('findTask', findTask)
        //console.log('isSame', isSame)

        if(data.results.length === 1 && isSame){
          //console.log('booking with no reaction', data)
          //const eventTriggers = data.results.filter((item) => item.workflowStepResultEventTriggers.length > 0)
          return {
            ...this.steps(0, data, this.starterStepId, data.isProcessStarter),
            children:[{
              ...this.bookingDays(0, data),
              children:data?.results.map((item)=> this.recursiveData(item))
            }]
          }
        } else if (findTask){
          //console.log('findTask', findTask);
          return {
            ...this.steps(0, data, this.starterStepId, data.isProcessStarter),
            children: [
              {
                ...this.bookingDays(0, data),
                children:[
                  {
                  ...this.reaction(0, 'noReaction', data),
                  children:[this.recursiveData(findTask)]
                },
                {
                  ...this.reaction(0, 'reaction', data),
                  children: data.isActive ? data.results.filter((item)=> item.id !== findTask.id).map((item) => this.recursiveData(item)) : null,
                },]
                
              }]
          };

        } else {
          return {
            ...this.steps(0,data, this.starterStepId, data.isProcessStarter),
            children: data.isActive ? data.results.map((item) => this.recursiveData(item)): null,
          };
        }

      } else {
        //console.log('no booking', data)
        return {
          ...this.steps(0,data, this.starterStepId, data.isProcessStarter),
          children: data.isActive ? data.results.map((item) => this.recursiveData(item)): null,
        };
      }
    } else if (data.nextStepId) {
      const nextStep = this.applicationInfoService.steps.toArray().find((stepItem) => stepItem.id === data.nextStepId);
      //console.log('nextStep', nextStep);
      if (nextStep) {
        return {
          ...this.resultsChildren(0,data, this.isStarterStep),
          children: data.isActive ? [this.recursiveSecond(nextStep)] : null,
        };
      } else {
        return {
          ...this.results(0,data),
        };
      }
    } else {
      return {
        ...this.results(0,data),
      };
    }
  }

  recursiveSecond(data) {
    if (data.workflowSteps) {
      if(data.workflowSteps.length === 0){
        return {
          ...this.workflows(1, data),
          children:[]
        }
      } else {
        return {
          ...this.workflows(1,data),
          children: data.isActive ? data.workflowSteps.map((item) => this.recursiveSecond(item)) : null,
        };
      }
    } else if (data.results) {
      if (data.bookTaskAfterXDays) {
        const findTask = this.applicationInfoService.results.toArray().find((resultItem) => resultItem.id === data.bookTaskAfterXDaysResultId);
        const isSame = data.results.find((result) => result.id === data.bookTaskAfterXDaysResultId)
        if(data.results.length === 1 && isSame){
          return {
            ...this.steps(1, data),
            children:[{
              ...this.bookingDays(1,data),
              children:data?.results.map((item)=> this.recursiveSecond(item))
            }]
          }
        } else if (findTask){
          return {
            ...this.steps(1, data),
            children: [
              {
                ...this.bookingDays(1,data),
                children:[
                  {
                  ...this.reaction(1,'noReaction', data),
                  children:[this.recursiveSecond(findTask)]
                },
                {
                  ...this.reaction(1,'reaction', data),
                  children: data.isActive ? data.results.filter((item)=> item.id !== findTask.id).map((item) => this.recursiveSecond(item)) : null,
                },]
                
              }]
          };
        } else {
          return {
            ...this.steps(1,data),
            children: data.isActive ? data.results.map((item) => this.recursiveSecond(item)) : null,
          };
        }
      } else {
        return {
          ...this.steps(1,data),
          children: data.isActive ? data.results.map((item) => this.recursiveSecond(item)) : null,
        };
      }
    } else if (data.nextStepId) {
      const nextStep = this.applicationInfoService.steps.toArray().find((stepItem) => stepItem.id === data.nextStepId);
      if (nextStep) {
        return {
          ...this.resultsChildren(1,data),
          nextStepName: nextStep.defaultName,
          children: data.isActive ? [this.recursiveThird(nextStep)] : null,
        };
      } else {
          return {
          ...this.results(1,data),
        };
      }
    } else {
      return {
        ...this.results(1,data),
      };
    }
  }

  recursiveThird(data) {
    if (data.workflowSteps) {
      return {
        ...this.workflows(2,data),
        children: data.isActive ? data.workflowSteps.map((item) => this.recursiveThird(item)) : null,
      };
    } else if (data.results) {
      if (data.bookTaskAfterXDays) {
          const findTask = this.applicationInfoService.results.toArray().find((resultItem) => resultItem.id === data.bookTaskAfterXDaysResultId);
          const isSame = data.results.find((result) => result.id === data.bookTaskAfterXDaysResultId)
          if(data.results.length === 1 && isSame){
            return {
              ...this.steps(2,data),
              children:[{
                ...this.bookingDays(2,data),
                children:data?.results.map((item)=> this.recursiveThird(item))
              }]
            }
          } else if(findTask){
            return {
              ...this.steps(2,data),
              children: [
                {
                  ...this.bookingDays(2,data),
                  children:[
                    {
                    ...this.reaction(2,'noReaction', data),
                    children:[this.recursiveThird(findTask)]
                  },
                  {
                    ...this.reaction(2,'reaction', data),
                    children: data.isActive ? data.results.filter((item)=> item.id !== findTask.id).map((item) => this.recursiveThird(item)) : null,
                  },]
                  
                }]
            };
          } else {
            return {
              ...this.steps(2,data),
              children: data.isActive ? data.results.map((item) => this.recursiveThird(item)) : null,
            };
          }
      } else {
        return {
          ...this.steps(2,data),
          children: data.isActive ? data.results.map((item) => this.recursiveThird(item)) : null,
        };
      }
    } else if (data.nextStepId) {
      const nextStep = this.applicationInfoService.steps.toArray().find((stepItem) => stepItem.id === data.nextStepId);
      if (nextStep && this.controlid !== 'microCampaignStarted') {
        return {
          ...this.resultsChildren(2,data),
          nextStepName: nextStep.defaultName,
          children: data.isActive ? [] : null,
        };
      } else if (nextStep && this.controlid === 'microCampaignStarted') {
        return {
          ...this.resultsChildren(2,data),
          nextStepName: nextStep.defaultName,
          children: data.isActive ? [this.recursiveThirdMCStarter(nextStep)] : null,
        };
      } else {
        return {
          ...this.results(2,data),
        };
      }

    } else {
      return {
        ...this.results(2,data),
      };
    }
  }

  recursiveThirdMCStarter(data) {
    if (data.workflowSteps) {
      return {
        ...this.workflows(3,data),
        children: data.isActive ? data.workflowSteps.map((item) => this.recursiveThirdMCStarter(item)) : null,
      };
    } else if (data.results) {
      if (data.bookTaskAfterXDays) {
        const findTask = this.applicationInfoService.results.toArray().find((resultItem) => resultItem.id === data.bookTaskAfterXDaysResultId);
        const isSame = data.results.find((result) => result.id === data.bookTaskAfterXDaysResultId)
        if(data.results.length === 1 && isSame){
          return {
            ...this.steps(3,data),
            children:[{
              ...this.bookingDays(3,data),
              children:data?.results.map((item)=> this.recursiveThirdMCStarter(item))
            }]
          }
        } else if (findTask){
          return {
            ...this.steps(3,data),
            children: [
              {
                ...this.bookingDays(3,data),
                children:[
                  {
                  ...this.reaction(3,'noReaction', data),
                  children:[this.recursiveThirdMCStarter(findTask)]
                },
                {
                  ...this.reaction(3,'reaction', data),
                  children: data.isActive ? data.results.filter((item)=> item.id !== findTask.id).map((item) => this.recursiveThirdMCStarter(item)) : null,
                },]
                
              }]
          };
        } else {
          return {
            ...this.steps(3,data),
            children: data.isActive ? data.results.map((item) => this.recursiveThirdMCStarter(item)) : null,
          };
        }
        
      } else {
        return {
          ...this.steps(3,data),
          children: data.isActive ? data.results.map((item) => this.recursiveThirdMCStarter(item)) : null,
        };
      }
    } else if (data.nextStepId) {
      const nextStep = this.applicationInfoService.steps
        .toArray()
        .find((stepItem) => stepItem.id === data.nextStepId);
      if (nextStep) {
        return {
          ...this.resultsChildren(3,data),
          nextStepName: nextStep.defaultName,
          children: data.isActive ? [] : null,
        };
      } else {
        return {
          ...this.results(3,data),
        };
      }
    } else {
      return {
        ...this.results(3,data),
      };
    }
  }

  ellipsis(string, starterStep?: boolean, emailtemplate?:any){
    //console.log('string', string)
    let maxLength= 14;
    if(starterStep && this.isEditor){
      maxLength = 7;
    }
    if(!this.isEditor && !emailtemplate){
      maxLength = 14;
    }

    return string.trim().length > maxLength ? string.trim().slice(0, maxLength) + '...' : string.trim()

  }

  workflows(treeId, data){
/*     console.log('data - workflows', data) */
    return{
      id: data.id,
      name: data.defaultName,
      value: `W__${treeId}__${data.id}`,
      type: 'workflows',
      status: data.isActive,
      starterStep: data.starterStep,
      starterStepId: data.starterStepId,
      description: this.getTranslationValue('description', data),
      descriptionObject: data.descriptionTranslationToken,
      typeLookupId:data.typeLookupId,
      svgLabelName: this.hasSVGs(data),
      ...this.styleConfig('workflows', data),
      collapsed: false,
    }

  }


  steps(treeId, data, starterStepId?:number, isProcessStarter?:boolean, findNestedStep?: number){
    this.countCall =  this.countCall + 1
    return {
      id: data.id,
      name: this.getTranslationValue("name", data),
      value: `S__${treeId}-${this.countCall}__${data.id}`,
      bookTaskAfterXDays: data.bookTaskAfterXDays,
      type: 'steps',
      status: data.isActive,
      svgLabelName: this.hasSVGs(data),
      description: this.getTranslationValue('description',data),
      descriptionObject:data.descriptionTranslationToken,
      //nodeHeader: ((starterStepId === data.id) || isProcessStarter ) ? this.getKeyToText('Flowchart.Label.StartProcess') : '',
      typeLookupId: data.typeLookupId,
      isProcessStarter: ((starterStepId === data.id) || isProcessStarter /* || findNestedStep > 0 */) ? true : false,
      ...this.styleConfig('steps', data),
      //collapsed: treeId === 0 ? false : true,
      collapsed: true,
    };
  }

  results(treeId, data){
    this.countCall = this.countCall +1
    return {
      id: data.id,
      name: this.getTranslationValue("name", data),
      value: `R__${treeId}-${this.countCall}__${data.id}`,
      nextStepId: data.nextStepId,
      type: 'results',
      status: data.isActive,
      dueInDaysMax: data.dueInDaysMax,
      bookTaskAfterXDays: data.bookTaskAfterXDays,
      leadStatusId: data.leadStatusId,
      emailTemplateId:data.emailTemplateId,          
      description: this.getTranslationValue('description',data),
      descriptionObject: data.descriptionTranslationToken,
      svgLabelName: this.hasSVGs(data),
      typeLookupId: data.typeLookupId,
      ...this.styleConfig('results',data),
      collapsed: true,
    }
  }

  resultsChildren(treeId, data, isStarterStep?:boolean){
    this.countCall = this.countCall + 1

/*     console.log('data - results children', data) */
    return {
      type: 'resultsChildren',
      id: data.id,
      name: this.getTranslationValue("name", data),
      value: `RCH__${treeId}-${this.countCall}__${data.id}`,
      nextStep: data.nextStepId,
      status: data.isActive,
      dueInDaysMax: data.dueInDaysMax ? `max. ${data.dueInDaysMax} `+ this.getKeyToText('Flowchart.Label.Days') :  '',//data.dueInDaysMax,
      bookTaskAfterXDays: data.bookTaskAfterXDays,
      leadStatusId: data.leadStatusId,
      emailTemplateId: data.emailTemplateId,
      description: this.getTranslationValue('description', data),
      descriptionObject: data.descriptionTranslationToken,
      isCampaignStarter: isStarterStep,
      nodeHeader: isStarterStep ? this.getKeyToText('Flowchart.Label.StartCampaign') : '',
      typeLookupId:data.typeLookupId,
      svgLabelName: this.hasSVGs(data),
      eventTriggers:  data?.workflowStepResultEventTriggers?.length > 0 ? `${data?.workflowStepResultEventTriggers?.length} ${this.getKeyToText('Flowchart.Label.EventTriggers')}` : '',
      ...this.styleConfig('results-children', data),
      collapsed: true,
    };
  }


  bookingDays(treeId, data){
    this.countCall = this.countCall + 1
    return {
      id: data.id,
      name: `max. ${data.bookTaskAfterXDays} `+ this.getKeyToText('Flowchart.Label.Days'),
      value: `BD__${treeId}-${this.countCall}__${data.id}`,
      type: 'bookingDays',
      ...this.styleConfig('bookingDays',data),
      collapsed: true,
    };
  }

  reaction(treeId, string, data){
    this.countCall = this.countCall + 1
    switch(string){
      case "noReaction":
        return {
          id:  data.id,
          name: this.axivasTranslationService.getTranslationTextForToken('MicroCampaign.Label.NoMoreAction'),
          value: `NRCT__${treeId}-${this.countCall}__${data.id}`,
          type: 'noReaction',
          ...this.styleConfig('reaction'),
          collapsed: true,
        }
      case "reaction":
        return {
          id:  data.id,
          name: this.axivasTranslationService.getTranslationTextForToken('MicroCampaign.Label.Reaction'),
          value: `RCT__${treeId}-${this.countCall}__${data.id}`,
          type: 'reaction',
          ...this.styleConfig('reaction'),
          collapsed: true,
        }
    }
  }

  styleConfig(string, data?: any,) {
    switch (string) {
      case "workflows":
      if(!this.isEditor){
        return {
          itemStyle: {
          },
          symbol: 'none',
          symbolSize: 0,
          label: {
            backgroundColor: data.isActive ? "#5162F6" : '#FFCBCB' ,
            borderRadius: 3,
            borderColor: data.isActive ? "#5465FF" : '#FF5050',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
            align:'left',
            color:'white',
            padding:[10,5],
          },
        };

      } else{
        return {
          itemStyle: {
            color: "#BFD7FF;",
          },
          symbol: 'none',
          symbolSize: 0,
          borderColor:'black',
          label: {
            backgroundColor: data.isActive ? "#BFD7FF" : '#FFCBCB',
            borderRadius: 3,
            borderColor: data.isActive ? "#5465FF" : '#FF5050',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
          },
          
     /*      animation:true,
          animationDelay: 0 */
        };
      }
        break;
      case "steps":
      if(!this.isEditor){
        return {
          itemStyle: {
          },
          symbol: 'none',
          symbolSize: 0,
          label: {
            backgroundColor: data.isActive ? "#5162F6" : '#FFCBCB' ,
            borderRadius: 3,
            borderColor: data.isActive ? "#5465FF" : '#FF5050',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
            align:'left',
            color:data.isActive ? 'white' : '#333333',
            padding:[10,5],
          },
        };

      } else {
            return {
              itemStyle: {
              },
              symbol: 'none',
              symbolSize: 0,
              label: {
                backgroundColor: data.isActive ? "#BFD7FF" : '#FF5050' ,
                borderRadius: 3,
                borderColor: data.isActive ? "#5465FF" : '#FF5050',
                borderWidth:1,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.15)",
                align:'left',
              },
            /*   animation:true,
              animationDelay: 0 */

            };
          }
          break;
      case "bookingDays":
        return {
          itemStyle: {
            color: "#333333",
          },
          symbol:'none',
          symbolSize:0,
          label: {
            backgroundColor: "#FFFFFF" ,
            borderRadius: 3,
            borderColor:'#333333',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
            align:'left'
          },
        };
        break;
      case "reaction":
        return {
          itemstyle: { color: "transparent"},
          symbol:  'none',
           label: {
            backgroundColor: "#FFFFFF" ,
            borderRadius: 3,
            borderColor:'#333333',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
            align:'left'
          },
        };
        break;
      case "results":
      if(!this.isEditor && !data.emailTemplateId){
        return {
          itemStyle: {
          },
          symbol:'none',
          symbolSize:0,
          /* symbol: data.isActive ? 'none' : this.nodesSVGs('inactive'),
          symbolSize: data.isActive ? 0 : 15, */
          label: {
            backgroundColor: data.isActive ? "#DFDFDF" : '#FFCBCB' ,
            borderRadius: 3,
            borderColor: data.isActive ? "#333333" : '#FF5050',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
            align:'left',
            color: '#333333',
            padding:[10,5],
          },
        };

      } else {
        return {
          itemStyle: {
          },
          symbol:'none',
          symbolSize:0,
          /* symbol: data.isActive ? 'none' : this.nodesSVGs('inactive'),
          symbolSize: data.isActive ? 0 : 15, */
          label: {
            backgroundColor: data.isActive ? "#DFDFDF" : '#FF5050' ,
            borderRadius: 3,
            borderColor: data.isActive ? "#333333" : '#FF5050',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
            align:'left',
          },
        };
      }
        break;
      case "results-children":
      if(!this.isEditor && !data.emailTemplateId){
        return {
          itemStyle: {
          },
          symbol:'none',
          symbolSize:0,
          label: {
            backgroundColor:data.isActive ? "#BFD7FF" : '#FFCBCB',
            borderRadius: 3,
            borderColor: data.isActive ? "#5465FF" : '#FF5050',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
            align:'left',
            color: data.isActive ? '#5162F6' : '#333333',
            padding:[10,5],
          },
        };
      } else {
        return {
          itemStyle: {
          },
          symbol:'none',
          symbolSize:0,
          label: {
            backgroundColor:data.isActive ? "#BFD7FF" : '#FF5050',
            borderRadius: 3,
            borderColor: data.isActive ? "#5465FF" : '#FF5050',
            borderWidth:1,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.15)",
            align:'left',
          },
        };
      }
        break;
    }
  }

  onModalOpen({ data }) {
    this.refreshBlocker = true;
    let type = data.type

    if(type === 'resultsChildren'){
      type = 'results'
    }else if ( type === 'reaction' || type === 'noReaction') {
      type = 'steps'
    } else {
      type = data.type
    }

    if(!this.isSuperVisor){
      this.openDialogue(data)
    } else {
      let object
      if(type !== 'bookingDays'){
        object = this.applicationInfoService[type].toArray().find((arrayItem) => arrayItem.id == data.id);
      }
    switch (type) {
      case "workflows":
        if (!this.userService.hasPermission("CanUpdateWorkflowStepResult")) {
          this.openDialogue(data, 'workflows');
        }
        object.showDuplicateOption = false;
        this.wizardService.showWizard(WizardMode.Process, object);
        break;
      case "steps":
        if (!this.userService.hasPermission("CanUpdateWorkflowStep")) {
          //console.log('no credentials')
           this.openDialogue(data, 'steps');
        }
        this.wizardService.showWizard(WizardMode.Step, object);
        break;
      case "results":
        if (!this.userService.hasPermission("CanUpdateWorkflow")) {
           this.openDialogue(data, 'results');
        }
        this.wizardService.showWizard(WizardMode.Result, object);
        break;
      case 'bookingDays':
        this.openDialogue(data, 'bookingDays')
        break;
    }

  }
    setTimeout(() => {
      this.refreshBlocker = false;
    }, 2000);
  }

  openDialogue(data, stringType?: string){
    let type = stringType ? stringType : data.type
    let name = data.name;

    if(type === 'resultsChildren'){
      type = 'results'
    }
    if(type === 'bookingDays'){
      type = 'steps'
      name = 'ProcessVisual.Label.Automaticbooking'
    }
    if ( type === 'reaction' || type === 'noReaction' ) {
      type = 'steps'
    }
      let object = this.applicationInfoService[type].toArray().find((arrayItem) => arrayItem.id == data.id);
      this.applicationInfoService.miscSettings["processVisualObject"] = object;
      this.applicationInfoService.miscSettings["processVisualObjectType"] = data.type;
      this.eventService.showJjPopup(name,
        "processvisualpopup",
        "500px; 550px"
        );
  }

  getTranslationValue(type, data) {
    if (type === "name") {
      if (
        data?.nameTranslationToken != null &&
        data?.nameTranslationToken != undefined
      ) {
        return this.axivasTranslationService.getTranslationTextForToken(
          data?.nameTranslationToken?.tokenFullName
        );
      } else {
        return data.defaultName;
      }
    }
    if (type === "description") {
      if (data?.descriptionTranslationToken != null && data?.descriptionTranslationToken != undefined) {
        return this.axivasTranslationService.getTranslationTextForToken(
          data?.descriptionTranslationToken?.tokenFullName
        );
      } else {
        return data.descriptionTranslationToken;
      }
    }
  }

  getKeyToText(key) {
    return this.axivasTranslationService.getTranslationTextForToken(key);
   }

  getLeadstate(data) {
    const leadstate = this.applicationInfoService.leadstates
      .toArray()
      .find((lead) => lead.lookupTable.id == data.leadStatusId);
    if (leadstate) {
      return this.axivasTranslationService.getTranslationTextForObject(
        leadstate.lookupTable,
        "nameTranslationToken"
      );
    } else {
      return this.axivasTranslationService.getTranslationTextForToken(
        "ProcessVisual.Label.NoChange"
      );
    }
  }

  getDifferenceInDays(date1, date2, stringContent, multiplicator) {
    if (date1 == null) {
      return '';
    } else {
      return this.commonService.getDifferenceInDays(date1, date2, stringContent, multiplicator);
    }
  }

  isValidStep(step) {
    let returnValue = true;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step))) {
      if (this.applicationInfoService.steps.Item(step).typeLookupId == 3842) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  nodesSVGs(string){
    switch(string){
      case 'inactive':
        return "path://M19.82,18.07a1.59,1.59,0,0,1,.3.46,1.45,1.45,0,0,1,.1.55,1.44,1.44,0,0,1-.1.54,1.59,1.59,0,0,1-.3.46,1.39,1.39,0,0,1-.45.31,1.31,1.31,0,0,1-1.05,0,1.39,1.39,0,0,1-.45-.31L12,14,6.13,20.08a1.39,1.39,0,0,1-.45.31,1.31,1.31,0,0,1-1,0,1.41,1.41,0,0,1-.75-.77,1.44,1.44,0,0,1-.1-.54,1.45,1.45,0,0,1,.1-.55,1.41,1.41,0,0,1,.3-.46L10.07,12,4.18,5.93a1.49,1.49,0,0,1-.4-1,1.45,1.45,0,0,1,.4-1,1.34,1.34,0,0,1,2,0L12,10l5.87-6.08a1.34,1.34,0,0,1,1.95,0,1.45,1.45,0,0,1,.4,1,1.49,1.49,0,0,1-.4,1L13.93,12Z"
      case 'bookingDays':
        return "path://M208,96a12,12,0,1,1,12,12A12,12,0,0,1,208,96ZM196,72a12,12,0,1,0-12-12A12,12,0,0,0,196,72Zm28.66,56a8,8,0,0,0-8.63,7.31A88.12,88.12,0,1,1,120.66,40,8,8,0,0,0,119.34,24,104.12,104.12,0,1,0,232,136.66,8,8,0,0,0,224.66,128ZM128,56a72,72,0,1,1-72,72A72.08,72.08,0,0,1,128,56Zm-8,72a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H136V80a8,8,0,0,0-16,0Zm40-80a12,12,0,1,0-12-12A12,12,0,0,0,160,48Z"
      case 'mail':
        return "path://M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM203.43,64,128,133.15,52.57,64ZM216,192H40V74.19l82.59,75.71a8,8,0,0,0,10.82,0L216,74.19V192Z"
      case 'call':
        return "path://M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z"
      case 'pending':
        return "path://M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm56,112H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48a8,8,0,0,1,0,16Z"
      case 'event-trigger':
        return "path://M48,76a60,60,0,0,1,120,0,8,8,0,0,1-16,0,44,44,0,0,0-88,0,8,8,0,0,1-16,0Zm140,44a27.9,27.9,0,0,0-13.36,3.39A28,28,0,0,0,136,106.7V76a28,28,0,0,0-56,0v80l-3.82-6.13a28,28,0,0,0-48.41,28.17l29.32,50A8,8,0,1,0,70.89,220L41.6,170a12,12,0,1,1,20.78-12l.14.23,18.68,30A8,8,0,0,0,96,184V76a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V132a12,12,0,0,1,24,0v20a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0v36c0,21.61-7.1,36.3-7.16,36.42a8,8,0,0,0,3.58,10.73A7.9,7.9,0,0,0,200,232a8,8,0,0,0,7.16-4.42c.37-.73,8.85-18,8.85-43.58V148A28,28,0,0,0,188,120Z"
        default:
          return
     }
  }

  hasSVGs(data){
    switch(data.typeLookupId){
      case 2435:
        return 'mail'
      case 2446:
        return 'conversion'
      case 3842:
        return 'pending'
      case 4190:
        return 'followup'
      case 2434:
        return 'call'
      case 4905:
        return 'meeting'
      case 4906:
        return 'miscellaneous'
    }

  }


  getRichIcons(){

    return {

      clock:{
        backgroundColor:{
          image:"/assets/images/svg/charts/clock.svg",
        },
        height: 20,
      },
      editorBg:{
        backgroundColor: {
          //image:"/assets/NEW_EVERLEAD_ICONS/Flowchart/note-pencil-black.svg"
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/note-pencil-black.svg"
        },
        width:20,
        height:20,
        align:'center',
      },
      editorBlue:{
        backgroundColor: {
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/note-pencil-blue.svg"
        },
        width:20,
        height:20,
        align:'center',
      },
      emailTemplateBlue:{
        backgroundColor: {
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/at-blue.svg",
        },
        height: 20,
        width:20,
        align:'center'
      },
      emailTemplateBlack:{
        backgroundColor: {
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/at-black.svg",
        },
        height: 20,
        width:20,
        align:'center'
      },
      caretWhite:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/caret-right-white.svg"
        },
        width:20,
        height:20,
        align:'center'
      },
      caretBlack:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/caret-right-black.svg"
        },
        width:20,
        height:20,
        align:'center'
      },
      caretBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/caret-right-blue.svg"
        },
        width:20,
        height:20,
        align:'center'
      },
      footprint:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/footprints.svg"
        },
        width:20,
        height:20,
        align:'center'
      },
      space:{
        height:5,
        width:5
      },
      mailWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/envelope-simple.svg",
        },
        height: 20,
      },
      mailBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/envelope-simple.svg",
        },
        height: 20,
      },
      conversionWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/factory.svg",
        },
        height: 20,
      },
      conversionBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/factory.svg",
        },
        height: 20,
      },
      pendingWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/clock.svg",
        },
        height: 20,
      },
      pendingBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/clock.svg",
        },
        height: 20,
      },
      followupWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/swap.svg",
        },
        height: 20,
      },
      followupBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/swap.svg",
        },
        height: 20,
      },
      callWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/phone.svg",
        },
        height: 20,
      },
      callBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/phone.svg",
        },
        height: 20,
      },
      meetingWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/users-four.svg",
        },
        height: 20,
      },
      meetingBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/users-four.svg",
        },
        height: 20,
      },
      miscellaneousWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/shuffle-angular.svg",
        },
        height: 20,
      },
      miscellaneousBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/shuffle-angular.svg",
        },
        height: 20,
      },
      bookWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/calendar-plus.svg",
        },
        height: 20,
      },
      bookBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/calendar-plus.svg",
        },
        height: 20,
      }
    }
  }

  getHeight() {
    if (isNaN(this.height)) {
      return this.height;
    } else {
      return this.height.toString().concat('px');
    }
  }

  getWidth() {
    if (isNaN(this.width)) {
      return this.width;
    } else {
      return this.width.toString().concat('px');
    }
  }



  getTemplateName(id){
/*     console.log('getTemplateName', id); */
    if(this.emailTemplates.length > 0){
      const template = this.emailTemplates.find(template => template.id == id);
      if (template){
        return template.defaultName
      } else {
        return this.axivasTranslationService.getTranslationTextForToken("ProcessDesign.Label.EmailTemplateNotAvailable")
      }
    }
  }


  tooltipHTMLFormater(type, data) {
/*     console.log('data', data) */
    switch (type) {
      case "workflows":
        return `
        <div class="tooltipFlowChart">
            <div class="tooltipFlowChart__section">
            ${
              data.status === true
                ? `<p><strong style="color:black" >Status: </strong><strong style="color: green"> ${this.axivasTranslationService.getTranslationTextForToken(
                    "ProcessDesign.Label.Active"
                  )}</strong></p>`
                : `<p><strong style="color: black">Status: </strong><strong style="color: red">${this.axivasTranslationService.getTranslationTextForToken(
                    "ProcessDesign.Label.Inactive"
                  )}</strong></p>`
            }
              <p>${data.name}</p>
              <span></span>
            </div>
              ${
                data.description
                  ? `<div class="tooltipFlowChart__section">
              <h4>${this.axivasTranslationService.getTranslationTextForToken(
                "ProcessVisual.Label.Description"
              )}</h4>
              <p>${this.axivasTranslationService.getTranslationTextForToken(data.description)}</p>
              <span></span>
            </div>`
                  : ""
              }
        </div>
        `;
      case "steps":
        return `
        ${this.getSvgTooltipFormater(data)}
        <div class="tooltipFlowChart">
            <div class="tooltipFlowChart__section">
            ${
              data.status === true
                ? `<p><strong style="color:black" >Status: </strong><strong style="color: green"> ${this.axivasTranslationService.getTranslationTextForToken(
                    "ProcessDesign.Label.Active"
                  )}</strong></p>`
                : `<p><strong style="color: black">Status: </strong><strong style="color: red">${this.axivasTranslationService.getTranslationTextForToken(
                    "ProcessDesign.Label.Inactive"
                  )}</strong></p>`
            }
              <p>${data.name}</p>
              <span></span>
            </div>
      
           ${
             data.description
               ? `<div class="tooltipFlowChart__section">
              <h4>${this.axivasTranslationService.getTranslationTextForToken(
                "ProcessVisual.Label.Description"
              )}</h4>
              <p>${data.description}</p>
              <span></span>
            </div>`
               : ""
           }
            <div class="tooltipFlowChart__section">
            </div>
        </div>
        `;
      case "results":
        return `
        ${this.getSvgTooltipFormater(data)}
          <div class="tooltipFlowChart">
            <div class="tooltipFlowChart__section">
                ${
                  data.status === true
                    ? `<p><strong style="color:black" >Status: </strong><strong style="color: green"> ${this.axivasTranslationService.getTranslationTextForToken(
                        "ProcessDesign.Label.Active"
                      )}</strong></p>`
                    : `<p><strong style="color: black">Status: </strong><strong style="color: red">${this.axivasTranslationService.getTranslationTextForToken(
                        "ProcessDesign.Label.Inactive"
                      )}</strong></p>`
                }
                <p>${data.name}</p>
                <span></span>
              </div>
              <div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "Account.Label.Leadstate"
                )}</h4>
                <p>${this.getLeadstate(data)}</p>
                <span></span>
              </div>
              ${
                data.dueInDaysMax
                  ? `<div class="tooltipFlowChart__section">
                <h4>Followup in days</h4>
                <p>${data.dueInDaysMax}</p>
                <span></span>
              </div>`
                  : ""
              }
              ${
                data.description
                  ? `<div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "ProcessVisual.Label.Description"
                )}</h4>
                <p>${data.description}</p>
                <span></span>
              </div>`
                  : ""
              }
              ${
                (data.emailTemplateId && this.emailTemplateFeatureIsActive)
                  ? `<div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "ProcessVisual.Label.EmailTemplateId"
                )}</h4>
                <p>${this.getTemplateName(data.emailTemplateId)}</p>
                <span></span>
              </div>`
                  : ""
              }
          </div>
          `;
      case "resultsChildren":
            return `
            ${this.getSvgTooltipFormater(data)}
          <div class="tooltipFlowChart">
            <div class="tooltipFlowChart__section">
                ${
                  data.status === true
                    ? `<p><strong style="color:black" >Status: </strong><strong style="color: green"> ${this.axivasTranslationService.getTranslationTextForToken(
                        "ProcessDesign.Label.Active"
                      )}</strong></p>`
                    : `<p><strong style="color: black">Status: </strong><strong style="color: red">${this.axivasTranslationService.getTranslationTextForToken(
                        "ProcessDesign.Label.Inactive"
                      )}</strong></p>`
                }
                <p>${data.name}</p>
                <span></span>
              </div>
              <div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "Account.Label.Leadstate"
                )}</h4>
                <p>${this.getLeadstate(data)}</p>
                <span></span>
              </div>
              ${
                data.dueInDaysMax
                  ? `<div class="tooltipFlowChart__section">
                <h4>Followup in days</h4>
                <p>${data.dueInDaysMax}</p>
                <span></span>
              </div>`
                  : ""
              }
              ${
                data.description
                  ? `<div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "ProcessVisual.Label.Description"
                )}</h4>
                <p>${data.description}</p>
                <span></span>
              </div>`
                  : ""
              }
              ${
                (data.emailTemplateId && this.emailTemplateFeatureIsActive) 
                  ? `<div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "ProcessVisual.Label.EmailTemplateId"
                )}</h4>
                <p>${this.getTemplateName(data.emailTemplateId)}</p>
                <span></span>
              </div>`
                  : ""
              }
          </div>
          `;
    }
  }

  getSvgTooltipFormater(data){
    //console.log('data', data)
    switch(data.svgLabelName){
      case 2435:
        return `
        <div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM203.43,64,128,133.15,52.57,64ZM216,192H40V74.19l82.59,75.71a8,8,0,0,0,10.82,0L216,74.19V192Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 2446:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M116,176a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h28A8,8,0,0,1,116,176Zm60-8H148a8,8,0,0,0,0,16h28a8,8,0,0,0,0-16Zm72,48a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H32V88a8,8,0,0,1,12.8-6.4L96,120V88a8,8,0,0,1,12.8-6.4l38.74,29.05L159.1,29.74A16.08,16.08,0,0,1,174.94,16h18.12A16.08,16.08,0,0,1,208.9,29.74l15,105.13s.08.78.08,1.13v72h16A8,8,0,0,1,248,216Zm-85.86-94.4,8.53,6.4h36.11L193.06,32H174.94ZM48,208H208V144H168a8,8,0,0,1-4.8-1.6l-14.4-10.8,0,0L112,104v32a8,8,0,0,1-12.8,6.4L48,104Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 3842:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-48-56a8,8,0,0,1-8,8H136v16a8,8,0,0,1-16,0V160H104a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16A8,8,0,0,1,160,152Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 4190:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M246,106.65l-36-40A8,8,0,0,0,204,64H136V32a8,8,0,0,0-16,0V64H40A16,16,0,0,0,24,80v64a16,16,0,0,0,16,16h80v64a8,8,0,0,0,16,0V160h68a8,8,0,0,0,5.95-2.65l36-40A8,8,0,0,0,246,106.65ZM200.44,144H40V80H200.44l28.8,32Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 2434:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 4905:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M27.2,126.4a8,8,0,0,0,11.2-1.6,52,52,0,0,1,83.2,0,8,8,0,0,0,11.2,1.59,7.73,7.73,0,0,0,1.59-1.59h0a52,52,0,0,1,83.2,0,8,8,0,0,0,12.8-9.61A67.85,67.85,0,0,0,203,93.51a40,40,0,1,0-53.94,0,67.27,67.27,0,0,0-21,14.31,67.27,67.27,0,0,0-21-14.31,40,40,0,1,0-53.94,0A67.88,67.88,0,0,0,25.6,115.2,8,8,0,0,0,27.2,126.4ZM176,40a24,24,0,1,1-24,24A24,24,0,0,1,176,40ZM80,40A24,24,0,1,1,56,64,24,24,0,0,1,80,40ZM203,197.51a40,40,0,1,0-53.94,0,67.27,67.27,0,0,0-21,14.31,67.27,67.27,0,0,0-21-14.31,40,40,0,1,0-53.94,0A67.88,67.88,0,0,0,25.6,219.2a8,8,0,1,0,12.8,9.6,52,52,0,0,1,83.2,0,8,8,0,0,0,11.2,1.59,7.73,7.73,0,0,0,1.59-1.59h0a52,52,0,0,1,83.2,0,8,8,0,0,0,12.8-9.61A67.85,67.85,0,0,0,203,197.51ZM80,144a24,24,0,1,1-24,24A24,24,0,0,1,80,144Zm96,0a24,24,0,1,1-24,24A24,24,0,0,1,176,144Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 4906:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__Svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" viewBox="0 0 256 256"><path d="M237.66,178.34a8,8,0,0,1,0,11.32l-24,24a8,8,0,0,1-11.32-11.32L212.69,192H200.94a72.12,72.12,0,0,1-58.59-30.15l-41.72-58.4A56.1,56.1,0,0,0,55.06,80H32a8,8,0,0,1,0-16H55.06a72.12,72.12,0,0,1,58.59,30.15l41.72,58.4A56.1,56.1,0,0,0,200.94,176h11.75l-10.35-10.34a8,8,0,0,1,11.32-11.32ZM143,107a8,8,0,0,0,11.16-1.86l1.2-1.67A56.1,56.1,0,0,1,200.94,80h11.75L202.34,90.34a8,8,0,0,0,11.32,11.32l24-24a8,8,0,0,0,0-11.32l-24-24a8,8,0,0,0-11.32,11.32L212.69,64H200.94a72.12,72.12,0,0,0-58.59,30.15l-1.2,1.67A8,8,0,0,0,143,107Zm-30,42a8,8,0,0,0-11.16,1.86l-1.2,1.67A56.1,56.1,0,0,1,55.06,176H32a8,8,0,0,0,0,16H55.06a72.12,72.12,0,0,0,58.59-30.15l1.2-1.67A8,8,0,0,0,113,149Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
        default:
          return `<span></span>`
    }
    
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) {
      this.customEventSubscription.unsubscribe();
    }
  }

}

