import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-echobot-interface-confirm',
  templateUrl: './echobot-interface-confirm.component.html',
  styleUrl: './echobot-interface-confirm.component.scss'
})
export class EchobotInterfaceConfirmComponent implements OnInit {
  
  
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private eventService : EventService
  ){}

  item

  
  ngOnInit(): void {

    this.item = this.applicationInfoService.miscSettings['echobotConfirm']
    this.applicationInfoService.miscSettings['echoConfirm']= null;
/*     console.log('message', this.item.message, 'item', this.item.item, 'type', this.item.type) */
  }


  confirm(){
    this.eventService.closeJjPopup()
    this.eventService.customEvent.emit({ id:'echobotconfirm', type:this.item.type , item: this.item.item })
  }

  reject(){
    this.eventService.closeJjPopup()
  }

}
