import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/authentication/services/user.service';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { GraphQLService, ApolloMethod } from './graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from '../translation/axivas-translate.service';

export class UserSetting {
  id: number;
  key: string;
  value: any;
  userId: number;
  constructor(id, key, value, userId) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.userId = userId;
  }
}

export enum Setting {
  ActivityFormLastTick = 'ActivityFormLastTick',
  LastNewsView = 'LastNewsView',
  LanguageIdentifier = 'LanguageIdentifier',
  SignalRConnectionError = 'SignalRConnectionError',
  UpdateActivityError = 'UpdateActivityError',
  LastTickError = 'LastTickError',
  Developer = 'Developer',
  TimeRecordingUser = 'TimeRecordingUser',
  WebPhoneUser = 'WebPhoneUser',
  UseNewHeader = 'UseNewHeader',
  PhoneType = 'PhoneType'
}

@Injectable()
export class SettingsService {
  private settingURL = 'assets/settings.json';
  public userSettings = {};  

  constructor(
        private httpClient: HttpClient
      , private userService: UserService
      , private graphQLService: GraphQLService
      , private externaldatasourceService: ExternaldatasourceService
      , private applicationInfoService: ApplicationInfoService
      , private graphqlqueryService: GraphqlqueryService
      , private commonService: CommonService
      , private axivasTranslateService: AxivasTranslateService
  ) {}

  getUserSettings(): Promise<any> {
    return new Promise((getUserSettingsResolve, getUserSettingsReject) => {
      this.userSettings = {};
      this.externaldatasourceService.executeExternalDataSource(393, [])
      .then(getUserSettingsResult => {
        this.consumeUserSettings(getUserSettingsResult);
        getUserSettingsResolve(getUserSettingsResult);        
      })
      .catch(error => {
        getUserSettingsReject(error);
      });
    });
  }

  consumeUserSettings(userSettings) {
    userSettings.forEach(userSetting => {
      const setting = new UserSetting(userSetting.id, userSetting.key, userSetting.value, this.userService.getUserId());
      this.userSettings[userSetting.key] = setting;
    });    
    if (this.userSettings[Setting.LanguageIdentifier]) {
      this.axivasTranslateService.currentLanguage = this.userSettings[Setting.LanguageIdentifier].value;          
    }      

    if (!this.commonService.isNullOrUndefined( this.applicationInfoService.applicationSettings['defaultUserSettings'])) {
      if (this.userSettings[Setting.LanguageIdentifier]) {
        this.axivasTranslateService.currentLanguage = this.userSettings[Setting.LanguageIdentifier].value;          
      }      
      const defaultSettings = this.applicationInfoService.applicationSettings['defaultUserSettings'].split(';');
      defaultSettings.forEach(defaultSetting => {
        const defaultSettingDetail = defaultSetting.split(':');
        this.checkAndInitSetting(
          defaultSettingDetail[0], 
          this.commonService.nullOrUndefinedToDefaultValue(defaultSettingDetail[1], '')
        );
      });
      this.axivasTranslateService.currentLanguage = this.userSettings[Setting.LanguageIdentifier].value;          
      if (this.userSettings[Setting.Developer]) {
        this.applicationInfoService.isDeveloper = JSON.parse(this.userSettings[Setting.Developer].value);    
      }      
      this.applicationInfoService.userAvatar = this.userSettings['AvatarImage'].value;    
      this.applicationInfoService.expertMode = JSON.parse(this.userSettings['ExpertMode'].value);    
    }   
  }

  updateSecurityUserSettings(userSetting = null) {
    if (userSetting) {
      this.applicationInfoService.userSettings[userSetting.key] = userSetting.value;
    }
    const array = {
      id: this.applicationInfoService.userID,
      userSettings: JSON.stringify(this.applicationInfoService.userSettings)
    };
    this.externaldatasourceService.executeExternalDataSource(728, [
      this.commonService.getModifyArrayBody(array, [])
    ])
  }

  updateSecurityUserSettingsJson() {
    const array = {
      id: this.applicationInfoService.userID,
      userSettings: JSON.stringify(this.applicationInfoService.userSettings)
    };
    this.externaldatasourceService.executeExternalDataSource(728, [
      this.commonService.getModifyArrayBody(array, [])
    ])
  }

  setUserSettingEx(userSetting): Promise<any> {
    return new Promise((getUserSettingsResolve, getUserSettingsReject) => {
      let externalDataSourceId = 395;
      let excludeList = [];
      if (userSetting.id == null || userSetting.id == undefined) {
        externalDataSourceId = 394;
        excludeList.push('id');
      } else {
        excludeList.push('key');
        excludeList.push('userId');
      }
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [
        this.commonService.getModifyArrayBody(userSetting, excludeList)
      ])
      .then(getUserSettingsResult => {
        this.updateSecurityUserSettings(userSetting);
        getUserSettingsResolve(getUserSettingsResult);
      })
      .catch(error => {
        getUserSettingsReject(error);
      });
    });
  }

  checkAndInitSetting(setting, defaultValue) {
    if (this.commonService.isNullOrUndefined(this.userSettings[setting])) {
      this.userSettings[setting] = new UserSetting(null, setting, defaultValue, this.userService.getUserId());
      this.setUserSettingEx(this.userSettings[setting]);
    }
  }


  getServerSettings() {
    return this.httpClient.get(this.settingURL);
  }


  public getUserSettingByString(settingName: string, defaultValue: any = null) {
    let result = null;
    this.applicationInfoService.user.settings.forEach(element => {
      if (element.key === settingName) {
        result = element;
      }
    });
    if (result == null) {
      result = { key: settingName, value: defaultValue };
      this.setUserSetting(settingName, defaultValue, true);
    }
    return result;
  }

  public getUserSettingByStringPromise(settingName: string, defaultValue: any = null): Promise<any> {
    return new Promise((getUserSettingByStringPromiseResolve, getUserSettingByStringPromiseReject) => {
      let result = null;
      this.applicationInfoService.user.settings.forEach(element => {
        if (element.key === settingName) {
          result = element;
        }
      });
      if (result == null) {
        result = { key: settingName, value: defaultValue };
        this.setUserSetting(settingName, defaultValue, true)
        .then(() => { getUserSettingByStringPromiseResolve(defaultValue); })
        .catch(() => { getUserSettingByStringPromiseReject(); });
      }  else {
        getUserSettingByStringPromiseResolve(result);
      }
    });
  }

  public getUserSetting(settingName: Setting, defaultValue: any = null): any {
    return this.getUserSettingByString(settingName, defaultValue);
  }

  public setUserSetting(key: string, value: string, newValue: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      let query = '';
      let setting = null;
      if (!newValue) {
        setting = this.getUserSettingByString(key);
      }
      if (setting == null ) {
        query = this.graphqlqueryService.settingServiceUCreateUserSettingMutation;
      } else {
        query = this.graphqlqueryService.settingServiceUpdateUserSettingMutation;
        query = query.replace('<SettingID>', setting.id);
      }
      query = query.replace('<userID>', this.userService.getUserId().toString());
      query = query.replace('<Key>', key);
      query = query.replace('<Value>', value);
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Mutation, query).then(result => {
        this.updateSecurityUserSettings({ key: key, value: value });
        this.userService.getJollyJupiterUserInformation().then(() => {          
          resolve(result);
        });
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
    });
  }

  getGQLUserSettings(): Promise<any> {
    return new Promise((getUserSettingsResolve, getUserSettingsReject) => {
      this.externaldatasourceService.executeExternalDataSource(393, [])
      .then(getUserSettingsResult => {
        getUserSettingsResolve(getUserSettingsResult);
      })
      .catch(error => {
        getUserSettingsReject(error);
      });
    });
  }
}
