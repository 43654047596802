import { Component } from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-everlead-support',
  templateUrl: './everlead-support.component.html',
  styleUrl: './everlead-support.component.scss'
})
export class EverleadSupportComponent {
  constructor(
    private eventService: EventService
  ) {}

  closePopup() {
    this.eventService.closeJjPopup();
  }
}
