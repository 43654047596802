import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-svg-button',
  templateUrl: './svg-button.component.html',
  styleUrl: './svg-button.component.scss'
})
export class SvgButtonComponent {

    @Input() id: string | number | null = null ;
    @Input() disabled: boolean = false;
    @Input() svg: string = null;
    @Input() label: string | undefined = undefined;
    @Input() type: 'sm' | 'md' | 'lg' = 'sm' ;
    @Input() color: string | null = null
    @Input() isActive: boolean | null = null; 

    @Output() onClick = new EventEmitter<any>();

    click = false;
    actioncolorchange = 'secondary';


  buttonClick(){

      this.click=true;
      this.onClick.emit();
      this.click=false;

      if(this.isActive){
        this.actioncolorchange = '#BFD7FF'
      } else {
        this.actioncolorchange = 'secondary'
      }
    }
  
}
