
<div class="tabButtonsMobile">
    <div class="everleadMobileTab">
        <div class="everleadMobileTab__valueWrapper">
            <select [(ngModel)]="section" class="selectTab__box">
                <option [ngValue]="1">{{'ProjectAPI.Label.General' | texttransform }}</option>
                <option [ngValue]="2">{{'ProjectAPI.Label.APIUser' | texttransform }}</option>
                <option [ngValue]="3">{{'ProjectAPI.Label.Downloads' | texttransform }}</option>
                <option [ngValue]="4" *ngIf="applicationInfoService.isDeveloper">{{'Devs: ApiUser anlegen' | texttransform }}</option>
            </select>
        </div>
    </div>
</div>

<div class="tabButtons">
    <app-scroll-tabs>
        <app-tab svg="calendar-dots" [active]="section == 1" label="ProjectAPI.Label.General" (onClick)="section = 1"></app-tab>
        <app-tab svg="calendar-dots" [active]="section == 2" label="ProjectAPI.Label.APIUser" (onClick)="section = 2"></app-tab>
        <app-tab svg="calendar-dots" [active]="section == 3" label="ProjectAPI.Label.Downloads" (onClick)="section = 3"></app-tab>
        <app-tab svg="calendar-dots" [active]="section == 4" label="Devs: ApiUser anlegen" (onClick)="section = 4" 
            *ngIf="applicationInfoService.isDeveloper"></app-tab>
    </app-scroll-tabs> 
</div>

<div *ngIf="section == 1" class="projectAPI__container">
    <div class="projectAPI__secretKey" *ngIf="secretKeyCreated">
        <label>{{ 'ProjectAPI.Label.SecretKeyInfo' | texttransform }}</label>
        <div>
            <label class="jjBold">{{ secretKey }}</label>
            <div (click)="copyToClipBoard()" class="projectAPI__footer">
                <i class="fas fa-copy fingerCursor"></i>{{ 'ProjectAPI.Label.CopyToClipBoard' | texttransform }}
            </div>
        </div>                
    </div>    
    <br>
    <app-main-button (onClick)="createSecretKey()" label="ProjectAPI.Label.CreateSecretKey"></app-main-button>    
</div>

<div *ngIf="section == 4" class="projectAPI__container">
    <app-text-input label="Contact.Label.FirstName" [(ngModel)]="apiUser.firstName"></app-text-input>
    <app-text-input label="Contact.Label.LastName" [(ngModel)]="apiUser.lastName"></app-text-input>
    <app-text-input label="Contact.Label.EMail" [(ngModel)]="apiUser.email"></app-text-input>
    <br>
    <app-main-button (onClick)="createApiUser()" label="API-User anlegen" [disabled]="checkApiUserReqs()"></app-main-button>
</div>
