import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { SDK } from '@ringcentral/sdk';

@Component({
  selector: 'app-ringcentral-webrtc-redirect',
  templateUrl: './ringcentral-webrtc-redirect.component.html',
  styleUrls: ['./ringcentral-webrtc-redirect.component.scss']
})
export class RingcentralWebrtcRedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private applicationInfoService: ApplicationInfoService,
  ) { }

  ngOnInit(): void {
    this.applicationInfoService.noAuthKilling = true;    
    this.route.queryParamMap.subscribe((params: any) => {
      const code = params['params']['code'];
      localStorage.setItem("ringCentralWebRTCAuthCode", code);
      window.self.close();
    });
  }
}
