import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { Subscription } from 'rxjs';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { isUndefined } from 'util';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export enum PopupDataType {
  Account = 'account',
  QM = 'qm'
}

@Component({
  selector: 'app-account-popup',
  templateUrl: './account-popup.component.html',
  styleUrls: ['./account-popup.component.scss']
})

export class AccountPopupComponent implements OnInit, OnDestroy {
  removeQMItemFromAccountFormArrayEventSubscription: Subscription = new Subscription();
  currentDataIndex = 0;
  assignMode = false;

  get PopupDataType() { return PopupDataType; }

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private methodService: MethodService,
    private eventService: EventService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.getCurrentDataIndex();
    this.applicationInfoService.miscSettings['accountPopupOpen'] = 'true';

    if (this.applicationInfoService.miscSettings['accountPopupAssignMode'] == 'true') {
      this.assignMode = true;
      this.applicationInfoService.miscSettings['accountPopupAssignMode'] == null;
    }

    this.eventService.entitySavedEvent.subscribe(event => {
        Object.getOwnPropertyNames(this.applicationInfoService.accountPopupDataArray[this.currentDataIndex]).forEach(item => {
          if (this.applicationInfoService.accountPopupDataType === PopupDataType.Account) {
            if (!isUndefined(event[item])) {
              this.applicationInfoService.accountPopupDataArray[this.currentDataIndex][item] = event[item];
            }
          }
        });
    });

    this.removeQMItemFromAccountFormArrayEventSubscription = this.eventService.removeQMItemFromAccountFormArrayEvent.subscribe(event => {
      if (this.applicationInfoService.accountPopupQMMode === true) {
        this.applicationInfoService.accountPopupDataArray.forEach((element, index) => {
          if (element.id === event) {
            this.applicationInfoService.accountPopupDataArray = this.commonService.removeItemFromArrayByIndex(
              this.applicationInfoService.accountPopupDataArray, index);
          }
        });
      }
      if (this.applicationInfoService.accountPopupDataArray.length < 1) {
        this.eventService.closeJjPopup();
      } else {
        this.currentDataIndex = 0;
        this.loaderService.display(true, false, 'removeQMItemFromAccountFormArrayEventSubscription');
        this.methodService.loadQMData(21, this.applicationInfoService.accountPopupDataArray[0])
        .then(() => {
          this.loaderService.display(false, false, 'removeQMItemFromAccountFormArrayEventSubscription');
        })
        .catch(error => {
          this.loaderService.display(false, false, 'removeQMItemFromAccountFormArrayEventSubscription');
        });
      }
    });
  }

  showNextData() {
    this.currentDataIndex += 1;
    this.showData(this.applicationInfoService.accountPopupDataArray[this.currentDataIndex]);
  }

  showFirstData() {
    this.currentDataIndex = 0;
    this.showData(this.applicationInfoService.accountPopupDataArray[this.currentDataIndex]);
  }

  showLastData() {
    this.currentDataIndex = this.applicationInfoService.accountPopupDataArray.length - 1;
    this.showData(this.applicationInfoService.accountPopupDataArray[this.currentDataIndex]);
  }

  showPreviousData() {
    this.currentDataIndex -= 1;
    this.showData(this.applicationInfoService.accountPopupDataArray[this.currentDataIndex]);
  }

  showData(data) {
    switch (this.applicationInfoService.accountPopupDataType) {
      case PopupDataType.Account:
        this.loaderService.display(true, false, 'AccountPopupComponent showData');
        this.projectService.getCurrentAccount(data.id)
        .then(() => {
          this.eventService.updateQuestionaireDataEvent.emit();
          this.eventService.updateControlAttributes();
          this.loaderService.display(false, false, 'AccountPopupComponent showData');
        })
        .catch(error => {
          this.messagingService.showDefaultError('', error, true);
          this.loaderService.display(false, false, 'AccountPopupComponent showData');
        });
        break;
      case PopupDataType.QM:
        this.loaderService.display(true, false, 'AccountPopupComponent showData');
        this.methodService.loadQMData(21, data)
        .then(() => {
          this.eventService.updateQuestionaireDataEvent.emit();
          this.eventService.updateSubResultsEvent.emit();
          this.eventService.customEvent.emit({ id: 'qmLoaded' });
          this.loaderService.display(false, false, 'AccountPopupComponent showData');
        })
        .catch(error => {
          this.messagingService.showDefaultError('', error, true);
          this.loaderService.display(false, false, 'AccountPopupComponent showData');
        });
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.applicationInfoService.additionalMenuBarControlId = 0;
    this.applicationInfoService.miscSettings['accountPopupOpen'] = null;
    this.applicationInfoService.accountPopupQMMode = false;
    if (this.removeQMItemFromAccountFormArrayEventSubscription) { this.removeQMItemFromAccountFormArrayEventSubscription.unsubscribe(); }
  }

  assignAccount() {
    let contactId = this.applicationInfoService.miscSettings['convertProspectAndAssignContactId'];
    if (isNaN(contactId)) {
      contactId = null;
    }
    this.methodService.launchInternalMethod(InternalMethodType.AssignAccountToContact, [
      this.applicationInfoService.currentAccount.id,
      'loadContact',
      contactId
    ])
      .then(() => {
        this.loaderService.display(false);
        this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
        this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
    });
  }

  getCurrentDataIndex() {
    this.applicationInfoService.accountPopupDataArray.forEach((dataItem, index) => {
      if (dataItem.id === this.applicationInfoService.accountPopupDataId) {
        this.currentDataIndex = index;
        return;
      }
    });
  }

  updateAndBackToMyLeads() {
    this.eventService.updateAndBackToMyLeadsEvent.emit(this.applicationInfoService.accountPopupDataArray);
    this.eventService.closeJjPopup();
  }

  loadAccount() {
    this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [this.applicationInfoService.accountPopupDataArray[this.currentDataIndex].id])
    .then(() => this.eventService.closeJjPopup() );
  }
}
