<div #parentFieldSelector class="everleadTextField">
    <label class="everleadTextField__label">
        {{ label | texttransform }}
    </label>

    <div class="everleadTextField__value" #selectedIcon>
        <div *ngIf="selectedValue">
            <app-jj-icons [type]="selectedValue" [width]="20" [height]="20"></app-jj-icons>
            {{ selectedValue | texttransform }}
        </div>
        <div *ngIf="!selectedValue">{{ placeholder | texttransform }}</div>
        <app-jj-icons [type]=" isOpen ? 'caret-up' : 'caret-down'" [width]="16" [height]="16" (click)="handleArrow()"></app-jj-icons>
    </div>

    <div #iconsList class="tippyIconSelector"[style.width.px]="parentWidth" [style.min-width.px]="parentWidth">
        <div  class="tippyIconSelector__value" *ngFor="let icon of library; let index = i" (click)="handleClick(icon)" [class.isSelected]="icon.name === selectedValue">
            <app-jj-icons *ngIf="icon?.type !== 'placeholder'" [type]="icon.name" [width]="25" [height]="25"></app-jj-icons>
            <label>{{ icon.name }}</label>
        </div>
    </div>
</div>
