<div class="websiteDashboard__wrapper">



    <div class="websiteDashboard__topRow">
        <div class="websiteDashboard__topRow__item websiteDashboard__topRow__item__left">
            <app-flex-dashboard type="myvisitors"></app-flex-dashboard>
        </div>
        <div class="websiteDashboard__topRow__item websiteDashboard__topRow__item__right">
            <app-flex-dashboard type="myconversions"></app-flex-dashboard>
        </div>
    </div>
    <div class="websiteDashboard__bottomRow">
        <div class="maxHeight">
            <div [innerHTML]="innerHTML"></div>
        </div>
    </div>
</div>

