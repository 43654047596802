<div class="echobotConfirm">
<div class="echobotConfirm__message">
    <app-jj-icons type="coins" width="60" height="60"></app-jj-icons>
            {{ item.message | texttransform }}
</div>

<div class="echobotConfirm__buttons">
    <app-main-button svg="x"  label="General.Label.No" (onClick)="reject()"></app-main-button>
    <app-main-button svg="check"  label="General.Label.Yes" (onClick)="confirm()"></app-main-button>

</div>
</div>
