import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-dashdashboardcomponent-updates',
  templateUrl: './dashdashboardcomponent-updates.component.html',
  styleUrl: './dashdashboardcomponent-updates.component.scss'
})
export class DashdashboardcomponentUpdatesComponent implements OnInit{

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private methodService: MethodService,
    private applicationInfoService: ApplicationInfoService,
    public commonService: CommonService

  ){}

  page:number = 0
  projectId

  webleads: null | any =[]
  visitors:null | any = []
  visitorOptions = [
    { id: 1, displayName: 'Dashboards.Label.AllVisitors' },
    { id: 2, displayName: 'Dashboards.Label.VIPs'}
  ]
  selectorVip = 1;
  visitorsLoading: boolean = false;
  webleadsLoading: boolean = false;
  visitorsNoData : boolean = false;
  webleadsNoData: boolean = false;
  visitorsArray = null;


  ngOnInit(){
    this.getData()
  }


  getData(){
   this.getVisitor()
   this.getWeblead()
  }



  getVisitor(){
    this.visitorsLoading=true;
    let colfilter = [
      { "columnName" : "isBlacklisted", "value": "false"}
    ];
    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true') {
      colfilter = [
        { "columnName" : "isBlacklisted", "value": "false"},
        { "columnName" : "isisp", "value": "false"},
        { "columnName" : "poolId", "value": this.applicationInfoService.poolId.toString() }
      ];
    }
    let vips = null;
    if (this.selectorVip == 2) {
      vips = true;
    }
    const tableSettings = {
      definitionId: 391,
      orderBy: { columnName : "lastVisited", orderByOperator: 'DESC_NULLS_LAST' },
      filterId: null,
      columnFilter: colfilter,
      pageSize: 20,
      pageNumber: 1,
      withVIP: null,
      vipViewName: "view_user_vip_list"
    }

    this.externaldatasourceService.executeExternalDataSource(950,[
      tableSettings.definitionId,
      tableSettings.pageSize,
      tableSettings.pageNumber,
      this.mapFilterArray(tableSettings.columnFilter),
      this.mapFilterArray(tableSettings.orderBy),
      '',
      tableSettings.filterId,
      tableSettings.vipViewName,
      vips
    ]).then((visitorsResult)=> {
      // console.log('VISITORS: ', visitorsResult) 
      this.visitorsLoading = false
      this.visitors = visitorsResult


      // todo if needed 
/*       console.log('this.visitors', this.visitors)
      let array = this.visitors.jsonData.map((item) => JSON.parse(item))
      this.visitorsArray= this.commonService.cleanKeysfromEntityTable(array);
      console.log('visiotrsArray', this.visitorsArray); */


      if(this.visitors?.jsonData?.length === 0){
        this.visitorsNoData = true;
      } else {
        this.visitorsNoData = false;
      }

    }).catch((error) => {
      this.visitorsLoading= false;
      this.visitorsNoData= true;
      console.log('error', error);
    });
  }

  getWeblead(){
    this.webleadsLoading= true

    const tableSettings = {
      definitionId: 392,
      orderBy: { columnName : "date", orderByOperator: 'DESC_NULLS_LAST'},
      filterId: null,
      columnFilter: [{ columnName : 'poolId', "value": this.applicationInfoService.poolId.toString() }],
      pageSize: 20,
      pageNumber: 1,
      withVIP: null,
      vipViewName: "view_user_vip_list"
    }

    
    this.externaldatasourceService.executeExternalDataSource(771,[
      tableSettings.definitionId,
      tableSettings.pageSize,
      tableSettings.pageNumber,
      this.mapFilterArray(tableSettings.columnFilter),
      this.mapFilterArray(tableSettings.orderBy),
      '',
      tableSettings.filterId,
    ]).then((webleadResult)=>{
      this.webleadsLoading=false;
      this.webleads = webleadResult;
      if(this.webleads?.jsonData?.length === 0){
        this.webleadsNoData=true
      }

    }).catch((error) => {
       this.webleadsLoading=false;
       this.webleadsNoData= true;
       console.log('error', error)
    })
  }




  mapFilterArray(filterArray){
    let filterArrayJson = JSON.stringify(filterArray);
    Object.keys(filterArray).forEach(() =>  {      
      filterArrayJson = filterArrayJson.replace('"'.concat('columnName', '"'), 'columnName');
      filterArrayJson = filterArrayJson.replace('"'.concat('value', '"'), 'value');
      filterArrayJson = filterArrayJson.replace('"'.concat('operator', '"'), 'operator');
      filterArrayJson = filterArrayJson.replace('"'.concat('orderByOperator', '"'), 'orderByOperator');
      filterArrayJson = filterArrayJson.replace('"'.concat('negate', '"'), 'negate');
      filterArrayJson = filterArrayJson.replace('"'.concat('DESC_NULLS_LAST', '"'), 'DESC_NULLS_LAST');
      filterArrayJson = filterArrayJson.replace('"'.concat('ASC_NULLS_LAST', '"'), 'ASC_NULLS_LAST');
    });

    //console.log('filterArrayJson', filterArrayJson);
    return filterArrayJson;
  }


  getLabelPage(){
    if(this.page === 0){
      return 'Dashboard.Label.UpdatePage0';
    }

    if(this.page === 1){
      return 'Dashboard.Label.UpdatePage1';
    }
    
    if(this.page === 2){
      return 'Dashboard.Label.UpdatePage2';
    }
  }

  loadSection() {
    switch (this.page) {
      case 0:
        this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['prospects', 'prospects']);
        break;
      case 1:
        this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['conversions', 'conversions']);
        break;
    }
  }  
}
