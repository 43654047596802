import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-quicksearch',
  templateUrl: './quicksearch.component.html',
  styleUrls: ['./quicksearch.component.scss']
})
export class QuicksearchComponent implements OnInit {
  searchResults = null;
  taskResults = [];
  tooManyTasks = false;
  resultType = null;
  filterString = '';
  searching = false;
  allFoundItems = 0;
  tooManyResults = false;
  filterActive = false;
  accountStrings = [];
  contactStrings = [];
  accountValues = null;
  contactValues = null;
  bannedValues = [];
  boldValues = [];
  highlightSearchResults = true;
  searchTab = 0;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private uiService: UiService,
    public commonService: CommonService,
    private methodService: MethodService,
    private eventService: EventService,
    private projectService: ProjectService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit() {
    // this.searchForValue();
    this.applicationInfoService.quickSearchValue = '';
    
    this.bannedValues = this.applicationInfoService.applicationSettings['quickSearchBannedValues'].split(';');
    this.accountValues = this.applicationInfoService.applicationSettings['quickSearchAccountValue'].split(';');
    this.contactValues = this.applicationInfoService.applicationSettings['quickSearchContactValue'].split(';');
    this.boldValues = this.applicationInfoService.applicationSettings['quickSearchBoldValue'].split(';');    
    const element = document.getElementById('quickSearchInput');
    if (element) { 
      element.focus();
    }
    if (this.applicationInfoService.quickSearchValue != '') {
      this.searchForValue();
    }

    this.eventService.customEvent.emit({ id: 'changePopupSubHeader', value: 'QuickSearch.Label.QuickSearchDescription' });
  }

  handleQuickSearchKeyDown(event: any) {
    if (event.keyCode === 13 || event.keyCode === 14) {
      this.searchForValue();
    }
  }

  clearSearchValues() {
    this.accountStrings = [];
    this.contactStrings = [];  
    this.taskResults = [];
    this.searchResults = null;
    this.allFoundItems = 0;
    this.tooManyResults = false;
    this.resultType = 0;
    this.tooManyTasks = false;
  }

  searchForValue() {
    this.searching = true;
    this.clearSearchValues();
    //this.loaderService.display(true);
    let searchValue = this.commonService.cleanStringValue(this.applicationInfoService.quickSearchValue).trim();
    let poolId = this.applicationInfoService.poolId;
    if (this.applicationInfoService.quickSearchAllPools == true) { poolId = null; }

    this.externaldatasourceService.executeExternalDataSource(880, [
      searchValue, 
      poolId,
      this.applicationInfoService.applicationSettings['quickSearchAccountValue'],
      this.applicationInfoService.applicationSettings['quickSearchContactValue'],
      this.applicationInfoService.applicationSettings['quickSearchDocumentValue']
    ])
    .then(quickSearchResult => {      
      //this.loaderService.display(false);
      this.searchResults = JSON.parse(quickSearchResult.resultJson);
      this.searchResults.accounts.forEach(account => { this.getAccountText(account); });
      this.searchResults.contacts.forEach(contact => { this.getContactText(contact); });
      this.searchResults['num_all'] = this.searchResults.num_accounts + this.searchResults.num_contacts
      this.searching = false;
    })
    .catch(() => { 
      this.searching = false; 
      //this.loaderService.display(false); 
    });
  }

  getAccountText(account) { 
    this.accountStrings[account.id] = {
      accountText: this.getCleanValue(account, this.accountValues)
    };
  }

  getContactText(contact) {
    this.contactStrings[contact.id] = {
      contactText: this.getCleanValue(contact, this.contactValues),
      accountText: this.getCleanValue(contact, this.accountValues, 'account.'),
    };
  }


  getQuickSearchTabLabel(nameString, value) {
     let baseString = this.axivasTranslateService.getTranslationTextForToken('QuickSearch.Label.'.concat(nameString));
    //let baseString = '';

    if (nameString == 'ResultAll') {
      baseString = this.axivasTranslateService.getTranslationTextForToken('QuickSearch.Label.'.concat(nameString)) ;
    }
    if (this.searchResults == null) {
      //baseString = baseString.concat(' ' + '0');
      baseString = '0' + ' ' + baseString

    } else {
      baseString = this.searchResults[value] + ' ' + baseString
/*       baseString = baseString.concat(' ' + this.searchResults[value]); */
    }    
    return baseString;
  }

  getCleanValue(baseArray, valueArray, section = '') {
    let returnValue = '';
    valueArray.forEach(value => {
      if (!this.commonService.checkIfItemIsInArray(this.bannedValues, value)) { 
        let tempValue = baseArray[section.concat(this.commonService.camelToSnakeCase(value))];
        if (this.commonService.isNullOrUndefined(tempValue)) { tempValue = ''; };        
        if (this.commonService.checkIfItemIsInArray(this.boldValues, value)) {
          returnValue = returnValue.concat('<b>', tempValue, '</b> '); 
        } else {
          returnValue = returnValue.concat(tempValue, ' '); 
        }                
      };
    });
    return returnValue;
  }


  changeResultType(resultType: any) {
    this.resultType = resultType;
  }

  checkIfItemCorrespondsFilter(array, type) {
    let baseString = '';
    switch(type) {
      case 1:
        baseString = this.accountStrings[array.id].accountText;
        break;
      case 2:
        baseString = this.contactStrings[array.id].accountText + this.contactStrings[array.id].contactText;
        break;
    }
    baseString = baseString.replace(/<b>/g, '');
    baseString = baseString.replace(/<\/b>/g, '');
    baseString = baseString.replace(/<span class="quickSearchSelectionFound">/g, '');
    baseString = baseString.replace(/<\/span>/g, '');
    let returnValue = false;
    if (this.commonService.checkIfStringContainsString(baseString.toString(), this.filterString)) {
      returnValue = true;
    }    
    return returnValue;
  }
}
