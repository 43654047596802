<div class="wizardSubheader__wrapper dataImportAssignment__container">
  <div class="wizardSubheader">
    <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name |
      texttransform }}</label>
    <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc |
      texttransform }}</label>
  </div>
  <div class="wizardPageContent maxHeight">
    <!-- ! upload file -->
    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1">
      <div *ngIf="uploadingFile==false">
        <input type="file" #file accept=".xlsx" placeholder="Choose file" (change)="uploadFile($event)"
          style="display:none;">
        <button type="button" class="jjButtonColor jjButtonStyle fingerCursor"
          (click)="file.click()">{{'Import.Label.UploadFile' | texttransform}}</button>
      </div>

      <div *ngIf="uploadingFile">
        {{'Import.Label.UploadingFilePleaseWait' | texttransform}}
      </div>
      <div *ngIf="uploadingFileError">
        <br><br>
        {{'Import.Label.UploadingFileError' | texttransform}}
      </div>

    </div>

    <!-- ! data sheet and pool -->
    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
      <div class="dataImportAssignment__uploadFileFirst" *ngIf="!wizardService.wizardArray.selectedSheet">
        {{'Import.Label.PleadUploadFileFirst' | texttransform}}
      </div>
      <div *ngIf="wizardService.wizardArray.selectedSheet" class="maxHeight">
        <div class="wizardDetailItem">
          <label class="noPadding wizardLabel">{{'Import.Label.DataPool' | texttransform}}</label>
          <select [(ngModel)]="wizardService.wizardArray.poolId" class="wizardDropDown" (click)="suggestValues()">
            <option *ngFor="let pool of pools" [ngValue]="pool.id">
                {{ pool.defaultName }}
            </option>
          </select>
          <i class="fas fa-plus fingerCursor" (click)="createPool()"></i>
        </div>

        <div class="wizardDetailItem">
          <label class="noPadding wizardLabel">{{'Import.Label.DataSheet' | texttransform}}</label>
          <select [(ngModel)]="wizardService.wizardArray.selectedSheet" (click)="suggestValues()" class="wizardDropDown">
            <option *ngFor="let sheet of sheets" [ngValue]="sheet">
                {{ sheet.name }}
            </option>
          </select>
        </div>
        <label class="dataImportAssignment__header__checkList">{{'Import.Label.ExampleData' | texttransform}}</label>
        <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.selectedSheet" class="dataImportAssignment__exampleData">          
          <app-wizard-dataimport-datalist></app-wizard-dataimport-datalist>
        </div>
      </div>
    </div>


    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 5" class="maxHeight">
      <div class="dataImportAssignment__uploadFileFirst" *ngIf="!wizardService.wizardArray.selectedSheet">
        {{'Import.Label.PleadUploadFileFirst' | texttransform}}
      </div>
      <div class="dataImportAssignment__wrapper" *ngIf="wizardService.wizardArray.selectedSheet">
        <div class="dataImportAssignmentKeyItem__assigned">
          {{'DataImport.Label.KeyValueVisibility' | texttransform}}
        </div>
        <br>
        <div class="maxHeight">
          <div *ngFor="let header of wizardService.wizardArray.selectedSheet.data.headers; let i = index" class="dataImportAssignmentKeyItem everleadTableRow"
          [ngClass]="{ hideInDom: header.member == null || header.colHasEmptyValue == true }" (click)="header.isKeyMember = !header.isKeyMember">
            <div class="dataImportAssignmentKeyItem__icon">
              <svg-icon *ngIf="header.entity == 1" src="assets/images/svg/iconset/05icon-adressen.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
              <svg-icon *ngIf="header.entity == 4" src="assets/images/svg/iconset/06icon-kontakte.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            </div>
            <div class="dataImportAssignmentKeyItem__icon">
              <button [disabled]="header.member == null" class="nudeButton">
                <i class="fas fa-key fa-lg fingerCursor" [ngClass]="{everleadCoral: header.isKeyMember}" 
                [tippy]="'Import.Label.KeyValueForImport' | texttransform" [tippyOptions]="{placement:'bottom'}"></i>
              </button>              
            </div>
            <div class="dataImportAssignmentKeyItem__content">
              <div class="dataImportAssignmentKeyItem__header">
                <label>{{ header.name }} </label>                
              </div>
              <div class="dataImportAssignmentKeyItem__assigned">
                <label>{{'Import.Label.AsignedToDatabaseFieldInfo' | texttransform}}: <label class="jjBold">{{ getMemberName(header) | texttransform}}</label></label>
                <label><i class="fas fa-lg fingerCursor " 
                  (click)="header.showDetails = !header.showDetails;$event.stopPropagation()" style="margin-left: 5px;" [ngClass]="{
                  'fa-chevron-down': header.showDetails == false,
                  'fa-chevron-up': header.showDetails
                }"></i></label>
              </div>
              <div *ngIf="header.showDetails" class="dataImportAssignmentKeyItem__exampleData">
                <label>{{'Import.Label.ExampleData' | texttransform}}:</label>
                <div *ngFor="let dataItem of this.wizardService.wizardArray.selectedSheet.data.data; let i = index" [ngClass]="{ hideInDom: i > 5 }">
                  {{ dataItem[header.dataOrder] }}
                </div>
              </div>  
            </div>
          </div>  
        </div>
      </div>
    </div>

 <!-- !! assignments -->
    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 3" class="maxHeight">
      <div class="dataImportAssignment__uploadFileFirst" *ngIf="!wizardService.wizardArray.selectedSheet">
        {{'Import.Label.PleadUploadFileFirst' | texttransform}}
      </div>
      <div class="dataImportAssignment__wrapper" *ngIf="wizardService.wizardArray.selectedSheet">
        <div class="dataImportAssignment__header">
          <div  class="dataImportAssignment__header__section">
            <div class="dataImportAssignment__header__checkList">{{'Import.Label.Checklist' | texttransform}}</div>
            <div class="dataImportAssignment__header__item">
              <div class="dataImportAssignment__header__item__check"><i class="fas fa-check everleadCoral" *ngIf="checkIfAccountKeyValuesAreSet().length > 0"></i></div>            
              {{'Import.Label.AccountKeyInfo' | texttransform}}<app-wizard-infobutton displayText="Import.Label.AccountKeyInfoDesc"></app-wizard-infobutton>
            </div>
            <div class="dataImportAssignment__header__item">
              <div class="dataImportAssignment__header__item__check"><i class="fas fa-check everleadCoral" *ngIf="checkIfAccountNameIsAssigned().length > 0"></i></div>            
              {{'Import.Label.AccountName1Assigned' | texttransform}}<app-wizard-infobutton displayText="Import.Label.AccountName1AssignedDesc"></app-wizard-infobutton>
            </div>
            <div class="dataImportAssignment__header__item" *ngIf="checkIfContactIsAssigned().length > 0">
              <div class="dataImportAssignment__header__item__check"><i class="fas fa-check everleadCoral" *ngIf="checkIfContactKeyValuesAreSet().length > 0"></i></div>            
              {{'Import.Label.ContactKeyInfo' | texttransform}}<app-wizard-infobutton displayText="Import.Label.ContactKeyInfoDesc"></app-wizard-infobutton>
            </div>  
            <div class="dataImportAssignment__header__item">
              <div class="dataImportAssignment__header__item__check"><i class="fas fa-check everleadCoral" *ngIf="checkForDirtyRows().length == 0"></i></div>            
              {{'Import.Label.NoDirtyRows' | texttransform}}<app-wizard-infobutton displayText="Import.Label.NoDirtyRowsDesc"></app-wizard-infobutton>
            </div>  
          </div>

          <div  class="dataImportAssignment__header__section">
            <div class="dataImportAssignment__header__checkList">{{'Import.Label.Assignments' | texttransform}}</div>
            <div class="dataAssignmentButton" (click)="suggestValues()">
              <i class="fas fa-lightbulb"></i>
              {{'Import.Label.AssignmentsSuggest' | texttransform}}
              <app-wizard-infobutton displayText="Import.Label.AssignmentsSuggestDesc"></app-wizard-infobutton>
            </div>
            <div class="dataAssignmentButton" (click)="saveAssignment()">
              <i class="fas fa-save"></i>
              {{'Import.Label.AssignmentsSave' | texttransform}}
              <app-wizard-infobutton displayText="Import.Label.AssignmentsSaveDesc"></app-wizard-infobutton>
            </div>
            <div class="dataAssignmentButton" (click)="loadAssignment()">
              <i class="fas fa-share-square"></i>
              {{'Import.Label.AssignmentsLoad' | texttransform}}
              <app-wizard-infobutton displayText="Import.Label.AssignmentsLoadDesc"></app-wizard-infobutton>
            </div>
          </div>

          <div  class="dataImportAssignment__header__section" *ngIf="wizardService.wizardArray.selectedTemplate">
            <div class="dataImportAssignment__header__checkList">{{'Import.Label.SelectedTemplate' | texttransform}}</div>
            <div>
              {{ wizardService.wizardArray.selectedTemplate?.name }}
            </div>
            <div class="dataAssignmentButton" (click)="updateTemplateContent()">
              <i class="fas fa-sync"></i>
              {{'Import.Label.UpdateAssignments' | texttransform}}
              <app-wizard-infobutton displayText="Import.Label.UpdateAssignmentsDesc"></app-wizard-infobutton>
            </div>
          </div>
        </div>

        <div class="dataImportAssignment__header__checkList" *ngIf="wizardService.wizardArray.selectedSheet">
          {{'Import.Label.DataFields' | texttransform}}
        </div>
        <div class="dataImportAssignment__body" *ngIf="wizardService.wizardArray.selectedSheet">          
          <div *ngFor="let header of wizardService.wizardArray.selectedSheet.data.headers; let i = index" 
            class="dataImportAssignment__body__itemWrapper everleadTableRow">
            <div class="dataImportAssignment__body__sheetItem">
              <div class="dataImportAssignment__body__sheetItem__entityType">
                <app-jj-icons [type]="'user'" *ngIf="header.entity == 4" [color]="getColor(header)" [width]="28" [height]="28" [pointer]="false"></app-jj-icons>
                <app-jj-icons [type]="'building'" *ngIf="header.entity == 1" [color]="getColor(header)" [width]="28" [height]="28" [pointer]="false"></app-jj-icons>
              </div>
              <div class="dataImportAssignment__body__sheetItem__name">
                <div [ngClass]="{ isDirty: checkIfDirty(header) }" [tippy]="getItemMouseover(header, i)" [tippyOptions]="{placement:'bottom'}">{{header.name}}</div>
              </div>

              <div class="dataImportAssignment__body__sheetItem__addLookups" *ngIf="header.lookupTableName">
                <input type="checkbox" [(ngModel)]="header.isCreateNewLookupValues">
                <label>{{'Import.Label.CreateLookupValues' | texttransform}}</label>
                <!-- <app-jj-icons [type]="'info'" [width]="28" [height]="28" [tippy]="'Import.Label.CreateLookupValuesDescription' | texttransform"></app-jj-icons>
                <app-wizard-infobutton displayText="Import.Label.CreateLookupValuesDescription"></app-wizard-infobutton> -->
              </div>

              <div class="dataImportAssignment__body__sheetItem__key">
                <!-- <button [disabled]="header.member == null" class="nudeButton" (click)="header.isKeyMember = !header.isKeyMember">
                  <i class="fas fa-key fingerCursor" [ngClass]="{everleadCoral: header.isKeyMember}" 
                  [title]="'Import.Label.KeyValueForImport' | texttransform"></i>
                </button> -->
                <app-jj-icons [type]="'prohibit'" [width]="28" [height]="28" (click)="header.skipRecordIfEmpty = !header.skipRecordIfEmpty"></app-jj-icons>
                <!-- <button [disabled]="header.member == null || header.isKeyMember == true" class="nudeButton" 
                  (click)="header.skipRecordIfEmpty = !header.skipRecordIfEmpty">
                  <i class="fas fa-ban fingerCursor" [ngClass]="{everleadCoral: header.skipRecordIfEmpty}"
                  [tippy]="'Import.Label.SkipRecordIfEmpty' | texttransform"  [tippyOptions]="{placement:'bottom'}"></i>                  
                </button> -->
                <app-jj-icons [type]="'copy'" [width]="28" [height]="28" *ngIf="header.isDoublicate != true" (click)="cloneHeader(header)"></app-jj-icons>
                <!-- <button *ngIf="header.isDoublicate != true" class="nudeButton" (click)="cloneHeader(header)">
                  <i class="fas fa-clone fingerCursor"
                  [tippy]="'Import.Label.DuplicateHeader' | texttransform"  [tippyOptions]="{placement:'bottom'}"></i>
                </button> -->
                <app-jj-icons [type]="'trash'" [width]="28" [height]="28" *ngIf="header.isDoublicate == true" (click)="removeHeader(header)"></app-jj-icons>
                <!-- <button *ngIf="header.isDoublicate == true" class="nudeButton" (click)="removeHeader(header)">
                  <i class="fas fa-trash fingerCursor"
                  [tippy]="'Import.Label.RemoveHeader' | texttransform" [tippyOptions]="{placement:'bottom'}"></i>
                </button> -->
              </div>
              <div class="dataImportAssignment__body__sheetItem__entity">
                <app-jj-icons [type]="'building'" [width]="28" [height]="28" (click)="setEntity(header, 1)" *ngIf="header.entity == 1"></app-jj-icons>
                <app-jj-icons [type]="'building'" [width]="28" [height]="28" (click)="setEntity(header, 1)" *ngIf="header.entity != 1" color="gray0"></app-jj-icons>
                <app-jj-icons [type]="'user'" [width]="28" [height]="28" (click)="setEntity(header, 4)" *ngIf="header.entity == 4"></app-jj-icons>
                <app-jj-icons [type]="'user'" [width]="28" [height]="28" (click)="setEntity(header, 4)" *ngIf="header.entity != 4" color="gray0"></app-jj-icons>
                <app-jj-icons [type]="'x'" [width]="28" [height]="28" (click)="setEntity(header, null)"></app-jj-icons>                                
              </div>
              <div class="dataImportAssignment__body__sheetItem__member">
                <div *ngIf="header.entity == null" >
                  {{'Import.Label.DontImport' | texttransform}}
                </div>
                <div *ngIf="header.member == -1" class="maxWidth">
                  {{ header.memberName }}
                </div>
                <select id="accountmember" [(ngModel)]="header.member" *ngIf="header.entity == 1" (change)="enrichHeader(header)">
                  <option [ngValue]=null></option>
                  <option [ngValue]="member.id" *ngFor="let member of accountMember" class="everleadCapitalizeValue"
                    [ngClass]="{ hideInDom: targetTypeNotMatching(header, member) }">
                      {{ getMemberName2(member) | texttransform }}
                  </option>
                </select>
                <select id="contactmember" [(ngModel)]="header.member" *ngIf="header.entity == 4" (change)="enrichHeader(header)">
                  <option [ngValue]="null"></option>
                  <option [ngValue]="member.id" *ngFor="let member of contactMember" class="everleadCapitalizeValue"
                    [ngClass]="{ hideInDom: targetTypeNotMatching(header, member) }">
                      {{ getMemberName2(member) | texttransform }}
                  </option>
                </select>

                <app-jj-icons [type]="'plus-circle'" *ngIf="applicationInfoService.isDeveloper && header.entity != null" [width]="28" [height]="28"
                  (click)="setMember(header, -1)"></app-jj-icons> 
              </div>             
            </div>


            <!-- Neuer Member / Lookup table-->
            <div *ngIf="header.member == -1 || memberHasLookup(header.member)" class="dataImportAssignment__body__sheetItem__newMember">
              <div class="dataImportAssignment__body__sheetItem__newMember__line" *ngIf="header.member == -1">
                <label>{{'Import.Label.MemberName' | texttransform }}</label>
                <input id="newMemberName" [(ngModel)]="header.memberName" class="toLowercase" (change)="checkMemberName(header)">
              </div>
              <div class="dataImportAssignment__body__sheetItem__newMember__line" *ngIf="header.member == -1">              
                <label>{{'Import.Label.DataType' | texttransform }}</label>
                <select id="newMemberDataType" [(ngModel)]="header.type" [disabled]="header.lookupTableName != null">
                  <option ngValue='int'>{{'Import.Label.Integer' | texttransform }}</option>
                  <option ngValue='string'>{{'Import.Label.Text' | texttransform }}</option>
                  <option ngValue='date'>{{'Import.Label.Date' | texttransform }}</option>
                </select>
              </div>
              <div class="dataImportAssignment__body__sheetItem__newMember__line">                
                <label>{{'Import.Label.LookupTable' | texttransform }}</label>
                <select id="newMemberLookupTable" [(ngModel)]="header.lookupTableName" (change)="resetHeaderIfRequired(header)">
                  <option [ngValue]="null"></option>
                  <option [ngValue]="lookupTable.definitionName" *ngFor="let lookupTable of lookupTables['data']">
                    {{ lookupTable.translation | texttransform }}
                    <!-- <span *ngIf="lookupTable.nameTranslationToken">{{ lookupTable.nameTranslationToken.tokenFullName | texttransform }}</span>
                    <span *ngIf="!lookupTable.nameTranslationToken">{{ lookupTable.definitionName | texttransform }}</span> -->
                  </option>
                </select>
                <div class="dataImportAssignment__body__sheetItem__newMember__line__newLoopup">
                  <i class="fas fa-plus fingerCursor" title="Neue Lookuptable anlegen" (click)="createNewLookupTable(header)"></i>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 4" class="maxHeight">
      <div class="dataImportAssignment__uploadFileFirst" *ngIf="!wizardService.wizardArray.selectedSheet">
        {{'Import.Label.PleadUploadFileFirst' | texttransform}}
      </div>
      <div class="dataImportAssignment__importData" *ngIf="wizardService.wizardArray.selectedSheet">
        <div class="dataImportAssignment__header__checkList">{{'Import.Label.FinalChecklist' | texttransform}}</div>
        <div class="dataImportAssignment__header__itemContainer">
          <div class="dataImportAssignment__header__item">
            <div class="dataImportAssignment__header__item__check">
              <i class="fas fa-check everleadCoral" *ngIf="checkIfAccountKeyValuesAreSet().length > 0"></i>
              <i class="fas fa-ban everleadCoral" *ngIf="checkIfAccountKeyValuesAreSet().length == 0"></i>
            </div>            
            {{'Import.Label.AccountKeyInfo' | texttransform}}<app-wizard-infobutton displayText="Import.Label.AccountKeyInfoDesc"></app-wizard-infobutton>
          </div>
          <div class="dataImportAssignment__header__item">
            <div class="dataImportAssignment__header__item__check">
              <i class="fas fa-check everleadCoral" *ngIf="checkIfAccountNameIsAssigned().length > 0"></i>
              <i class="fas fa-ban everleadCoral" *ngIf="checkIfAccountNameIsAssigned().length == 0"></i>
            </div>            
            {{'Import.Label.AccountName1Assigned' | texttransform}}<app-wizard-infobutton displayText="Import.Label.AccountName1AssignedDesc"></app-wizard-infobutton>
          </div>
          <div class="dataImportAssignment__header__item" *ngIf="checkIfContactIsAssigned().length > 0">
            <div class="dataImportAssignment__header__item__check">
              <i class="fas fa-check everleadCoral" *ngIf="checkIfContactKeyValuesAreSet().length > 0"></i>
              <i class="fas fa-ban everleadCoral" *ngIf="checkIfContactKeyValuesAreSet().length == 0"></i>
            </div>            
            {{'Import.Label.ContactKeyInfo' | texttransform}}<app-wizard-infobutton displayText="Import.Label.ContactKeyInfoDesc"></app-wizard-infobutton>
          </div>  
          <div class="dataImportAssignment__header__item">
            <div class="dataImportAssignment__header__item__check">
              <i class="fas fa-check everleadCoral" *ngIf="checkForDirtyRows().length == 0"></i>
              <i class="fas fa-ban everleadCoral" *ngIf="checkForDirtyRows().length > 0"></i>
            </div>
            {{'Import.Label.NoDirtyRows' | texttransform}}<app-wizard-infobutton displayText="Import.Label.NoDirtyRowsDesc"></app-wizard-infobutton>
          </div>  
          <div class="dataImportAssignment__header__item">
            <div class="dataImportAssignment__header__item__check">
              <i class="fas fa-check everleadCoral" *ngIf="wizardService.wizardArray.poolId != null"></i>
              <i class="fas fa-ban everleadCoral" *ngIf="wizardService.wizardArray.poolId == null"></i>
            </div>            
            {{'Import.Label.PoolSelected' | texttransform}}
          </div>          
          <div class="everleadCoral fontSmall" *ngIf="checkNewMembers().length > 0">
            {{'Import.Label.NewMemberInfo' | texttransform}}
          </div>
        </div>

        <div class="dataImportAssignment__additionalImportSettings">          
          <input [(ngModel)]="isSkipImportOnError" type="checkbox">
          <label>{{'Import.Label.IsSkipImportOnError' | texttransform}}</label>
        </div>
        <div class="dataImportAssignment__additionalImportSettings">          
          <input [(ngModel)]="isCheckIfEmailExists" type="checkbox">
          <label>{{'Import.Label.IsCheckIfEmailExists' | texttransform}}</label>
        </div>
        <br>
        <button class="jjButtonColor jjButtonStyle fingerCursor" [disabled]="!checkIfImportCanBeStarted() || importCurrentlyRunning"
          (click)="importSheet()">
          {{'DataImport.Label.Page4' | texttransform}}
        </button>

        <button class="jjButtonColor jjButtonStyle fingerCursor" *ngIf="applicationInfoService.isDeveloper" (click)="importSheet(false, true)">
          {{ 'Import.Label.ToTempTable' | texttransform }}
        </button>
        <!-- <br>
        <br>
        <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="importSheet(true)">Doublettencheck</button>   -->
      </div>
    </div>
  </div>











  <div class="dataPopup__background" *ngIf="dataPopupOpen"></div>
  <div class="dataPopup " [ngClass]="{
    dataPopup__load: templateMode == 2,
    dataPopup__create: templateMode == 1
  }" *ngIf="dataPopupOpen">
    <div class="dataPopup__header">
      <label class="ellipsisText" *ngIf="templateMode == 1 || templateMode == 2">{{'Import.Label.Template' | texttransform}}</label>
      <label class="ellipsisText" *ngIf="templateMode == 3">{{'Import.Label.NewLookupTable' | texttransform}}</label>
      <label class="ellipsisText" *ngIf="templateMode == 4">{{'Import.Label.NewPool' | texttransform}}</label>
      <label class="ellipsisText" *ngIf="templateMode == 5">{{'Import.Label.DataImportResult' | texttransform}}</label>
      <i class="fas fa-close fingerCursor" (click)="dataPopupOpen = false"></i>
    </div>
    <div class="dataPopup__wrapper" *ngIf="templateMode == 1">
      <label class="ellipsisText">{{'Import.Label.TemplateName' | texttransform}}</label>
      <input class="dataPopup__createInput" [(ngModel)]="newTemplateName">
      <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="createImportTemplate(newTemplateName)"
        [disabled]="newTemplateName.length < 4">
        {{'Import.Label.AssignmentsSave' | texttransform}}
      </button>
    </div>

    <div class="dataPopup__wrapper" *ngIf="templateMode == 2">
      <div class="dataPopup__loader__wrapper">
        <div class="dataImportAssignment__header__checkList">{{'Import.Label.TemplateListHeader' | texttransform}}</div>
        <div *ngFor="let savedTemplate of savedTemplates" class="everleadShadowBox">

          <div class="assignments__fields__header">
            <div class="everleadInfoLine">
              <i class="fas fa-share-square fingerCursor" (click)="assignByTemplate(savedTemplate)"></i>
              <input [(ngModel)]="savedTemplate.name" (change)="updateImportTemplate(savedTemplate)">              
            </div>
            <div>
              <i class="fas fa-trash fingerCursor everleadCoral" (click)="deleteImportTemplate(savedTemplate)"></i>
            </div>
            <i class="fingerCursor fas " (click)="savedTemplate.showDetails = !savedTemplate.showDetails" [ngClass]="{
              'fa-chevron-down': !savedTemplate.showDetails,
              'fa-chevron-up': savedTemplate.showDetails
            }"></i>
          </div>

          <div class="assignments__fields" *ngIf="savedTemplate.showDetails">
            <div class="dataImportAssignment__header__checkList">{{'Import.Label.ListOfFields' | texttransform}}</div>
            <div class="assignments__fields__line" *ngFor="let assignment of savedTemplate.fields">          
              <div class="assignments__fields__line__large">
                {{ assignment.name }}
              </div>
              <div class="assignments__fields__line__small">
                <i class="fas fa-arrow-alt-circle-right"></i>
              </div>
              <div class="assignments__fields__line__large">
                {{commonService.getNameTranslationOrDefaultName(applicationInfoService.entityMember.Item(assignment.entityMemberId)) | texttransform }}
              </div>
              <div class="assignments__fields__line__small">
                <i class="fas fa-trash fingerCursor everleadCoral" (click)="deleteImportTemplateField(savedTemplate.fields, assignment)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>

    <div class="dataPopup__wrapper" *ngIf="templateMode == 3">
      <label class="ellipsisText">{{'Import.Label.LookupTableName' | texttransform}}</label>
      <input class="dataPopup__createInput" [(ngModel)]="newLookupTableName">
      <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="createLookupTable(newLookupTableName)"
        [disabled]="newLookupTableName.length < 4">
        {{'Import.Label.CreateLookupTable' | texttransform}}
      </button>
    </div>

    <div class="dataPopup__wrapper" *ngIf="templateMode == 4">
      <label class="ellipsisText">{{'Import.Label.PoolName' | texttransform}}</label>
      <input class="dataPopup__createInput" [(ngModel)]="newPoolName">
      <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="createNewPool(newPoolName)"
        [disabled]="newPoolName.length < 4">
        {{'Import.Label.CreatePool' | texttransform}}
      </button>
    </div>

    <div class="dataPopup__importResult" *ngIf="templateMode == 5">
      <!-- Result of dataimport -->
      <div *ngIf="importResult.resultCode == -1">
        <div class="dataPopup__importResult__header">{{'Import.Label.ImportFailed' | texttransform}}</div>
        <div class="dataPopup__importResult__infoLineError">
          <!-- <label>{{'Import.Label.ErrorMessage' | texttransform}}: </label> -->
          <div class="errorMessage" *ngIf="importResult.message == null">{{ importResult.results }}</div>
          <div class="errorMessage" *ngIf="importResult.message != null">{{ importResult.message | texttransform }}</div>
          <app-main-button (onClick)="dataPopupOpen = false" label="General.Label.Close"></app-main-button>
        </div>
        <br>
        <button class="jjButtonStyle jjButtonColor" (click)="exportData()" *ngIf="importResult.guid != null">
          {{'Import.Label.DownloadNotImportedData' | texttransform}}
        </button>
      </div>

      <div *ngIf="importResult.resultCode != -1">
        <div class="dataPopup__importResult__header">{{'Import.Label.ImportSuccessfull' | texttransform}}</div>
        <div class="dataPopup__importResult__infoLine">
          <label>{{'Import.Label.ImportedAccounts' | texttransform}}: </label>
          <label>{{ importResult.importedAccounts }}</label>
        </div>
        <div class="dataPopup__importResult__infoLine">
          <label>{{'Import.Label.ImportedContacts' | texttransform}}: </label>
          <label>{{ importResult.importedContacts }}</label>
        </div>
        <div class="dataPopup__importResult__infoLine  everleadShadowBox isDeveloper" *ngIf="applicationInfoService.isDeveloper"
          (click)="copyToClipBoard()">
          <label [innerHTML]="getDeveloperResult()"></label>
        </div>
      </div>
    </div>
  </div>
</div>

