import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrl: './status-bar.component.scss'
})
export class StatusBarComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges{



  @Input() value: any;
  @Input() label: string | null = null;

  @Input() barWidth : number | null;
  @Input() options: any[] | null = null; 

/*   @ViewChild('bar', {static: false }) bar!:ElementRef; */
width
  ngOnInit(){

    this.value = Number(this.value)
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['value'] || changes['barWidth']){
/*       console.log('changes', changes) */
    }
  }


  calcWidth(){
    if(this.barWidth !== null){
      this.width = (this.barWidth * this.value) / 100
    }
  }


  ngAfterViewInit(): void {

/*     if(!this.barWidth){
      setTimeout(() =>{
        this.barWidth = this.bar.nativeElement.offsetWidth;
        this.calcWidth()
      },0)
    } else {
      this.calcWidth()
    } */
  }



  ngAfterViewChecked(): void {
/*     if(!this.barWidth){
      this.barWidth = this.bar.nativeElement.offsetWidth;
    } */
  }

}
