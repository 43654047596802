<div class="microCampaignCancel">
    <div class="microCampaignCancel__description">
        {{ 'MicroCampaign.Label.CancelMicroCampaignDescription' | texttransform }}
    </div>
    <div class="microCampaignCancel__canceltext">
        <!-- <app-text-area-input
        [fire]="true" 
        [(ngModel)]="cancelText"
        [label]="'MicroCampaign.Label.CancelMicroCampaignDescription' | texttransform"
        [height]="550"
        ></app-text-area-input> -->
        <textarea [(ngModel)]="cancelText" class="microCampaignCancel__canceltext__area"></textarea>
    </div>
    <div class="microCampaignCancel__buttonContainer">
        <app-main-button 
        type="border"
        svg="trash"
        (onClick)="cancelMicroCampaign()"
        [disabled]="cancelText.length < 5"
        [label]="'MicroCampaign.Label.CancelMicroCampaignButtonDescription' | texttransform"
        ></app-main-button>
    </div>
</div>