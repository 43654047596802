<div class="cloudConnectionLogin__wrapper">
    <div class="cloudConnectionLogin__label">
        <label [innerHTML]="'CloudConnectionLogin.Label.PrivacyModeDescription' | texttransform"></label><br>
        <br>
        <label [innerHTML]="'CloudConnectionLogin.Label.PrivacyModeDescription2' | texttransform"></label>
    </div>

    <div class="cloudConnectionLogin__button">
        
        <a class="everleadCustomButton" [href]=cloudConnectionLink target="_blank">{{ 'CloudConnectionLogin.Label.Login' | texttransform }}</a>
    </div>    
</div>