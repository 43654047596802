<div class="selectedDashboard">
<app-layout header="Dashboard.Item.AccountOwner" [subheader]="selectedReport?.subHeader">
    <div class="selectedDashboard__headerNav">
        <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back" type="small"></app-main-button>
   </div> 
    <div class="selectedDashboard__innerBorderLayer">
    
        <div class="selectedDashboard__nav">
            <div class="selectedDashboard__filterMobile">
                <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                    <div class="selectedDashboard__filterMobile">
                        <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>          
                    </div>
                </app-accordion>
            </div>
            <div class="selectedDashboard__filter">
                <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>          
            </div>   
        </div>
   

        <div *ngIf="loadingData == true">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </div>
        
        <div class="apiDiv" *ngIf="dashboardResultData[this.dashboardId]==null && loadingData == false">
            {{ 'General.Label.NoDataAvailable' | texttransform }}
        </div>

        <div *ngIf="loadingData == false" class="selectedDashboard__content">
            <div *ngFor="let pool of pools">
                <app-bar-chart 
                    *ngIf="getPoolData(pool).length > 0"
                    [barHeader]="pool.defaultName"
                    [chartData]="getPoolData(pool)" 
                    component="accountOwner" 
                    [consentRef]="sortArrayId"
                    [controlid]="accountOwnerChart">
                </app-bar-chart>
            </div>
        </div>
    </div>
</app-layout>
</div>