<div class="selectedDashboard">
    <app-layout [header]="selectedReport.defaultName" [subheader]="selectedReport?.subHeader">
        <div class="selectedDashboard__headerNav">
            <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back"
                type="small"></app-main-button>
        </div>
        <div class="selectedDashboard__innerBorderLayer">
            <div class="selectedDashboard__nav">
                <div class="selectedDashboard__filterMobile">
                    <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                        <app-date-input [calendarOnly]="true" label="UTMDashboard.Label.FromDate" [(ngModel)]="fromDate"
                            (dateChange)="updateDashboardValues()"></app-date-input>
                        <app-date-input [calendarOnly]="true" label="UTMDashboard.Label.ToDate" [(ngModel)]="toDate"
                            (dateChange)="updateDashboardValues()"></app-date-input>
                        <app-select-input label="UTMDashboard.Label.Content" [options]="contents" [(ngModel)]="content"
                            [complex]="true" display="option"
                            (valueChange)="updateDashboardValues()"></app-select-input>
                        <app-select-input label="UTMDashboard.Label.Campaigns" [options]="campaigns"
                            [(ngModel)]="campaign" [complex]="true" display="option"
                            (valueChange)="updateDashboardValues()"></app-select-input>
                        <app-select-input label="UTMDashboard.Label.Sources" [options]="sources" [(ngModel)]="source"
                            [complex]="true" display="option"
                            (valueChange)="updateDashboardValues()"></app-select-input>
                    </app-accordion>
                </div>
                <div class="selectedDashboard__filter">
                    <app-date-input [calendarOnly]="true" label="UTMDashboard.Label.FromDate" [(ngModel)]="fromDate"
                        (dateChange)="updateDashboardValues()"></app-date-input>
                    <app-date-input [calendarOnly]="true" label="UTMDashboard.Label.ToDate" [(ngModel)]="toDate"
                        (dateChange)="updateDashboardValues()"></app-date-input>
                    <app-select-input label="UTMDashboard.Label.Content" [options]="contents" [(ngModel)]="content"
                        [complex]="true" display="option" (valueChange)="updateDashboardValues()"></app-select-input>
                    <app-select-input label="UTMDashboard.Label.Campaigns" [options]="campaigns" [(ngModel)]="campaign"
                        [complex]="true" display="option" (valueChange)="updateDashboardValues()"></app-select-input>
                    <app-select-input label="UTMDashboard.Label.Sources" [options]="sources" [(ngModel)]="source"
                        [complex]="true" display="option" (valueChange)="updateDashboardValues()"></app-select-input>
                </div>
            </div>

            <div *ngIf="loadingData == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                texttransform }}
            </div>

            <div class="dataDiv" *ngIf="dashboardResultData[this.dashboardId]==null && loadingData == false">
                {{ 'General.Label.NoDataAvailable' | texttransform }}
            </div>
            <span id="dashboardContainerDesignSpan"></span>
            <!--  was 32 for height -->
            <div *ngIf="loadingData == false && dashboardResultData[this.dashboardId]!=null"
                class="selectedDashboard__content selectedDashboard__columnFlex padding"
                [style.height.px]="getControlHeight('dashboardContainerDesignSpan', 45)"
                [style.max-height.px]="getControlHeight('dashboardContainerDesignSpan', 45)">
                <div class="utmPies">
                    <div class="chartBox">
                        <app-echarts id="#sourceChart" [chart]="'pie'"
                            [title]="'UTMDashboard.Label.Sources' | texttransform"
                            [chartData]="getDataArray('sourceSave')" [darkmode]="false" [legend]="false" [height]="275"
                            tooltipPosition="right"></app-echarts>
                    </div>
                    <div class="chartBox">
                        <app-echarts id="#campaignChart" [chart]="'pie'"
                            [title]="'UTMDashboard.Label.Campaigns' | texttransform"
                            [chartData]="getDataArray('campaignSave')" [darkmode]="false" [legend]="false"
                            tooltipPosition="left" [height]="275"></app-echarts>
                    </div>
                    <div class="chartBox">
                        <app-echarts id="#contentChart" [chart]="'pie'"
                            [title]="'UTMDashboard.Label.Content' | texttransform"
                            [chartData]="getDataArray('contentSave')" [darkmode]="false" [legend]="false" [height]="275"
                            tooltipPosition="right"></app-echarts>
                    </div>
                </div>
                <div class="sourcesPerCampaign">
                    <app-layout header="UTMDashboard.Label.SourcesPerCampaign">
                        <app-bar-chart *ngIf="getFilteredDataArray().length > 0" [height]="getBarChartHeight()"
                            [chartData]="getFilteredDataArray()" component="utmSourcesPerCampaign"
                            consentRef="sortArrayId" [controlid]="utmSourcesPerCampaign"></app-bar-chart>

                    </app-layout>
                </div>




                <div class="sourcesOverview">
                    <app-layout header="General.Label.Overview">
                        <div class="utmOverviewHeader utmOverview">
                            <div class="utmOverview__content">{{ 'UTMDashboard.Label.SourceContentCol' | texttransform }}</div>
                            <div class="utmOverview__campaign" *ngFor="let campaign of getOverviewItems('campaign')" [tippy]="campaign">
                                {{ campaign }}
                            </div>
                            <div class="utmOverview__total">{{ 'UTMDashboard.Label.Total' | texttransform }}</div>
                        </div>
                        <div *ngFor="let source of getOverviewItems('source')" class="everleadTableRow">
                            <div class="utmOverview utmOverview__topLine">
                                <div class="utmOverview__content">{{ source }}</div>
                                <div class="utmOverview__campaign" *ngFor="let campaign of getOverviewItems('campaign')">
                                    {{ getOverviewRowValue(source, campaign).length }}
                                </div>
                                <div class="utmOverview__total">
                                    {{ getOverviewRowValue(source, campaign, null, true).length }}
                                </div>
                            </div>
                            <div *ngFor="let content of getOverviewSourceItems(source)" class="utmOverview">
                                <div class="utmOverview__content utmOverview__subRow">{{ content }}</div>
                                <div class="utmOverview__campaign" *ngFor="let campaign of getOverviewItems('campaign')">
                                    {{ getOverviewRowValue(source, campaign, content).length }}
                                </div>
                                <div class="utmOverview__total">
                                    {{ getOverviewRowValue(source, campaign, content, true).length }}
                                </div>                                
                            </div>
                        </div>
                    </app-layout>
                </div>
            </div>
        </div>
    </app-layout>
</div>