import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { GraphQLService, ApolloMethod } from 'app/shared/service/graphql.service';
import { Subscription } from 'rxjs';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-custom-powerbutton',
  templateUrl: './custom-powerbutton.component.html',
  styleUrls: ['./custom-powerbutton.component.scss']
})

export class CustomPowerbuttonComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  updatePowerButtonsEventSubScription: Subscription = new Subscription();
  powerButtons = [];
  accountIsLocked = false;

  constructor(
    private injector: Injector,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit() {
    this.updatePowerButtonsEventSubScription = this.eventService.updatePowerButtonsEvent.subscribe(() => {
      this.getPowerButtons();
    });
    this.getPowerButtons();
    if (this.applicationInfoService.currentAccount) {
      if (this.applicationInfoService.currentAccount.isReadOnly == true) {
        this.accountIsLocked = true;
      }
    }
  }

  ngOnDestroy() {
    if (this.updatePowerButtonsEventSubScription) { this.updatePowerButtonsEventSubScription.unsubscribe(); }
  }

  getPowerButtons() {
    if (this.applicationInfoService.currentTask) {
      if (this.applicationInfoService.currentTask.stepId !== undefined) {
        this.loadingControlData = true;
        this.externalDatasourceService.executeExternalDataSource(617)
        .then(result => {
          if (result) {
            const finalResults = this.commonService.getFilteredResults(result);
            this.powerButtons = [];
            finalResults.forEach(element => {
              let positivityLookupId = -1;
              let elementText = '';
              if (element.nameTranslationToken !== null) {
                elementText = element.nameTranslationToken.tokenFullName;
              } else {
                elementText = element.defaultName;
              }
              if (element.leadStatus) {
                if (!isNullOrUndefined(element.leadStatus.lookupTablePositivity)) {
                  positivityLookupId = element.leadStatus.lookupTablePositivity.positivityValue;
                }
              }
              if (element.isActive) {
                this.powerButtons.push({ id: element.id, name: elementText, result: positivityLookupId });
              }
            });
            this.loadingControlData = false;
          }
        })
        .catch(() => { this.loadingControlData = false; });
      }
    }
  }

  callPowerButton(powerButtonResultId) {
    this.applicationInfoService.currentTask.resultId = powerButtonResultId;
    this.entityService.updateValuebyType(this.applicationInfoService.taskEntityId, 'resultId', powerButtonResultId, ValueType.Number);
    this.methodService.launchInternalMethod('changecontainercontent', [
      this.applicationInfoService.taskDetailsContainerId.toString(),
      this.applicationInfoService.taskDetailsBookingViewId.toString()
    ]);
  }

  getPowerButtonClasses() {
    if (isNullOrUndefined(this.controlDefinition.additionalCssClasses)) {
      return '';
    } else {
      return this.controlDefinition.additionalCssClasses;
    }
  }
}
