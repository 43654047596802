
<div class="containerNavBarOuter" >
    <div class="containerNavBar">
        <button *ngIf="list?.isOverflow" (click)="list?.scroll(-1)" class="containerNavBar__button" [class.disabled]="!list.canScrollStart" [disabled]="!list.canScrollStart">
            <app-jj-icons type="caret-left" color="primaryLight" width="20" height="20"></app-jj-icons>
        </button>

        <div appScrollable #list="appScrollable" [scrollUnit]="150" class="containerNavBar__navWrapper"[ngClass]="{ appScrollTab__navWrapper__scroll: list?.isOverflow }">
            <ng-container *ngIf="!children">
                <app-navbar-item 
                *ngFor="let option of options; let index = i" 
                [hidden]=option.hidden 
                [label]="option.label" 
                (onClick)="selectItem(option.id)" 
                [active]="option.id === selectedId"
                [overflow]="list.isOverflow"
                [first]="option.id === options[0].id"
                [last]="option.id === options[options.length - 1].id"
                ></app-navbar-item></ng-container>

            <ng-container *ngIf="children">
                <ng-content></ng-content>
            </ng-container>
            
        </div>
        <button *ngIf="list?.isOverflow" (click)="list?.scroll(1)" class="containerNavBar__button" [class.disabled]="!list.canScrollEnd" [disabled]="!list.canScrollEnd">
            <app-jj-icons type="caret-right" color="primaryLight" width="20" height="20"></app-jj-icons>
        </button>
    </div>
    <div class="containerNavBarMobile" *ngIf="options">
        <app-select-input uiType="custom-register" [label]="'General.Label.SelectTab' | texttransform" [(ngModel)]="selectedId" [options]="options"
        (valueChange)="selectorItem(selectedId)" 
        display="label" bind="id" class="tabSelectWrapper"></app-select-input>

    </div>
</div>




