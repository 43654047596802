import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-orderdlc',
  templateUrl: './orderdlc.component.html',
  styleUrl: './orderdlc.component.scss'
})

export class OrderdlcComponent implements OnInit {
  dlc = null;
  acceptLicense = false;
  allPackagePrices = [];
  dlcOrdered = false;

  constructor(
    private externalDataSourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService,
    private eventService: EventService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.dlc = this.applicationInfoService.miscSettings['orderDlc'];
    this.allPackagePrices = this.applicationInfoService.miscSettings['allPackagePrices'];
  }

  orderDLC() {
    this.loaderService.display(true);
    this.externalDataSourceService.executeExternalDataSource(1030, [this.dlc.id]).then(orderDLCResult => {      
      if (orderDLCResult == true) {
        this.dlcOrdered = true;
        this.loaderService.display(false);
        // this.messagingService.showDefaultSuccess(this.dlc.settingJson.packageSectionName, 'DLC.Label.OrderSuccess');
      } else {
        this.messagingService.showDefaultSuccess(this.dlc.settingJson.packageSectionName, 'DLC.Label.OrderError');
        this.loaderService.display(false);
      }
      // this.eventService.closeJjPopup();
    }).catch(() => {
      this.loaderService.display(false);
    });
  }

  closeDLC() {
    this.eventService.closeJjPopup();
  }

  getDLCPrice(dlc) {
    const price = this.allPackagePrices.find(item => item.id == dlc.id);
    if (price) {
      return ''.concat(price.setupPrice, ' ', price.currency.toUpperCase());
    } else {
      return '';
    }    
  }
}
