import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { QuestionaireControlService } from '../questionaire-control.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-questionaire-designer-questioncontrol',
  templateUrl: './questionaire-designer-questioncontrol.component.html',
  styleUrls: ['./questionaire-designer-questioncontrol.component.scss']
})
export class QuestionaireDesignerQuestioncontrolComponent implements OnInit {
  @Input() question = null;
  @Input() parent = null;
  typeLookups = [];
  subQuestionDeleted = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public questionaireControlService: QuestionaireControlService,
    public commonService: CommonService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private confirmationService: ConfirmationService
   ) { }

  ngOnInit(): void {
    this.getAnswersByQuestion();
    this.getTypeLookups();
  }

  addAnswer() {
    this.questionaireControlService.addAnswer(this.question).then(addAnswerResult => {
      addAnswerResult['childQuestions'] = [];
      this.question.answers.push(addAnswerResult);
      this.eventService.customEvent.emit({ id: 'refreshQuestionaireControl' });
    });
  }

  addQuestion(answer) {
    this.questionaireControlService.addQuestion(answer.id).then(result => {
      if (!answer.childQuestions) {
        answer.childQuestions = [];
      }
      answer.childQuestions.push(result);
      this.eventService.customEvent.emit({ id: 'refreshQuestionaireControl' });
    });
  }

  moveInList(event: CdkDragDrop<string[]>, item: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.saveAnswerOrder();
    }
  }

  saveAnswerOrder() {
    let i = 0;
    this.question.answers.forEach(answer => {
      answer.order = i;
      this.questionaireControlService.updateAnswer(answer, false);
      i ++;
    });
  }

  getAnswersByQuestion() {
    this.questionaireControlService.getAnswersByQuestion(this.question).then(getAnsersByQuestionResult => {
      this.question.answers = getAnsersByQuestionResult;
    });    
  }

  getTypeLookups() {
    this.typeLookups = this.commonService.getProjectLookupTableValues(51, 'central');
  }

  deleteQuestion(question) {
    this.questionaireControlService.deleteQuestion(question).then(() => { 
      this.subQuestionDeleted = true; 
      question.isDeleted = false; 
      this.eventService.customEvent.emit({ id: 'refreshQuestionaireControl' });
    });
  }

  deleteAnswer(answer) {
    this.questionaireControlService.deleteAnswer(answer).then(() => {
      this.commonService.removeItemFromArray(this.question.answers, answer);
      this.eventService.customEvent.emit({ id: 'refreshQuestionaireControl' });
    });
  }
}
