import { AfterContentInit, Component, ContentChildren, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrl: './select-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true
    }
  ]
})

export class SelectInputComponent implements ControlValueAccessor, AfterContentInit{
  constructor(
    public commonService: CommonService
  ){}
  @Input() id: string | number | null = 'id';
  @Input() options:any;
  @Input() bind: string | null = null;
  @Input() label: string= ''
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() info: string | null = null;
  @Input() error: boolean | null = null;
  @Input() uiType: string | null = '';
  @Input() subArray = null;
  @Input() placeholder: string | null = '-';
  @Input() ellipsis: number | null = null;
  @Input() translation : 'defaultName' | 'description' = 'defaultName';
  @Input() backgroundcolor = null;

  // change Porperty Names
  @Input() display: string | boolean | null = 'name';
  @Input() complex: boolean = false;
  @Input() displayMultiValue = null;
  @Output() blur = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<void>();

  
  isFocused:boolean = false;
  value: any;
  touched


  ngAfterContentInit(): void {   
  }


  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleSimpleValue(event: Event) {    
    const target = event.target as HTMLSelectElement;
    let selectedvalue:any = target.value;
    
    if(!this.disabled){
      if (!isNaN(Number(target.value))) {
        selectedvalue = Number(target.value);
      }

      this.value = selectedvalue

      if(!selectedvalue){
        this.value = null;
      }

      if(this.value === 'null'){
        this.value = null
      }
      this.onChange(this.value);
      this.valueChange.emit(this.value);
      this.onTouched();

    }

  }


  handleObjectValue(event){
    if(!this.disabled){
      this.onChange(this.value);
      this.valueChange.emit(this.value);
      this.onTouched();
    }
  }


  handleFocus(){
    if(!this.disabled){
      this.isFocused = true;
    }
  }

  handleBlur() {
    if(!this.disabled){
      this.isFocused = false;
      this.blur.emit();
      this.onTouched();
    }
  }

  getDisplayMultiValue(dataObject) {
    const valuesToShow = this.displayMultiValue.split(';');    
    let returnValue = '';
    valuesToShow.forEach(valueItem => {      
      if (!this.commonService.isNullOrUndefined(dataObject[valueItem])) {
        returnValue = returnValue.concat(dataObject[valueItem], ' ');
      }
    });
    return returnValue;
  }


  ellipsisLabel(option, ellipsis){
    let label = option.trim();
    let string = label.length > ellipsis ? label.substring(0, ellipsis) + '...' : label ;
   return string
  }

  getLabelBackgroundColor() {
    if (this.backgroundcolor != null) {
      return this.backgroundcolor;
    } 
  }
}
