import { Component } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-expandcampaignflow',
  templateUrl: './expandcampaignflow.component.html',
  styleUrl: './expandcampaignflow.component.scss'
})
export class ExpandcampaignflowComponent {

  constructor(
    public applicationInfoService: ApplicationInfoService 
    ) { }

  flowChartData: any;
  flowChartOverviewId: any;

  ngOnInit(): void {
    this.showCampaignOverview()
  }

  showCampaignOverview(){
   this.flowChartData = this.applicationInfoService.miscSettings['flowChartOverviewExpanded'];
   this.flowChartOverviewId = this.applicationInfoService.miscSettings['flowChartOverviewExpandedType'];
  }
}
