import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { VipLookup, VipService } from 'app/jollyjupiter/service/vip.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { Subscription } from 'rxjs';
import { ControlsDesignLookupTableComponent } from '../../designer/controls/controls-design-lookup-table/controls-design-lookup-table.component';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { DashboardcoreService } from '../../dashboards/dashboardcore.service';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS_FACTORY } from '@angular/material/progress-spinner';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-prospect-detail',
  templateUrl: './prospect-detail.component.html',
  styleUrls: ['./prospect-detail.component.scss']
})
export class ProspectDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  updateControlAttributesEventSubscription: Subscription = new Subscription();
  updateCharDataEventSubscription : Subscription = new Subscription();
  ipProspectFound = false;
  @Input() useContactProspect = false;
  prospect = null;
  displayedColumns = ['date', 'type', 'url', 'refurl'];
  dataSource: MatTableDataSource<any>;
  editControlDiv = false;
  assignContactAfterCreation = false;
  loadingData = true;
  forceShow = false;
  innerHTMLEventsContent = ` <jj-container controluiid="48202"> </jj-container>`;
  innerHTMLEvents = null;
  updateList = false;
  accountInfo = null;
  conversionInfo = null;
  conversionInfoFull = '';
  showOptions = false;
  isVip = false;


  // leadscoring detail 
  chartView=false
  //activityCount
  activity=false;
  loadingChart=false;
  prospectDetail: any[] | null = [];

  // todo new variables 2025;
  prospectId
  projectId
  activityPoints
  totalCount

  previousStep;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private eventService: EventService,
    private loaderService: LoaderService,
    private methodService: MethodService,
    private messagingService: MessagingService,
    private domSanitizer: DomSanitizer,
    public vipService: VipService,
    private axivasTranslateService: AxivasTranslateService,
    private paginatorIntl : MatPaginatorIntl,
    private dashboardcoreService: DashboardcoreService,
    public datePipe: DatePipe
  ) { }

   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getBaseProspectInfo();
      this.getProspectConversionInfo();
    }, 200);
  }

  ngOnInit() {

    //this.getActivityCount();

    this.paginatorIntl.itemsPerPageLabel =this.axivasTranslateService.getTranslationTextForToken('General.Label.Paginator')
    this.innerHTMLEvents = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLEventsContent);

    this.updateControlAttributesEventSubscription = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.getBaseProspectInfo();
    });

    this.updateCharDataEventSubscription = this.eventService.chartDatachange.subscribe((event) => {


      if(event.id === 'entitytable-chart'){
        this.prospectId = event.prospect_id;
        this.totalCount = event.totalCount;
        this.activity = true;
        this.getDashboardDetails(43).then((result) => {
          if(result === undefined){
            this.activity = false;
          }

          if(result && (result?.length === 0 || result[0].length === 0) ){
            this.activity = false;

          } else {
            this.activityPoints = JSON.parse(JSON.stringify(result[0]))
            if(this.activityPoints && this.activityPoints.length > 0){
  
              this.getProspectDetail(event.totalCount)
            } else {
              this.activity = false;
            }
          }
          
        }).catch((error) => {
          this.activity = false;
          console.log('error', error)
        })

      }
    })

    if (this.applicationInfoService.miscSettings['convertProspectAndAssignContactToIt'] == true) {
      this.assignContactAfterCreation = true;
      this.applicationInfoService.miscSettings['convertProspectAndAssignContactToIt'] = false;
    }
    if (this.applicationInfoService.miscSettings['convertProspectForceShow'] == true) {
      this.forceShow = true;
      this.applicationInfoService.miscSettings['convertProspectForceShow'] = false;
    }    
  }

  getProspectConversionInfo() {
    this.externaldatasourceService.executeExternalDataSource(301, ['companyName'])
          .then(conversionResult => {
            conversionResult.forEach(conversion => {
              if (!this.commonService.isNullOrUndefined(conversion.companyName)) {
                if (this.conversionInfo == null) {
                  this.conversionInfo = conversion.companyName;
                }
                this.conversionInfoFull = this.conversionInfoFull.concat(conversion.companyName, '\n')
              }
            });
            this.loadingData = false;
          })
          .catch(error => {
            console.error(error);
            this.loadingData = false;
          })
  }

  getBaseProspectInfo() {
    if (this.useContactProspect == false) {
      this.getProspectInfo(this.applicationInfoService.miscSettings['currentProspect']);
    }  else {
      if (this.applicationInfoService.currentContact) {
        if (this.applicationInfoService.currentContact.prospectId != null) {
          this.applicationInfoService.miscSettings['currentProspect'] = this.applicationInfoService.currentContact.prospectId;
          this.getProspectInfo(this.applicationInfoService.currentContact.prospectId);
        } else if (this.applicationInfoService.currentContact.ipProspectId != null) {
          this.applicationInfoService.miscSettings['currentProspect'] = this.applicationInfoService.currentContact.ipProspectId;
          this.getProspectInfo(this.applicationInfoService.miscSettings['currentProspect']);
          this.ipProspectFound = true;
        } else {
          this.showOptions = true;
          this.prospect = null;
        }
      }
    }
  }




  callPopup(force = false) {
    if (force) {
      this.applicationInfoService.miscSettings['convertProspectForceShow'] = true;
      this.forceShow= true;
      this.applicationInfoService.miscSettings['convertProspectForceShow'] = false;
     /*  this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [this.prospect.companyName, 'prospectdetail', 80]) */
    } else {
      if (this.useContactProspect && !this.prospect.isisp) {
        this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [this.prospect.companyName, 'prospectdetail', 80])
      }
    }
  }

  getProspectInfo(id) {
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(406, [id])
    .then(getProspectInfoResult => {
      this.prospect = getProspectInfoResult;
   /*    console.log('this.prospect', this.prospect) */
      if (!this.prospect.enriched || this.prospect.isisp) {
        this.showOptions = true;
      }
      this.loadingData = false;
 /*      console.log('showOptions', this.showOptions); */
      if (this.prospect.companyName === null) {
        this.prospect.companyName = 'Prospect';
      }

/*       this.getActivityCount(); */
      this.dataSource = new MatTableDataSource(getProspectInfoResult.events);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.eventService.customEvent.emit({ id: 'changePopupHeader', value: this.prospect.companyName });
      if (this.prospect.accountId != null) {
        this.externaldatasourceService.executeExternalDataSource(87, [this.prospect.accountId]).then(result => {
          this.accountInfo = result;
        })
      }
      this.checkIfProspectIsVip(this.prospect);
    }).catch(() => { this.loadingData = false; });
  }

  convertToDate(value) {
    if (value !== null) {
      value = new Date(value).toLocaleString();
    }
    return value;
  }

  shortenString(value) {
    if (value.length > 50) {
      value = value.substr(0, 50) + '...';
    }
    return value;
  }

  toggleEditDiv() {
    this.editControlDiv = !this.editControlDiv;
  }

  getImageUrl(url){
    if (url !== '') {

      var pattern = /^((http|https):\/\/)/;

      if(!pattern.test(url)) {
       url =  url.replace(url, 'https://' + url);
      }
    }

    return url;

  }

  changeProspectBlackList() {
    this.prospect.isblacklisted = !this.prospect.isblacklisted;
    this.updateList = true;
    this.saveProspect();
  }

  saveProspect(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(291, [
        this.commonService.getModifyArrayBody(this.prospect, [], [])
      ])
      .then(() => {
        resolve(null);
        this.messagingService.showDefaultSuccess('', 'Prospect.Message.Saved', false)
      })
      .catch(error => { console.error(error);
        reject(error);
      })
      .finally(() => { this.loaderService.display(false); });
    });
  }

  createAccountFromProspect() {
    this.applicationInfoService.miscSettings['prospectToConvert'] = this.prospect;
    this.eventService.showJjPopup('Prospect.Label.ConvertToAccount', 'newaccount', '90');
    return;

    this.saveProspect()
    .then(() => {
      this.loaderService.display(true);
      this.prospect['poolId'] = this.applicationInfoService.poolId;
      this.externaldatasourceService.executeExternalDataSource(292, [this.prospect.id])
      .then(createAccountFromProspectResult => {
        this.loaderService.display(false);
        this.prospect.accountId = createAccountFromProspectResult.id;
        this.messagingService.showDefaultSuccess('', 'Prospect.Message.AccountCreationSuccessfull', false);
        if (this.assignContactAfterCreation) {
          this.methodService.launchInternalMethod(InternalMethodType.AssignAccountToContact, [this.prospect.accountId, 'loadContact'])
        } else {
          this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [createAccountFromProspectResult.id]);
        }
        this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
      })
      .catch(error => { console.error(error); })
      .finally(() => { this.loaderService.display(false); });
    });
  }

  loadProspectAccount() {
    this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [this.accountInfo.id]);
    this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
  }

  assignAccount() {
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['Button.Label.AssignAccount', '24859', '80']);
  }

  convertProspectAndAssignContactToIt() {
    this.applicationInfoService.miscSettings['convertProspectAndAssignContactToIt'] = true;
    this.callPopup();
  }

  createNewAdressAndAssign() {
    this.applicationInfoService.miscSettings['convertProspectAndAssignContactToIt'] = true;
    this.applicationInfoService.miscSettings['convertProspectAndAssignContactId'] = this.applicationInfoService.currentContact.id;
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['Supervisor.Menu.NewAccount', 'newaccount', '70']);
  }

  addProspectToVipList(prospect) {
    if (this.isVip) {
      this.loaderService.display(true);
      this.vipService.deleteProspectFromList(prospect)
      .then(() => {
        this.isVip = false;
        this.loaderService.display(false);
      })
      .catch(error => {
        console.error(error);
        this.loaderService.display(false);
      });
    } else {
      this.loaderService.display(true);
      this.vipService.addItemToVipList(prospect.companyName, prospect.url, VipLookup.Prospect)
      .then(() => {
        this.isVip = true;
        this.loaderService.display(false);
      })
      .catch(error => {
        console.error(error);
        this.loaderService.display(false);
      });
    }
  }

  checkIfProspectIsVip(prospect) {
    if (this.applicationInfoService.applicationSettings['showVipSettings'] == 'true') {
      this.vipService.checkIfProspectIsVip(prospect)
      .then(checkIfProspectIsVipResult => {
        this.isVip = checkIfProspectIsVipResult;
      });
    }
  }



/*   getActivityCount(){
    this.activity=false;
    this.externaldatasourceService.executeExternalDataSource(633).then((result) => {
      this.activityCount = result;

      
      this.activity=true;
    }).catch((error) =>{
      this.activity=false;
    }) 
  
}*/



  mapFilterArray(filterArray){
    let filterArrayJson = JSON.stringify(filterArray);
    Object.keys(filterArray).forEach(() =>  {      
      filterArrayJson = filterArrayJson.replace('"'.concat('columnName', '"'), 'columnName');
      filterArrayJson = filterArrayJson.replace('"'.concat('value', '"'), 'value');
      filterArrayJson = filterArrayJson.replace('"'.concat('operator', '"'), 'operator');
      filterArrayJson = filterArrayJson.replace('"'.concat('orderByOperator', '"'), 'orderByOperator');
      filterArrayJson = filterArrayJson.replace('"'.concat('negate', '"'), 'negate');
      filterArrayJson = filterArrayJson.replace('"'.concat('DESC_NULLS_LAST', '"'), 'DESC_NULLS_LAST');
      filterArrayJson = filterArrayJson.replace('"'.concat('ASC_NULLS_LAST', '"'), 'ASC_NULLS_LAST');
    });

    //console.log('filterArrayJson', filterArrayJson);
    return filterArrayJson;
  }


  getProspectDetail(pageSize){
    this.loadingChart = true;

    let colfilter = [
      { "columnName" : "prospectId", "value": this.applicationInfoService.miscSettings['currentProspect'].toString() }
    ];

    let vips = null;

    const tableSettings = {
      definitionId: 232,
      orderBy: { columnName : "timestamp_date", orderByOperator: 'DESC_NULLS_LAST' },
      filterId: null,
      columnFilter: colfilter,
      pageSize: pageSize,
      pageNumber: 1,
      withVIP: null,
      vipViewName: "view_user_vip_list"
    }

    this.externaldatasourceService.executeExternalDataSource(895,[
      tableSettings.definitionId,
      tableSettings.pageSize,
      tableSettings.pageNumber,
      this.mapFilterArray(tableSettings.columnFilter),
      this.mapFilterArray(tableSettings.orderBy),
      '',
      tableSettings.filterId,
      tableSettings.vipViewName,
      vips
    ]).then((prospectResult)=> {
      this.loadingChart = false
      let array = prospectResult.jsonData.map((item) => JSON.parse(item))
      let data = this.commonService.cleanKeysfromEntityTable(array);
      this.prospectDetail= data.map((item) =>{
        const count = this.activityPoints.find(act => act.event_id === item.id)
            return{
              ...item,
              timestamp2:this.datePipe.transform(item.timestamp_date, 'dd-MM-yyyy HH:mm:ss'),
              timestamp: item.timestamp_date ? new Date(item.timestamp_date).getTime(): null,
              value: count ? this.formatNumber(count.points) : 0,
              pure_points: count ? this.formatNumber(count.pure_points) : 0,
            }
        }).sort((a,b) => a.timestamp < b.timestamp ? -1 : 1);

    }).catch((error) => {
      this.loadingChart= false;
      console.log('error', error);
    });
  }


  formatNumber(number){
    return number % 1 === 0 ? number : parseFloat(number.toFixed(1)) 
  }


  ngOnDestroy() {
    if (this.updateControlAttributesEventSubscription || this.updateCharDataEventSubscription) { 
      this.updateControlAttributesEventSubscription.unsubscribe();
      this.updateCharDataEventSubscription.unsubscribe();
     }
    if (this.updateList) {
      this.eventService.updateControlContent(this.applicationInfoService.applicationSettings['prospectlistid']);
    }

    this.forceShow= false;

 
  }


  getDashboardDetails(dashboardId, type = null): Promise<any> {
    this.loadingData = true;
    return new Promise((getDashboardDetailsResolve, getDashboardDetailsReject) => {
      let params = this.fillParams(type);
      this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {      
        this.loadingData = false;
        getDashboardDetailsResolve(getDashboardDataArrayResult);
      }).catch((error)=>{
        this.loadingData = false;
        getDashboardDetailsReject(error);
      });  
    });
  }

  fillParams(eventType = null) {
    const params = [];   
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@prospect_id', value: this.prospectId });
/*     if (eventType) {
      params.push({ key: '@event_type', value: eventType });
    } */
    return params;
  }



}
