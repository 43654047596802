import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExternaldatasourceService } from '../service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { environment } from 'environments/environment';
import { CommonService } from '../service/common.service';
import { ExternalContentService } from '../service/external-content.service';
import { MethodService } from '../service/method.service';
import { LanguageService } from 'app/shared/translation/language.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Setting, SettingsService } from 'app/shared/service/settings.service';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { EventService } from '../service/event.service';
import { RefreshValueService } from '../signalr/refresh-value.service';
import { RingcentralwebphoneService } from '../service/ringcentralwebphone.service';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { MessagingService } from '../service/messaging.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-usersettings',
  templateUrl: './usersettings.component.html',
  styleUrls: ['./newUserSettings.scss']
})
export class UsersettingsComponent implements OnInit {
  applicationName = '';
  useRingCentral = '';
  userList = [];
  projects = [];
  apiBackend;
  apiUrl;
  signalRUrl;
  extension3cx = '';
  matTablePageSize = 20;
  cacheCentral = '';
  cacheData = '';
  doNotUntrackValue = '';
  schemaReloadProjectId = null;
  schemaUnloadProjectId = null;
  expertMode = false;
  deleteProjectId = null;
  ringCentralOwnNumberSetting = '';

  isMfaRequired: boolean = false;
  mfaSetupEnabled: boolean = false;
  userPass: string;
  userAuthenticated: boolean = false;
  setupMfaSecretCode: string = '';
  setupMfaAnswer: string;
  isMfaEnabled: boolean;

  centralCacheResetItems = [];
  dataCacheResetItems = [];
  quickTaskSteps = [];
  quickTaskStepId = null;
  tab= 1;
  languageChanged = false;

  constructor(
    private router: Router,
    private externalContentService: ExternalContentService,
    private loaderService: LoaderService,
    public axivasTranslateService: AxivasTranslateService,
    public userService: UserService,
    private methodService: MethodService,
    private sharedAPI: SharedAPI,
    public settingsService: SettingsService,
    private commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private authService: AuthService,
    private eventService: EventService,
    private refreshValueService: RefreshValueService,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    private awsCognitoService: AwsCognitoService,
    private messagingService: MessagingService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.useRingCentral = environment.useRingCentral;
    this.getUserList();
    this.getProjects();
    this.getQuickTaskSteps();
    this.apiUrl = environment.apiUrl;
    this.signalRUrl = environment.signalRUrlMiddleware;
    this.apiBackend = this.applicationInfoService.apiBackendUrl;
    this.applicationName = environment.applicationName;
    this.schemaReloadProjectId = this.applicationInfoService.projectID;
    this.ringCentralOwnNumberSetting = 'ringCentralOwnNumber'.concat(this.applicationInfoService.projectID.toString());

    this.applicationInfoService.workflows.toArray();
    this.applicationInfoService.workflowSteps.toArray();

    this.centralCacheResetItems = this.applicationInfoService.applicationSettings['centralCacheResetItems'].split(';');
    this.dataCacheResetItems = this.applicationInfoService.applicationSettings['dataCacheResetItems'].split(';');

    //if mfa required, new user cannot enable/disable it
    this.isMfaRequired = this.applicationInfoService.applicationSettings['isMfaRequired'] === 'true';
    // this.isMfaRequired = false;  //TODO get from backend

    const tempStepId = this.applicationInfoService.userSettings['quickTaskStepId'.concat(this.applicationInfoService.projectID.toString())];
    if (!this.commonService.isNullOrUndefined(tempStepId)) {
      this.quickTaskStepId = tempStepId;
    }    
  }

  saveQuickTaskStepSettings() {
    this.applicationInfoService.userSettings['quickTaskStepId'.concat(this.applicationInfoService.projectID.toString())] = 
      this.quickTaskStepId;
    this.settingsService.updateSecurityUserSettingsJson();
  }

  getQuickTaskSteps() {
    this.refreshValueService.getWorkflows().then(() => {
      this.quickTaskSteps = this.applicationInfoService.steps.toArray().filter(item => item.isFollowUp == true);
    });    
  }

  getProjects() {
    this.externaldatasourceService.executeExternalDataSource(2).then(getProjectsResult => {
      // getProjectsResult.sort((a, b) => a.projectName < b.projectName ? -1 : 1);
      this.projects = getProjectsResult;
    })
  }

  toggleEdit() {
    this.applicationInfoService.designerMode = !this.applicationInfoService.designerMode;
  }

  getUserList() {
    // this.userList = this.applicationInfoService.projectUsers.toArray();
    this.externaldatasourceService.executeExternalDataSource(14).then(getUserListResult => { this.userList = getUserListResult; });
  }

  fakeUser(userId) {
    //this.loaderService.display(true, false, 'UsersettingsComponent getUserList');
    this.applicationInfoService.ignoreFakeUserId = true;
    this.externaldatasourceService.executeExternalDataSource(173, [userId])
    .then(() => {
      this.userService.getJollyJupiterUserInformation();
      this.applicationInfoService.ignoreFakeUserId = false;
      //this.loaderService.display(false, false, 'UsersettingsComponent getUserList');
    })
    .catch(error => {
      this.applicationInfoService.ignoreFakeUserId = false;
      //this.loaderService.display(false, false, 'UsersettingsComponent getUserList');
    });
  }

  changeDesignerMode() {
    if (this.applicationInfoService.isDeveloper === true) {
      this.applicationInfoService.designerMode = !this.applicationInfoService.designerMode;
      this.applicationInfoService.isTimeRecordingUser = !this.applicationInfoService.isTimeRecordingUser;
      this.applicationInfoService.isDebugMode = !this.applicationInfoService.isDebugMode;
      this.applicationInfoService.isWebPhoneUser = !this.applicationInfoService.isWebPhoneUser;
    }
  }

  resetCacheCentral() {
    this.sharedAPI.repoCacheClearCentral().subscribe(() => {});
  }

  openExternalSite(target) {
    this.commonService.openUrl(environment[target], '_blank');
  }

  changeNewDesignMode() {
    this.applicationInfoService.useNewDesign = !this.applicationInfoService.useNewDesign;
    this.externalContentService.setNewDesignCSS(true);
  }

  changeMaximixedMode() {
    this.applicationInfoService.mainViewMaximixed = !this.applicationInfoService.mainViewMaximixed;
  }

  changeLanguage() {
    this.loaderService.display(true);
    const promiseArray: Promise<any>[] = [];
    this.applicationInfoService.userSettings['LanguageIdentifier'] == this.axivasTranslateService.currentLanguage;
    this.settingsService.updateSecurityUserSettingsJson();
    promiseArray.push(this.settingsService.setUserSetting(Setting.LanguageIdentifier, this.axivasTranslateService.currentLanguage, false));
    promiseArray.push(this.refreshValueService.refreshGlobalTranslations());
    promiseArray.push(this.refreshValueService.refreshProjectTranslations());
    Promise.all(promiseArray).then(() => {
      this.axivasTranslateService.translatedValues = [];
      this.loaderService.display(false);
      this.languageChanged = true;
    }).catch(() => {
      this.loaderService.display(false);
    });
    // this.methodService.launchInternalMethod('changelanguage', [this.axivasTranslateService.currentLanguage]);
  }

  changeTestEnvironmentMode() {
    this.applicationInfoService.testEnvironment = !this.applicationInfoService.testEnvironment;
  }

  updateCache(entity) {
    this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/' + entity + '/0').subscribe();
  }

  updateDataCache(entity) {
    this.authService.AuthGet(environment.apiData + '/AdminRefreshRepositoryCache/refresh/entity/' + entity + '/0').subscribe();
  }

  updateEMailQueue() {
    this.authService.AuthGet(this.applicationInfoService.apiBackendUrl + '/EmailEvent/checkQueue').subscribe();
  }

  reloadHasura() {
    this.authService.AuthGet(this.applicationInfoService.apiBackendUrl + '/hasurareload').subscribe();
  }

  doNotUntrack() {
    this.authService.AuthGet(this.applicationInfoService.apiBackendUrl + '/hasurareload?doNotUntrack='.concat(this.doNotUntrackValue)).subscribe();
  }

  openVipSettings() {
    this.eventService.showJjPopup('Supervisor.Label.ManageVip', 'vip', '80');
  }

  changeExpertMode() {
    this.settingsService.setUserSettingEx(this.settingsService.userSettings['ExpertMode'])
    if (this.settingsService.userSettings['ExpertMode'].value == 'true') {
      this.applicationInfoService.expertMode = true;
    } else {
      this.applicationInfoService.expertMode = false;
    }
  }

  updateSchema() {
    this.sharedAPI.updateDataSchema(this.schemaReloadProjectId).subscribe(() => {});
  }

  unloadSchema() {
    this.sharedAPI.unloadDataSchema(this.schemaUnloadProjectId).subscribe(() => {});
  }

  deleteProject() {
    this.confirmationService.confirm({
      message: 'Sind Sie sicher, dass Sie das Projekt löschen möchten?',
      header: 'Löschen',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sharedAPI.deleteProject(this.deleteProjectId).subscribe(() => {});      },
    });        
  }

  updateCentralSchema() {
    this.sharedAPI.updateCentralSchema().subscribe(() => {});
  }

  uploadAvatar(event) {
    this.createBase64String(event);
  }

  createBase64String(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const imgBase64Path = e.target.result;
          this.settingsService.setUserSetting('AvatarImage', imgBase64Path);
          this.applicationInfoService.userAvatar = imgBase64Path;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeAvatar() {
    this.settingsService.setUserSetting('AvatarImage', 'none');
    this.applicationInfoService.userAvatar = 'none';
  }

  changeRingCentralNumber() {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.userSettings[this.ringCentralOwnNumberSetting])) {
      this.ringcentralwebphoneService.ownCallerNumber = this.applicationInfoService.userSettings[this.ringCentralOwnNumberSetting];
    }
    this.settingsService.updateSecurityUserSettingsJson();
  }

  changeRingCentralHardphone() {
    this.settingsService.updateSecurityUserSettingsJson();
  }

  authenticateUser() {
    const userName = this.applicationInfoService.user.email;
    const password = this.userPass;
    let clientId = environment.cognitoClientId;
    let userPoolId = environment.cognitoClientPool;

    // const clientId = '72u09oohekqru5ej6udql41qmf';
    // const userPoolId = 'eu-central-1_uXryGITFU';

    if (this.applicationInfoService.user.isExternalUser == true) {
      this.externaldatasourceService.executeExternalDataSource(998, [this.applicationInfoService.user.email]).then(getUserDetails => {
        clientId = getUserDetails.awsCognitoPool.clientId;
        // clientId = getUserDetails.awsCognitoPool.clientIdStaging;        
        userPoolId = getUserDetails.awsCognitoPool.cognitoPoolId;
        console.log(getUserDetails);
        this.authenticateAndSetUser(userName, password, clientId, userPoolId);
      });
    } else {
      this.authenticateAndSetUser(userName, password, clientId, userPoolId);
    }
  }

  authenticateAndSetUser(userName, password, clientId, userPoolId) {
    this.awsCognitoService.authenticateAndSetUser(userName, password, clientId, userPoolId, {
      onSuccess: () => {
        //mfa is disabled
        this.userAuthenticated = true;
        this.isMfaEnabled = false;
        this.startMfaSetup();
      },
      totpRequired: () => {
        //mfa is enabled
        this.userAuthenticated = true;
        this.isMfaEnabled = true;
      },
      onFailure: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
      },
    })
  }

  startMfaSetup() {
    this.awsCognitoService.setupMfa({
      getSecretCode: (secretCode: string) => {
        this.setupMfaSecretCode = secretCode;
      },
      onFailure: (err: any) => {
        this.messagingService.showDefaultError('Login', err.message);
      },
    });
  }

  finishMfaSetup() {
    this.awsCognitoService.enableMfaByToken(this.setupMfaAnswer, {
      mfaSetupSuccess: () => {
        this.messagingService.showDefaultSuccess('Login', 'MFA is successfully enabled');
        this.mfaSetupEnabled = false;
        this.userAuthenticated = false;
      },
      mfaSetupFailed: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
      },
      onFailure: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
      },
    });
  }

  disableMfa() {
    this.awsCognitoService.cognitoUser.sendMFACode(this.setupMfaAnswer, {
      onSuccess: () => {
        this.awsCognitoService.setMfaEnabled(false, {
          onSuccess: () => {
            this.messagingService.showDefaultSuccess('Login', 'MFA is successfully disabled');
            this.mfaSetupEnabled = false;
            this.userAuthenticated = false;
          },
          onError: (err) => {
            this.messagingService.showDefaultError('Login', err.message);
          }})
      },
      onFailure: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
      },
    }, 'SOFTWARE_TOKEN_MFA');
  }

  reloadPage() {
    window.location.reload();
  }
}
