import { Component, Input } from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-bar-leadscore',
  templateUrl: './bar-leadscore.component.html',
  styleUrl: './bar-leadscore.component.scss'
})
export class BarLeadscoreComponent {
  constructor(
    private eventService: EventService
  ) {

  }
  @Input () value = 100;
  @Input () label: string = '';
  @Input () labelStrong : string = '';
  @Input () popupAllowed = false;
  @Input () accountHeader = false;
  @Input () info: string | null= null

  scoreArray=[
    { range: { min: 0, max: 20 }, class:'active__one'},
    { range: { min: 20, max: 40 }, class:'active__five'},
    { range: { min: 40, max: 60 }, class:'active__five'},
    { range: { min: 60, max: 80 }, class:'active__seven'},
    { range: { min: 80, max: 100 }, class:'active__nine'},
  ]

  // scoreArray=[
  //   { range: {min:0, max:10}, class:'active__one'},
  //   { range: {min:10, max:20}, class:'active__two'},
  //   { range: {min:20, max:30}, class:'active__three'},
  //   { range: {min:30, max:40}, class:'active__four'},
  //   { range: {min:40, max:50}, class:'active__five'},
  //   { range: {min:50, max:60}, class:'active__six'},
  //   { range: {min:60, max:70}, class:'active__seven'},
  //   { range: {min:70, max:80}, class:'active__eight'},
  //   { range: {min:80, max:90}, class:'active__nine'},
  //   { range: {min:90, max:100}, class:'active__ten'},
  // ]


  getValue(range){
    if(this.value >= range.min){
      return true
    } else {
      return false
    }
  }

  getNumberColor(){
    const findRange = this.scoreArray.find((item) =>  this.value >= item.range.min && this.value <= item.range.max);
    let returnValue = `${findRange?.class}`;
    if (this.accountHeader) {
      returnValue = returnValue.concat (' ' , 'accountHeaderLabel');
    }
    return returnValue;
  }

  roundUp(value) {
    return Math.round(value);
  }

  showPopup() {
    if (!this.popupAllowed) {
      return;
    }
    this.eventService.showJjPopup('General.AccountFooter.LeadScoringDetail', 'leadscoringdetail', '80');
  }
}
