import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrl: './navbar-item.component.scss'
})
export class NavbarItemComponent {

  @Input() label: string = null;
  @Input() disabled: boolean = false;
  @Input() active: boolean = null;
  @Input() hidden: boolean = false;
  @Input() first: boolean = null;
  @Input() last: boolean = false;
  @Input() overflow: boolean = false;



  @Output() onClick = new EventEmitter<any>();

  buttonClick(value?: any) {

    if (value) {
      this.onClick.emit(value);
    } else {
      this.onClick.emit();
    }

  }

}
